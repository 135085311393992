<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ 'Import VIP Adjustments' }}</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="col-12 form-group row mb-0">
              <label class="col-4 kt-form__label col-form-label">{{ 'Adjustments' }} <span class="text-danger">*</span> </label>
              <label class="col-2 btn btn-default btn-sm kt-form__control" [ngClass]="buttonLoading ? 'disabled' : ''">
                <ng-container>
                  <span>{{ 'Import File' }}</span>
                  <input type="file" hidden id="file" accept=".csv" (change)="onFileUpload($event)" [disabled]="buttonLoading" #myFile>
                </ng-container>
              </label>
              <label class="col-6 kt-form__label col-form-label" *ngIf="file">{{ file.name }}</label>
            </div>

            <div class="col-12 row mb-4">
              <label class="col-4 "></label>
              <div class="col-8 pl-0"><i class="fa fa-file-csv"></i><a href="assets/ExampleImportVIPs.csv" target="_blank" download="ExampleImportVIPs.csv"> {{ 'Download Template' }}</a></div>
            </div>

            <ng-container *ngIf="isAdminUser || permission?.approve_vip_upgrade">
              <div class="col-12 form-group row mb-0">
                <label class="col-4 kt-form__label col-form-label">{{ 'Auto Approve' }}</label>
                <div class="col-md-8 m-auto p-0 pt-2">
                  <label class="checkbox">
                      <input type="checkbox" formControlName="auto_approve"/>
                      <span></span>
                  </label>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col spinner-wrapper d-flex" *ngIf="buttonLoading">
            <mat-spinner [diameter]="15" class="mr-2" style="top: 1px"></mat-spinner>
            <p class="text-left">{{ 'Importing... Please Wait' }}</p>
          </div>
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()" ><i class="fas fa-ban"></i>{{ 'Close' }}</button>
          <kt-submit-button [buttonLoading]="buttonLoading" [isDisabled]="!form.valid" (confirmed)="onSave()"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>
  
  <!--swal-->
  <div style="display:none;" #importMessages>
      <p>{{ messages$ | async }}</p><br>
      <div style="text-align: left;" class="mb-3" *ngIf="showTextareaDuplicate">
        <label>{{ 'Duplicate Information' }}: 
          <button id="btn-copy" style="all:unset;">
            <i class="fas fa-copy copy-icon copy-duplicate-username"></i><div class="swal2-copy-tooltip copy-duplicate-tooltip">Copied!</div>
          </button>
        </label><br>
        <textarea style="width: 100%;" rows="5" [value]="textareaListDuplicate" #NotFound readonly></textarea>
      </div>
      <div style="text-align: left;" class="mb-3" *ngIf="showTextareaInvalid">
        <label>{{ 'Invalid Information' }}: 
          <button id="btn-copy" style="all:unset;">
            <i class="fas fa-copy copy-icon copy-invalid-info"></i><div class="swal2-copy-tooltip copy-invalid-tooltip">Copied!</div>
          </button>
        </label><br>
        <textarea style="width: 100%;" rows="5" [value]="textareaListInvalid" #NotFound readonly></textarea>
      </div>
  </div>
  