<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
          <div class="kt-form kt-form--label-right">
              <div class="row align-items-center">
                  <div class="col-xl-12 order-2 order-xl-1 pr-0">
                      <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Member Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload 
                                        [form]='form' 
                                        [dropdownList]='memberGroupsDropdownList' 
                                        [dropdownSettings]='memberGroupsDropdownSettings' 
                                        [formName]="'member_group_id'" 
                                        [selectionAttributes]="'id'" 
                                        [selectedItems]='memberGroupsSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <div class="input-group-prepend">
                                            <select formControlName="date_type" class="form-control">
                                                <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                         ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
              <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
              </div>
              <button *ngIf="canCreateRebate" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
          </div>
          <div class="kt-section">
              <div class="kt-section__content">
                  <div class="table-responsive">
                      <table class="table table-bordered table-hover table-striped table-sortable">
                          <thead>
                              <tr>
                                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('member_groups_name')" [ngClass]="sortingConfig.member_groups_name" [class.sort-selected]="sortingSelection.sort_by === 'member_groups_name'">Member Group</th>
                                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('min_rebate_limit')" [ngClass]="sortingConfig.min_rebate_limit" [class.sort-selected]="sortingSelection.sort_by === 'min_rebate_limit'">Min Rebate Limit</th>
                                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('max_rebate_limit')" [ngClass]="sortingConfig.max_rebate_limit" [class.sort-selected]="sortingSelection.sort_by === 'max_rebate_limit'">Max Rebate Limit</th>
                                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('percentage')" [ngClass]="sortingConfig.percentage" [class.sort-selected]="sortingSelection.sort_by === 'percentage'">Rebate Percentage</th>
                                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                  <th class="date-column text-center sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                  <th class="date-column text-center sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                  <th class="text-center">Actions</th>
                              </tr>
                          </thead>
                          <tbody *ngIf="(rebates$ | async) as rows">
                            <tr *ngFor="let row of rows">
                                <td>{{ row.name }}</td>
                                <td>{{ row.member_groups_name != null ? row.member_groups_name : '-' }}</td>
                                <td class="text-right">{{ row.min_rebate_limit }}</td>
                                <td class="text-right">{{ row.max_rebate_limit }}</td>
                                <td class="text-right">{{ row.percentage }}</td>
                                <td class="text-center">
                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status_name }}</span>
                                </td>
                                <td>
                                    {{ row.created_by ? row.created_by : 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #createdDateTime>
                                        {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                </td>
                                <td>
                                    {{ row.updated_by_username ? row.updated_by_username : 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #updatedDateTime>
                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                </td>
                                <td class="text-center">
                                    <button *ngIf="canViewRebate" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-cog"></i></button>
                                </td>
                            </tr>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                      </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                    <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
