import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PromotionTransferStatus } from '@core/enums/member-promotion-transfer-status.enum';
import { SitePrefix } from '@core/enums/site-prefix.enum';
import { MemberPromotion } from '@core/models/member-promotion.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { interval, Observable, of, Subscription, throwError } from 'rxjs';
import { exhaustMap, map, tap, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../members/services/member-data.service';
import { MemberPromotionEditDialogComponent } from './dialogs/member-promotion-edit.component';
import { MemberPromotionDataService } from './services/member-promotion-data.service';
import { MemberPromotionEntityService } from './services/member-promotion-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { MemberPromotionHistoryComponent } from '../members/dialogs/member-promotion-history/member-promotion-history.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'kt-member-promotions',
  templateUrl: './member-promotion.component.html',
  styleUrls: ['./member-promotion.component.scss']
})
export class MemberPromotionComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdownSettings = {};
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    gameProviders: this.dropdownHttpService.gameProviders,
    targetTypes: this.dropdownHttpService.targetTypes,
    statuses: Object.keys(PromotionTransferStatus).filter(status => !(parseInt(status, 10) >= 0)),
    perPage: this.dropdownHttpService.perPage
  };
  promotionTransferStatus = PromotionTransferStatus;
  memberPromotions$: Observable<MemberPromotion[]>;
  messages$ = this.memberPromotionDataService.messages$;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  ranges: any;
  currentDate = new Date();
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '3.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'game_provider_code': 'desc',
    'promo_code': 'desc',
    'status': 'desc',
    'start_datetime': 'desc',
    'end_datetime': 'desc',
    'amount': 'desc',
    'target_amount': 'desc',
    'accumulated_win_loss': 'desc',
    'rebate_amount': 'desc',
    'updated_at': 'desc',
    'unlocked_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  dataLength: number;
  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userPermissionsSyncPromo = this.isAdminUser === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).sync_member_promotions : true);
  userPermissions$ = this.store.pipe(select(specialPermissions));

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  // memberPromotionsUseNewPermissions: boolean;
  canViewMemberDialog: boolean;
  canCancelMemberPromotions: boolean;
  canUnlockMemberPromotions: boolean;
  canSyncPromotionTurnover: boolean;
  canViewPromotionHistoryLog: boolean;
  canExportMemberPromotion: boolean;

  dateTimeFilterType = [
    {
      key: 'start_at',
      label: 'Start Date'
    },
    {
      key: 'end_at',
      label: 'End Date'
    },
    {
      key: 'unlocked_at',
      label: 'Unlocked Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ]

  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private memberPromotionEntityService: MemberPromotionEntityService,
    private memberPromotionDataService: MemberPromotionDataService,
    private memberDataService: MemberDataService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private store: Store<AppState>,
    private translateService: TranslateService,
  ) { }

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.formInit();
    this.pagination = this.memberPromotionDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    // this.memberPromotionsUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('member_promotions');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.member_promotions_view_member_dialog;
      this.canCancelMemberPromotions = appPermissions.cancel_member_promotions;
      this.canUnlockMemberPromotions = appPermissions.unlock_member_promotions;
      this.canSyncPromotionTurnover = appPermissions.sync_promotion_turnover;
      this.canViewPromotionHistoryLog = appPermissions.member_promotions_view_promotion_history_log;
      this.canExportMemberPromotion = appPermissions.export_member_promotions_list;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  onOpenDialog(type: string, memberPromotionData?: any) {
    if (memberPromotionData) {
      if (type === 'member-information') {
        if (!this.canViewMemberDialog) {
          Swal.fire({
            icon: 'error',
            title: 'Permission Denied',
            text: 'You do not have permission to view member information.',
          });
          return;
        }

        const member = this.memberDataService.getById(memberPromotionData, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        ).subscribe();
      } else {
        switch (type) {
          case 'unlock':
            this.openDialogBy(MemberPromotionEditDialogComponent, { memberPromotion: memberPromotionData, mode: 'unlock' });
            break;
          case 'cancel':
            const totalPromoAmout = +memberPromotionData.bonus_amount + +memberPromotionData.amount;
            if (+memberPromotionData.balance > totalPromoAmout && +memberPromotionData.accumulated_target_amount == 0) {
              Swal.fire({
                html: '<div class="text-center m-t-20">' +
                      '<ul><li>'+ `The member's turnover is currently being updated and will be available shortly.` +'</li></ul>' +
                      '<ul><li class="mt-4">It is recommended to wait until the turnover update is complete before proceeding with the promotion cancellation.</li></ul>' +
                      '<ul><li class="mt-4 text-danger"><b>' + `Are you sure you want to cancel the member's promotion?` + '</b></li></ul>' +
                      '</div>',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                reverseButtons: true
              }).then((result) => {
                if (result.value) {
                  this.openDialogBy(MemberPromotionEditDialogComponent, { memberPromotion: memberPromotionData, mode: 'cancel' });
                }
              });
            } else {
              this.openDialogBy(MemberPromotionEditDialogComponent, { memberPromotion: memberPromotionData, mode: 'cancel' });
            }
            break;
        }
      }
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberPromotions$ = this.memberPromotionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.memberPromotionDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      id: null,
      username: null,
      game_provider_id: 'all',
      status: 'all',
      start: null,
      end: null,
      target_type: 'all',
      promo_code: null,
      currency_id: 'all',
      defaultDate: null,
      remarks: null,
      account_type_condition: 'Or',
      account_type: [1, 2, 3],
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start']) {
          data['start'] = moment(data['start']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end'] = moment(data['end']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.memberPromotions$ = this.memberPromotionEntityService.getWithQuery(`?perPage=${this.pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.memberPromotionDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start']) {
          data['start'] = moment(data['start']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end'] = moment(data['end']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.memberPromotionDataService.export(`?perPage=${this.pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSync(promotion_id: number) {
    $('#syncBtn_' + promotion_id).toggleClass('fa-spin');
    this.memberPromotionDataService.sync(promotion_id).subscribe(
      res => {
        if (res.success) {
          Swal.fire({
            text: res.message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          }).then((resp) => {
            this.memberPromotionDataService.getById(promotion_id).subscribe(update => {
              this.updateRow(update).subscribe();
            });
          })
        }
      },
      err => $('#syncBtn_' + promotion_id).toggleClass('fa-spin')
    );
  }

  updateRow(promotion: MemberPromotion) {
    this.loadingBar.start();
    return this.memberPromotions$ = this.memberPromotions$.pipe(
      map((rows: any) => {
        const index = rows.findIndex(row => row.id === promotion.id);

        let updatedRows = rows;
        updatedRows[index] = promotion;

        return updatedRows;
      }), tap(res => {
        this.loadingBar.complete();
        $('#syncBtn_' + promotion.id).toggleClass('fa-spin');
      }),
    )
  }

  private openDialogBy(componentRef: any, data?: { memberPromotion?: any, mode?: any, member?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        memberPromotion: data.memberPromotion,
        mode: data.mode,
        member: data.member
      },
      autoFocus: data.mode === 'member-information' ? false : true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      game_provider_id: new FormControl('all'),
      status: new FormControl('all'),
      start: new FormControl(null),
      end: new FormControl(null),
      target_type: new FormControl('all'),
      promo_code: new FormControl(null),
      currency_id: new FormControl('all'),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      remarks: new FormControl(null),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1, 2, 3]),
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  onShowHistory(promotionId: number) {
    this.dialog.open(MemberPromotionHistoryComponent, {
      data: {
        promotion_id: promotionId,
      },
      width: '1200px',
      autoFocus: false
    });
  }

}
