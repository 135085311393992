import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { VipVerificationHttpService } from '@core/services/vip-verification-http.service';
import { Clipboard } from '@angular/cdk/clipboard';

declare const $: any;

@Component({
  selector: 'kt-import-vips',
  templateUrl: './import-vips.component.html',
  styleUrls: ['./import-vips.component.scss']
})
export class ImportVIPsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('importMessages') importMessages: ElementRef;
  form: FormGroup;
  buttonLoading = false;
  checkValidation = false;
  file: any;
  messages$ = this.vipVerificationHttpService.messages$;
  textareaListDuplicate: string;
  textareaListInvalid: string;
  icon: SweetAlertIcon = 'info';
  refreshStatus: boolean;
  duplicateUsername = [];
  invalidInfo = [];
  showTextareaDuplicate = false;
  showTextareaInvalid = false;

  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  roleVersion = JSON.parse(localStorage.getItem('user_data')).authorized_role_version;
  permission = null;

  private subscription = new Subscription();

  @ViewChild('myFile') fileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ImportVIPsDialogComponent>,
    private vipVerificationHttpService: VipVerificationHttpService,
    private translateService: TranslateService,
    private appPermissionService: AppPermissionService,
    private clipboard: Clipboard,
  ) { }

  async ngOnInit() {
    this.formInit();
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      if (this.isAdminUser != 1 && this.roleVersion == 2) {
        this.permission = appPermissions;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.checkValidation = true;
    if (this.form.valid) {
      const formData = new FormData();
      formData.append('attached_file', this.file);
      formData.append('auto_approve', this.form.value.auto_approve ? '1' : '0');

      if (this.form.value.auto_approve == 1) {
        Swal.fire({
          title: this.translateService.instant('Are you sure?'),
          text: this.translateService.instant('Do you want to auto approve all the vips?'),
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: this.translateService.instant('Yes'),
          cancelButtonText: this.translateService.instant('No'),
          reverseButtons: true
        }).then((resp) => {
          // when close close the popup
          if (resp.dismiss === Swal.DismissReason.backdrop || resp.dismiss == Swal.DismissReason.cancel) {
            return;
          } else if (resp.value) { //when click button YES
            this.buttonLoading = true;
            this.onImportVIP(formData);
          }
        });
      }
      else {
        this.buttonLoading = true;
        this.onImportVIP(formData);
      }
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onFileUpload(event: any) {
    const file: File = event.target.files[0];
    this.file = file;
    this.form.patchValue({ attached_file: file });
  }

  onSwal() {
    const nativeElement = this.importMessages.nativeElement;
    nativeElement.style.cssText = 'visibility:visible';
    const textareaDuplicate = this.duplicateUsername.join('\n');
    const textareaInvalid = this.invalidInfo.join('\n');
    this.textareaListDuplicate = [textareaDuplicate].filter(element => element).join('\n\n');

    if (this.textareaListDuplicate.length !== 0) {
      this.showTextareaDuplicate = true;
    }

    this.textareaListInvalid = [textareaInvalid].filter(element => element).join('\n\n');
    
    if (this.textareaListInvalid.length !== 0) {
      this.showTextareaInvalid = true;
    }

    Swal.fire({
      title: this.translateService.instant('Import Complete!'),
      html: this.importMessages.nativeElement,
      icon: this.icon,
      heightAuto: false,
      didOpen: () => {
        // Bind function to the icon
        const copyIcon1 = document.querySelector('.copy-duplicate-username');
        if (copyIcon1) {
          const tooltip1 = document.querySelector('.copy-duplicate-tooltip') as HTMLElement;
          copyIcon1.addEventListener('click', () => {
            const clipboardText = this.duplicateUsername.join('\n');
            this.clipboard.copy(clipboardText);

            tooltip1.style.display = 'block';
            setTimeout(() => {
              tooltip1.style.display = 'none';
            }, 2000);
          });
        }

        const copyIcon2 = document.querySelector('.copy-invalid-info');
        if (copyIcon2) {
          const tooltip2 = document.querySelector('.copy-invalid-tooltip') as HTMLElement;
          copyIcon2.addEventListener('click', () => {
            const clipboardText = this.invalidInfo.join('\n');
            this.clipboard.copy(clipboardText);

            tooltip2.style.display = 'block';
            setTimeout(() => {
              tooltip2.style.display = 'none';
            }, 2000);
          });
        }
      }
    }).then(result => {
      if (result) {
        const textArea = $("#NotFound")[0];
        textArea.select();
        navigator.clipboard.writeText(textArea.value);
        this.onCloseDialog();
      }
    });
  }

  onImportVIP(formData: any) {
    this.refreshStatus = true;
    this.dialogRef.disableClose = true;
    this.vipVerificationHttpService.import(formData).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        this.duplicateUsername = res.duplicate_username;
        this.invalidInfo = res.invalid_info;
        this.dialogRef.disableClose = false;
        this.dialogRef.close(true);
        this.onSwal();
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.dialogRef.disableClose = false;
        this.file = null;
        this.form.patchValue({ attached_file: null });
        this.fileInput.nativeElement.value = "";
        throw error;
      })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      attached_file: new FormControl(null, [Validators.required]),
      auto_approve: new FormControl(null),
    });
  }
}
