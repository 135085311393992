
<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
        <div class="kt-form kt-form--label-right  kt-margin-b-10">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1">
                  <mat-tab-group animationDuration="0ms" (selectedTabChange)="onSelectTab()">
                    <ng-container>
                      <mat-tab label="Send Inbox Message">
                        <b *ngIf="!canSendMessage; else elseMessageTab" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <ng-template #elseMessageTab>
                          <app-send-message-tab mode="Create"></app-send-message-tab>
                        </ng-template>
                      </mat-tab>
                      <mat-tab label="Send SMS">
                        <b *ngIf="!canSendSMS; else elseSMSTab" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <ng-template #elseSMSTab>
                          <app-send-sms-tab
                            mode="Create"
                            [genericnotice]="genericnotice"
                          ></app-send-sms-tab>
                        </ng-template>
                      </mat-tab>
                      <mat-tab label="Send Campaign SMS" id="campaign-tab">
                        <b *ngIf="!canSendCampaignSMS; else elseCampaignSMSTab" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <ng-template #elseCampaignSMSTab>
                          <form class="row align-items-center" [formGroup]="formBulk" class="campaign-tab">
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Currency <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <select formControlName="currency_id" class="form-control" (change)="onSelectCurrency($event)" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.currency_id.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                      <option value="null" disabled hidden> Please Select </option>
                                      <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                          {{ value.name }}
                                      </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>SMS Provider <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <select class="form-control" formControlName="sms_provider" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.sms_provider.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <ng-container *ngFor="let value of dropdown.campaignSMSProvider | async">
                                        <option [value]="value.id">{{ value.name }}</option>
                                    </ng-container>
                                </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Execution Time (From-To)</label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <div class="input-group date">
                                    <input type="text" class="form-control" placeholder="HH:mm:ss - HH:mm:ss"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [locale]="{format: 'HH:mm:ss'}"
                                        [timePicker24Hour]="true" [showDropdowns]="false"
                                    ngxDaterangepickerMd/>
                                    <span class="input-group-append">
                                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Deduplicate <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <div class="custom-control custom-checkbox form-control border-0">
                                    <input type="checkbox" formControlName="deduplicate" class="custom-control-input" id="deduplicate" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.deduplicate.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                    <label class="custom-control-label" for="deduplicate"></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Promotion</label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <kt-dropdown-wo-lazyload *ngIf="rewardsDropdown.length > 0" [form]='formBulk' [dropdownList]='rewardsDropdown' [dropdownSettings]='rewardsDropdownSettings' [formName]="'promotion_id'" [selectionAttributes]="'id'">
                                  </kt-dropdown-wo-lazyload>
                                  <div class="spinner-wrapper" *ngIf="loading | async">
                                    <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Recipients <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && bulkFile === undefined, 'disabled' : !canSendCampaignSMS }">
                                    <span>Choose file</span>
                                    <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'formBulk')" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null" #myFileInput>
                                  </label>
                                  <div class="kt-form__label col-form-label" *ngIf="bulkFileName !== null">
                                    <label>{{ bulkFileName }}
                                      <span class="ml-2 btn btn-link" (click)="onRemoveFile('formBulk')">x</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Subject <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.subject.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Message Template</label>
                                </div>
                                <div class="col-10 kt-form__control campaign-template">
                                  <kt-dropdown-wo-lazyload id="campaign-dropdown-template"
                                    [form] = 'sendMessageForm'
                                    [dropdownList] = 'smsMessageTemplateDropdownList'
                                    [dropdownSettings] = 'smsMessageTemplateDropdownSettings'
                                    [formName] = "'message_template'"
                                    [selectionAttributes] = "'message'"
                                    [selectedItems]="selectedMessageTemplate"
                                    [isSMSMessageTemplate]="true"
                                    (selectedItemsChanged)="onMessageTemplateChanged($event, 'bulkSmsForm')">
                                  </kt-dropdown-wo-lazyload>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Message <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <textarea type="text" formControlName="message_content" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.message_content.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null"></textarea>
                                  <span>Message template variable not supported in this feature.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 form-group d-flex flex-row-reverse mb-0">
                              <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="canSendCampaignSMS" (confirmed)="onSend('bulk')"></kt-submit-button>
                            </div>
                          </form>
                        </ng-template>
                      </mat-tab>
                      <mat-tab label="Send Webpush">
                        <b *ngIf="!canSendWebpush; else elseWebpushTab" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <ng-template #elseWebpushTab>
                          <form class="row align-items-center" [formGroup]="sendWebPushForm">
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="webpushFile == undefined">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Member <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <kt-member-dropdown
                                    class="dropdown-maxheight"
                                    [form]="sendWebPushForm"
                                    [dropdownSettings]='webPushMemberDropdownSettings'
                                    [formName]="'member_account_id'"
                                    [selectionAttributes]="'id'"
                                    [selectedItems]="selectedMembersWebpush"
                                    (selectedItemsChanged)="onMemberChanged($event, 'sendWebPushForm')"
                                    [ngClass]="{'is-invalid': checkValidation && selectedMembersWebpush.length === 0 && webpushFile === undefined}">
                                  </kt-member-dropdown>
                                  <p class="selected-member-wrapper">
                                    <small *ngFor="let item of selectedMembersWebpush" class="selected-member bg-info">
                                      {{ item.username }}
                                      <span class="bg-danger remove" (click)="onRemoveMember(item.id, 'sendWebPushForm')">x</span>
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Subject <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <input type="text" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendWebPushForm.controls.title.errors }" [attr.disabled]="!canSendWebpush ? 'disabled' : null">
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="sendWebPushForm.get('member_account_id').value == null || sendWebPushForm.get('member_account_id').value?.length == 0">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Recipients <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && webpushFile === undefined && selectedMembers.length === 0, 'disabled' : !canSendWebpush }">
                                    <span>Choose file</span>
                                    <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'webPushForm')" [attr.disabled]="!canSendWebpush ? 'disabled' : null" #myFileInput>
                                  </label>
                                  <div class="kt-form__label col-form-label" *ngIf="webpushFileName !== null">
                                    <label>{{ webpushFileName }}
                                      <span class="ml-2 btn btn-link" (click)="onRemoveFile('webPushForm')">x</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Message Template</label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <kt-dropdown-wo-lazyload
                                    [form] = 'sendWebPushForm'
                                    [dropdownList] = 'messageTemplateDropdownList'
                                    [dropdownSettings] = 'webPushTemplateDropdownSettings'
                                    [formName] = "'message_template'"
                                    [selectionAttributes] = "'message'"
                                    [selectedItems]="selectedMessageTemplate"
                                    [isMessageTemplate]="true"
                                    (selectedItemsChanged)="onMessageTemplateChanged($event, 'webPushForm')">
                                  </kt-dropdown-wo-lazyload>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                              <div class="kt-form__group--inline row">
                                <div class="col-2 kt-form__label col-form-label">
                                  <label>Message <span class="text-danger">*</span></label>
                                </div>
                                <div class="col-10 kt-form__control">
                                  <ckeditor *ngIf="canSendWebpush" [config]="editorConfig" [editor]="editor" formControlName="content" [ngClass]="{ 'is-invalid':checkValidation && sendWebPushForm.controls.content.errors }"></ckeditor>
                                  <textarea *ngIf="!canSendWebpush" type="text" rows="3" class="form-control" [attr.disabled]="!canSendWebpush ? 'disabled' : null"></textarea>
                                  <span>{{ genericnotice }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 form-group d-flex flex-row-reverse mb-0">
                              <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="canSendWebpush" (confirmed)="onSend('webpush')"></kt-submit-button>
                            </div>
                          </form>
                        </ng-template>
                      </mat-tab>

                    </ng-container>
                  </mat-tab-group>
                </div>
            </div>
        </div>
      </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
