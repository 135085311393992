import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RebateEntityService } from './services/rebate-entity.service';
import { RebateDataService } from './services/rebate-data.service';
import { Pagination } from '@core/models/pagination.model';
import { RebateEditDialogComponent } from './dialogs/rebate-edit.component';
import { Observable, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Rebate } from '@core/models/rebate.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { GroupEntityService } from '../../general/groups/services/group-entity.service';

@Component({
  selector: 'kt-rebates',
  templateUrl: './rebates.component.html',
  styleUrls: ['./rebates.component.scss']
})
export class RebatesComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  rebates$: Observable<Rebate[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  ranges: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  memberGroupsDropdownList = [];
  memberGroupsSelectedItems = [];
  memberGroupsDropdownSettings = {};
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canCreateRebate: boolean;
  canViewRebate: boolean;

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  sortingConfig = {
    'name': 'desc',
    'member_groups_name': 'desc',
    'min_rebate_limit': 'desc',
    'max_rebate_limit': 'desc',
    'percentage': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '4.4';

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private rebateDataService: RebateDataService,
    private rebateEntityService: RebateEntityService,
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private gameProviderHttpService: GameProviderHttpService,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
    private translateService: TranslateService,
    private groupService: GroupEntityService,
  ) { }

  async ngOnInit() {
    this.ranges = this.transactionHttpService.ranges;
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.memberGroupsDropdownSettings = {
      text: this.translateService.instant('Please Select'),
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      singleSelection: false,
      classes: "dropdown",
      primaryKey: "id",
      labelKey: "name",
      noDataLabel: "",
      showCheckbox: false,
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRebate = appPermissions.create_rebate_settings;
      this.canViewRebate = appPermissions.view_rebate_settings;
    });

    this.subscriptions.add(apSub);

    this.formInit();
    await this.setMemberGroup();
    this.onSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(type: string, rebateId?: number) {
    this.gameProviderHttpService.selectionMode = 'fresh';
    if (rebateId) {
      const operator = this.rebateEntityService.getByKey(rebateId);
      this.subscription = operator.pipe(
        tap((res) => {
          this.openDialogBy(RebateEditDialogComponent, { rebate: res, mode: type });
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(RebateEditDialogComponent, { mode: type });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.rebates$ = this.rebateEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.rebateDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.memberGroupsSelectedItems = [];
    this.form.patchValue({
      name: null,
      status: 'all',
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key,
      member_group_id: null
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {

        if (data['defaultDate']) {
          delete data['defaultDate'];
        }

        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.rebates$ = this.rebateEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.rebateDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { rebate?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        rebate: data.rebate,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      status: new FormControl(1),
      date_type: new FormControl(this.dateTimeFilterType[0].key),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      member_group_id: new FormControl(null)
    });
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }
  
  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }


  private setMemberGroup():Promise<void> {
    return new Promise((resolve) => {
        this.groupService.getWithQuery(`?status=1&paginate=false`).subscribe(res => {
            this.memberGroupsDropdownList = res;  
            resolve();
        });
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
}
