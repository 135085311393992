
import { Status } from '@core/enums/status.enum';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, exhaustMap, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { PromotionContentDataService } from './services/promotion-content-data.service';
import { PromotionContentEntityService } from './services/promotion-content-entity.service';
import { PromotionContent } from '@core/models/promotion-content.model';
import { Observable, Subscription, BehaviorSubject, interval, Subject, of } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { PromotionContentEditDialogComponent } from './dialogs/promotion-content-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PromotionApplicationComponent } from '../promotion-application/dialogs/promotion-application.component';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { PromotionContentMemberEligibleDialogComponent } from './dialogs/promotion-content-member-eligible/promotion-content-member-eligible.component';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
@Component({
  selector: 'kt-promotion-contents',
  templateUrl: './promotion-content.component.html',
  styleUrls: ['./promotion-content.component.scss']
})
export class PromotionContentComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses: Object.keys(Status).filter(status => !(parseInt(status, 10) >= 0)),
    perPage: this.dropdownHttpService.perPage,
    contentType: this.dropdownHttpService.promotionContentType,
    dateTypes:[
      {
        name: 'Start Date', value: 'start_date'
      },{
        name: 'End Date', value: 'end_date'
      },{
        name: 'Publish Date', value: 'publish_date'
      },{
        name: 'Unpublish Date', value: 'expire_date'
      },{
        name: 'Created Date', value: 'created_at'
      },{
        name: 'Updated Date', value: 'updated_at'
      },
    ],
  };
  status = Status;

  promotionContents$: Observable<PromotionContent[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'language=1';
  dropdownLocales: any;
  dropdownCategory: any;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  disabledEdit$ = new Subject<boolean>();

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();

  categoriesDropdownList = [];
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  // permissions
  canCreatePromotionContent: boolean;
  canEditPromotionContent: boolean;
  canViewTotalApplicantsList: boolean;
  canCheckMemberEligibility: boolean;

  userPermissions$ = this.store.pipe(select(specialPermissions));

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    private subheaderService: SubheaderService,
    private promotionContentDataService: PromotionContentDataService,
    private promotionContentEntityService: PromotionContentEntityService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private appPermissionService: AppPermissionService,
    private store: Store<AppState>,
  ) { }

  private localesSub = new Subscription();
  private categoriesSub = new Subscription();
  private subscriptions = new Subscription();

  ngOnInit() {
    this.formInit();
    this.pagination = this.promotionContentDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.promotionContentDataService.locales$.next(res);
      this.dropdownLocales = res;
    })).subscribe();
    
    this.categoriesSub = this.dropdownHttpService.promoCategories.pipe(tap(res => {
      this.dropdownCategory = res;
    })).subscribe();

    this.disabledEdit$.next(false);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreatePromotionContent = appPermissions.create_promotion_content;
      this.canEditPromotionContent = appPermissions.edit_promotion_content;
      this.canViewTotalApplicantsList = appPermissions.view_total_applicants_list;
      this.canCheckMemberEligibility = appPermissions.promotion_contents_check_member_eligibility;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.localesSub.unsubscribe();
    this.categoriesSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionContents$ = this.promotionContentEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.promotionContentDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }
  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, promotionContentId?: number) {
    this.disabledEdit$.next(true);
    if (promotionContentId) {
      const promotionContent = this.promotionContentEntityService.getByKey(promotionContentId);
      this.subscription = promotionContent.pipe(
        tap((res) => {
          switch (type) {
            case 'edit':
              this.openDialogBy(PromotionContentEditDialogComponent, { promotionContent: res, mode: 'edit' });
              break;
            case 'promo-eligible':
              this.openDialogBy(PromotionContentMemberEligibleDialogComponent, { promotionContent: res, mode: type });
              break
          }
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(PromotionContentEditDialogComponent, { mode: 'create' });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      language: 'all',
      status: 'all',
      code: null,
      promo_code: null,
      category_id: 'all',
      type: 0,
      defaultDate: null, // Do not remove: For Clearing The Range
      date_type: this.dropdown.dateTypes[0].value, // Date filter by created at (0 for created at, 1 for approved at and 2 for processing time)
      start_date: null,
      end_date: null,
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.promotionContents$ = this.promotionContentEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.promotionContentDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onPromotionApplication(data: PromotionContent) {
    if (!this.canViewTotalApplicantsList) {
      Swal.fire('Permission Denied', 'You do not have permission to view total applicants list', 'error');
      return;
    }

    data = { ...data, language: this.form.value.language };
    this.dialog.open(PromotionApplicationComponent, {
      width: '85vw',
      data: {
        promotionContent: data,
        isModal: true
      }
    })
  }

  private openDialogBy(componentRef: any, data?: { promotionContent?: any; mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode == 'promo-eligible' ? '1500px' : '1000px',
      data: {
        promotionContent: data.promotionContent,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.disabledEdit$.next(false);
      if (result === true && data.mode !== 'promo-eligible') {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      language: new FormControl('all'),
      status: new FormControl('all'),
      code: new FormControl(null),
      promo_code: new FormControl(null),
      category_id: new FormControl('all'),
      type: new FormControl(0),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      date_type: new FormControl(this.dropdown.dateTypes[0].value), // Date filter by created at (0 for created at, 1 for approved at and 2 for processing time)
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      title: new FormControl(null),
      category: new FormControl('all'),
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== 'all' && key !== 'defaultDate' || key === 'status') ? fields[key] = key === 'start_date' || key === 'end_date' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:ss') : formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

}
