import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment-timezone';
import { Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { TranslateService } from '@ngx-translate/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TransactionHttpService } from '@core/services/transaction-http.service';

@Component({
  selector: 'kt-affiliate-member',
  templateUrl: './affiliate-member.component.html',
  styleUrls: ['./affiliate-member.component.scss']
})
export class AffiliateMemberComponent implements OnInit {

  pageSize = 30;
  page = 1;
  params = `affiliate_id=${this.data.affiliate.id}`;
  perPageDropdown = this.dropdownHttpService.perPage;
  pagination: Pagination;
  maxSize = 5;
  totalMember = 0;
  form: FormGroup;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  affiliateMember$: any;
  dataLength: number;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;

  dateTimeFilterType = [
    {
      key: 'register_at',
      label: 'Registration Date'
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any, dummy: any },
    private allAffiliatesDataService: AllAffiliatesDataService,
    public dialogRef: MatDialogRef<AffiliateMemberComponent>,
    private dropdownHttpService: DropdownHttpService,
    private translateService: TranslateService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
  ) { }

  ngOnInit() {
    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    if (this.data.dummy == 1) {
      this.accountTypeSelectedItems = [
        { id: 1, name: 'Normal'},
        { id: 2, name: 'With-Label'},
        { id: 3, name: 'Dummy'}
      ];
    } else {
      this.accountTypeSelectedItems = [
        { id: 1, name: 'Normal'},
        { id: 2, name: 'With-Label'}
      ];
    }
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    this.formInit();
    this.onSubmit();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSubmit(clearSearch?: boolean, page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.affiliateMember$ = [];

    if(this.form.value.account_type){
      this.form.value.account_type = this.form.value.account_type.toString();
    }

    const data = this.form.value;

    if (this.form.value.start_date) {
      data.start_date = moment(this.form.value.start_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data.end_date = moment(this.form.value.end_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    if(this.form.value.start_date == null){
      delete data['date_type'];
    }
    delete data['defaultDate'];

    let formParams = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = formParams ? `&${formParams}` : '';
    this.loadingBar.start();
    this.allAffiliatesDataService.getMembers(`?page=${page}&perPage=${pageSize}${params}${parameters}`).pipe(
      tap(res => {
        this.pagination = this.allAffiliatesDataService.pagination;
        this.totalMember = this.pagination.total;
        this.affiliateMember$ = res;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit(false, this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      account_type_condition: 'Or',
      account_type: [1, 2, 3],
      start_date: null,
      end_date: null,
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  private formInit() {
    this.form = new FormGroup({
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl(this.data.dummy == 1 ? [1,2,3] : [1,2]),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }
}
