<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
                <div class="kt-form kt-form--label-right mb-2">
                    <div class="row align-items-center">
                        <div class="col-xl-12 order-2 order-xl-1 pr-0">
                            <form class="row align-items-center" [formGroup]="form">
                                <div class="col-12 row mb-2 pr-0">
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>{{ 'Code' | translate }}:</label>
                                    </div>
                                    <div class="col-md-2 kt-form__control">
                                        <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                    </div>
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>{{ 'Currency' | translate }}:</label>
                                    </div>
                                    <div class="col-md-2 kt-form__control">
                                        <select formControlName="currency_id" class="form-control">
                                            <option value="all"> {{ 'All' | translate }} </option>
                                            <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                                {{ value.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>Status:</label>
                                    </div>
                                    <div class="col-md-2 kt-form__control">
                                        <select formControlName="status" class="form-control">
                                            <option value="all"> {{ 'All' | translate }} </option>
                                            <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                                {{ value }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 row mb-2 pr-0">
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>Date/Time:</label>
                                    </div>
                                    <div class="col-md-5 kt-form__control">
                                        <div class="input-group date">
                                            <div class="input-group-prepend">
                                                <select formControlName="date_type" class="form-control">
                                                    <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                                </select>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                             ngxDaterangepickerMd/>
                                            <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>Account Type:</label>
                                    </div>
                                    <div class="col-md-2 kt-form__control">
                                        <select formControlName="account_type_condition" class="form-control" >
                                            <option value="And">AND Condition</option>
                                            <option value="Or">OR Condition</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 kt-form__control">
                                        <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                            [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                            [formName]="'account_type'" [selectionAttributes]="'id'"
                                            [selectedItems]='accountTypeSelectedItems'>
                                        </kt-dropdown-wo-lazyload>
                                    </div>
                                </div>
                              </form>
                          </div>
                      </div>
                </div>
                <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                    <div>
                        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                    <button *ngIf="canExportPromotionStatistics" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>{{ 'Export' | translate }}</button>
                </div>
              <div class="kt-section">
                  <div class="kt-section__content">
                      <div class="table-responsive">
                          <table class="table table-bordered table-hover table-striped">
                              <thead>
                                  <tr class="text-top">
                                    <th class="text-center">Code</th>
                                    <th class="text-center">Currency</th>
                                    <th class="text-center">Unique Player(s)</th>
                                    <th class="text-center">Total Player(s)</th>
                                    <th class="text-center">Total Deposit</th>
                                    <th class="text-center">Total Bonus</th>
                                    <th class="text-center">Status</th>
                                    <th class="date-column">Created By</th>
                                    <th class="date-column">Updated By</th>
                                  </tr>
                              </thead>
                              <tbody *ngIf="(promotionStat$ | async) as rows">
                                  <tr *ngFor="let row of promotionStat$ | async; let i = index">
                                    <td class="text-center">{{ promotion$[i].code }}</td>
                                    <td class="text-center">{{ promotion$[i].currency_code }}</td>
                                    <td class="text-right">{{ promotion$[i].unique_players }}</td>
                                    <td class="text-right">{{ promotion$[i].total_players }}</td>
                                    <td class="text-right">{{ promotion$[i].total_deposit | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ promotion$[i].total_bonus | number : '1.2-2' }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + promotion$[i].status"> {{ status[promotion$[i].status] }}</span>
                                    </td>
                                    <td>
                                        {{ promotion$[i].created_by ? promotion$[i].created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ promotion$[i].created_date | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ promotion$[i].created_date  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ promotion$[i].updated_by ? promotion$[i].updated_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ promotion$[i].updated_date | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ promotion$[i].updated_date  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                  </tr>
                              </tbody>
                              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                          </table>
                      </div>
                      <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                        [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                        [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of
                                {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
    
