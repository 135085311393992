
import { map, catchError, tap, debounceTime, distinctUntilChanged, exhaustMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, Inject, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MessageStatus } from '@core/enums/message-status.enum';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Pagination } from '@core/models/pagination.model';
import { TranslateService } from '@ngx-translate/core';
import { MessageTemplate } from '@core/models/message-template.model';
import { MessageTemplateEntityService } from '@views/pages/apps/superuser/message-template/services/message-template-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import * as moment from 'moment-timezone';
import { SendMessageHistoriesEntityService } from './service/send-message-histories-entity.service';
import { SendMessageHistoriesDataService } from './service/send-message-histories-data.service';
import { HttpParams } from '@angular/common/http';
import { SendMessageTabComponent } from "../send-message-tab/send-message-tab.component";
import { SendSmsTabComponent } from "../send-sms-tab/send-sms-tab.component";
import { MatDialog } from "@angular/material/dialog";
import { AppPermissionService } from '@core/services/app-permission.service';
declare var $: any;

@Component({
  selector: 'kt-send-message-histories',
  templateUrl: './send-message-histories.component.html',
  styleUrls: ['./send-message-histories.component.scss']
})

export class SendMessageHistoriesComponent implements OnInit {
  form: FormGroup;
  usableVariables: any;
  messagesHistories$;
  messages$ = this.sendMessageHistoriesDataService.messageHistories$;
  editable: boolean = true;
  ranges: any;
  dataLength: number;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  messageStatus = MessageStatus;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  sortingConfig = {
    'id': 'desc',
    'schedule_datetime': 'desc',
    'message_templates.code': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
    'member_type': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  dateTimeFilterType = [
    {
      key: 'send_date',
      label: 'Send Date'
    },
    {
      key: 'created_date',
      label: 'Created Date'
    },
    {
      key: 'updated_date',
      label: 'Updated Date'
    }
  ];
  memberTypeFilterType = [
    {
      key: 'all',
      label: 'All'
    },
    {
      key: 1,
      label: 'Member'
    },
    {
      key: 2,
      label: 'Non Member'
    }
  ];

  dropdown = {
    statuses: [
      {
        name: 'Pending', value: 1, checked: true
      },
      {
        name: 'Processing', value: 2, checked: true
      },
      {
        name: 'Completed', value: 3, checked: false
      },
      {
        name: 'Cancelled', value: 4, checked: false
      },
      {
        name: 'Failed', value: 5, checked: false
      }
    ],
    perPage: this.dropdownHttpService.perPage,
  };
  status = {
    'Pending': 1,
    'Processing': 2,
    'Completed': 3,
    'Cancelled': 4,
    'Failed': 5
  };
  statusLabel = {
    1: 'Pending',
    2: 'Processing',
    3: 'Completed',
    4: 'Cancelled',
    5: 'Failed'
  };
  searchStatus = [1,2];
  regionDropdownSettings = {};
  regionDropdownList = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
  regionSelectedItems = [];
  templateTypeahead = [];

  // permissions
  canSendNow: boolean;
  canViewSettings: boolean;
  canDownloadSentReport: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private localesSub = new Subscription();

  languages: { id: number; code: string; name: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: 'inbox_message' | 'sms' },
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private authHttpService: AuthHttpService,
    public loadingBar: LoadingBarService,
    public dialogRef: MatDialogRef<SendMessageHistoriesComponent>,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private messageTemplateEntityService: MessageTemplateEntityService,
    private currencyHttpService: CurrencyHttpService,
    private sendMessageHistoriesEntityService: SendMessageHistoriesEntityService,
    private sendMessageHistoriesDataService: SendMessageHistoriesDataService,
    private dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();

    this.regionDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    this.formInit();
    this.messageTemplateEntityService.getAll().subscribe((res: any) => {
      this.templateTypeahead = res;
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canSendNow = this.data.type === 'inbox_message' ? appPermissions.send_message_now : appPermissions.send_sms_now;
      this.canViewSettings = this.data.type === 'inbox_message' ? appPermissions.view_message_settings : appPermissions.view_sms_settings;
      this.canDownloadSentReport = this.data.type === 'inbox_message' ? appPermissions.send_message_download_sent_report : appPermissions.send_sms_download_sent_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.localesSub.unsubscribe();
  }

  onCloseDialog($event?) {
    this.dialogRef.close();
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.regionSelectedItems = [];
    this.searchStatus = [1,2];
    this.form.patchValue({
      message_template: null,
      status: this.searchStatus,
      datetime_type: this.dateTimeFilterType[0].key,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      region: []
    });
    if( this.data.type == 'sms' ) {
      this.form.patchValue({
        member_type: this.memberTypeFilterType[0].key
      });
    }
    this.dropdown.statuses.forEach((child) => {
      if (child.name !== 'Pending' && child.name !== 'Processing') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.onSubmit(true);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.messagesHistories$ = this.sendMessageHistoriesEntityService.getWithQuery(`?${this.params}&page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;     
        this.pagination = this.sendMessageHistoriesDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.searchBtnLoading = clearSearch ? false : true;
        this.loading = true;

        // format date/time
        if (data['start_datetime']) {
          this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        // format message template
        if( data['message_template'] ) {
          data['message_template_code'] = data['message_template'].code;
          delete data['message_template'];
        }

        // convert object to query string
        let params = new HttpParams();
        Object.keys(data).forEach(key => {
          const value = data[key];
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              params = params.append(`${key}[${index}]`, item);
            });
          } else {
            params = params.set(key, value);
          }
        });
        this.params = params.toString();

        // api call
        this.loadingBar.start();
        return this.messagesHistories$ = this.sendMessageHistoriesEntityService.getWithQuery(`?${this.params}&page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.sendMessageHistoriesDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSendNow(id) {
    this.loading = true;
    const data = {
      'id' : id,
      'send_now' : true,
      'schedule_datetime' : moment(new Date()).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    };
    this.subscription = this.sendMessageHistoriesDataService.updateHistory(data).pipe(
      tap((res: any) => {
        this.messages$ = this.sendMessageHistoriesDataService.messageHistories$;
        this.onSubmit();
        this.loading = false;
      }),
      catchError((error) => {
        this.loading = false;
        throw error;
      })
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      message_template: new FormControl(null),
      status: new FormControl(this.searchStatus),
      datetime_type: new FormControl(this.dateTimeFilterType[0].key),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      region: new FormControl([]),
      type: new FormControl(this.data.type == 'sms' ? 2 : 1),
    });
    if( this.data.type == 'sms' ) {
      this.form.addControl('member_type', new FormControl(this.memberTypeFilterType[0].key));
    }
  }

  onSearchStatus(event: any, id: number, ind: number) {
    
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
    
  }

  private setCurrencyDropdown() {
    if (this.regionDropdownList.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.regionDropdownList = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.regionDropdownList != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.regionDropdownList = res;
        });
      }
    }
  }

  filterTemplate(term: string): MessageTemplate[] {
    if (this.templateTypeahead) {
      return this.templateTypeahead.filter(
        template => template.code.toLowerCase().includes(term.toLowerCase()) || template.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      return [];
    }
  }

  searchTemplate = (search$: Observable<string>): Observable<MessageTemplate[]> =>{
    return search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterTemplate(term))
    )
  }

  formatTemplate(template: MessageTemplate): string {
    return `${template.code} - ${template.name}`;
  }

  convertToUserTimezone(utcDatetime: string): string {
    if (!utcDatetime) {
      return 'Invalid input';
    }
  
    try {
      // Parse the UTC datetime and convert to the specified timezone
      const datetime = moment.utc(utcDatetime).tz(this.timezone);
  
      // Format the date and append the GMT offset
      const formattedDate = datetime.format('YYYY-MM-DD HH:mm:ss');
      const gmtOffset = datetime.format('Z'); // Get offset in +08:00 format
  
      return `${formattedDate} (GMT${gmtOffset})`;
    } catch (error) {
      return 'Error converting datetime';
    }
  }

  onOpenEditDialog(details?: any) {
    this.sendMessageHistoriesDataService.getById(details.id).pipe(
      tap((res: any) => {
        if (res) {
          let editDialog;
          if( res.type == 2 ) {
            editDialog = this.dialog.open(SendSmsTabComponent, {
              data: res,
              width: '1200px'
            });
          } else {
            editDialog = this.dialog.open(SendMessageTabComponent, {
              data: res,
              width: '1200px'
            });
          }
          editDialog.afterClosed().subscribe((res) => {
            if( res ) {
              this.onSubmit();
            }
          });
        }
      })
    ).subscribe();
  }

  onDownloadSentReport(id) {
    this.sendMessageHistoriesDataService.getSentReport(id).pipe(
      tap((res: any) => {
        if (res) {
          const fileUrl = res;
          // Trigger the browser download
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank';
          link.click();
        }
      }),
      catchError((error) => {
        this.messages$.next([...error.message]);
        throw error;
      })
    ).subscribe();
  }

}
