import { AffiliateApproval } from '@core/models/affiliate-approval.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { AffiliateApprovalDataService } from './../services/affiliate-approval-data.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'kt-affiliate-approval-edit',
  templateUrl: './affiliate-approval-edit.component.html',
  styleUrls: ['./affiliate-approval-edit.component.scss']
})
export class AffiliateApprovalEditDialogComponent implements OnInit , OnDestroy{

  messages$ = this.affiliateApprovalDataService.messages$;
  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  title = '';
  message = '';
  smsNotification: any;
  emailNotification: any;
  smsNotificationType: any;
  emailNotificationType: any;
  affiliateIds = [];
  modeType: any;
  SMSApproveRejectType = false;
  EmailApproveRejectType = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: AffiliateApproval[], mode: string },
    public dialogRef: MatDialogRef<AffiliateApprovalEditDialogComponent>,
    private affiliateApprovalDataService: AffiliateApprovalDataService
  ) { }

  async ngOnInit() {
    if (this.data.mode == 'approve_single') {
      this.title = 'Approve Affiliate';
      this.message = 'Do you want to approve this affiliate application?';
      this.modeType = 1;
    } else if (this.data.mode == 'reject_single') {
      this.title = 'Reject Affiliate';
      this.message = 'Do you want to reject this affiliate application?';
      this.modeType = 2;
    } else if (this.data.mode == 'approve_selected') {
      this.title = 'Approve Affiliate';
      this.message = 'Do you want to approve the selected affiliate application below?';
      this.modeType = 1;
    } else if (this.data.mode == 'reject_selected') {
      this.title = 'Reject Affiliate';
      this.message = 'Do you want to reject the selected affiliate application below?';
      this.modeType = 2;
    }

    this.formInit();
    await this.getAffiliateApprovalSettings();
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(affiliate: any, mode?: string){
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    this.affiliateIds = this.data.affiliate.map(affiliate => affiliate.id);

    const data = {
      id: this.affiliateIds ? this.affiliateIds : null,
      status: mode === 'reject_single' || mode === 'reject_selected' ? 3 : 1,
      ...this.form.getRawValue()
    };

    const inputValue = (document.getElementById('remark-input') as HTMLInputElement)?.value ?? '';
    const validationMessageContainer = document.getElementById('custom-validation-message');

    if (validationMessageContainer) {
      validationMessageContainer.innerHTML = '';
    }

    const messages = [];
    if (inputValue.trim() === '') {
      messages.push('<div class="swal2-validation-message" id="swal2-validation-message" style="display: flex;">Remark is required.</div>');
    }

    if (messages.length > 0) {
      if (validationMessageContainer) {
        validationMessageContainer.innerHTML = messages.join('');
        this.buttonLoading = false;
        return false;
      }
    }

    this.subscription = this.affiliateApprovalDataService.statusUpdate(data).pipe(
      tap((res: any) => {
      this.messages$.next([...res.message]);
      this.buttonLoading = false;
      // To enable "Save" button after get response
      this.form.setErrors(null);
      this.refreshStatus = true;
    }),
    catchError((error) => {
      this.buttonLoading = false;
      // To enable "Save" button after get response
      this.form.setErrors(null);
      throw error;
    })
    ).subscribe();
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let approval_remarks = null;
    approval_remarks = this.data.affiliate ? null : this.data.affiliate;

    this.form = new FormGroup({
      remarks: new FormControl(approval_remarks, [Validators.required]),
      sms_notification: new FormControl(1),
      email_notification: new FormControl(1)
    });
  }

  onCheckbox(event: Event, formKey: string) {
    this.form.patchValue({
      [formKey]: (event.target as HTMLInputElement).checked ? 1 : 0
    });
  }

  getAffiliateApprovalSettings():Promise<void> {
    return new Promise((resolve, reject) => {
      this.affiliateApprovalDataService.getSettings().subscribe(res => {
        this.smsNotificationType = res.sms_notification_type;
        this.emailNotificationType = res.email_notification_type;

        if (res.sms_notification_type == 3) {
          //Approve & reject
          this.SMSApproveRejectType = true;
        }

        if (res.email_notification_type == 3) {
          //Approve & reject
          this.EmailApproveRejectType = true;
        }

        if (this.data.affiliate.length > 1) {
          this.data.affiliate.some((element: any) => {
            this.smsNotification = res.sms_notification.filter(x => x.currency_code === element.currency);
            this.emailNotification = res.email_notification.filter(x => x.currency_code === element.currency);
            
            return this.smsNotification.length > 0 && this.emailNotification.length > 0;
          });
        } else {
          this.smsNotification = res.sms_notification.filter(x => x.currency_code === this.data.affiliate[0].currency);
          this.emailNotification = res.email_notification.filter(x => x.currency_code === this.data.affiliate[0].currency);
        }

        if (this.smsNotification.length === 0 || (this.smsNotificationType != this.modeType && this.SMSApproveRejectType == false)) {
          this.form.get('sms_notification').disable();

          this.form.patchValue({
            sms_notification: 0
          });

        } else {
          this.form.get('sms_notification').enable();
        }
  
        if (this.emailNotification.length === 0 || (this.emailNotificationType != this.modeType && this.EmailApproveRejectType == false)) {
          this.form.get('email_notification').disable();

          this.form.patchValue({
            email_notification: 0
          });

        } else {
          this.form.get('email_notification').enable();
        }
        resolve();
      });
    });
  }
}
