
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription, interval, Subject, of } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef, ViewChildren, QueryList } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TermsConditionsDataService } from './services/terms-conditions-data.service';
import { TermsConditionsEntityService } from './services/terms-conditions-entity.service';
import { Dropdown } from '@core/models/dropdown.model';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { EventEmitterService } from '@core/services/event-emitter.service';
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';


@Component({
  selector: 'kt-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '80px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class TermsConditionsComponent implements OnInit, OnDestroy, AfterViewInit {

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config2;
  @ViewChildren('editors') editorComponent: QueryList<CKEditorComponent>;
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
    platform: this.dropdownHttpService.sitePlatforms,
  };

  termsConditions$: any;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdownLocales: Dropdown[];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  isEmptyContent = true;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  sections: any;
  checkboxForm: FormGroup;
  mode: any;
  displayLocale: any;
  toggleAll: boolean = false;
  availableTermsConditions = [];
  buttonLoading = true;

  termsConditionsData = {
    main_descriptions: [],
    contents: [],
  }

  termsConditions: any;
  viewTermsConditions: any;
  viewMode: any = false;

  formContent: FormGroup;
  initTermsConditions: any;
  changedObjects: any;

  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];

  private localesSub = new Subscription();
  messages$ = this.termsConditionsDataService.messages$;

  showMain = false;
  show = false;
  checkLoadingPage = false;

  // permissions
  canCreateTac: boolean;
  canViewTacDetails: boolean;
  canEditTac: boolean;
  canUpdateTacStatus: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    private termsConditionsDataService: TermsConditionsDataService,
    private dropdownHttpService: DropdownHttpService,
    public sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private editorService: EditorService,
    private appPermissionService: AppPermissionService,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.displayLocale = this.dropdown.locales[0];
      this.formInit();
      this.pagination = this.termsConditionsDataService.pagination;
      this.onSubmit();
      this.initTermsConditions = JSON.stringify({ ...this.formContent.value });

    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateTac = appPermissions.create_tac;
      this.canViewTacDetails = appPermissions.view_tac_details;
      this.canEditTac = appPermissions.edit_tac;
      this.canUpdateTacStatus = appPermissions.update_tac_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.localesSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      this.loading = true;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loadingBar.start();
      this.termsConditionsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).subscribe(res => {
        if (res) {
          this.termsConditions$ = res;
        }
        this.pagination = this.termsConditionsDataService.pagination;
        this.dataLength = res['contents'].rows.length;
        this.termsConditionsData.main_descriptions = res['main_descriptions'][0];
        this.termsConditionsData.contents = res['contents'].rows;
        this.availableTermsConditions = res['contents'].rows;
        this.toggleAll = false;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res['contents'].rows.length;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      });
    }
    else {
      this.page = this.backupPage;
    }
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.onSubmit(clearSearch);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;

    if (clearSearch) {
      this.onClear();
    }

    const data = {
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.termsConditionsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).subscribe(res => {
      if (res) {
        this.termsConditions$ = res;
      }
      this.pagination = this.termsConditionsDataService.pagination;
      this.dataLength = res['contents'].rows.length;
      this.termsConditionsData.main_descriptions = res['main_descriptions'][0];
      this.termsConditionsData.contents = res['contents'].rows;
      this.availableTermsConditions = res['contents'].rows;
      this.toggleAll = false;
      this.page = 1;
      this.loading = false;
      this.clearBtnLoading = false;
      this.searchBtnLoading = false;
      this.dataLength = res['contents'].rows.length;
      this.loadingBar.complete();
      this.cdr.detectChanges();
    })
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      settings_locale_id: new FormControl('all'),
      keyword: new FormControl(null),
      platform_type_id: new FormControl(1),
    });

    //----- terms conditions ------//
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          title: new FormControl(null),
          description: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formContent = new FormGroup({
      // Create terms conditions
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(1, [Validators.required]),
      content_type: new FormControl(null),
      platform_type_id: new FormControl(1, [Validators.required]),
      contents: new FormGroup(buildContents())
    });

    //----- end terms conditions ------//

    this.formContent.valueChanges.subscribe(data => {
      let isContent = false;
      Object.keys(data['contents']).forEach((sf) => {
        if (data['contents'][sf]['description'] != null && data['contents'][sf]['description'] != '') {
          isContent = true;
        }
      })
      this.isEmptyContent = isContent ? false : true;
      this.cdr.detectChanges();
    })
  }

  onClearContent() {
    this.formContent.patchValue({
      content_type: null,
      position: 99,
      status: 1,
      platform_type_id: 1
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            title: null,
            description: null
          }
        }
      });
    });
    this.initTermsConditions = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {
    this.mode = '';
    this.onClearContent();
    this.onSubmitClick(clearSearch);
    this.isEmptyContent = true;
    this.buttonLoading = false;
  }

  onSanitize(data: string) {
    return this.sanitizer.bypassSecurityTrustHtml(data.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'))
  }

  onFormatString(data: string) {
    return data.split(",").join(", <br />");
  }

  checkupdate() {
    const termsConditionscheck = this.initTermsConditions !== JSON.stringify({ ...this.formContent.value }) ? true : false;
    return termsConditionscheck ? true : false;
  }

  expandRowCreate(mode: any, close?: boolean) {
    let termsConditionsInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initTermsConditions != termsConditionsInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {

      this.changedObjects = false;
      this.initTermsConditions = null;
      this.mode = mode;
      this.cdr.detectChanges();

      this.onClearContent();

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.unsaveStatus = true;
            this.isEmptyContent = true;
            this.onClearContent();
            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {
        this.mode = '';
        this.unsaveStatus = true;
        this.isEmptyContent = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.termsConditions == row ? true : false;

    let termsConditionsInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initTermsConditions && this.initTermsConditions != termsConditionsInput ? true : false;
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.termsConditions = row;
      this.changedObjects = false;
      this.initTermsConditions = null;
      this.mode = mode;
      this.cdr.detectChanges();

      this.formContent.patchValue({
        content_type: this.termsConditions.content_type,
        position: this.termsConditions.position,
        status: this.termsConditions.status,
        platform_type_id: this.termsConditions.platform_type_id,
      });

      if (this.termsConditions.content_type == 1) {
        this.formContent.get('position').clearValidators();
        this.formContent.get('position').updateValueAndValidity();
      }

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.id]: {
              title: null,
              description: null
            }
          }
        });
      });

      setTimeout(() => {
        this.termsConditions.contents.forEach((element: any) => {
          this.formContent.patchValue({
            contents: {
              [element.settings_locale_id]: {
                title: element.raw_title,
                description: element.raw_description
              }
            }
          });
        });

        this.cdr.detectChanges();

        this.initTermsConditions = JSON.stringify({ ...this.formContent.value });
      }, 500);

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);
    } else {
      this.changedObjects = this.initTermsConditions != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            if (this.termsConditions == row) {
              this.termsConditions = null;
            }
            else {
              this.termsConditions = null;
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {
        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.termsConditions = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  expandRowView(row?: any) {
    this.show = false;

    if (this.viewTermsConditions && row.id != this.viewTermsConditions.id) {
      this.viewMode = true;
    } else {
      this.viewMode = this.viewMode ? false : true;
    }

    this.viewTermsConditions = row;
  }

  addTitleVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['title'] ? this.formContent.value.contents[id]['title'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          title: content + variable,
        }
      }
    });
  }

  addDescriptionVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['description'] ? this.formContent.value.contents[id]['description'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          description: content + variable,
        }
      }
    });
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  checkContent(locale: any) {
    let description = this.formContent.value.contents[locale.id].description ? this.formContent.value.contents[locale.id].description : null;

    if ((description == null || description == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  onSave() {
    this.loading = true;
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    // all new create termsConditions are content_type = 2 (section type)
    if (this.mode == 'create') {
      this.formContent.patchValue({
        content_type: 2
      });
    }

    const data = {
      id: this.termsConditions ? this.termsConditions.id : null,
      ...this.formContent.value,
    };

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    switch (this.mode) {
      case 'edit':
        this.subscription = this.termsConditionsDataService.updateTermsConditionsContent(this.termsConditions.id, data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.termsConditionsDataService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
    }

  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.termsConditionsDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  displayLocaleContent(row: any, column: any, type: any) {
    let mainDescription = [];
    mainDescription.push(this.termsConditionsData.main_descriptions)
    let termsConditions = type == 'mainDescription' ? mainDescription.filter(x => x.id == row.id)[0]['contents'] : this.availableTermsConditions.filter(x => x.id == row.id)[0]['contents'];

    termsConditions = termsConditions.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (termsConditions.length > 0) {
      if (column) {
        if (column == 'description') {
          return termsConditions[0]['description'] ? termsConditions[0]['description'] : '-';
        }
      }
      return termsConditions[0]['title'] ? termsConditions[0]['title'] : '-';

    }
    else {
      return '-';
    }
  }

  showReadMoreButton(id) {
    var element = document.getElementById(id);
    if (element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
    this.showMain = false;
    this.show = false;
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (!this.toggleAll) {
      this.availableTermsConditions.forEach(item => {
        patchValue = { ...patchValue, [item.id]: true };
      });
    }
    else {
      this.availableTermsConditions.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              title: null,
              description: null
            }
          }
        });

        let contents = this.formContent.value.contents;
        this.isEmptyContent = true;

        Object.keys(contents).forEach((sf) => {
          if (contents[sf]['content'] != null) {
            this.isEmptyContent = false;
          }
        });
      }
      this.cdr.detectChanges();
    });
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let tncInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initTermsConditions && this.initTermsConditions != tncInput ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.isEmptyContent = true;
            this.mode = '';
            this.termsConditions = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }
}
