import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, ViewRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { catchError, tap, exhaustMap, map } from 'rxjs/operators';
import { AffiliateGroupsDataService } from '../../services/affiliate-groups-data.service';
import { AllAffiliatesDataService } from '../../../all-affiliates/services/all-affiliates-data.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { GroupDataService } from '@views/pages/apps/general/groups/services/group-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AffiliateGroupsDialogBulkAddComponent } from './dialogs/affiliate-groups-dialog-bulk-add.component';
import { AffiliateGroupsPromotionBulkAddComponent } from './promotions/affiliate-groups-promotion-bulk-add.component';
import { AffiliateGroupsInboxBulkAddComponent } from './inboxes/affiliate-groups-inbox-bulk-add.component';
import { AffiliateGroupsMemberGroupBulkAddComponent } from './member-groups/affiliate-groups-member-group-bulk-add.component';
import { MatDialog } from '@angular/material/dialog';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { Status } from '@core/enums/status.enum';
import { AfffiliateMessageTemplateEntityService } from '@views/pages/apps/superuser/message-template/services/message-template-affiliate-entity.service';
import { AfffiliateMessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-affiliate-data.service';
import { AffiliateMessageTemplate } from '@core/models/message-template-affiliate.model';

@Component({
  selector: 'kt-affiliate-groups-edit',
  templateUrl: './affiliate-groups-edit.component.html',
  styleUrls: ['./affiliate-groups-edit.component.scss']
})
export class AffiliateGroupsEditDialogComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChildren('tab') tabs: QueryList<ElementRef>;
  status = Status;

  form: FormGroup;
  searchForm: FormGroup;
  searchPromotionForm: FormGroup;
  searchInboxMessageForm: FormGroup;
  searchInboxMessageAffForm: FormGroup;
  searchMemberGroupForm: FormGroup;
  limitForm: FormGroup;
  visibilityForm: FormGroup;
  dialogPopupVisibilityForm: FormGroup;
  dialogPopupVisibilityArrayForm: FormGroup;
  promotionVisibilityArrayForm: FormGroup;
  inboxMessageVisibilityForm: FormGroup;
  inboxMessageVisibilityArrayForm: FormGroup;
  memberGroupVisibilityForm: FormGroup;
  memberGroupVisibilityArrayForm: FormGroup;

  messages$ = this.affiliateGroupsDataService.messages$;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    memberGroups: [],
    statuses: this.dropdownHttpService.statuses,
    groupTypes: this.dropdownHttpService.affiliateGroupTypeStatuses,
    memberGroupTypes: this.dropdownHttpService.member_group_types,
    partnerNetwork: this.dropdownHttpService.affiliatePartnerNetwork,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
    promoTypes: [],
  }
  buttonLoading = false;
  tabLoading = false;

  // dialog popup
  affiliateDropdownList: any = [];
  affiliateDropdownListSettings: any;
  affiliateSelectedList = [];

  affiliateDialogDropdownListSettings: any;
  affiliateDialogSelectedList = [];

  affiliateDialogListDropdownListSettings: any;
  affiliateDialogListDropdownList = [];
  affiliateDialogListSelectedItems = [];

  // inbox message
  affiliateInboxDropdownListSettings: any;
  affiliateInboxSelectedList = [];

  inboxMessageListDropdownList = [];
  inboxMessageListDropdownListSettings: any;
  inboxMessageListSelectedItems = [];

  affiliateInboxListDropdownListSettings: any;
  affiliateInboxListDropdownList = [];
  affiliateInboxListSelectedItems = [];

  affiliateInboxListAffDropdownListSettings: any;
  affiliateMessageTemplates$: Observable<AffiliateMessageTemplate[]>;
  
  // member group
  affiliateMemberGroupDropdownListSettings: any;
  affiliateMemberGroupSelectedList = [];

  memberGroupListDropdownList = [];
  memberGroupListDropdownListSettings: any;
  memberGroupListSelectedItems = [];

  affiliateMemberGroupListDropdownListSettings: any;
  affiliateMemberGroupListDropdownList = [];
  affiliateMemberGroupListSelectedItems = [];

  memberGroupsSelectedItems = [];

  // promotion
  promotionListDropdownList = [];
  promotionListDropdownListSettings: any;
  promotionListSelectedItems = [];

  affiliatePromotionListDropdownListSettings: any;
  affiliatePromotionListDropdownList = [];
  affiliatePromotionListSelectedItems = [];

  isDisabledPromotionSubmit = true;

  currentActiveTab = null;
  currentActiveIndex = 0;
  resetInitDialog = false;
  initDialog: any;
  initDialogPopupVisibility: any;
  initAffiliateSelectedList: any;
  initAffiliateVisibility: any;
  initInboxMessageVisibility: any;
  initInboxMessageAffiliateSelectedList: any;
  initInboxMessageAffiliateVisibility: any;
  initMemberGroupVisibility: any;
  initMemberGroupAffiliateSelectedList: any;
  initMemberGroupAffiliateVisibility: any;
  dialogPopupVisibility$ = [];
  promotionVisibility$ = [];
  inboxMessageVisibility$ = [];
  memberGroupVisibility$ = [];
  isOnAddClicked = false;
  checkValidation = false;
  loading = false;
  loadingAff = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  searchBtnLoadingAff = false;
  clearBtnLoadingAff = false;
  dataLength: number;
  dataLengthAff: number;
  pagination: Pagination;
  pageSize = 10;
  page = 1;
  paginationAff: Pagination;
  pageSizeAff = 10;
  pageAff = 1;
  maxSize = 5;
  params = '';
  paramsAff = '';
  popupDropdownList = [];
  popupDropdownListAll = [];
  inboxDropdownList = [];
  inboxDropdownListAll = [];
  memberGroupDropdownList = [];
  memberGroupDropdownListAll = [];
  backUpPopupDialogArr: any;
  backUpPromotionArr: any;
  backUpInboxMessageArr: any;
  backUpMemberGroupArr: any;
  previousDialogPopupVisibilityList = null;
  previousInboxMessageVisibilityList = null;
  previousMemberGroupVisibilityList = null;
  isTriggerSwal = true;
  isTriggerInboxSwal = true;
  isTriggerMemberGroupSwal = true;
  clonedDialogData: any;
  clonedInboxData: any;
  clonedMemberGroupData: any;

  promotionDropdownList = [];
  promotionDropdownListAll = [];

  // permissions dialog
  canViewAffiliateGroupSettings: boolean;
  canEditAffiliateGroupSettings: boolean;
  canViewAffiliateReferralSettings: boolean;
  canEditAffiliateReferralSettings: boolean;

  canViewDialog: boolean;
  canEditDialog: boolean;
  canBulkAddDialog: boolean;
  canAddDialog: boolean;
  canDeleteDialog: boolean;

  // permissions promotion
  canViewPromotion: boolean;
  canEditPromotion: boolean;
  canBulkAddPromotion: boolean;
  canAddPromotion: boolean;
  canDeletePromotion: boolean;

  // permissions inbox message
  canViewInboxMessage: boolean;
  canEditInboxMessage: boolean;
  canBulkAddInboxMessage: boolean;
  canAddInboxMessage: boolean;
  canDeleteInboxMessage: boolean;

  // permissions inbox message
  canViewMemberGroup: boolean;
  canEditMemberGroup: boolean;
  canBulkAddMemberGroup: boolean;
  canAddMemberGroup: boolean;
  canDeleteMemberGroup: boolean;

  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  roleVersion = JSON.parse(localStorage.getItem('user_data')).authorized_role_version;
  isAllowViewEditPromotion = this.isAdminUser === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).view_and_edit_affiliate_promotion_settings : false);

  tabList = [
    {id: 0, tab: 'Affiliate Group Settings'},
    {id: 1, tab: 'Affiliate Referral Settings'},
    {id: 2, tab: 'Affiliate Promotion Settings'},
    {id: 3, tab: 'Affiliate Dialog Popup Settings'},
    {id: 4, tab: 'Affiliate Inbox Message Settings'},
    {id: 5, tab: 'Affiliate Member Group Settings'},
  ];

  popupDialogLoading = false;
  inboxMessageLoading = false;
  memberGroupLoading = false;
  private subscriptions = new Subscription();
  private subscription = new Subscription();
  private dialogSubscription = new Subscription();
  private inboxSubscription = new Subscription();
  private promotionSubscription = new Subscription();
  private memberGroupSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      mode: string, 
      groups: any, 
      visibilitySettings: { affiliate_group_id: number, affiliate_id: number[], column_visibility: number },
      dialogPopupVisibilitySettings: { affiliate_group_id: number, affiliate_id: number[], dialog_popup_column_visibility: number },
      inboxMessageVisibilitySettings: { affiliate_group_id: number, affiliate_id: number[], inbox_message_column_visibility: number },
      memberGroupVisibilitySettings: { affiliate_group_id: number, affiliate_id: number[], member_group_column_visibility: number },
    },
    public dialogRef: MatDialogRef<AffiliateGroupsEditDialogComponent>,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private dropdownHttpService: DropdownHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private loadingBar: LoadingBarService,
    private dialogDataService: DialogDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private affiliateMessageTemplateDataService: AfffiliateMessageTemplateDataService,
    private affiliateMessageTemplateEntityService: AfffiliateMessageTemplateEntityService,
    private appPermissionService: AppPermissionService,
    public dialog: MatDialog,
    private rewardsHttpService: RewardsHttpService,
    private groupDataService: GroupDataService,
  ) { }

  ngOnInit() {
    this.tabLoading = true;
    if (this.data.mode == 'edit') {
      this.clonedDialogData = Object.assign({}, this.data);
      this.clonedInboxData = Object.assign({}, this.data);
      this.clonedMemberGroupData = Object.assign({}, this.data);
    }

    this.dropdownHttpService.locales.subscribe(res => {
      this.dropdown.locales = res;
    });

    this.dropdownHttpService.promoTypes.subscribe(res => {
      this.dropdown.promoTypes = res.filter(x => x.id != 1);
    });

    this.dropdownHttpService.groups.subscribe(res => {
      this.dropdown.memberGroups = res;
    });

    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      // Affiliate Group Settings
      this.canViewAffiliateGroupSettings = appPermissions.view_affiliate_group_settings;
      this.canEditAffiliateGroupSettings = appPermissions.edit_affiliate_group_settings;
      this.currentActiveTab = this.currentActiveTab == null && this.canViewAffiliateGroupSettings ? 0 : this.currentActiveTab;

      // Affiliate Referral Settings
      this.canViewAffiliateReferralSettings = appPermissions.view_affiliate_referral_settings;
      this.canEditAffiliateReferralSettings = appPermissions.edit_affiliate_referral_settings;
      this.currentActiveTab = this.currentActiveTab == null && this.canViewAffiliateReferralSettings ? 1 : this.currentActiveTab;

      // Affiliate Promotion Settings
      this.canViewPromotion = appPermissions.view_affiliate_promotion_settings;
      this.canEditPromotion = appPermissions.edit_affiliate_promotion_settings;
      this.canBulkAddPromotion = appPermissions.bulk_add_affiliate_promotion_eligibility;
      this.canAddPromotion = appPermissions.add_affiliate_promotion_eligibility;
      this.canDeletePromotion = appPermissions.delete_affiliate_promotion_eligibility;
      this.currentActiveTab = this.currentActiveTab == null && this.canViewPromotion ? 2 : this.currentActiveTab;

      // Affiliate Dialog Popup Settings
      this.canViewDialog = appPermissions.view_affiliate_dialog_popup_settings;
      this.canEditDialog = appPermissions.edit_affiliate_dialog_popup_settings;
      this.canBulkAddDialog = appPermissions.bulk_add_affiliate_dialog_popup_eligibility;
      this.canAddDialog = appPermissions.add_affiliate_dialog_popup_eligibility;
      this.canDeleteDialog = appPermissions.delete_affiliate_dialog_popup_eligibility;
      this.currentActiveTab = this.currentActiveTab == null && this.canViewDialog ? 3 : this.currentActiveTab;

      // Affiliate Inbox Message Settings
      this.canViewInboxMessage = appPermissions.view_affiliate_inbox_message_settings;
      this.canEditInboxMessage = appPermissions.edit_affiliate_inbox_message_settings;
      this.canBulkAddInboxMessage = appPermissions.bulk_add_affiliate_inbox_message_eligibility;
      this.canAddInboxMessage = appPermissions.add_affiliate_inbox_message_eligibility;
      this.canDeleteInboxMessage = appPermissions.delete_affiliate_inbox_message_eligibility;
      this.currentActiveTab = this.currentActiveTab == null && this.canViewInboxMessage ? 4 : this.currentActiveTab;

      // Affiliate Member Group Settings
      this.canViewMemberGroup = appPermissions.view_affiliate_member_group_settings;
      this.canEditMemberGroup = appPermissions.edit_affiliate_member_group_settings;
      this.canBulkAddMemberGroup = appPermissions.bulk_add_affiliate_member_group_eligibility;
      this.canAddMemberGroup = appPermissions.add_affiliate_member_group_eligibility;
      this.canDeleteMemberGroup = appPermissions.delete_affiliate_member_group_eligibility;
      this.currentActiveTab = this.currentActiveTab == null && this.canViewMemberGroup ? 5 : this.currentActiveTab;

      this.formInit();
      if (!this.canEditAffiliateGroupSettings) {
        this.form.disable();
        this.limitForm.disable();
      } else {
        this.form.enable();
        this.limitForm.enable();
      }

      if (!this.canEditAffiliateReferralSettings) {
        this.visibilityForm.disable();
      } else {
        this.visibilityForm.enable();
      }
    });

    this.subscriptions.add(apSub);
  }

  onCloseDialog(value = false, closeDialog = false, customClose = false) {
    if (closeDialog || this.data.mode == 'create') {
      if (this.checkupdate()) {
        const result = Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Do you want to save the changes of ' + this.tabList.find(x => x.id == this.currentActiveTab).tab + '?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.onSave(this.currentActiveTab, true);
          } else if (result.isDenied) {
            this.dialogRef.close(value);
          }
        });
      }
      else {
        this.dialogRef.close(value);
      }
    }
    else if (customClose) {
      this.dialogRef.close(value);
    }
  }

  onSave(currentActiveTab?: any, closeDialog = false) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    const data = {
      id: this.data.groups ? this.data.groups.id : null,
      ...this.form.value,
      default: this.form.value.default ? 1 : 0,
    };

    const limitData = {
      ...this.limitForm.value,
    };

    const visibilityData = {
      column_visibility: this.visibilityForm.get('toggle').value ? +this.visibilityForm.get('column_visibility').value : 0,
    }
    if (this.visibilityForm.get('toggle').value && +this.visibilityForm.get('column_visibility').value > 1) {
      visibilityData['affiliate_id'] = this.visibilityForm.get('affiliate_id').value;
    }

    const dialogPopupVisibilityData = {
      dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
    }
    if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
      dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
    }

    const inboxMessageVisibilityData = {
      inbox_message_column_visibility: this.inboxMessageVisibilityForm.get('inbox_message_toggle').value ? +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value : 0,
    }
    if (this.inboxMessageVisibilityForm.get('inbox_message_toggle').value && +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value > 1) {
      inboxMessageVisibilityData['affiliate_id'] = this.inboxMessageVisibilityForm.get('inbox_message_affiliate_id').value;
    }

    const memberGroupVisibilityData = {
      member_group_column_visibility: this.memberGroupVisibilityForm.get('member_group_toggle').value ? +this.memberGroupVisibilityForm.get('member_group_column_visibility').value : 0,
    }
    if (this.memberGroupVisibilityForm.get('member_group_toggle').value && +this.memberGroupVisibilityForm.get('member_group_column_visibility').value > 1) {
      memberGroupVisibilityData['affiliate_id'] = this.memberGroupVisibilityForm.get('member_group_affiliate_id').value;
    }

    Object.keys(limitData).forEach((key) => (limitData[key] == null || limitData[key] === '') && delete limitData[key]);
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.mode === 'create') {
      this.form.setErrors({ 'invalid': true });
      this.subscription = this.affiliateGroupsDataService.add(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe(res => {
        this.dialogRef.close(true);
      });
    } else {
      if (currentActiveTab == 0) { // Affiliate Group Settings
        this.form.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateGroup(this.data.groups.id, data),
          this.affiliateGroupsDataService.updateWithdrawlimit(limitData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.limitForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.limitForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.groups = {
            default: data['default'],
            id: data['id'],
            member_group_id: data['member_group_id'],
            name: data['name'],
            remarks: data['remarks'],
            settings_currency_id: data['currency_id'],
            status: data['status'],
            type: data['type'],
            partner_network: data['partner_network'],
            limit: {
              daily_max: limitData['daily_max'],
              daily_max_count: limitData['daily_max_count'],
              id: limitData['id'],
              max: limitData['max'],
              min: limitData['min'],
              setting_currency_id: limitData['setting_currency_id'],
            }
          }
          this.formInit();
        });
      }
      else if (currentActiveTab == 1) { // Affiliate Referral Settings
        this.visibilityForm.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateReferralVisibilitySettings(this.data.groups.id, visibilityData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.visibilityForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.visibilityForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.visibilitySettings = {
            affiliate_group_id: this.data.groups.id,
            column_visibility: visibilityData['column_visibility'],
            affiliate_id: visibilityData['affiliate_id'] != undefined ? visibilityData['affiliate_id'] : this.data.visibilitySettings.affiliate_id,
          }
          this.formInit();
        });
      }
      else if (currentActiveTab == 2) { // Affiliate Promotion Settings
        this.isDisabledPromotionSubmit = true;
        this.promotionVisibilityArr.controls.forEach((control, index)=> {
          if (control.enabled && !this.validationPromotionList(index)) {
            const promotionListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
            promotionListData['affiliate_group_id'] = this.data.groups.id;

            this.subscription = forkJoin([
              this.affiliateGroupsDataService.createUpdatePromotionVisibilityList(control.value.mode, promotionListData),
            ]).pipe(
              tap(() => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                // this.dialogRef.close(closeDialog);
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                // this.dialogRef.close(closeDialog);
                throw error;
              })
            ).subscribe(res => {
              this.formInit();
              closeDialog ? this.dialogRef.close(true) : null;
            });
          }
          else if (control.enabled && this.validationPromotionList(index)) {
            this.buttonLoading = false;
            closeDialog ? this.dialogRef.close(true) : null;
          }

          if (index == this.promotionVisibilityArr.controls.length) {
            this.buttonLoading = false;
            closeDialog ? this.dialogRef.close(true) : null;
          }
        });
      }
      else if (currentActiveTab == 3) {  // Affiliate Dialog Popup Settings
        this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
          if (control.enabled && !this.validationDialogList(index)) {
            const dialogListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
            dialogListData['affiliate_group_id'] = this.data.groups.id;

            this.subscription = forkJoin([
              this.affiliateGroupsDataService.createUpdateDialogPopupVisibilityList(control.value.mode, dialogListData),
            ]).pipe(
              tap(() => {
                this.form.setErrors(null);
              }),
              catchError((error) => {
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe(res => {
              this.formInit();
            });
          }
        });
        
        this.dialogPopupVisibilityForm.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.dialogPopupVisibilityForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.dialogPopupVisibilityForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.dialogPopupVisibilitySettings = {
            ...this.data.dialogPopupVisibilitySettings,
            dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
            affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
          }

          this.clonedDialogData.dialogPopupVisibilitySettings = {
            ...this.clonedDialogData.dialogPopupVisibilitySettings,
            dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
            affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
          }
          this.formInit();

          closeDialog ? this.dialogRef.close(true) : null;
        });
      }
      else if (currentActiveTab == 4) {  // Affiliate Inbox Message Settings
        this.inboxMessageVisibilityArr.controls.forEach((control, index)=> {
          if (control.enabled && !this.validationInboxList(index)) {
            const inboxListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
            inboxListData['affiliate_group_id'] = this.data.groups.id;

            this.subscription = forkJoin([
              this.affiliateGroupsDataService.createUpdateInboxMessageVisibilityList(control.value.mode, inboxListData),
            ]).pipe(
              tap(() => {
                this.form.setErrors(null);
              }),
              catchError((error) => {
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe(res => {
              this.formInit();
            });
          }
        });
        
        this.inboxMessageVisibilityForm.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateInboxMessageVisibilitySettings(this.data.groups.id, inboxMessageVisibilityData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.inboxMessageVisibilityForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.inboxMessageVisibilityForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.inboxMessageVisibilitySettings = {
            ...this.data.inboxMessageVisibilitySettings,
            inbox_message_column_visibility: inboxMessageVisibilityData['inbox_message_column_visibility'],
            affiliate_id: inboxMessageVisibilityData['affiliate_id'] != undefined ? inboxMessageVisibilityData['affiliate_id'] : this.data.inboxMessageVisibilitySettings.affiliate_id,
          }

          this.clonedInboxData.inboxMessageVisibilitySettings = {
            ...this.clonedInboxData.inboxMessageVisibilitySettings,
            inbox_message_column_visibility: inboxMessageVisibilityData['inbox_message_column_visibility'],
            affiliate_id: inboxMessageVisibilityData['affiliate_id'] != undefined ? inboxMessageVisibilityData['affiliate_id'] : this.data.inboxMessageVisibilitySettings.affiliate_id,
          }
          this.formInit();

          closeDialog ? this.dialogRef.close(true) : null;
        });
      }
      else if (currentActiveTab == 5) {  // Affiliate Member Group Settings
        this.memberGroupVisibilityArr.controls.forEach((control, index)=> {
          if (control.enabled && !this.validationMemberGroupList(index)) {
            const memberGroupListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
            memberGroupListData['affiliate_group_id'] = this.data.groups.id;

            this.subscription = forkJoin([
              this.affiliateGroupsDataService.createUpdateMemberGroupVisibilityList(control.value.mode, memberGroupListData),
            ]).pipe(
              tap(() => {
                this.form.setErrors(null);
              }),
              catchError((error) => {
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe(res => {
              this.formInit();
            });
          }
        });
        
        this.memberGroupVisibilityForm.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateMemberGroupVisibilitySettings(this.data.groups.id, memberGroupVisibilityData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.memberGroupVisibilityForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.memberGroupVisibilityForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.memberGroupVisibilitySettings = {
            ...this.data.memberGroupVisibilitySettings,
            member_group_column_visibility: memberGroupVisibilityData['member_group_column_visibility'],
            affiliate_id: memberGroupVisibilityData['affiliate_id'] != undefined ? memberGroupVisibilityData['affiliate_id'] : this.data.memberGroupVisibilitySettings.affiliate_id,
          }

          this.clonedMemberGroupData.memberGroupVisibilitySettings = {
            ...this.clonedMemberGroupData.memberGroupVisibilitySettings,
            member_group_column_visibility: memberGroupVisibilityData['member_group_column_visibility'],
            affiliate_id: memberGroupVisibilityData['affiliate_id'] != undefined ? memberGroupVisibilityData['affiliate_id'] : this.data.memberGroupVisibilitySettings.affiliate_id,
          }
          this.formInit();

          closeDialog ? this.dialogRef.close(true) : null;
        });
      }
    }
  }

  onLimitByCurrency() {
    if (this.data.mode === 'edit') {
      this.limitForm.patchValue({ setting_currency_id: this.form.value.currency_id });
    }
  }

  private formInit() {
    setTimeout(() => {
      let tabIndex = -1;
      
      // Affiliate Group Settings 
      this.canViewAffiliateGroupSettings ? tabIndex++ : null;
      if (this.tabGroup.selectedIndex == tabIndex && this.canViewAffiliateGroupSettings) {
        this.currentActiveIndex = this.tabGroup.selectedIndex;
        this.currentActiveTab = 0;
      }

      // // Affiliate Referral Settings
      this.canViewAffiliateReferralSettings ? tabIndex++ : null;
      if (this.tabGroup.selectedIndex == tabIndex && this.canViewAffiliateReferralSettings) {
        this.currentActiveIndex = this.tabGroup.selectedIndex;
        this.currentActiveTab = 1;
      }

      // // Affiliate Promotion Settings
      this.canViewPromotion ? tabIndex++ : null;
      if (this.tabGroup.selectedIndex == tabIndex && this.canViewPromotion) {
        this.currentActiveIndex = this.tabGroup.selectedIndex;
        this.currentActiveTab = 2;
      }

      // // Affiliate Dialog Popup Settings
      this.canViewDialog ? tabIndex++ : null;
      if (this.tabGroup.selectedIndex == tabIndex && this.canViewDialog) {
        this.currentActiveIndex = this.tabGroup.selectedIndex;
        this.currentActiveTab = 3;
      }

      // // Affiliate Inbox Message Settings
      this.canViewInboxMessage ? tabIndex++ : null;
      if (this.tabGroup.selectedIndex == tabIndex && this.canViewInboxMessage) {
        this.currentActiveIndex = this.tabGroup.selectedIndex;
        this.currentActiveTab = 4;
      }

      // // Affiliate Member Group Settings
      this.canViewMemberGroup ? tabIndex++ : null;
      if (this.tabGroup.selectedIndex == tabIndex && this.canViewMemberGroup) {
        this.currentActiveIndex = this.tabGroup.selectedIndex;
        this.currentActiveTab = 5;
      }
      
      this.pagination = undefined;
      this.resetInitDialog = true;

      if (tabIndex == -1) {
        this.onCloseDialog(true, false, true);
      }
    });
    
    
    if (this.data.mode == 'edit') {
      this.allAffiliatesDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&paginate=false`).subscribe(res => {
        this.affiliateDropdownList = res;
        this.affiliateSelectedList = res.filter(x => this.data.visibilitySettings.affiliate_id.includes(x.id));
        this.affiliateDialogSelectedList = res.filter(x => this.data.dialogPopupVisibilitySettings.affiliate_id.includes(x.id));
        this.affiliateInboxSelectedList = res.filter(x => this.data.inboxMessageVisibilitySettings.affiliate_id.includes(x.id));
        this.affiliateMemberGroupSelectedList = res.filter(x => this.data.memberGroupVisibilitySettings.affiliate_id.includes(x.id));
        
        if (this.currentActiveTab == 0 || this.currentActiveTab == 1) {
          this.tabLoading = false;
        }

        if (this.currentActiveTab == 3) {
          if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3) {
            this.affiliateDialogListDropdownList = this.affiliateDropdownList;
          }
          else {
            this.affiliateDialogListDropdownList = this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.affiliate_id.includes(x.id));
          }
        }

        if (this.currentActiveTab == 4) {
          if (this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 || this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 3) {
            this.affiliateInboxListDropdownList = this.affiliateDropdownList;
          }
          else {
            this.affiliateInboxListDropdownList = this.affiliateDropdownList.filter(x => this.data.inboxMessageVisibilitySettings.affiliate_id.includes(x.id));
          }
        }

        if (this.currentActiveTab == 5) {
          if (this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 || this.memberGroupVisibilityForm.value.member_group_column_visibility == 3) {
            this.affiliateMemberGroupListDropdownList = this.affiliateDropdownList;
          }
          else {
            this.affiliateMemberGroupListDropdownList = this.affiliateDropdownList.filter(x => this.data.memberGroupVisibilitySettings.affiliate_id.includes(x.id));
          }
        }
      });
      this.affiliateDropdownListSettings = {
        autoPosition: false,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
        disabled: this.data.visibilitySettings.column_visibility < 2
      };

      this.affiliateDialogDropdownListSettings = {
        autoPosition: false,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliateDialogListDropdownListSettings = {
        autoPosition: true,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliateInboxDropdownListSettings = {
        autoPosition: false,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliateInboxListDropdownListSettings = {
        autoPosition: true,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliateInboxListAffDropdownListSettings = {
        autoPosition: true,
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };
      
      this.affiliateMemberGroupDropdownListSettings = {
        autoPosition: false,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliateMemberGroupListDropdownListSettings = {
        autoPosition: true,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.promotionListDropdownListSettings = {
        autoPosition: true,
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'code',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.inboxMessageListDropdownListSettings = {
        autoPosition: true,
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'code',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.memberGroupListDropdownListSettings = {
        autoPosition: true,
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'code',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliatePromotionListDropdownListSettings = {
        autoPosition: true,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };
    }

    let name = null;
    let currencyId = 0;
    let memberGroup = 0;
    let type = 1;
    let partner_network = 1;
    let isDefault = null;
    let status = null;
    let remarks = null;

    // withdraw limit
    let limitId = null;
    let min = null;
    let max = null;
    let dailyMax = null;
    let dailyMaxCount = null;
    let limitSettingCurrencyId = null;

    // Referral settings
    let toggle: boolean = false;
    let column_visibility: number = null;
    let affiliate_id: number[] = [];

    // Dialog Popup Visibility settings
    let dialog_toggle: boolean = false;
    let dialog_popup_column_visibility: number = null;
    let dialog_affiliate_id: number[] = [];

    // Inbox Message Visibility settings
    let inbox_message_toggle: boolean = false;
    let inbox_message_column_visibility: number = null;
    let inbox_message_affiliate_id: number[] = [];

    // Member Group Visibility settings
    let member_group_toggle: boolean = false;
    let member_group_column_visibility: number = null;
    let member_group_affiliate_id: number[] = [];

    if (this.data.mode === 'edit') {
      name = this.data.groups.name;
      currencyId = this.data.groups.settings_currency_id;
      memberGroup = this.data.groups.member_group_id;
      type = this.data.groups.type;
      partner_network = this.data.groups.partner_network;
      isDefault = this.data.groups.default;
      status = this.data.groups.status;
      remarks = this.data.groups.remarks;

      // withdraw limit
      limitId = this.data.groups.limit.id;
      min = this.data.groups.limit.min;
      max = this.data.groups.limit.max;
      dailyMax = this.data.groups.limit.daily_max;
      dailyMaxCount = this.data.groups.limit.daily_max_count;
      limitSettingCurrencyId = this.data.groups.limit.setting_currency_id;

      // Visibility setting
      column_visibility = this.data.visibilitySettings.column_visibility == 0 ? null : this.data.visibilitySettings.column_visibility;
      toggle = column_visibility == null ? false : true;
      affiliate_id = this.data.visibilitySettings.affiliate_id;

      // Dialog Popup Visibility setting
      dialog_popup_column_visibility = this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 0 ? null : this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility;
      dialog_toggle = dialog_popup_column_visibility == null ? false : true;
      dialog_affiliate_id = this.data.dialogPopupVisibilitySettings.affiliate_id;

      // Inbox Message Visibility setting
      inbox_message_column_visibility = this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 0 ? null : this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility;
      inbox_message_toggle = inbox_message_column_visibility == null ? false : true;
      inbox_message_affiliate_id = this.data.inboxMessageVisibilitySettings.affiliate_id;

      // Member Group Visibility setting
      member_group_column_visibility = this.data.memberGroupVisibilitySettings.member_group_column_visibility == 0 ? null : this.data.memberGroupVisibilitySettings.member_group_column_visibility;
      member_group_toggle = member_group_column_visibility == null ? false : true;
      member_group_affiliate_id = this.data.memberGroupVisibilitySettings.affiliate_id;
    }

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      currency_id: new FormControl(currencyId, [Validators.required, Validators.min(1)]),
      member_group_id: new FormControl(memberGroup, [Validators.required, Validators.min(1)]),
      type: new FormControl(type, [Validators.required, Validators.min(1)]),
      partner_network: new FormControl(partner_network, [Validators.required, Validators.min(1)]),
      default: new FormControl(isDefault),
      status: new FormControl(status),
      remarks: new FormControl(remarks)
    });

    // withdraw limit
    this.limitForm = new FormGroup({
      id: new FormControl(limitId),
      min: new FormControl(min),
      max: new FormControl(max),
      daily_max: new FormControl(dailyMax),
      daily_max_count: new FormControl(dailyMaxCount),
      setting_currency_id: new FormControl(limitSettingCurrencyId),
    });

    // Visibility Settings
    this.visibilityForm = new FormGroup({
      toggle: new FormControl(toggle),
      column_visibility: new FormControl(column_visibility),
      affiliate_id: new FormControl(affiliate_id)
    })

    this.visibilityForm.get('toggle').valueChanges.subscribe(res => {
      res ? this.visibilityForm.get('column_visibility').setValidators([Validators.required]) : this.visibilityForm.get('column_visibility').clearValidators();
      this.visibilityForm.get('column_visibility').updateValueAndValidity();
      this.resetValue();
    })

    this.visibilityForm.get('column_visibility').valueChanges.subscribe(res => {
      res > 1 ? this.visibilityForm.get('affiliate_id').setValidators([Validators.required]) : this.visibilityForm.get('affiliate_id').clearValidators();
      this.visibilityForm.get('affiliate_id').updateValueAndValidity();

      this.affiliateDropdownListSettings = {
        ...this.affiliateDropdownListSettings,
        disabled: res > 1 ? false : true
      };

      this.cdr.detectChanges();
    });

    

    // Dialog Popup Visibility Settings
    this.dialogPopupVisibilityForm = new FormGroup({
      dialog_toggle: new FormControl(dialog_toggle),
      dialog_popup_column_visibility: new FormControl(dialog_popup_column_visibility),
      dialog_affiliate_id: new FormControl(dialog_affiliate_id)
    })

    this.dialogPopupVisibilityForm.get('dialog_toggle').valueChanges.subscribe(res => {
      res ? this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').setValidators([Validators.required]) : this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').clearValidators();
      this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').updateValueAndValidity();

      if (res) {
        this.dialogPopupVisibilityForm.patchValue({
          dialog_popup_column_visibility: 1,
        });
        
        this.dialogPopupInit();
      }
      else {
        this.previousDialogPopupVisibilityList = null;
      }
    })

    this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').valueChanges.subscribe(res => {
      let isShowDeleteDialogConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
      if ((isShowDeleteDialogConfirmation == null || isShowDeleteDialogConfirmation == 'true') && this.initAffiliateVisibility !== res && res != 1 && this.dialogPopupVisibilityArr.controls.length > 0) {
        const result = Swal.fire({
          title: '<div class="text-center">Confirmation</div>',
          html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Dialog Popup Visibility</strong><br><br>Do you want to continue?</div>',
          showDenyButton: true,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          input: 'checkbox',
          inputPlaceholder: 'Do not show it again',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then((response) => {
          if (response.value && response.isConfirmed) {
            sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
            this.data.dialogPopupVisibilitySettings = {
              ...this.data.dialogPopupVisibilitySettings,
              dialog_popup_column_visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
            }
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateVisibility = res;
            this.cdr.detectChanges();
          }
          else if (!response.value && response.isConfirmed) {
            this.data.dialogPopupVisibilitySettings = {
              ...this.data.dialogPopupVisibilitySettings,
              dialog_popup_column_visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
            }
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateSelectedList = res;
            this.cdr.detectChanges();
          } else if (response.isDenied) {
            this.dialogPopupVisibilityForm.patchValue({
              dialog_popup_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
          else {
            this.dialogPopupVisibilityForm.patchValue({
              dialog_popup_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
        });
      }
      else {
        this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
        this.initAffiliateSelectedList = res;
        this.cdr.detectChanges();
      }
    });

    this.dialogPopupVisibilityForm.get('dialog_affiliate_id').valueChanges.subscribe(res => {
      setTimeout(() => {
        if (this.isTriggerSwal && this.initAffiliateSelectedList && this.initAffiliateSelectedList != JSON.stringify(this.affiliateDialogSelectedList.map(x => x.id)) &&
          JSON.parse(this.initAffiliateSelectedList).length > this.affiliateDialogSelectedList.length) {
          this.isTriggerSwal = false;

          let isShowDeleteDialogConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
          if (isShowDeleteDialogConfirmation == null || isShowDeleteDialogConfirmation == 'true') {
            const result = Swal.fire({
              title: '<div class="text-center">Confirmation</div>',
              html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Dialog Popup Visibility</strong><br><br>Do you want to continue?</div>',
              showDenyButton: true,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: true,
              reverseButtons: true,
              denyButtonText: this.translateService.instant('No'),
              confirmButtonText: this.translateService.instant('Yes'),
              icon: 'warning',
              input: 'checkbox',
              inputPlaceholder: 'Do not show it again',
              customClass: {
                denyButton: 'deny-button',
                confirmButton: 'confirm-button',
              }
            }).then((response) => {
              if (response.value && response.isConfirmed) {
                this.isTriggerSwal = true;
                sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
                this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
              }
              else if (!response.value && response.isConfirmed) {
                this.isTriggerSwal = true;
                this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
              } else if (response.isDenied) {
                this.isTriggerSwal = true;
                this.affiliateDialogSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.dialogPopupVisibilityForm.patchValue({
                  dialog_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
              else {
                this.isTriggerSwal = true;
                this.affiliateDialogSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.dialogPopupVisibilityForm.patchValue({
                  dialog_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
            });
          }
          else {
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
          }
        }
        else {
          this.initAffiliateSelectedList = JSON.stringify(this.affiliateDialogSelectedList.map(x => x.id));
          if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == null || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1) {
            const popIds = this.affiliateDropdownList.map(x => x.id);
            this.getDialogAffiliateList(popIds);
          }
          else {
            this.getDialogAffiliateList(res);
          }
        }
      }, 250);
    })

    // Inbox Message Visibility Settings
    this.inboxMessageVisibilityForm = new FormGroup({
      inbox_message_toggle: new FormControl(inbox_message_toggle),
      inbox_message_column_visibility: new FormControl(inbox_message_column_visibility),
      inbox_message_affiliate_id: new FormControl(inbox_message_affiliate_id)
    })

    this.inboxMessageVisibilityForm.get('inbox_message_toggle').valueChanges.subscribe(res => {
      res ? this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').setValidators([Validators.required]) : this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').clearValidators();
      this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').updateValueAndValidity();

      if (res) {
        this.inboxMessageVisibilityForm.patchValue({
          inbox_message_column_visibility: 1,
        });
        
        this.inboxMessageInit();
      }
      else {
        this.previousInboxMessageVisibilityList = null;
      }
    })

    this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').valueChanges.subscribe(res => {
      let isShowDeleteInboxConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
      if ((isShowDeleteInboxConfirmation == null || isShowDeleteInboxConfirmation == 'true') && this.initAffiliateVisibility !== res && res != 1 && this.inboxMessageVisibilityArr.controls.length > 0) {
        const result = Swal.fire({
          title: '<div class="text-center">Confirmation</div>',
          html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Inbox Message Visibility</strong><br><br>Do you want to continue?</div>',
          showDenyButton: true,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          input: 'checkbox',
          inputPlaceholder: 'Do not show it again',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then((response) => {
          if (response.value && response.isConfirmed) {
            sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
            this.data.inboxMessageVisibilitySettings = {
              ...this.data.inboxMessageVisibilitySettings,
              inbox_message_column_visibility: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility,
            }
            this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateVisibility = res;
            this.cdr.detectChanges();
          }
          else if (!response.value && response.isConfirmed) {
            this.data.inboxMessageVisibilitySettings = {
              ...this.data.inboxMessageVisibilitySettings,
              inbox_message_column_visibility: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility,
            }
            this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateSelectedList = res;
            this.cdr.detectChanges();
          } else if (response.isDenied) {
            this.inboxMessageVisibilityForm.patchValue({
              inbox_message_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
          else {
            this.inboxMessageVisibilityForm.patchValue({
              inbox_message_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
        });
      }
      else {
        this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
        this.initAffiliateSelectedList = res;
        this.cdr.detectChanges();
      }
    });

    this.inboxMessageVisibilityForm.get('inbox_message_affiliate_id').valueChanges.subscribe(res => {
      setTimeout(() => {
        if (this.isTriggerInboxSwal && this.initAffiliateSelectedList && this.initAffiliateSelectedList != JSON.stringify(this.affiliateInboxSelectedList.map(x => x.id)) &&
          JSON.parse(this.initAffiliateSelectedList).length > this.affiliateInboxSelectedList.length) {
          this.isTriggerInboxSwal = false;

          let isShowDeleteInboxConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
          if (isShowDeleteInboxConfirmation == null || isShowDeleteInboxConfirmation == 'true') {
            const result = Swal.fire({
              title: '<div class="text-center">Confirmation</div>',
              html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Inbox Message Visibility</strong><br><br>Do you want to continue?</div>',
              showDenyButton: true,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: true,
              reverseButtons: true,
              denyButtonText: this.translateService.instant('No'),
              confirmButtonText: this.translateService.instant('Yes'),
              icon: 'warning',
              input: 'checkbox',
              inputPlaceholder: 'Do not show it again',
              customClass: {
                denyButton: 'deny-button',
                confirmButton: 'confirm-button',
              }
            }).then((response) => {
              if (response.value && response.isConfirmed) {
                this.isTriggerInboxSwal = true;
                sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
                this.initAffiliateSelectedList = JSON.stringify(this.affiliateInboxSelectedList.map(x => x.id));
                this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
              }
              else if (!response.value && response.isConfirmed) {
                this.isTriggerInboxSwal = true;
                this.initAffiliateSelectedList = JSON.stringify(this.affiliateInboxSelectedList.map(x => x.id));
                this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
              } else if (response.isDenied) {
                this.isTriggerInboxSwal = true;
                this.affiliateInboxSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.inboxMessageVisibilityForm.patchValue({
                  inbox_message_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
              else {
                this.isTriggerInboxSwal = true;
                this.affiliateInboxSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.inboxMessageVisibilityForm.patchValue({
                  inbox_message_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
            });
          }
          else {
            this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
          }
        }
        else {
          this.initAffiliateSelectedList = JSON.stringify(this.affiliateInboxSelectedList.map(x => x.id));
          if (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == null || this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1) {
            const msgIds = this.affiliateDropdownList.map(x => x.id);
            this.getInboxAffiliateList(msgIds);
          }
          else {
            this.getInboxAffiliateList(res);
          }
        }
      }, 250);
    })

    // Member Group Visibility Settings
    this.memberGroupVisibilityForm = new FormGroup({
      member_group_toggle: new FormControl(member_group_toggle),
      member_group_column_visibility: new FormControl(member_group_column_visibility),
      member_group_affiliate_id: new FormControl(member_group_affiliate_id)
    })

    this.memberGroupVisibilityForm.get('member_group_toggle').valueChanges.subscribe(res => {
      res ? this.memberGroupVisibilityForm.get('member_group_column_visibility').setValidators([Validators.required]) : this.memberGroupVisibilityForm.get('member_group_column_visibility').clearValidators();
      this.memberGroupVisibilityForm.get('member_group_column_visibility').updateValueAndValidity();

      if (res) {
        this.memberGroupVisibilityForm.patchValue({
          member_group_column_visibility: 1,
        });
        
        this.memberGroupInit();
      }
      else {
        this.previousMemberGroupVisibilityList = null;
      }
    })

    this.memberGroupVisibilityForm.get('member_group_column_visibility').valueChanges.subscribe(res => {
      let isShowDeleteMemberGroupConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
      if ((isShowDeleteMemberGroupConfirmation == null || isShowDeleteMemberGroupConfirmation == 'true') && this.initAffiliateVisibility !== res && res != 1 && this.memberGroupVisibilityArr.controls.length > 0) {
        const result = Swal.fire({
          title: '<div class="text-center">Confirmation</div>',
          html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Member Group Visibility</strong><br><br>Do you want to continue?</div>',
          showDenyButton: true,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          input: 'checkbox',
          inputPlaceholder: 'Do not show it again',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then((response) => {
          if (response.value && response.isConfirmed) {
            sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
            this.data.memberGroupVisibilitySettings = {
              ...this.data.memberGroupVisibilitySettings,
              member_group_column_visibility: this.memberGroupVisibilityForm.value.member_group_column_visibility,
            }
            this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateVisibility = res;
            this.cdr.detectChanges();
          }
          else if (!response.value && response.isConfirmed) {
            this.data.memberGroupVisibilitySettings = {
              ...this.data.memberGroupVisibilitySettings,
              member_group_column_visibility: this.memberGroupVisibilityForm.value.member_group_column_visibility,
            }
            this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateSelectedList = res;
            this.cdr.detectChanges();
          } else if (response.isDenied) {
            this.memberGroupVisibilityForm.patchValue({
              member_group_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
          else {
            this.memberGroupVisibilityForm.patchValue({
              member_group_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
        });
      }
      else {
        this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
        this.initAffiliateSelectedList = res;
        this.cdr.detectChanges();
      }
    });

    this.memberGroupVisibilityForm.get('member_group_affiliate_id').valueChanges.subscribe(res => {
      setTimeout(() => {
        if (this.isTriggerMemberGroupSwal && this.initAffiliateSelectedList && this.initAffiliateSelectedList != JSON.stringify(this.affiliateMemberGroupSelectedList.map(x => x.id)) &&
          JSON.parse(this.initAffiliateSelectedList).length > this.affiliateMemberGroupSelectedList.length) {
          this.isTriggerMemberGroupSwal = false;

          let isShowDeleteMemberGroupConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
          if (isShowDeleteMemberGroupConfirmation == null || isShowDeleteMemberGroupConfirmation == 'true') {
            const result = Swal.fire({
              title: '<div class="text-center">Confirmation</div>',
              html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Member Group Visibility</strong><br><br>Do you want to continue?</div>',
              showDenyButton: true,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: true,
              reverseButtons: true,
              denyButtonText: this.translateService.instant('No'),
              confirmButtonText: this.translateService.instant('Yes'),
              icon: 'warning',
              input: 'checkbox',
              inputPlaceholder: 'Do not show it again',
              customClass: {
                denyButton: 'deny-button',
                confirmButton: 'confirm-button',
              }
            }).then((response) => {
              if (response.value && response.isConfirmed) {
                this.isTriggerMemberGroupSwal = true;
                sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
                this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
              }
              else if (!response.value && response.isConfirmed) {
                this.isTriggerMemberGroupSwal = true;
                this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
              } else if (response.isDenied) {
                this.isTriggerMemberGroupSwal = true;
                this.affiliateMemberGroupSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.memberGroupVisibilityForm.patchValue({
                  member_group_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
              else {
                this.isTriggerMemberGroupSwal = true;
                this.affiliateMemberGroupSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.memberGroupVisibilityForm.patchValue({
                  member_group_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
            });
          }
          else {
            this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
          }
        }
        else {
          this.initAffiliateSelectedList = JSON.stringify(this.affiliateMemberGroupSelectedList.map(x => x.id));
          if (this.memberGroupVisibilityForm.value.member_group_column_visibility == null || this.memberGroupVisibilityForm.value.member_group_column_visibility == 1) {
            const msgIds = this.affiliateDropdownList.map(x => x.id);
            this.getMemberGroupAffiliateList(msgIds);
          }
          else {
            this.getMemberGroupAffiliateList(res);
          }
        }
      }, 250);
    })
    
    this.promotionInit();
    this.dialogPopupInit();
    this.inboxMessageInit();
    this.memberGroupInit();

    setTimeout(() => {
      this.updateInitDialog(true);
    });
    
    this.cdr.detectChanges();
  }

  updateDialogVisibilityValidator(res: any) {
    res > 1 ? this.dialogPopupVisibilityForm.get('dialog_affiliate_id').setValidators([Validators.required]) : this.dialogPopupVisibilityForm.get('dialog_affiliate_id').clearValidators();
    this.dialogPopupVisibilityForm.get('dialog_affiliate_id').updateValueAndValidity();

    this.affiliateDialogDropdownListSettings = {
      ...this.affiliateDialogDropdownListSettings,
      disabled: res > 1 ? false : true
    };

    this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
      if (res > 0 && (control.value.is_default == 1 || res == 2 || res == 3)) {
        control.patchValue({
          visibility: res,
        });
      }
    });
    this.initAffiliateVisibility = res;
    this.cdr.detectChanges();
  }

  getDialogAffiliateList(popIds: any) {
    if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3) {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList.filter(x => popIds.includes(x.id));
    }

    this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.is_default == 1) {
        this.affiliateDialogListSelectedItems[index] = control.value.visibility == 1 ? [] : this.affiliateDialogListDropdownList.filter(x => popIds.includes(x.id));
        control.patchValue({
          affiliate_id: control.value.visibility == 1 ? [] : this.affiliateDialogListSelectedItems[index].map(x => x.id),
        });
      }
      else {
        if (control.value.visibility == 2) {
          let affiliateArr = control.value.affiliate_id.filter(x => popIds.includes(x));
          this.affiliateDialogListSelectedItems[index] = this.affiliateDialogListDropdownList.filter(x => affiliateArr.includes(x.id));
          control.patchValue({
            affiliate_id: this.affiliateDialogListSelectedItems[index].length > 0 ? this.affiliateDialogListSelectedItems[index].map(x => x.id) : [],
          });
        }
      }
    });
    this.cdr.detectChanges();
  }

  getInboxAffiliateList(msgIds: any) {
    if (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 || this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 3) {
      this.affiliateInboxListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateInboxListDropdownList = this.affiliateDropdownList.filter(x => msgIds.includes(x.id));
    }

    this.inboxMessageVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.is_default == 1) {
        this.affiliateInboxListSelectedItems[index] = control.value.visibility == 1 ? [] : this.affiliateInboxListDropdownList.filter(x => msgIds.includes(x.id));
        control.patchValue({
          affiliate_id: control.value.visibility == 1 ? [] : this.affiliateInboxListSelectedItems[index].map(x => x.id),
        });
      }
      else {
        if (control.value.visibility == 2) {
          let affiliateArr = control.value.affiliate_id.filter(x => msgIds.includes(x));
          this.affiliateInboxListSelectedItems[index] = this.affiliateInboxListDropdownList.filter(x => affiliateArr.includes(x.id));
          control.patchValue({
            affiliate_id: this.affiliateInboxListSelectedItems[index].length > 0 ? this.affiliateInboxListSelectedItems[index].map(x => x.id) : [],
          });
        }
      }
    });
    this.cdr.detectChanges();
  }

  getMemberGroupAffiliateList(msgIds: any) {
    if (this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 || this.memberGroupVisibilityForm.value.member_group_column_visibility == 3) {
      this.affiliateMemberGroupListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateMemberGroupListDropdownList = this.affiliateDropdownList.filter(x => msgIds.includes(x.id));
    }

    this.memberGroupVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.is_default == 1) {
        this.affiliateMemberGroupListSelectedItems[index] = control.value.visibility == 1 ? [] : this.affiliateMemberGroupListDropdownList.filter(x => msgIds.includes(x.id));
        control.patchValue({
          affiliate_id: control.value.visibility == 1 ? [] : this.affiliateMemberGroupListSelectedItems[index].map(x => x.id),
        });
      }
      else {
        if (control.value.visibility == 2) {
          let affiliateArr = control.value.affiliate_id.filter(x => msgIds.includes(x));
          this.affiliateMemberGroupListSelectedItems[index] = this.affiliateMemberGroupListDropdownList.filter(x => affiliateArr.includes(x.id));
          control.patchValue({
            affiliate_id: this.affiliateMemberGroupListSelectedItems[index].length > 0 ? this.affiliateMemberGroupListSelectedItems[index].map(x => x.id) : [],
          });
        }
      }
    });
    this.cdr.detectChanges();
  }

  private dialogPopupInit() {
    // Dialog Popup Visibility List Search Form
    if (this.searchForm == undefined) {
      this.searchForm = new FormGroup({
        code: new FormControl(null),
        locale_id: new FormControl('all'),
      });
    }

    // Dialog Popup Visibility List
    this.dialogPopupVisibilityArrayForm = this.fb.group({
      dialogPopupVisibility: new FormArray([]),
    });

    this.dialogPopupVisibilityArrayForm.get('dialogPopupVisibility').valueChanges.subscribe(res => {
      if (res.length == 0 && this.pagination != undefined && this.pagination.total > 0 && this.canViewDialog) {
        this.page = 1;
        this.currentActiveTab = 3;
        this.updateInitDialog(true);
        this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
      }
    });
    
    setTimeout(() => {
      if (this.currentActiveTab == 3) {
          this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
      }
    }, 250);
  }

  private inboxMessageInit() {
    // Inbox Message Visibility List Search Form
    if (this.searchInboxMessageForm == undefined) {
      this.searchInboxMessageForm = new FormGroup({
        code: new FormControl(null),
        locale_id: new FormControl('all'),
      });
    }

    if (this.searchInboxMessageAffForm == undefined) {
      this.searchInboxMessageAffForm = new FormGroup({
        code: new FormControl(null),
        locale: new FormControl('all'),
        created_by: new FormControl(null),
      });
    }

    // Inbox Message Visibility List
    this.inboxMessageVisibilityArrayForm = this.fb.group({
      inboxMessageVisibility: new FormArray([]),
    });

    this.inboxMessageVisibilityArrayForm.get('inboxMessageVisibility').valueChanges.subscribe(res => {
      if (res.length == 0 && this.pagination != undefined && this.pagination.total > 0 && this.canViewInboxMessage) {
        this.page = 1;
        this.currentActiveTab = 4;
        this.updateInitDialog(true);
        this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
      }
    });
    
    setTimeout(() => {
      if (this.currentActiveTab == 4) {
        this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
      }
    }, 250);
  }

  private memberGroupInit() {
    // Member Group Visibility List Search Form
    if (this.searchMemberGroupForm == undefined) {
      this.searchMemberGroupForm = new FormGroup({
        member_group_id: new FormControl('all'),
        group_type: new FormControl('all'),
        status: new FormControl('all'),
      });
    }

    // Member Group Visibility List
    this.memberGroupVisibilityArrayForm = this.fb.group({
      memberGroupVisibility: new FormArray([]),
    });
    
    this.memberGroupVisibilityArrayForm.get('memberGroupVisibility').valueChanges.subscribe(res => {
      if (res.length == 0 && this.pagination != undefined && this.pagination.total > 0 && this.canViewMemberGroup) {
        this.page = 1;
        this.currentActiveTab = 5;
        this.updateInitDialog(true);
        this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
      }
    });

    setTimeout(() => {
      if (this.currentActiveTab == 5) {
        this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
      }
    }, 250);
  }

  onChangeMemberGroup($event: any) {
    if ($event.length > 0) {
      this.searchMemberGroupForm.patchValue({
        member_group_id: $event[0].id
      })
    }
    else {
      this.searchMemberGroupForm.patchValue({
        member_group_id: 'all'
      })
    }
  }

  private promotionInit() {
    // Promotion Visibility List Search Form
    this.searchPromotionForm = new FormGroup({
      code: new FormControl(null),
      name: new FormControl(null),
      promo_type: new FormControl('all'),
      currency_id: new FormControl('all'),
      status: new FormControl('all'),
    });

    // Promotion Visibility List
    this.promotionVisibilityArrayForm = this.fb.group({
      promotionVisibility: new FormArray([]),
    });

    this.promotionVisibilityArrayForm.get('promotionVisibility').valueChanges.subscribe(res => {
      if (res.length == 0 && this.pagination != undefined && this.pagination.total > 0 && this.canViewPromotion) {
        this.page = 1;
        this.currentActiveTab = 2;
        this.updateInitDialog(true);
        this.getPromotionVisibility(this.page, this.pageSize, this.params);
      }
    });
    
    setTimeout(() => {
      if (this.currentActiveTab == 2) {
          this.page = 1;
          this.getPromotionVisibility(this.page, this.pageSize, this.params);
      }
    }, 250);
  }

  tabChanged(tab: any, event: any) {
    let eventTabId = event.index == -1 ? -1 : this.tabList.find(x => x.tab == event.tab.textLabel).id;
    if (eventTabId != -1 && eventTabId != this.currentActiveTab) {
      this.tabLoading = true;
      if (this.checkupdate() && ((this.form.valid && this.currentActiveTab == 0) || (this.visibilityForm.valid && this.currentActiveTab == 1) || (this.currentActiveTab == 2) || (this.dialogPopupVisibilityForm.valid && this.currentActiveTab == 3) || (this.inboxMessageVisibilityForm.valid && this.currentActiveTab == 4) || (this.memberGroupVisibilityForm.valid && this.currentActiveTab == 5))) {
        const result = Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Do you want to save the changes of ' + this.tabList.find(x => x.id == this.currentActiveTab).tab + '?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.onSave(this.currentActiveTab);
            this.currentActiveTab = eventTabId;
            this.currentActiveIndex = tab.selectedIndex;
            tab.selectedIndex = this.currentActiveIndex;
            this.updateInitDialog(true);
            this.previousDialogPopupVisibilityList = null;
            this.resetPagination();
            this.onClear(false);
          } else if (result.isDenied) {
            this.currentActiveTab = eventTabId;
            this.currentActiveIndex = tab.selectedIndex;
            tab.selectedIndex = this.currentActiveIndex;

            if (this.currentActiveTab == 3) {
              this.data.dialogPopupVisibilitySettings = {
                ...this.data.dialogPopupVisibilitySettings,
                dialog_popup_column_visibility: this.clonedDialogData.dialogPopupVisibilitySettings.dialog_popup_column_visibility,
              }
              this.popupDialogLoading = true;
            }
            else {
              this.popupDialogLoading = false;
            }

            if (this.currentActiveTab == 4) {
              this.data.inboxMessageVisibilitySettings = {
                ...this.data.inboxMessageVisibilitySettings,
                inbox_message_column_visibility: this.clonedInboxData.inboxMessageVisibilitySettings.inbox_message_column_visibility,
              }
              this.inboxMessageLoading = true;
            }
            else {
              this.inboxMessageLoading = false;
            }

            if (this.currentActiveTab == 5) {
              this.data.memberGroupVisibilitySettings = {
                ...this.data.memberGroupVisibilitySettings,
                member_group_column_visibility: this.clonedMemberGroupData.memberGroupVisibilitySettings.member_group_column_visibility,
              }
              this.memberGroupLoading = true;
            }
            else {
              this.memberGroupLoading = false;
            }
            
            this.formInit();
            this.onClear();
            this.updateInitDialog(true);
          }
        });
      }
      else {
        this.currentActiveTab = this.tabList.find(x => x.tab == event.tab.textLabel).id;
        this.currentActiveIndex = tab.selectedIndex;
        this.popupDialogLoading = this.currentActiveTab == 3 ? true : false;
        this.inboxMessageLoading = this.currentActiveTab == 4 ? true : false;
        this.memberGroupLoading = this.currentActiveTab == 5 ? true : false;
        this.form.setErrors(null);
        this.limitForm.setErrors(null);
        this.visibilityForm.setErrors(null);
        this.resetPagination();
        this.formInit();
        this.onClear(false);
      }
    }
  }

  resetPagination() {
    this.pageSize = 10;
    this.page = 1;
    this.maxSize = 5;
    this.params = '';
  }

  checkupdate() {
    switch (this.currentActiveTab) {
      case 0: // Affiliate Group Settings
        return this.initDialog !== JSON.stringify({ ...this.form.value }) + JSON.stringify({ ...this.limitForm.value });
      case 1: // Affiliate Referral Settings
        return this.initDialog !== JSON.stringify({ ...this.visibilityForm.value });
      case 2: // Affiliate Promotion Settings
        return this.initDialog !== JSON.stringify({ ...this.promotionVisibilityArr.value });
      case 3: // Affiliate Dialog Popup Settings
        return this.initDialog !== JSON.stringify({ ...this.dialogPopupVisibilityForm.value }) || this.initDialogPopupVisibility != JSON.stringify({ ...this.dialogPopupVisibilityArr.value });
      case 4: // Affiliate Inbox Message Settings
        return this.initDialog !== JSON.stringify({ ...this.inboxMessageVisibilityForm.value }) || this.initInboxMessageVisibility != JSON.stringify({ ...this.inboxMessageVisibilityArr.value });
      case 5: // Affiliate Member Group Settings
        return this.initDialog !== JSON.stringify({ ...this.memberGroupVisibilityForm.value }) || this.initMemberGroupVisibility != JSON.stringify({ ...this.memberGroupVisibilityArr.value });
    }
  }

  updateInitDialog(isUpdateInitDialog = false) {
    switch (this.currentActiveTab) {
      case 0: // Affiliate Group Settings
        this.initDialog = JSON.stringify({ ...this.form.value }) + JSON.stringify({ ...this.limitForm.value });
        break;
      case 1: // Affiliate Referral Settings
        this.initDialog = JSON.stringify({ ...this.visibilityForm.value });
        break;
      case 2: // Affiliate Promotion Settings
        this.initDialog = JSON.stringify({ ...this.promotionVisibilityArr.value });
        break;
      case 3: // Affiliate Dialog Popup Settings 
        this.initDialog = isUpdateInitDialog || this.resetInitDialog ? JSON.stringify({ ...this.dialogPopupVisibilityForm.value }) : this.initDialog;
        this.initDialogPopupVisibility = JSON.stringify({ ...this.dialogPopupVisibilityArr.value });
        this.initAffiliateSelectedList = isUpdateInitDialog || this.resetInitDialog ? JSON.stringify(this.affiliateDialogSelectedList.map(x => x.id)) : this.initAffiliateSelectedList;
        this.initAffiliateVisibility = isUpdateInitDialog || this.resetInitDialog ? this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility : this.initAffiliateVisibility;
        break;
      case 4: // Affiliate Inbox Message Settings 
        this.initDialog = isUpdateInitDialog || this.resetInitDialog ? JSON.stringify({ ...this.inboxMessageVisibilityForm.value }) : this.initDialog;
        this.initInboxMessageVisibility = JSON.stringify({ ...this.inboxMessageVisibilityArr.value });
        this.initAffiliateSelectedList = isUpdateInitDialog || this.resetInitDialog ? JSON.stringify(this.affiliateInboxSelectedList.map(x => x.id)) : this.initAffiliateSelectedList;
        this.initAffiliateVisibility = isUpdateInitDialog || this.resetInitDialog ? this.inboxMessageVisibilityForm.value.inbox_message_column_visibility : this.initAffiliateVisibility;
        break;
      case 5: // Affiliate Member Group Settings 
        this.initDialog = isUpdateInitDialog ? JSON.stringify({ ...this.memberGroupVisibilityForm.value }) : this.initDialog;
        this.initMemberGroupVisibility = JSON.stringify({ ...this.memberGroupVisibilityArr.value });
        this.initAffiliateSelectedList = isUpdateInitDialog || this.resetInitDialog ? JSON.stringify(this.affiliateMemberGroupSelectedList.map(x => x.id)) : this.initAffiliateSelectedList;
        this.initAffiliateVisibility = isUpdateInitDialog || this.resetInitDialog ? this.memberGroupVisibilityForm.value.member_group_column_visibility : this.initAffiliateVisibility;
        break;
    }
    this.resetInitDialog = false;
  }

  getDialogPopupVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    this.resetValue();
    this.dialogSubscription.unsubscribe();
    this.popupDropdownList = [];
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.dialogSubscription = this.dialogDataService.getWithQuery(`?paginate=false&status=1&always_pop=0&affiliates_visibility=1&affiliate_group_id=${this.data.groups.id}`).subscribe(res => {
      let contents = [];
      res.map(function (elm) {
        elm.contents.forEach(v => contents.push(v));

        let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
        localeTitle = localeTitle.sort(function(a, b) { 
          return a.locale_id - b.locale_id;
        });

        if (localeTitle.length > 0) {
          let title = (localeTitle[0]['title'].length > 55) ? localeTitle[0]['title'].slice(0, 55 - 1) + ' . . . ' : localeTitle[0]['title'];
          elm['labelKey'] = elm.code + ' (' + title + ')';
        }
        else {
          elm['labelKey'] = elm.code;
        }
      });

      this.popupDropdownList = this.popupDropdownList.concat(res);
      this.popupDropdownList = this.popupDropdownList.map((x) => {
        return {
          id: x.id,
          labelKey: x.labelKey,
          code: x.code,
          locale_name: x.locale_name != null ? x.locale_name.replace(/\,/g, ", ") : null,
        }
      });

      this.dialogDataService.getWithQuery(`?paginate=false&status=1&always_pop=0&=1&affiliates_visibility=1`).subscribe(res => {
        let contents = [];
        res.map(function (elm) {
          elm.contents.forEach(v => contents.push(v));
  
          let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
          localeTitle = localeTitle.sort(function(a, b) { 
            return a.locale_id - b.locale_id;
          });
  
          if (localeTitle.length > 0) {
            let title = (localeTitle[0]['title'].length > 55) ? localeTitle[0]['title'].slice(0, 55 - 1) + ' . . . ' : localeTitle[0]['title'];
            elm['labelKey'] = elm.code + ' (' + title + ')';
          }
          else {
            elm['labelKey'] = elm.code;
          }
        });
  
        this.popupDropdownListAll = this.popupDropdownListAll.concat(res);
        this.popupDropdownListAll = this.popupDropdownListAll.map((x) => {
          return {
            id: x.id,
            labelKey: x.labelKey,
            code: x.code,
            locale_name: x.locale_name != null ? x.locale_name.replace(/\,/g, ", ") : null,
          }
        });

        const data = this.filterFormFields(this.searchForm.value);
        data['affiliate_group_id'] = this.data.groups.id;
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.affiliateGroupsDataService.getDialogPopupVisibilityList(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
          this.dialogPopupVisibility$ = res;
          if (this.dialogPopupVisibility$.length > 0) {
            this.dialogPopupVisibility$.map(item => {
              this.onAddRow(false, item);
            });
          }
          this.page = page;
          this.dataLength = res.length;
          this.pagination = this.affiliateGroupsDataService.pagination;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loading = false;
          this.loadingBar.complete();
          this.popupDialogLoading = false;

          this.isOnAddClicked = false;
          
          this.tabLoading = false;
          this.updateInitDialog(false);
        });
      });
    });

    this.cdr.detectChanges();
  }

  getInboxMessageVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    this.resetValue();
    this.inboxSubscription.unsubscribe();
    this.inboxDropdownList = [];
    this.inboxMessageListDropdownList = [];
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.inboxSubscription = this.messageTemplateDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&is_affiliate_setting=1&paginate=0`).subscribe(res => {
      this.inboxDropdownListAll = res;
      this.inboxDropdownListAll = this.inboxDropdownListAll.map((x) => {
        return {
          id: x.id,
          name: x.name,
          code: x.code,
          locales: x.locales != null ? x.locales.replace(/\,/g, ", ") : null,
          status: x.status,
          affiliate_group_id: x.affiliate_group_id
        }
      });

      this.inboxDropdownList = this.inboxDropdownListAll.filter(x => x.affiliate_group_id == null || x.affiliate_group_id == '');
      const data = this.filterFormFields(this.searchInboxMessageForm.value);
      data['affiliate_group_id'] = this.data.groups.id;
      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.affiliateGroupsDataService.getInboxMessageVisibilityList(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
        this.inboxMessageVisibility$ = res;
        if (this.inboxMessageVisibility$.length > 0) {
          this.inboxMessageVisibility$.map(item => {
            this.onInboxAddRow(false, item);
          });
        }
        this.assignInboxMessageList();
        this.page = page;
        this.dataLength = res.length;
        this.pagination = this.affiliateGroupsDataService.pagination;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loading = false;
        this.loadingBar.complete();
        this.inboxMessageLoading = false;

        this.isOnAddClicked = false;
        this.tabLoading = false;
        this.updateInitDialog(false);
      });
    });

    this.cdr.detectChanges();
  }

  onSubmitAffInboxMessage(clearSearch?: boolean) {
    this.searchBtnLoadingAff = clearSearch ? false : true;
    this.loadingAff = true;
    of(this.searchInboxMessageAffForm.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.paramsAff = paramsRef;
        this.loadingBar.useRef().start();
        return this.affiliateMessageTemplates$ = this.affiliateMessageTemplateEntityService.getWithQuery(`?${this.paramsAff}&perPage=${this.pageSize}&affiliate_group_id=${this.data.groups.id}&view_affiliate_only=1&status=1`).pipe(
          tap(res => {
            this.pageAff = 1;
            this.loadingAff = false;
            this.clearBtnLoadingAff = false;
            this.searchBtnLoadingAff = false;
            this.dataLengthAff = res.length;
            this.paginationAff = this.affiliateMessageTemplateDataService.pagination;
            this.loadingBar.useRef().complete();
          })
        );
      }),
    ).subscribe();
  }
  
  getMemberGroupVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    this.resetValue();
    this.memberGroupSubscription.unsubscribe();
    this.memberGroupDropdownList = [];
    this.memberGroupListDropdownList = [];
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.memberGroupSubscription = this.groupDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&is_affiliate_setting=1&paginate=0`).subscribe(res => {
      this.memberGroupDropdownListAll = res;
      this.memberGroupDropdownListAll = this.memberGroupDropdownListAll.map((x) => {
        return {
          id: x.id,
          name: x.name,
          code: x.code,
          default: x.default,
          vip: x.vip,
          trial: x.trial,
          status: x.status,
          affiliate_group_id: x.affiliate_group_id
        }
      });

      this.memberGroupDropdownList = this.memberGroupDropdownListAll.filter(x => x.affiliate_group_id == null || x.affiliate_group_id == '');
      const data = this.filterFormFields(this.searchMemberGroupForm.value);
      data['affiliate_group_id'] = this.data.groups.id;
      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.affiliateGroupsDataService.getMemberGroupVisibilityList(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
        this.memberGroupVisibility$ = res;
        if (this.memberGroupVisibility$.length > 0) {
          this.memberGroupVisibility$.map(item => {
            this.onMemberGroupAddRow(false, item);
          });
        }
        this.assignMemberGroupList();
        this.page = page;
        this.dataLength = res.length;
        this.pagination = this.affiliateGroupsDataService.pagination;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loading = false;
        this.loadingBar.complete();
        this.memberGroupLoading = false;

        this.isOnAddClicked = false;
        this.tabLoading = false;
        this.updateInitDialog(false);
      });
    });

    this.cdr.detectChanges();
  }

  getPromotionVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    this.resetValue();
    this.promotionSubscription.unsubscribe();
    this.promotionDropdownList = [];
    this.promotionListDropdownList = [];
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.promotionSubscription = this.rewardsHttpService.getRewardsList(`?affiliate_group_id=${this.data.groups.id}&is_affiliate_setting=1&paginate=0`).subscribe(res => {
      this.promotionDropdownListAll = res;
      this.promotionDropdownListAll = this.promotionDropdownListAll.map((x) => {
        return {
          id: x.id,
          name: x.name,
          code: x.code,
          promo_type: x.promo_type,
          settings_currency_name: x.settings_currency_name != null ? x.settings_currency_name.replace(/\,/g, ", ") : null,
          status: x.status,
          affiliate_group_id: x.affiliate_group_id
        }
      });
      
      this.promotionDropdownList = this.promotionDropdownListAll.filter(x => x.affiliate_group_id == null || x.affiliate_group_id == '');
      const data = this.filterFormFields(this.searchPromotionForm.value);
      data['affiliate_group_id'] = this.data.groups.id;
      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.affiliateGroupsDataService.getPromotionVisibilityList(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
        this.promotionVisibility$ = res;
        if (this.promotionVisibility$.length > 0) {
          this.promotionVisibility$.map(item => {
            this.onPromotionAddRow(false, item);
          });
        }
        this.assignPromotionList();
        this.page = page;
        this.dataLength = res.length;
        this.pagination = this.affiliateGroupsDataService.pagination;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loading = false;
        this.loadingBar.complete();

        this.isOnAddClicked = false;
        this.tabLoading = false;
        this.updateInitDialog(false);
      });
    });

    this.cdr.detectChanges();
  }

  onClear(reload = true) {
    this.tabLoading = true;
    this.clearBtnLoading = true;
    if (this.currentActiveTab == 2) {
      this.searchPromotionForm.patchValue({
        code: null,
        name: null,
        promo_type: 'all',
        currency_id: 'all',
        status: 'all',
      });
      this.page = 1;
      reload ? this.getPromotionVisibility(this.page, this.pageSize, this.params, true) : null;
    }
    else if (this.currentActiveTab == 3) {
      this.searchForm.patchValue({
        code: null,
        locale_id: 'all'
      });
      reload ? this.getDialogPopupVisibility(this.page, this.pageSize, this.params, true) : null;
    }
    else if (this.currentActiveTab == 4) {
      this.searchInboxMessageForm.patchValue({
        code: null,
        locale_id: 'all'
      });
      reload ? this.getInboxMessageVisibility(this.page, this.pageSize, this.params, true) : null;
    }
    else if (this.currentActiveTab == 5) {
      this.searchMemberGroupForm.patchValue({
        member_group_id: 'all',
        group_type: 'all',
        status: 'all'
      });
      this.memberGroupsSelectedItems = [];
      reload ? this.getMemberGroupVisibility(this.page, this.pageSize, this.params, true) : null;
    }
    else {
      this.tabLoading = false;
      this.clearBtnLoading = false;
    }
  }

  onClearAffInboxMessage() {
    this.searchInboxMessageAffForm.patchValue({
      code: null,
      locale: 'all',
      created_by: null
    });

    this.onSubmitAffInboxMessage(true);
  }
  
  get dialogPopupVisibilityArr(): FormArray {
    return this.dialogPopupVisibilityArrayForm.get('dialogPopupVisibility') as FormArray;
  }

  get promotionVisibilityArr(): FormArray {
    return this.promotionVisibilityArrayForm.get('promotionVisibility') as FormArray;
  }

  get inboxMessageVisibilityArr(): FormArray {
    return this.inboxMessageVisibilityArrayForm.get('inboxMessageVisibility') as FormArray;
  }

  get memberGroupVisibilityArr(): FormArray {
    return this.memberGroupVisibilityArrayForm.get('memberGroupVisibility') as FormArray;
  }

  private resetValue() {
    this.dialogPopupVisibility$ = [];
    this.dialogPopupVisibilityArr.clear();

    this.promotionVisibility$ = [];
    this.promotionVisibilityArr.clear();

    this.inboxMessageVisibility$ = [];
    this.inboxMessageVisibilityArr.clear();

    this.memberGroupVisibility$ = [];
    this.memberGroupVisibilityArr.clear();

    this.cdr.detectChanges();
  }

  onAddRow(isNew = false, data?: any) {
    this.checkValidation = false;
    let popup_id = data !== undefined ? data.popup_id : null;
    let is_default = data !== undefined ? data.is_default : null;
    let visibility = data !== undefined ? (data.is_default ? this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility : data.visibility) : null;
    let affiliate_id = data !== undefined ? data.affiliate_id : [];

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      popup_id: new FormControl(popup_id, [Validators.required]),
      is_default: new FormControl(is_default),
      visibility: new FormControl(isNew && this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 ? this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility : visibility ?? null, [Validators.required]),
      affiliate_id: new FormControl(affiliate_id),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (isNew) {
      this.dialogPopupVisibilityArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.dialogPopupVisibilityArr.controls[index];
        this.dialogPopupVisibilityArr.insert(index+1, currentGroup)
        this.dialogPopupVisibilityArr.removeAt(index);
      })

      this.dialogPopupVisibilityArr.insert(0, form);
    } 
    else {
      this.dialogPopupVisibilityArr.push(form);
    }

    if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3) {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList.filter(x => this.dialogPopupVisibilityForm.value.dialog_affiliate_id.includes(x.id));
    }
    
    let indexDialog = this.dialogPopupVisibilityArr.length - 1;
    if (data !== undefined) {
      this.popupDropdownList.push(this.popupDropdownListAll.filter(x => x.id == data.popup_id)[0]);

      this.popupDropdownList.sort(function(a, b) { 
        return - (a.id - b.id);
      });

      if (is_default == 1) {
        this.affiliateDialogListSelectedItems[indexDialog] = this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDialogListDropdownList.filter(x => this.dialogPopupVisibilityForm.value.dialog_affiliate_id.includes(x.id));
        this.dialogPopupVisibilityArr.controls[indexDialog].patchValue({
          affiliate_id: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDialogListSelectedItems[indexDialog].map(x => x.id),
        });
      }
      else {
        this.affiliateDialogListSelectedItems[indexDialog] = this.affiliateDialogListDropdownList.filter(x => affiliate_id.includes(x.id));
        this.dialogPopupVisibilityArr.controls[indexDialog].patchValue({
          affiliate_id: this.affiliateDialogListSelectedItems[indexDialog].length > 0 ? this.affiliateDialogListSelectedItems[indexDialog].map(x => x.id) : [],
        });
      }

      if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1) {
        this.dialogPopupVisibilityArr.controls[indexDialog].patchValue({
          visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
        });
      }
      
      this.dialogPopupVisibilityArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.affiliateDialogListSelectedItems.unshift([]);
      this.isOnAddClicked = true;
    }
  }

  onInboxAddRow(isNew = false, data?: any) {
    this.checkValidation = false;
    let message_template_id = data !== undefined ? data.message_template_id : null;
    let is_default = data !== undefined ? data.is_default : null;
    let visibility = data !== undefined ? (data.is_default ? this.inboxMessageVisibilityForm.value.inbox_message_column_visibility : data.visibility) : null;
    let affiliate_id = data !== undefined ? data.affiliate_id : [];

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      message_template_id: new FormControl(message_template_id, [Validators.required]),
      is_default: new FormControl(is_default),
      visibility: new FormControl(isNew && this.inboxMessageVisibilityForm.value.inbox_message_column_visibility != 1 ? this.inboxMessageVisibilityForm.value.inbox_message_column_visibility : visibility ?? null, [Validators.required]),
      affiliate_id: new FormControl(affiliate_id),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 || this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 3) {
      this.affiliateInboxListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateInboxListDropdownList = this.affiliateDropdownList.filter(x => this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id.includes(x.id));
    }

    if (isNew) {
      this.inboxMessageVisibilityArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.inboxMessageVisibilityArr.controls[index];
        this.inboxMessageVisibilityArr.insert(index+1, currentGroup)
        this.inboxMessageVisibilityArr.removeAt(index);
      })

      this.inboxMessageVisibilityArr.insert(0, form);
      this.assignInboxMessageList();
    } 
    else {
      this.inboxMessageVisibilityArr.push(form);
    }

    let indexInbox = this.inboxMessageVisibilityArr.length - 1;
    if (data !== undefined) {
      this.inboxDropdownList.push(this.inboxDropdownListAll.filter(x => x.id == data.message_template_id)[0]);

      this.inboxDropdownList.sort(function(a, b) { 
        return - (a.id - b.id);
      });

      if (is_default == 1) {
        this.affiliateInboxListSelectedItems[indexInbox] = this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 ? [] : this.affiliateInboxListDropdownList.filter(x => this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id.includes(x.id));
        this.inboxMessageVisibilityArr.controls[indexInbox].patchValue({
          affiliate_id: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 ? [] : this.affiliateInboxListSelectedItems[indexInbox].map(x => x.id),
        });
      }
      else {
        this.affiliateInboxListSelectedItems[indexInbox] = this.affiliateInboxListDropdownList.filter(x => affiliate_id.includes(x.id));
        this.inboxMessageVisibilityArr.controls[indexInbox].patchValue({
          affiliate_id: this.affiliateInboxListSelectedItems[indexInbox].length > 0 ? this.affiliateInboxListSelectedItems[indexInbox].map(x => x.id) : [],
        });
      }

      if (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility != 1) {
        this.inboxMessageVisibilityArr.controls[indexInbox].patchValue({
          visibility: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility,
        });
      }

      // this.affiliateInboxListSelectedItems[indexInbox] = this.affiliateInboxListDropdownList.filter(x => data.affiliate_id.includes(x.id));
      this.inboxMessageListSelectedItems[indexInbox] = this.inboxDropdownList.filter(x => x.id == data.message_template_id);
      this.inboxMessageVisibilityArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.affiliateInboxListSelectedItems.unshift([]);
      this.inboxMessageListSelectedItems.unshift([]);
      this.isOnAddClicked = true;
    }
  }

  onMemberGroupAddRow(isNew = false, data?: any) {
    let isDefaultGroup = false;
    if (data) {
      let group = this.dropdown.memberGroups.find(x => x.id == data.member_group_id);
      isDefaultGroup = group.default > 0 ? true : false;
    }
    this.checkValidation = false;
    let member_group_id = data !== undefined ? data.member_group_id : null;
    let is_default = data !== undefined ? data.is_default : null;
    let visibility = data !== undefined ? (data.is_default && !isDefaultGroup ? this.memberGroupVisibilityForm.value.member_group_column_visibility : data.visibility) : null;
    let affiliate_id = data !== undefined ? data.affiliate_id : [];
    
    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      member_group_id: new FormControl(member_group_id, [Validators.required]),
      is_default: new FormControl(is_default),
      visibility: new FormControl(isNew && this.memberGroupVisibilityForm.value.member_group_column_visibility != 1 ? this.memberGroupVisibilityForm.value.member_group_column_visibility : visibility ?? null, [Validators.required]),
      affiliate_id: new FormControl(affiliate_id),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 || this.memberGroupVisibilityForm.value.member_group_column_visibility == 3) {
      this.affiliateMemberGroupListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateMemberGroupListDropdownList = this.affiliateDropdownList.filter(x => this.memberGroupVisibilityForm.value.member_group_affiliate_id.includes(x.id));
    }

    if (isNew) {
      this.memberGroupVisibilityArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.memberGroupVisibilityArr.controls[index];
        this.memberGroupVisibilityArr.insert(index+1, currentGroup)
        this.memberGroupVisibilityArr.removeAt(index);
      })

      this.memberGroupVisibilityArr.insert(0, form);
      this.assignMemberGroupList();
    } 
    else {
      this.memberGroupVisibilityArr.push(form);
    }

    let indexMemberGroup = this.memberGroupVisibilityArr.length - 1;
    if (data !== undefined) {
      this.memberGroupDropdownList.push(this.memberGroupDropdownListAll.filter(x => x.id == data.member_group_id)[0]);

      this.memberGroupDropdownList.sort(function(a, b) { 
        return - (a.id - b.id);
      });

      if (is_default == 1) {
        this.affiliateMemberGroupListSelectedItems[indexMemberGroup] = this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 ? [] : this.affiliateMemberGroupListDropdownList.filter(x => this.memberGroupVisibilityForm.value.member_group_affiliate_id.includes(x.id));
        this.memberGroupVisibilityArr.controls[indexMemberGroup].patchValue({
          affiliate_id: this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 ? [] : this.affiliateMemberGroupListSelectedItems[indexMemberGroup].map(x => x.id),
        });
      }
      else {
        this.affiliateMemberGroupListSelectedItems[indexMemberGroup] = this.affiliateMemberGroupListDropdownList.filter(x => affiliate_id.includes(x.id));
        this.memberGroupVisibilityArr.controls[indexMemberGroup].patchValue({
          affiliate_id: this.affiliateMemberGroupListSelectedItems[indexMemberGroup].length > 0 ? this.affiliateMemberGroupListSelectedItems[indexMemberGroup].map(x => x.id) : [],
        });
      }

      if (this.memberGroupVisibilityForm.value.member_group_column_visibility != 1) {
        this.memberGroupVisibilityArr.controls[indexMemberGroup].patchValue({
          visibility: !isDefaultGroup ? this.memberGroupVisibilityForm.value.member_group_column_visibility : visibility,
        });
      }

      // this.affiliateMemberGroupListSelectedItems[indexMemberGroup] = this.affiliateMemberGroupListDropdownList.filter(x => data.affiliate_id.includes(x.id));
      this.memberGroupListSelectedItems[indexMemberGroup] = this.memberGroupDropdownList.filter(x => x.id == data.member_group_id);
      this.memberGroupVisibilityArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.affiliateMemberGroupListSelectedItems.unshift([]);
      this.memberGroupListSelectedItems.unshift([]);
      this.isOnAddClicked = true;
    }
  }

  onPromotionAddRow(isNew = false, data?: any) {
    this.checkValidation = false;
    let promo_type = data !== undefined ? data.promo_type : null;
    let promotion_id = data !== undefined ? data.promotion_id : null;
    let visibility = data !== undefined ? data.visibility : null;
    let affiliate_id = data !== undefined ? data.affiliate_id : [];

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      promo_type: new FormControl(promo_type, [Validators.required]),
      promotion_id: new FormControl(promotion_id, [Validators.required]),
      visibility: new FormControl(visibility, [Validators.required]),
      affiliate_id: new FormControl(affiliate_id),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (isNew) {
      this.promotionVisibilityArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.promotionVisibilityArr.controls[index];
        this.promotionVisibilityArr.insert(index+1, currentGroup)
        this.promotionVisibilityArr.removeAt(index);
      })

      this.promotionVisibilityArr.insert(0, form);
    } 
    else {
      this.promotionVisibilityArr.push(form);
    }

    this.affiliatePromotionListDropdownList = this.affiliateDropdownList;
    
    let indexDialog = this.promotionVisibilityArr.length - 1;
    if (data !== undefined) {
      this.promotionDropdownList.push(this.promotionDropdownListAll.filter(x => x.id == data.promotion_id)[0]);
      this.promotionDropdownList.sort(function(a, b) { 
        return - (a.id - b.id);
      });

      this.affiliatePromotionListSelectedItems[indexDialog] = this.affiliatePromotionListDropdownList.filter(x => data.affiliate_id.includes(x.id));
      this.promotionListSelectedItems[indexDialog] = this.promotionDropdownList.filter(x => x.id == data.promotion_id);
      this.promotionVisibilityArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.affiliatePromotionListSelectedItems.unshift([]);
      this.promotionListSelectedItems.unshift([]);
      this.promotionListDropdownList.unshift([]);
      this.isOnAddClicked = true;
    }
  }

  assignPromotionList() {
    let selectedPromo = [];
    selectedPromo = this.promotionVisibilityArr.controls.filter(function(obj) {
      return obj.value.promotion_id != null;
    }).map(function(obj) { return obj.value.promotion_id; });

    this.promotionVisibilityArr.controls.forEach((control, index)=> {
      const exist = selectedPromo.indexOf(parseInt(control.value.promotion_id));

      let promoIds =  [...selectedPromo];
      if (exist > -1) {
        promoIds.splice(exist, 1);
      }

      if (control.value.promo_type != null) {
        this.promotionListDropdownList[index] = this.promotionDropdownList.filter(x => !promoIds.includes(x.id) && x.promo_type == control.value.promo_type);
      }
      else {
        this.promotionListDropdownList[index] = this.promotionDropdownList.filter(x => !promoIds.includes(x.id));
      }
    });
  }

  assignInboxMessageList() {
    let selectedMessage = [];
    selectedMessage = this.inboxMessageVisibilityArr.controls.filter(function(obj) {
      return obj.value.message_template_id != null;
    }).map(function(obj) { return obj.value.message_template_id; });

    this.inboxMessageVisibilityArr.controls.forEach((control, index)=> {
      const exist = selectedMessage.indexOf(parseInt(control.value.message_template_id));

      let msgIds =  [...selectedMessage];
      if (exist > -1) {
        msgIds.splice(exist, 1);
      }

      this.inboxMessageListDropdownList[index] = this.inboxDropdownList.filter(x => !msgIds.includes(x.id));
    });
  }

  assignMemberGroupList() {
    let selectedMemberGroup = [];
    selectedMemberGroup = this.memberGroupVisibilityArr.controls.filter(function(obj) {
      return obj.value.member_group_id != null;
    }).map(function(obj) { return obj.value.member_group_id; });

    this.memberGroupVisibilityArr.controls.forEach((control, index)=> {
      const exist = selectedMemberGroup.indexOf(parseInt(control.value.member_group_id));

      let groupIds =  [...selectedMemberGroup];
      if (exist > -1) {
        groupIds.splice(exist, 1);
      }

      this.memberGroupListDropdownList[index] = this.memberGroupDropdownList.filter(x => !groupIds.includes(x.id));
    });
  }

  enableEdit(index: number) {
    this.backUpPopupDialogArr = ({...this.dialogPopupVisibilityArr.controls[index].value});
    this.dialogPopupVisibilityArr.controls.forEach(control => {
      control.disable();
    });
    this.dialogPopupVisibilityArr.controls[index].enable();
    this.cdr.detectChanges();
  }

  enableInboxEdit(index: number) {
    this.backUpInboxMessageArr = ({...this.inboxMessageVisibilityArr.controls[index].value});
    this.inboxMessageVisibilityArr.controls.forEach(control => {
      control.disable();
    });
    this.inboxMessageVisibilityArr.controls[index].enable();
    this.cdr.detectChanges();
  }

  enableMemberGroupEdit(index: number) {
    this.backUpMemberGroupArr = ({...this.memberGroupVisibilityArr.controls[index].value});
    this.memberGroupVisibilityArr.controls.forEach(control => {
      control.disable();
    });
    this.memberGroupVisibilityArr.controls[index].enable();
    this.cdr.detectChanges();
  }

  enablePromotionEdit(index: number) {
    this.backUpPromotionArr = ({...this.promotionVisibilityArr.controls[index].value});
    this.promotionVisibilityArr.controls.forEach(control => {
      control.disable();
    });
    this.promotionVisibilityArr.controls[index].enable();
    this.cdr.detectChanges();
  }
  
  onDismissRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
        this.dialogPopupVisibilityArr.removeAt(index);
        this.affiliateDialogListSelectedItems.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.dialogPopupVisibilityArr.controls[index].patchValue({ 
          ...this.backUpPopupDialogArr,
        })
        if (this.backUpPopupDialogArr['visibility'] == 1) {
          this.affiliateDialogListSelectedItems[index] = [];
          this.dialogPopupVisibilityArr.controls[index].patchValue({ 
            affiliate_id: [],
          })
        }
        else {
          this.affiliateDialogListSelectedItems[index] = this.affiliateDialogListDropdownList.filter(x => this.backUpPopupDialogArr.affiliate_id.includes(x.id));
        }

        this.dialogPopupVisibilityArr.controls[index].disable();
        break;
    }
    this.cdr.detectChanges();
  }

  onDismissInboxRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
        this.inboxMessageVisibilityArr.removeAt(index);
        this.affiliateInboxListSelectedItems.shift();
        this.inboxMessageListDropdownList.shift();
        this.inboxMessageListSelectedItems.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.inboxMessageVisibilityArr.controls[index].patchValue({ 
          ...this.backUpInboxMessageArr,
        })
        if (this.backUpInboxMessageArr['visibility'] == 1) {
          this.affiliateInboxListSelectedItems[index] = [];
          this.inboxMessageVisibilityArr.controls[index].patchValue({ 
            affiliate_id: [],
          })
        }
        else {
          this.affiliateInboxListSelectedItems[index] = this.affiliateInboxListDropdownList.filter(x => this.backUpInboxMessageArr.affiliate_id.includes(x.id));
        }

        this.inboxMessageListSelectedItems[index] = this.inboxDropdownList.filter(x => x.id == this.backUpInboxMessageArr.message_template_id);
        this.assignInboxMessageList();
        this.inboxMessageVisibilityArr.controls[index].disable();
        break;
    }
    this.cdr.detectChanges();
  }

  onDismissMemberGroupRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
        this.memberGroupVisibilityArr.removeAt(index);
        this.affiliateMemberGroupListSelectedItems.shift();
        this.memberGroupListDropdownList.shift();
        this.memberGroupListSelectedItems.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.memberGroupVisibilityArr.controls[index].patchValue({ 
          ...this.backUpMemberGroupArr,
        })
        if (this.backUpMemberGroupArr['visibility'] == 1) {
          this.affiliateMemberGroupListSelectedItems[index] = [];
          this.memberGroupVisibilityArr.controls[index].patchValue({ 
            affiliate_id: [],
          })
        }
        else {
          this.affiliateMemberGroupListSelectedItems[index] = this.affiliateMemberGroupListDropdownList.filter(x => this.backUpMemberGroupArr.affiliate_id.includes(x.id));
        }

        this.memberGroupListSelectedItems[index] = this.memberGroupDropdownList.filter(x => x.id == this.backUpMemberGroupArr.member_group_id);
        this.assignMemberGroupList();
        this.memberGroupVisibilityArr.controls[index].disable();
        break;
    }
    this.cdr.detectChanges();
  }

  onDismissPromotionRow(mode: string, index: number) {
    this.isDisabledPromotionSubmit = true;
    switch (mode) {
      case 'create':
        this.promotionVisibilityArr.removeAt(index);
        this.affiliatePromotionListSelectedItems.shift();
        this.promotionListDropdownList.shift();
        this.promotionListSelectedItems.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.promotionVisibilityArr.controls[index].patchValue({ 
          ...this.backUpPromotionArr,
        })
        
        if (this.backUpPromotionArr['visibility'] == 1) {
          this.affiliatePromotionListSelectedItems[index] = [];
          this.promotionVisibilityArr.controls[index].patchValue({ 
            affiliate_id: [],
          })
        }
        else {
          this.affiliatePromotionListSelectedItems[index] = this.affiliatePromotionListDropdownList.filter(x => this.backUpPromotionArr.affiliate_id.includes(x.id));
        }

        this.promotionListSelectedItems[index] = this.promotionDropdownList.filter(x => x.id == this.backUpPromotionArr.promotion_id);
        this.assignPromotionList();
        this.promotionVisibilityArr.controls[index].disable();
        break;
    }
    this.cdr.detectChanges();
  }

  onPopupDialogSave(row: any, index?: number,) {
    this.form.setErrors({ 'invalid': true });
    this.checkValidation = true;
    const dialogListData = row.mode === 'create' ? this.filterFormFields(row) : row;
    dialogListData['affiliate_group_id'] = this.data.groups.id;

    const dialogPopupVisibilityData = {
      dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
    }
    if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
      dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
    }

    if (this.currentActiveTab == 3) {  // Affiliate Dialog Popup Settings
      this.subscription = forkJoin([
        this.affiliateGroupsDataService.createUpdateDialogPopupVisibilityList(row.mode, dialogListData),
      ]).pipe(
        tap(() => {
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe(res => {
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData),
        ]).pipe(
          tap(() => {
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.dialogPopupVisibilitySettings = {
            ...this.data.dialogPopupVisibilitySettings,
            dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
            affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
          }
          this.formInit();
        });
      });
    }
  }

  onInboxMessageSave(row: any, index?: number,) {
    this.form.setErrors({ 'invalid': true });
    this.checkValidation = true;
    const inboxListData = row.mode === 'create' ? this.filterFormFields(row) : row;
    inboxListData['affiliate_group_id'] = this.data.groups.id;

    const inboxMessageVisibilityData = {
      inbox_message_column_visibility: this.inboxMessageVisibilityForm.get('inbox_message_toggle').value ? +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value : 0,
    }
    if (this.inboxMessageVisibilityForm.get('inbox_message_toggle').value && +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value > 1) {
      inboxMessageVisibilityData['affiliate_id'] = this.inboxMessageVisibilityForm.get('inbox_message_affiliate_id').value;
    }

    if (this.currentActiveTab == 4) {  // Affiliate Inbox Message Settings
      this.subscription = forkJoin([
        this.affiliateGroupsDataService.createUpdateInboxMessageVisibilityList(row.mode, inboxListData),
      ]).pipe(
        tap(() => {
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe(res => {
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateInboxMessageVisibilitySettings(this.data.groups.id, inboxMessageVisibilityData),
        ]).pipe(
          tap(() => {
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.inboxMessageVisibilitySettings = {
            ...this.data.inboxMessageVisibilitySettings,
            inbox_message_column_visibility: inboxMessageVisibilityData['inbox_message_column_visibility'],
            affiliate_id: inboxMessageVisibilityData['affiliate_id'] != undefined ? inboxMessageVisibilityData['affiliate_id'] : this.data.inboxMessageVisibilitySettings.affiliate_id,
          }
          this.formInit();
        });
      });
    }
  }

  onMemberGroupSave(row: any, index?: number,) {
    this.form.setErrors({ 'invalid': true });
    this.checkValidation = true;
    const memberGroupListData = row.mode === 'create' ? this.filterFormFields(row) : row;
    memberGroupListData['affiliate_group_id'] = this.data.groups.id;

    const memberGroupVisibilityData = {
      member_group_column_visibility: this.memberGroupVisibilityForm.get('member_group_toggle').value ? +this.memberGroupVisibilityForm.get('member_group_column_visibility').value : 0,
    }
    if (this.memberGroupVisibilityForm.get('member_group_toggle').value && +this.memberGroupVisibilityForm.get('member_group_column_visibility').value > 1) {
      memberGroupVisibilityData['affiliate_id'] = this.memberGroupVisibilityForm.get('member_group_affiliate_id').value;
    }

    if (this.currentActiveTab == 5) {  // Affiliate Dialog Popup Settings
      this.subscription = forkJoin([
        this.affiliateGroupsDataService.createUpdateMemberGroupVisibilityList(row.mode, memberGroupListData),
      ]).pipe(
        tap(() => {
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe(res => {
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateMemberGroupVisibilitySettings(this.data.groups.id, memberGroupVisibilityData),
        ]).pipe(
          tap(() => {
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.memberGroupVisibilitySettings = {
            ...this.data.memberGroupVisibilitySettings,
            member_group_column_visibility: memberGroupVisibilityData['member_group_column_visibility'],
            affiliate_id: memberGroupVisibilityData['affiliate_id'] != undefined ? memberGroupVisibilityData['affiliate_id'] : this.data.memberGroupVisibilitySettings.affiliate_id,
          }
          this.formInit();
        });
      });
    }
  }

  onPromotionSave(row: any, index?: number,) {
    this.checkValidation = true;
    this.isDisabledPromotionSubmit = true;
    const promotionListData = row.mode === 'create' ? this.filterFormFields(row) : row;
    promotionListData['affiliate_group_id'] = this.data.groups.id;

    if (this.currentActiveTab == 2) {  // Affiliate Promotion Settings
      this.subscription = forkJoin([
        this.affiliateGroupsDataService.createUpdatePromotionVisibilityList(row.mode, promotionListData),
      ]).pipe(
        tap(() => {
          this.isDisabledPromotionSubmit = true;
        }),
        catchError((error) => {
          this.isDisabledPromotionSubmit = true;
          throw error;
        })
      ).subscribe(res => {
        this.isDisabledPromotionSubmit = true;
        this.formInit();
      });
    }
  }

  onPopupDialogDelete(id: any) {
    Swal.fire({
      title: 'System Alert',
      html: '<div>Are you sure you want to delete?<br><p style="color: red">This action will remove the associated dialog popup from the affiliate campaign settings.</p></div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.form.setErrors({ 'invalid': true });
        this.checkValidation = true;
        this.buttonLoading = true;

        const dialogPopupVisibilityData = {
          dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
        }
        
        if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
          dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
        }

        this.subscription = forkJoin([
          this.affiliateGroupsDataService.deleteDialogPopupVisibilityList(id),
        ]).pipe(
          tap(() => {
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.subscription = forkJoin([
            this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData)
          ]).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ).subscribe(res => {
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
          });
        });
      }
    });
  }

  onInboxMessageDelete(id: any) {
    Swal.fire({
      title: 'System Alert',
      html: '<div>Are you sure you want to delete?<br><p style="color: red">This action will remove the associated inbox message from the affiliate campaign settings.</p></div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.form.setErrors({ 'invalid': true });
        this.checkValidation = true;
        this.buttonLoading = true;

        const inboxMessageVisibilityData = {
          inbox_message_column_visibility: this.inboxMessageVisibilityForm.get('inbox_message_toggle').value ? +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value : 0,
        }
        
        if (this.inboxMessageVisibilityForm.get('inbox_message_toggle').value && +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value > 1) {
          inboxMessageVisibilityData['affiliate_id'] = this.inboxMessageVisibilityForm.get('inbox_message_affiliate_id').value;
        }

        this.subscription = forkJoin([
          this.affiliateGroupsDataService.deleteInboxMessageVisibilityList(id),
        ]).pipe(
          tap(() => {
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.subscription = forkJoin([
            this.affiliateGroupsDataService.updateInboxMessageVisibilitySettings(this.data.groups.id, inboxMessageVisibilityData)
          ]).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ).subscribe(res => {
            this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
          });
        });
      }
    });
  }

  onMemberGroupDelete(id: any) {
    Swal.fire({
      title: 'System Alert',
      html: '<div>Are you sure you want to delete?<br><p style="color: red">This action will remove the associated member group from the affiliate campaign settings.</p></div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.form.setErrors({ 'invalid': true });
        this.checkValidation = true;
        this.buttonLoading = true;

        const memberGroupVisibilityData = {
          member_group_column_visibility: this.memberGroupVisibilityForm.get('member_group_toggle').value ? +this.memberGroupVisibilityForm.get('member_group_column_visibility').value : 0,
        }
        
        if (this.memberGroupVisibilityForm.get('member_group_toggle').value && +this.memberGroupVisibilityForm.get('member_group_column_visibility').value > 1) {
          memberGroupVisibilityData['affiliate_id'] = this.memberGroupVisibilityForm.get('member_group_affiliate_id').value;
        }

        this.subscription = forkJoin([
          this.affiliateGroupsDataService.deleteMemberGroupVisibilityList(id),
        ]).pipe(
          tap(() => {
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.subscription = forkJoin([
            this.affiliateGroupsDataService.updateMemberGroupVisibilitySettings(this.data.groups.id, memberGroupVisibilityData)
          ]).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ).subscribe(res => {
            this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
          });
        });
      }
    });
  }

  onPromotionDelete(id: any) {
    Swal.fire({
      title: 'System Alert',
      html: '<div>Are you sure you want to delete?<br><p style="color: red">This action will remove the associated promotion from the affiliate campaign settings.</p></div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.form.setErrors({ 'invalid': true });
        this.checkValidation = true;
        this.buttonLoading = true;

        this.subscription = forkJoin([
          this.affiliateGroupsDataService.deletePromotionVisibilityList(id)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.getPromotionVisibility(this.page, this.pageSize, this.params);
        });
      }
    });
  }

  onPerPage(size: Event) {
    this.tabLoading = true;
    this.pageSize = +(size.target as HTMLSelectElement).value;

    if (this.currentActiveTab == 2) {
      this.page = 1;
      this.getPromotionVisibility(this.page, this.pageSize, this.params);
    }
    else if (this.currentActiveTab == 3) {
      this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
    }
    else if (this.currentActiveTab == 4) {
      this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
    }
    else if (this.currentActiveTab == 5) {
      this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
    }
    else {
      this.tabLoading = false;
    }
  }

  onPerPageAffInboxMessage(size: Event) {
    this.pageSizeAff = +(size.target as HTMLSelectElement).value;
    this.onViewPageByAffInboxMessage(this.pageAff, this.pageSizeAff, this.paramsAff);
  }

  onViewPageBy(page = 1, pageSize?: number) {
    this.tabLoading = true;
    this.page = page;

    if (this.currentActiveTab == 2) {
      this.getPromotionVisibility(this.page, this.pageSize, this.params);
    }
    else if (this.currentActiveTab == 3) {
      this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
    }
    else if (this.currentActiveTab == 4) {
      this.getInboxMessageVisibility(this.page, this.pageSize, this.params);
    }
    else if (this.currentActiveTab == 5) {
      this.getMemberGroupVisibility(this.page, this.pageSize, this.params);
    }
    else {
      this.tabLoading = false;
    }
  }

  onViewPageByAffInboxMessage(page = 1, pageSize?: number, params?: string) {
    this.loadingAff = true;
    pageSize = this.pageSizeAff;
    params = this.paramsAff ? `&${this.paramsAff}` : '';
    this.loadingBar.useRef().start();
    return this.affiliateMessageTemplates$ = this.affiliateMessageTemplateEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&affiliate_group_id=${this.data.groups.id}${params}`).pipe(
      tap(res => {
        this.paginationAff = this.affiliateMessageTemplateDataService.pagination;
        this.loadingAff = false;
        this.dataLengthAff = res.length;
        this.loadingBar.useRef().complete();
      })
    );
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }

  getLocaleName (index: number) {
    if (this.dialogPopupVisibilityArr.controls[index].value.popup_id == null) {
      return '-';
    }
    else {
      return this.popupDropdownList.find(x => x.id == this.dialogPopupVisibilityArr.controls[index].value.popup_id).locale_name;
    }
  }

  getInboxLocaleName (index: number) {
    if (this.inboxMessageVisibilityArr.controls[index].value.message_template_id == null) {
      return '-';
    }
    else {
      let localeName = this.inboxDropdownList.find(x => x.id == this.inboxMessageVisibilityArr.controls[index].value.message_template_id).locales;

      if (localeName) {
        return localeName;
      }
      else {
        return '-';
      } 
    }
  }

  getMemberGroupType(index: number, type: string) {
    switch(type) {
      case 'default':
        return +this.memberGroupDropdownList.find(x => x.id == this.memberGroupVisibilityArr.controls[index].value.member_group_id).default;
      case 'trial':
        return +this.memberGroupDropdownList.find(x => x.id == this.memberGroupVisibilityArr.controls[index].value.member_group_id).trial;
      case 'vip':
        return +this.memberGroupDropdownList.find(x => x.id == this.memberGroupVisibilityArr.controls[index].value.member_group_id).vip;
    }
  }

  getCurrencyName(index: number) {
    if (this.promotionVisibilityArr.controls[index].value.promotion_id == null) {
      return '-';
    }
    else {
      let currencyName = this.promotionDropdownList.find(x => x.id == this.promotionVisibilityArr.controls[index].value.promotion_id).settings_currency_name;
      return currencyName == null || currencyName == '' ? '-' : currencyName;
    }
  }

  getPromotionName(index: number) {
    if (this.promotionVisibilityArr.controls[index].value.promotion_id == null) {
      return '-';
    }
    else {
      return this.promotionDropdownList.find(x => x.id == this.promotionVisibilityArr.controls[index].value.promotion_id).name;
    }
  }

  getPromotionStatus(index: number) {
    if (this.promotionVisibilityArr.controls[index].value.promotion_id == null) {
      return '-';
    }
    else {
      return this.promotionDropdownList.find(x => x.id == this.promotionVisibilityArr.controls[index].value.promotion_id).status;
    }
  }

  getMemberGroupStatus(index: number) {
    if (this.memberGroupVisibilityArr.controls[index].value.member_group_id == null) {
      return '-';
    }
    else {
      return this.memberGroupDropdownList.find(x => x.id == this.memberGroupVisibilityArr.controls[index].value.member_group_id).status;
    }
  }

  onPromoTypeDropdown(index: number) {
    this.promotionVisibilityArr.controls[index].patchValue({
      promotion_id: null,
    })

    this.promotionListSelectedItems[index] = [];
    this.assignPromotionList();
  }

  onSelectedAffiliateDialogListItems($event: any, index: any) {
    if ($event.length == 0) {
      this.affiliateDialogListSelectedItems[index] = [];
    }
    else {
      this.affiliateDialogListSelectedItems[index] = $event;
    }
  }

  onSelectedAffiliateInboxListItems($event: any, index: any) {
    if ($event.length == 0) {
      this.affiliateInboxListSelectedItems[index] = [];
    }
    else {
      this.affiliateInboxListSelectedItems[index] = $event;
    }
  }

  onSelectedAffiliateMemberGroupListItems($event: any, index: any) {
    if ($event.length == 0) {
      this.affiliateMemberGroupListSelectedItems[index] = [];
    }
    else {
      this.affiliateMemberGroupListSelectedItems[index] = $event;
    }
  }

  onSelectedAffiliatePromotionListItems($event: any, index: any) {
    if ($event.length == 0) {
      this.affiliatePromotionListSelectedItems[index] = [];
    }
    else {
      this.affiliatePromotionListSelectedItems[index] = $event;
    }
  }

  getPopupDropdownList(popId: any) {
    let selectedPopup = [];
    this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.popup_id !== null ) {
        selectedPopup.push(parseInt(control.value.popup_id));
      }
    });

    const index = selectedPopup.indexOf(parseInt(popId));
    if (index > -1) {
      selectedPopup.splice(index, 1);
    }

    return this.popupDropdownList.filter(x => !selectedPopup.includes(x.id));
  }

  getInboxDropdownList(inboxId: any) {
    let selectedInbox = [];
    this.inboxMessageVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.popup_id !== null ) {
        selectedInbox.push(parseInt(control.value.message_template_id));
      }
    });

    const index = selectedInbox.indexOf(parseInt(inboxId));
    if (index > -1) {
      selectedInbox.splice(index, 1);
    }

    return this.inboxDropdownList.filter(x => !selectedInbox.includes(x.id));
  }

  getMemberGroupDropdownList(memberGroupId: any) {
    let selectedMemberGroup = [];
    this.memberGroupVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.popup_id !== null ) {
        selectedMemberGroup.push(parseInt(control.value.member_group_id));
      }
    });

    const index = selectedMemberGroup.indexOf(parseInt(memberGroupId));
    if (index > -1) {
      selectedMemberGroup.splice(index, 1);
    }

    return this.memberGroupDropdownList.filter(x => !selectedMemberGroup.includes(x.id));
  }

  getPromotionDropdownList(promoType: any, promoId: any) {
    let selectedPromo = [];
    this.promotionVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.promotion_id !== null ) {
        selectedPromo.push(parseInt(control.value.promotion_id));
      }
    });

    const index = selectedPromo.indexOf(parseInt(promoId));
    if (index > -1) {
      selectedPromo.splice(index, 1);
    }

    if (promoType) {
      return this.promotionDropdownList.filter(x => !selectedPromo.includes(x.id) && x.promo_type == promoType);
    }
    else {
      return this.promotionDropdownList.filter(x => !selectedPromo.includes(x.id));
    }
  }

  validationDialogList(i?: any) {
    if (i != undefined) {
      const dialogArr = ({...this.dialogPopupVisibilityArr.controls[i].value});
      if (dialogArr.popup_id !== null && dialogArr.visibility !== null) {
        if (dialogArr.is_default) {
          return false;
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }  
    }
    else {
      const statues = this.dialogPopupVisibilityArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  validationInboxList(i?: any) {
    if (i != undefined) {
      const inboxArr = ({...this.inboxMessageVisibilityArr.controls[i].value});
      if (inboxArr.message_template_id !== null && inboxArr.visibility !== null) {
        if (inboxArr.is_default) {
          return false;
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }  
    }
    else {
      const statues = this.inboxMessageVisibilityArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  validationMemberGroupList(i?: any) {
    if (i != undefined) {
      const memberGroupArr = ({...this.memberGroupVisibilityArr.controls[i].value});
      if (memberGroupArr.member_group_id !== null && memberGroupArr.visibility !== null) {
        if (memberGroupArr.is_default) {
          return false;
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }  
    }
    else {
      const statues = this.memberGroupVisibilityArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  validationPromotionList(i?: any) {
    if (i != undefined) {
      const promotionArr = ({...this.promotionVisibilityArr.controls[i].value});
      if (promotionArr.promotion_id !== null && promotionArr.visibility !== null) {
          this.isDisabledPromotionSubmit = false;
          return false;
      }
      else {
        this.isDisabledPromotionSubmit = true;
        return true;
      }  
    }
    else {
      const statues = this.promotionVisibilityArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  isPopupDialogDefault(i: any) {
    const isDefault = this.dialogPopupVisibilityArr.controls[i].value.is_default;
    if (this.dialogPopupVisibilityArr.controls[i].disabled) {
      return true;
    }
    else {
      if (isDefault == true || isDefault == 1) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  isInboxMessageDefault(i: any) {
    const isDefault = this.inboxMessageVisibilityArr.controls[i].value.is_default;
    if (this.inboxMessageVisibilityArr.controls[i].disabled) {
      return true;
    }
    else {
      if (isDefault == true || isDefault == 1) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  isMemberGroupDefault(i: any) {
    const isDefault = this.memberGroupVisibilityArr.controls[i].value.is_default;
    if (this.memberGroupVisibilityArr.controls[i].disabled) {
      return true;
    }
    else {
      if (isDefault == true || isDefault == 1) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onChangePopupDialogDefault(event: any, i: any) {
    if (event.target.checked) {
      this.dialogPopupVisibilityArr.controls[i].patchValue({ 
        visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
        affiliate_id: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.dialogPopupVisibilityForm.value.dialog_affiliate_id,
      });
      this.affiliateDialogListSelectedItems[i] = this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDropdownList.filter(x => this.dialogPopupVisibilityForm.value.dialog_affiliate_id.includes(x.id));
      this.cdr.detectChanges();
    }
    else {
      if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1) {
        this.dialogPopupVisibilityArr.controls[i].patchValue({ 
          visibility: null,
          affiliate_id: [],
        });
      }
      else {
        this.dialogPopupVisibilityArr.controls[i].patchValue({ 
          visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
          affiliate_id: [],
        });
      }
      this.affiliateDialogListSelectedItems[i] = [];
      this.cdr.detectChanges();
    }
  }

  onChangeInboxMessageDefault(event: any, i: any) {
    if (event.target.checked) {
      this.inboxMessageVisibilityArr.controls[i].patchValue({ 
        visibility: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility,
        affiliate_id: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 ? [] : this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id,
      });
      this.affiliateInboxListSelectedItems[i] = this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 ? [] : this.affiliateDropdownList.filter(x => this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id.includes(x.id));
      this.cdr.detectChanges();
    }
    else {
      if (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1) {
        this.inboxMessageVisibilityArr.controls[i].patchValue({ 
          visibility: null,
          affiliate_id: [],
        });
      }
      else {
        this.inboxMessageVisibilityArr.controls[i].patchValue({ 
          visibility: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility,
          affiliate_id: [],
        });
      }
      this.affiliateInboxListSelectedItems[i] = [];
      this.cdr.detectChanges();
    }
  }

  onChangeMemberGroupDefault(event: any, i: any) {
    if (event.target.checked) {
      this.memberGroupVisibilityArr.controls[i].patchValue({ 
        visibility: this.memberGroupVisibilityForm.value.member_group_column_visibility,
        affiliate_id: this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 ? [] : this.memberGroupVisibilityForm.value.member_group_affiliate_id,
      });
      this.affiliateMemberGroupListSelectedItems[i] = this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 ? [] : this.affiliateDropdownList.filter(x => this.memberGroupVisibilityForm.value.member_group_affiliate_id.includes(x.id));
      this.cdr.detectChanges();
    }
    else {
      if (this.memberGroupVisibilityForm.value.member_group_column_visibility == 1) {
        this.memberGroupVisibilityArr.controls[i].patchValue({ 
          visibility: null,
          affiliate_id: [],
        });
      }
      else {
        this.memberGroupVisibilityArr.controls[i].patchValue({ 
          visibility: this.memberGroupVisibilityForm.value.member_group_column_visibility,
          affiliate_id: [],
        });
      }
      this.affiliateMemberGroupListSelectedItems[i] = [];
      this.cdr.detectChanges();
    }
  }

  checkValidationAffiliateDialogList(i?: any) {
    if (!this.loading) {
      if (i !== undefined) {
        if ((!this.dialogPopupVisibilityArr.controls[i].value.is_default && this.affiliateDialogListSelectedItems[i].length == 0) ||
          (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 && this.dialogPopupVisibilityForm.value.dialog_affiliate_id.length == 0)) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 && this.dialogPopupVisibilityForm.value.dialog_affiliate_id.length == 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  checkValidationAffiliateInboxList(i?: any) {
    if (!this.loading) {
      if (i !== undefined) {
        if ((!this.inboxMessageVisibilityArr.controls[i].value.is_default && this.affiliateInboxListSelectedItems[i].length == 0) ||
          (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility != 1 && this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id.length == 0)) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        if (this.inboxMessageVisibilityForm.value.inbox_message_column_visibility != 1 && this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id.length == 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  checkValidationAffiliateMemberGroupList(i?: any) {
    if (!this.loading) {
      if (i !== undefined) {
        if ((!this.memberGroupVisibilityArr.controls[i].value.is_default && this.affiliateMemberGroupListSelectedItems[i].length == 0) ||
          (this.memberGroupVisibilityForm.value.member_group_column_visibility != 1 && this.memberGroupVisibilityForm.value.member_group_affiliate_id.length == 0)) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        if (this.memberGroupVisibilityForm.value.member_group_column_visibility != 1 && this.memberGroupVisibilityForm.value.member_group_affiliate_id.length == 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  checkValidationAffiliatePromotionList(i?: any) {
    if (!this.loading) {
      if (i !== undefined) {
        if (this.affiliatePromotionListSelectedItems[i].length == 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  onChangePopupDialogVisbility(event: any, i: any) {
    if (event.target.value) {
      this.dialogPopupVisibilityArr.controls[i].patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.dialogPopupVisibilityArr.controls[i].patchValue({ 
          affiliate_id: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.dialogPopupVisibilityForm.value.dialog_affiliate_id,
        });
  
        this.affiliateDialogListSelectedItems[i] = this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDialogSelectedList;
      }
    }
    else {
      this.dialogPopupVisibilityArr.controls[i].patchValue({ 
        visibility: null,
      });
    }
  }

  onChangeInboxMessageVisbility(event: any, i: any) {
    if (event.target.value) {
      this.inboxMessageVisibilityArr.controls[i].patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.inboxMessageVisibilityArr.controls[i].patchValue({ 
          affiliate_id: this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 ? [] : this.inboxMessageVisibilityForm.value.inbox_message_affiliate_id,
        });
  
        this.affiliateInboxListSelectedItems[i] = this.inboxMessageVisibilityForm.value.inbox_message_column_visibility == 1 ? [] : this.affiliateInboxSelectedList;
      }
    }
    else {
      this.inboxMessageVisibilityArr.controls[i].patchValue({ 
        visibility: null,
      });
    }
  }

  onChangeMemberGroupVisbility(event: any, i: any) {
    if (event.target.value) {
      this.memberGroupVisibilityArr.controls[i].patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.memberGroupVisibilityArr.controls[i].patchValue({ 
          affiliate_id: this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 ? [] : this.memberGroupVisibilityForm.value.member_group_affiliate_id,
        });
  
        this.affiliateMemberGroupListSelectedItems[i] = this.memberGroupVisibilityForm.value.member_group_column_visibility == 1 ? [] : this.affiliateMemberGroupSelectedList;
      }
    }
    else {
      this.memberGroupVisibilityArr.controls[i].patchValue({ 
        visibility: null,
      });
    }
  }

  onChangePromotionVisbility(event: any, i: any) {
    if (event.target.value) {
      this.promotionVisibilityArr.controls[i].patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.promotionVisibilityArr.controls[i].patchValue({ 
          affiliate_id: [],
        });
  
        this.affiliatePromotionListSelectedItems[i] = [];
      }
    }
    else {
      this.promotionVisibilityArr.controls[i].patchValue({ 
        visibility: null,
      });
    }
  }

  onBulkAddDialog() {
    const dialogRef = this.dialog.open(AffiliateGroupsDialogBulkAddComponent, {
      width: '800px',
      data: {
        mode: this.data.mode,
        groups: this.data.groups,
        dialogPopupVisibilitySettings: this.dialogPopupVisibilityForm.value,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        const dialogPopupVisibilityData = {
          dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
        }
        if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
          dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
        }
        
        this.data.dialogPopupVisibilitySettings = {
          ...this.data.dialogPopupVisibilitySettings,
          dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
          affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
        }
        this.formInit();
      }
    });
  }

  onBulkAddInbox() {
    const dialogRef = this.dialog.open(AffiliateGroupsInboxBulkAddComponent, {
      width: '800px',
      data: {
        mode: this.data.mode,
        groups: this.data.groups,
        inboxMessageVisibilitySettings: this.inboxMessageVisibilityForm.value,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        const inboxMessageVisibilityData = {
          inbox_message_column_visibility: this.inboxMessageVisibilityForm.get('inbox_message_toggle').value ? +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value : 0,
        }
        if (this.inboxMessageVisibilityForm.get('inbox_message_toggle').value && +this.inboxMessageVisibilityForm.get('inbox_message_column_visibility').value > 1) {
          inboxMessageVisibilityData['affiliate_id'] = this.inboxMessageVisibilityForm.get('inbox_message_affiliate_id').value;
        }
        
        this.data.inboxMessageVisibilitySettings = {
          ...this.data.inboxMessageVisibilitySettings,
          inbox_message_column_visibility: inboxMessageVisibilityData['inbox_message_column_visibility'],
          affiliate_id: inboxMessageVisibilityData['affiliate_id'] != undefined ? inboxMessageVisibilityData['affiliate_id'] : this.data.inboxMessageVisibilitySettings.affiliate_id,
        }
        this.formInit();
      }
    });
  }

  onBulkAddMemberGroup() {
    const dialogRef = this.dialog.open(AffiliateGroupsMemberGroupBulkAddComponent, {
      width: '800px',
      data: {
        mode: this.data.mode,
        groups: this.data.groups,
        memberGroupVisibilitySettings: this.memberGroupVisibilityForm.value,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        const memberGroupVisibilityData = {
          member_group_column_visibility: this.memberGroupVisibilityForm.get('member_group_toggle').value ? +this.memberGroupVisibilityForm.get('member_group_column_visibility').value : 0,
        }
        if (this.memberGroupVisibilityForm.get('member_group_toggle').value && +this.memberGroupVisibilityForm.get('member_group_column_visibility').value > 1) {
          memberGroupVisibilityData['affiliate_id'] = this.memberGroupVisibilityForm.get('member_group_affiliate_id').value;
        }
        
        this.data.memberGroupVisibilitySettings = {
          ...this.data.memberGroupVisibilitySettings,
          member_group_column_visibility: memberGroupVisibilityData['member_group_column_visibility'],
          affiliate_id: memberGroupVisibilityData['affiliate_id'] != undefined ? memberGroupVisibilityData['affiliate_id'] : this.data.memberGroupVisibilitySettings.affiliate_id,
        }
        this.formInit();
      }
    });
  }

  onBulkAddPromotion() {
    const dialogRef = this.dialog.open(AffiliateGroupsPromotionBulkAddComponent, {
      width: '800px',
      data: {
        mode: this.data.mode,
        groups: this.data.groups,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.formInit();
      }
    });
  }
}
