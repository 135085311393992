<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div  class="kt-portlet kt-portlet--mobile">
        <div  class="kt-portlet__body">
            <mat-tab-group>
                <mat-tab *ngIf="canViewBankingTab">
                    <!-- Banking Tab -->
                    <ng-template mat-tab-label>
                        Banking
                        <span *ngIf="totalBanking > 0" class="tab-badge-header text-light ml-2">{{ totalBanking }}</span>
                    </ng-template>
                    <kt-deposits 
                        *ngIf="(merchantBankAccountsBanking$ | async)"
                        type="banking"
                        [merchantBankAccounts]="merchantBankAccountsBanking$ | async"
                        [memberGroups]="groups">
                    </kt-deposits>
                </mat-tab>
                <mat-tab *ngIf="canViewCryptoTab">
                    <!-- Crypto Tab -->
                    <ng-template mat-tab-label>
                        Crypto
                        <span *ngIf="totalCrypto > 0" class="tab-badge-header text-light ml-2">{{ totalCrypto }}</span>
                    </ng-template>
                    <kt-deposits 
                        *ngIf="(merchantBankAccountsCrypto$ | async)"
                        type="crypto"
                        [merchantBankAccounts]="merchantBankAccountsCrypto$ | async"
                        [memberGroups]="groups">
                    </kt-deposits>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>