import { Subscription } from 'rxjs/internal/Subscription';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { of, forkJoin } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { AffiliateGroupsEditDialogComponent } from './dialogs/affiliate-groups-edit/affiliate-groups-edit.component';
import { CommissionSettingsDialogComponent } from './dialogs/commission-settings/commission-settings.component';
import { CurrencySettingsDialogComponent } from './dialogs/currency-settings/currency-settings.component';
import { SettlementSettingsComponent } from './dialogs/settlement-settings/settlement-settings.component';
import { AffiliateGroupsDataService } from './services/affiliate-groups-data.service';
import * as moment from 'moment-timezone';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'kt-affiliate-groups',
  templateUrl: './affiliate-groups.component.html',
  styleUrls: ['./affiliate-groups.component.scss']
})
export class AffiliateGroupsComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  }
  affGroups$ = [];
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  default = {
    month: moment(this.transactionHttpService.getYesterday().from).format('MM'),
    year: moment(this.transactionHttpService.getYesterday().from).format('YYYY')
  };

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canCreateAffiliateGroup: boolean;
  canViewSettings: boolean;
  canConfigureCommissionSettings: boolean;
  canConfigureCurrencySettings: boolean;
  canConfigureSettlementSettings: boolean;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.affiliateGroupsDataService.pagination;
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateAffiliateGroup = appPermissions.create_affiliate_group;
      this.canViewSettings = appPermissions.view_settings;
      this.canConfigureCommissionSettings = appPermissions.commission_settings;
      this.canConfigureCurrencySettings = appPermissions.currency_settings;
      this.canConfigureSettlementSettings = appPermissions.settlement_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        if(data['defaultDate']){
          delete data['defaultDate'];
        }
        this.affGroups$ = [];
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.affiliateGroupsDataService.getWithQuery(`?page=${this.page}&perPage=${this.pageSize}${parameters}`).pipe(
          tap((res: any) => {
            this.affGroups$ = res;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.affiliateGroupsDataService.pagination;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.form.patchValue({
      name: null,
      currency_id: 'all',
      status: 'all',
      start_date: null,
      end_date: null,
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.affGroups$ = [];
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.affiliateGroupsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.affGroups$ = res;
        this.pagination = this.affiliateGroupsDataService.pagination;
        this.dataLength = res.length;
        this.loadingBar.complete();
        this.loading = false;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit();
  }

  onOpenDialog(mode: string, data?: any) {
    switch (mode) {
      case 'create':
        this.openDialogBy(AffiliateGroupsEditDialogComponent, { mode: mode });
        break;
      case 'edit':
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.getById(data.id),
          this.affiliateGroupsDataService.getWithdrawlimit(data.id),
          this.affiliateGroupsDataService.getReferralVisibilitySettings(data.id),
          this.affiliateGroupsDataService.getDialogPopupVisibilitySettings(data.id),
          this.affiliateGroupsDataService.getInboxMessageVisibilitySettings(data.id),
          this.affiliateGroupsDataService.getMemberGroupVisibilitySettings(data.id),
        ]).subscribe(([affGroup, limit, visibilitySettings, dialogPopupVisibilitySettings, inboxMessageVisibilitySettings, memberGroupVisibilitySettings]) => {
          const groupData = {
            ...affGroup,
            limit
          };
          this.openDialogBy(AffiliateGroupsEditDialogComponent, { mode: mode, groups: groupData, visibilitySettings: visibilitySettings, dialogPopupVisibilitySettings: dialogPopupVisibilitySettings, inboxMessageVisibilitySettings: inboxMessageVisibilitySettings, memberGroupVisibilitySettings: memberGroupVisibilitySettings });
        });
        break;
      case 'commission':
        this.affiliateGroupsDataService.getCommissionSettings(data.id).subscribe(res => {
          this.openDialogBy(CommissionSettingsDialogComponent, { mode: mode, groups: data.id, settings: res });
        });
        break;
      case 'settlement':
        this.affiliateGroupsDataService.getSettlementSettings(data.id).subscribe(res => {
          this.openDialogBy(SettlementSettingsComponent, { mode: mode, groups: data.id, settings: res });
        });
        break;
      case 'currency':
        this.affiliateGroupsDataService.getCurrencySettings(data.id, this.default).subscribe(res => {
          this.openDialogBy(CurrencySettingsDialogComponent, { mode: mode, groups: data, settings: res });
        });
        break;
    }
  }

  private openDialogBy(componentRef: any, data: { mode: string, groups?: any, settings?: any, visibilitySettings?: any, dialogPopupVisibilitySettings?: any, inboxMessageVisibilitySettings?: any, memberGroupVisibilitySettings?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'commission' || data.mode === 'settlement' || data.mode === 'currency' ? '1200px' : data.mode === 'edit' ? '1700px' : '800px',
      data: {
        mode: data.mode,
        groups: data.groups,
        settings: data.settings,
        visibilitySettings: data.visibilitySettings,
        dialogPopupVisibilitySettings: data.dialogPopupVisibilitySettings,
        inboxMessageVisibilitySettings: data.inboxMessageVisibilitySettings,
        memberGroupVisibilitySettings: data.memberGroupVisibilitySettings
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl('all'),
      date_type: new FormControl(this.dateTimeFilterType[0].key),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null)
    });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

}
