import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TransactionHttpService } from "@core/services/transaction-http.service";

declare var $: any;
@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
})
export class DateTimePickerComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  dateTimeFilterType = null;

  @Input()
  opens: 'left' | 'center' | 'right' = 'left';

  @Input()
  drops: 'up' | 'down' = "down";

  @Input()
  placeholder = 'Search';

  @Input()
  hasRanges: boolean = true;

  @Input()
  singleDatePicker: boolean = false;

  @Input()
  minDate: any = null;

  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem("user_data")).timezone;

  constructor(public transactionHttpService: TransactionHttpService) {}

  ngOnInit(): void {}

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    this.form.patchValue({ defaultDate: null });
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate,
      });
    }
  }
}
