import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AffiliateApprovalDataService } from '../../services/affiliate-approval-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MessageTemplateDataService } from '../../../../superuser/message-template/services/message-template-data.service';
import { ProviderPermissionsDataService } from "../../../../sms/providers/service/providers-permissions-data.service";

@Component({
  templateUrl: './affiliate-approval-settings.component.html',
  styleUrls: ['./affiliate-approval-settings.component.scss']
})
export class AffiliateApprovalSettingsDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  messages$ = this.affiliateApprovalDataService.messages$;
  form: FormGroup;
  buttonLoading = false;

  dropdownList = {
    approval_type: [
      { 'id': 1, name: 'Auto Approve' },
      { 'id': 2, name: 'Manual Approve' }
    ],
    sms_email_notification_type: [
      { 'id': 0, name: 'None'},
      { 'id': 1, name: 'Approve' },
      { 'id': 2, name: 'Reject' },
      { 'id': 3, name: 'Approve and Reject' }
    ],
    regions: this.dropdownHttpService.regions
  }

  smsRegionLoading = false;
  emailRegionLoading = false;
  messageTemplateLoading = false;
  smsProviderLoading = false;
  isSMSRegion = false;
  approveMessageTemplate = false;
  rejectMessageTemplate = false;

  affiliateApprovalSettings: any;

  smsRegionSelectedItems = [];
  emailRegionSelectedItems = [];
  regionDropdownList = [];
  regionSMSDropdownSettings = {
    singleSelection: false,
    maxHeight: 200,
    enableFilterSelectAll: true,
    enableSearchFilter: true,
    text: 'Please Select',
    classes: 'dropdown',
    primaryKey: 'settings_currency_id',
    labelKey: 'country_code',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: false
  };

  regionEmailDropdownSettings = {
    singleSelection: false,
    maxHeight: 200,
    enableFilterSelectAll: true,
    enableSearchFilter: true,
    text: 'Please Select',
    classes: 'dropdown',
    primaryKey: 'settings_currency_id',
    labelKey: 'country_code',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: false
  };

  selectedApproveMessageTemplate = [];
  selectedRejectMessageTemplate = [];
  messageTemplateDropdownList = [];
  messageTemplateDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'code',
    noDataLabel: '',
    showCheckbox: false,
    autoPosition: false,
    position: 'bottom',
  };

  smsProvidersDropdownList = [];
  smsProvidersSelectedItem = [];
  filteredSMSProvidersDropdownList = [];
  smsProvidersDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false,
    autoPosition: false,
    position: 'bottom',
  };

  // permissions
  canEditAffiliateApprovalSettings: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AffiliateApprovalSettingsDialogComponent>,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
    private affiliateApprovalDataService: AffiliateApprovalDataService,
    private dropdownHttpService: DropdownHttpService,
    private messageTemplateDataService: MessageTemplateDataService,
    private providerPermissionsDataService: ProviderPermissionsDataService
  ) { }

  async ngOnInit() {
    await this.onAffiliateApprovalSetting();
    await this.initSMSProviders();
    this.formInit()
    await this.setNotificationDropdowns();
    this.initMessageTemplateDropdown(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditAffiliateApprovalSettings = appPermissions.affiliates_approvals_edit_affiliate_settings;
    });

    this.subscriptions.add(apSub);
  }

  setNotificationDropdowns() :Promise<void>{
    return new Promise((resolve, reject) => {
      forkJoin(this.dropdownList).subscribe((res: any) => {
        this.smsRegionLoading = true;
        this.emailRegionLoading = true; 

        this.regionDropdownList = res.regions;

        this.smsRegionLoading = false;
        this.emailRegionLoading = false; 

        if (this.affiliateApprovalSettings.sms_notification_type == 0) {
          this.regionSMSDropdownSettings = {
            ...this.regionSMSDropdownSettings,
            disabled: true,
          };
  
          this.form.get('sms_currencies').clearValidators();
          this.form.get('sms_currencies').updateValueAndValidity();
        }
  
        if (this.affiliateApprovalSettings.email_notification_type == 0) {
          this.regionEmailDropdownSettings = {
            ...this.regionEmailDropdownSettings,
            disabled: true,
          };
  
          this.form.get('email_currencies').clearValidators();
          this.form.get('email_currencies').updateValueAndValidity();
        }

        if (this.affiliateApprovalSettings.sms_notification && this.affiliateApprovalSettings.sms_notification.length > 0) {
          this.regionDropdownList.forEach(item => { this.affiliateApprovalSettings.sms_notification.forEach(r => { if (+r.settings_currency_id === +item.settings_currency_id) { this.smsRegionSelectedItems.push(item); } }); });

          this.smsRegionSelectedItems = Array.from(new Set(this.smsRegionSelectedItems));
          this.form.patchValue({
            sms_currencies: this.smsRegionSelectedItems.map(item => item.country_code)
          });
          this.isSMSRegion = true; 
        }

        if (this.affiliateApprovalSettings.email_notification && this.affiliateApprovalSettings.email_notification.length > 0) {
          this.regionDropdownList.forEach(item => { this.affiliateApprovalSettings.email_notification.forEach(x => { if (+x.settings_currency_id === +item.settings_currency_id) { this.emailRegionSelectedItems.push(item); } }); });
          this.emailRegionSelectedItems = Array.from(new Set(this.emailRegionSelectedItems));
  
          this.form.patchValue({
            email_currencies: this.emailRegionSelectedItems.map(item => item.settings_currency_id),
          });
        }
        resolve();
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private onAffiliateApprovalSetting():Promise<void>{
    return new Promise((resolve, reject) => {
      this.affiliateApprovalDataService.getSettings().subscribe(res => {
        this.affiliateApprovalSettings = res;
        resolve();
      });
    });
  }

  private initSMSProviders():Promise<void>{
    return new Promise((resolve, reject) => {
      this.providerPermissionsDataService.getWithQuery(`?status=1&paginate=false`).subscribe(res => {
        this.smsProvidersDropdownList = res;
        resolve();
      });
    });
  }

  private initMessageTemplateDropdown(init: boolean) {
    this.messageTemplateLoading = true;

    let currencyIds = null;
    if (this.smsRegionSelectedItems.length > 0) {
      currencyIds = this.smsRegionSelectedItems.map(item => item.settings_currency_id);
    }
    
    this.messageTemplateDataService.getWithQuery(`?type[]=2&section=11&page=1&perPage=30&settings_currency_id=${currencyIds}`).subscribe(res => {
        this.messageTemplateLoading = false;

        // Filter only active message templates (status = 1)
        const activeTemplates = res.filter(v => v.status === 1);
        this.messageTemplateDropdownList = activeTemplates;

        // Find selected approve template (including inactive)
        if (init) {
          const selectedApprove = res.find(v => v.id === this.affiliateApprovalSettings.approve_message_template_id);
          this.selectedApproveMessageTemplate = selectedApprove ? [selectedApprove] : [];

          // Handle error for inactive approve template
          if (selectedApprove && selectedApprove.status === 0) {
              this.form.controls['approve_message_template_id'].setErrors({ inactive: true });
          } else {
              this.form.controls['approve_message_template_id'].setErrors(null);
          }

          // Find selected reject template (including inactive)
          const selectedReject = res.find(v => v.id === this.affiliateApprovalSettings.reject_message_template_id);
          this.selectedRejectMessageTemplate = selectedReject ? [selectedReject] : [];

          // Handle error for inactive reject template
          if (selectedReject && selectedReject.status === 0) {
              this.form.controls['reject_message_template_id'].setErrors({ inactive: true });
          } else {
              this.form.controls['reject_message_template_id'].setErrors(null);
          }
        }

        // Empty message template selected item
        let approved_template = null;
        let rejected_template = null;

        if (this.selectedApproveMessageTemplate.length > 0) {
          approved_template = this.messageTemplateDropdownList.find(v => v.id === this.selectedApproveMessageTemplate[0].id);

          if (approved_template == null) {
            this.selectedApproveMessageTemplate = [];
            this.form.patchValue({
              approve_message_template_id: null
            });
          }
        }
        
        if (this.selectedRejectMessageTemplate.length > 0) {
          rejected_template = this.messageTemplateDropdownList.find(v => v.id === this.selectedRejectMessageTemplate[0].id);

          if (rejected_template == null) {
            this.selectedRejectMessageTemplate = [];
            this.form.patchValue({
              reject_message_template_id: null
            });
          }
        }

        // Mark fields as touched
        this.form.controls['approve_message_template_id'].markAsTouched();
        this.form.controls['reject_message_template_id'].markAsTouched();
        
    });
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] < 0 || data[key].length < 1) && delete data[key]);
    this.subscription = this.affiliateApprovalDataService.updateAffiliateApprovalSettings(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  timezoneDate(date: any, format: any) {
    return this.timeZoneDate.transform(date, format);
  }

  onTemplateSelect(selectedItem: any, type: string) {
    if (selectedItem.length > 0) {
      if (type === 'approve') {
          const control = this.form.controls['approve_message_template_id'];
          this.selectedApproveMessageTemplate = selectedItem;
          if (selectedItem[0].status == 0) {
              control.setErrors({ inactive: true });
          } else {
              control.setErrors(null);
          }
          control.markAsTouched(); // Mark the control as touched to show validation feedback
      } else if (type === 'reject') {
          const control = this.form.controls['reject_message_template_id'];
          this.selectedRejectMessageTemplate = selectedItem;
          if (selectedItem[0].status == 0) {
              control.setErrors({ inactive: true });
          } else {
              control.setErrors(null);
          }
          control.markAsTouched(); // Mark the control as touched to show validation feedback
      }
    }
  }

  onSMSNotificationTypeChange(value: string) {
    switch(value) {
      case '0':
        this.regionSMSDropdownSettings = {
          ...this.regionSMSDropdownSettings,
          disabled: true,
        };
        this.form.patchValue({
          sms_currencies: null
        });
        this.smsRegionSelectedItems = [];
        this.approveMessageTemplate = false;
        this.rejectMessageTemplate = false;
        this.form.patchValue({
          approve_message_template_id: null,
          reject_message_template_id: null
        });
        this.selectedRejectMessageTemplate = [];
        this.selectedApproveMessageTemplate = [];
        this.form.get('reject_message_template_id').clearValidators();
        this.form.get('reject_message_template_id').updateValueAndValidity();
        this.form.get('approve_message_template_id').clearValidators();
        this.form.get('approve_message_template_id').updateValueAndValidity();
        this.form.get('sms_currencies').clearValidators();
        this.form.get('sms_currencies').updateValueAndValidity();

        this.isSMSRegion = false;
        const smsRegionsGroup = this.form.get('sms_regions') as FormGroup;
        // Remove deselected regions from the form
        Object.keys(smsRegionsGroup.controls).forEach(controlName => {   
          smsRegionsGroup.removeControl(controlName);
          delete this.filteredSMSProvidersDropdownList[+controlName];
          delete this.smsProvidersSelectedItem[+controlName];   
        });
      break;
      case '1':
        this.regionSMSDropdownSettings = {
          ...this.regionSMSDropdownSettings,
          disabled: false,
        };
        this.approveMessageTemplate = true;
        this.rejectMessageTemplate = false;
        this.form.patchValue({
          reject_message_template_id: null
        });
        this.selectedRejectMessageTemplate = [];
        this.form.get('reject_message_template_id').clearValidators();
        this.form.get('reject_message_template_id').updateValueAndValidity();
        this.form.get('approve_message_template_id').setValidators(Validators.required);
        this.form.get('approve_message_template_id').updateValueAndValidity();
        this.form.get('sms_currencies').setValidators(Validators.required);
        this.form.get('sms_currencies').updateValueAndValidity();
      break;
      case '2':
        this.regionSMSDropdownSettings = {
          ...this.regionSMSDropdownSettings,
          disabled: false,
        };
        this.approveMessageTemplate = false;
        this.rejectMessageTemplate = true;
        this.form.patchValue({
          approve_message_template_id: null
        });
        this.selectedApproveMessageTemplate = [];
        this.form.get('approve_message_template_id').clearValidators();
        this.form.get('approve_message_template_id').updateValueAndValidity();
        this.form.get('reject_message_template_id').setValidators(Validators.required);
        this.form.get('reject_message_template_id').updateValueAndValidity();
        this.form.get('sms_currencies').setValidators(Validators.required);
        this.form.get('sms_currencies').updateValueAndValidity();
      break;
      case '3':
        this.regionSMSDropdownSettings = {
          ...this.regionSMSDropdownSettings,
          disabled: false,
        };
        this.approveMessageTemplate = true;
        this.rejectMessageTemplate = true;
        this.form.get('approve_message_template_id').setValidators(Validators.required);
        this.form.get('approve_message_template_id').updateValueAndValidity();
        this.form.get('reject_message_template_id').setValidators(Validators.required);
        this.form.get('reject_message_template_id').updateValueAndValidity();
        this.form.get('sms_currencies').setValidators(Validators.required);
        this.form.get('sms_currencies').updateValueAndValidity();
      break;
    }
  }

  onEmailNotificationTypeChange(value: string) {
    switch(value) {
      case '0':
        this.regionEmailDropdownSettings = {
          ...this.regionEmailDropdownSettings,
          disabled: true,
        };
        this.form.patchValue({
          email_currencies: null
        });
        this.emailRegionSelectedItems = [];
        this.form.get('email_currencies').clearValidators();
        this.form.get('email_currencies').updateValueAndValidity();
      break;
      case '1':
      case '2':
      case '3':
        this.regionEmailDropdownSettings = {
          ...this.regionEmailDropdownSettings,
          disabled: false,
        };
        this.form.get('email_currencies').setValidators(Validators.required);
        this.form.get('email_currencies').updateValueAndValidity();
      break;
    }
  }

  onSMSRegionSelect(selectedItems: any[] = []) {
    if (selectedItems.length > 0) {
      this.isSMSRegion = true;

      const smsRegionsGroup = this.form.get('sms_regions') as FormGroup;
      this.smsRegionSelectedItems = selectedItems;

      // Get current selected regions
      const selectedRegions = selectedItems.map(item => item.settings_currency_id);
      
      // Add new regions to the form
      selectedRegions.forEach(regionId => {
        if (!smsRegionsGroup.get(regionId.toString())) {
          const regionCountryCode = selectedItems.find(item => item.settings_currency_id === regionId)?.country_code;
          const filteredProviders = this.smsProvidersDropdownList.filter(provider =>
            provider.region.split(',').includes(regionCountryCode)
          );

          this.filteredSMSProvidersDropdownList[regionId] = filteredProviders;
          smsRegionsGroup.addControl(
            regionId.toString(),
            new FormGroup({
              settings_currency_id: new FormControl(regionId),
              sms_provider_id: new FormControl(null, [Validators.required]),
            })
          );

          this.smsProvidersSelectedItem[regionId] = [];
        }
      });

      // Remove deselected regions from the form
      Object.keys(smsRegionsGroup.controls).forEach(controlName => {
        if (!selectedRegions.includes(+controlName)) {
          smsRegionsGroup.removeControl(controlName);
          delete this.filteredSMSProvidersDropdownList[+controlName];
          delete this.smsProvidersSelectedItem[+controlName];
        }
      });

      this.initMessageTemplateDropdown(false);
    } else {
      this.isSMSRegion = false;

      const smsRegionsGroup = this.form.get('sms_regions') as FormGroup;
      const selectedRegions = selectedItems.map(item => item.settings_currency_id);

      // Remove deselected regions from the form
      Object.keys(smsRegionsGroup.controls).forEach(controlName => {
        if (!selectedRegions.includes(+controlName)) {
          smsRegionsGroup.removeControl(controlName);
          delete this.filteredSMSProvidersDropdownList[+controlName];
          delete this.smsProvidersSelectedItem[+controlName];
        }
      });

      // Empty message template selected item
      this.selectedApproveMessageTemplate = [];
      this.selectedRejectMessageTemplate = [];
      this.form.patchValue({
        approve_message_template_id: null,
        reject_message_template_id: null
      });
      this.smsRegionSelectedItems = [];
      this.initMessageTemplateDropdown(false);
    }
  }

  onSMSProviderChange(index: number, value?: any) {
    const controlName = this.smsRegionSelectedItems[index].settings_currency_id;
    if (value.length > 0) {
      this.form.get(`sms_regions.${controlName}`)?.patchValue({
        sms_provider_id: value[0]['id']
      });
    } else {
      this.form.get(`sms_regions.${controlName}`)?.patchValue({
        sms_provider_id: null
      });
    }
  }

  private formInit() {
    this.smsProvidersSelectedItem = []; 

    const smsRegionsGroup = this.affiliateApprovalSettings.sms_notification.reduce((acc, item) => {
      const filteredProviders = this.smsProvidersDropdownList.filter(provider =>
        provider.region.split(',').includes(item.region)
      );

      this.filteredSMSProvidersDropdownList[item.settings_currency_id] = filteredProviders;
      const selectedProvider = filteredProviders.find(provider => provider.id === item.sms_provider_id);

      acc[item.settings_currency_id.toString()] = new FormGroup({
        settings_currency_id: new FormControl(item.settings_currency_id),
        sms_provider_id: new FormControl(selectedProvider ? selectedProvider.id : null, [Validators.required])
      });

      this.smsProvidersSelectedItem[item.settings_currency_id] = selectedProvider ? [selectedProvider] : [];

      return acc;
    }, {});

    this.form = new FormGroup({
      approval_type: new FormControl(this.affiliateApprovalSettings.approval_type, [Validators.required]),
      sms_notification_type: new FormControl(this.affiliateApprovalSettings.sms_notification_type),
      email_notification_type: new FormControl(this.affiliateApprovalSettings.email_notification_type),
      approve_message_template_id: new FormControl(this.affiliateApprovalSettings.approve_message_template_id, this.approveMessageTemplate ? [Validators.required] : []),
      reject_message_template_id: new FormControl(this.affiliateApprovalSettings.reject_message_template_id, this.approveMessageTemplate ? [Validators.required] : []),
      sms_currencies: new FormControl(null, [Validators.required]),
      email_currencies: new FormControl(null, [Validators.required]),
      sms_regions: new FormGroup(smsRegionsGroup)
    });

    if (this.form.value.approve_message_template_id != null) {
      this.approveMessageTemplate = true;
    }

    if (this.form.value.reject_message_template_id != null) {
      this.rejectMessageTemplate = true;
    }
  }
}
