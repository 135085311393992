<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-100" [formGroup]="form">
    <input type="hidden" formControlName="game_provider_ids" class="form-control">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : (data.mode === 'duplicate' ? 'Duplicate': 'Create') }} Promotion Code</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">

            <div class="row">
                <div class="col-xl-4 col-lg-6 col-sm-12 col-md-6 p-0">
                    <section class="col card border-0">
                        <div class="col-12 p-0 card rounded-0">
                          <div class="card-header">
                            Basic Info
                          </div>
                          <section class="col-12 p-4">
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Code <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <input type="text" class="font-weight-bold col-lg-12 form-control" [attr.disabled]="disabledInput ? '' : null" formControlName="code" #focusfield/>
                              </div>
                            </div>

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Name <span class="text-danger">*</span></span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <input type="text" class="col-lg-12 form-control" formControlName="name" />
                                </div>
                            </div>

                            <!-- <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Category <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <kt-dropdown-wo-lazyload
                                  [form] = 'form'
                                  [dropdownList] = 'categoriesDropdownList'
                                  [dropdownSettings] = 'categoriesDropdownSettings'
                                  [formName] = "'promotion_category_ids'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'categoriesSelectedItems'>
                              </kt-dropdown-wo-lazyload>
                              </div>
                            </div> -->

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Types <span class="text-danger">*</span></span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <select formControlName="promo_type" class="form-control mb-1" (change)="onPromotionType($event)" [attr.disabled]="disabledInput ? '' : null">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option [value]="row.id" *ngFor="let row of promoTypesDropdown">
                                        {{ row.name }}
                                    </option>
                                  </select>

                                  <select class="form-control" formControlName="promo_sub_type" *ngIf="promotionSubTypes" (change)="onPromotionSubType($event)" [attr.disabled]="disabledInput ? '' : null">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option *ngFor="let subType of promotionSubTypes;" [value]="subType.id"> {{ subType.name }}</option>
                                  </select>
                                </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="form.value.promo_type == 4">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Free Spin Games <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <div [ngClass]="freeSpinGameProviderDisabled ? 'disabled-div' : ''">
                                  <kt-dropdown-wo-lazyload
                                      [form] = 'form'
                                      [dropdownList] = "freeSpinGameProvidersDropdownList"
                                      [dropdownSettings] = 'freeSpingameProvidersDropdownSettings'
                                      [formName] = "'free_spin_game_provider_id'"
                                      [selectionAttributes] = "'id'"
                                      [selectedItems] = freeSpinGameProvidersSelectedList
                                      (selectedItemsChanged)="onFreeSpinGameProviderChange($event)"
                                  >
                                  </kt-dropdown-wo-lazyload>
                                </div>
                                <div [ngClass]="freeSpinGameDisabled ? 'disabled-div' : ''">
                                  <kt-dropdown-wo-lazyload
                                    [form] = 'form'
                                    [dropdownList] = "freeSpinGameDropdownList"
                                    [dropdownSettings] = 'freeSpinGameDropdownSettings'
                                    [formName] = "'free_spin_game_code'"
                                    [selectionAttributes] = "'code'"
                                    [selectedItems] = freeSpinGameSelectedList
                                  >
                                  </kt-dropdown-wo-lazyload>
                                </div>
                              </div>
                            </div>

                            <div class="row border-top border-left border-bottom">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Linked Promotions</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <kt-dropdown-wo-lazyload
                                    [form] = 'form'
                                    [dropdownList] = 'promotionCodeDropdownList'
                                    [dropdownSettings] = 'promotionCodeDropdownSettings'
                                    [formName] = "'promotion_ids'"
                                    [selectionAttributes] = "'id'"
                                    [selectedItems] = 'promotionCodeSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                          </div>
                          </section>
                        </div>
                    </section>

                    <section class="col card border-0">
                        <div class="col-12 p-0 card rounded-0 mt-2">
                          <div class="card-header">
                            Bonus
                          </div>
                          <section class="col-12 p-4">
                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Limit Provider Transfer In</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                    <label class="checkbox">
                                        <input type="checkbox" formControlName="limit_transfer_in"/>
                                        <span></span>
                                    </label>
                                </div>
                            </div>

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Limit Provider Transfer Out</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                    <label class="checkbox">
                                        <input type="checkbox" formControlName="limit_transfer_out" />
                                        <span></span>
                                    </label>
                                </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="requiredBonusRate">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Bonus Rate % <span class="text-danger">*</span></span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <input type="number" (wheel)="false" class="col-lg-12 form-control" formControlName="bonus_rate">
                                  <div class="col-md-12">
                                    Max 2 decimal places
                                  </div>
                                </div>
                            </div>

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Auto Unlock</span>
                                  <i class="fas fa-exclamation-circle" matTooltip='Not applicable when "Unlock Upon Transfer Out" is checked.' matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                </div>
                                <div class="col-md-8 border-right border-bottom p-3">
                                    <label class="checkbox">
                                        <input type="checkbox" formControlName="auto_unlock" />
                                        <span></span>
                                    </label>
                                </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="this.form.value.limit_transfer_out == true">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Unlock Upon Transfer Out</span>
                                <i class="fas fa-exclamation-circle" placement="bottom" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i>
                                <ng-template #linkToolTip>
                                  <div class="text-left">
                                    Not applicable when "Auto Unlock" is checked.<br>
                                    <b>Recommended to be enabled if "Max Transfer Out" field is configured.</b>
                                  </div>
                                </ng-template>
                              </div>
                              <div class="col-md-8 border-right border-bottom p-3">
                                <label class="checkbox">
                                    <input type="checkbox" formControlName="transfer_unlock" />
                                    <span></span>
                                </label>
                              </div>
                            </div>

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Allow Cancel Promotion
                                    <i class="fas fa-exclamation-circle" matTooltip="Member is allow to cancel the promotion on user portal." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                  </span>
                                </div>
                                <div class="col-md-8 border-right border-bottom p-3">
                                    <label class="checkbox">
                                        <input type="checkbox" formControlName="allow_cancel" />
                                        <span></span>
                                    </label>
                                </div>
                            </div>

                          </section>
                        </div>
                    </section>
                    <section class="col card border-0">
                      <div class="col-12 p-0 card rounded-0 mt-2">
                        <div class="card-header">
                          KYC Verification
                        </div>
                        <section class="col-12 p-4">
                          <div class="row border-top border-left">
                            <div class="col-lg-4 col-md-4 card-header border-right p-3">
                              <span class="kt-font-bold">Type <span class="text-danger">*</span></span>
                            </div>
                            <div [ngClass]="selectedKycType == null ? 'border-bottom' : ''" class="col-md-8 border-right p-3">
                              <select formControlName="kyc_type" class="form-control mb-1" (change)="onKycType($event)">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <option *ngFor="let value of dropdown.kycTypes; let i = index;" [value]="i+1">{{ value }}</option>
                              </select>
                            </div>
                          </div>
                          <ng-container *ngIf="selectedKycType == 1">
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">KYC Status <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3 border-bottom">
                                <ng-container>
                                  <kt-dropdown-wo-lazyload
                                    [form] = 'form'
                                    [dropdownList] = 'dropdown.kycStatuses'
                                    [dropdownSettings] = 'kycStatusDropdownSettings'
                                    [formName] = "'kyc_status'"
                                    [selectionAttributes] = "'id'"
                                    [selectedItems] ='kycStatusSelectedItem'>
                                  </kt-dropdown-wo-lazyload>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="selectedKycType == 2">
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Email Verified</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <label class="checkbox">
                                  <input type="checkbox" formControlName="requires_email" />
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Mobile Verified</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <label class="checkbox">
                                  <input type="checkbox" formControlName="requires_mobile" />
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Date of Birth</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <label class="checkbox">
                                  <input type="checkbox" formControlName="requires_dob" />
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Full Name</span>
                              </div>
                              <div class="col-md-8 border-right p-3 border-bottom">
                                <label class="checkbox">
                                  <input type="checkbox" formControlName="requires_fullname" />
                                  <span></span>
                                </label>
                              </div>
                            </div>
                          </ng-container>
                        </section>
                      </div>
                    </section>
                </div>

                <div class="col-xl-4 col-lg-6 col-sm-12 col-md-6 p-0">
                    <section class="col card border-0">
                        <div class="col-12 p-0 card rounded-0">
                          <div class="card-header">
                            Basic Setting
                          </div>
                          <section class="col-12 p-4">
                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Valid From <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                              <div class="input-group date">
                                <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.valid_from" [ngModelOptions]="{standalone: true}">
                                <input type="text" formControlName="valid_from" class="form-control">
                                <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                                    <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                                </span>
                                <owl-date-time #dateStartRef></owl-date-time>
                              </div>
                              </div>
                            </div>

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Valid To</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <div class="input-group date">
                                    <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.valid_to" [ngModelOptions]="{standalone: true}">
                                    <input type="text" formControlName="valid_to" class="form-control">
                                    <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                                    </span>
                                    <owl-date-time #dateEndRef></owl-date-time>
                                  </div>
                                </div>
                            </div>

                            <div class="row border-top border-left border-bottom">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Validity</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                    <input type="number" (wheel)="false" min="1" max="30" class="col-lg-12 form-control" formControlName="validity">
                                </div>
                            </div>

                            <div class="row border-top border-left border-bottom" *ngIf="showRewardsValidity">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Rewards Validity</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                  <input type="number" (wheel)="false" min="1" max="" class="col-lg-12 form-control" formControlName="reward_validity">
                                  <div class="col-md-12">
                                    Enter 0 for unlimited
                                  </div>
                              </div>
                          </div>

                            <div class="row border-top border-left border-top-0 border-bottom">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Frequency</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <input type="hidden" formControlName="frequency" />
                                  <select class="w-100 mb-2 form-control float-left" formControlName="frequency_type" (change)="onFrequencyType($event)">
                                      <ng-container *ngFor="let frequency of frequencyTypes" >
                                        <option [value]="frequency.id">{{ frequency.name }}</option>
                                      </ng-container>
                                  </select>
                                  <ng-container>
                                    <div class="w-1000" *ngIf="+this.form.value.frequency_type === 2">
                                      <label class="col-form-label checkbox mr-3 frequency-label weekdays" *ngFor="let week of frequencyWeekdays">
                                        <input type="checkbox" [value]="week.id" (click)="onFrequency($event)" [checked]="frequencyCheckboHandler(week.id, (data.mode === 'edit' || data.mode === 'duplicate' ? data.promotionCode.frequency : []))" /> {{ week.name }}
                                        <span></span>
                                      </label>
                                    </div>
                                    <div class="w-1000" *ngIf="+this.form.value.frequency_type === 3">
                                      <label class="col-form-label checkbox mr-3 frequency-label months" *ngFor="let month of frequencyMonths">
                                        <input type="checkbox" [value]="month.id" (click)="onFrequency($event)" [checked]="frequencyCheckboHandler(month.id, (data.mode === 'edit' || data.mode === 'duplicate' ? data.promotionCode.frequency : []))" /> {{ month.name }}
                                        <span></span>
                                      </label>
                                    </div>
                                  </ng-container>

                                </div>
                            </div>
                          </section>
                        </div>
                    </section>

                    <section class="col card border-0" formArrayName="target">
                        <div class="col-12 p-0 card rounded-0 mt-2">
                          <div class="card-header">
                            Target Amount
                          </div>
                          <ng-container *ngFor="let rowType of (dropdown.targetTypes) as targetTypesRef; let pi = index">
                            <section class="col-12 p-4" [formGroupName]="pi" *ngIf="rowType.id >= 1 && rowType.id <= 2">
                                <div class="row border-top border-left">
                                  <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                    <span class="kt-font-bold">Target Type</span>
                                      <ng-container *ngIf="(showFirstAsterisk === true && pi == 0) || (showSecondAsterisk === true && pi == 1)">
                                        <span class="text-danger">*</span>
                                      </ng-container>
                                  </div>
                                  <div class="col-md-8 border-right p-3">
                                    {{ rowType.id === 1 ? 'Turnover' : 'Winloss' }}
                                  </div>
                                </div>
                                <div class="row border-top border-left">
                                  <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                    <span class="kt-font-bold">Transfer Amount</span>
                                      <ng-container *ngIf="(showFirstAsterisk === true && pi == 0) || (showSecondAsterisk === true && pi == 1)">
                                        <span class="text-danger">*</span>
                                      </ng-container>
                                  </div>
                                  <!-- <div class="col-md-8 border-right p-3">
                                    <select formControlName="type" class="form-control w-100 float-left" (change)="onChangeTarget(pi)">
                                        <option value="">Please Select</option>
                                        <ng-container *ngFor="let row of targetTypesRef; let si = index">
                                          <option [value]="row.id" *ngIf="row.id >= 1 && pi === 0 && (si === 0 || si === 2)">
                                            {{ row.name }}
                                          </option>
                                          <option [value]="row.id" *ngIf="row.id >= 1 && pi === 1 && (si === 1 || si === 3)">
                                            {{ row.name }}
                                          </option>
                                        </ng-container>
                                      </select>
                                  </div> -->
                                  <div class="col-md-8 border-right p-3">
                                      <ng-container class="kt-radio col-form-label pt-0" *ngFor="let row of targetTypesRef; let si = index">
                                        <ng-container *ngIf="row.id >= 1 && pi === 0 && (si === 0 || si === 2)">
                                          <input
                                            type="radio"
                                            formControlName="type"
                                            [value]="row.id"
                                            (change)="onChangeTarget(pi)"
                                          >
                                            <span class="pl-2 pr-2">{{ row.name }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="row.id >= 1 && pi === 1 && (si === 1 || si === 3)">
                                          <input
                                            type="radio"
                                            formControlName="type"
                                            [value]="row.id"
                                            (change)="onChangeTarget(pi)"
                                          >
                                          <span class="pl-2 pr-2">{{ row.name }}</span>
                                        </ng-container>
                                      </ng-container>
                                  </div>
                                </div>

                                <div class="row border-top border-bottom border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                      <span class="kt-font-bold">Target Multiplier</span>
                                        <ng-container *ngIf="(showFirstAsterisk === true && pi == 0) || (showSecondAsterisk === true && pi == 1)">
                                          <span class="text-danger">*</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                      <input type="number" (wheel)="false" class="col-lg-12 form-control" formControlName="multiplier">
                                    </div>
                                </div>

                                <div class="row border-top border-bottom border-left">
                                  <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                    <span class="kt-font-bold">Categories</span>
                                      <ng-container *ngIf="(showFirstAsterisk === true && pi == 0) || (showSecondAsterisk === true && pi == 1)">
                                        <span class="text-danger">*</span>
                                      </ng-container>
                                  </div>
                                  <div class="col-md-8 border-right p-3">
                                    <ng-container>
                                      <kt-dropdown-wo-lazyload
                                        [form] = 'form'
                                        [dropdownList] = "rowType.id == 1 ? turnoverCategoriesDropdownList : winlossCategoriesDropdownList"
                                        [dropdownSettings] = 'categoriesDropdownSettings'
                                        [formName] = "rowType.id == 1 ? 'promotion_category_turnover' : 'promotion_category_winloss'"
                                        [selectionAttributes] = "'id'"
                                        [selectedItems] = "rowType.id == 1 ? turnoverCategoriesSelectedItems : winlossCategoriesSelectedItems"
                                        (selectedItemsChanged)="onGameCategoryChanged($event, rowType.id)"
                                        >
                                      </kt-dropdown-wo-lazyload>
                                    </ng-container>
                                  </div>
                                </div>

                                <div class="row border-top border-bottom border-left">
                                  <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                    <span class="kt-font-bold">Game Providers</span>
                                      <ng-container *ngIf="(showFirstAsterisk === true && pi == 0) || (showSecondAsterisk === true && pi == 1)">
                                        <span class="text-danger">*</span>
                                      </ng-container>
                                  </div>
                                  <div class="col-md-8 border-right p-3">
                                    <div [ngClass]="(rowType.id == 1 && gameProviderDropdownTurnoverDisabled)
                                        || (rowType.id == 2 && gameProviderDropdownWinoverDisabled)
                                        ? 'disabled-div'
                                        : ''"
                                    >
                                      <kt-dropdown-wo-lazyload
                                        [form] = 'form'
                                        [dropdownList] = "rowType.id === 1 ? gameProvidersDropdownListTurnover : gameProvidersDropdownListWinover"
                                        [dropdownSettings] = 'gameProvidersDropdownSettings'
                                        [formName] = "'game_provider_ids'"
                                        [selectionAttributes] = "'id'"
                                        [selectedItems] = "rowType.id === 1 ? gameProvidersSelectedTurnover : gameProvidersSelectedWinover"
                                        (selectedItemsChanged)="onGameProviderChanged(pi, $event)"
                                        (openTriggered)="formValueChanges($event)">
                                      </kt-dropdown-wo-lazyload>
                                    </div>
                                  </div>
                                </div>
                                <p class="pl-0 mt-2" *ngIf="[1].includes(+form.get('target.'+pi).value.type)">Target Amount = (Transfer Amount + Bonus) x Target Multiplier  </p>
                                <p class="pl-0 mt-2" *ngIf="[2].includes(+form.get('target.'+pi).value.type)">Target Amount = (Transfer Amount + Bonus) + [(Transfer Amount + Bonus) x Target Multiplier]  </p>
                                <p class="pl-0 mt-2" *ngIf="[3].includes(+form.get('target.'+pi).value.type)">Target Amount = Transfer Amount + (Bonus x Target Multiplier) </p>
                                <p class="pl-0 mt-2" *ngIf="[4].includes(+form.get('target.'+pi).value.type)">Target Amount = (Transfer Amount + Bonus) + [(Bonus x Target Multiplier)] </p>
                              </section>
                            </ng-container>
                        </div>
                    </section>
                </div>
                <div class="col-xl-4 col-lg-6 col-sm-12 col-md-6 p-0">
                    <section class="col card border-0">
                        <div class="col-12 p-0 card rounded-0">
                          <div class="card-header">
                            Eligibility
                          </div>
                          <section class="col-12 p-4">

                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Eligible Types <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <select formControlName="eligible_types" class="form-control mb-1" (change)="onSelectEligibleTypes($event)" #eligibleTypes>
                                  <option [value]="null" [disabled]="true">Please Select</option>
                                  <option [value]="+item.id" *ngFor="let item of dropdown.eligibleTypes">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>

                            <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <ng-container *ngIf="+form.value.eligible_types !== 4; else telemarketerDropdown">
                                  <span class="kt-font-bold">{{ +form.value.eligible_types === 2 ? 'Affiliate' : 'Member' }} Group</span>
                                </ng-container>
                                <ng-template #telemarketerDropdown>
                                  <span class="kt-font-bold" style="white-space: nowrap;">Telemarketer</span>
                                </ng-template>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <!-- Show empty dropdown when eligible type is not selected -->
                                <kt-dropdown-wo-lazyload *ngIf="!form.value.eligible_types"
                                  [dropdownList] = 'groupDropdownList'
                                  [dropdownSettings] = 'groupDropdownSettings'
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'groupSelectedItems'>
                                </kt-dropdown-wo-lazyload>

                                <kt-dropdown-wo-lazyload *ngIf="+form.value.eligible_types === 1 || +form.value.eligible_types === 3"
                                  [form] = 'form'
                                  [dropdownList] = 'groupDropdownList'
                                  [dropdownSettings] = 'groupDropdownSettings'
                                  [formName] = "'member_group_ids'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'groupSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                                <kt-dropdown-wo-lazyload *ngIf="+form.value.eligible_types === 2"
                                  [form] = 'form'
                                  [dropdownList] = 'dropdown.affiliateGroup'
                                  [dropdownSettings] = 'groupDropdownSettings'
                                  [formName] = "'affiliate_group_ids'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'affiliateGroupSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                                <kt-dropdown-wo-lazyload *ngIf="+form.value.eligible_types === 4" class="dropdown-maxheight col-8 p-0"
                                  [form] = 'form'
                                  [dropdownList] = 'telemarketerDropdownList'
                                  [dropdownSettings] = 'telemarketerDropdownSettings'
                                  [formName] = "'telemarketer_ids'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'telemarketerSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="+form.value.eligible_types === 4">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Account Managers</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <kt-dropdown-wo-lazyload *ngIf="+form.value.eligible_types === 4" class="dropdown-maxheight col-8 p-0"
                                  [form] = 'form'
                                  [dropdownList] = 'normalAccountManagerDropdownList'
                                  [dropdownSettings] = 'normalAccountManagerDropdownSettings'
                                  [formName] = "'normal_account_manager_ids'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'normalAccountManagerSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="+form.value.eligible_types === 4">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold"> Vip Account Managers</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <kt-dropdown-wo-lazyload *ngIf="+form.value.eligible_types === 4" class="dropdown-maxheight col-8 p-0"
                                  [form] = 'form'
                                  [dropdownList] = 'vipAccountManagerDropdownList'
                                  [dropdownSettings] = 'vipAccountManagerDropdownSettings'
                                  [formName] = "'vip_account_manager_ids'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'vipAccountManagerSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>

                            <div class="row border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">{{ +form.value.eligible_types === 2 ? 'Affiliates' : 'Members' }}</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <!-- TEMPORARY REMOVE THE DISABLE ATTRIBUTE -->
                                  <button type="button" class="btn btn-brand" (click)="onPersonListDialog()" [disabled]="eligibleTypes.value == 'null' || isMemberListDisabled()" ><i class="fa fa-plus" aria-hidden="true"></i>{{ +form.value.eligible_types === 2 ? 'Affiliate' : 'Member' }} List</button>
                                </div>
                            </div>

                            <div *ngIf="+form.value.eligible_types === 1" class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold d-flex align-items-center">Visible by Affiliate
                                  <i class="fas fa-exclamation-circle" matTooltip="Selected affiliates will be granted the ability to view and create campaigns using the promotion code on the Affiliate Portal." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                </span>
                              </div>
                              <div class="col-md-8 border-right p-3 d-flex align-items-center">
                                <label class="checkbox mr-2 mb-0">
                                  <input type="checkbox" formControlName="visible_by_affiliate" (change)="onChangeVisibileByAffiliate($event)"/>
                                </label>
                              </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="+form.value.visible_by_affiliate == 1 && +form.value.eligible_types === 1">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">{{ 'Affiliates' }}</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <button type="button" class="btn btn-brand" (click)="onPromotionAffiliateDialog()" ><i class="fa fa-plus" aria-hidden="true"></i>{{ 'Affiliate List' }}</button>
                              </div>
                            </div>

                            <div class="row border-top border-left border-bottom">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold d-flex align-items-center">Last Deposit
                                    <i class="fas fa-exclamation-circle" matTooltip="Members have to deposit to main wallet first and have not applied for other promotions to apply this promotion." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                  </span>
                                </div>
                                <div class="col-md-8 border-right p-3 d-flex align-items-center">
                                  <label class="checkbox mr-2 mb-0">
                                    <input type="checkbox" formControlName="last_deposit" />
                                  </label>
                                </div>
                            </div>

                            <div class="row border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Auto Approve</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                    <label class="checkbox mr-4">
                                        <input type="checkbox" formControlName="auto_approve" />
                                        <span></span>
                                      </label>
                                </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="!hideReccuring">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Recurring <span class="text-danger">*</span></span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <!-- TODO: No API collection yet-->
                                <select formControlName="recurring" class="form-control mb-1" (change)="onSetRecurring($event)">
                                  <option [value]="null" [disabled]="true">Please Select</option>
                                  <option [value]="0">One Time</option>
                                  <option [value]="1">Recurring</option>
                                </select>
                              </div>
                            </div>

                            <div class="row border-top border-left border-bottom" *ngIf="!hideResetFrequency">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Reset Frequency</span>
                                </div>
                                <div class="col-lg-4 col-md-4 p-3">
                                    <div class="kt-radio-list">
                                      <label class="kt-radio col-form-label pt-0" *ngFor="let value of frequencyTypes; let i = index" (change)="onResetPrequency($event)">
                                        <input type="radio" formControlName="reset_frequency" [value]="value.id"> {{ value.name }} <span class="reset-preq-radio"></span>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 border-right pt-3 pb-3 relative-div pl-0 pr-0">
                                  <div class="col-12 reset-frequency-select1">
                                    <select formControlName="reset_dayWeekly" class="form-control mt-2" *ngIf="form.value.reset_frequency === 2">
                                      <option value="null" [disabled]="true">Days</option>
                                      <option [value]="week.id" *ngFor="let week of frequencyWeekdays">{{ week.name }}</option>
                                    </select>
                                  </div>
                                  <div class="col-12 reset-frequency-select2">
                                    <select formControlName="reset_dayMonthly" class="form-control mt-4" *ngIf="form.value.reset_frequency === 3">
                                      <option value="null" [disabled]="true">Days</option>
                                      <option [value]="month.id" *ngFor="let month of frequencyMonths">{{ month.name }}</option>
                                    </select>
                                  </div>
                                </div>
                            </div>

                            <div class="row border-top border-left" *ngIf="requiredDepositWelcome">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">Max Per Player (Lifetime)</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <input type="number" (wheel)="false" min="1" class="form-control" formControlName="max_per_player" />
                                <div class="col-md-12">
                                  Enter 0 for unlimited
                                </div>
                              </div>
                            </div>

                            <div class="row border-bottom border-top border-left" *ngIf="requiredDepositWelcome">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Daily Max <span class="text-danger">*</span></span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <input type="number" (wheel)="false" min="0" class="form-control" formControlName="daily_max" />
                                </div>
                            </div>

                            <div class="row border-bottom border-top border-left">
                                <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                  <span class="kt-font-bold">Status</span>
                                </div>
                                <div class="col-md-8 border-right p-3">
                                  <select *ngIf="dropdown.statuses" formControlName="status" class="form-control">
                                    <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                                  </select>
                                </div>
                            </div>
                          </section>

                        </div>
                    </section>

                    <section class="col card border-0">
                      <div class="col-12 p-0 card rounded-0 mt-2">
                        <div class="card-header">
                          Message
                        </div>
                        <section class="col-12 p-4">
                          <div class="row border-top border-left">
                              <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                <span class="kt-font-bold">SMS</span>
                              </div>
                              <div class="col-md-8 border-right p-3">
                                <kt-dropdown-wo-lazyload
                                  [form] = 'form'
                                  [dropdownList] = 'smsTemplateList'
                                  [dropdownSettings] = 'messageTemplatesSettings'
                                  [formName] = "'message_template_sms_id'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] ='smsTemplateSelectedItem'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                          </div>
                          <div class="row border-top border-left">
                            <div class="col-lg-4 col-md-4 card-header border-right p-3">
                              <span class="kt-font-bold">Message</span>
                            </div>
                            <div class="col-md-8 border-right border-bottom p-3">
                                <kt-dropdown-wo-lazyload
                                [form] = 'form'
                                [dropdownList] = 'messageTemplateList'
                                [dropdownSettings] = 'messageTemplatesSettings'
                                [formName] = "'message_template_id'"
                                [selectionAttributes] = "'id'"
                                [selectedItems] ='messageTemplateSelectedItem'>
                              </kt-dropdown-wo-lazyload>
                            </div>
                          </div>
                          <div class="row border-top border-left">
                            <div class="col-lg-4 col-md-4 card-header border-right p-3">
                              <span class="kt-font-bold">{{ 'Dialog Popup' }}</span>
                            </div>
                            <div class="col-md-8 border-right border-bottom p-3">
                              <div class="mb-2">
                                <div>
                                  <kt-dropdown-wo-lazyload
                                    [form]="form" 
                                    [dropdownList]="popupDropdownListArray" 
                                    [dropdownSettings]="popupDropdownSettings" 
                                    [formName]="'dialog_popups'" 
                                    [selectionAttributes]="'id'" 
                                    [selectedItems]="popupSelectedItems"
                                    (selectedItemsChanged)="onSelectedPopupItems($event)"> 
                                  </kt-dropdown-wo-lazyload>
                                </div>
                              </div>
                              <div *ngIf="checkDialogValidityDate(popupSelectedItems)" class="dialog-container mt-2">
                                <span class="d-flex dialog-validity" >
                                  <i class="fa fa-exclamation-circle promotion-code-detail-tooltip" placement="top"></i>
                                  <p class="mb-0">{{ 'The selected dialog popup is not within its validity period or has been expired.' }}</p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </section>
                </div>
            </div>
      </div>
      <div class="modal-footer">
        <div class="col">
          <div style="display: contents;">
            <i class="fas fa-circle warn-icon" [hidden]="form.value.promo_type === '' || form.value.promo_type === null || form.value.promo_sub_type === '' || form.value.promo_sub_type === null || isHiddenWarn()"></i>
            <button *ngIf="canViewPromotionCurrency" type="button" class="btn btn-brand" [disabled]="form.value.promo_type === '' || form.value.promo_type === null || form.value.promo_sub_type === '' || form.value.promo_sub_type === null" (click)="onOpenDialog()"><i class="fa fa-plus" aria-hidden="true"></i>Promotion Currency</button>
          </div>
          <button *ngIf="canViewPromotionName && data.mode === 'edit'" type="button" class="btn btn-success ml-2" (click)="onPromotionNamesDialog()"><i class="fa fa-plus" aria-hidden="true"></i>Promotion Names</button>
          <!-- <button type="button" class="btn btn-dark ml-2" *ngIf="gameProvidersSelectedItems.length >= 1" (click)="onBlacklistDialog(gameProvidersSelectedItems)" ><i class="fa fa-plus" aria-hidden="true"></i>BlackList</button> -->
          <button *ngIf="canViewGameProviderBlacklist && gameProvidersSelectedItems.length >= 1" type="button" class="btn btn-dark ml-2" (click)="onNewBlacklistDialog(gameProvidersSelectedItems, turnoverCategoriesSelectedItems, winlossCategoriesSelectedItems)" ><i class="fa fa-plus" aria-hidden="true"></i>BlackList</button>
        </div>
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="(data.mode == 'create' && canCreatePromotionCode) || (data.mode == 'edit' && canEditPromotionCode) || (data.mode == 'duplicate' && canDuplicatePromotionCode)" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.promotionCode, data.mode)" [text]="data.mode == 'duplicate' ? 'Duplicate': 'Submit'"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
