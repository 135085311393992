<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form *ngIf="form" class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Affiliate Settings</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-12 p-0 card rounded-0 mb-4">
          <div class="card-header">
            Approval Settings
          </div>
          <div class="col-12 row pl-4 pt-4 pb-0">
            <label class="col-4 col-form-label">Approval Type <span class="text-danger">*</span> </label>
            <select formControlName="approval_type" class="col-8 form-control">
              <option [value]="value.id" *ngFor="let value of dropdownList.approval_type"> {{ value.name }} </option>
            </select>
          </div>
          <div class="col-12 row pl-4 pt-4 pb-0">
            <label class="col-4 col-form-label">SMS Notification </label>
            <select formControlName="sms_notification_type" class="col-3 form-control" (change)="onSMSNotificationTypeChange($event.target.value)">
              <option [value]="value.id" *ngFor="let value of dropdownList.sms_email_notification_type"> {{ value.name }} </option>
            </select>
            <kt-dropdown-wo-lazyload class="dropdown-maxheight col-5" style="padding:0"
              [form] = 'form'
              [dropdownList] = 'regionDropdownList'
              [dropdownSettings] = 'regionSMSDropdownSettings'
              [formName] = "'sms_currencies'"
              [selectionAttributes] = "'settings_currency_id'"
              [selectedItems] = 'smsRegionSelectedItems'
              (selectedItemsChanged)="onSMSRegionSelect($event)">
            </kt-dropdown-wo-lazyload>
          </div>
          <div class="col-12 row pl-4 pt-4" [ngClass]="approveMessageTemplate || rejectMessageTemplate ? 'pb-0' : 'pb-4'">
            <label class="col-4 col-form-label">Email Notification </label>
            <select formControlName="email_notification_type" class="col-3 form-control" (change)="onEmailNotificationTypeChange($event.target.value)">
              <option [value]="value.id" *ngFor="let value of dropdownList.sms_email_notification_type"> {{ value.name }} </option>
            </select>
            <kt-dropdown-wo-lazyload 
              class="dropdown-maxheight col-5" style="padding:0"
              [form] = 'form'
              [dropdownList] = 'regionDropdownList'
              [dropdownSettings] = 'regionEmailDropdownSettings'
              [formName] = "'email_currencies'"
              [selectionAttributes] = "'settings_currency_id'"
              [selectedItems] = 'emailRegionSelectedItems'>
            </kt-dropdown-wo-lazyload>
          </div>
          <div class="col-12 row pl-4 pt-4" *ngIf="approveMessageTemplate" [ngClass]="rejectMessageTemplate ? 'pb-0' : 'pb-4'">
            <label class="col-4 col-form-label">Message Template <span class="text-danger">*</span> </label>
            <input disabled class="col-2 form-control" placeholder="Approve" />
            <kt-dropdown-wo-lazyload
              #approveSelect
              class="col-6" style="padding:0"
              [form] = 'form'
              [dropdownList] = 'messageTemplateDropdownList'
              [dropdownSettings] = 'messageTemplateDropdownSettings'
              [formName] = "'approve_message_template_id'"
              [selectionAttributes] = "'id'"
              [selectedItems]="selectedApproveMessageTemplate"
              [isMessageTemplate]="true"
              [dataLoading]="loading"
              (click)="onTemplateSelect(approveSelect.selectedItems, 'approve')">
            </kt-dropdown-wo-lazyload>
          </div>
          <div class="col-12 row pl-4 pt-4 pb-4" *ngIf="rejectMessageTemplate">
            <label class="col-4 col-form-label">
              <ng-container *ngIf="rejectMessageTemplate && !approveMessageTemplate else rejectAndApprove">
                Message Template <span class="text-danger">*</span>
              </ng-container>
              <ng-template #rejectAndApprove></ng-template>
            </label>
            <input disabled class="col-2 form-control" placeholder="Reject" />
            <kt-dropdown-wo-lazyload
              #rejectSelect
              class="col-6" style="padding:0"
              [form] = 'form'
              [dropdownList] = 'messageTemplateDropdownList'
              [dropdownSettings] = 'messageTemplateDropdownSettings'
              [formName] = "'reject_message_template_id'"
              [selectionAttributes] = "'id'"
              [selectedItems]="selectedRejectMessageTemplate"
              [isMessageTemplate]="true"
              [dataLoading]="loading"
              (click)="onTemplateSelect(rejectSelect.selectedItems, 'reject')">
            </kt-dropdown-wo-lazyload>
          </div>
        </div>
        <div class="col-12 p-0 pb-4 card rounded-0 mt-0" *ngIf="isSMSRegion" formGroupName="sms_regions">
          <div class="card-header">
            SMS Provider Settings
          </div>
          <ng-container *ngFor="let value of smsRegionSelectedItems; let i = index" [formGroupName]="value.settings_currency_id">
            <div class="col-12 row pl-4 pt-4 pb-0">
                <label class="col-4 col-form-label">{{ value.country_code }} <span class="text-danger">*</span> </label>
                <kt-dropdown-wo-lazyload 
                  #smsProvider
                  class="dropdown-maxheight col-5" style="padding:0"
                  [form] = 'form'
                  [dropdownList] = 'filteredSMSProvidersDropdownList[value.settings_currency_id]'
                  [dropdownSettings] = 'smsProvidersDropdownSettings'
                  [formName] = "'sms_provider_id'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'smsProvidersSelectedItem[value.settings_currency_id]'
                  (selectedItemsChanged)='onSMSProviderChange(i, $event)'>
                </kt-dropdown-wo-lazyload>
            </div>
          </ng-container>
        </div>
        <div class="col-12 pl-0 pr-4 pb-0" [ngClass]="isSMSRegion ? 'pt-4' : ''">
          <table class="col-12">
            <tr>
              <td>
                Updated by: {{ affiliateApprovalSettings.updated_by ? affiliateApprovalSettings.updated_by + '&nbsp;' : '-&nbsp;' }}{{ affiliateApprovalSettings.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss'}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <button *ngIf="canEditAffiliateApprovalSettings" class="btn btn-primary btn-icon-sm mr-2" (click)="onSave()" type="button" [disabled]="buttonLoading || !form.valid"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Submit</button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>