import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { forkJoin, Subscription } from 'rxjs';
import { NewRebateReleaseDataService } from '../../services/new-rebate-release-data.service';
import { MemberReportDetailsDialogComponent } from '@views/pages/apps/reports/member-report/dialogs/member-report-details.component';
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from "@core/services/app-permission.service";
import { PromotionTurnoverDetailDialogComponent } from '../promotion-turnover-detail/promotion-turnover-detail.component';
@Component({
  selector: 'kt-rebate-details',
  templateUrl: './rebate-details.component.html',
  styleUrls: ['./rebate-details.component.scss'],
})
export class RebateDetailsDialogComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  buttonLoading = false;
  currencyTimezone = CurrencyTimezone;
  checkValidation = false;
  eligibleAmountByCategory = [];
  
  dropdown = {
    gameProviders: this.dropdownHttpService.gameProviders
  };

  // permissions
  private subscriptions = new Subscription();
  canViewMemberBetLogDialog: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebateRelease : any , details: any;  },
    private rebateReleaseDataService: NewRebateReleaseDataService,
    public dialogRef: MatDialogRef<RebateDetailsDialogComponent>,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService
  ) { }

  ngOnInit() {
    this.data.details.forEach((element,index) => {
      this.data.details[index]['eligible_amount'] = 0;

      if(!this.eligibleAmountByCategory[element.game_provider_code]){
        this.eligibleAmountByCategory[element.game_provider_code] = [];
        this.eligibleAmountByCategory[element.game_provider_code][element.category_code] = 0;
      }
      var previousEligibleAmount = this.eligibleAmountByCategory[element.game_provider_code][element.category_code];
      this.eligibleAmountByCategory[element.game_provider_code][element.category_code] = this.eligibleAmountByCategory[element.game_provider_code][element.category_code] + parseFloat(this.data.details[index]['rebate_amount']);
      
      if(this.eligibleAmountByCategory[element.game_provider_code][element.category_code] > this.data.rebateRelease.max_rebate_limit){
        if(previousEligibleAmount < this.data.rebateRelease.max_rebate_limit){
          this.data.details[index]['eligible_amount'] = this.data.rebateRelease.max_rebate_limit- previousEligibleAmount;
        }
      }else{
        this.data.details[index]['eligible_amount'] = this.data.details[index]['rebate_amount'];
      }
      
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberBetLogDialog = appPermissions.view_member_bet_logs_rebate_v2;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(type :string, row :any) {
    if (type === 'show-game-account-details') {
      this.dropdown.gameProviders.subscribe((res) => {
        const data = {
          game_account: row.game_account,
          game_provider_id: (res.find((x) => { return x.code === ( row?.game_provider_code) }))?.id,
          game_provider_code: row?.game_provider_code,
          username: row?.username,
          member_account_id: row?.member_account_id,
          start_date_time: row.start_datetime ,
          end_date_time: row.end_datetime ,
          type: 'member-report',
          viewType: 'dialog',
          timezone: this.timezone,
          currency_code: row.currency,
          game_type: (res.find((x) => { return x.code === row.game_provider_code })).type,
          merchant: this.data.rebateRelease.site_id,
          from: 'rebate-releases-2.0',
        };
        this.openDialogBy(MemberReportDetailsDialogComponent, data);
      });
    }
    else if (type === 'show-promo-turnover-details') {
      const data = {
        game_account: row.game_account,
        game_provider_code: row?.game_provider_code,
        category_code: row?.category_code,
        game_provider_sub_categories_name : row?.game_provider_sub_categories_name,
        username: row?.username,
        member_account_id: row?.member_account_id,
        start_datetime: row.start_datetime ,
        end_datetime: row.end_datetime ,
        currency_code: row.currency,
        timezone: this.timezone,
        rebate_release_id : row.rebate_release_id,
        currency: this.data.rebateRelease.currency
      };
      this.openDialogBy(PromotionTurnoverDetailDialogComponent, data);
    }

  }


  private openDialogBy(componentRef: any, data?: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data
    });
  }

}
