import { MessageTemplate } from '@core/models/message-template.model';
import { ApiResponse } from '@core/models/api-response.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class MessageTemplateDataService extends DefaultDataService<MessageTemplate> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  locales$ = new BehaviorSubject([]);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MessageTemplate', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MessageTemplate[]> {
    return this.http.get<ApiResponse>('/messagetemplate').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<MessageTemplate[]> {
    return this.http.get<ApiResponse>(`/messagetemplate${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getMessageTemplateSection(action: 'create' | 'edit' = 'edit') {
    return this.http.get<ApiResponse>(`/messagetemplatesections?action=${action}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(messageTemplate: MessageTemplate): Observable<MessageTemplate> {
    return this.http.post<ApiResponse>(`/messagetemplate`, messageTemplate).pipe(
      tap(res => this.messages$.next(res.message)),
      concatMap((res) => this.http.get(`/messagetemplate/${res.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get<ApiResponse>(`/messagetemplate/${id}`).pipe(
      map(res => res.data)
    );
  }

  getMessageTemplateList(type: number, section: number, currency_ids = [], status?: number): Observable<Dropdown[]> {
    let params = `type=${type}&section=${section}`;
    currency_ids.forEach((currency_code, index) => {
      params += `&currency_code[${index}]=${currency_code}`;
    });
    if (status != undefined) {
      params += `&status=${status}`;
    }

    return this.http.get<ApiResponse>(`/messagetemplates?${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getMessageTemplatVariables(sectionID: number, name?: string): Observable<Dropdown[]> {
    let params = `/messagetemplatevariables?section_id=${sectionID}`;

    if (name) {
      params += `&name=${name}`;
    }

    return this.http.get<ApiResponse>(params).pipe(
        map(res => res.data?.rows)
    );
  }

  getGenericVariables(): Observable<MessageTemplate> {
    return this.http.get<ApiResponse>(`/messagetemplate/generic`).pipe(
      map(res => res.data)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
