<!-- Create Mode -->
<ng-container *ngIf="mode === 'Create'">
  <ng-container *ngTemplateOutlet="formContent"></ng-container>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
</ng-container>

<!-- Edit Mode -->
<ng-container *ngIf="mode === 'Edit'">
  <div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'Send Message' | translate }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body p-5">
        <ng-container *ngTemplateOutlet="formContent"></ng-container>
      </div>
      <div class="modal-footer d-flex justify-content-between w-100">
        <button *ngIf="canCancelScheduledMessage && data.status == 1" class="btn btn-danger" (click)="onCancel()" [disabled]="buttonLoading">
          <i [class]="buttonLoading? 'fas fa-ban fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Cancel
        </button>
        <div class="d-flex">
          <button *ngIf="canSendMessageNow && data.status == 1" class="btn btn-primary mr-2" (click)="onSendNow()" [disabled]="buttonLoading">
            <i [class]="buttonLoading? 'fas fa-paper-plane fa-spin p-0 mr-2' : 'fa fa-paper-plane'"></i>Send Now
          </button>
          <button *ngIf="canUpdateMessageSettings && data.status == 1" class="btn btn-success" (click)="onSend()" [disabled]="buttonLoading">
            <i [class]="buttonLoading? 'fas fa-save fa-spin p-0 mr-2' : 'fa fa-save'"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
</ng-container>


<!-- Form Content (Shared Use) -->
<ng-template #formContent>
  <form class="row align-items-center" [formGroup]="sendMessageForm">

    <!-- Histories button -->
    <div class="col-md-12 form-group d-flex" *ngIf="canViewSendMessageHistories && mode === 'Create'">
      <button class="btn btn-secondary ml-auto btn-light" (click)="onOpenDialog('history')">
        <i class="fas fa-history"></i>
        {{ 'Histories' | translate }}</button>
    </div>
  
    <!-- Region -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ 'Region' | translate }} <span class="text-danger">*</span></label>
        </div>
        <kt-dropdown-wo-lazyload
          class="col-10"
          [form]='sendMessageForm'
          [dropdownList]='regions'
          [dropdownSettings]='regionsDropdownSettings'
          [formName]="'settings_currency_ids'"
          [selectionAttributes]="'id'"
          [selectedItems]='regionsSelectedItems'
          [ngClass]="{ 'is-invalid': checkValidation && sendMessageForm.controls.settings_currency_ids.errors }"
          (selectedItemsChanged)="onRegionChanged($event)"
        ></kt-dropdown-wo-lazyload>
      </div>
    </div>
  
    <!-- Member Group -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ 'Member Group' | translate }} <span class="text-danger">*</span></label>
        </div>
        <div class="col-10 kt-form__control">
          <kt-dropdown-wo-lazyload
            [form]="sendMessageForm"
            [dropdownList]="memberGroupDropdownList"
            [dropdownSettings]="memberGroupDropdownSettings"
            [formName]="'member_group_ids'"
            [selectionAttributes]="'id'"
            [selectedItems]="memberGroupSelectedItems"
            [ngClass]="{ 'is-invalid': checkValidation && sendMessageForm.controls.member_group_ids.errors }"
            (selectedItemsChanged)="onSelectMemberGroup($event)"
          >
          </kt-dropdown-wo-lazyload>
        </div>
      </div>
    </div>
  
    <!-- Member -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ 'Members' | translate }} <span class="text-danger">*</span></label>
        </div>
  
        <div class="kt-form__control" [ngClass]="{'col-6': mode === 'Create', 'col-4': mode === 'Edit'}">
          <kt-member-dropdown
            class="dropdown-maxheight"
            [form]="sendMessageForm"
            [dropdownSettings]="messageMemberDropdownSettings"
            [formName]="'member_account_ids'"
            [selectionAttributes]="'id'"
            [selectedItems]="selectedMembers"
            [isFilterByMembergroupId]="true"
            [membergroupId]="memberGroupSelectedItems"
            [ngClass]="{ 'is-invalid': checkValidation && sendMessageForm.controls.member_account_ids.errors }"
          ></kt-member-dropdown>
          <div class="col mt-2">
            <input
              type="checkbox"
              formControlName="send_to_all_members"
              (change)="onSendToAllMembersChange($event)"
            />
            <span class="pl-2 pr-2">{{ 'Send to All Members' | translate }}</span>
          </div>
        </div>
  
        <div class="kt-form__control" [ngClass]="{'col-2': mode === 'Create', 'col-3': mode === 'Edit'}">
          <a [href]="'assets/' + templateFileName" target="_blank" [download]="templateFileName" class="btn btn-primary download-template w-100">
            <i class="fa fa-file-csv mr-2"></i> {{ 'Download Template' | translate }}
          </a>
        </div>
        <div class="kt-form__control" [ngClass]="{'col-2': mode === 'Create', 'col-3': mode === 'Edit'}">
          <div class="d-flex kt-form__control" [ngClass]="{'disabled': sendMessageForm.get('member_account_ids').value.length > 0}">
            <span style="cursor: pointer;" class="col-5 col-form-label btn btn-import import-button text-center px-0" 
              [ngClass]="(buttonLoading || sendMessageForm.get('member_account_ids').value.length > 0 || sendMessageForm.get('send_to_all_members').value == 1) ? 'disabled' : 'btn-import'"
              (click)="!buttonLoading && sendMessageForm.get('member_account_ids').value.length === 0 ? myFile.click() : null"
            >
              <ng-container>
                <i class="fas fa-file-import"></i> {{ 'Import' | translate }}
                <input type="file" hidden id="file" accept=".csv" (change)="onFileUpload($event)" [disabled]="buttonLoading" #myFile>
              </ng-container>
            </span>
            <div class="col-7 d-flex align-items-center import-button" *ngIf="file || attachedFile" (click)="!buttonLoading && sendMessageForm.get('member_account_ids').value.length === 0 && (sendMessageForm.get('send_to_all_members').value === 0 || sendMessageForm.get('send_to_all_members').value === null) ? myFile.click() : null">
              <span class="text-truncate flex-grow-1">{{ file ? file.name : attachedFile.name }}</span>
              <i style="cursor: pointer;" class="fa fa-times ml-2" (click)="onRemoveFile()" aria-hidden="true"></i>
            </div>
            <span style="cursor: pointer;" class="col-7 col-form-label import-button text-center" *ngIf="!file && !attachedFile" (click)="!buttonLoading && sendMessageForm.get('member_account_ids').value.length === 0 && (sendMessageForm.get('send_to_all_members').value === 0 || sendMessageForm.get('send_to_all_members').value === null) ? myFile.click() : null">{{ 'Choose File' | translate }}</span>
          </div>
          <div class="col-12 text-right mt-2" *ngIf="attachedFile">
            <i class="fas fa-download"></i>&nbsp;&nbsp;<a [href]="attachedFile.url" [download]="attachedFile.name">{{ 'Download Imported File' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Date/Time -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ 'Schedule Date/Time' | translate }}</label>
        </div>
        <div class="col-10 kt-form__control date-time-picker-div">
          <app-date-time-picker
            [form]="sendMessageForm"
            [hasRanges]="false"
            [singleDatePicker]="true"
            [minDate]="minDate"
            [placeholder]="'Leave empty for immediate send'"
          ></app-date-time-picker>
        </div>
      </div>
    </div>
  
    <!-- Message Template -->
    <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
      <div class="kt-form__group--inline row">
        <div class="col-2 kt-form__label col-form-label">
          <label>{{ "Message Template" | translate }}</label>
        </div>
        <div class="col-2 kt-form__control pr-0">
          <kt-dropdown-wo-lazyload
            [form]="sendMessageForm"
            [dropdownList]="messageTemplateSectionDropdownList"
            [dropdownSettings]="messageTemplateSectionDropdownSettings"
            [formName]="'section'"
            [selectionAttributes]="'section'"
            [selectedItems]="selectedMessageTemplateSection"
            (selectedItemsChanged)="onMessageTemplateSectionChanged($event)"
          ></kt-dropdown-wo-lazyload>
        </div>
        <div class="col-6 kt-form__control pl-0">
          <kt-dropdown-wo-lazyload
            [form]="sendMessageForm"
            [dropdownList]="messageTemplateDropdownList"
            [dropdownSettings]="inboxMessageTemplateDropdownSettings"
            [formName]="'message_template'"
            [selectionAttributes]="'id'"
            [selectedItems]="selectedMessageTemplate"
            [isSMSMessageTemplate]="true"
            (selectedItemsChanged)="onMessageTemplateChanged($event)"
          ></kt-dropdown-wo-lazyload>
        </div>
        <div class="col-2 kt-form__control pl-0">
          <button type="button" class="btn btn-brand w-100" (click)="onOpenDialog('message_template')"><i class="fa fa-plus" aria-hidden="true"></i>{{ ('Message Template' | translate) }}</button>
        </div>
      </div>
    </div>
  
    <!-- Message Subject & Body -->
    <ng-container *ngIf="languages.length > 0; else noLanguages" >
      <mat-tab-group animationDuration="0ms" class="col-12 form-group locale-form" formGroupName="details">
        <mat-tab label="{{language.code}}" *ngFor="let language of languages; let i = index" [formGroupName]="language.id" (click)="$event.preventDefault()">
          <div class="col-12 form-group" [ngClass]="{ 'disabled': disableMessageField }">
            <label class="control-label">{{ 'Subject' | translate }} <span class="text-danger">*</span></label>
            <input type="text" formControlName="subject" class="col-12 form-control" (input)="updateDetailsValidators()">
          </div>
          <div class="col-12 form-group" [ngClass]="{ 'disabled': disableMessageField }">
            <label>{{ 'Message' | translate }} <span class="text-danger">*</span></label>
            <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="message" (change)="updateDetailsValidators()"></ckeditor>
            <div>
              <label class="usable-variables-label">{{ 'Usable variables' | translate }}: </label>
              <div *ngFor="let usableVariable of usableVariables; let last = last">
                  <button class="usable-variables-text" (click)="onSelectUsableVariable(usableVariable, i)">{{ usableVariable }}</button>
                  <div *ngIf="!last" class="usable-variables-text">,</div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #noLanguages>
      <div style="min-height: 300px; display: block; opacity: 0;">no language</div>
    </ng-template>
  
    <!-- Send Button -->
    <div *ngIf="mode === 'Create'" class="col-12 form-group d-flex flex-row-reverse mb-0">
      <button *ngIf="canSendMessage"  type="button" [disabled]="buttonLoading || !sendMessageForm.valid" class="btn btn-primary" (click)="onSend()">
        <i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-paper-plane'"></i>{{ 'Send Message' | translate }}
      </button>
    </div>
  
  </form>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
</ng-template>

