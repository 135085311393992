import { EditorService } from '@core/services/editor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChildren, QueryList, OnDestroy, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { Subscription, forkJoin, of } from 'rxjs';
import { map, tap, catchError, delay } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { PromotionContent } from '@core/models/promotion-content.model';
import { PromotionContentEntityService } from '../services/promotion-content-entity.service';
import { PromotionContentDataService } from '../services/promotion-content-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import * as moment from 'moment-timezone';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import Swal from 'sweetalert2';
import { PromotionCodeDataService } from '../../promotion-codes/services/promotion-code-data.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { MiniGameDataService } from '../../../games/mini-games/service/mini-game-data.service';

type DisplaySuffixTypes = 'image' | 'content';

@Component({
  selector: 'kt-promotion-content-edit',
  templateUrl: './promotion-content-edit.component.html',
  styleUrls: ['./promotion-content-edit.component.scss']
})

export class PromotionContentEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    promoCategories: this.dropdownHttpService.promoCategories,
    promoCategories2: this.dropdownHttpService.promoContentCategories,
    applyActions: this.dropdownHttpService.promoApplyActions,
    displayTypes: this.dropdownHttpService.displayTypes,
    locales$: this.promotionContentDataService.locales$,
    promotionIds: this.promotionCodeDataService.getWithQuery(`?&paginate=false`),
    promotionTypes: this.promotionCodeDataService.promotionTypes,
    linkTypes: this.dropdownHttpService.linkType,
    categories: [],
    gameProviders: [],
    memberVisibility: this.dropdownHttpService.member_visibility_types,
  };

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  buttonLoading = false;
  promoDetails: any;
  dateTimeStack = [];

  showApplyAction = false;
  showSelectForm = false;
  showSelectLink = false;
  showLink = null;
  showLaunchGameLink = false;
  showGames = false;
  showUsableLink = false;
  categoriesLoading = false;
  gamesLoading = false;

  disabledButton = false;
  firstTimeOpen = true;

  variable = null;
  gameLaunchLink: any;
  submitGameLaunchLink: any;

  formFields = [];
  formFieldsSelectedItems = [];
  formFieldsDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: false,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    noDataLabel: '',
    maxHeight: 150,
    showCheckbox: false
  };
  checkValidation = false;

  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.promotionContentDataService.messages$;
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  imagePreview = [];
  languages: { id: number; code: string; name: string }[] = [];

  private datePickerSubscription = new Subscription();
  private localesSub = new Subscription();
  private uploadSub = new Subscription();
  refreshStatus: boolean;

  categoriesDropdownList = [];
  categoriesSelectedItems = [];
  categoriesDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };

  providersDropdownList = [];
  providersSelectedItems = [];
  providersDropdownSettings = {
    text: 'Game Provider',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    singleSelection: true,
    classes: 'dropdown',
    primaryKey: 'code',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    searchBy: ["name"],
    maxHeight: 150,
  };

  gameCategoriesDropdownList = [];
  gameCategoriesSelectedItems = [];
  gameCategoriesDropdownSettings = {
    text: 'Game Category',
    enableFilterSelectAll: false,
    enableSearchFilter: false,
    singleSelection: true,
    classes: 'dropdown',
    primaryKey: 'category_code',
    labelKey: 'category_name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true,
    maxHeight: 150,
  };

  gamesDropdownList = [];
  gamesSelectedItems = [];
  gamesDropdownSettings = {
    text: 'Game',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    singleSelection: true,
    classes: 'dropdown',
    primaryKey: 'code',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    searchBy: ["name", "code"],
    disabled: true,
    maxHeight: 150,
  };

  usableLinks = [
    'referral',
    'vip'
  ];

  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionContent: any; mode: string; },
    public dialogRef: MatDialogRef<PromotionContentEditDialogComponent>,
    private datePipe: DatePipe,
    private promotionContentService: PromotionContentEntityService,
    private promotionContentDataService: PromotionContentDataService,
    private promotionCodeDataService: PromotionCodeDataService,
    private dropdownHttpService: DropdownHttpService,
    private uploadService: UploadHttpService,
    private editorService: EditorService,
    private gameProviderHttpService: GameProviderHttpService,
    private miniGameDataService: MiniGameDataService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.fillInForms.subscribe(res => {
      Object.keys(res).forEach(key => {
        this.formFields.push({
          name: res[key],
          id: key
        });
        if (this.data.promotionContent?.content.fill_in_form.includes(key)) {
          this.formFieldsSelectedItems.push({
            name: res[key],
            id: key
          });
        }
      });
    });

    this.localesSub = this.dropdown.locales$.pipe(tap(res => this.languages = res)).subscribe(res => {
      res.forEach(item => {
        if (this.data.mode === 'edit') {
          let language_id = item.id;
          let details = null;
          let dateTime = null;
          if (this.data.promotionContent.details[language_id]) {
            Object.keys(this.data.promotionContent.details[language_id]).forEach((key) => {
              if (key === 'start' || key === 'end' || key === 'expire_at' || key === 'publish_at') {
                dateTime = {
                  ...dateTime,
                  [key]: new Date(new Date(this.data.promotionContent.details[language_id][key]).getTime() + this.offset - this.clientOffset)
                };
              }
              details = {
                ...details,
                [key]:
                  key === 'start' || key === 'end' || key === 'expire_at' || key === 'publish_at' ?
                    moment(new Date(new Date(this.data.promotionContent.details[language_id][key]).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss')
                    : this.data.promotionContent.details[language_id][key]
              };
            });
          } else {
            dateTime = {
              start: null,
              end: null,
              expire_at: null,
              publish_at: null,
            }
          }
          this.dateTimeStack.push(dateTime);
          this.promoDetails = {
            ...this.promoDetails,
            [language_id]: {
              ...details
            }
          };
        }
      })
    });
    this.formInit();

    this.dropdown.promoCategories.subscribe(
      res => {
        this.categoriesDropdownList = res;
        if (this.data.promotionContent) {
          this.categoriesSelectedItems = this.categoriesDropdownList.filter(item =>
            this.data.promotionContent.category_id.includes(item.id)
          );
        }
      }
    );

    this.dropdownHttpService.gameProviders.subscribe(res => {
      this.providersDropdownList = res;
      if (this.data.mode == 'edit') {
        if (this.data.promotionContent.content.apply_action == 3) {
          this.form.controls['max_application'].disable();
          if (this.data.promotionContent.content.link) {
            if (this.data.promotionContent.content.link.includes('/launch') && this.firstTimeOpen == true) {
              this.showSelectLink = true;
              this.showLaunchGameLink = true;
              this.showGames = true;
              let datas = this.data.promotionContent.content.link.split('/');
              this.form.patchValue({
                link_type: 3,
              });

              this.providersSelectedItems = [
                this.providersDropdownList.find((v) => v.code === datas[2])
              ];
              this.setGameCategoryList(this.providersSelectedItems[0].id);
            } else if (this.data.promotionContent.content.link.includes('external')) {

              this.showSelectLink = true;
              this.showLink = true;
              this.form.patchValue({
                link_type: 2,
              });

            } else {

              this.showSelectLink = true;
              this.showLink = true;
              this.showUsableLink = true;
              this.form.patchValue({
                link_type: 1,
              });

            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.localesSub.unsubscribe();
    this.uploadSub.unsubscribe();
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    const subs = [];
    this.languages.map((element, index) => {
      subs.push(this.buildPromotionContentDate((index === 0) ? index : (index * 4), element.id, 'start'));
      subs.push(this.buildPromotionContentDate((index === 0) ? 1 : ((index * 4) + 1), element.id, 'end'));
      subs.push(this.buildPromotionContentDate((index === 0) ? 2 : ((index * 4) + 2), element.id, 'publish_at'));
      subs.push(this.buildPromotionContentDate((index === 0) ? 3 : ((index * 4) + 3), element.id, 'expire_at'));
    });
    this.datePickerSubscription = forkJoin(subs).subscribe();
  }

  onAllowApply(event: any) {
    if (event.target.checked) {
      this.showApplyAction = true;
    } else {

      this.showApplyAction = false;
      this.showSelectForm = false;
      this.showSelectLink = false;
      this.form.patchValue({
        apply_action: 0,
        fill_in_form: [],
        link: null,
        max_application: 0
      });

    }
  }

  onApplyAction(event: any) {
    if (+event.srcElement.value === 1) {

      this.showSelectForm = true;
      this.showSelectLink = false;
      this.showLink = false;
      this.showGames = false;
      this.showLaunchGameLink = false;
      this.disabledButton = false;
      this.providersSelectedItems = [];
      this.gameCategoriesSelectedItems = [];
      this.gamesSelectedItems = [];
      this.form.controls['max_application'].enable();

    } else if (+event.srcElement.value === 3) {

      this.showSelectLink = true;
      this.showSelectForm = false;
      this.showLink = false;
      this.showLaunchGameLink = false;
      this.disabledButton = true;
      this.form.controls['max_application'].disable();
      this.form.patchValue({
        fill_in_form: [],
        link_type: null,
        link: null,
        max_application: 0,
      });

    } else {

      this.showSelectForm = false;
      this.showSelectLink = false;
      this.showLink = false;
      this.showLaunchGameLink = false;
      this.disabledButton = false;
      this.showGames = false;
      this.providersSelectedItems = [];
      this.gameCategoriesSelectedItems = [];
      this.gamesSelectedItems = [];
      this.form.controls['max_application'].enable();
      this.form.patchValue({
        fill_in_form: [],
        link_type: null,
        link: null,
      });

    }
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first.nativeElement.focus()
      )).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onUploadFile(event: any, languageId: number) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'promotions');
    this.uploadSub = this.uploadService.upload(formData).subscribe(res => {
      this.imagePreview = res;
      this.form.patchValue({
        details: { [languageId]: this.buildMediaPropertyBy('content_type', languageId, 'image') }
      });
      this.onFocusField();
    });
  }

  onSave(promotionContent: PromotionContent, mode?: string) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.checkValidation = true;
    this.isCheckboxValid();

    if (this.form.valid) {
      this.buttonLoading = true;
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });

      if (this.form.value.game_code != null) {
        this.submitGameLaunchLink = '/launch/' + this.providersSelectedItems[0].code + '/' + this.gameCategoriesSelectedItems[0].category_code + '/' + this.form.value.game_code;
      } else {
        this.submitGameLaunchLink = '/launch' + this.gameLaunchLink;
      }

      const data = {
        id: promotionContent ? promotionContent.id : null,
        ...this.form.value,
        link: this.form.value.link_type ? this.form.value.link_type == 3 ? this.submitGameLaunchLink : this.form.value.link : null,
        details: this.convertData(this.form.value.details)
      };

      if (data['allow_apply'] == 1 && data['apply_action'] == 0) {
        data['apply_action'] = 2;
      }

      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      switch (mode) {
        case 'edit':
          this.subscription = this.promotionContentService.update(data).pipe(
            tap((res: any) => {
              this.messages$.next([...res.message]);
              this.buttonLoading = false;
              this.checkValidation = false;
              this.disabledButton = false;
              this.onCloseDialog();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.disabledButton = false;
              this.form.setErrors(null);
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = forkJoin([
            this.promotionContentDataService.add(data).pipe(
              tap((res: any) => {
                this.form.setErrors(null);
                this.buttonLoading = false;
                this.checkValidation = false;
                this.disabledButton = false;
                this.onCloseDialog();
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.checkValidation = false;
                this.disabledButton = false;
                this.form.setErrors(null);
                throw error;
              })
            ),
            this.promotionContentDataService.messages$
          ]).subscribe();
          break;
      }
      this.refreshStatus = true;
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  isCheckboxValid() {
    const condition = (this.form.controls.content_type['controls'][1].value === null || this.form.controls.content_type['controls'][1].value === false) &&
      (this.form.controls.content_type['controls'][2].value === null || this.form.controls.content_type['controls'][2].value === false);
    if (condition) {
      this.form.controls.content_type.setErrors({ 'invalid': true });
    } else {
      this.form.controls.content_type.valid;
    }
  }

  onDeleteLocale(data: any, event: any) {
    if (data !== undefined) {
      Swal.fire({
        title: `Are you sure you want to delete contents for ${data.settings_locale_code}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.subscription = this.promotionContentDataService.deleteLocale(data.id).pipe(
            tap((res: any) => {
              this.messages$.next([res]);
              let details = null;
              this.promoDetails = {
                ...this.promoDetails,
                [data.settings_locale_id]: {
                  ...details
                }
              };
              this.formInit();
              event.target.closest('mat-icon').remove();
            }),
            catchError((error) => {
              this.form.setErrors(null);
              this.form.enable();
              throw error;
            })
          ).subscribe();
        }
      });
    }
  }

  getMediaSource(languageId: number, propertySuffix: DisplaySuffixTypes = 'image') {
    let media: any;
    media = this.form.get(`details.${languageId}.${propertySuffix}`).value;
    return media;
  }

  private convertData(data: any) {
    let details = JSON.parse(JSON.stringify(data));

    Object.keys(details).forEach(ind => {
      Object.keys(details[ind]).forEach(key => {


        if (key === 'start' || key === 'end' || key === 'publish_at' || key === 'expire_at') {
          if (details[ind][key] !== "" && details[ind][key] !== null && details[ind][key] !== "0000-00-00 00:00:00") {
            details[ind][key] = moment(new Date(new Date(details[ind][key]).getTime() - this.offset)).format('YYYY-MM-DD HH:mm:ss')
          } else {
            details[ind][key] = null;
          }
        }
        if (key === 'mobile_content') {
          if (this.form.value.content_type[2]) {
            details[ind][key] = details[ind]['web_content'];
          }
          else {
            details[ind][key] = null;
          }
        }
      });


    });
    return details;
  }

  private buildMediaPropertyBy(property: string, languageId: number, propertySuffix?: DisplaySuffixTypes) {
    let output = {};
    output = { ...output, [propertySuffix]: this.imagePreview[0] };
    output = { ...output, settings_locale_id: languageId };
    return output;
  }

  private formInit() {
    const validatorOptions = []; // [Validators.required];
    const status = 1;

    const buildDisplayTypes = (isParent = false, propertySuffix?: DisplaySuffixTypes) => {
      let displayTypes = {};
      this.dropdown.displayTypes.map(element => {
        if (isParent) {
          displayTypes = { ...displayTypes, [element.id]: new FormControl(null) };
        }
      });
      return displayTypes;
    };

    const buildSubDetails = () => {
      let details = {};
      const isEditMode = () => {
        let edit = {};
        if (this.data.mode === 'edit') {
          edit = { ...edit, id: new FormControl(null) };
        }
        return edit;
      };
      this.languages.map(element => {
        const detailsGroup = new FormGroup({
          settings_locale_id: new FormControl(null, validatorOptions),
          title: new FormControl(null, validatorOptions),
          description: new FormControl(null, validatorOptions),
          start: new FormControl(null, validatorOptions),
          image: new FormControl(null, validatorOptions),
          end: new FormControl(null),
          publish_at: new FormControl(null),
          expire_at: new FormControl(null),
          content: new FormControl(null),
          promotion_type: new FormControl(null, validatorOptions),
          promotion_amount: new FormControl(null),
          ...isEditMode()
        });
        details = { ...details, [element.id]: detailsGroup };
      });
      return details;
    };

    this.form = new FormGroup({
      code: new FormControl(null, [Validators.required]),
      promotion_id: new FormControl(null),
      category_id: new FormControl(null, [Validators.required]),
      content_type: new FormGroup(buildDisplayTypes(true)),
      member_visibility: new FormControl(0, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }, validatorOptions),
      position: new FormControl(99, [Validators.min(1), Validators.required]),
      allow_apply: new FormControl(null),
      apply_action: new FormControl(0),
      max_application: new FormControl(0, validatorOptions),
      fill_in_form: new FormControl(null),
      link_type: new FormControl(null),
      link: new FormControl(null),
      game_provider_code: new FormControl(null),
      game_category_code: new FormControl(null),
      game_code: new FormControl(null),
      details: new FormGroup(buildSubDetails()),
    });

    if (this.data.mode === 'edit') {
      const content = this.data.promotionContent.content;
      const details = this.promoDetails;
      const contentTypes = content.content_type;
      const buildContentType = () => {
        let subType = {};
        contentTypes.map(typeId => {
          this.dropdown.displayTypes.map((display) => {
            if (typeId === display.id) {
              subType = { ...subType, [display.id]: true };
            }
          });
        });
        return { content_type: subType };
      };
      this.form.patchValue({ ...content, ...buildContentType(), details });

      content.allow_apply ? this.showApplyAction = true : this.showApplyAction = false;
      content.apply_action == 1 ? this.showSelectForm = true : this.showSelectForm = false;

      this.disabledButton = false;
    }

    // Declare on change listener for value game_provider_code
    this.form.get('game_provider_code').valueChanges.subscribe((value) => {
      this.gamesSelectedItems = [];
      this.gamesDropdownSettings = {
        ...this.gamesDropdownSettings,
        disabled: true,
      };
      this.dropdown.categories = [];
      this.gameCategoriesSelectedItems = [];
      this.gameCategoriesDropdownSettings = {
        ...this.gameCategoriesDropdownSettings,
        disabled: true,
      };

      if (value !== null) {
        this.form.patchValue({ game_category_code: null });
        const selectedGameProvider = this.providersDropdownList.find(x => x.code === value);
        if (selectedGameProvider) {
          this.setGameCategoryList(selectedGameProvider.id);
          this.gameLaunchLink = '/' + this.providersSelectedItems[0].code;
        }
      } else {
        this.gameLaunchLink = '';
        this.disabledButton = true;
      }
    });

    // Declare on change listener for value game_category_code
    this.form.get('game_category_code').valueChanges.subscribe((value) => {
      this.gamesSelectedItems = [];

      if (value !== null) {

        this.form.patchValue({ game_code: null });
        this.getGameList(this.gameCategoriesSelectedItems[0].category_code, this.providersSelectedItems[0].code);
        this.gameLaunchLink = '/' + this.providersSelectedItems[0].code + '/' + this.gameCategoriesSelectedItems[0].category_code;

      } else if (value == null && this.providersSelectedItems.length == 0) {

        this.gameLaunchLink = '';
        this.disabledButton = true;
        this.gamesDropdownSettings = {
          ...this.gamesDropdownSettings,
          disabled: true,
        };

      } else {

        this.gameLaunchLink = '/' + this.providersSelectedItems[0].code;
        this.gamesDropdownSettings = {
          ...this.gamesDropdownSettings,
          disabled: true,
        };
        this.disabledButton = true;

      }
    });

    // Declare on change listener for value game_code
    this.form.get('game_code').valueChanges.subscribe((value) => {
      if (value !== null) {
        this.disabledButton = false;
        this.gameLaunchLink = '/' + this.providersSelectedItems[0].code + '/' + this.gameCategoriesSelectedItems[0].category_code + '/' + this.gamesSelectedItems[0].code;
      } else if (value == null && this.providersSelectedItems.length == 0 && this.gameCategoriesSelectedItems.length == 0) {

        this.gameLaunchLink = '';
        this.gamesDropdownSettings = {
          ...this.gamesDropdownSettings,
          disabled: true,
        };

      } else {
        this.disabledButton = true;
        this.gameLaunchLink = '/' + this.providersSelectedItems[0].code + '/' + this.gameCategoriesSelectedItems[0].category_code;
      }
    });

    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

  private buildPromotionContentDate(index: number, tabId: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, (index % 2) === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({
          details: { [tabId]: { [formKey]: date } }
        });
      })
    );
  }

  onChangeValidate(event: any, key) {
    let content_type = event.target.value;
    let itemControls = <FormGroup>this.form.controls['details'];
    let itemFormGroup = <FormGroup>itemControls.controls[key];

    if (content_type.length == 0) {
      /// remove validators if the title name is null
      itemFormGroup.controls["image"].clearValidators();
      itemFormGroup.controls["image"].reset();
      itemFormGroup.controls["image"].setErrors(null);
      itemFormGroup.controls["description"].clearValidators();
      itemFormGroup.controls["publish_at"].clearValidators();
      itemFormGroup.controls["expire_at"].clearValidators();
    } else {
      /// add validators if the title name is not null
      if (content_type.length > 0) {
        itemFormGroup.controls["image"].setValidators(Validators.required);
        var imageValue = itemFormGroup.controls["image"].value ? itemFormGroup.controls["image"].value : null;
        if (imageValue) itemFormGroup.controls["image"].setValue(imageValue);
        if (!imageValue) itemFormGroup.controls["image"].reset();
        itemFormGroup.controls["image"].setErrors(null);
        itemFormGroup.controls["description"].setValidators(Validators.required);
        itemFormGroup.controls["publish_at"].setValidators(Validators.required);
        itemFormGroup.controls["expire_at"].setValidators(Validators.required);
      }
    }
    itemFormGroup.controls["image"].updateValueAndValidity();
    itemFormGroup.controls["description"].updateValueAndValidity();
    itemFormGroup.controls["publish_at"].updateValueAndValidity();
    itemFormGroup.controls["expire_at"].updateValueAndValidity();
  }

  onChangeLinkFormat(event: any) {
    if (+event.srcElement.value === 1 || +event.srcElement.value === 2) {
      this.showLaunchGameLink = false;
      this.showGames = false;
      this.showLink = true;
      if (+event.srcElement.value === 1) {
        this.variable = '/'
        this.showUsableLink = true;
      } else {
        this.showUsableLink = false;
        this.variable = '/external?link=';
      }
      this.form.patchValue({
        link: this.variable
      });
      this.disabledButton = false;
      this.providersSelectedItems = [];
      this.gameCategoriesSelectedItems = [];
      this.gamesSelectedItems = [];

    } else {
      this.showLink = false;
      this.showLaunchGameLink = true;
      this.disabledButton = true;
      this.showGames = true;
      this.showUsableLink = false;
      this.gamesDropdownSettings = {
        ...this.gamesDropdownSettings,
        disabled: true,
      };
      this.form.patchValue({
        game_category_code: null,
        game_provider_code: null,
        game_code: null
      });
    }
  }

  private setGameCategoryList(game_provider_id: number) {
    this.categoriesLoading = true;
    this.gameProviderHttpService.getGameCategory(game_provider_id).subscribe(res => {
      this.gameCategoriesDropdownList = res;

      if (this.data.mode == 'edit') {
        if (this.data.promotionContent.content.apply_action == 3) {
          if (this.data.promotionContent.content.link.includes('/launch') && this.firstTimeOpen == true) {
            let datas = this.data.promotionContent.content.link.split('/');
            this.gameCategoriesSelectedItems = [
              this.gameCategoriesDropdownList.find((v) => v.category_code === datas[3])
            ];
            this.gameCategoriesDropdownSettings = {
              ...this.gameCategoriesDropdownSettings,
              disabled: false,
            };

            this.gameLaunchLink = '/' + this.providersSelectedItems[0].code + '/' + this.gameCategoriesSelectedItems[0].category_code;

            if (datas[4] != undefined) {
              this.getGameList(this.gameCategoriesSelectedItems[0].category_code, this.providersSelectedItems[0].code);
            } else {
              this.firstTimeOpen = false;
            }
          }
        }
      }
    });

    this.categoriesLoading = false;

    this.gameCategoriesDropdownSettings = {
      ...this.gameCategoriesDropdownSettings,
      disabled: false,
    };
    this.gamesDropdownSettings = {
      ...this.gamesDropdownSettings,
      disabled: true,
    };
  }

  getGameList(game_category_code: string, game_provider_code: string) {
    this.gamesLoading = true;
    this.miniGameDataService.getWithQuery(`?paginate=false&status=1&category_code=${game_category_code}&game_provider_code=${game_provider_code}`).subscribe(res => {
      this.gamesDropdownList = res;
      this.gamesDropdownList.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });

      if (this.data.mode == 'edit') {
        if (this.data.promotionContent.content.apply_action == 3) {
          if (this.data.promotionContent.content.link.includes('/launch') && this.firstTimeOpen == true) {
            this.gamesDropdownSettings = {
              ...this.gamesDropdownSettings,
              disabled: false,
            };
            let datas = this.data.promotionContent.content.link.split('/');
            this.gamesSelectedItems = [
              this.gamesDropdownList.find((v) => v.code === datas[4])
            ];
            this.gameLaunchLink = '/' + this.providersSelectedItems[0].code + '/' + this.gameCategoriesSelectedItems[0].category_code + '/' + this.gamesSelectedItems[0].code;
            this.firstTimeOpen = false;
          }
        }
      }

      if (res != null && res.length > 0) {

        this.gamesLoading = false;
        this.gamesDropdownSettings = {
          ...this.gamesDropdownSettings,
          disabled: false,
        };

      } else {
        this.gamesLoading = false;
        this.disabledButton = false;
        this.gamesDropdownSettings = {
          ...this.gamesDropdownSettings,
          disabled: true,
        };

      }
    })
  }

  addUsableLink(link: any) {
    switch (link) {
      case 'referral':
        this.variable = '/referral';
        break;
      case 'vip':
        this.variable = '/vip';
        break;
    }

    this.form.patchValue({
      link: this.variable
    });
  }

  addVariableTitle(variable: any, id: any) {
    let title = this.form.value.details[id]['title'] ? this.form.value.details[id]['title'] : '';
    this.form.patchValue({
      details: {
        [id]: {
          title: title + variable,
        }
      }
    });
  }

  addVariableDescriptions(variable: any, id: any) {
    let description = this.form.value.details[id]['description'] ? this.form.value.details[id]['description'] : '';
    this.form.patchValue({
      details: {
        [id]: {
          description: description + variable,
        }
      }
    });
  }

  addVariableContent(variable: any, id: any) {
    let content = this.form.value.details[id]['content'] ? this.form.value.details[id]['content'] : '';
    this.form.patchValue({
      details: {
        [id]: {
          content: content + variable,
        }
      }
    });
  }
}
