<form class="row align-items-center" [formGroup]="form">
    <!-- First Fow -->
    <div class="col-12 row mb-2 pr-0">
        <!-- Username -->
        <div class="col-md-1 kt-form__label col-form-label">
        <label>Username:</label>
        </div>
        <div class="col-md-2 kt-form__control">
        <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
        </div>

        <!-- Promotion Content -->
        <div class="col-md-1 kt-form__label col-form-label">
        <label class="mb-0">Promotion Content:</label>
        </div>
        <div class="col-md-2 kt-form__control" *ngIf="promotionContent else elseIsNull">
        <input type="text" formControlName="promotion_content_code" class="form-control" readonly>
        </div>
        <ng-template #elseIsNull>
        <div class="col-md-2 kt-form__control">
            <input hidden="true" formControlName="promotion_content_code">
            <kt-dropdown-wo-lazyload
            [form] = 'form'
            [dropdownList] = 'promotionContentDropdownList'
            [dropdownSettings] = 'promotionContentDropdownSettings'
            [formName] = "'promotion_content_id'"
            [selectionAttributes] = "'id'"
            [selectedItems] = 'promotionContentSelectedItems'>
            </kt-dropdown-wo-lazyload>
        </div>
        </ng-template>
        <!-- Date Time -->
        <div class="col-md-1 kt-form__label col-form-label">
        <label>Date/Time:</label>
        </div>
        <div class="col-md-5 kt-form__control pr-0">
        <div class="input-group date">
            <div class="input-group-prepend">
                <select formControlName="date_type" class="form-control">
                    <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                </select>
            </div>
            <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="{format: 'YYYY-MM-DD HH:mm:ss'}" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
            <span class="input-group-append">
            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
            </span>
        </div>
        </div>
    </div>

    <!-- Second Fow -->
    <div class="col-12 row mb-2 pr-0">
        <!-- Status -->
        <div class="col-md-1 kt-form__label col-form-label">
        <label>Status:</label>
        </div>
        <div class="col-md-2 kt-form__control">
            <select formControlName="status" class="form-control">
                <option [value]="'all'">All</option>
                <ng-container *ngFor="let item of status | keyvalue">
                <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                </ng-container>
            </select>
        </div>
        <div class="col-md-1 kt-form__label col-form-label">
            <label>Account Type:</label>
        </div>
        <div class="col-md-2 kt-form__control">
            <select formControlName="account_type_condition" class="form-control" >
                <option value="And">AND Condition</option>
                <option value="Or">OR Condition</option>
            </select>
        </div>
        <div class="col-md-3 kt-form__control">
            <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                [formName]="'account_type'" [selectionAttributes]="'id'"
                [selectedItems]='accountTypeSelectedItems'>
            </kt-dropdown-wo-lazyload>
        </div>
    </div>
</form>

<!-- Action Button -->
<div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
<div>
    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
</div>
    <div>
        <button *ngIf="isModal ? canPromotionContentApproveRejectPromotionApplication : canApproveRejectPromotionApplication" class="btn btn-success btn-icon-sm mr-2" (click)="onChangeStatus(selectedPromotionApplication, 1, '')" [disabled]="selectedPromotionApplication.length < 1"><i class="fas fa-check"></i>Approve Selected</button>
        <button *ngIf="isModal ? canPromotionContentApproveRejectPromotionApplication : canApproveRejectPromotionApplication" class="btn btn-danger btn-icon-sm mr-2" (click)="onChangeStatus(selectedPromotionApplication, 2, '')" [disabled]="selectedPromotionApplication.length < 1"><i class="fas fa-ban"></i>Reject Selected</button>
    </div>
</div>

<!-- Content -->
<div class="kt-section">
    <div class="kt-section__content">
        <div class="table-responsive">
        <!-- Table -->
            <table class="table table-bordered table-hover table-striped">
                <!-- Header -->
                <thead>
                <tr>
                    <th><input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()"></th>
                    <th>ID</th>
                    <th>Username</th>
                    <th class="text-center">Locale</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Member Remarks</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Updated By</th>
                    <th class="text-center">Actions</th>
                </tr>
                </thead>
                <!-- Body -->
                <tbody *ngIf="(promotionApplications$ | async) as rows">
                    <tr *ngFor="let row of rows">
                        <td [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id" (change)="onCheck(row, $event)"></td>
                        <td>{{ row.id }}</td>
                        <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_id)">{{ row.username }}</a></td>
                        <td>{{ row.locale }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.email }}</td>
                        <td>{{ row.phone }}</td>
                        <td>{{ row.address }}</td>
                        <td>{{ row.remarks }}</td>
                        <td>
                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ status[+row.status] }}</span>
                        </td>
                        <td>
                            {{ row.created_by ? row.created_by : 'System' }}<br/>
                            <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ timezoneDate(row.created_at, 'YYYY-MM-DD HH:mm') }}</span>
                            <ng-template #createdDateTime>
                                {{ timezoneDate(row.created_at, 'YYYY-MM-DD HH:mm:ss') }}
                            </ng-template>
                        </td>
                        <td>
                            {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ timezoneDate(row.updated_at, 'YYYY-MM-DD HH:mm') }}</span>
                            <ng-template #updatedDateTime>
                                {{ timezoneDate(row.updated_at, 'YYYY-MM-DD HH:mm:ss') }}
                            </ng-template>
                        </td>
                        <td class="text-center">
                        <button *ngIf="isModal ? canPromotionContentApproveRejectPromotionApplication : canApproveRejectPromotionApplication" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onChangeStatus([row.id], status['Approved'], row.username)" matTooltip="Approve" [disabled]="row.status == 1"><i  class="fas fa-check"></i></button>
                        <button *ngIf="isModal ? canPromotionContentApproveRejectPromotionApplication : canApproveRejectPromotionApplication" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onChangeStatus([row.id], status['Rejected'], row.username)" matTooltip="Reject" [disabled]="row.status == 2"><i  class="fas fa-ban"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
        </div>
        <!-- Pagination -->
        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
        </ngb-pagination>
        <div class="kt-pagination__toolbar">
            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
            </select>
            <span class="pagination__desc">
            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
            </span>
        </div>
        </div>
    </div>
</div>


<kt-swal-alert [message]="messages$ | async" (confirmed)="onReload($event)"></kt-swal-alert>
