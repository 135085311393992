import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class VipVerificationHttpService {

    pagination: Pagination;
    messages$ = new Subject<any[]>();
    data$ = new Subject<any[]>();

    constructor(private http: HttpClient) { }

    import(data: FormData) {
        return this.http.post<ApiResponse>(`/vipverification/import`, data).pipe(
            tap(res => this.messages$.next(res.message)),
            map((res) => res.data)
        );
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }

}
