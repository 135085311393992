import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromotionCurrency } from '@core/models/promotion-currency.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { PromotionCurrencyDataService } from '../services/promotion-currency-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionCurrencyEntityService } from '../services/promotion-currency-entity.service';
import { tap, catchError, map, filter } from 'rxjs/operators';
import { Status } from '@core/enums/status.enum';

@Component({
  selector: 'kt-promotion-currency',
  templateUrl: './promotion-currency-details.component.html',
  styleUrls: ['./promotion-currency-details.component.scss']
})
export class PromotionCurrencyDetailsComponent implements OnInit, OnDestroy {

  status = Status;
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.statuses
  };
  messages$ = this.promotionCurrencyDataService.messages$;
  private subscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  promotionType = this.data.promotionType;
  buttonLoading = false;
  fieldDisabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionCurrency: any, mode: string, promotionId: number, promotionType: any, promoSubType: number, promotionCodeMode: string, promotionCurrencyRow: any },
    private promotionCurrencyDataService: PromotionCurrencyDataService,
    private promotionCurrencyEntitySerice: PromotionCurrencyEntityService,
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<PromotionCurrencyDetailsComponent>,
  ) { }

  ngOnInit() {
    this.setCurrency();
    this.formInit();
    this.form.getRawValue();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

 private formInit() {
  let promotionId = null;
  let settingsCurrencyId = null;
  let maxTotalApplication = null;
  let maxTotalBonus= null;
  let minBonus = null;
  let minTransfer = null;
  let minDeposit = null;
  let maxBonus = null;
  let freeCreditAmount = null;
  let maxBalanceClaim = null;
  let currentPlayers = null;
  let usedBudget = null;
  let threshold = null;
  let status = 1;
  let maxTransferOut = null;

  // Free Spin
  let coins = null;
  let amountPerLine = null;
  let rounds = null;
  let lines = null;

  let manualDepositControls = {};
  let freeCreditControls = {};
  let freeSpinControls = {};

  if (this.data.mode === 'edit') {
    promotionId = this.data.promotionCodeMode === 'create' ? null : this.data.promotionCurrency.promotion_id;
    settingsCurrencyId = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.currency_id ?? null : this.data.promotionCurrency.settings_currency_id;
    maxTotalApplication = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.max_total_applications ?? null : this.data.promotionCurrency.max_total_applications;
    maxTotalBonus = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.max_total_bonus ?? null : this.data.promotionCurrency.max_total_bonus;
    minTransfer = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.max_total_bonus ?? null : this.data.promotionCurrency.min_transfer;
    minDeposit = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.min_deposit : this.data.promotionCurrency.min_deposit;
    maxBonus = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.max_bonus ?? null : this.data.promotionCurrency.max_bonus;
    minBonus = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.min_bonus ?? null : this.data.promotionCurrency.min_bonus;
    freeCreditAmount = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.free_credit_amount ?? null : this.data.promotionCurrency.free_credit_amount;
    maxBalanceClaim = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.max_balance_claim ?? null : this.data.promotionCurrency.max_balance_claim;
    currentPlayers = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.current_players ?? null : this.data.promotionCurrency.current_players;
    usedBudget = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.used_budget ?? null : this.data.promotionCurrency.used_budget;
    coins = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.coins ?? null : this.data.promotionCurrency.coins;
    amountPerLine = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.amount_per_line ?? null : this.data.promotionCurrency.amount_per_line;
    rounds = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.rounds ?? null : this.data.promotionCurrency.rounds;
    lines = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.lines ?? null : this.data.promotionCurrency.lines;
    threshold = this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.threshold ?? null : this.data.promotionCurrency.threshold;
    status =this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.status ?? null :  this.data.promotionCurrency.status;
    maxTransferOut =this.data.promotionCodeMode === 'create' ? this.data.promotionCurrencyRow.max_transfer_out ?? null :  this.data.promotionCurrency.max_transfer_out;
  }

  // Manual / Deposit
  if(this.promotionType === 1 || this.promotionType === 2) {
    manualDepositControls = {
      ...manualDepositControls,
      min_transfer: new FormControl(minTransfer, [Validators.required]),
      // min_deposit: new FormControl(minDeposit, [Validators.required]),
      max_bonus: new FormControl(maxBonus, [Validators.required]),
      max_total_applications: new FormControl(maxTotalApplication, [Validators.required]),
      max_total_bonus: new FormControl(maxTotalBonus, [Validators.required]),
    }
  }

  // Free Credit
  if(this.promotionType === 3) {
    manualDepositControls = {
      ...manualDepositControls,
      // free_credit_amount: new FormControl(freeCreditAmount, [Validators.required]),
      max_balance_claim: new FormControl(maxBalanceClaim, [Validators.required]),
    }

    if (this.data.promoSubType === 3) {
      manualDepositControls = {
        ...manualDepositControls,
        threshold: new FormControl(threshold, [Validators.required]),
        max_total_applications: new FormControl(maxTotalApplication, [Validators.required]),
      }
    } else if (this.data.promoSubType === 2 || this.data.promoSubType === 4) {
      manualDepositControls = {
        ...manualDepositControls,
        min_bonus: new FormControl(minBonus, [Validators.required]),
        max_bonus: new FormControl(maxBonus, [Validators.required]),
      }
    } else {
      manualDepositControls = {
        ...manualDepositControls,
        max_total_applications: new FormControl(maxTotalApplication, [Validators.required]),
        max_total_bonus: new FormControl(maxTotalBonus, [Validators.required]),
        free_credit_amount: new FormControl(freeCreditAmount, [Validators.required]),
      }
    }
  }

  // Free Spin
  if(this.promotionType === 4) {
    if (this.data.mode === 'edit'
      && this.data.promotionCurrency['free_spin_member_promo_exists'] !== undefined
      && this.data.promotionCurrency.free_spin_member_promo_exists == true
    ) {
      this.fieldDisabled = true;
    }
    freeSpinControls = {
      ...freeSpinControls,
      coins: new FormControl({value: coins, disabled: this.fieldDisabled}, [Validators.required]),
      amount_per_line: new FormControl({value: amountPerLine, disabled: this.fieldDisabled}, [Validators.required]),
      rounds: new FormControl({value: rounds, disabled: this.fieldDisabled}, [Validators.required]),
      lines: new FormControl({value: lines, disabled: this.fieldDisabled}, [Validators.required]),
      min_transfer: new FormControl(minTransfer, [Validators.required]),
      // min_deposit: new FormControl({value: minDeposit, disabled: this.fieldDisabled}, [Validators.required]),
      max_total_applications: new FormControl(maxTotalApplication, [Validators.required]),
      max_total_bonus: new FormControl(maxTotalBonus, [Validators.required]),
    }
  }

  this.form = new FormGroup({
    promotion_id: new FormControl(localStorage.getItem('promoId')), // to refactor soon
    currency_id: new FormControl({value: settingsCurrencyId, disabled: this.data.mode === 'edit'}, [Validators.required]),
    // max_total_applications: new FormControl(maxTotalApplication, [Validators.required]),
    // max_total_bonus: new FormControl(maxTotalBonus, [Validators.required]),
    // max_bonus: new FormControl(maxBonus, [Validators.required]),
    // min_bonus: new FormControl(minBonus, [Validators.required]),
    // free_credit_amount: new FormControl(freeCreditAmount, [Validators.required]),
    // max_balance_claim: new FormControl(maxBalanceClaim, [Validators.required]),
    ...manualDepositControls,
    ...freeCreditControls,
    ...freeSpinControls,
    status: new FormControl(status, [Validators.required]),
    max_transfer_out: new FormControl(maxTransferOut, [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]),
  });
 }

  onSave(promotionCurrencyDetails: PromotionCurrency, mode?: string) {
    let data = {
      id: promotionCurrencyDetails ? promotionCurrencyDetails.id : null,
      ...this.form.value,
      promotion_id: this.data.promotionCodeMode === 'create' ? null : (mode === 'create') ? this.data.promotionId : this.data.promotionCurrency.promotion_id,
      promo_type: this.data.promotionType,
      currency_id: (mode === 'create') ? this.form.value.currency_id : this.data.promotionCurrency.settings_currency_id
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if(this.data.promotionCodeMode === 'edit'){
      this.buttonLoading = true;
      switch (mode) {
        case 'edit':
          this.subscription = this.promotionCurrencyEntitySerice.update(data).pipe(
            tap((res: any) => {
                this.messages$.next([...res.message]);
                this.buttonLoading = false;
                this.form.setErrors(null);
            }),
            catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                throw error;
            })
          ).subscribe();
        break;
        case 'create':
          this.subscription = this.promotionCurrencyEntitySerice.add(data).pipe(
            tap((res: any) => {
                this.messages$.next([...res.message]);
                this.buttonLoading = false;
                this.form.setErrors(null);
            }),
            catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                throw error;
            })
          ).subscribe();
        break;
      }
      this.refreshStatus = true;
    }else{
      const createPromoCodeCurrencies = localStorage.getItem('createPromoCodeCurrencies');
      if (this.data.mode === 'create'){
        if (this.form.value.currency_id !== null){
          data = {
            ...data,
            currency: this.dropdown.currencies.find(row => +row.id === +this.form.value.currency_id).name,
            current_players: 0,
            used_budget: 0.00,
          };
          let updatedData = [data];
          if (createPromoCodeCurrencies !== null){
            JSON.parse(createPromoCodeCurrencies).map( (res: any) => {
              updatedData.push(res)
            });
          };
          localStorage.setItem('createPromoCodeCurrencies', JSON.stringify(updatedData));
        }
      }else{
        if (createPromoCodeCurrencies !== null){
          let updatedData = JSON.parse(createPromoCodeCurrencies).filter(res => +res.currency_id !== +this.data.promotionCurrencyRow.currency_id)
          data = {
            ...data,
            currency: this.dropdown.currencies.find(row => +row.id === +this.data.promotionCurrencyRow.currency_id).name,
            currency_id: +this.data.promotionCurrencyRow.currency_id,
            current_players: 0,
            used_budget: 0.00,
          };
          updatedData.push(
            data
          );
          localStorage.setItem('createPromoCodeCurrencies', JSON.stringify(updatedData));
        };
      }
      this.dialogRef.close(1);
    }
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  private setCurrency(){
    const selectCurrency = () => {
      if(this.data.promotionCodeMode === 'create' && this.data.mode === 'create'){
        if (this.data.promotionCurrency.length > 0){
          this.data.promotionCurrency.map( promo => {
            this.dropdown.currencies = this.dropdown.currencies.filter(currency => +currency.id !== +promo.currency_id);
          });
        }
      }
    };

    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        selectCurrency();
      });
    }else{
      selectCurrency();
    }
  }

}
