import { trigger, state, style, animate, transition } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { FaqEntityService } from './services/faq-entity.service';
import { FaqDataService } from './services/faq-data.service';
import { FaqSectionsDataService } from '../faq-sections/services/faq-sections-data.service';
import { tap, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, OnDestroy, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'kt-faq-management',
  templateUrl: './faq-management.component.html',
  styleUrls: ['./faq-management.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '80px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class FaqManagementComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }
  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config2;
  @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;

  form: FormGroup;

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    faqSections: [],
    perPage: this.dropdownHttpService.perPage,
    platform: this.dropdownHttpService.sitePlatforms,
  };

  faqs$: any;
  dataLength: number;
  loading = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  sections: any;
  clearBtnLoading = false;
  searchBtnLoading = false;
  selectedFaq = [];

  mode: any;
  displayLocale: any;
  toggleAll: boolean = false;
  availableFaq = [];

  faqsData = {
    main_descriptions: [],
    faq_sections: [],
    faq_contents: [],
  }

  faq: any;
  viewFaq: any;
  viewMode:any = false;

  formContent: FormGroup;
  initFaq: any;
  changedObjects: any;
  changedObjects1: any;

  formSection: FormGroup;
  initFaqSection: any;
  modeFaqSection: any;
  faqSection: any;

  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];

  buttonLoading = true;
  messages$ = this.faqDataService.messages$;
  show = false;
  showMain = false;

  checkLoadingPage = false;

  // permission
  canCreateFAQSection: boolean;
  canCreateFAQ: boolean;
  canViewFAQDetails: boolean;
  canEditFAQ: boolean;
  canUpdateFAQStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private dropdownHttpService: DropdownHttpService,
    private faqDataService: FaqDataService,
    private faqSectionsDataService : FaqSectionsDataService,
    private faqEntityService: FaqEntityService,
    public  sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private editorService: EditorService,
    private eventEmitterService: EventEmitterService,
    private appPermissionService: AppPermissionService,
  ) {
  }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {

    this.dropdownHttpService.faqSections.pipe(tap(res => {
      this.dropdown.faqSections = res;
    })).subscribe();
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.displayLocale = this.dropdown.locales[0];
      this.formInit();
      this.pagination = this.faqDataService.pagination;
      this.onSubmit();

      this.initFaq = JSON.stringify({ ...this.formContent.value });
      this.initFaqSection = JSON.stringify({ ...this.formSection.value });
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateFAQSection = appPermissions.create_faq_section;
      this.canCreateFAQ = appPermissions.create_faq;
      this.canViewFAQDetails = appPermissions.view_faq_details;
      this.canEditFAQ = appPermissions.edit_faq;
      this.canUpdateFAQStatus = appPermissions.update_faq_status;
    });

    this.subscriptions.add(apSub);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit()
  }

  async onSubmit(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.searchBtnLoading = clearSearch ? false : true;
      this.loading = true;

      if (clearSearch) {
        this.onClear();
      }

      const data = {
        ...this.form.value,
      };

      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.loadingBar.start();

      this.faqDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).subscribe(res => {
                if(res){
                  this.faqs$ = res;
                }

                this.mode = null;
                this.modeFaqSection = null;
                this.onClearContent();
                this.onClearFaqSection();

                this.page = 1;
                this.pagination = this.faqDataService.pagination;
                this.dataLength = res['faq_contents'].rows.length;
                this.faqsData.main_descriptions = res['main_descriptions'][0];
                this.faqsData.faq_sections = res['faq_sections'];
                this.faqsData.faq_contents = res['faq_contents'].rows;
                this.availableFaq = res['faq_contents'].rows;

                this.toggleAll = false;

                this.loading = false;
                this.clearBtnLoading = false;
                this.searchBtnLoading = false;
                this.loadingBar.complete();
                this.cdr.detectChanges();
      })
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      this.loading = true;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loadingBar.start();
      this.faqDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).subscribe(res => {
        if(res){
          this.faqs$ = res;
        }
        this.pagination = this.faqDataService.pagination;
        this.dataLength = res['faq_contents'].rows.length;
        this.faqsData.main_descriptions = res['main_descriptions'][0];
        this.faqsData.faq_sections = res['faq_sections'];
        this.faqsData.faq_contents = res['faq_contents'].rows;
        this.availableFaq = res['faq_contents'].rows;

        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      })
    }
    else {
      this.page = this.backupPage;
    }
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  onSanitize(data: string) {
    return this.sanitizer.bypassSecurityTrustHtml(data.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'))
  }

  onFormatString(data: string) {
    return data.split(",").join(", <br />");
  }

  private formInit() {
    this.form = new FormGroup({
      keyword: new FormControl(null),
      settings_locale_id: new FormControl('all'),
      status: new FormControl('all'),
      faq_section_id : new FormControl('all'),
      platform_type_id: new FormControl(1),
    });

    //----- faq ------//
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          question: new FormControl(null),
          answer: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formContent = new FormGroup({
      // Create faq
      faq_section_id: new FormControl(null, [Validators.required]),
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(null, [Validators.required]),
      content_type: new FormControl(null),
      details: new FormGroup(buildContents()),
      platform_type_id: new FormControl(null, [Validators.required])
    });

    //----- end faq ------//

    //----- faq section ------//
    const buildFaqSection = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          display_title: new FormControl(null),
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formSection = new FormGroup({
      // Create faq section
      section_title: new FormControl(null, [Validators.required]),
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      details: new FormGroup(buildFaqSection())
    });

    //----- end faq section------//

  }


  expandRowCreate(mode: any, close?: boolean) {
    let faqInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initFaq != faqInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {

      this.dropdownHttpService.faqSections.pipe(tap(res => {
        this.dropdown.faqSections = res;
      })).subscribe();

      this.viewMode = null;
      this.modeFaqSection = null;

      this.changedObjects = false;
      this.initFaq = null;
      this.mode = mode;
      this.modeFaqSection =null;
      this.onClearFaqSection();

      this.cdr.detectChanges();

      this.onClearContent();

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.unsaveStatus = true;
            this.modeFaqSection = '';
            this.onClearContent();
            this.onClearFaqSection();
            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {

    if (close != true) close = this.faq == row ? true : false;

    this.dropdownHttpService.faqSections.pipe(tap(res => {
      this.dropdown.faqSections = res;
    })).subscribe();

    let faqInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initFaq && this.initFaq != faqInput ? true : false;
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.faq = row;
      this.changedObjects = false;
      this.initFaq = null;
      this.mode = mode;
      this.modeFaqSection =null;
      this.onClearFaqSection();

      this.viewMode = null;
      this.modeFaqSection = null;

      this.cdr.detectChanges();

      this.formContent.patchValue({
        content_type: this.faq.content_type,
        faq_section_id: this.faq.faq_section_id,
        position: this.faq.position,
        status: this.faq.status,
        platform_type_id: this.faq.platform_type_id
      });

      if(this.faq.content_type == 1){
        this.formContent.get('faq_section_id').clearValidators();
        this.formContent.get('faq_section_id').updateValueAndValidity();
        this.formContent.get('position').clearValidators();
        this.formContent.get('position').updateValueAndValidity();
      }

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          details: {
            [element.id]: {
              question: null,
              answer: null
            }
          }
        });
      });

      setTimeout(() => {
        this.faq.details.forEach((element: any) => {
          this.formContent.patchValue({
            details: {
              [element.settings_locale_id]: {
                question: element.raw_question,
                answer: element.raw_answer
              }
            }
          });
        });

        this.cdr.detectChanges();

        this.initFaq = JSON.stringify({ ...this.formContent.value });
      }, 500);

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      this.changedObjects = this.initFaq != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            this.onClearFaqSection();
                      // Check if reopen needed
            if (this.faq == row) {
              this.faq = null;
            }
            else {
              this.faq = null;
              this.modeFaqSection = '';
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {
        this.mode = '';
        this.unsaveStatus = true;
        this.faq = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  expandRowView(row?: any) {
   this.show = false;
   if(this.viewFaq && row.id != this.viewFaq.id){
    if (this.checkupdate()) {
      Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.modeFaqSection = null;
          this.mode = null;
          this.unsaveStatus = true;
          this.viewMode = true;
          this.onClearFaqSection();
          this.onClearContent();
        }else{
          this.viewMode = false;
        }
      });
    }else{
      this.viewMode = true;
      this.modeFaqSection = null;
      this.mode = null;
      this.unsaveStatus = true;
      this.onClearFaqSection();
      this.onClearContent();
    }

   }else{
    if (this.checkupdate()) {
      Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.modeFaqSection = null;
          this.mode = null;
          this.unsaveStatus = true;
          this.viewMode = true;
          this.onClearFaqSection();
          this.onClearContent();
        }else{
          this.viewMode = false;
        }
      });
    }else{
      this.viewMode = this.viewMode ? false : true;
      this.modeFaqSection = null;
      this.mode = null;
      this.unsaveStatus = true;
      this.onClearFaqSection();
      this.onClearContent();
    }
   }

   this.viewFaq = row;
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  displayLocaleContent(row: any, column: any , type:any) {

    let mainDescription = [];
    mainDescription.push(this.faqsData.main_descriptions)
    let faq = type =='mainDescription' ? mainDescription.filter(x => x.id == row.id)[0]['details'] : this.availableFaq.filter(x => x.id == row.id)[0]['details'];

    faq = faq.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (faq.length > 0) {
      if(column){
        if(column == 'question' ) {
          return faq[0]['question'] ?  faq[0]['question']:  '-';
        }
      }

      return faq[0]['answer']  ? faq[0]['answer'].replace('&nbsp;',' ') :  '-';;
    }
    else {
      return '-';
    }
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onClearContent() {

    this.formContent.patchValue({
      content_type: null,
      faq_section_id: null,
      position: 99,
      status:1,
      platform_type_id: 1
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        details: {
          [element.id]: {
            question: null,
            answer: null
          }
        }
      });
    });
    this.initFaq = JSON.stringify({ ...this.formContent.value });
  }

  addQuestionVariable(variable: any, id: any) {
    let content = this.formContent.value.details[id]['question'] ? this.formContent.value.details[id]['question'] : '';
    this.formContent.patchValue({
      details: {
        [id]: {
          question: content + variable,
        }
      }
    });
  }

  addAnswerVariable(variable: any, id: any) {
    let content = this.formContent.value.details[id]['answer'] ? this.formContent.value.details[id]['answer'] : '';
    this.formContent.patchValue({
      details: {
        [id]: {
          answer: content + variable,
        }
      }
    });
  }

  addDisplayVariable(variable: any, id: any) {
    let content = this.formSection.value.details[id]['display_title'] ? this.formSection.value.details[id]['display_title'] : '';
    this.formSection.patchValue({
      details: {
        [id]: {
          display_title: content + variable,
        }
      }
    });
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  checkContent(locale: any) {
    let question =  this.formContent.value.details[locale.id].question ?  this.formContent.value.details[locale.id].question : null;
    let answer = this.formContent.value.details[locale.id].answer ?  this.formContent.value.details[locale.id].answer : null;


    if ((question == null || question == '') && (answer == null || answer == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  checkContentFaq(locale: any) {
    let display_title =  this.formSection.value.details[locale.id].display_title ?  this.formSection.value.details[locale.id].display_title : null;

    if ((display_title == null || display_title == '') && (display_title == null || display_title == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  onSave() {

    this.buttonLoading = true;

    // all new create faq are content_type = 2 (section type)
    if(this.mode == 'create'){
      this.formContent.patchValue({
        content_type: 2
      });
    }

    const data = {
      id: this.faq ? this.faq.id : null,
      ...this.formContent.value,
    };

    // check must fill in details and title , either one is fill-in
    let keepcheck = true;
    let fillInQuestionNull = true;
    let fillInAnswerNUll = true;
    let locale = null;

    if(this.formContent.value.content_type == 2){
      Object.keys(data['details']).forEach((key) => {
        if(keepcheck){
          if( (data['details'][key]['question'] == null || data['details'][key]['question'] =='') &&
          (data['details'][key]['answer'] !== null && data['details'][key]['answer'] !=='')
          ){
            fillInQuestionNull = false;
            keepcheck = false;
            locale = this.dropdown.locales.filter(x => x.id == key)[0]
          }else if( (data['details'][key]['question'] !== null && data['details'][key]['question'] !=='') &&
                    (data['details'][key]['answer'] == null || data['details'][key]['answer'] =='')
          ){
            fillInAnswerNUll = false;
            keepcheck = false;
            locale = this.dropdown.locales.filter(x => x.id == key)[0]
          }
        }
      });
    }


    if(!fillInQuestionNull || !fillInAnswerNUll){

      let message = null;

      if(!fillInQuestionNull){
        message ="Please Fill in Question for locale "+locale['code'];
      }else if(!fillInAnswerNUll){
        message ="Please Fill in Answer for locale "+locale['code'];
      }

      Swal.fire({
        title: '<div class="text-center">'+message+'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

      return false;
    }

    // check least one locale content
    let checkcontent = false;

    Object.keys(data['details']).forEach((key) => {
      if( (data['details'][key]['question'] !== null && data['details'][key]['question'] !=='') ||
          (data['details'][key]['answer'] !== null && data['details'][key]['answer'] !=='')
      ){
        checkcontent = true;
      }
    });

    if(!checkcontent){

      Swal.fire({
        title: '<div class="text-center">Fill in at least one locale content</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

    }

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    if(checkcontent){
      switch (this.mode) {
        case 'edit':
          this.subscription = this.faqDataService.updateFaqContent(this.faq.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.faqDataService.addContent(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }

  }

  reload(clearSearch?: boolean) {
    this.mode = '';
    this.onClearContent();
    this.modeFaqSection = '';
    this.onClearFaqSection();
    this.onSubmit(clearSearch);
    this.initFaq = JSON.stringify({ ...this.formContent.value });
    this.initFaqSection = JSON.stringify({ ...this.formSection.value });
    this.buttonLoading = false;
    this.selectedFaq = [];
  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.faqDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  expandRowFaqSectionCreate(mode: any, close?: boolean) {
    let faqInput = JSON.stringify({ ...this.formSection.value });
    this.changedObjects = this.initFaqSection != faqInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {

      this.viewMode = null;
      this.modeFaqSection = null;

      this.changedObjects = false;
      this.initFaqSection = null;
      this.modeFaqSection = mode;
      this.mode =null;
      this.onClearContent();

      this.cdr.detectChanges();

      this.onClearFaqSection();
    } else {
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.modeFaqSection = '';
            this.onClearContent();
            this.onClearFaqSection();
            // Check if reopen needed
            this.checkReopenFaqSection(mode);
          }
        });
      }
      else {
        this.modeFaqSection = '';
        this.onClearFaqSection();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowFaqSectionEdit(mode: any, row?: any, close?: boolean) {
    this.loading = true;
    if (close != true) close = this.faqSection == row ? true : false;
    let faqInput = JSON.stringify({ ...this.formSection.value });
    this.changedObjects = this.initFaqSection && this.initFaqSection != faqInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {

      this.viewMode = null;
      this.modeFaqSection = null;

      this.faqSection = row;
      this.changedObjects = false;
      this.initFaqSection = null;
      this.modeFaqSection = mode;
      this.mode = null;
      this.onClearContent();

      this.cdr.detectChanges();

      this.formSection.patchValue({
        section_title : this.faqSection.section_title,
        position: this.faqSection.position
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formSection.patchValue({
          details: {
            [element.id]: {
              display_title: null
            }
          }
        });
      });

      setTimeout(() => {
        this.faqSection.details.forEach((element: any) => {
          this.formSection.patchValue({
            details: {
              [element.settings_locale_id]: {
                display_title: element.display_title,
              }
            }
          });
        });

        this.loading = false;
        this.cdr.detectChanges();

        this.initFaqSection = JSON.stringify({ ...this.formSection.value });
      }, 500);

    } else {
      this.loading = false;
      this.changedObjects = this.initFaqSection != JSON.stringify({ ...this.formSection.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.modeFaqSection = '';
            this.onClearFaqSection();
            this.onClearContent();
            // Check if reopen needed
            if (this.faqSection == row) {
              this.faqSection = null;
            }
            else {
              this.faqSection = null;
              this.faq = null;
              this.checkReopenFaqSection(mode, row);
            }
          }
        });
      }
      else {
        this.modeFaqSection = '';
        this.faqSection = null;
        this.onClearFaqSection();
        this.onClearContent();
      }
    }
  }

  checkReopenFaqSection(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowFaqSectionEdit(mode, row)
        break;
      case 'create':
        this.expandRowFaqSectionCreate(mode);
        break;
    }
  }

  onClearFaqSection() {

    this.formSection.patchValue({
      section_title: null,
      position: 99,
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formSection.patchValue({
        details: {
          [element.id]: {
            display_title: null,
          }
        }
      });
    });
    this.initFaqSection = JSON.stringify({ ...this.formSection.value });
  }

  onSaveFaqSection() {

    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formSection.setErrors({ 'invalid': true });

    const data = {
      id: this.faqSection ? this.faqSection.id : null,
      ...this.formSection.value,
    };

    let checkcontent = false;

    Object.keys(data['details']).forEach((key) => {
      if(data['details'][key]['display_title'] !== null && data['details'][key]['display_title'] !== ''
      ){
        checkcontent = true;
      }
    });

    if(!checkcontent){

      Swal.fire({
        title: '<div class="text-center">Fill in at least one locale content</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
        this.formContent.setErrors(null);
        this.formContent.enable();
      });

    }

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    if(checkcontent){
      switch (this.modeFaqSection) {
        case 'edit':
          this.subscription = this.faqSectionsDataService.updateSections(this.faqSection.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.faqSectionsDataService.addSections(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }

  }

  checkupdate() {
    const faqcheck = this.initFaq !== JSON.stringify({ ...this.formContent.value }) ? true : false;
    const faqSectioncheck =this.initFaqSection !== JSON.stringify({ ...this.formSection.value }) ? true : false;
    return (faqcheck || faqSectioncheck) ? true : false;
  }

  showReadMoreButton(id){
    var element = document.getElementById(id);
    if (element.offsetHeight < element.scrollHeight ||
         element.offsetWidth < element.scrollWidth) {
         return true;
     } else {
        return false;
     }
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          details: {
            [locale.id]: {
              question: null,
              answer: null
            }
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

  clearContentFaq(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formSection.patchValue({
          details: {
            [locale.id]: {
              display_title: null,
            }
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let faqInput = JSON.stringify({ ...this.formContent.value });
      let faqSectionInput = JSON.stringify({ ...this.formSection.value });
      this.changedObjects = this.initFaq && this.initFaq != faqInput ? true : false;
      this.changedObjects1 = this.initFaqSection && this.initFaqSection != faqSectionInput ? true : false;

      if (this.changedObjects == true || this.changedObjects1 == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.faq = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }
}
