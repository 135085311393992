import { select } from '@ngrx/store';
import { AfterViewChecked, ChangeDetectorRef, Component, Input, Inject, OnDestroy, OnInit, QueryList, ViewChildren, Optional  } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as Editor from "@core/../../assets/js/global/integration/plugins/ckeditor";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { EditorService } from "@core/services/editor.service";
import * as moment from 'moment-timezone';
import { forkJoin, Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { catchError, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AppPermissionService } from "@core/services/app-permission.service";
import { MessageTemplateDataService } from "@views/pages/apps/superuser/message-template/services/message-template-data.service";
import Swal from "sweetalert2";
import { MessageTemplateEditComponent } from "@views/pages/apps/superuser/message-template/dialogs/message-template-edit.component";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AllSmsMessagesDataService } from '@views/pages/apps/sms/all-sms-messages/service/all-sms-messages-data.service';
import { SendMessageHistoriesComponent } from "@views/pages/apps/sms/send/send-message-histories/send-message-histories.component";
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { SendMessageHistoriesDataService } from '@views/pages/apps/sms/send/send-message-histories/service/send-message-histories-data.service';

interface Region {
  id: number;
  name: string;
  currency_name: string; // currency's code
}

@Component({
  selector: "app-send-message-tab",
  templateUrl: "./send-message-tab.component.html",
  styleUrls: ["./send-message-tab.component.scss"],
})
export class SendMessageTabComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren("editors") editorComponent: QueryList<CKEditorComponent>;

  @Input()
  mode: string = 'Edit'; // Default to Edit (tab mode)

  private subscriptions = new Subscription();

  sendMessageForm: FormGroup;
  checkValidation = false;
  regionsSelectedItems = [];
  memberGroupSelectedItems = [];
  memberGroupDropdownList = [];
  messageTemplateSectionDropdownList = [];
  selectedMessageTemplateSection = [];
  messageTemplateDropdownList = [];
  oriMessageTemplateDropdownList = [];
  selectedMessageTemplate = [];

  regionsDropdownSettings = {};
  memberGroupDropdownSettings = {};
  messageMemberDropdownSettings = {};
  messageTemplateSectionDropdownSettings = {};
  inboxMessageTemplateDropdownSettings = {};
  selectedRegions = [];

  selectedMembers = [];

  regions: Region[] = [];

  templateFileName: string = 'ExampleImportSendMessage.csv';

  buttonLoading = false;
  fileName = null;
  file: File;
  disableMessageField = false;
  attachedFile: any = null;

  minDate = moment();
  originalLanguages: any = [];
  languages: any = [];

  usableVariables: any;

  timezone = JSON.parse(localStorage.getItem("user_data")).timezone;
  messages$ = this.allSmsMessagesDataService.messages$;

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    groups: this.dropdownHttpService.groups,
    locales$: this.messageTemplateDataService.locales$,
    locales: this.dropdownHttpService.locales,
  };

  private localesGroupsLoaded$ = new Subject<void>();
  private messageTemplateLoaded$ = new Subject<void>();

  // permissions
  canSendMessage: boolean;
  canViewSendMessageHistories: boolean;
  canSendMessageNow: boolean;
  canUpdateMessageSettings: boolean;
  canCancelScheduledMessage: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
    private messageTemplateDataService: MessageTemplateDataService,
    private dialog: MatDialog,
    private editorService: EditorService,
    private allSmsMessagesDataService: AllSmsMessagesDataService,
    private dropdownHttpService: DropdownHttpService,
    @Optional() public dialogRef: MatDialogRef<SendMessageTabComponent>,
    private sendMessageHistoriesDataService: SendMessageHistoriesDataService,
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.initRegions();
    this.setMemberGroupsAndLocales();
    this.initDropdownSettings();
    this.setPermissions();

    if( this.mode === 'Edit' ) {
      this.setFormData();
    }
  }

  ngAfterViewChecked(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private formInit() {
    this.sendMessageForm = new FormGroup({
      settings_currency_ids: new FormControl([], [Validators.required]),
      member_group_ids: new FormControl([], [Validators.required]),
      member_account_ids: new FormControl([], [Validators.required]),
      send_to_all_members: new FormControl({ value: 0, disabled: true }), // disabled initially
      attached_file: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      section: new FormControl(null),
      message_template: new FormControl(null),
      details: new FormGroup({}),
    });

    if( this.mode === 'Edit' ) {
      this.sendMessageForm.addControl('attached_file_url', new FormControl(null));
    }
  }

  resetForm() {
    this.regionsSelectedItems = [];
    this.selectedMembers = [];

    this.sendMessageForm.patchValue({
      settings_currency_ids: [],
      member_group_ids: [],
      member_account_ids: [],
      send_to_all_members: 0,
      attached_file: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      section: null,
      message_template: null,
    });
    this.resetMessage(); // details need to be reset separately
    this.memberGroupSelectedItems = [];
    this.selectedMessageTemplateSection = [];
    this.selectedMessageTemplate = [];

    this.messageMemberDropdownSettings = {
      ...this.messageMemberDropdownSettings,
      disabled: true,
    };
    this.sendMessageForm.get('send_to_all_members').disable();

    const inputFile = document.getElementById('file') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = ''; // Clear the input value
    }

    this.onRemoveFile();
  }

  resetMessage() {
    const detailsGroup = this.sendMessageForm?.get("details") as FormGroup;
    Object.keys(detailsGroup?.controls || {}).forEach((localeId) => {
      const detailsControl = detailsGroup.get(localeId) as any;

      if (detailsControl) {
        detailsControl.patchValue({
          subject: null,
          message: null,
        });
      }
    });
  }

  private initRegions() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        this.regions = this.dropdown.currencies.map(currency => ({
          id: currency.id,
          name: this.getCurrencyRegion(currency.name),
          currency_name: currency.name
        }));
      });
    } else {
      this.regions = this.dropdown.currencies.map(currency => ({
        id: currency.id,
        name: this.getCurrencyRegion(currency.name),
        currency_name: currency.name
      }));
    }
  }

  private getCurrencyRegion(currencyName: string): string {
    const currencyRegionMap = {
      'MYR': 'MY',
      'SGD': 'SG',
      'IDR': 'ID',
      'THB': 'TH',
      'INR': 'IN',
      'USD': 'US',
      'PHP': 'PH'
    };
    return currencyRegionMap[currencyName] || '';
  }

  onSelectMemberGroup(event: any){
    this.memberGroupSelectedItems = [...event];

    // Disable/Enable member dropdown based on selection
    this.messageMemberDropdownSettings = {
      ...this.messageMemberDropdownSettings,
      disabled: !event?.length
    };

    if (event?.length) {
      this.sendMessageForm.get('send_to_all_members').enable();
    } else {
      this.selectedMembers = [];
      this.sendMessageForm.get('send_to_all_members').disable();

      this.sendMessageForm.patchValue({
        member_account_ids: [],
        send_to_all_members: 0
      });
    }

    this.cdr.detectChanges();
  }

  onSendToAllMembersChange(event) {
    const checked = event.target.checked;

    // disable member dropdown if send to all members is checked
    this.messageMemberDropdownSettings = {
      ...this.messageMemberDropdownSettings,
      disabled: checked,
    };

    const memberAccountIdsControl = this.sendMessageForm.get('member_account_ids');
    if (checked) {
      this.selectedMembers = [];
      memberAccountIdsControl.setValue([]);
      memberAccountIdsControl.clearValidators();
    } else {
      memberAccountIdsControl.setValidators([Validators.required]);
    }
    memberAccountIdsControl.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  onFileUpload(event: any){
    const file: File = event.target.files[0];
    const fileExtension = file.name.split('.').pop();
    if (fileExtension !== 'csv') {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File Type',
        text: 'Please upload a CSV file!',
      });
      return;
    }

    this.file = file;

    const formData = new FormData();
    formData.append('attached_file', file, file.name);
    this.sendMessageForm.patchValue({
      attached_file: file,
      member_group_ids: [],
      member_account_ids: [],
    });

    // Disable member dropdown
    this.messageMemberDropdownSettings = {
      ...this.messageMemberDropdownSettings,
      disabled: true
    };
    this.selectedMembers = [];
    this.sendMessageForm.controls.member_account_ids.clearValidators();
    this.sendMessageForm.get('member_account_ids').updateValueAndValidity();

    // Disable member group dropdown
    this.memberGroupSelectedItems = [];
    this.memberGroupDropdownSettings = {
      ...this.memberGroupDropdownSettings,
      disabled: true
    };
    this.sendMessageForm.controls.member_group_ids.clearValidators();
    this.sendMessageForm.get('member_group_ids').updateValueAndValidity();

    // Disable send to all member
    this.sendMessageForm.get('send_to_all_members')?.setValue(0);
    this.sendMessageForm.get('send_to_all_members').disable();
    

    if( this.mode === 'Edit' ) {
      this.sendMessageForm.patchValue({attached_file_url: null});
      this.attachedFile = null;
    }

    this.cdr.detectChanges();
  }

  onMessageTemplateSectionChanged(event) {
    this.usableVariables = [];

    if (event[0]?.section) {
      this.messageTemplateDataService.getMessageTemplatVariables(event[0]?.section).subscribe((res) => {
        if( res ) {
          this.usableVariables = res["usable_variables"];
          this.cdr.detectChanges();
        }
      });
      this.sendMessageForm.patchValue({section: event[0].section});
    }
    this.inboxMessageTemplateDropdownSettings = {
      ...this.inboxMessageTemplateDropdownSettings,
      disabled: event.length === 0,
    };

    const selectedSection = event[0]?.section;
    this.messageTemplateDropdownList = this.oriMessageTemplateDropdownList.filter((template) => template.section === selectedSection);

    this.selectedMessageTemplate = [];
    this.disableMessageField = false;
    this.resetMessage();
    this.cdr.detectChanges();
  }

  onMessageTemplateChanged(event: any) {
    this.disableMessageField = false;

    this.resetMessage();
    if (event.length > 0) {
      this.messageTemplateDataService.getById(event[0].id).subscribe((res) => {
        if(res) {
          const messageDetailsArray = Object.entries(res.message_details).map(([key, item]) => item);
          messageDetailsArray.forEach((item) => {
            const localeId = item["settings_locale_id"];
            const detailsGroup = this.sendMessageForm.get(`details.${localeId}`);
            if (detailsGroup) {
              detailsGroup.patchValue({
                subject: item["subject"],
                message: item["message"],
              });
            }
          });
          this.disableMessageField = true;
          this.updateDetailsValidators();
          this.cdr.detectChanges();
        }
      });
      this.sendMessageForm.patchValue({message_template: event[0].id});
    }
  }

  onOpenDialog(type: string) {
    switch(type) {
      case 'message_template':
        this.openDialogBy(MessageTemplateEditComponent, { mode: 'create' });
        break;
      case 'history':
        // TODO
        this.openDialogBy(SendMessageHistoriesComponent, { type: 'inbox_message' });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: any) {
    const dialogData = data || {};
    switch( componentRef ) {
      case MessageTemplateEditComponent:
        const messageTemplateDialogRef = this.dialog.open(componentRef, {
          data: dialogData,
          width: '1000px'
        });
        messageTemplateDialogRef.afterClosed().subscribe(() => {
          this.initMessageTemplateDropdown();
        });
        break;
      case SendMessageHistoriesComponent:
        this.dialog.open(componentRef, {
          data: dialogData,
          width: '1400px',
        });
        break;
    }
  }

  buildSubDetails = () => {
    let details = {};

    this.originalLanguages.map((element) => {
      const detailsGroup = new FormGroup({
        settings_locale_id: new FormControl(element.id, []),
        subject: new FormControl(null, []),
        message: new FormControl(null, []),
      });
      details = { ...details, [element.id]: detailsGroup };
    });

    return details;
  };

  private initMessageTemplateDropdown() {
    // Reset dropdown
    this.messageTemplateSectionDropdownList = [];
    this.messageTemplateDropdownList = [];

    // Get locales by selected regions
    const currencyIds = this.selectedRegions.map(currency => currency.id);
    const localeIds = this.originalLanguages
      .filter(locale => currencyIds.includes(locale.settings_currency_id))
      .map(locale => locale.id);
    
    // Prepare query string
    const data = {
      'paginate': false,
      'status': 1,
      'type': [1],
      'locale': localeIds
    };
    const queryString = new URLSearchParams(
      Object.entries(data).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(val => acc.push([`${key}[]`, val.toString()]));
        } else {
          acc.push([key, value.toString()]);
        }
        return acc;
      }, [] as [string, string][])
    ).toString();

    // Call API to get message templates
    if( currencyIds.length > 0 ) {
      this.messageTemplateDataService.getWithQuery('?'+queryString).subscribe(res => {
        this.selectedMessageTemplate = this.selectedMessageTemplateSection = [];
  
        this.messageTemplateDropdownList = res;
        this.oriMessageTemplateDropdownList = [...res];
  
        const uniqueSections = new Set();
        this.messageTemplateDropdownList.forEach(item => {
          if (!uniqueSections.has(item.section_name)) {
              uniqueSections.add(item.section_name);
              this.messageTemplateSectionDropdownList.push(item);
          }
        });

        this.resetMessage();
        
        this.messageTemplateLoaded$.next();
        this.messageTemplateLoaded$.complete();
        
        this.cdr.detectChanges();
      })
    }
  }

  private initDropdownSettings() {
    this.regionsDropdownSettings = {
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "id",
      labelKey: "name",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
    };

    this.memberGroupDropdownSettings = {
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "name",
      labelKey: "name",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
      searchBy: ['name']
    };

    this.messageMemberDropdownSettings = {
      singleSelection: false,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "labelKey",
      labelKey: "username",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
      disabled: true,
    };

    this.messageTemplateSectionDropdownSettings = {
      singleSelection: true,
      text: 'Message Section',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'section',
      labelKey: 'section_name',
      noDataLabel: '',
      showCheckbox: false,
      disabled: false,
      autoPosition: false,
    };

    this.inboxMessageTemplateDropdownSettings = {
      singleSelection: true,
      text: "Template Code",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      maxHeight: 200, //'auto',
      primaryKey: "id",
      labelKey: "code",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
      searchBy: ['code'],
      autoPosition: false,
    };
  }

  onSelectUsableVariable(usableVariable: String, i) {
    let editorInstance = this.editorComponent.toArray()[i].editorInstance;
    const selection = editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    editorInstance.editing.view.focus();
    editorInstance.model.change((writer) => {
      writer.insert(usableVariable + " ", range.start);
      const rangeAfterAppend = selection.getFirstRange();
      writer.setSelection(writer.createPositionAt(rangeAfterAppend.start));
    });
  }

  onSend() {
    this.checkValidation = true;
    if (this.sendMessageForm.valid) {

      let data = {
        ...this.sendMessageForm.getRawValue(),
        send_to_all_members: this.sendMessageForm.get('send_to_all_members').value ? 1 : 0
      };

      data = this.filterFormFields(data);

      if( data.datetime ) {
        const scheduledDateTimeString = data.datetime;
        const scheduledDateTime = new Date(`${scheduledDateTimeString}Z`);
        const currentDateTimeUTC = new Date();

        if (scheduledDateTime > currentDateTimeUTC) {
          this.scheduleMessage();
        } else {
          Swal.fire({
            title: 'Invalid Date Time',
            html: '<div>The selected date and time have already passed. Do you want to proceed with sending it immediately?</p></div>',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            allowOutsideClick: false
          }).then((response) => {
            if (response.isConfirmed) {
              this.scheduleMessage();
            } else {
              this.checkValidation = false;
            }
          });
        }
      } else {
        this.scheduleMessage();
      }
    }
  }

  scheduleMessage() {
    this.checkValidation = false;
    this.buttonLoading = true;

    const formData = new FormData();
    let data = {
      ...this.sendMessageForm.getRawValue(),
      send_to_all_members: this.sendMessageForm.get('send_to_all_members').value ? 1 : 0
    };

    if( this.mode === 'Edit' ) {
      data.id = this.data.id;
    }

    data = this.filterFormFields(data);

    // Append all fields to FormData
    Object.keys(data).forEach(key => {
      // Handle complex objects/arrays with JSON.stringify
      if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
        formData.append(key, JSON.stringify(data[key]));
      }
      // Handle simple values as is
      else {
        formData.append(key, data[key]);
      }
    });

    const sub = forkJoin([
      this.allSmsMessagesDataService.sendMessage(formData).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          this.disableMessageField = false; 

          this.resetForm();

          // To enable "Save" button after get response
          this.sendMessageForm.setErrors(null);

          this.onCloseDialog(true);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.sendMessageForm.setErrors(null);
          throw error;
        })
      ),
      this.allSmsMessagesDataService.messages$,
    ]).subscribe();

    this.subscriptions.add(sub);
  }

  private filterFormFields(data: any) {
    const fields = {};
    Object.keys(data).forEach(key => {
      if (key === 'start_datetime' && data[key] !== '' && data[key] !== null) {
        fields['datetime'] = moment(data[key]).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      }
      else if (data[key] !== '' && data[key] !== null && key !== 'defaultDate' && key !== 'end_datetime') {
        fields[key] = data[key];
      }
    });

    return fields;
  }

  onRemoveFile() {
    this.file = null;
    const inputFile = document.getElementById('file') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = ''; // Clear the input value
    }
    this.sendMessageForm.patchValue({attached_file: null});

    if( this.mode === 'Edit' ) {
      this.sendMessageForm.patchValue({attached_file_url: null});
      this.attachedFile = null;
    }

    // Enable member dropdown
    this.messageMemberDropdownSettings = {
      ...this.messageMemberDropdownSettings,
      disabled: false
    };
    this.sendMessageForm.controls.member_account_ids.setValidators([Validators.required]);
    this.sendMessageForm.get('member_account_ids').updateValueAndValidity();

    // Enable member group dropdown
    this.memberGroupDropdownSettings = {
      ...this.memberGroupDropdownSettings,
      disabled: false
    };
    this.sendMessageForm.controls.member_group_ids.setValidators([Validators.required]);
    this.sendMessageForm.get('member_group_ids').updateValueAndValidity();
    
    // Enable send to all members
    this.sendMessageForm.get('send_to_all_members').enable()

    this.cdr.detectChanges();
  }

  onRegionChanged(selectedRegions) {
    this.selectedRegions = selectedRegions;
    this.regionsSelectedItems = [...this.selectedRegions];

    // Remove file if region change and there's a file
    if (this.file) {
      this.onRemoveFile();
    }

    // Set languages for content body
    this.setLanguagesBasedOnRegions(selectedRegions);

    // Initialize message template dropdown
    this.initMessageTemplateDropdown();

    // update message templates options based on region languages 
    const selectedSection = this.sendMessageForm.get('section').value;
    if( selectedSection ) {
      this.messageTemplateDropdownList = this.oriMessageTemplateDropdownList.filter((template) => {
        const availableLocaleCodes = this.languages.map(language => language.code);
        const currentTemplateLocaleCodes = template.locales.split(',');
        const hasMatchingLocale = availableLocaleCodes.some(availableCode => 
          currentTemplateLocaleCodes.includes(availableCode)
        );
  
        return hasMatchingLocale && template.section === selectedSection;
      });
    }

    // reset message template and section
    this.usableVariables = [];
    this.sendMessageForm.patchValue({
      section: null,
      message_template: null,
    });
    this.selectedMessageTemplateSection = [];
    this.selectedMessageTemplate = [];
    this.disableMessageField = false;

    this.cdr.detectChanges();
  }

  updateDetailsValidators() {
    const detailsGroup = this.sendMessageForm?.get("details") as FormGroup;

    const isLocaleComplete = (control): boolean => {
      const value = control.value;
      return value.subject && value.message;
    };

    // Check if any locale has complete data
    const hasCompleteLocale = Object.values(detailsGroup?.controls || {})
      .some(control => isLocaleComplete(control)); 

    // Update validators for all locales
    Object.keys(detailsGroup?.controls || {}).forEach((localeId) => {
      const detailsControl = detailsGroup.get(localeId) as any;

      if (detailsControl) {
        if (this.languages.find(language => language.id == localeId)) {
          // If no locale is complete, set as required
          // If a locale is complete, only keep required for the complete locale
          const shouldBeRequired = !hasCompleteLocale || 
                                 isLocaleComplete(detailsControl);

          if (shouldBeRequired) {
            detailsControl.controls.message.setValidators([Validators.required]);
            detailsControl.controls.subject.setValidators([Validators.required]);
          } else {
            detailsControl.controls.message.clearValidators();
            detailsControl.controls.subject.clearValidators();
          }
        } else {
          detailsControl.controls.message.clearValidators();
          detailsControl.controls.subject.clearValidators();
        }

        detailsControl.controls.message.updateValueAndValidity({ emitEvent: false });
        detailsControl.controls.subject.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private setLanguagesBasedOnRegions(selectedRegions) {
    this.languages = this.originalLanguages.filter(language => this.selectedRegions.find(r => r.id == language.settings_currency_id));
  }

  onCloseDialog(refresh: boolean) {
    if( this.mode == 'Edit' && this.dialogRef ) {
      this.dialogRef.close(refresh);
    }
  }

  onSendNow() {
    if( this.mode === 'Edit' ) {
      const data = {
        'id' : this.data.id,
        'send_now' : true,
        'schedule_datetime' : moment(new Date()).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.updateRequest(data);
    }
  }

  onCancel() {
    if( this.mode === 'Edit' ) {
      const data = {
        'id' : this.data.id,
        'status': 4 // Cancel
      };
      this.updateRequest(data);
    }
  }

  updateRequest(data) {
    this.buttonLoading = true;
    this.sendMessageHistoriesDataService.updateHistory(data).pipe(
      tap((res: any) => {
        this.messages$ = this.sendMessageHistoriesDataService.messageHistories$;
        this.buttonLoading = false;
        this.onCloseDialog(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  private setPermissions() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
      this.canSendMessage = appPermissions.send_message;
      this.canViewSendMessageHistories = appPermissions.view_send_message_histories;
      this.canSendMessageNow = appPermissions.send_message_now;
      this.canUpdateMessageSettings = appPermissions.update_message_settings;
      this.canCancelScheduledMessage = appPermissions.cancel_scheduled_message;
    });

    this.subscriptions.add(apSub);
  }

  private setMemberGroupsAndLocales() {
    forkJoin({
      locales: this.dropdown.locales,
      groups: this.dropdown.groups
    }).subscribe(({ locales, groups }) => {  
      // Logic for locales
      this.messageTemplateDataService.locales$.next(locales);
      this.originalLanguages = [...locales];
      const detailsFormGroup = new FormGroup(this.buildSubDetails());
      this.sendMessageForm.setControl('details', detailsFormGroup);
      this.setLanguagesBasedOnRegions([]);
  
      // Logic for groups
      this.memberGroupDropdownList = groups;
  
      // Combined logic after both are completed
      this.localesGroupsLoaded$.next();
      this.localesGroupsLoaded$.complete();
    });
  }

  private setFormData() {
    if( this.mode !== 'Edit' ) {
      return;
    }

    this.localesGroupsLoaded$.subscribe(() => {
      // Set regions
      this.selectedRegions = this.regions.filter(item => this.data.currency_ids.includes(item.id));
      this.regionsSelectedItems = this.selectedRegions;
      this.onRegionChanged(this.selectedRegions);
      this.sendMessageForm.patchValue({
        'settings_currency_ids': this.selectedRegions.map(item => item.id)
      });

      // Set member groups
      this.memberGroupSelectedItems = this.memberGroupDropdownList.filter(item => this.data.member_group_ids.includes(item.id));
      this.onSelectMemberGroup(this.memberGroupSelectedItems);
      this.sendMessageForm.patchValue({
        'member_group_ids': this.memberGroupSelectedItems.map(item => item.id)
      });

      // Set member
      if( this.data.attached_file !== null && this.data.attached_file !== '' ) {
        // From file upload
        this.attachedFile = {
          'url' : this.data.attached_file,
          'name' : this.data.attached_file_name
        };
        this.sendMessageForm.get('attached_file_url').setValue(this.attachedFile.url);
        
        this.messageMemberDropdownSettings = {
          ...this.messageMemberDropdownSettings,
          disabled: true
        };
        this.sendMessageForm.get('send_to_all_members').disable();
        this.sendMessageForm.controls.member_account_ids.clearValidators();
        this.sendMessageForm.get('member_account_ids').updateValueAndValidity();
        this.sendMessageForm.get('send_to_all_members')?.setValue(0);
      } else if( this.data.send_all === 1 ) {
        // Send all
        this.sendMessageForm.get('send_to_all_members').setValue(1);
        this.sendMessageForm.get('send_to_all_members').enable();
        this.sendMessageForm.controls.member_account_ids.clearValidators();
        this.sendMessageForm.get('member_account_ids').updateValueAndValidity();
      } else if( this.data.member_accounts.length > 0 ) {
        // From select members
        let member_account_ids = [];
        Object.keys(this.data.member_accounts).forEach((key) => {
          this.selectedMembers.push(this.data.member_accounts[key]);
          member_account_ids.push(this.data.member_accounts[key].id);
        });
        this.sendMessageForm.patchValue({
          member_account_ids: member_account_ids,
        });
        this.sendMessageForm.get('send_to_all_members').enable();
        this.sendMessageForm.controls.member_account_ids.setValidators([Validators.required]);
        this.sendMessageForm.get('member_account_ids').updateValueAndValidity();
      }

      // Set schedule date/time
      this.sendMessageForm.patchValue({
        'defaultDate': {
          'startDate': new Date(this.data.schedule_datetime),
          'endDate': new Date(this.data.schedule_datetime)
        },
        'start_datetime': new Date(this.data.schedule_datetime),
        'end_datetime': new Date(this.data.schedule_datetime),
      });
      
      this.cdr.detectChanges();
    });

    // Set message template
    this.messageTemplateLoaded$.subscribe(() => {
      if( this.data.message_template_id !== null && this.data.section !== null ) {
        this.selectedMessageTemplateSection = this.messageTemplateSectionDropdownList.filter(item => +item.section === this.data.section);
        this.onMessageTemplateSectionChanged(this.selectedMessageTemplateSection);
        
        this.selectedMessageTemplate = this.messageTemplateDropdownList.filter(item => +item.id === this.data.message_template_id);
        this.onMessageTemplateChanged(this.selectedMessageTemplate);

        this.cdr.detectChanges();
      } else if( this.data.details !== null ) {
        Object.keys(this.data.details).forEach(localeId => {
          const detailsGroup = this.sendMessageForm.get(`details.${localeId}`);
          if (detailsGroup) {
            detailsGroup.patchValue({
              subject: this.data.details[localeId].subject,
              message: this.data.details[localeId].message,
            });
          }
        });
      }

    });
  }
}


