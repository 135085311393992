import { FormGroup, FormControl } from '@angular/forms';
import { PromotionCategoryEditDialogComponent } from './dialogs/promotion-category-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PromotionCategoryDataService } from './services/promotion-category-data.service';
import { PromotionCategoryEntityService } from './services/promotion-category-entity.service';
import { PromotionCategory } from '@core/models/promotion-content-category.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import * as moment from 'moment-timezone';
@Component({
  selector: 'kt-promotion-category',
  templateUrl: './promotion-category.component.html',
  styleUrls: ['./promotion-category.component.scss']
})
export class PromotionCategoryComponent implements OnInit, OnDestroy {

  form: FormGroup;
  promotionCategory$: Observable<PromotionCategory[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status=1';
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    statuses: this.dropdownHttpService.statuses
  };
  buttonLoading = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  // permissions
  canCreatePromotionContentCategory: boolean;
  canEditPromotionContentCategory: boolean;


  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];

  ranges: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  messages$ = this.promotionCategoryDataService.messages$;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private promotionCategoryDataService: PromotionCategoryDataService,
    private promotionCategoryEntityService: PromotionCategoryEntityService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
    private timeZoneDate: TimezoneDatePipe
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);
    this.ranges = this.transactionHttpService.ranges;
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreatePromotionContentCategory = appPermissions.create_promotion_content_category;
      this.canEditPromotionContentCategory = appPermissions.edit_promotion_content_category;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionCategory$ = this.promotionCategoryEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.promotionCategoryDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: string, row?: PromotionCategory) {
    if (mode === 'edit') {
      this.openDialogBy(PromotionCategoryEditDialogComponent, { mode: 'edit', row: row });
    } else {
      this.openDialogBy(PromotionCategoryEditDialogComponent, { mode: 'create' });
    }
  }

  onReload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      status: 1,
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.promotionCategory$ = this.promotionCategoryEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.promotionCategoryDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') ? fields[key] = formData[key] : key);
    return fields;
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, row?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        row: data.row
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl(1),
      date_type: new FormControl(this.dateTimeFilterType[0].key),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
    });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  timezoneDate(date: any, format: any) {
    return this.timeZoneDate.transform(date, format);
  }

}
