import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { AllAffiliatesDataService } from '../../../../all-affiliates/services/all-affiliates-data.service';
import { AffiliateGroupsDataService } from '../../../services/affiliate-groups-data.service';
import { GroupDataService } from '@views/pages/apps/general/groups/services/group-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './affiliate-groups-member-group-bulk-add.component.html',
  styleUrls: ['./affiliate-groups-member-group-bulk-add.component.scss']
})
export class AffiliateGroupsMemberGroupBulkAddComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.affiliateGroupsDataService.messages$;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  memberGroupDropdownList = [];
  memberGroupDropdownListSettings: any;
  memberGroupListSelectedItems = [];

  affiliateDropdownList = [];
  affiliateDropdownListSettings: any;
  affiliateListSelectedItems = [];
  filteredAffiliateDropdownList = [];

  canEditMemberGroup: boolean;

  constructor(
    public dialogRef: MatDialogRef<AffiliateGroupsMemberGroupBulkAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      mode: string, 
      groups: any,
      memberGroupVisibilitySettings: { member_group_affiliate_id: number[], member_group_column_visibility: number, member_group_toggle: boolean },
    },
    private groupDataService: GroupDataService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditMemberGroup = appPermissions.edit_affiliate_member_group_settings;
    });

    this.memberGroupDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.affiliateDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'username',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.getDropdown();
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event, refresh = false) {
    this.dialogRef.close(refresh);
  }

  getDropdown() {
    this.memberGroupDropdownList = [];
    this.groupDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&is_affiliate_setting=1&paginate=0`).subscribe(res => {
      this.memberGroupDropdownList = res;
      this.memberGroupDropdownList = this.memberGroupDropdownList.filter(x => x.affiliate_group_id == null || x.affiliate_group_id == '');
      this.memberGroupDropdownList = this.memberGroupDropdownList.map((x) => {
        return {
          id: x.id,
          name: x.name,
          code: x.code,
          default: x.default,
          vip: x.vip,
          trial: x.trial,
          status: x.status,
          affiliate_group_id: x.affiliate_group_id
        }
      });
    });


    this.allAffiliatesDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&paginate=false`).subscribe(res => {
      this.affiliateDropdownList = res;

      if (this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1) {
        this.affiliateListSelectedItems = this.affiliateDropdownList;
        this.filteredAffiliateDropdownList = this.affiliateDropdownList;
      }
      else {
        this.affiliateListSelectedItems = this.affiliateDropdownList.filter(x => this.data.memberGroupVisibilitySettings.member_group_affiliate_id.includes(x.id));

        if (this.data.memberGroupVisibilitySettings.member_group_column_visibility == 2) {
          this.filteredAffiliateDropdownList = this.affiliateDropdownList.filter(x => this.data.memberGroupVisibilitySettings.member_group_affiliate_id.includes(x.id));
        }
        else {
          this.filteredAffiliateDropdownList = this.affiliateDropdownList;
        }
      }
    });
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let affiliate_id = this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 ? [] : this.data.memberGroupVisibilitySettings.member_group_affiliate_id;
    this.form = new FormGroup({
      member_group_id: new FormControl(null, [Validators.required]),
      is_default: new FormControl(1),
      visibility: new FormControl(this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 ? 1 : this.data.memberGroupVisibilitySettings.member_group_column_visibility),
      affiliate_id: new FormControl(affiliate_id),
    });
  }

  onChangeMemberGroupDefault(event: any) {
    if (event.target.checked) {
      this.form.patchValue({ 
        visibility: this.data.memberGroupVisibilitySettings.member_group_column_visibility,
        affiliate_id: this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 ? [] : this.data.memberGroupVisibilitySettings.member_group_affiliate_id,
      });
      this.affiliateListSelectedItems = this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateDropdownList.filter(x => this.data.memberGroupVisibilitySettings.member_group_affiliate_id.includes(x.id));
    }
    else {
      if (this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1) {
        this.form.patchValue({ 
          visibility: null,
          affiliate_id: [],
        })
        this.affiliateListSelectedItems = [];
      }
      else {
        this.form.patchValue({ 
          affiliate_id: [],
        })
        this.affiliateListSelectedItems = [];
      }
    }
  }

  onSelectedAffiliateMemberGroupListItems($event: any) {
    if ($event.length == 0) {
      this.affiliateListSelectedItems = [];
    }
    else {
      this.affiliateListSelectedItems = $event;
    }
  }

  onChangeMemberGroupVisbility(event: any) {
    if (event.target.value) {
      this.form.patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.form.patchValue({ 
          affiliate_id: this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 ? [] : this.data.memberGroupVisibilitySettings.member_group_affiliate_id,
        });
  
        this.affiliateListSelectedItems = this.data.memberGroupVisibilitySettings.member_group_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateListSelectedItems;
        this.filteredAffiliateDropdownList = this.affiliateDropdownList;
      }
      else {
        if (this.data.memberGroupVisibilitySettings.member_group_column_visibility != 1) {
          this.form.patchValue({ 
            affiliate_id: [],
          });
          
          this.affiliateListSelectedItems = [];
    
          if (event.target.value == 3) {
            this.filteredAffiliateDropdownList = this.affiliateDropdownList;
          }
          else if (event.target.value == 2) {
            this.filteredAffiliateDropdownList = this.affiliateDropdownList.filter(x => this.data.memberGroupVisibilitySettings.member_group_affiliate_id.includes(x.id));
          }
        }
      }
    }
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    const memberGroupListData = this.filterFormFields(this.form.value);
    memberGroupListData['affiliate_group_id'] = this.data.groups.id;

    const memberGroupVisibilityData = {
      member_group_column_visibility: this.data.memberGroupVisibilitySettings.member_group_toggle ? +this.data.memberGroupVisibilitySettings.member_group_column_visibility : 0,
    }
    if (this.data.memberGroupVisibilitySettings.member_group_toggle && +this.data.memberGroupVisibilitySettings.member_group_column_visibility > 1) {
      memberGroupVisibilityData['affiliate_id'] = this.data.memberGroupVisibilitySettings.member_group_affiliate_id;
    }

    this.subscription = forkJoin([
      this.affiliateGroupsDataService.createBulkMemberGroupVisibilityList(memberGroupListData),
      this.affiliateGroupsDataService.updateMemberGroupVisibilitySettings(this.data.groups.id, memberGroupVisibilityData),
    ]).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }
}
