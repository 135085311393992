<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form" *ngIf="data.mode === 'edit'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Withdrawal Details</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="container p-0">
          <div class="row">
            <!-- affiliate info -->
            <section class="col-lg-6 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Affiliate Info
                </div>
                <div class="col-12 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Affiliate ID</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_id }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Username</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_username }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Name</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_name }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Group</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_group }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Remarks</span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_remarks }}
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <!-- bank info -->
            <section class="col-lg-6 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Bank Info
                </div>
                <div class="col-12 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                      <span class="kt-font-bold">Bank Name</span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_bank_name }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Account Number</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_account_number }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Account Name</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ affiliateWithdraw.affiliate_account_name }}
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
          <!-- withdraw info -->
          <div class="row mt-2">
            <section class="col-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Withdrawal Info
                </div>
                <div class="col-12 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">ID</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.id }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Created At</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Status</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="affiliateWithdrawStatus === 4 ? 'kt-badge--10' : 'kt-badge--' + affiliateWithdrawStatus"> {{ affiliateWithdraw.status_name }}</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Confirmed Amount ({{affiliateWithdraw.currency_code}})</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.confirmed_amount | number : '1.2-2' }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Handler</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ currentUsername }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Processing Time</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ affiliateWithdraw.processing_time | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Remarks</span>
                    </div>
                    <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      <input type="text" formControlName="remarks" class="form-control" [ngClass]="{ 'is-invalid': this.form.value.errors }" #focusfield>
                      <span class="input-group-append" *ngIf="data.affiliateWithdraw.status === 1">
                        <button type="button" class="btn btn-success" (click)="onUpdateRemarks()">Update</button>
                      </span>
                      <label *ngIf="!isValidRemarks" class="col-12 col-form-label">
                        <mat-error>
                          <strong>Remarks required to reject</strong>
                        </mat-error>
                      </label>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>

          <div class="row mt-2">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Bank Transaction
                </div>
                <section class="col-12 p-4">
                  <div class="row">
                    <kt-withdrawal-multi
                      [from]="'affiliate'"
                      [id]="data.affiliateWithdraw.id"
                      [purposeId]="purposeId"
                      [currencyId]="data.affiliateWithdraw.currency_id"
                      [confirmedAmount]="data.affiliateWithdraw.confirmed_amount"
                      [bankTransactionButtonLoading]="buttonLoading"
                      (changed)="bankTransactionsChanged($event)"
                      [bankTransactions]="data.affiliateWithdraw.bank_transactions"
                      [status]="affiliateWithdrawStatus"
                      [withdrawalDetails]="data.affiliateWithdraw"
                      from="affiliate"
                      (updateWithdraw)="handleUpdateWithdraw($event)"
                      (approveBankTransaction)="handleApproveBankTransaction($event)"
                      (payoutBankTransaction)="handlePayoutBankTransaction($event)"
                      class="w-100">
                    </kt-withdrawal-multi>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div *ngIf="canEditAffiliateWithdrawal" class="modal-footer justify-content-between">
        <button [type]="affiliateWithdrawStatus === 3 ? 'button' : 'submit'" *ngIf="affiliateWithdrawStatus === 3 || affiliateWithdrawStatus === 0 || affiliateWithdrawStatus === 4" class="btn btn-danger" (click)="onTransactionAction(data.affiliateWithdraw, 'reject')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject</button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>