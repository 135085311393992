<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode | titlecase }} Affiliate Group</h5>
                <span class="modal-x-button" (click)="onCloseDialog(true, true)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <mat-tab-group #tabGroup animationDuration="0ms" (selectedTabChange)="tabChanged(tabGroup, $event)" [ngClass]="tabLoading ? 'mat-tab-disabled' : ''">
                    <ng-container>
                        <mat-tab *ngIf="canViewAffiliateGroupSettings" label="Affiliate Group Settings">
                            <div class="row form-group ml-0 mr-0 mb-2">
                                <div class="col-md-12 form-group row">
                                    <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                                    <input type="text" formControlName="name" class="col-8 form-control">
                                </div>
                                <div class="col-12 form-group row">
                                    <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
                                    <select class="col-8 form-control" formControlName="currency_id" (change)="onLimitByCurrency()">
                                        <option value="0" disabled> Please Select </option>
                                        <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-12 form-group row">
                                    <label class="col-4 col-form-label">Default Member Group <span class="text-danger">*</span></label>
                                    <select class="col-8 form-control" formControlName="member_group_id">
                                        <option value="0" disabled> Please Select </option>
                                        <option *ngFor="let value of dropdown.memberGroups" [value]="value.id">{{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-12 form-group row">
                                    <label class="col-4 col-form-label">Type <span class="text-danger">*</span></label>
                                    <select class="col-8 form-control" formControlName="type">
                                        <option value="0" disabled> Please Select </option>
                                        <option *ngFor="let value of dropdown.groupTypes" [value]="value.id">{{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-12 form-group row">
                                    <label class="col-4 col-form-label">Partner Network <span class="text-danger">*</span></label>
                                    <select class="col-8 form-control" formControlName="partner_network">
                                        <option value="0" disabled> Please Select </option>
                                        <option *ngFor="let value of dropdown.partnerNetwork" [value]="value.id">{{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-12 form-group row">
                                    <label class="col-4 col-form-label">Default</label>
                                    <div class="col-8 col-form-label custom-control custom-checkbox">
                                        <input type="checkbox" formControlName="default" class="custom-control-input" id="defaultCheck">
                                        <label class="custom-control-label" for="defaultCheck">{{ form.value.default ? 'Yes' : 'No' }}</label>
                                    </div>
                                </div>
                                <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                                    <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
                                    <select class="col-8 form-control" formControlName="status">
                                        <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                                    </select>
                                </div>
                                <div class="col-12 form-group row">
                                    <label class="col-4 col-form-label">Remarks </label>
                                    <input type="text" formControlName="remarks" class="col-8 form-control">
                                </div>
                                <form [formGroup]="limitForm" *ngIf="data.mode === 'edit'" class="col-12 row form-group ml-0 mr-0 mb-2 p-0">
                                    <div class="col-12 form-group row">
                                        <label class="col-4 col-form-label">Minimum Withdraw</label>
                                        <input type="number" formControlName="min" class="col-8 form-control">
                                    </div>
                                    <div class="col-12 form-group row">
                                        <label class="col-4 col-form-label">Maximum Withdraw </label>
                                        <input type="number" formControlName="max" class="col-8 form-control">
                                    </div>
                                    <div class="col-12 form-group row">
                                        <label class="col-4 col-form-label">Withdraw Daily Maximum Amount</label>
                                        <input type="number" formControlName="daily_max" class="col-8 form-control">
                                    </div>
                                    <div class="col-12 form-group row">
                                        <label class="col-4 col-form-label">Withdraw Daily Maximum Count</label>
                                        <input type="number" formControlName="daily_max_count" class="col-8 form-control">
                                    </div>
                                </form>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="data.mode === 'edit' && canViewAffiliateReferralSettings" label="Affiliate Referral Settings">
                            <div class="row form-group ml-0 mr-0 mb-2">
                                <div [formGroup]="visibilityForm" class="col-12 row p-0 form-group ml-0 mr-0 mb-2">
                                    <div class="col-12 form-group row mb-3">
                                        <label class="col-12"></label>
                                        <label class="col-2 col-form-label sub_tab_title">Column Visibility</label>
                                        <label class="switch mr-3" style="align-self: center;">
                                            <input type="checkbox" formControlName="toggle">
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                        <i class="fas fa-exclamation-circle mt-3" matTooltip="Toggle On to display the 'Member Referral Signup Count' and 'Member Referral FTD Count' columns in the campaign report on Affiliate Portal." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                    </div>
                                    <div class="col-12 form-group row ml-0" id="visibilitySetting" *ngIf="visibilityForm.get('toggle').value">
                                        <select class="col-3 form-control" formControlName="column_visibility">
                                            <option value="null" disabled>Please Select</option>
                                            <option value="1">All</option>
                                            <option value="2">Include Selected</option>
                                            <option value="3">Exclude Selected</option>
                                        </select>
                                        <ng-container *ngIf="visibilityForm.get('column_visibility').value > 1">
                                            <kt-dropdown-wo-lazyload class="col-8" [form]='visibilityForm' [dropdownList]='affiliateDropdownList' [dropdownSettings]='affiliateDropdownListSettings' [formName]="'affiliate_id'" [selectionAttributes]="'id'" [selectedItems]='affiliateSelectedList'>
                                            </kt-dropdown-wo-lazyload>
                                            <i class="fas fa-exclamation-circle mt-3" matTooltip="Affiliates selected will be able/not able to view the 'Member Referral Signup Count' and 'Member Referral FTD Count' columns in the campaign report on Affiliate Portal." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="data.mode === 'edit' && canViewPromotion" label="Affiliate Promotion Settings">
                            <div class="col-12 kt-form kt-form--label-right">
                                <div class="row align-items-center">
                                    <div class="col-xl-12 order-2 order-xl-1">
                                        <form class="align-items-center" [formGroup]="searchPromotionForm">
                                            <div class="row mb-3">
                                                <div class="col-md-1 kt-form__label col-form-label pl-0">
                                                    <label>Promotion Code:</label>
                                                </div>
                                                <div class="col-md-3 kt-form__control">
                                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                                </div>
                                                <div class="col-md-1 kt-form__label col-form-label">
                                                    <label>Promotion Name:</label>
                                                </div>
                                                <div class="col-md-3 kt-form__control">
                                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                                </div>
                                                <div class="col-md-1 kt-form__label col-form-label">
                                                    <label>Bonus Type:</label>
                                                </div>
                                                <div class="col-md-3 kt-form__control pr-0">
                                                    <select formControlName="promo_type" class="form-control">
                                                        <option value="all">{{ 'All' | translate }}</option>
                                                        <option [value]="row.id" *ngFor="let row of dropdown.promoTypes">
                                                            {{ row.name | titlecase | translate }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-1 kt-form__label col-form-label pl-0">
                                                    <label>Currency:</label>
                                                </div>
                                                <div class="col-md-3 kt-form__control">
                                                    <select formControlName="currency_id" class="form-control">
                                                        <option value="all"> All </option>
                                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-1 kt-form__label col-form-label">
                                                    <label>Status:</label>
                                                </div>
                                                <div class="col-md-3 kt-form__control">
                                                    <select formControlName="status" class="form-control">
                                                        <option value="all"> All </option>
                                                        <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!--Action Button-->
                            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                <div class="dropdown dropdown-inline">
                                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="getPromotionVisibility()"></kt-search-button>
                                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                                </div>
                                <div class="dropdown dropdown-inline">
                                    <ng-container *ngIf="canBulkAddPromotion">
                                        <button class="btn btn-warning btn-icon-sm mr-2" (click)="onBulkAddPromotion()" [disabled]="buttonLoading || loading || isOnAddClicked || validationPromotionList()"><i class="fas fa-plus"></i>Bulk Add</button>
                                    </ng-container>
                                    <ng-container *ngIf="canAddPromotion">
                                        <button class="btn btn-brand btn-icon-sm" (click)="onPromotionAddRow(true)" [disabled]="buttonLoading || loading || isOnAddClicked || validationPromotionList()"><i class="fas fa-plus"></i>Add</button>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="kt-section col-12 mb-0">
                                <div class="kt-section__content">
                                    <div class="table-responsive">
                                        <form [formGroup]="promotionVisibilityArrayForm">
                                            <table class="table table-bordered table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" style="width: 10%">Bonus Type</th>
                                                        <th class="text-center" style="width: 20%">Promotion Code</th>
                                                        <th class="text-center" style="width: 10%">Promotion Name</th>
                                                        <th class="text-center" style="width: 8%">Currencies</th>
                                                        <th class="text-center" style="width: 5%">Status</th>
                                                        <th class="text-center" colspan="2">Visibility</th>
                                                        <th *ngIf="canEditPromotion" class="text-center" style="width: 10%">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="promotionContainer">
                                                    <ng-container formArrayName="promotionVisibility">
                                                        <ng-container *ngIf="!loading && promotionVisibilityArr.controls.length > 0">
                                                            <ng-container *ngFor="let row of promotionVisibilityArr.controls; index as i" [formGroupName]="i"> 
                                                                <tr [ngClass]="row.enabled ? 'edit-active-row': ''">
                                                                    <td style="width: 10%">
                                                                        <select formControlName="promo_type" class="form-control" (change)="onPromoTypeDropdown(i)">
                                                                            <option value="null" disabled>Please Select</option>
                                                                            <option [value]="row.id" *ngFor="let row of dropdown.promoTypes">
                                                                                {{ row.name | titlecase | translate }}
                                                                            </option>
                                                                        </select>
                                                                    </td>
                                                                    <td style="width: 20%">
                                                                        <div style="width: 18.7%" class="p-0 position-absolute" [ngClass]="{'div-disabled': row.disabled || row.controls.promo_type.value == null }">
                                                                            <kt-dropdown-wo-lazyload
                                                                                [form]='promotionVisibilityArr.controls[i]'
                                                                                [dropdownList]='promotionListDropdownList[i]'
                                                                                [dropdownSettings]='promotionListDropdownListSettings'
                                                                                [formName]="'promotion_id'"
                                                                                [selectionAttributes]="'id'"
                                                                                [selectedItems]="promotionListSelectedItems[i]">
                                                                            </kt-dropdown-wo-lazyload>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center align-content-center" style="width: 10%">{{ getPromotionName(i) }}</td>
                                                                    <td class="text-center align-content-center" style="width: 8%">{{ getCurrencyName(i) }}</td>
                                                                    <td class="text-center align-content-center" style="width: 5%">
                                                                        <span [ngClass]="getPromotionStatus(i) != '-' ? 'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--' + getPromotionStatus(i): ''"> {{ getPromotionStatus(i) != '-' ? status[getPromotionStatus(i)] : '-' }}</span>
                                                                    </td>
                                                                    <td class="border-top-0 border-right-0 border-left-0 pr-0" style="width: 11%">
                                                                        <select class="form-control" formControlName="visibility" (change)="onChangePromotionVisbility($event, i)">
                                                                            <option value="null" disabled>Please Select</option>
                                                                            <option value="1">All</option>
                                                                            <option value="2">Include Selected</option>
                                                                            <option value="3">Exclude Selected</option>
                                                                        </select>
                                                                    </td>
                                                                    <td class="border-top-0 border-right-0 border-left-0">
                                                                        <ng-container *ngIf="row.controls.visibility.value > 1">
                                                                            <div class="pr-0" [ngClass]="{'is-invalid': checkValidationAffiliatePromotionList(i) && row.controls.visibility.value != null }">
                                                                                <div [ngStyle]="{'width': canEditPromotion ? '24.7%': '34.7%'}" class="p-0 position-absolute affiliate-dropdown" [ngClass]="{'div-disabled': row.disabled || row.controls.visibility.value == null }">
                                                                                    <kt-dropdown-wo-lazyload
                                                                                        [form]='promotionVisibilityArr.controls[i]'
                                                                                        [dropdownList]='affiliatePromotionListDropdownList'
                                                                                        [dropdownSettings]='affiliatePromotionListDropdownListSettings'
                                                                                        [formName]="'affiliate_id'"
                                                                                        [selectionAttributes]="'id'"
                                                                                        [selectedItems]="affiliatePromotionListSelectedItems[i]"
                                                                                        (selectedItemsChanged)="onSelectedAffiliatePromotionListItems($event, i)">
                                                                                    </kt-dropdown-wo-lazyload>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                    <td *ngIf="canEditPromotion" class="text-center" style="width: 10%">
                                                                        <button *ngIf="canEditPromotion && row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="enablePromotionEdit(i)" [disabled]="!row.disabled || validationPromotionList()"><i class="fas fa-edit"></i></button>
                                                                        <button *ngIf="canEditPromotion" matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onPromotionSave(row.value, i)" [disabled]="row.disabled || (!row.disabled && validationPromotionList(i))"><i class="fas fa-save"></i></button>
                                                                        <button *ngIf="canEditPromotion && row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onDismissPromotionRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                                                        <button *ngIf="canDeletePromotion && row.value.mode === 'edit' && row.disabled" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onPromotionDelete(row.value.id)" [disabled]="validationPromotionList()"><i class="fas fa-trash-alt"></i></button>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                                <tbody shared-table-handler [loading]="loading" [dataLength]="promotionVisibility$.length || promotionVisibilityArr.controls.length >= 1" class="text-gray-600 fw-bold"></tbody>
                                            </table>
                                        </form>
                                    </div>
                                    <div class="kt-pagination kt-pagination--brand mt-2 mb-2" *ngIf="pagination !== undefined">
                                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page, pageSize)">
                                        </ngb-pagination>
                                        <div class="kt-pagination__toolbar">
                                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                        </select>
                                        <span class="pagination__desc">
                                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                                        </span>
                                        </div>
                                    </div>            
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="data.mode === 'edit' && canViewDialog" label="Affiliate Dialog Popup Settings">
                            <div class="spinner-wrapper mb-4" *ngIf="popupDialogLoading">
                                <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                            </div>
                            <div class="row form-group ml-0 mr-0 mb-2" *ngIf="!popupDialogLoading">
                                <div [formGroup]="dialogPopupVisibilityForm" class="col-12 row p-0 form-group ml-0 mr-0 mb-2" [ngClass]="{' border-btm-dialog': dialogPopupVisibilityForm.get('dialog_toggle').value}">
                                    <div class="col-12 form-group row mb-3">
                                        <label class="col-12"></label>
                                        <label class="col-2 col-form-label sub_tab_title pl-0">Field Visibility</label>
                                        <label class="switch mr-3" [ngClass]="buttonLoading || loading || validationDialogList() || !canEditDialog ? 'disabled-toggle' : ''" style="align-self: center;" >
                                            <input type="checkbox" formControlName="dialog_toggle" data-target="#dialogPopupVisibilitySetting">
                                            <div [ngClass]="buttonLoading || loading || validationDialogList() || !canEditDialog ? 'disabled-select-toggle' : ''" class="slider round" >
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                        <i class="fas fa-exclamation-circle mt-3" matTooltip="Toggle On to display the 'Dialog Popup' selection in the 'My Campaign' on Affiliate Portal during create or edit." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                    </div>
                                    <div class="col-12 form-group row ml-0 pl-0" id="dialogPopupVisibilitySetting" *ngIf="dialogPopupVisibilityForm.get('dialog_toggle').value">
                                        <select class="col-3 form-control" formControlName="dialog_popup_column_visibility" [value]="dialogPopupVisibilityForm.value.dialog_popup_column_visibility == null ? 1 : dialogPopupVisibilityForm.value.dialog_popup_column_visibility" [ngClass]="buttonLoading || loading || validationDialogList() || !canEditDialog ? 'disabled-select' : ''">
                                            <option value="null" disabled>Please Select</option>
                                            <option value="1">All</option>
                                            <option value="2">Include Selected</option>
                                            <option value="3">Exclude Selected</option>
                                        </select>
                                        <ng-container *ngIf="dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1">
                                            <div class="col-8" [ngClass]="{'is-invalid': dialogPopupVisibilityForm.get('dialog_affiliate_id').value.length == 0 }">
                                                <div [ngClass]="{'div-disabled': buttonLoading || loading || validationDialogList() || !canEditDialog}">
                                                    <kt-dropdown-wo-lazyload 
                                                        [form]='dialogPopupVisibilityForm'
                                                        [dropdownList]='affiliateDropdownList'
                                                        [dropdownSettings]='affiliateDialogDropdownListSettings'
                                                        [formName]="'dialog_affiliate_id'"
                                                        [selectionAttributes]="'id'"
                                                        [selectedItems]='affiliateDialogSelectedList'>
                                                    </kt-dropdown-wo-lazyload>
                                                </div>
                                            </div>
                                            <i class="fas fa-exclamation-circle mt-3 ml-4" matTooltip="Affiliates selected will be able/not able to view the 'Dialog Popup' selection in the 'My Campaign' on Affiliate Portal during create or edit." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container *ngIf="dialogPopupVisibilityForm.get('dialog_toggle').value">
                                    <div class="col-12 row p-0 form-group ml-0 mr-0 mb-2">
                                        <div class="col-12 form-group row mb-3">
                                            <label class="col-12"></label>
                                            <label class="col-2 col-form-label sub_tab_title pl-0">Dialog Popup Visibility</label>
                                        </div>
                                    </div>
                                    <div class="col-12 kt-form kt-form--label-right">
                                        <div class="row align-items-center">
                                            <div class="col-xl-12 order-2 order-xl-1">
                                                <form class="align-items-center" [formGroup]="searchForm">
                                                    <div class="row mb-3">
                                                        <div class="col-md-1 kt-form__label col-form-label pl-0">
                                                            <label>Code:</label>
                                                        </div>
                                                        <div class="col-md-3 kt-form__control">
                                                            <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                                        </div>
                                                        <div class="col-md-1 kt-form__label col-form-label">
                                                            <label>Locale:</label>
                                                        </div>
                                                        <div class="col-md-3 kt-form__control">
                                                            <select formControlName="locale_id" class="form-control">
                                                                <option value="all"> {{ 'All' }} </option>
                                                                <option [value]="value.id" *ngFor="let value of dropdown.locales">
                                                                    {{ value.code }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                        <div class="dropdown dropdown-inline">
                                            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="getDialogPopupVisibility()"></kt-search-button>
                                            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                                        </div>
                                        <div class="dropdown dropdown-inline">
                                            <ng-container *ngIf="canBulkAddDialog">
                                                <button class="btn btn-warning btn-icon-sm mr-2" (click)="onBulkAddDialog()" [disabled]="buttonLoading || loading || isOnAddClicked || validationDialogList()"><i class="fas fa-plus"></i>Bulk Add</button>
                                            </ng-container>
                                            <ng-container *ngIf="canAddDialog">
                                                <button class="btn btn-brand btn-icon-sm" (click)="onAddRow(true)" [disabled]="buttonLoading || loading || isOnAddClicked || validationDialogList()"><i class="fas fa-plus"></i>Add</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="kt-section col-12 mb-0">
                                        <div class="kt-section__content">
                                            <div class="table-responsive">
                                                <form [formGroup]="dialogPopupVisibilityArrayForm">
                                                    <table class="table table-bordered table-hover table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center" style="width: 30%">Dialog Code (Popup Title)</th>
                                                                <th class="text-center" style="width: 10%">Locales</th>
                                                                <th class="text-center" style="width: 7%">Default<i class="fas fa-exclamation-circle" matTooltip="Check to apply the same visibility settings from Field Visibility." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i></th>
                                                                <th class="text-center" colspan="2">Visibility</th>
                                                                <th *ngIf="canEditDialog" class="text-center" style="width: 10%">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container formArrayName="dialogPopupVisibility">
                                                                <ng-container *ngIf="!loading && dialogPopupVisibilityArr.controls.length > 0 && currentActiveTab == 3">
                                                                    <ng-container *ngFor="let row of dialogPopupVisibilityArr.controls; index as i" [formGroupName]="i"> 
                                                                        <tr [ngClass]="row.enabled ? 'edit-active-row': ''">
                                                                            <td style="width: 30%">
                                                                                <select formControlName="popup_id" class="form-control" [ngClass]="{'is-invalid': checkValidation && row.controls.popup_id.errors }">
                                                                                    <option value="null" disabled>Please Select</option>
                                                                                    <option [value]="value.id" *ngFor="let value of getPopupDropdownList(row.controls.popup_id.value)">
                                                                                        {{ value.labelKey }}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="text-center align-content-center" style="width: 10%">{{ getLocaleName(i) }}</td>
                                                                            <td class="text-center align-content-center" style="width: 7%">
                                                                                <div class="custom-control custom-checkbox p-0">
                                                                                    <input type="checkbox" formControlName="is_default" (change)="onChangePopupDialogDefault($event, i)" id="is_default" [ngClass]="{'is-invalid': checkValidation && row.controls.is_default.errors }">
                                                                                </div>
                                                                            </td>
                                                                            <td class="border-top-0 border-right-0 border-left-0 pr-0" style="width: 12%">
                                                                                <select class="form-control" formControlName="visibility" [ngClass]="isPopupDialogDefault(i) == true || dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 2 || dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3 ? 'disabled-select' : ''" (change)="onChangePopupDialogVisbility($event, i)">
                                                                                    <option value="null" disabled>Please Select</option>
                                                                                    <ng-container *ngIf="dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 || row.controls.is_default.value">
                                                                                        <option value="1">All</option>
                                                                                    </ng-container>
                                                                                    <option value="2">Include Selected</option>
                                                                                    <option value="3">Exclude Selected</option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="border-top-0 border-right-0 border-left-0">
                                                                                <ng-container *ngIf="row.controls.visibility.value > 1">
                                                                                    <div class="pr-0" [ngClass]="{'is-invalid': checkValidationAffiliateDialogList(i) && row.controls.visibility.value != null }">
                                                                                        <div [ngStyle]="{'width': (canEditDialog) ? '29.7%': '39.7%'}" class="p-0 position-absolute affiliate-dropdown" [ngClass]="{'div-disabled': row.disabled || row.controls.is_default.value || row.controls.visibility.value == 1 || row.controls.visibility.value == null }">
                                                                                            <kt-dropdown-wo-lazyload
                                                                                                [form]='dialogPopupVisibilityArr.controls[i]'
                                                                                                [dropdownList]='affiliateDialogListDropdownList'
                                                                                                [dropdownSettings]='affiliateDialogListDropdownListSettings'
                                                                                                [formName]="'affiliate_id'"
                                                                                                [selectionAttributes]="'id'"
                                                                                                [selectedItems]="affiliateDialogListSelectedItems[i]"
                                                                                                (selectedItemsChanged)="onSelectedAffiliateDialogListItems($event, i)">
                                                                                            </kt-dropdown-wo-lazyload>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td *ngIf="canEditDialog" class="text-center" style="width: 10%">
                                                                                <button *ngIf="canEditDialog && row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="enableEdit(i)" [disabled]="!row.disabled || validationDialogList()"><i class="fas fa-edit"></i></button>
                                                                                <button *ngIf="canEditDialog" matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onPopupDialogSave(row.value, i)" [disabled]="row.disabled || (!row.disabled && validationDialogList(i))"><i class="fas fa-save"></i></button>
                                                                                <button *ngIf="canEditDialog && row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onDismissRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                                                                <button *ngIf="canDeleteDialog && row.value.mode === 'edit' && row.disabled" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onPopupDialogDelete(row.value.id)" [disabled]="validationDialogList()"><i class="fas fa-trash-alt"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tbody>
                                                        <tbody shared-table-handler [loading]="loading" [dataLength]="dialogPopupVisibility$.length || dialogPopupVisibilityArr.controls.length >= 1" class="text-gray-600 fw-bold"></tbody>
                                                    </table>
                                                </form>
                                            </div>
                                            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                                                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page, pageSize)">
                                                </ngb-pagination>
                                                <div class="kt-pagination__toolbar">
                                                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                                                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                                </select>
                                                <span class="pagination__desc">
                                                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                                                </span>
                                                </div>
                                            </div>            
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="data.mode === 'edit' && canViewInboxMessage" label="Affiliate Inbox Message Settings">
                            <div class="spinner-wrapper mb-4" *ngIf="inboxMessageLoading">
                                <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                            </div>
                            <div class="row form-group ml-0 mr-0 mb-2" *ngIf="!inboxMessageLoading">
                                <div [formGroup]="inboxMessageVisibilityForm" class="col-12 row p-0 form-group ml-0 mr-0 mb-2" [ngClass]="{'border-btm-dialog': inboxMessageVisibilityForm.get('inbox_message_toggle').value}">
                                    <div class="col-12 form-group row mb-3">
                                        <label class="col-12"></label>
                                        <label class="col-2 col-form-label sub_tab_title pl-0">Field Visibility</label>
                                        <label class="switch mr-3" [ngClass]="buttonLoading || loading || validationInboxList() || !canEditInboxMessage ? 'disabled-toggle' : ''" style="align-self: center;" >
                                            <input type="checkbox" formControlName="inbox_message_toggle" data-target="#inboxMessageVisibilitySetting">
                                            <div [ngClass]="buttonLoading || loading || validationInboxList() || !canEditInboxMessage ? 'disabled-select-toggle' : ''" class="slider round" >
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                        <i class="fas fa-exclamation-circle mt-3" matTooltip="Toggle On to display the 'Inbox Message' selection in the 'My Campaign' on Affiliate Portal during create or edit." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                    </div>
                                    <div class="col-12 form-group row ml-0 pl-0" id="inboxMessageVisibilitySetting" *ngIf="inboxMessageVisibilityForm.get('inbox_message_toggle').value">
                                        <select class="col-3 form-control" formControlName="inbox_message_column_visibility" [value]="inboxMessageVisibilityForm.value.inbox_message_column_visibility == null ? 1 : inboxMessageVisibilityForm.value.inbox_message_column_visibility" [ngClass]="buttonLoading || loading || validationInboxList() || !canEditInboxMessage ? 'disabled-select' : ''">
                                            <option value="null" disabled>Please Select</option>
                                            <option value="1">All</option>
                                            <option value="2">Include Selected</option>
                                            <option value="3">Exclude Selected</option>
                                        </select>
                                        <ng-container *ngIf="inboxMessageVisibilityForm.get('inbox_message_column_visibility').value > 1">
                                            <div class="col-8" [ngClass]="{'is-invalid': inboxMessageVisibilityForm.get('inbox_message_affiliate_id').value.length == 0 }">
                                                <div [ngClass]="{'div-disabled': buttonLoading || loading || validationInboxList() || !canEditInboxMessage}">
                                                    <kt-dropdown-wo-lazyload 
                                                        [form]='inboxMessageVisibilityForm'
                                                        [dropdownList]='affiliateDropdownList'
                                                        [dropdownSettings]='affiliateInboxDropdownListSettings'
                                                        [formName]="'inbox_message_affiliate_id'"
                                                        [selectionAttributes]="'id'"
                                                        [selectedItems]='affiliateInboxSelectedList'>
                                                    </kt-dropdown-wo-lazyload>
                                                </div>
                                            </div>
                                            <i class="fas fa-exclamation-circle mt-3 ml-4" matTooltip="Affiliates selected will be able/not able to view the 'Inbox Message' selection in the 'My Campaign' on Affiliate Portal during create or edit." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container *ngIf="inboxMessageVisibilityForm.get('inbox_message_toggle').value">
                                    <mat-tab-group #childTabs class="w-100 mb-3 mt-4">
                                        <mat-tab label="General">
                                            <ng-template></ng-template>
                                            <div class="col-12 row p-0 form-group ml-0 mr-0 mb-2 mt-3">
                                                <div class="col-12 form-group row mb-3">
                                                    <label class="col-12"></label>
                                                    <label class="col-2 col-form-label sub_tab_title pl-0">General Inbox Message Visibility</label>
                                                </div>
                                            </div>
                                            <div class="col-12 kt-form kt-form--label-right">
                                                <div class="row align-items-center">
                                                    <div class="col-xl-12 order-2 order-xl-1">
                                                        <form class="align-items-center" [formGroup]="searchInboxMessageForm">
                                                            <div class="row mb-2">
                                                                <div class="col-md-1 kt-form__label col-form-label pl-0">
                                                                    <label>Code:</label>
                                                                </div>
                                                                <div class="col-md-3 kt-form__control">
                                                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                                                </div>
                                                                <div class="col-md-1 kt-form__label col-form-label">
                                                                    <label>Locale:</label>
                                                                </div>
                                                                <div class="col-md-3 kt-form__control">
                                                                    <select formControlName="locale_id" class="form-control">
                                                                        <option value="all"> {{ 'All' }} </option>
                                                                        <option [value]="value.id" *ngFor="let value of dropdown.locales">
                                                                            {{ value.code }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                                <div class="dropdown dropdown-inline">
                                                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="getInboxMessageVisibility()"></kt-search-button>
                                                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                                                </div>
                                                <div class="dropdown dropdown-inline">
                                                    <ng-container *ngIf="canBulkAddInboxMessage">
                                                        <button class="btn btn-warning btn-icon-sm mr-2" (click)="onBulkAddInbox()" [disabled]="buttonLoading || loading || isOnAddClicked || validationInboxList()"><i class="fas fa-plus"></i>Bulk Add</button>
                                                    </ng-container>
                                                    <ng-container *ngIf="canAddInboxMessage">
                                                        <button class="btn btn-brand btn-icon-sm" (click)="onInboxAddRow(true)" [disabled]="buttonLoading || loading || isOnAddClicked || validationInboxList()"><i class="fas fa-plus"></i>Add</button>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="kt-section col-12 mb-0">
                                                <div class="kt-section__content">
                                                    <div class="table-responsive">
                                                        <form [formGroup]="inboxMessageVisibilityArrayForm">
                                                            <table class="table table-bordered table-hover table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center" style="width: 30%">Code</th>
                                                                        <th class="text-center" style="width: 11%">Locales</th>
                                                                        <th class="text-center" style="width: 7%">Default<i class="fas fa-exclamation-circle" matTooltip="Check to apply the same visibility settings from Field Visibility." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i></th>
                                                                        <th class="text-center" colspan="2">Visibility</th>
                                                                        <th *ngIf="canEditInboxMessage" class="text-center" style="width: 10%">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <ng-container formArrayName="inboxMessageVisibility">
                                                                        <ng-container *ngIf="!loading && inboxMessageVisibilityArr.controls.length > 0 && currentActiveTab == 4">
                                                                            <ng-container *ngFor="let row of inboxMessageVisibilityArr.controls; index as i" [formGroupName]="i"> 
                                                                                <tr [ngClass]="row.enabled ? 'edit-active-row': ''">
                                                                                    <td style="width: 30%">
                                                                                        <!-- <select formControlName="message_template_id" class="form-control" [ngClass]="{'is-invalid': checkValidation && row.controls.message_template_id.errors }">
                                                                                            <option value="null" disabled>Please Select</option>
                                                                                            <option [value]="value.id" *ngFor="let value of getInboxDropdownList(row.controls.message_template_id.value)">
                                                                                                {{ value.code }}
                                                                                            </option>
                                                                                        </select> -->
                                                                                        <div style="width: 28.75%" class="p-0 position-absolute" [ngClass]="{'div-disabled': row.disabled }">
                                                                                            <kt-dropdown-wo-lazyload
                                                                                                [form]='inboxMessageVisibilityArr.controls[i]'
                                                                                                [dropdownList]='inboxMessageListDropdownList[i]'
                                                                                                [dropdownSettings]='inboxMessageListDropdownListSettings'
                                                                                                [formName]="'message_template_id'"
                                                                                                [selectionAttributes]="'id'"
                                                                                                [selectedItems]="inboxMessageListSelectedItems[i]">
                                                                                            </kt-dropdown-wo-lazyload>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="text-center align-content-center" style="width: 11%">{{ getInboxLocaleName(i) }}</td>
                                                                                    <td class="text-center align-content-center" style="width: 7%">
                                                                                        <div class="custom-control custom-checkbox p-0">
                                                                                            <input type="checkbox" formControlName="is_default" (change)="onChangeInboxMessageDefault($event, i)" id="is_default" [ngClass]="{'is-invalid': checkValidation && row.controls.is_default.errors }">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="border-top-0 border-right-0 border-left-0 pr-0" style="width: 12%">
                                                                                        <select class="form-control" formControlName="visibility" [ngClass]="isInboxMessageDefault(i) == true || inboxMessageVisibilityForm.value.inbox_message_column_visibility == 2 || inboxMessageVisibilityForm.value.inbox_message_column_visibility == 3 ? 'disabled-select' : ''" (change)="onChangeInboxMessageVisbility($event, i)">
                                                                                            <option value="null" disabled>Please Select</option>
                                                                                            <ng-container *ngIf="inboxMessageVisibilityForm.value.inbox_message_column_visibility != 1 || row.controls.is_default.value">
                                                                                                <option value="1">All</option>
                                                                                            </ng-container>
                                                                                            <option value="2">Include Selected</option>
                                                                                            <option value="3">Exclude Selected</option>
                                                                                        </select>
                                                                                    </td>
                                                                                    <td class="border-top-0 border-right-0 border-left-0">
                                                                                        <ng-container *ngIf="row.controls.visibility.value > 1">
                                                                                            <div class="pr-0" [ngClass]="{'is-invalid': checkValidationAffiliateInboxList(i) && row.controls.visibility.value != null }">
                                                                                                <div [ngStyle]="{'width': (canEditInboxMessage) ? '28.7%': '38.7%'}" class="p-0 position-absolute affiliate-dropdown" [ngClass]="{'div-disabled': row.disabled || row.controls.is_default.value || row.controls.visibility.value == 1 || row.controls.visibility.value == null }">
                                                                                                    <kt-dropdown-wo-lazyload
                                                                                                        [form]='inboxMessageVisibilityArr.controls[i]'
                                                                                                        [dropdownList]='affiliateInboxListDropdownList'
                                                                                                        [dropdownSettings]='affiliateInboxListDropdownListSettings'
                                                                                                        [formName]="'affiliate_id'"
                                                                                                        [selectionAttributes]="'id'"
                                                                                                        [selectedItems]="affiliateInboxListSelectedItems[i]"
                                                                                                        (selectedItemsChanged)="onSelectedAffiliateInboxListItems($event, i)">
                                                                                                    </kt-dropdown-wo-lazyload>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </td>
                                                                                    <td *ngIf="canEditInboxMessage" class="text-center" style="width: 10%">
                                                                                        <button *ngIf="canEditInboxMessage && row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="enableInboxEdit(i)" [disabled]="!row.disabled || validationInboxList()"><i class="fas fa-edit"></i></button>
                                                                                        <button *ngIf="canEditInboxMessage" matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onInboxMessageSave(row.value, i)" [disabled]="row.disabled || (!row.disabled && validationInboxList(i))"><i class="fas fa-save"></i></button>
                                                                                        <button *ngIf="canEditInboxMessage && row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onDismissInboxRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                                                                        <button *ngIf="canDeleteInboxMessage && row.value.mode === 'edit' && row.disabled" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onInboxMessageDelete(row.value.id)" [disabled]="validationInboxList()"><i class="fas fa-trash-alt"></i></button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </tbody>
                                                                <tbody shared-table-handler [loading]="loading" [dataLength]="inboxMessageVisibility$.length || inboxMessageVisibilityArr.controls.length >= 1" class="text-gray-600 fw-bold"></tbody>
                                                            </table>
                                                        </form>
                                                    </div>
                                                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                                                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page, pageSize)">
                                                        </ngb-pagination>
                                                        <div class="kt-pagination__toolbar">
                                                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                                                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                                        </select>
                                                        <span class="pagination__desc">
                                                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                                                        </span>
                                                        </div>
                                                    </div>            
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Affiliate">
                                            <ng-template></ng-template>
                                            <div class="col-12 row p-0 form-group ml-0 mr-0 mb-2 mt-3">
                                                <div class="col-12 form-group row mb-3">
                                                    <label class="col-12"></label>
                                                    <label class="col-2 col-form-label sub_tab_title pl-0">Affiliate Inbox Message Visibility</label>
                                                </div>
                                            </div>
                                            <div class="col-12 kt-form kt-form--label-right">
                                                <div class="row align-items-center">
                                                    <div class="col-xl-12 order-2 order-xl-1">
                                                        <form class="align-items-center" [formGroup]="searchInboxMessageAffForm">
                                                            <div class="row mb-2">
                                                                <div class="col-md-1 kt-form__label col-form-label pl-0">
                                                                    <label>Code:</label>
                                                                </div>
                                                                <div class="col-md-3 kt-form__control">
                                                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                                                </div>
                                                                <div class="col-md-1 kt-form__label col-form-label">
                                                                    <label>Locale:</label>
                                                                </div>
                                                                <div class="col-md-3 kt-form__control">
                                                                    <select formControlName="locale" class="form-control">
                                                                        <option value="all"> {{ 'All' }} </option>
                                                                        <option [value]="value.id" *ngFor="let value of dropdown.locales">
                                                                            {{ value.code }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-1 kt-form__label col-form-label">
                                                                    <label>Visibility:</label>
                                                                </div>
                                                                <div class="col-md-3 kt-form__control">
                                                                    <kt-member-dropdown 
                                                                        class="dropdown-maxheight" 
                                                                        [form]="searchInboxMessageAffForm" 
                                                                        [isAffiliate]="true" 
                                                                        [formName]="'created_by'"
                                                                        [dropdownSettings]='affiliateInboxListAffDropdownListSettings' 
                                                                        [selectionAttributes]="'id'">
                                                                    </kt-member-dropdown>
                                                                    <!-- [selectedItems]="selectedMembers" -->
                                                                    <!-- (selectedItemsChanged)="onSelectAffiliate($event)" -->
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                                <div class="dropdown dropdown-inline">
                                                    <kt-search-button [loading]="searchBtnLoadingAff" [disabledButton]="loadingAff" (search)="onSubmitAffInboxMessage()"></kt-search-button>
                                                    <kt-clear-button [loading]="clearBtnLoadingAff" [disabledButton]="loadingAff" (clear)="onClearAffInboxMessage()"></kt-clear-button>
                                                </div>
                                            </div>
                                            <div class="kt-section col-12 mb-0">
                                                <div class="kt-section__content">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered table-hover table-striped table-sortable">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Code</th>
                                                                    <th class="text-center">Locales</th>
                                                                    <th class="text-center">Visibility</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="(affiliateMessageTemplates$ | async) as rows">
                                                                <tr *ngFor="let row of rows">
                                                                    <td>{{ row.code | uppercase }}</td>
                                                                    <td class="text-center">{{ row.locales }}</td>
                                                                    <td class="text-center">{{ row.created_by }}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody shared-table-handler [loading]="loadingAff" [dataLength]="dataLengthAff" class="text-gray-600 fw-bold"></tbody>
                                                        </table>
                                                    </div>
                                                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="paginationAff !== undefined">
                                                        <ngb-pagination
                                                            [pageSize]="pageSizeAff"
                                                            [(page)]="pageAff"
                                                            [maxSize]="maxSize"
                                                            [directionLinks]="true"
                                                            [boundaryLinks]="true"
                                                            [rotate]="true"
                                                            [collectionSize]="paginationAff.total"
                                                            (pageChange)="onViewPageByAffInboxMessage(pageAff)">
                                                        </ngb-pagination>
                                                        <div class="kt-pagination__toolbar">
                                                        <select (change)="onPerPageAffInboxMessage($event)" [(ngModel)]="pageSizeAff" [ngModelOptions]="{ standalone: true }" class="form-control kt-font-brand page-size">
                                                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                                        </select>
                                                        <span class="pagination__desc">
                                                            Showing {{paginationAff.from}} to {{paginationAff.to}} of {{ paginationAff.total | number : '1.0' }} records
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                </ng-container>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="data.mode === 'edit' && canViewMemberGroup" label="Affiliate Member Group Settings">
                            <div class="spinner-wrapper mb-4" *ngIf="memberGroupLoading">
                                <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                            </div>
                            <div class="row form-group ml-0 mr-0 mb-2" *ngIf="!memberGroupLoading">
                                <div [formGroup]="memberGroupVisibilityForm" class="col-12 row p-0 form-group ml-0 mr-0 mb-2" [ngClass]="{'border-btm-dialog': memberGroupVisibilityForm.get('member_group_toggle').value}">
                                    <div class="col-12 form-group row mb-3">
                                        <label class="col-12"></label>
                                        <label class="col-2 col-form-label sub_tab_title pl-0">Field Visibility</label>
                                        <label class="switch mr-3" [ngClass]="buttonLoading || loading || validationMemberGroupList() || !canEditMemberGroup ? 'disabled-toggle' : ''" style="align-self: center;" >
                                            <input type="checkbox" formControlName="member_group_toggle" data-target="#memberGroupVisibilitySetting">
                                            <div [ngClass]="buttonLoading || loading || validationMemberGroupList() || !canEditMemberGroup ? 'disabled-select-toggle' : ''" class="slider round" >
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                        <i class="fas fa-exclamation-circle mt-3" matTooltip="Toggle On to display the 'MemberGroup' selection in the 'My Campaign' on Affiliate Portal during create or edit." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                    </div>
                                    <div class="col-12 form-group row ml-0 pl-0" id="memberGroupVisibilitySetting" *ngIf="memberGroupVisibilityForm.get('member_group_toggle').value">
                                        <select class="col-3 form-control" formControlName="member_group_column_visibility" [value]="memberGroupVisibilityForm.value.member_group_column_visibility == null ? 1 : memberGroupVisibilityForm.value.member_group_column_visibility" [ngClass]="buttonLoading || loading || validationMemberGroupList() || !canEditMemberGroup ? 'disabled-select' : ''">
                                            <option value="null" disabled>Please Select</option>
                                            <option value="1">All</option>
                                            <option value="2">Include Selected</option>
                                            <option value="3">Exclude Selected</option>
                                        </select>
                                        <ng-container *ngIf="memberGroupVisibilityForm.get('member_group_column_visibility').value > 1">
                                            <div class="col-8" [ngClass]="{'is-invalid': memberGroupVisibilityForm.get('member_group_affiliate_id').value.length == 0 }">
                                                <div [ngClass]="{'div-disabled': buttonLoading || loading || validationMemberGroupList() || !canEditMemberGroup}">
                                                    <kt-dropdown-wo-lazyload 
                                                        [form]='memberGroupVisibilityForm'
                                                        [dropdownList]='affiliateDropdownList'
                                                        [dropdownSettings]='affiliateMemberGroupDropdownListSettings'
                                                        [formName]="'member_group_affiliate_id'"
                                                        [selectionAttributes]="'id'"
                                                        [selectedItems]='affiliateMemberGroupSelectedList'>
                                                    </kt-dropdown-wo-lazyload>
                                                </div>
                                            </div>
                                            <i class="fas fa-exclamation-circle mt-3 ml-4" matTooltip="Affiliates selected will be able/not able to view the 'Member Group' selection in the 'My Campaign' on Affiliate Portal during create or edit." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container *ngIf="memberGroupVisibilityForm.get('member_group_toggle').value">
                                    <div class="col-12 row p-0 form-group ml-0 mr-0 mb-2">
                                        <div class="col-12 form-group row mb-3">
                                            <label class="col-12"></label>
                                            <label class="col-2 col-form-label sub_tab_title pl-0">Member Group Visibility</label>
                                        </div>
                                    </div>
                                    <div class="col-12 kt-form kt-form--label-right">
                                        <div class="row align-items-center">
                                            <div class="col-xl-12 order-2 order-xl-1">
                                                <form class="align-items-center" [formGroup]="searchMemberGroupForm">
                                                    <div class="row mb-3">
                                                        <div class="col-md-1 kt-form__label col-form-label pl-0">
                                                            <label>Member Group:</label>
                                                        </div>
                                                        <div class="col-md-3 kt-form__control">
                                                            <kt-dropdown-wo-lazyload 
                                                                [form]='form' 
                                                                [dropdownList]='dropdown.memberGroups' 
                                                                [dropdownSettings]='memberGroupListDropdownListSettings' 
                                                                [formName]="'member_group_id'" 
                                                                [selectionAttributes]="'id'" 
                                                                [selectedItems]='memberGroupsSelectedItems'
                                                                (selectedItemsChanged)="onChangeMemberGroup($event)">
                                                            </kt-dropdown-wo-lazyload>
                                                        </div>
                                                        <div class="col-md-1 kt-form__label col-form-label">
                                                            <label>Group Type:</label>
                                                        </div>
                                                        <div class="col-md-3 kt-form__control">
                                                            <select formControlName="group_type" class="form-control">
                                                                <option value="all"> {{ 'All' }} </option>
                                                                <option [value]="value.id" *ngFor="let value of dropdown.memberGroupTypes">
                                                                    {{ value.name }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-1 kt-form__label col-form-label">
                                                            <label>Status:</label>
                                                        </div>
                                                        <div class="col-md-3 kt-form__control pr-0">
                                                            <select formControlName="status" class="form-control">
                                                                <option value="all"> All </option>
                                                                <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                                        <div class="dropdown dropdown-inline">
                                            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="getMemberGroupVisibility()"></kt-search-button>
                                            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                                        </div>
                                        <div class="dropdown dropdown-inline">
                                            <ng-container *ngIf="canBulkAddMemberGroup">
                                                <button class="btn btn-warning btn-icon-sm mr-2" (click)="onBulkAddMemberGroup()" [disabled]="buttonLoading || loading || isOnAddClicked || validationMemberGroupList()"><i class="fas fa-plus"></i>Bulk Add</button>
                                            </ng-container>
                                            <ng-container *ngIf="canAddMemberGroup">
                                                <button class="btn btn-brand btn-icon-sm" (click)="onMemberGroupAddRow(true)" [disabled]="buttonLoading || loading || isOnAddClicked || validationMemberGroupList()"><i class="fas fa-plus"></i>Add</button>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="kt-section col-12 mb-0">
                                        <div class="kt-section__content">
                                            <div class="table-responsive">
                                                <form [formGroup]="memberGroupVisibilityArrayForm">
                                                    <table class="table table-bordered table-hover table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center" style="width: 20%">Member Group</th>
                                                                <th class="text-center" style="width: 7%">Type</th>
                                                                <th class="text-center" style="width: 5%">Status</th>
                                                                <th class="text-center" style="width: 7%">Default<i class="fas fa-exclamation-circle" matTooltip="Check to apply the same visibility settings from Field Visibility." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i></th>
                                                                <th class="text-center" colspan="2">Visibility</th>
                                                                <th *ngIf="canEditMemberGroup" class="text-center" style="width: 10%">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container formArrayName="memberGroupVisibility">
                                                                <ng-container *ngIf="!loading && memberGroupVisibilityArr.controls.length > 0 && currentActiveTab == 5">
                                                                    <ng-container *ngFor="let row of memberGroupVisibilityArr.controls; index as i" [formGroupName]="i"> 
                                                                        <tr [ngClass]="row.enabled ? 'edit-active-row': ''">
                                                                            <td style="width: 20%">
                                                                                <!-- <select formControlName="member_group_id" class="form-control" [ngClass]="{'is-invalid': checkValidation && row.controls.member_group_id.errors }">
                                                                                    <option value="null" disabled>Please Select</option>
                                                                                    <option [value]="value.id" *ngFor="let value of getMemberGroupDropdownList(row.controls.member_group_id.value)">
                                                                                        {{ value.code }}
                                                                                    </option>
                                                                                </select> -->
                                                                                <div style="width: 18.60%" class="p-0 position-absolute" [ngClass]="{'div-disabled': row.disabled }">
                                                                                    <kt-dropdown-wo-lazyload
                                                                                        [form]='memberGroupVisibilityArr.controls[i]'
                                                                                        [dropdownList]='memberGroupListDropdownList[i]'
                                                                                        [dropdownSettings]='memberGroupListDropdownListSettings'
                                                                                        [formName]="'member_group_id'"
                                                                                        [selectionAttributes]="'id'"
                                                                                        [selectedItems]="memberGroupListSelectedItems[i]">
                                                                                    </kt-dropdown-wo-lazyload>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngClass]="memberGroupVisibilityArr.controls[i].value.member_group_id != null ? 'group-padding' : 'text-center'" class="align-content-center no-wrap pt-0 pb-0" style="width: 7%;">
                                                                                <ng-container *ngIf="memberGroupVisibilityArr.controls[i].value.member_group_id != null; else noMemberGroup">
                                                                                    <span class="no-wrap">
                                                                                        <i matTooltip="{{ 'Default' | translate }}" [ngClass]="getMemberGroupType(i, 'default') > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                                                                        {{ 'Default' | translate }}
                                                                                    </span><br>
                                                                                    <span class="no-wrap">
                                                                                        <i matTooltip="{{ 'VIP' | translate }}" [ngClass]="getMemberGroupType(i, 'vip') > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                                                                        {{ 'VIP' | translate }}
                                                                                    </span><br>
                                                                                    <span class="no-wrap">
                                                                                        <i matTooltip="{{ 'Trial' | translate }}" [ngClass]="getMemberGroupType(i, 'trial') > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                                                                        {{ 'Trial' | translate }}
                                                                                    </span><br>
                                                                                </ng-container>
                                                                                <ng-template #noMemberGroup>
                                                                                    -
                                                                                </ng-template>
                                                                            </td>
                                                                            <td class="text-center align-content-center" style="width: 5%">
                                                                                <span [ngClass]="getMemberGroupStatus(i) != '-' ? 'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--' + getMemberGroupStatus(i): ''"> {{ getMemberGroupStatus(i) != '-' ? status[getMemberGroupStatus(i)] : '-' }}</span>
                                                                            </td>
                                                                            <td class="text-center align-content-center" style="width: 7%">
                                                                                <div class="custom-control custom-checkbox p-0">
                                                                                    <input type="checkbox" formControlName="is_default" (change)="onChangeMemberGroupDefault($event, i)" id="is_default" [ngClass]="{'is-invalid': checkValidation && row.controls.is_default.errors }">
                                                                                </div>
                                                                            </td>
                                                                            <td class="border-top-0 border-right-0 border-left-0 pr-0" style="width: 12%">
                                                                                <select class="form-control" formControlName="visibility" [ngClass]="isMemberGroupDefault(i) == true || memberGroupVisibilityForm.value.member_group_column_visibility == 2 || memberGroupVisibilityForm.value.member_group_column_visibility == 3 ? 'disabled-select' : ''" (change)="onChangeMemberGroupVisbility($event, i)">
                                                                                    <option value="null" disabled>Please Select</option>
                                                                                    <ng-container *ngIf="memberGroupVisibilityForm.value.member_group_column_visibility != 1 || row.controls.is_default.value || (memberGroupVisibilityArr.controls[i].value.member_group_id != null && getMemberGroupType(i, 'default') > 0)">
                                                                                        <option value="1">All</option>
                                                                                    </ng-container>
                                                                                    <option value="2">Include Selected</option>
                                                                                    <option value="3">Exclude Selected</option>
                                                                                </select>
                                                                            </td>
                                                                            <td class="border-top-0 border-right-0 border-left-0">
                                                                                <ng-container *ngIf="row.controls.visibility.value > 1">
                                                                                    <div class="pr-0" [ngClass]="{'is-invalid': checkValidationAffiliateMemberGroupList(i) && row.controls.visibility.value != null }">
                                                                                        <div [ngStyle]="{'width': (canEditMemberGroup) ? '37.4%': '47.4%'}" class="p-0 position-absolute affiliate-dropdown" [ngClass]="{'div-disabled': row.disabled || row.controls.is_default.value || row.controls.visibility.value == 1 || row.controls.visibility.value == null }">
                                                                                            <kt-dropdown-wo-lazyload
                                                                                                [form]='memberGroupVisibilityArr.controls[i]'
                                                                                                [dropdownList]='affiliateMemberGroupListDropdownList'
                                                                                                [dropdownSettings]='affiliateMemberGroupListDropdownListSettings'
                                                                                                [formName]="'affiliate_id'"
                                                                                                [selectionAttributes]="'id'"
                                                                                                [selectedItems]="affiliateMemberGroupListSelectedItems[i]"
                                                                                                (selectedItemsChanged)="onSelectedAffiliateMemberGroupListItems($event, i)">
                                                                                            </kt-dropdown-wo-lazyload>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td *ngIf="canEditMemberGroup" class="text-center" style="width: 10%">
                                                                                <button *ngIf="canEditMemberGroup && row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="enableMemberGroupEdit(i)" [disabled]="!row.disabled || validationMemberGroupList() || getMemberGroupType(i, 'default') == 1"><i class="fas fa-edit"></i></button>
                                                                                <button *ngIf="canEditMemberGroup" matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onMemberGroupSave(row.value, i)" [disabled]="row.disabled || (!row.disabled && validationMemberGroupList(i)) || getMemberGroupType(i, 'default') == 1"><i class="fas fa-save"></i></button>
                                                                                <button *ngIf="canEditMemberGroup && row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onDismissMemberGroupRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                                                                <button *ngIf="canDeleteMemberGroup && row.value.mode === 'edit' && row.disabled" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onMemberGroupDelete(row.value.id)" [disabled]="validationMemberGroupList() || getMemberGroupType(i, 'default') == 1"><i class="fas fa-trash-alt"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tbody>
                                                        <tbody shared-table-handler [loading]="loading" [dataLength]="memberGroupVisibility$.length || memberGroupVisibilityArr.controls.length >= 1" class="text-gray-600 fw-bold"></tbody>
                                                    </table>
                                                </form>
                                            </div>
                                            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                                                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page, pageSize)">
                                                </ngb-pagination>
                                                <div class="kt-pagination__toolbar">
                                                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                                                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                                </select>
                                                <span class="pagination__desc">
                                                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                                                </span>
                                                </div>
                                            </div>            
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
                
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog(true, true)"><i class="fas fa-ban"></i>Close</button>
                <ng-container *ngIf="(canEditAffiliateGroupSettings && currentActiveTab == 0) ||
                                     (canEditAffiliateReferralSettings && currentActiveTab == 1) ||
                                     (canEditPromotion && currentActiveTab == 2) ||
                                     (canEditDialog && currentActiveTab == 3) || 
                                     (canEditInboxMessage && currentActiveTab == 4) || 
                                     (canEditMemberGroup && currentActiveTab == 5)">
                    <kt-submit-button text="Save" 
                        [isDisabled]="(!form.valid && currentActiveTab == 0) || 
                        (!visibilityForm.valid && currentActiveTab == 1) || 
                        (isDisabledPromotionSubmit && currentActiveTab == 2) || 
                        (!dialogPopupVisibilityForm.valid && currentActiveTab == 3) || 
                        (!inboxMessageVisibilityForm.valid && currentActiveTab == 4) || 
                        (!memberGroupVisibilityForm.valid && currentActiveTab == 5) || 
                        (dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value != 1 && dialogPopupVisibilityForm.get('dialog_affiliate_id').value.length == 0 && currentActiveTab == 3) ||
                        (inboxMessageVisibilityForm.get('inbox_message_column_visibility').value != 1 && inboxMessageVisibilityForm.get('inbox_message_affiliate_id').value.length == 0 && currentActiveTab == 4) ||
                        (memberGroupVisibilityForm.get('member_group_column_visibility').value != 1 && memberGroupVisibilityForm.get('member_group_affiliate_id').value.length == 0 && currentActiveTab == 5) ||
                        validationInboxList() || validationDialogList() || validationMemberGroupList() ||
                        popupDialogLoading || inboxMessageLoading || memberGroupLoading || searchBtnLoading || clearBtnLoading" 
                        [buttonLoading]="buttonLoading" 
                        (confirmed)="onSave(currentActiveTab)">
                    </kt-submit-button>
                </ng-container>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true, false)"></kt-swal-alert>