import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { GameProviderHttpService } from "@core/services/game-provider-http.service";
import { of, Subscription } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { RebateDataService } from "../../../../../apps/rebates/rebates/services/rebate-data.service";
import { GroupDataService } from "../../services/group-data.service";

@Component({
  selector: "kt-rebate-settings",
  templateUrl: "./rebate-settings.component.html",
  styleUrls: ["./rebate-settings.component.scss"],
})
export class RebateSettingsDialogComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  filterForm: FormGroup;
  form: FormGroup;

  dropdown = {
    providers: null,
    currencies: [{ id: null, name: "All", rebate_setting_id: null }],
    categories: null,
  };

  provider: any = {
    code: null,
    id: null,
  };
  category: any = {
    category_id: null,
    category_name: null,
  };

  params = "";

  rebateSettings = [];
  gameProviderRebateSettings = [];
  messages$ = this.groupDataService.messages$;
  validForm = false;

  isLoading = true;
  buttonLoading = false;

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { mode: string; groupId: number },
    public dialogRef: MatDialogRef<RebateSettingsDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private groupDataService: GroupDataService,
    private rebateDataService: RebateDataService,
    private gameProviderService: GameProviderHttpService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    // Initialize form
    this.filterFormInit();

    // Get rebate settings
    this.rebateDataService
      .getAll()
      .subscribe((res) => (this.rebateSettings = res));

    // Get currencies
    this.dropdownHttpService.currencies.subscribe((res) => {
      res.map((item) => {
        this.dropdown.currencies.push({ ...item, rebate_setting_id: null });
      });
    });

    this.getGameProvider();
  }

  private filterFormInit() {
    this.filterForm = new FormGroup({
      game_provider_id: new FormControl(null),
      category_id: new FormControl(null),
      currency_id: new FormControl(null), // Default 0, equal to all
      member_group_id: new FormControl(this.data.groupId)
    });
  }

  ngOnDestroy() {}

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onFilter(type?: string) {
    this.validForm = false;
    switch (type) {
      case "provider": {
        this.provider = this.dropdown.providers.find(
          (x) => x.id === this.filterForm.get("game_provider_id").value
        );
        this.getGameCategory(true);
        break;
      }
      case "category": {
        this.category = this.dropdown.categories.find(
          (x) => x.category_id === this.filterForm.get("category_id").value
        );
        this.getGameProviderRebateSettings();
        break;
      }
    }
  }

  getGameProvider() {
    this.isLoading = true;
    // Get game provider list
    this.dropdownHttpService.gameProviders.subscribe((gpRes) => {
      // Set game provider dropdown list
      this.dropdown.providers = gpRes;
      // Set first item of the array as default selection
      this.filterForm.patchValue({ game_provider_id: gpRes[0].id });
      this.provider = { code: gpRes[0].code, id: gpRes[0].id };

      this.getGameCategory(true);
    });
  }

  getGameCategory(init = false) {
    this.isLoading = true;
    // Get game category based on game provider
    this.gameProviderService
      .getGameCategory(this.filterForm.get("game_provider_id").value)
      .subscribe((gcRes) => {
        // Set game category dropdown list
        this.dropdown.categories = gcRes;
        // Set first item of the array as default selection
        this.filterForm.patchValue({ category_id: gcRes[0].category_id });
        this.category = {
          category_id: gcRes[0].category_id,
          category_name: gcRes[0].category_name,
        };
        this.isLoading = false;
        init ? this.getGameProviderRebateSettings() : "";
      });
  }

  getGameProviderRebateSettings() {
    this.isLoading = true;
    // Get game provider rebate settings based on member group & game provider & currency
    of(this.filterForm.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          const parameters = this.params ? `?${this.params}` : "";
          this.groupDataService
            .getRebateSettings(parameters)
            .subscribe((res) => {
              this.gameProviderRebateSettings = res.filter((x) => {
                x.currency_id = x.settings_currency_id;
                delete x.settings_currency_id;
                return (
                  x.category_id === this.filterForm.get("category_id").value
                );
              });
              // Update dropdown currencies rebate_setting_id field
              this.dropdown.currencies.forEach((item) => {
                var rebateSetting = this.gameProviderRebateSettings.find(
                  (each) => {
                    return each.currency_id === item.id;
                  }
                );
                item.rebate_setting_id = rebateSetting
                  ? rebateSetting.rebate_setting_id
                  : null;
              });
              this.isLoading = false;
            });
        })
      )
      .subscribe();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.validForm = false;

    const data = {
      member_group_id: this.data.groupId,
      rebates: this.gameProviderRebateSettings,
    };
    this.subscription = this.groupDataService
      .updateRebateSettings(data)
      .pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.validForm = true;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      )
      .subscribe();
  }

  updateData(currencyId: number, rebateId: any) {
    const existingRecordIndex = this.gameProviderRebateSettings.findIndex(
      (x) => x.currency_id === currencyId
    );
    if (existingRecordIndex > -1) {
      const updatedRebate = this.gameProviderRebateSettings;
      updatedRebate[existingRecordIndex] = {
        ...updatedRebate[existingRecordIndex],
        rebate_setting_id: +rebateId,
      };
      this.gameProviderRebateSettings = updatedRebate;
      this.validForm = true;
    } else {
      const row = {
        game_provider_id: this.provider.id,
        currency_id: currencyId,
        category_id: this.category.category_id,
        rebate_setting_id: +rebateId,
        status: 1,
      };
      this.gameProviderRebateSettings.push(row);
      this.validForm = true;
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) =>
      formData[key] !== "" && formData[key] !== null
        ? (fields[key] = formData[key])
        : key
    );
    return fields;
  }

}
