import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren, Optional, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as Editor from "@core/../../assets/js/global/integration/plugins/ckeditor";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import { EditorService } from "@core/services/editor.service";
import { SendSMSHttpService } from "@core/services/send-sms-http.service";
import * as moment from 'moment-timezone';
import { forkJoin, Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { catchError, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AppPermissionService } from "@core/services/app-permission.service";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { ProviderPermissionsDataService } from "../../providers/service/providers-permissions-data.service";
import { MessageTemplateDataService } from "@views/pages/apps/superuser/message-template/services/message-template-data.service";
import Swal from "sweetalert2";
import { MessageTemplateEditComponent } from "@views/pages/apps/superuser/message-template/dialogs/message-template-edit.component";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SendMessageHistoriesComponent } from "@views/pages/apps/sms/send/send-message-histories/send-message-histories.component";
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ProvidersDataService } from "../../providers/service/providers-data.service";
import { SendMessageHistoriesDataService } from '@views/pages/apps/sms/send/send-message-histories/service/send-message-histories-data.service';

declare var $: any;

interface Region {
  id: number;
  name: string;
  currency_name: string; // currency's code
}

@Component({
  selector: "app-send-sms-tab",
  templateUrl: "./send-sms-tab.component.html",
  styleUrls: ["./send-sms-tab.component.scss"],
})
export class SendSmsTabComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren("editors") editorComponent: QueryList<CKEditorComponent>;

  @Input()
  mode: string = 'Edit'; // Default to Edit (tab mode)

  @Input()
  genericnotice: string;

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;

  messages$ = this.sendSMSHttpService.messages$;

  private _filteredCurrencies: any[] = [];

  templateFileName: string = 'ExampleImportSendSMSMember.csv';

  sendSMSForm: FormGroup;
  checkValidation = false;
  buttonLoading = false;
  selectedRegions = [];
  selectedMembers = [];

  regions: Region[] = [];

  smsProviders = [];
  usableVariables: any;

  regionsDropdownSettings = {};
  memberGroupDropdownSettings = {};
  smsMemberDropdownSettings = {};
  messageTemplateSectionDropdownSettings = {};
  smsMessageTemplateDropdownSettings = {};

  memberGroupDropdownList = [];
  messageTemplateSectionDropdownList = [];
  messageTemplateDropdownList = [];
  oriMessageTemplateDropdownList = [];


  regionsSelectedItems = [];
  memberGroupSelectedItems = [];
  selectedMessageTemplateSection = [];
  selectedMessageTemplate = [];

  disableMessageField = false;
  attachedFile: any = null;

  originalLanguages: any = [];
  languages: any = [];

  fileName = null;
  file: File;

  minDate = moment();

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    smsProvider: this.providersDataService.getProviders(`?smstype=all`),
    groups: this.dropdownHttpService.groups,
    locales$: this.messageTemplateDataService.locales$,
    locales: this.dropdownHttpService.locales,
  };

  private localesGroupsLoaded$ = new Subject<void>();
  private messageTemplateLoaded$ = new Subject<void>();
  private providerLoaded$ = new Subject<void>();

  private subscriptions = new Subscription();

  timezone = JSON.parse(localStorage.getItem("user_data")).timezone;

  // permissions
  canSendSMS: boolean;
  canViewSendSMSHistories: boolean;
  canSendSMSNow: boolean;
  canUpdateSMSSettings: boolean;
  canCancelScheduledSMS: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private providersDataService: ProvidersDataService,
    private sendSMSHttpService: SendSMSHttpService,
    private editorService: EditorService,
    private transactionHttpService: TransactionHttpService,
    private providerPermissionsDataService: ProviderPermissionsDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
    private dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    @Optional() public dialogRef: MatDialogRef<SendSmsTabComponent>,
    private sendMessageHistoriesDataService: SendMessageHistoriesDataService,
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.initRegions();
    this.setMemberGroupsAndLocales();
    this.setProviders();
    this.initDropdownSettings();
    this.setPermissions();

    if( this.mode === 'Edit' ) {
      this.setFormData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewChecked(): void {}

  onOpenDialog(type: string) {
    switch(type) {
      case 'message_template':
        this.openDialogBy(MessageTemplateEditComponent, { mode: 'create' });
        break;
      case 'history':
        // TODO
        this.openDialogBy(SendMessageHistoriesComponent, { type: 'sms' });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: any) {
    const dialogData = data || {};
    switch( componentRef ) {
      case MessageTemplateEditComponent:
        const messageTemplateDialogRef = this.dialog.open(componentRef, {
          data: dialogData,
          width: '1000px'
        });
        messageTemplateDialogRef.afterClosed().subscribe(() => {
          // this.initMessageTemplateDropdown();
        });
        break;
      case SendMessageHistoriesComponent:
        this.dialog.open(componentRef, {
          data: dialogData,
          width: '1400px',
        });
        break;
    }
  }

  getFilteredProviders(currencyId: string): any[] {
    return this.smsProviders.filter(provider => provider.currency.includes(currencyId));
  }

  onSectionChange(sectionID: number, name: string) {
    this.messageTemplateDataService.getMessageTemplatVariables(sectionID, name).subscribe((res) => {
      this.usableVariables = res["usable_variables"];
    });
  }

  onSelectMemberGroup(event: any){
    this.memberGroupSelectedItems = [...event];

    // Disable/Enable member dropdown based on selection
    this.smsMemberDropdownSettings = {
      ...this.smsMemberDropdownSettings,
      disabled: !event?.length
    };

    if (event?.length) {
      this.sendSMSForm.get('send_to_all_members').enable();
    } else {
      this.selectedMembers = [];
      this.sendSMSForm.get('send_to_all_members').disable();

      this.sendSMSForm.patchValue({
        member_account_ids: [],
        send_to_all_members: false
      });
    }

    this.cdr.detectChanges();
  }

  onSelectUsableVariable(usableVariable: String, i) {
    let editorInstance = this.editorComponent.toArray()[i].editorInstance;
    const selection = editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    editorInstance.editing.view.focus();
    editorInstance.model.change((writer) => {
      writer.insert(usableVariable + " ", range.start);
      const rangeAfterAppend = selection.getFirstRange();
      writer.setSelection(writer.createPositionAt(rangeAfterAppend.start));
    });
  }

  onMessageTemplateSectionChanged(event) {
    this.usableVariables = [];

    if (event[0]?.section) {
      this.messageTemplateDataService.getMessageTemplatVariables(event[0]?.section).subscribe((res) => {
        if( res ) {
          this.usableVariables = res["usable_variables"];
          this.cdr.detectChanges();
        }
      });
      this.sendSMSForm.patchValue({section: event[0].section});
    }
    this.smsMessageTemplateDropdownSettings = {
      ...this.smsMessageTemplateDropdownSettings,
      disabled: event.length === 0,
    };

    const selectedSection = event[0]?.section;
    this.messageTemplateDropdownList = this.oriMessageTemplateDropdownList.filter((template) => template.section === selectedSection);

    this.selectedMessageTemplate = [];
    this.disableMessageField = false;
    this.resetMessage();
    this.cdr.detectChanges();
  }

  onMessageTemplateChanged(event: any) {
    this.disableMessageField = false;

    this.resetMessage();
    if (event.length > 0) {
      this.messageTemplateDataService.getById(event[0].id).subscribe((res) => {
        if(res) {
          const messageDetailsArray = Object.entries(res.message_details).map(([key, item]) => item);
          messageDetailsArray.forEach((item) => {
            const localeId = item["settings_locale_id"];
            const detailsGroup = this.sendSMSForm.get(`details.${localeId}`);
            if (detailsGroup) {
              detailsGroup.patchValue({
                subject: item["subject"],
                message: item["message"],
              });
            }
          });
          this.disableMessageField = true;
          this.updateDetailsValidators();
          this.cdr.detectChanges();
        }
      });
      this.sendSMSForm.patchValue({message_template: event[0].id});
    }
  }

  resetMessage() {
    const detailsGroup = this.sendSMSForm?.get("details") as FormGroup;
    Object.keys(detailsGroup?.controls || {}).forEach((localeId) => {
      const detailsControl = detailsGroup.get(localeId) as any;

      if (detailsControl) {
        detailsControl.patchValue({
          subject: null,
          message: null,
        });
      }
    });
  }

  resetForm() {
    this.regionsSelectedItems = [];
    this.selectedMembers = [];

    // reset all form values
    this.sendSMSForm.patchValue({
      settings_currency_ids: [],
      member_type: 1,
      member_group_ids: [],
      member_account_ids: [],
      send_to_all_members: 0,
      attached_file: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      sms_providers: [],
      section: null,
      message_template: null,
    });
    this.resetMessage(); // details need to be reset separately
    this.memberGroupSelectedItems = [];
    this.selectedMessageTemplateSection = [];
    this.selectedMessageTemplate = [];

    this.smsMemberDropdownSettings = {
      ...this.smsMemberDropdownSettings,
      disabled: true,
    };
    this.sendSMSForm.get('send_to_all_members').disable();

    const inputFile = document.getElementById('sms-file') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = ''; // Clear the input value
    }

    this.onRemoveFile();
  }

  updateDetailsValidators() {
    const detailsGroup = this.sendSMSForm?.get("details") as FormGroup;

    const isLocaleComplete = (control): boolean => {
      const value = control.value;
      return value.subject && value.message;
    };

    // Check if any locale has complete data
    const hasCompleteLocale = Object.values(detailsGroup?.controls || {})
      .some(control => isLocaleComplete(control)); 

    // Update validators for all locales
    Object.keys(detailsGroup?.controls || {}).forEach((localeId) => {
      const detailsControl = detailsGroup.get(localeId) as any;

      if (detailsControl) {
        if (this.languages.find(language => language.id == localeId)) {
          // If no locale is complete, set as required
          // If a locale is complete, only keep required for the complete locale
          const shouldBeRequired = !hasCompleteLocale || 
                                 isLocaleComplete(detailsControl);

          if (shouldBeRequired) {
            detailsControl.controls.message.setValidators([Validators.required]);
            detailsControl.controls.subject.setValidators([Validators.required]);
          } else {
            detailsControl.controls.message.clearValidators();
            detailsControl.controls.subject.clearValidators();
          }
        } else {
          detailsControl.controls.message.clearValidators();
          detailsControl.controls.subject.clearValidators();
        }

        detailsControl.controls.message.updateValueAndValidity({ emitEvent: false });
        detailsControl.controls.subject.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  onSendToAllMembersChange(event) {
    const checked = event.target.checked;

    // disable member dropdown if send to all members is checked
    this.smsMemberDropdownSettings = {
      ...this.smsMemberDropdownSettings,
      disabled: checked,
    };

    const memberAccountIdsControl = this.sendSMSForm.get('member_account_ids');
    if (checked) {
      this.selectedMembers = [];
      memberAccountIdsControl.setValue([]);
      memberAccountIdsControl.clearValidators();
    } else {
      memberAccountIdsControl.setValidators([Validators.required]);
    }

    memberAccountIdsControl.updateValueAndValidity();
  }

  onProviderSelect(provider_id: any, currency_id: any) {
    const providerId = parseInt(provider_id);
    const currencyId = currency_id;

    let providers = [...this.sendSMSForm.get('sms_providers').value];

    // Find if we already have a provider for this currency
    const existingIndex = providers.findIndex(p => 
      p.settings_currency_id === currencyId
    );
  
    if (existingIndex >= 0) {
      // Update existing provider
      providers[existingIndex] = {
        settings_currency_id: currencyId,
        sms_provider_id: providerId
      };
    } else {
      // Add new provider
      providers.push({
        settings_currency_id: currencyId,
        sms_provider_id: providerId
      });
    }

    this.sendSMSForm.get('sms_providers').setValue(providers);
    this.cdr.detectChanges();
  }

  validateProviders(): boolean {
    const settingsCurrencyIds = this.sendSMSForm.get('settings_currency_ids').value;
    const providers = this.sendSMSForm.get('sms_providers').value;

    if (!settingsCurrencyIds || !providers) return false;

    // Check if we have a provider for each selected region/currency
    return settingsCurrencyIds.every(settingsCurrencyId => 
      providers.some(p => p.settings_currency_id === settingsCurrencyId)
    );
  }

  onSend() {
    this.checkValidation = true;
    if (this.sendSMSForm.valid && this.validateProviders()) {
      let data = {
        ...this.sendSMSForm.getRawValue(),
        send_to_all_members: this.sendSMSForm.get('send_to_all_members').value ? 1 : 0
      };

      if( this.mode === 'Edit' ) {
        data.id = this.data.id;
      }

      data = this.filterFormFields(data);
  
      if(data.datetime) {
        const scheduledDateTimeString = data.datetime;
        const scheduledDateTime = new Date(`${scheduledDateTimeString}Z`);
        const currentDateTimeUTC = new Date();

        if (scheduledDateTime > currentDateTimeUTC) {
          this.scheduleSMS(data);
        } else {
          Swal.fire({
            title: 'Invalid Date Time',
            html: '<div>The selected date and time have already passed. Do you want to proceed with sending it immediately?</p></div>',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            allowOutsideClick: false
          }).then((response) => {
            if (response.isConfirmed) {
              this.scheduleSMS(data);
            } else {
              this.checkValidation = false;
            }
          });
        }
      } else {
        this.scheduleSMS(data);
      }
    }
  }

  scheduleSMS(data) {
    this.buttonLoading = true;
    const formData = new FormData();

    // Append all fields to FormData
    Object.keys(data).forEach(key => {
      // Handle complex objects/arrays with JSON.stringify
      if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
        formData.append(key, JSON.stringify(data[key]));
      }
      // Handle simple values as is
      else {
        formData.append(key, data[key]);
      }
    });

    const sub = forkJoin([
      this.sendSMSHttpService.sendSms(formData).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          this.disableMessageField = false; 

          this.resetForm();

          // To enable "Save" button after get response
          this.sendSMSForm.setErrors(null);

          this.onCloseDialog(true);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable "Save" button after get response
          this.sendSMSForm.setErrors(null);
          throw error;
        })
      ),
      this.sendSMSHttpService.messages$,
    ]).subscribe();

    this.subscriptions.add(sub);
  }

  buildSubDetails = () => {
    let details = {};

    this.originalLanguages.map((element) => {
      const detailsGroup = new FormGroup({
        settings_locale_id: new FormControl(element.id, []),
        subject: new FormControl(null, []),
        message: new FormControl(null, []),
      });
      details = { ...details, [element.id]: detailsGroup };
    });

    return details;
  };

  private formInit() {
    this.sendSMSForm = new FormGroup({
      settings_currency_ids: new FormControl(null, [Validators.required]),
      member_type: new FormControl(1, [Validators.required]), // 1 = Member, 2 = Non-Member
      member_group_ids: new FormControl([], [Validators.required]),
      member_account_ids: new FormControl([], [Validators.required]),
      send_to_all_members: new FormControl({ value: 0, disabled: true }), // disabled initially
      attached_file: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      sms_providers: new FormControl([], [Validators.required]),
      section: new FormControl(null),
      message_template: new FormControl(null),
      details: new FormGroup({}),
    });

    if( this.mode === 'Edit' ) {
      this.sendSMSForm.addControl('attached_file_url', new FormControl(null));
    }

    const settingsCurrencyIdsSub = this.sendSMSForm.get('settings_currency_ids').valueChanges.subscribe((currentSettingsCurrencyIds: number[]) => {
      if (!currentSettingsCurrencyIds) {
        this.sendSMSForm.get('sms_providers').setValue([]);
        return;
      }

      const previousProviders = this.sendSMSForm.get('sms_providers').value;

      // Keep only providers that are still in selected regions/currencies
      const updatedProviders = previousProviders.filter(provider => 
        currentSettingsCurrencyIds.includes(provider.settings_currency_id)
      );

      // Only update if there's a change
      if (updatedProviders.length !== previousProviders.length) {
        this.sendSMSForm.get('sms_providers').setValue(updatedProviders);
      }
    });

    const memberTypeSub = this.sendSMSForm.get('member_type').valueChanges.subscribe(value => {
      // Update template file name based on member type
      this.templateFileName = value === 1 ? 
        'ExampleImportSendSMSMember.csv' : 
        'ExampleImportSendSMSNonMember.csv';

      // Reset related form controls when switching to non member type
      if (value === 2) {
        this.memberGroupSelectedItems = [];
        this.selectedMembers = [];

        this.sendSMSForm.patchValue({
          member_group_ids: [],
          member_account_ids: [],
          send_to_all_members: false
        });
        this.sendSMSForm.controls.member_group_ids.clearValidators();
        this.sendSMSForm.controls.member_account_ids.clearValidators();
        this.sendSMSForm.controls.send_to_all_members.disable();
      } else {
        this.sendSMSForm.controls.member_group_ids.setValidators([Validators.required]);
        this.sendSMSForm.controls.member_account_ids.setValidators([Validators.required]);
        this.sendSMSForm.controls.send_to_all_members.enable();
      }
    });

    this.subscriptions.add(settingsCurrencyIdsSub);
    this.subscriptions.add(memberTypeSub);
  }

  isProviderSelectedForCurrency(currencyId: number): boolean {
    const providers = this.sendSMSForm.get('sms_providers').value;
    return providers.some(p => p.settings_currency_id === currencyId);
  }

  getSelectedProviderForCurrency(currencyId: number): number | null {
    const providers = this.sendSMSForm.get('sms_providers').value;
    const provider = providers.find(p => p.settings_currency_id === currencyId);
    return provider ? provider.sms_provider_id : null;
  }

  private initSMSMessageTemplateDropdown() {
    // Reset dropdown
    this.messageTemplateSectionDropdownList = [];
    this.messageTemplateDropdownList = [];

    // Get locales by selected regions
    const currencyIds = this.selectedRegions.map(currency => currency.id);
    const localeIds = this.originalLanguages
      .filter(locale => currencyIds.includes(locale.settings_currency_id))
      .map(locale => locale.id);

    // Prepare query string
    const data = {
      'paginate': false,
      'status': 1,
      'type': [2],
      'locale': localeIds
    };
    const queryString = new URLSearchParams(
      Object.entries(data).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(val => acc.push([`${key}[]`, val.toString()]));
        } else {
          acc.push([key, value.toString()]);
        }
        return acc;
      }, [] as [string, string][])
    ).toString();

    // Call API to get message templates
    if( currencyIds.length > 0 ) {
      this.messageTemplateDataService.getWithQuery('?'+queryString).subscribe(res => {
        this.selectedMessageTemplate = this.selectedMessageTemplateSection = [];
  
        this.messageTemplateDropdownList = res;
        this.oriMessageTemplateDropdownList = [...res];
  
        const uniqueSections = new Set();
        this.messageTemplateDropdownList.forEach(item => {
          if (!uniqueSections.has(item.section_name)) {
              uniqueSections.add(item.section_name);
              this.messageTemplateSectionDropdownList.push(item);
          }
        });

        this.resetMessage();
        
        this.messageTemplateLoaded$.next();
        this.messageTemplateLoaded$.complete();
        
        this.cdr.detectChanges();
      })
    }
  }

  onRegionChanged(selectedRegions) {
    this.selectedRegions = selectedRegions;
    this.regionsSelectedItems = [...this.selectedRegions];

    // Remove file if region change and there's a file
    if (this.file) {
      this.onRemoveFile();
    }

    // Set languages for content body
    this.setLanguagesBasedOnRegions(selectedRegions);

    // Initialize message template dropdown
    this.initSMSMessageTemplateDropdown();

    // update message templates options based on region languages 
    const selectedSection = this.sendSMSForm.get('section').value;
    if( selectedSection ) {
      this.messageTemplateDropdownList = this.oriMessageTemplateDropdownList.filter((template) => {
        const availableLocaleCodes = this.languages.map(language => language.code);
        const currentTemplateLocaleCodes = template.locales.split(',');
        const hasMatchingLocale = availableLocaleCodes.some(availableCode => 
          currentTemplateLocaleCodes.includes(availableCode)
        );
  
        return hasMatchingLocale && template.section === selectedSection;
      });
    }

    // reset message template and section
    this.usableVariables = [];
    this.sendSMSForm.patchValue({
      section: null,
      message_template: null,
    });
    this.selectedMessageTemplateSection = [];
    this.selectedMessageTemplate = [];
    this.disableMessageField = false;

    this.cdr.detectChanges();
  }

  onFileUpload(event: any) {
    const file: File = event.target.files[0];
    const fileExtension = file.name.split('.').pop();
    if (fileExtension !== 'csv') {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File Type',
        text: 'Please upload a CSV file!',
      });
      return;
    }

    this.file = file;

    const formData = new FormData();
    formData.append('attached_file', file, file.name);
    this.sendSMSForm.patchValue({
      attached_file: file,
      member_group_ids: [],
    });

    this.sendSMSForm.controls.member_group_ids.clearValidators();
    this.sendSMSForm.controls.member_account_ids.clearValidators();
    this.sendSMSForm.get('member_group_ids').updateValueAndValidity();
    this.sendSMSForm.get('member_account_ids').updateValueAndValidity();
    this.sendSMSForm.get('send_to_all_members')?.setValue(0);
    this.sendSMSForm.get('send_to_all_members').disable()

    this.memberGroupDropdownSettings = {
      ...this.memberGroupDropdownSettings,
      disabled: true
    };

    this.smsMemberDropdownSettings = {
      ...this.smsMemberDropdownSettings,
      disabled: true
    };

    if( this.mode === 'Edit' ) {
      this.sendSMSForm.patchValue({attached_file_url: null});
      this.attachedFile = null;
    }

    this.sendSMSForm.get('member_type').disable();

    this.cdr.detectChanges();
  }

  onRemoveFile() {
    this.file = null;
    const inputFile = document.getElementById('sms-file') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = ''; // Clear the input value
    }
    this.sendSMSForm.patchValue({attached_file: null});

    if( this.mode === 'Edit' ) {
      this.sendSMSForm.patchValue({attached_file_url: null});
      this.attachedFile = null;
    }

    this.memberGroupDropdownSettings = {
      ...this.memberGroupDropdownSettings,
      disabled: false
    };

    this.smsMemberDropdownSettings = {
      ...this.smsMemberDropdownSettings,
      disabled: false
    };

    this.sendSMSForm.get('member_type').enable();

    this.sendSMSForm.controls.member_group_ids.setValidators([Validators.required]);
    this.sendSMSForm.controls.member_account_ids.setValidators([Validators.required]);
    this.sendSMSForm.get('member_group_ids').updateValueAndValidity();
    this.sendSMSForm.get('member_account_ids').updateValueAndValidity();
    this.sendSMSForm.get('send_to_all_members').enable()

    this.cdr.detectChanges();
  }

  isMemberType(): boolean {
    return this.sendSMSForm.get('member_type').value === 1;
  }

  private setLanguagesBasedOnRegions(selectedRegions) {
    this.languages = this.originalLanguages.filter(language => this.selectedRegions.find(r => r.id == language.settings_currency_id));
  }

  private initRegions() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        this.regions = this.dropdown.currencies.map(currency => ({
          id: currency.id,
          name: this.getCurrencyRegion(currency.name),
          currency_name: currency.name
        }));
      });
    } else {
      this.regions = this.dropdown.currencies.map(currency => ({
        id: currency.id,
        name: this.getCurrencyRegion(currency.name),
        currency_name: currency.name
      }));
    }
  }

  filteredCurrencies() {
    const selectedRegions = this.sendSMSForm.get('settings_currency_ids').value;
    if (!selectedRegions) return [];

    this._filteredCurrencies = this.dropdown.currencies.filter(
      currency => selectedRegions.includes(currency.id)
    );

    return this._filteredCurrencies;
  }

  private getCurrencyRegion(currencyName: string): string {
    const currencyRegionMap = {
      'MYR': 'MY',
      'SGD': 'SG',
      'IDR': 'ID',
      'THB': 'TH',
      'INR': 'IN',
      'USD': 'US',
      'PHP': 'PH'
    };
    return currencyRegionMap[currencyName] || '';
  }

  private filterFormFields(data: any) {
    const fields = {};
    Object.keys(data).forEach(key => {
      if (key === 'start_datetime' && data[key]) {
        fields['datetime'] = moment(data[key]).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      }
      else if (data[key] !== '' && data[key] !== null && key !== 'defaultDate' && key !== 'end_datetime') {
        fields[key] = data[key];
      }
    });

    return fields;
  }

  private setMemberGroupsAndLocales() {
    forkJoin({
      locales: this.dropdown.locales,
      groups: this.dropdown.groups
    }).subscribe(({ locales, groups }) => {  
      // Logic for locales
      this.messageTemplateDataService.locales$.next(locales);
      this.originalLanguages = [...locales];
      const detailsFormGroup = new FormGroup(this.buildSubDetails());
      this.sendSMSForm.setControl('details', detailsFormGroup);
      this.setLanguagesBasedOnRegions([]);
  
      // Logic for groups
      this.memberGroupDropdownList = groups;
  
      // Combined logic after both are completed
      this.localesGroupsLoaded$.next();
      this.localesGroupsLoaded$.complete();
    });
  }

  private setPermissions() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
      this.canSendSMS = appPermissions.send_sms;
      this.canViewSendSMSHistories = appPermissions.view_send_sms_histories;
      this.canSendSMSNow = appPermissions.send_sms_now;
      this.canUpdateSMSSettings = appPermissions.update_sms_settings;
      this.canCancelScheduledSMS = appPermissions.cancel_scheduled_sms;
    });

    this.subscriptions.add(apSub);
  }

  private initDropdownSettings() {
    this.regionsDropdownSettings = {
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "id",
      labelKey: "name",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
    };

    this.memberGroupDropdownSettings = {
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "name",
      labelKey: "name",
      searchBy: ["name"],
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
    };

    this.smsMemberDropdownSettings = {
      singleSelection: false,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "labelKey",
      labelKey: "username",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
      disabled: true,
    };

    this.messageTemplateSectionDropdownSettings = {
      singleSelection: true,
      text: 'Message Section',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'section',
      labelKey: 'section_name',
      noDataLabel: '',
      showCheckbox: false,
      disabled: false,
      autoPosition: false,
    };

    this.smsMessageTemplateDropdownSettings = {
      singleSelection: true,
      text: "Template Code",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      maxHeight: 200, //'auto',
      primaryKey: "id",
      labelKey: "code",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false,
      autoPosition: false,
    };
  }

  onCloseDialog(refresh: boolean) {
    if( this.mode == 'Edit' && this.dialogRef ) {
      this.dialogRef.close(refresh);
    }
  }

  onSendNow() {
    if( this.mode === 'Edit' ) {
      const data = {
        'id' : this.data.id,
        'send_now' : true,
        'schedule_datetime' : moment(new Date()).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.updateRequest(data);
    }
  }

  onCancel() {
    if( this.mode === 'Edit' ) {
      const data = {
        'id' : this.data.id,
        'status': 4 // Cancel
      };
      this.updateRequest(data);
    }
  }

  updateRequest(data) {
    this.buttonLoading = true;
    this.sendMessageHistoriesDataService.updateHistory(data).pipe(
      tap((res: any) => {
        this.messages$ = this.sendMessageHistoriesDataService.messageHistories$;
        this.buttonLoading = false;
        this.onCloseDialog(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }
  
  private setFormData() {
    if( this.mode !== 'Edit' ) {
      return;
    }

    // Set member type
    this.sendSMSForm.patchValue({
      'member_type': this.data.member_type
    });

    this.localesGroupsLoaded$.subscribe(() => {
      // Set regions
      this.selectedRegions = this.regions.filter(item => this.data.currency_ids.includes(item.id));
      this.onRegionChanged(this.selectedRegions);
      this.sendSMSForm.patchValue({
        'settings_currency_ids': this.selectedRegions.map(item => item.id)
      });

      if( this.data.member_type == 1 ) {
        // Is member

        // Set member groups
        this.memberGroupSelectedItems = this.memberGroupDropdownList.filter(item => this.data.member_group_ids.includes(item.id));
        this.onSelectMemberGroup(this.memberGroupSelectedItems);
        this.sendSMSForm.patchValue({
          'member_group_ids': this.memberGroupSelectedItems.map(item => item.id)
        });

        // Set member
        if( this.data.attached_file !== null && this.data.attached_file !== '' ) {
          // From file upload
          this.attachedFile = {
            'url' : this.data.attached_file,
            'name' : this.data.attached_file_name
          };
          this.sendSMSForm.get('attached_file_url').setValue(this.attachedFile.url);
          
          this.smsMemberDropdownSettings = {
            ...this.smsMemberDropdownSettings,
            disabled: true
          };
          this.sendSMSForm.get('send_to_all_members').disable();
          this.sendSMSForm.controls.member_account_ids.clearValidators();
          this.sendSMSForm.get('member_account_ids').updateValueAndValidity();
          this.sendSMSForm.get('send_to_all_members')?.setValue(0);
        } else if( this.data.send_all === 1 ) {
          // Send all
          this.sendSMSForm.get('send_to_all_members').setValue(1);
          this.sendSMSForm.get('send_to_all_members').enable();
          this.sendSMSForm.controls.member_account_ids.clearValidators();
          this.sendSMSForm.get('member_account_ids').updateValueAndValidity();
        } else if( this.data.member_accounts.length > 0 ) {
          // From select members
          let member_account_ids = [];
          Object.keys(this.data.member_accounts).forEach((key) => {
            this.selectedMembers.push(this.data.member_accounts[key]);
            member_account_ids.push(this.data.member_accounts[key].id);
          });
          this.sendSMSForm.patchValue({
            member_account_ids: member_account_ids,
          });
          this.sendSMSForm.get('send_to_all_members').enable();
          this.sendSMSForm.controls.member_account_ids.setValidators([Validators.required]);
          this.sendSMSForm.get('member_account_ids').updateValueAndValidity();
        }
      } else {
        // Is non member 
        // From file upload
        if( this.data.attached_file !== null && this.data.attached_file !== '' ) {
          this.attachedFile = {
            'url' : this.data.attached_file,
            'name' : this.data.attached_file_name
          };
          this.sendSMSForm.get('attached_file_url').setValue(this.attachedFile.url);
          
          this.smsMemberDropdownSettings = {
            ...this.smsMemberDropdownSettings,
            disabled: true
          };
          this.sendSMSForm.controls.member_group_ids.clearValidators();
          this.sendSMSForm.controls.member_account_ids.clearValidators();
          this.sendSMSForm.get('member_account_ids').updateValueAndValidity();
          this.sendSMSForm.get('send_to_all_members')?.setValue(0);
          this.sendSMSForm.get('send_to_all_members').disable();
        }
      }

      // Set schedule date/time
      this.sendSMSForm.patchValue({
        'defaultDate': {
          'startDate': new Date(this.data.schedule_datetime),
          'endDate': new Date(this.data.schedule_datetime)
        },
        'start_datetime': new Date(this.data.schedule_datetime),
        'end_datetime': new Date(this.data.schedule_datetime),
      });

      // Set provider
      this.providerLoaded$.subscribe(() => {
        if( this.data.sms_provider_detail_setting ) {
          Object.keys(this.data.sms_provider_detail_setting).forEach((currency_id) => {
            this.onProviderSelect(this.data.sms_provider_detail_setting[currency_id], +currency_id);
          });
        }
      });
      
      this.cdr.detectChanges();
    });

    // Set message template
    this.messageTemplateLoaded$.subscribe(() => {
      if( this.data.message_template_id !== null && this.data.section !== null ) {
        this.selectedMessageTemplateSection = this.messageTemplateSectionDropdownList.filter(item => +item.section === this.data.section);
        this.onMessageTemplateSectionChanged(this.selectedMessageTemplateSection);
        
        this.selectedMessageTemplate = this.messageTemplateDropdownList.filter(item => +item.id === this.data.message_template_id);
        this.onMessageTemplateChanged(this.selectedMessageTemplate);

        this.cdr.detectChanges();
      } else if( this.data.details !== null ) {
        Object.keys(this.data.details).forEach(localeId => {
          const detailsGroup = this.sendSMSForm.get(`details.${localeId}`);
          if (detailsGroup) {
            detailsGroup.patchValue({
              subject: this.data.details[localeId].subject,
              message: this.data.details[localeId].message,
            });
          }
        });
      }

    });
  }

  setProviders() {
    this.dropdown.smsProvider.subscribe((res) => {
      this.smsProviders = res;
      this.providerLoaded$.next();
      this.providerLoaded$.complete();
    });
  }
  
}
