import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Status } from '@core/enums/status.enum';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionCodeDataService } from '@views/pages/apps/general/promotion-codes/services/promotion-code-data.service';
import { catchError, tap, map } from 'rxjs/operators';
import { VipSettingsDataService } from '../services/vip-settings-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { forkJoin, Subscription } from 'rxjs';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { VIPNameDialogComponent } from './vip-name-dialog/vip-name-dialog.component';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
import * as moment from 'moment-timezone';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DatePipe } from '@angular/common';

type MemberGroupDropdownListItem = {
    id: number;
    code: string;
    name: string;
    trial: number;
}

type NextMemberGroupDropdownListItem = {
    id: number;
    code: string;
    name: string;
    trial: number;
}

@Component({
    templateUrl: './vip-settings-edit.component.html',
    styleUrls: ['./vip-settings-edit.component.scss']
})
export class VipSettingsEditDialogComponent implements OnInit, OnDestroy {
    @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

    form: FormGroup;

    dropdown = {
        currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
        promotionCode: [],
        groups: this.dropdownHttpService.groups,
        statuses: Status,
        upgradeRequirementType: this.dropdownHttpService.upgradeRequirementType,
        smstemplates: this.messageTemplateDataService.getMessageTemplateList(2, 7), // Get sms templates with type = 2 (SMS) and section = 7 (VIPs)
        messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1, 7), // Get message templates with type = 1 (Message) and section = 7 (VIPs)
        locales$: this.vipSettingsDataService.locales$,
        locales: [],
        popupdialogs: this.dialogDataService.getWithQuery(`?paginate=false`),
        annualMaintenanceRequirementType: this.dropdownHttpService.annualMaintenanceRequirementType,
        downgradeNotificationFrequencyType: this.dropdownHttpService.downgradeNotificationFrequencyType,
    };

    memberGroupDropdownList: Array<MemberGroupDropdownListItem> = [];
    nextMemberGroupDropdownList: Array<NextMemberGroupDropdownListItem> = [];
    memberGroupDropdownSettings = {
        text: 'Please Select',
        enableSearchFilter: true,
        singleSelection: true,
        classes: 'dropdown',
        primaryKey: 'id',
        labelKey: 'name',
        noDataLabel: '',
        showCheckbox: false
    };
    memberGroupSelectedItems = [];
    nextMemberGroupSelectedItems = [];
    promotionCodeDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'name',
        noDataLabel: '',
        showCheckbox: false
    };
    promotionCodeDropdownList = [];
    upgradeBonusSelectedItems = [];
    birthdayBonusSelectedItems = []

    messages$ = this.vipSettingsDataService.messages$;
    buttonLoading = false;
    vipSettings = [];

    // permissions
    canViewPromotionCodeList: boolean;
    canViewDialogList: boolean;

    messageTemplatesSettings = {};
    messageTemplateList = [];
    messageTemplateSelectedItem = [];
    smsTemplateList = [];
    smsTemplateSelectedItem = [];

    isDisabledVipName = false;
    popupDropdownSettings = {};
    popupDropdownList = [];
    popupDropdownListArray = [];
    popupSelectedItems = [];

    frequencyWeekdays = this.buildFrequencyWeeks();
    frequencyMonths = this.buildFrequencyMonths();
    
    dateTimeStack = {
        dateStart: null
    };
    timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    clientOffset = moment().utcOffset() * 60 * 1000;
    offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    dateTimeFormat = 'yyyy-MM-dd HH:mm:';
    formattedDate: string = '';

    private subscriptions = new Subscription();
    private datePickerSubscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { vipSettings: any, mode: string },
        public dialogRef: MatDialogRef<VipSettingsEditDialogComponent>,
        private dropdownHttpService: DropdownHttpService,
        private vipSettingsDataService: VipSettingsDataService,
        private promotionCodeDataService: PromotionCodeDataService,
        private appPermissionService: AppPermissionService,
        private messageTemplateDataService: MessageTemplateDataService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private dialogDataService: DialogDataService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.dropdown.locales$.subscribe(locales => {
          this.dropdown.locales = locales;
        });

        if(this.dropdown.currencies.length === 0){
            this.dropdownHttpService.currencies.subscribe( res => {
              this.dropdown.currencies = res;
            });
        }
        this.formInit();
        this.setPopups();
        this.setMessagesDropdown();

        this.dropdown.groups.subscribe(async res => {
            const data = []
            res.forEach(row => {
                data.push({
                    id: row.id,
                    code: row.code,
                    name: row.name,
                    trial: row.trial,
                    vip: row.vip,
                    status: row.status
                })
            });
            this.memberGroupDropdownList = data.filter(x => x.vip == 1);
            if (this.data.mode == 'edit') {
                let selectedGroup = this.memberGroupDropdownList.find(x => x.id === this.data.vipSettings.member_group_id);
                if (selectedGroup) {
                    await this.checkMemberAvailableAdjustment(this.data.vipSettings.currency_id, true);

                    let selectedNextGroup = this.nextMemberGroupDropdownList.find(x => x.id === this.data.vipSettings.next_vip_upgrade_id);
                    this.nextMemberGroupSelectedItems = selectedNextGroup ? [selectedNextGroup] : [];

                    this.form.patchValue({
                        next_vip_upgrade_id: this.nextMemberGroupSelectedItems.length > 0 ? this.data.vipSettings.next_vip_upgrade_id : null,
                    })
                }
                
                this.form.patchValue({
                    upgrade_promotion_id: this.data.vipSettings.upgrade_promotion_id,
                    birthday_promotion_id: this.data.vipSettings.birthday_promotion_id,
                })

                this.promotionCodeDataService.getWithQuery(`?paginate=false&vip=1&currency_id=${this.form.value.currency_id}`).subscribe(res => {
                    this.dropdown.promotionCode = res;
                    this.promotionCodeDropdownList = res;
                    this.promotionCodeDropdownList.map(function (elm) {
                        elm['id'] = elm.id;
                        elm['name'] = elm.code + ' - ' + elm.name;
                        elm['status'] = elm.status;
                        elm['valid_from'] = elm.valid_from;
                        elm['valid_to'] = elm.valid_to;
                    });
    
                
                    if (this.data.vipSettings.upgrade_promotion_id !== 0) {
                        this.upgradeBonusSelectedItems.push(this.promotionCodeDropdownList.find(x => x.id == this.data.vipSettings.upgrade_promotion_id));
                    };
                    if (this.data.vipSettings.birthday_promotion_id !== 0) {
                        this.birthdayBonusSelectedItems.push(this.promotionCodeDropdownList.find(x => x.id == this.data.vipSettings.birthday_promotion_id));
                    }
                });
            }
        });

        const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
            this.canViewPromotionCodeList = appPermissions.view_promotion_codes_list;
            this.canViewDialogList = appPermissions.view_dialog_list;
        });

        this.subscriptions.add(apSub);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.datePickerSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.datePickerSubscription = forkJoin([
          this.buildDatePicker(0, 'notification_Yearly'),
        ]).subscribe();
      }

    onCloseDialog(event?: Event, refresh = false) {
        this.dialogRef.close(refresh);
    }

    onSave() {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });
        let data = {
            ...this.form.value,
            ftd: this.form.value.ftd ? 1 : 0,
            auto_approve: this.form.value.auto_approve ? 1 : 0,
            upgrade_promotion_id: this.data.mode === 'edit' && this.form.value.upgrade_promotion_id === null ? 0 : this.form.value.upgrade_promotion_id,
            birthday_promotion_id: this.data.mode === 'edit' && this.form.value.birthday_promotion_id === null ? 0 : this.form.value.birthday_promotion_id,
            annual_maintenance_requirement: this.form.value.annual_maintenance_requirement_type == 1 ? this.form.value.annual_maintenance_requirement_annual_deposit : this.form.value.annual_maintenance_requirement_type == 2 ? this.form.value.annual_maintenance_requirement_flat_deposit : null,
        };

        // Set downgrade notification frequency based on type
        if (this.form.value.downgrade_notification_frequency_type == 2) {
            data.downgrade_notification_frequency = this.form.value.notification_Weekly;
        } else if (this.form.value.downgrade_notification_frequency_type == 3) {
            data.downgrade_notification_frequency = this.form.value.notification_Monthly;
        } else if (this.form.value.downgrade_notification_frequency_type == 4) {
            data.downgrade_notification_frequency_yearly_date = this.form.value.notification_Yearly;
        }
        
        Object.keys(data).forEach((key) => ((data[key] === null || data[key] === '' || key === 'annual_maintenance_requirement_flat_deposit') && key !== 'message_template_id' && key !== 'sms_template_id' && key !== 'next_vip_upgrade_id' && key !== 'popup_id' && key !== 'annual_maintenance_requirement') && delete data[key]);
        switch (this.data.mode) {
            case 'edit':
                this.vipSettingsDataService.update(this.data.vipSettings.id, data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
            case 'create':
                this.vipSettingsDataService.add(data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
        }
    }

    onFTDTargetAmount(type: string) {
        if (type === 'ftd') {
            this.form.patchValue({ upgrade_requirement: null });
        } else {
            if (this.form.value.upgrade_requirement) {
                this.form.patchValue({ ftd: 0 });
            }
        }

        this.form.controls.ftd.clearValidators();
        this.form.controls.upgrade_requirement.setValidators([Validators.required, Validators.min(1)]);

        if (this.form.value.ftd) {
            this.form.controls.ftd.setValidators(Validators.required);
            this.form.controls.upgrade_requirement.clearValidators();
        }

        if (this.form.value.trial) {
            this.form.controls.upgrade_requirement.clearValidators();
        }

        this.updateFormValidity();
    }

    onChangeUpgradeRequirementType(value: any) {
        if (value == 1) {
            this.form.controls.ftd.clearValidators();
            this.form.controls.upgrade_requirement.clearValidators();
        }
        else {
            this.form.controls.upgrade_requirement.setValidators([Validators.required, Validators.min(1)]);
        }

        if (value == 2) {
            this.form.patchValue({
                ftd: 1
            })
        }
        else {
            this.form.patchValue({
                ftd: 0
            })
        }

        this.updateAnnualMaintenanceDropdown(value);
        this.updateFormValidity();
    }

    onChangeMemberGroup(value: any){
        this.isDisabledVipName = true;
        if (value.length > 0) {
            this.form.patchValue({
                member_group_id: value[0].id,
            })

            this.checkMemberAvailableAdjustment(this.form.value.currency_id, true);

            this.vipSettingsDataService.getVIPNameLocales(value[0].id).subscribe(res => {
                let vipNameList = res['vip_name'];
                if (this.data.mode == 'edit') {
                    this.data.vipSettings.vip_name = res['vip_name'];
                }

                this.form.removeControl('vip_names');
                this.form.addControl('vip_names', new FormGroup(this.buildVIPName(vipNameList)));
                this.isDisabledVipName = false;
                this.updateFormValidity();
            });
        }
        else {
            this.form.patchValue({
                member_group_id: null,
                next_vip_upgrade_id: null,
                vip_names: []
            })
            this.form.removeControl('vip_names');
            this.nextMemberGroupDropdownList = [];
            this.nextMemberGroupSelectedItems = [];
            this.updateFormValidity();
        }
        this.cdr.detectChanges();
    }

    setPopups() {
        this.popupDropdownSettings = {
          autoPosition: false,
          singleSelection: true,
          text: 'Please Select',
          enableFilterSelectAll: false,
          enableSearchFilter: true,
          classes: 'dropdown',
          maxHeight: 200, //'auto',
          primaryKey: 'id',
          labelKey: 'labelKey',
          noDataLabel: '',
          showCheckbox: false
        };

        // Dropdown list for dialog
        this.dropdown.popupdialogs.subscribe(res => {
            let contents = [];
            res.map(function (elm) {
                elm.contents.forEach(v => contents.push(v));
    
                let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
                localeTitle = localeTitle.sort(function(a, b) { 
                return a.locale_id - b.locale_id;
                });
    
                if (localeTitle.length > 0) {
                let title = (localeTitle[0]['title'].length > 25) ? localeTitle[0]['title'].slice(0, 25 - 1) + ' . . . ' : localeTitle[0]['title'];
                elm['labelKey'] = elm.code + ' (' + title + ')';
                }
                else {
                elm['labelKey'] = elm.code;
                }
            });
            this.popupDropdownList = this.popupDropdownList.concat(res);
            this.popupDropdownList = this.popupDropdownList.map((x) => {
                return {
                id: x.id,
                start_date: x.start_date,
                end_date: x.end_date,
                labelKey: x.labelKey,
                code: x.code,
                status: x.status
                }
            });
            
            this.popupDropdownListArray = this.popupDropdownList;

            if (this.data.mode == 'edit') {
              this.popupSelectedItems = this.popupDropdownListArray.filter(x => x.id == this.data.vipSettings.popup_id);
              this.form.patchValue({
                popup_id: this.data.vipSettings.popup_id
              })
              this.cdr.detectChanges();
            }
          });
    }

    // onSelectedPopupItems($event: any) {
    //     if ($event.length == 0) {
    //     this.popupSelectedItems = [];
    //     }
    //     else {
    //     this.popupSelectedItems = [$event[0]];
    //     }
    // }

    private updateFormValidity() {
        Object.keys(this.form.controls).forEach((key) => {
            if (key === 'upgrade_requirement' || key === 'ftd' || key === 'auto_approve' || key === 'notification_Weekly' || key === 'notification_Monthly' || key === 'notification_Yearly' || key === 'annual_maintenance_requirement_annual_deposit' || key === 'annual_maintenance_requirement_flat_deposit') {
                this.form.get(key).updateValueAndValidity();
            }
        });
    }

    private formInit() {
        let name = null;
        let status = 1;
        let currency_id = null;
        let member_group_id = null;
        let upgrade_requirement = null;
        let upgrade_requirement_type_id = null;
        let next_vip_upgrade_id = null;
        let upgrade_promotion_id = null;
        let birthday_promotion_id = null;
        let days = null;
        let service_priority = null;
        let lc_rebate = null;
        let sl_rebate = null;
        let sp_rebate = null;
        let reward_bonus = null;
        let birth_rebate = null;
        let ftd = 0;
        let auto_approve = 0;
        let trial = 0;
        let message_template_id = null;
        let sms_template_id = null;
        let vip_names = null;
        let popupId = null;
        let annual_maintenance_requirement_type = null;
        let annual_maintenance_requirement_annual_deposit = null;
        let annual_maintenance_requirement_flat_deposit = null;
        let downgrade_notification_frequency_type = null;
        let notification_Weekly = null;
        let notification_Monthly = null;
        let notification_Yearly = null;

        if (this.data.mode === 'edit') {
            name = this.data.vipSettings.name;
            status = this.data.vipSettings.status;
            currency_id = this.data.vipSettings.settings_currency_id;
            upgrade_requirement_type_id = this.data.vipSettings.upgrade_requirement_type_id;
            member_group_id = this.data.vipSettings.member_group_id;
            next_vip_upgrade_id = this.data.vipSettings.next_vip_upgrade_id;
            upgrade_requirement = this.data.vipSettings.upgrade_requirement;
            upgrade_promotion_id = this.data.vipSettings.upgrade_promotion_id > 0 ? this.data.vipSettings.upgrade_promotion_id : null;
            birthday_promotion_id = this.data.vipSettings.birthday_promotion_id > 0 ? this.data.vipSettings.birthday_promotion_id : null;
            days = this.data.vipSettings.days;
            service_priority = this.data.vipSettings.service_priority;
            lc_rebate = this.data.vipSettings.lc_rebate;
            sl_rebate = this.data.vipSettings.sl_rebate;
            sp_rebate = this.data.vipSettings.sp_rebate;
            reward_bonus = this.data.vipSettings.reward_bonus;
            birth_rebate = this.data.vipSettings.birth_rebate;
            ftd = this.data.vipSettings.ftd;
            auto_approve = this.data.vipSettings.auto_approve;
            trial = this.data.vipSettings.trial;
            message_template_id = this.data.vipSettings.message_template_id;
            sms_template_id = this.data.vipSettings.sms_template_id;
            vip_names = this.data.vipSettings.vip_names;
            popupId = this.data.vipSettings.popup_id;
            annual_maintenance_requirement_type = this.data.vipSettings.annual_maintenance_requirement_type;
            if (annual_maintenance_requirement_type == 1) {
                annual_maintenance_requirement_annual_deposit = this.data.vipSettings.annual_maintenance_requirement;
            } else if (annual_maintenance_requirement_type == 2) {
                annual_maintenance_requirement_flat_deposit = parseInt(this.data.vipSettings.annual_maintenance_requirement);
            }
            downgrade_notification_frequency_type = this.data.vipSettings.downgrade_notification_frequency_type;
            if (downgrade_notification_frequency_type == 2) {
                notification_Weekly = this.data.vipSettings.downgrade_notification_frequency;
            } else if (downgrade_notification_frequency_type == 3) {
                notification_Monthly = this.data.vipSettings.downgrade_notification_frequency;
            } else if (downgrade_notification_frequency_type == 4) {
                notification_Yearly = this.data.vipSettings.downgrade_notification_frequency_yearly_date;
                this.dateTimeStack = {
                    dateStart: this.data.vipSettings.downgrade_notification_frequency_yearly_date !== null ? new Date(new Date(this.data.vipSettings.downgrade_notification_frequency_yearly_date).getTime() + this.offset - this.clientOffset) : null,
                };
                this.updateFormattedDate(notification_Yearly);
            }

            var interval = setInterval(() => {
                if (this.memberGroupDropdownList.length > 0) {
                    let selectedGroup = this.memberGroupDropdownList.find(x => x.id === member_group_id);
                    this.memberGroupSelectedItems = selectedGroup ? [selectedGroup] : [];

                    clearInterval(interval);
                }
            }, 500);
        }

        this.form = new FormGroup({
            name: new FormControl(name, [Validators.required]),                                     // STRING
            status: new FormControl(status, [Validators.required]),                                 // NUMBER
            currency_id: new FormControl(currency_id, [Validators.required]),                       // ID
            member_group_id: new FormControl(member_group_id, [Validators.required]),               // ID
            upgrade_requirement: new FormControl(upgrade_requirement),                              // NUMBER
            upgrade_requirement_type_id: new FormControl(upgrade_requirement_type_id, [Validators.required]),
            next_vip_upgrade_id: new FormControl(next_vip_upgrade_id),
            message_template_id: new FormControl(message_template_id),
            sms_template_id: new FormControl(sms_template_id),
            upgrade_promotion_id: new FormControl(upgrade_promotion_id),                            // ID
            birthday_promotion_id: new FormControl(birthday_promotion_id),                          // ID
            days: new FormControl(days, [Validators.required]),                                     // NUMBER
            service_priority: new FormControl(service_priority, [Validators.required]),             // STRING
            lc_rebate: new FormControl(lc_rebate, [Validators.required]),                           // NUMBER
            sl_rebate: new FormControl(sl_rebate, [Validators.required]),                           // NUMBER
            sp_rebate: new FormControl(sp_rebate, [Validators.required]),                           // NUMBER
            reward_bonus: new FormControl(reward_bonus, [Validators.required]),                     // NUMBER
            birth_rebate: new FormControl(birth_rebate, [Validators.required]),                     // NUMBER
            ftd: new FormControl(ftd, [Validators.required]),           
            auto_approve: new FormControl(auto_approve, [Validators.required]),                     
            type: new FormControl(1, [Validators.required]),                                        // NUMBER *NOTE: THIS FIELD IS PERMANENT, NOT CHANGEABLE*
            trial: new FormControl(trial, [Validators.required]),                                   // NUMBER, this field is used to validate target amount only, not updated to database
            vip_names: new FormGroup(this.buildVIPName()),
            popup_id: new FormControl(null),
            annual_maintenance_requirement_type: new FormControl(annual_maintenance_requirement_type, [Validators.required]),
            annual_maintenance_requirement_annual_deposit: new FormControl(annual_maintenance_requirement_annual_deposit),
            annual_maintenance_requirement_flat_deposit: new FormControl(annual_maintenance_requirement_flat_deposit),
            downgrade_notification_frequency_type: new FormControl(downgrade_notification_frequency_type),
            notification_Weekly: new FormControl(notification_Weekly),
            notification_Monthly: new FormControl(notification_Monthly),
            notification_Yearly: new FormControl(notification_Yearly)
        });

        this.form.get('member_group_id').valueChanges.subscribe(val => {
            const memberGroup = this.memberGroupDropdownList.find(l => l.id === val);
            this.memberGroupSelectedItems = memberGroup ? [memberGroup] : [];

            if (!memberGroup?.trial && this.form.value.upgrade_requirement_type_id != 1) {
                this.form.controls.upgrade_requirement.setValidators([Validators.required, Validators.min(1)]);
            } else {
                this.form.controls.upgrade_requirement.clearValidators();
            }

            this.form.get('upgrade_requirement').updateValueAndValidity();

            // update trial form value
            this.form.patchValue({
                trial: memberGroup?.trial
            });
        });

        this.form.get('upgrade_requirement_type_id').valueChanges.subscribe(val => {
            if (val == 1) {
                this.form.patchValue({
                    upgrade_requirement: '0.00'
                });
            }
        });

        if (this.data.mode === 'edit') {
            if (this.form.value.ftd) {
                this.form.controls.ftd.setValidators(Validators.required);
                this.form.controls.upgrade_requirement.clearValidators();
            } else {
                this.form.controls.ftd.setValidators(Validators.required);

                // target amount is optional if member group is trial
                if (this.form.value.trial == 0) {
                    this.form.controls.upgrade_requirement.setValidators([Validators.required, Validators.min(1)]);
                }
            }

            if (this.data.vipSettings.upgrade_requirement_type_id == 1) {
                this.form.controls.ftd.clearValidators();
                this.form.controls.upgrade_requirement.clearValidators();
            }
            else {
                this.form.controls.upgrade_requirement.setValidators([Validators.required, Validators.min(1)]);
            }
    
            this.updateAnnualMaintenanceDropdown(this.data.vipSettings.upgrade_requirement_type_id);
            this.updateFormValidity();
        } 

        this.onChangeNotification();
        this.onChangeMaintenanceRequirementType();
    }

    private setMessagesDropdown() {
        // Message Templates Dropdown Settings
        this.messageTemplatesSettings = {
          maxHeight: 200,
          singleSelection: true,
          text: 'Please Select',
          enableFilterSelectAll: false,
          enableSearchFilter: true,
          classes: 'dropdown',
          primaryKey: 'id',
          labelKey: 'code',
          showCheckbox: false
        };
    
        // Dropdown list for SMS templates
        this.dropdown.smstemplates.subscribe(res => {
          this.smsTemplateList = res;
          if (this.data.mode == 'edit') {
            this.smsTemplateSelectedItem = this.smsTemplateList.filter(x => x.id == this.data.vipSettings.sms_template_id);
            this.cdr.detectChanges();
          }
        });
        
        // Dropdown list for Message templates
        this.dropdown.messagetemplates.subscribe(res => {
          this.messageTemplateList = res;
          if (this.data.mode == 'edit') {
            this.messageTemplateSelectedItem = this.messageTemplateList.filter(x => x.id == this.data.vipSettings.message_template_id);
            this.cdr.detectChanges();
          }
        });
        
    }

    checkMemberAvailableAdjustment(currency_id: any, reset = false) {
        return new Promise<void>((resolve, reject) => {
            if (reset) {
                this.vipSettings = [];
                this.nextMemberGroupDropdownList = [];
                this.form.patchValue({
                    next_vip_upgrade_id: null
                })
                this.nextMemberGroupSelectedItems = [];
            }
            
            if (currency_id != undefined) {
                this.form.patchValue({
                    upgrade_promotion_id: null,
                    birthday_promotion_id: null
                })

                this.birthdayBonusSelectedItems = [];
                this.upgradeBonusSelectedItems = [];

                if (!reset) {
                    this.promotionCodeDataService.getWithQuery(`?paginate=false&vip=1&currency_id=${this.form.value.currency_id}`).subscribe(res => {
                        this.dropdown.promotionCode = res;
                        this.promotionCodeDropdownList = res;
                        this.promotionCodeDropdownList.map(function (elm) {
                            elm['id'] = elm.id;
                            elm['name'] = elm.code + ' - ' + elm.name;
                            elm['status'] = elm.status;
                            elm['valid_from'] = elm.valid_from;
                            elm['valid_to'] = elm.valid_to;
                        });
                    });
                }

                if (this.form.value.member_group_id != null) {
                    this.vipSettingsDataService.getWithQuery(`?paginate=0&status=1&currency_id=${currency_id}`).subscribe(res => {
                        this.vipSettings = res;
                        let filteredVipSettings = [];
                        if (this.data.mode == 'edit') {
                            filteredVipSettings = this.vipSettings.filter(x => x.id != this.data.vipSettings.id);
                        }
                        else {
                            filteredVipSettings = Object.assign([], this.vipSettings);
                        }

                        let vipSettingsIds =  Array.from(new Set(filteredVipSettings.map((item: any) => item.member_group_id))) ?? [];
                        let searchLevelId = Array.from(new Set(filteredVipSettings.filter(x => x.next_vip_upgrade_id == this.form.value.member_group_id).map((item: any) => item.member_group_id))) ?? [];
                        let downgradeIds = searchLevelId.length ? searchLevelId : [];

                        while(searchLevelId.length > 0) {
                            let searchId = Array.from(new Set(filteredVipSettings.filter(x => searchLevelId.includes(x.next_vip_upgrade_id) && x.status == 1).map((item: any) => item.member_group_id))) ?? [];
                            if (searchId.length > 0) {
                                searchLevelId = Object.assign([], searchId);
                                downgradeIds = Array.from(new Set(downgradeIds.concat(searchId))).filter(function(el) { return el; });
                            }
                            else {
                                searchLevelId = [];
                            }
                        } 
                        this.nextMemberGroupDropdownList = this.memberGroupDropdownList.filter(x => !downgradeIds.includes(x.id) && x.id != this.form.value.member_group_id);
                        this.nextMemberGroupDropdownList = this.nextMemberGroupDropdownList.filter(x => vipSettingsIds.includes(x.id));
                        resolve();
                    });
                }
                else {
                    resolve();
                } 
            }
            else {
                this.form.patchValue({
                    upgrade_promotion_id: null,
                    birthday_promotion_id: null
                })

                this.birthdayBonusSelectedItems = [];
                this.upgradeBonusSelectedItems = [];
                resolve();
            }
        });
    }

    checkValidity(data: any, type: any) {
        switch (type) {
            case 1: // upgrade bonus or birthday bonus
                if (data != undefined && data.length > 0) {
                    if (data[0]['status'] == 0) {
                        return false;
                    }
                    else {
                        var today = new Date();
                        var start_date = new Date(data[0]['valid_from']);
    
                        if (data[0]['valid_to'] != null) {
                            var end_date = new Date(data[0]['valid_to']);
                            if (today > end_date || today < start_date) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                        else if (today < start_date) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                }
                else {
                    return true;
                }
            case 2: // message or sms message
                if (data != undefined && data.length > 0) {
                    if (data[0]['status'] == 0) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            case 3: // dialog popup
                if (data != undefined && data.length > 0) {
                    if (data[0]['status'] == 0) {
                        return false;
                    }
                    else {
                        var today = new Date();
                        var start_date = new Date(data[0]['start_date']);
    
                        if (data[0]['end_date'] != null) {
                            var end_date = new Date(data[0]['end_date']);
                            if (today > end_date || today < start_date) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                        else if (today < start_date) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                }
                else {
                    return true;
                }
        }

    }

    checkAllValidity() {
        if (!this.checkValidity(this.upgradeBonusSelectedItems, 1) ||
            !this.checkValidity(this.birthdayBonusSelectedItems, 1) ||
            !this.checkValidity(this.smsTemplateSelectedItem, 2) ||
            !this.checkValidity(this.messageTemplateSelectedItem, 2) ||
            !this.checkValidity(this.popupSelectedItems, 3)
            ) {
            return false;
        }
        else {
            return true;
        }
    }

    onOpenDialog(dialog: string) {
        this.openDialogBy(VIPNameDialogComponent, { vip_names: this.form.value.vip_names});
    }
    
    private openDialogBy(componentRef: any, data?: { vip_names: any }) {
        const dialogRef = this.dialog.open(componentRef, {
            width: '800px',
            data: {
            vip_names: data.vip_names
            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
            this.form.patchValue({
                vip_names: result
            });
            }
        });
    }

    buildVIPName(vipNameList?: any) {
        let result = {};
        this.dropdown.locales.forEach((item, index) => {
            const vip_locale_name = this.data.vipSettings ? this.data.vipSettings?.vip_name.find(x => x.settings_locale_id === item.id)?.name : vipNameList?.find(x => x.settings_locale_id === item.id)?.name;
            const vipNameGroup = result[item.id] = new FormGroup({
                settings_locale_id: new FormControl(item.id),
                name: new FormControl(vip_locale_name || null),
            });
            result = { ...result, [item.id]: vipNameGroup };
        });
        return result;
    }

    private buildFrequencyWeeks() {
        return Object.keys(moment.weekdays()).map(el => {
            const id = (+el + 1);
            const name = String(moment.weekdays(id)).substring(0, 3);
            return { id, name };
          });
    }

    private buildFrequencyMonths() {
        const result = [];
        for (let i = 1; i <= +moment().endOf('month').format('DD'); i++) {
          result.push({ id: i, name: i });
        }
        result.push({ id: 99, name: 'Last day of the month' });
        return result;
    }

    private buildDatePicker(index: number, formKey: string) {
        return this.datePicker.toArray()[index].valueChange.pipe(
          map(res => this.datePipe.transform(res, formKey.includes('notification_Yearly') ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
          tap(date => {
            this.form.patchValue({ [formKey]: date });
          })
        );
    }

    updateAnnualMaintenanceDropdown(value: any) {
        // Only allow 'Multiplier of Annual Deposit' when upgrade requirement is 'Annual Deposit'
        if (value == 4) {
            this.dropdown.annualMaintenanceRequirementType = this.dropdownHttpService.annualMaintenanceRequirementType;
        } else {
            this.dropdown.annualMaintenanceRequirementType = this.dropdown.annualMaintenanceRequirementType.filter(x => x.id == 0 || x.id == 2);
        }

        // If annual maintenance requirement not eligible for upgrade requirement then set to null 
        if (!this.dropdown.annualMaintenanceRequirementType.some( item => item.id == this.form.get('annual_maintenance_requirement_type').value )) {
            this.form.patchValue({
                annual_maintenance_requirement_type: null
            });
        }
    }

    onChangeNotification() {
        let value = this.form.get('downgrade_notification_frequency_type').value;

        if (value == 0 || value == 1) {
            this.form.get('notification_Weekly').clearValidators();
            this.form.get('notification_Monthly').clearValidators();
            this.form.get('notification_Yearly').clearValidators();
        } else if (value == 2) {
            this.form.get('notification_Weekly').setValidators(Validators.required);
            this.form.get('notification_Monthly').clearValidators();
            this.form.get('notification_Yearly').clearValidators();
        } else if(value == 3) {
            this.form.get('notification_Weekly').clearValidators();
            this.form.get('notification_Monthly').setValidators(Validators.required);
            this.form.get('notification_Yearly').clearValidators();
        } else if(value == 4) {
            this.form.get('notification_Weekly').clearValidators();
            this.form.get('notification_Monthly').clearValidators();
            this.form.get('notification_Yearly').setValidators(Validators.required);
        }

        this.updateFormValidity();
    }

    onChangeMaintenanceRequirementType() {
        let value = this.form.get('annual_maintenance_requirement_type').value;

        if (value == 0) { // None
            this.form.get('annual_maintenance_requirement_annual_deposit').clearValidators();
            this.form.get('annual_maintenance_requirement_flat_deposit').clearValidators();
        } else if (value == 1) { // Multiplier of Annual Deposit
            this.form.get('annual_maintenance_requirement_annual_deposit').setValidators([Validators.required, Validators.pattern(/^\d*\.?\d{0,2}$/), Validators.min(0.01)]);
            this.form.get('annual_maintenance_requirement_flat_deposit').clearValidators();
        } else if (value == 2) { // Flat Deposit
            this.form.get('annual_maintenance_requirement_annual_deposit').clearValidators();
            this.form.get('annual_maintenance_requirement_flat_deposit').setValidators([Validators.required, Validators.pattern(/^\d+$/), Validators.min(1)]);
        }

        this.updateFormValidity();
    }

    onDateChange(event: any): void {
        const selectedDate = event.value;
        this.dateTimeStack.dateStart = selectedDate;
        this.updateFormattedDate(selectedDate);
    }

    private updateFormattedDate(date: Date | string): void {
        const fullDate = new Date(date);

        // Format the date as DD-MMM (e.g., 11-Nov)
        const day = fullDate.getDate();
        const month = fullDate.toLocaleString('default', { month: 'short' });
        this.formattedDate = `${day}-${month}`;
    }
}
