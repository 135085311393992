<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Promotion Currency</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="control-label">Currency <span class="text-danger">*</span></label>
              <select class="form-control"  formControlName="currency_id" placeholder="Please Select">
                <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                  {{ value.name }}
                </option>
              </select>
            </div>
            <ng-container *ngIf="data.promotionType === 3 && (data.promoSubType === 2 || data.promoSubType === 4); else elseTemplate">
              <div class="col-md-12 form-group">
                <label class="control-label">Min Reward<span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" formControlName="min_bonus" class="col-12 form-control"/>
              </div>
              <div class="col-md-12 form-group">
                <label class="control-label">Max Reward<span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" formControlName="max_bonus" class="col-12 form-control">
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="col-md-12 form-group">
                <label class="control-label">Max Total Applications <span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" formControlName="max_total_applications" class="col-12 form-control"/>
                <span class="form-text text-muted">Enter 0 for Unlimited</span>
              </div>
              <div class="col-md-12 form-group">
                <ng-container *ngIf="promotionType === 3 && data.promoSubType === 3; else totalAmount">
                  <label class="control-label"> Threshold <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="threshold" class="col-12 form-control">
                </ng-container>
                <ng-template #totalAmount>
                  <label class="control-label">Max Total Amount <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="max_total_bonus" class="col-12 form-control">
                  <span class="form-text text-muted">Enter 0 for Unlimited</span>
                </ng-template>
              </div>

              <!-- Free Spin type -->
              <ng-container *ngIf="promotionType === 4">
                <div class="col-md-12 form-group">
                  <label class="control-label">Coins <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="coins" class="col-12 form-control">
                </div>

                <div class="col-md-12 form-group">
                  <label class="control-label">Amount Per Line <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="amount_per_line" class="col-12 form-control">
                </div>

                <div class="col-md-12 form-group">
                  <label class="control-label">Lines <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="lines" class="col-12 form-control">
                </div>

                <div class="col-md-12 form-group">
                  <label class="control-label">Total Rounds <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="rounds" class="col-12 form-control">
                </div>
              </ng-container>
              <!-- End Free Spin Type -->

              <div class="col-md-12 form-group" *ngIf="(promotionType === 2 || promotionType === 1 || promotionType === 4)">
                <label class="control-label">Min Transfer <span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" formControlName="min_transfer" class="col-12 form-control">
              </div>

              <div class="col-md-12 form-group" *ngIf="promotionType === 2 || promotionType === 1">
                <label class="control-label">Max Bonus <span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" formControlName="max_bonus" class="col-12 form-control">
              </div>

              <div class="col-md-12 form-group" *ngIf="promotionType === 3 && data.promoSubType !== 3">
                <label class="control-label">Bonus Amount <span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" formControlName="free_credit_amount" class="col-12 form-control">
              </div>

            </ng-template>
            <div class="col-md-12 form-group" *ngIf="promotionType === 3">
              <label class="control-label">Max Balance Claim
                <span class="text-danger">*</span>
                <i class="fa fa-info-circle promotion-code-detail-tooltip" ></i>
                <div class="tooltip-box">
                  Max Balance Claim: <br>
                  1. If value entered for "Max Balance Claim" is "0": Player is able to claim the "Free Credit bonus" even though his/her main wallet is ≥ 0. <br>
                  2.  If value entered for "Max Balance Claim" is NOT "0": Player is able to claim "Free Credit bonus" only if the wallet balance not exceed "Max Balance Claim".
                </div>
              </label>
              <input type="number" (wheel)="false" formControlName="max_balance_claim" class="col-12 form-control">
              <span class="form-text text-muted">Enter 0 for Unlimited</span>
            </div>

            <div class="col-md-12 form-group">
              <label class="control-label">Max Transfer Out
                <span class="text-danger">*</span>
                <i class="fa fa-info-circle promotion-code-detail-tooltip"></i>
                <div class="tooltip-box">
                  The maximum amount a member can transfer out upon promotion unlock.  <br>
                  <b>This condition will be bypass if "Limit Provider Transfer Out" is OFF</b> <br>
                  <b>Recommended to enable "Unlock Upon Transfer Out" if this field is configured.</b>
                </div>
              </label>
              <input type="number" (wheel)="false" formControlName="max_transfer_out" class="col-12 form-control">
              <span class="form-text text-muted">Enter 0 for Unlimited</span>
            </div>

            <div class="col-md-12 form-group">
              <label class="control-label">Status <span class="text-danger">*</span></label>
              <select class="form-control" formControlName="status" placeholder="Please Select">
                <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index;">
                  {{ value }}
                </option>
              </select>
            </div>

          </div>
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.promotionCurrency, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
