<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        Promotion Turnover Details: <span class="p-l-0">{{ data.username }}</span> | {{ data.game_account  }} |<span>{{ data.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm (ddd)': currencyTimezone[data.currency] }} - {{ data.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm (ddd)': currencyTimezone[data.currency] }}</span>
      </h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive mb-2">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="text-center">Game Provider</th>
                  <th class="text-center">Category</th>
                  <td class="text-center">Sub Category</td>
                  <th class="text-center">Game Name</th>
                  <th class="text-center date-column sort-enabled" (click)="onSortColumn('settlement_time')" [ngClass]="sortingConfig.settlement_time" [class.sort-selected]="sortingSelection.sort_by === 'settlement_time'">Settlement Time</th>
                  <th class="text-center">W/L</th>
                  <th class="text-center">Promo Turnover</th>
                </tr>
              </thead>
              <tbody *ngIf="(memberPromoTurnover$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td class="text-center">{{ row.game_provider_name}}</td>
                  <td class="text-center">{{ row.game_category_code }}</td>
                  <td class="text-center">{{ row.game_sub_category }}</td>
                  <td>{{ row.game_name }}</td>
                  <td>{{ row.settlement_time | timezoneDate: 'YYYY-MM-DD HH:mm' : currencyTimezone[data.currency] }} </td>
                  <td class="text-right">{{ row.win_lose | number : '1.2' }}</td>
                  <td class="text-right">{{ row.turnover | number : '1.2'}}</td>
                </tr>
                <!-- If no record -->
                <!-- <kt-fallback-row [collection]="rows"></kt-fallback-row> -->
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
              <tfoot *ngIf="loading == false">
                <tr *ngIf="pagination !== undefined && pagination.last_page > 1">
                  <th colspan="5" class="text-right">Sub Total</th>
                  <th class="text-right">{{ subtotalWL| number : '1.2'}}</th>
                  <th class="text-right">{{ subtotalTurnover | number : '1.2'}}</th>
                </tr>
                <tr>
                  <th colspan="5" class="text-right">Total</th>
                  <th class="text-right">{{ totalWL | number : '1.2'}}</th>
                  <th class="text-right">{{ totalTurnover | number : '1.2'}}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                {{ 'Showing' | translate }} {{pagination.from}} {{ 'to' | translate }} {{pagination.to}} {{ 'of' | translate }} {{ pagination.total | number : '1.0' }} {{ 'records' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>