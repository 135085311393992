<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Affiliate Details' : 'Create Affiliate' }} </h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Username <span class="text-danger">*</span></label>
              <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" class="col-8 form-control">
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
              <select (change)="onSelectCurrency(form.value.currency_id)" formControlName="currency_id" class="col-8 form-control">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
              </select>
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Mobile <span class="text-danger">*</span></label>
              <input type="text" formControlName="mobile" class="col-8 form-control">
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Email</label>
              <input type="text" formControlName="email" class="col-8 form-control">
            </div>

            <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
              <label class="col-4 col-form-label">Remarks</label>
              <input type="text" formControlName="remarks" class="col-8 form-control">
            </div>

            <ng-container *ngIf="data.mode === 'create'">
              <div class="col-12 form-group row">
                <label class="col-4 col-form-label">Password <span class="text-danger">*</span></label>
                <input type="text" formControlName="password" class="col-8 form-control">
              </div>
            </ng-container>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Registration Postback Domain</label>
              <select class="col-2 form-control" formControlName="postback_method">
                <option value="1">{{ 'POST' | translate }}</option>
                <option value="2">{{ 'GET' | translate }}</option>
              </select>
              <input type="text" placeholder="https://www.website.com" [ngClass]="{ 'is-invalid': form.controls.postback_domain.errors }" formControlName="postback_domain" class="col-6 form-control" #registration>
              <div class="mt-3 w-100 d-flex justify-content-between">
                <div class="row">
                  <label class="usable-variables-label col-4">Usable variables: </label>
                  <div class="col-7 pl-2">
                    <ng-container *ngFor="let variable of affiliatePostbackVariables">
                      <button class="usable-variables-text" (click)="onSelectUsableVariable(variable.label, 'postback_domain', registration)" *ngIf="variable.type === 'All' || variable.type === 1">
                        {{ variable.label }}
                      </button>
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="data.mode === 'edit' && canAffiliatePostbackTestFire">
                  <button [disabled]="registration.value.length == 0" type="button" class="btn btn-dark" (click)="postbackTest('registration', form.controls.postback_domain.value)"><i class="fas fa-magic"></i>Test</button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
              <input type="text" formControlName="name" class="col-8 form-control">
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Group <span class="text-danger">*</span></label>
              <select formControlName="affiliate_group_id" class="col-8 form-control">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option  *ngFor="let value of dropdown.groups" [value]="value.id"> {{ value.name }} </option>
              </select>
              <i *ngIf="dropdownLoading && form.value.currency_id !== null" class="fas fa-circle-notch fa-spin p-0 mr-2 dropdown-loading"></i>
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Parent</label>
              <div class="col-8 px-0">
                <kt-dropdown-wo-lazyload
                  [form] = 'form'
                  [dropdownList] = 'affiliatesDropdownList'
                  [dropdownSettings] = 'affiliatesDropdownSettings'
                  [formName] = "'upline_id'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'affiliatesSelectedItems'
                  [dataLoading]="affiliateLoading">
                </kt-dropdown-wo-lazyload>
              </div>
            </div>

            <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
              <label class="col-4 col-form-label">Status</label>
              <select class="form-control col-8" formControlName="status">
                <option *ngFor="let item of dropdown.statuses" [value]="item.value">{{ item.name }}</option>
              </select>
            </div>

            <div class="col-12 form-group row" style="height: 10px;">
            </div>

            <div class="col-12 form-group row">
            </div>

            <div class="col-12 form-group row" *ngIf="data.mode === 'create'">
              <label class="col-4">Confirm Password<span class="text-danger">*</span></label>
              <input type="text" formControlName="confirm_password" class="col-8 form-control">
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">FTD Postback Domain</label>
              <select class="col-2 form-control" formControlName="ftd_postback_method">
                <option value="1">{{ 'POST' | translate }}</option>
                <option value="2">{{ 'GET' | translate }}</option>
              </select>
              <input type="text" placeholder="https://www.website.com" [ngClass]="{ 'is-invalid': form.controls.ftd_postback_domain.errors }" formControlName="ftd_postback_domain" class="col-6 form-control" #ftd>
              <div class="mt-3 w-100 d-flex justify-content-between">
                <div class="row">
                  <label class="usable-variables-label col-4">Usable variables: </label>
                  <div class="col-7 pl-2">
                    <ng-container *ngFor="let variable of affiliatePostbackVariables">
                      <button class="usable-variables-text" (click)="onSelectUsableVariable(variable.label, 'ftd_postback_domain', ftd)" *ngIf="variable.type === 'All' || variable.type === 2">
                        {{ variable.label }}
                      </button>
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="data.mode === 'edit' && canAffiliatePostbackTestFire">
                  <button [disabled]="ftd.value.length == 0" type="button" class="btn btn-dark" (click)="postbackTest('ftd', form.controls.ftd_postback_domain.value)"><i class="fas fa-magic"></i>Test</button>
                </div>
              </div>
            </div>

          </div>
        </div>

        <ng-container *ngIf="data.affiliate && data.mode === 'edit'">
          <hr>
          <div class="row">
            <div class="col-md-6">
              <div class="col-12 form-group row">
                <label class="col-4">Register date: </label>
                <span class="col-8 mt-10"> {{ data.affiliate.register_date ? (data.affiliate.register_date | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }} </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-12 form-group row">
               <label class="col-4">Registration IP: </label>
               <span class="col-8 mt-10"> {{ data.affiliate.registration_ip ? data.affiliate.registration_ip : '-' }} </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSubmit(data.mode)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>
