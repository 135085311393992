<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right mb-2">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Promo Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="promotion_code" placeholder='Search' class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Promo Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="promo_type" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.promoTypes | async">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control ">
                                    <div class="input-group date">
                                        <div class="input-group-prepend">
                                            <select formControlName="date_type" class="form-control">
                                                <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Dummy Account:<i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="dummyToolTip" tooltipClass="tooltip-class"></i></label>
                                </div>  
                                <div class="col-md-2 kt-form__control">
                                    <label class="switch mr-3" style="align-self: center;">
                                        <input type="checkbox" formControlName="dummy" [checked]="form.value.dummy == 1" (change)="onChangeDummy($event)">
                                        <div class="slider round">
                                            <span class="on">INC</span>
                                            <span class="off">EXC</span>
                                        </div>
                                    </label>
                                </div> 
                                <ng-template #dummyToolTip>
                                    <div class="text-left">
                                        INC (Include): Include dummy accounts.<br>EXC (Exclude): Exclude dummy accounts.
                                    </div>
                                </ng-template>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateCampaign" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('bonus_type')" [ngClass]="sortingConfig.bonus_type" [class.sort-selected]="sortingSelection.sort_by === 'bonus_type'">Promotion Type</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('promotion_code')" [ngClass]="sortingConfig.promotion_code" [class.sort-selected]="sortingSelection.sort_by === 'promotion_code'">Promotion Code</th>
                                <th class="text-center">Dialog Code (Popup Title)</th>
                                <th class="text-center">Auto Assign</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('code')" [ngClass]="sortingConfig.code" [class.sort-selected]="sortingSelection.sort_by === 'code'">Code</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('total_members')" [ngClass]="sortingConfig.total_members" [class.sort-selected]="sortingSelection.sort_by === 'total_members'">Members</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('total_clicks')" [ngClass]="sortingConfig.total_clicks" [class.sort-selected]="sortingSelection.sort_by === 'total_clicks'">Clicks</th>
                                <th class="text-center">Status</th>
                                <th class=" sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                                <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(campaign$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.name }}</td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td>
                                        <ng-container *ngIf="row.promo_type == null">
                                            -
                                        </ng-container>
                                        {{ row.bonus_type }}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.promotion_code == null">
                                            -
                                        </ng-container>
                                        {{ row.promotion_code }}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.dialog_pop_name == null || row.dialog_pop_name == ''">
                                            -
                                        </ng-container>
                                        <div *ngIf="checkDialogValidityDate(row)" class="campaign-dialog-container mt-1">
                                            <span class="d-flex">
                                                <i class="fa fa-exclamation-circle promotion-code-detail-tooltip" placement="top" matTooltip="The selected dialog popup is not within its validity period or has been expired."></i>
                                            </span>
                                        </div>
                                        {{ row.dialog_pop_name }}
                                    </td>
                                    <td class="text-center">
                                        <i [class]="row.auto_assign_promo >= 1 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                    </td>
                                    <td class="text-center">
                                    <ng-container *ngIf="row.code && getLink(row) !== null; else elseTemp">
                                        <a class="text-primary" [href]="getLink(row)" target="_blank">{{ row.code }}</a>
                                        <a
                                            (click)="tooltip.show();"
                                            [cdkCopyToClipboard]="getLink(row)"
                                            (mouseleave) = "tooltip.hide(2000)"
                                            style="margin-left:5px; color: #5867dd !important;" >
                                            <i class="fa fa-paste"
                                                mat-raised-button #tooltip="matTooltip"
                                                (mouseenter)="$event.stopImmediatePropagation()"
                                                (mouseleave)="$event.stopImmediatePropagation()"
                                                matTooltip="Copied!"
                                                matTooltipPosition="above"></i>
                                        </a>
                                    </ng-container>
                                    <ng-template #elseTemp>
                                        <a class="text-primary" [href]="" target="_blank" style="pointer-events: none; color: unset !important">{{ row.code }}</a>
                                        <a
                                            (click)="tooltip.show();"
                                            (mouseleave) = "tooltip.hide(2000)"
                                            style="margin-left:5px; pointer-events: none; color: unset !important" >
                                            <i class="fa fa-paste"
                                                mat-raised-button #tooltip="matTooltip"
                                                (mouseenter)="$event.stopImmediatePropagation()"
                                                (mouseleave)="$event.stopImmediatePropagation()"
                                                matTooltip="Copied!"
                                                matTooltipPosition="above"></i>
                                        </a>
                                    </ng-template>
                                    </td>
                                    <td class="text-center" *ngIf="row.total_members > 0 && canViewCampaignMembersList">
                                        <a class="text-primary" (click)="onOpenDialog('campaign-members', row.id)">{{ row.total_members }}</a>
                                    </td>
                                    <td class="text-center" *ngIf="row.total_members <= 0 || !canViewCampaignMembersList">
                                        {{ row.total_members }}
                                    </td>

                                    <td class="text-center">{{ row.total_clicks }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                    </td>
                                    <td>{{ row.remarks }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditCampaign" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
                                        <button *ngIf="canViewAdCost && canViewAdCostList" matTooltip="View Ad Cost" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"  (click)="openAdCost(row.name, row.id)" ><i class="fas fa-chart-line"></i></button>
                                        <button *ngIf="canCreateAdCostCampaigns" matTooltip="Create Ad Cost" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('createAdCost', row.id)"><i class="fas fa-cart-plus"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
