import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { SendMessageHistories } from '@core/models/send-message-histories.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class SendMessageHistoriesDataService extends DefaultDataService<SendMessageHistories>  {

  messageHistories$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('SendMessageHistories', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<SendMessageHistories[]>{
    return this.http.get<ApiResponse>(`/message/history${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number|string): Observable<SendMessageHistories> {
    return this.http.get<ApiResponse>(`/message/history/${id}`).pipe(
        map(res => res.data)
    );
  }

  updateHistory(data: any): Observable<SendMessageHistories[]>{
    return this.http.put<ApiResponse>(`/message/history/${data.id}`, data).pipe(
      tap( res => this.messageHistories$.next(res.message)),
      map((row: ApiResponse) => row.data.rows)
    );
  }

  getSentReport(id: number|string): Observable<SendMessageHistories> {
    return this.http.get<ApiResponse>(`/message/history/report/${id}`).pipe(
        map(res => res.data)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }
}
