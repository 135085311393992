<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign Leads</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <!-- Currency -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Currency <span class="text-danger">*</span> </label>
          <select formControlName="settings_currency_id" class="col-8 form-control"  (change)="onChangeCurrency($event.target.value)">
            <option value="null" disabled hidden> Please Select </option>
            <option [value]="value.id" *ngFor="let value of currencies"> {{ value.name }} </option>
          </select>
        </div>

        <!-- Telemarketer -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Telemarketer <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8 telemarketer-list" style="padding:0"
            [form] = 'form'
            [dropdownList] = 'telemarketerDropdownList'
            [dropdownSettings] = 'telemarketerDropdownSettings'
            [formName] = "'telemarketer_id'"
            [selectionAttributes] = "'id'"
            [selectedItems] = 'telemarketerSelectedItems'
            (deSelectAll) = "onTelemarketerIdChanges()"
            (deSelectItem) = "onTelemarketerIdChanges()"
            (closeTriggered) = "onTelemarketerIdChanges()">
          </kt-dropdown-wo-lazyload>
        </div>

        <!-- Initial Lead Type -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Initial Lead Type <span class="text-danger">*</span> </label>
          <select formControlName="initial_lead_type" class="col-8 form-control"  (change)="onChangeInitialLeadType($event.target.value)">
            <option value="null" disabled hidden> Please Select </option>
            <option *ngFor="let item of dropdown.initialLeadTypes" [value]="item.id"> {{ item.name }} </option>
          </select>
        </div>

        <!-- Non Member Lead -->
        <ng-container  *ngIf="isNonMemberLead">
          <!-- No. of Fresh Leads -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">No. of Fresh Leads<i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="The leads that are imported through CSV file and have not been assigned to a telemarketer."></i></label>
            <input *ngIf="freshLeads == 0" class="col-8 form-control" disabled>
            <input *ngIf="freshLeads > 0" type="number" (wheel)="false" min="1" formControlName="fresh_leads" class="col-8 form-control" placeholder="Enter total number of leads for all selected telemarketers" [ngClass]="{'is-invalid': form.controls.fresh_leads.errors}">
            <!-- Available leads -->
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8">
              Available leads:
              <ng-container *ngIf="getLeadsList"> {{ freshLeads | number:'1.0-2'}}</ng-container>
              <ng-container *ngIf="!getLeadsList">-</ng-container>
            </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8 red-hint" *ngIf="form.controls.fresh_leads.errors">
              <i class="fa fa-exclamation-circle red-hint"></i> Minimum: {{ minLeadsRequired }}
            </small>
          </div>

          <!-- No. of Retarget Leads -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">No. of Retarget Leads<i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="Leads that meet specified conditions, with a lead status of 'Expired,' and not assigned to the selected telemarketers."></i></label>
            <input *ngIf="retargetLeads == 0" class="col-8 form-control" disabled>
            <input *ngIf="retargetLeads > 0" type="number" (wheel)="false" min="1" formControlName="retarget_leads" class="col-8 form-control">
            <!-- Available leads -->
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8">
              Available leads:
              <ng-container *ngIf="getLeadsList"> {{ retargetLeads | number:'1.0-2'}}</ng-container>
              <ng-container *ngIf="!getLeadsList">-</ng-container>
            </small>
          </div>
        </ng-container>

        <!-- Member Lead -->
        <ng-container  *ngIf="isMemberLead">
          <!-- Member Type -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Member Type <span class="text-danger">*</span> </label>
            <select formControlName="member_type" class="col-8 form-control"  (change)="onChangeMemberType($event.target.value)">
              <option value="null" disabled hidden> Please Select </option>
              <option *ngFor="let item of dropdown.memberTypes" [value]="item.id"> {{ item.name }} </option>
            </select>
          </div>

          <!-- Deposit Inactivity Period (Days) -->
          <div class="col-12 form-group row pr-0" *ngIf="isDepositor">
            <label class="col-4 col-form-label">Deposit Inactivity Period (Days) <span class="text-danger">*</span><i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="The number of days without a deposit since the last deposit date."></i> </label>
            <input type="number" (wheel)="false" min="1" formControlName="deposit_inactivity_period" class="col-8 form-control" [ngClass]="{'is-invalid': form.controls.deposit_inactivity_period.touched && form.controls.deposit_inactivity_period.errors}">
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8 red-hint" *ngIf="form.controls.deposit_inactivity_period.touched && form.controls.deposit_inactivity_period.errors">
              <i class="fa fa-exclamation-circle red-hint"></i> Minimum value: 1
            </small>
          </div>

          <!-- Registration Date -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Registration Date Time </label>
            <input *ngIf="buttonLoading || form.get('telemarketer_id').value == null" class="col-8 form-control" [value]="form.get('registration_start_datetime').value+' - '+form.get('registration_end_datetime').value" disabled>
            <input
                *ngIf="!buttonLoading && form.get('telemarketer_id').value != null"
                type="text" class="col-8 form-control" placeholder="Search"
                (change)="onDateRange($event)" formControlName="defaultDate"
                [timePicker]="true" [timePickerSeconds]="true"
                [alwaysShowCalendars]="true" [ranges]="ranges"
                [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
            ngxDaterangepickerMd/>
            <span class="input-group-append">
                <span class="input-group-text pointer-calendar clear-button" (click)="onClearDate()">X</span>
            </span>
          </div>

          <!-- Source Type -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Source Type </label>
            <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
              [form] = 'form'
              [dropdownList] = 'sourceTypeDropdownList'
              [dropdownSettings] = 'sourceTypeDropdownSettings'
              [formName] = "'source_type'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'sourceTypeSelectedItems'
              (selectedItemsChanged)="onSelectedSourceTypeChanged($event)">
            </kt-dropdown-wo-lazyload>
          </div>

          <ng-container *ngIf="isAffiliate">
            <!-- Affiliate Group -->
            <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Affiliate Group </label>
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8 affiliate-group-list" style="padding:0"
                [form] = 'form'
                [dropdownList] = 'affiliateGroupDropdownList'
                [dropdownSettings] = 'affiliateGroupDropdownSettings'
                [formName] = "'affiliate_group'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'affiliateGroupSelectedItems'>
              </kt-dropdown-wo-lazyload>
            </div>

            <!-- Affiliate Type -->
            <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Affiliate Type <span class="text-danger">*</span></label>
              <select formControlName="affiliate_type" class="col-8 form-control"  (change)="onChangeAffiliateType($event.target.value)">
                <option value="null" disabled hidden> Please Select </option>
                <option *ngFor="let item of dropdown.affiliateTypes" [value]="item.id"> {{ item.name }} </option>
              </select>
            </div>
          </ng-container>

          <!-- Source Account -->
          <div class="col-12 form-group row pr-0" *ngIf="this.isSourceAccount">
            <label class="col-4 col-form-label">Source Account </label>
            <!-- Affiliate -->
            <ng-container *ngIf="this.form.controls['source_type'].value == 0">
              <kt-member-dropdown class="col-8 pl-0 p-0 dropdown-maxheight"
              [form]="form"
              [currencyId]="currencyId"
              [isAffiliate]="true"
              [affiliate_group_ids]="affiliateGroupIds"
              [affiliate_type]="affiliateType"
              [dropdownSettings]='sourceAccountDropdownSettings'
              [formName]="'source_account'"
              [selectionAttributes]="'id'"
              [selectedItems] = 'sourceAccountSelectedItems'>
              </kt-member-dropdown>
            </ng-container>
            <!-- Telemarketer -->
            <ng-container *ngIf="this.form.controls['source_type'].value == 1">
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
                [form] = 'form'
                [dropdownList] = 'sourceAccountDropdownList'
                [dropdownSettings] = 'sourceAccountDropdownSettings'
                [formName] = "'source_account'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'sourceAccountSelectedItems'>
              </kt-dropdown-wo-lazyload>
            </ng-container>
            <!-- Player Referral -->
            <ng-container *ngIf="this.form.controls['source_type'].value == 2">
              <kt-member-dropdown class="col-8 pl-0 p-0 dropdown-maxheight"
              [form]="form"
              [isFilterByCurrency]="true"
              [currencyId]="currencyId"
              [dropdownSettings]='sourceAccountDropdownSettings'
              [formName]="'source_account'"
              [selectionAttributes]="'id'"
              [selectedItems] = 'sourceAccountSelectedItems'>
              </kt-member-dropdown>
            </ng-container>
          </div>

          <!-- No. of Member Leads -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">No. of Member Leads 
              <ng-container *ngIf="isDepositor else nonDepositor">
                <i class="fa fa-info-circle lead-tooltip" placement="top" 
                  matTooltip="Active member accounts registered within the specified timeframe with no deposits for the specified period (Deposit Inactivity Period), meeting source type conditions, without an existing lead record with the same mobile number with the status: Active, Following Up, Partially Closed, Closed, Expired, or Pending Justification.">
                </i>
              </ng-container>
              <ng-template #nonDepositor>
                <i class="fa fa-info-circle lead-tooltip" placement="top" 
                  matTooltip="Active member accounts registered within the specified timeframe, with no deposits after registration, meeting source type conditions, without an existing lead record with the same mobile number with the status: Active, Following Up, Partially Closed, Closed, Expired, or Pending Justification.">
                </i>
              </ng-template>
            </label>
            <input *ngIf="inactiveLeads == 0" class="col-8 form-control" disabled>
            <input *ngIf="inactiveLeads > 0" type="number" (wheel)="false" min="1" formControlName="inactive_leads" class="col-8 form-control" placeholder="Enter total number of leads for all selected telemarketers" [ngClass]="{'is-invalid': form.controls.inactive_leads.errors}">
            <!-- Available leads -->
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8">
              Available leads: 
              <ng-container *ngIf="getLeadsList">{{ inactiveLeads | number:'1.0-2' }}</ng-container>
              <ng-container *ngIf="!getLeadsList">-</ng-container>
            </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8 red-hint" *ngIf="form.controls.inactive_leads.errors">
              <i class="fa fa-exclamation-circle red-hint"></i> Minimum: {{ minLeadsRequired }}
            </small>
          </div>

          <!-- No. of Retarget Leads -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">No. of Retarget Leads<i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="Leads that meet specified conditions, with a lead status of 'Expired,' and not assigned to the selected telemarketers."></i></label>
            <input *ngIf="retargetLeads == 0" class="col-8 form-control" disabled>
            <input *ngIf="retargetLeads > 0" type="number" (wheel)="false" min="1" formControlName="retarget_leads" class="col-8 form-control" placeholder="Enter total number of leads for all selected telemarketers" [ngClass]="{'is-invalid': form.controls.retarget_leads.errors}">
            <!-- Available leads -->
            <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8">
              Available leads:
              <ng-container *ngIf="getLeadsList"> {{ retargetLeads | number:'1.0-2'}}</ng-container>
              <ng-container *ngIf="!getLeadsList">-</ng-container>
            </small>
            <small id="passwordHelpBlock" class="form-text text-muted col-8 red-hint" *ngIf="form.controls.retarget_leads.errors">
              <i class="fa fa-exclamation-circle red-hint"></i> Minimum: {{ minLeadsRequired }}
            </small>
          </div>
        </ng-container>
        
        <div class="col-12 form-group row pr-0" *ngIf="leadIndicator != ''" [ngbTooltip]="leadIndicator">
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8 leadIndicator">
            {{ leadIndicator }}
          </small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <button *ngIf="canAssignLead" class="btn btn-primary btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading || !form.valid || !checkAssignLead()"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Submit</button>
      </div>
    </div>
  </form>
</div>
