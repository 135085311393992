import { MemberRemarksListComponent } from '../../../../../../../shared/member-remarks-list/member-remarks-list.component';
import { PdfDialogComponent } from '@shared/pdf-dialog/pdf-dialog.component';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MemberDataService } from './../../../members/services/member-data.service';
import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, forkJoin, BehaviorSubject, of, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { tap, map, delay, distinctUntilChanged, debounceTime, catchError } from 'rxjs/operators';
import { DepositEntityService } from '../../services/deposit-entity.service';
import { Deposit } from '@core/models/deposit.model';
import Swal from 'sweetalert2';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { DepositDataService } from '../../services/deposit-data.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DatePipe } from '@angular/common';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { BankReceiptBrowserDialogComponent } from '@views/pages/apps/settings/bank-receipts/dialogs/bank-receipt-browser/bank-receipt-browser.component';
import { BankReceipt } from '@core/models/bank-receipt.model';
import { BankReceiptDataService } from '@views/pages/apps/settings/bank-receipts/services/bank-receipt-data.service';
import { BankReceiptStatus } from '@core/enums/bank-receipt.enum';
import * as moment from 'moment-timezone';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Lightbox } from 'ngx-lightbox';
import { SystemSettingDataService } from '../../../../superuser/system-setting/services/system-setting-data.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { DateTime } from 'luxon';
import { RequestReceiptDialogComponent } from '@shared/request-receipt-dialog/request-receipt-dialog.component';
import { ViewReceiptComponent } from '../view-receipt/view-receipt.component';
import { UploadReceiptComponent } from '../upload-receipt/upload-receipt.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { TransactionCallbackHttpService } from '@core/services/transaction-callback-http.service';
import { Pagination } from '@core/models/pagination.model';
import { Stats } from '@core/enums/stats.enum';
import { DepositStatus } from '@core/enums/deposit-status.enum';

type OnSaveDepositActionType = 'approve' | 'reject' | 'void' | 'revert' | 'mark-resolve';

declare var $: any;
@Component({
  selector: 'kt-view-deposit',
  templateUrl: './deposit-details.component.html',
  styleUrls: ['./deposit-details.component.scss']
})

export class DepositDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  // expose to template
  DepositStatusEnum = DepositStatus;

  accountName: string;
  bankName: string;
  accountNumber: number;
  searchTerm = new Subject<string>();
  count = 0;

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  imagePreview = [];
  username = '';
  depositStatus: number;
  depo = {
    ...this.data.deposit
  };

  form: FormGroup;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;

  status = TransactionStatus;
  statusReceipt = BankReceiptStatus;
  statusCallback = Stats;
  selectedReceipt$ = new BehaviorSubject<BankReceipt[]>(null);
  deposit = {
    newBoUserId: 0
  };
  messages$ = this.depositDataService.messages$;

  isSelectedMerchantBank = false;
  isValidMerchantBank = true;
  merchanBank = {
    bank_name: '',
    bank_branch: '',
    account_number: '',
    account_name: ''
  };

  bankReceiptId: number;
  refreshStatus: boolean;

  dropdownSettings = {};
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;

  buttonLoading = false;

  currencies = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
  selectedMember = [];
  receiptList = [];
  isAdmin = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userPermissionsVoid = JSON.parse(localStorage.getItem('user_permissions')).void_deposit_withdrawal;
  userPermissions$ = this.store.pipe(select(specialPermissions));
  checkValidation = false;
  timezone = this.data.timezone ? this.data.timezone : JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  bankAccountLoading = false;

  showTotalProcessingFeeError: boolean = false;
  totalProcessingFeeErrorMessage: string = '';

  showProcessingFeeError: boolean = false;
  processingFeeErrorMessage: string = '';
  cr_totalProcessingFeeErrorMessage: string = '';
  cr_processingFeeErrorMessage: string = '';

  depositReceipts = [];

  selectReceiptAgain: boolean = false;

  pageSize = 8;
  page = 1;
  maxSize = 5;
  transactionCallbackLogsPagination: Pagination;
  transactionCallbackLogs = [];

  depositLimit = null;
  selectedTabIndex: number = 0;

  // permissions
  canEditDeposits: boolean;
  canVoidDepositsBankTransaction: boolean;
  canVoidDepositsPaymentGateway: boolean;
  canRevertDeposits: boolean;
  canResolveUnusualCallback: boolean;
  canViewReceipts: boolean;
  canRequestReceipts: boolean;
  canUploadReceipts: boolean;
  canBrowseReceipts: boolean;
  canBypassBankingDepositLimit: boolean;
  canViewDepositEventHistory: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deposit: Deposit, mode: string, timezone: string, is_crypto: number },
    public dialogRef: MatDialogRef<DepositDetailsComponent>,
    public dialog: MatDialog,
    private depositEntityService: DepositEntityService,
    private depositDataService: DepositDataService,
    private dropdownHttpService: DropdownHttpService,
    private uploadService: UploadHttpService,
    private datePipe: DatePipe,
    private transactionHttpService: TransactionHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private memberDataService: MemberDataService,
    private bankReceiptDataService: BankReceiptDataService,
    private eventEmitterService: EventEmitterService,
    private lightbox: Lightbox,
    private systemSettingService: SystemSettingDataService,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private transactionCallbackHttpService: TransactionCallbackHttpService,
  ) { }

  ngOnInit() {
    if (this.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.currencies = res;
      });
    }
    if (this.data.mode !== 'create') {
      this.receiptList.push({
        src: this.data.deposit['receipt_path'],
        caption: (this.data.deposit['username'] + ' deposit receipt').toLocaleUpperCase(),
        thumb: this.data.deposit['receipt_path']
      });
    }
    this.formInit();
    if (this.data.mode !== 'create') {
      this.getReceipts();
      this.bankAccountLoading = true;
      this.onFocusField();
      this.eventEmitterService.selectedReceiptSubsVar = this.eventEmitterService.updateSelectedReceipt.subscribe((res) => {
        this.onUpdateSelectedReceipt(res);
      });

      this.bankReceiptInit(this.data.deposit.bank_receipt_id);

      this.merchantBankHttpService.getMerchantBanksAccount(`&status[0]=1&status[1]=2&purpose=1&currency_id=${this.depo.currency_id}`).pipe(
        map(res => res.map(function (elm) {
          elm['labelKey'] = elm.account_number ? elm.bank_code + ' - ' + elm.account_name + ' - ' + elm.account_number : elm.bank_code + ' - ' + elm.account_name;
          elm['value'] = [elm.id, elm.account_name, elm.account_number, elm.bank_name];
          return elm;
        }))
      ).subscribe(res => {
        var selectedBank = res.find(v => v.account_number == this.data.deposit.account_number);
        this.bankaccountDropdownList = res;
        this.bankaccountSelectedItems = selectedBank ? [selectedBank] : [];
        this.bankAccountLoading = false;
      });

      // get PG callback logs
      this.onGetTransactionCallbackLogs();
    } else {
      this.form.get('member_account_id').valueChanges.subscribe(value => {
        this.form.patchValue({ merchant_bank_id: null });
        this.depositLimit = null;
        $("kt-dropdown-wo-lazyload div angular2-multiselect div div div span.c-remove.clear-all.ng-star-inserted").click();
        if (value === null) {
          this.bankaccountDropdownList = [];
        } else {
          if (this.selectedMember.length > 0) {
            this.bankAccountLoading = true;
            const currency_id = this.currencies.find(currency => currency.id === this.selectedMember.find(member => member.id === value).currency_id).id;
            this.merchantBankHttpService.getMerchantBanksAccount(`&status[0]=1&status[1]=2&purpose=1&currency_id=${currency_id}`).pipe(
              map(res => res.map(function (elm) {
                elm['labelKey'] = elm.account_number ? elm.bank_code + ' - ' + elm.account_name + ' - ' + elm.account_number : elm.bank_code + ' - ' + elm.account_name;
                elm['value'] = [elm.id, elm.account_name, elm.account_number, elm.bank_name];
                return elm;
              })
              )
            ).subscribe(res => {
              this.bankaccountDropdownList = res;
              this.bankAccountLoading = false;
            });
          }
        }
      })
    }

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      maxHeight: 170,
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      labelKey: 'username',
      maxHeight: 200,
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      const isCrypto = this.data.deposit?.is_crypto === 1;

      this.canEditDeposits = isCrypto ? appPermissions.edit_crypto_deposits : appPermissions.edit_deposits;
      this.canVoidDepositsBankTransaction = isCrypto ? appPermissions.void_crypto_deposits : appPermissions.void_deposits_bank_transactions;
      this.canVoidDepositsPaymentGateway = isCrypto ? appPermissions.void_crypto_deposits : appPermissions.void_deposits_payment_gateway;
      this.canRevertDeposits = isCrypto ? false : appPermissions.revert_deposits;
      this.canResolveUnusualCallback = isCrypto ? false : appPermissions.resolve_unusual_callback_deposit;
      this.canViewReceipts = isCrypto ? false : appPermissions.view_deposit_receipts;
      this.canRequestReceipts = isCrypto ? false : appPermissions.request_receipts;
      this.canUploadReceipts = isCrypto ? false : appPermissions.upload_receipts;
      this.canBrowseReceipts = isCrypto ? false : appPermissions.browse_receipts;
      this.canBypassBankingDepositLimit = isCrypto ? false : appPermissions.bypass_banking_deposit_limit;
      this.canViewDepositEventHistory = isCrypto ? false : appPermissions.view_deposit_event_history;

      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    if (this.data.mode === 'create') {
      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'datetime'),
      ]).subscribe();
    }
  }

  onViewMoreRemarks(data: any) {
    this.memberDataService.getRemarksHistory(data.member_id).subscribe(res => {
      this.openDialogBy(MemberRemarksListComponent, { remarks: res });
    });
  }

  onDisableBrowse(method: string) {
    return method.includes('Payment Gateway');
  }

  openReceipt() {
    let depositData = {
      ...this.data.deposit
    };
    depositData.receipts = this.depositReceipts;

    this.openDialogBy(ViewReceiptComponent, { deposit: depositData });
  }

  requestReceipt() {
    if (this.data.deposit.status === 7) {
      Swal.fire({
        icon: "error",
        title: 'System Message',
        text: 'Receipt already requested'
      });
      return;
    }

    const requestReceiptDialogRef = this.dialog.open(RequestReceiptDialogComponent, {
      width: "800px",
      data: {
        deposit: this.data.deposit,
      },
    });

    requestReceiptDialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dialogRef.close(true);
      }
    });
  }

  onUpdateSelectedReceipt(data: any) {
    this.selectedReceipt$.next(data);
    this.onFocusField();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onGetTransactionCallbackLogs(page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    const params = `&reference=${this.data.deposit.pg_reference_id}`;
    this.transactionCallbackHttpService.getCallbackLogs(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap((res: any) => {
        this.transactionCallbackLogsPagination = res.paginations;
        this.transactionCallbackLogs = res.rows;
      })
    ).subscribe();
  }

  onVoid(deposit: Deposit) {
    if (
      (this.isBankTransfer() && !this.canVoidDepositsBankTransaction) ||
      (!this.isBankTransfer() && !this.canVoidDepositsPaymentGateway)
    ) {
      Swal.fire({
        icon: "error",
        title: 'Permission Denied',
        text: 'You do not have permission to void deposit'
      });
      return;
    }

    const amount = this.form.get('confirmed_amount').value;

    this.buttonLoading = true;

    this.depositDataService.checkWalletBalance(`member_account_id=${deposit.member_id}&amount=${amount}`).pipe(
      tap(res => {
        if (res.success) {
          this.onSave(deposit, 'void');
        }
      }),
      catchError(err => {
        this.buttonLoading = false;
        throw err;
      })
    ).subscribe();

  }

  onSave(deposit: Deposit, type: OnSaveDepositActionType, mode?: string) {
    if (!this.canEditDeposits) {
      Swal.fire({
        icon: "error",
        title: 'Permission Denied',
        text: 'You do not have permission to edit deposit'
      });
      return;
    }

    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      this.subscription = this.depositEntityService.getByKey(deposit.id).pipe(
        tap((res) => {
          this.deposit.newBoUserId = res.bo_user_id;
          const data = {
            id: deposit ? deposit.id : null,
            bank_receipt_id: sessionStorage.getItem('bank_receipt_id'),
            bank_receipt_currency: sessionStorage.getItem('bank_receipt_currency'),
            ...this.form.value,
            status: (type === 'approve')
                      ? DepositStatus.Approved
                      : type === 'revert'
                      ? DepositStatus.PendingCallback
                      : (type === 'void' || type === 'reject')
                      ? DepositStatus.Rejected
                      : this.depositStatus,
            type,
          };

          if (type === 'mark-resolve') {
            data.unusual_callback = 0;
          }

          Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
          const showConfirmation = (title: string, text: string, icon?: 'warning') => {
            return Swal.fire({
              title,
              text,
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              reverseButtons: true
            });
          };
          const updateDeposit = (takeover = false) => {
            data.takeover = takeover;
            this.depositEntityService.update(data).pipe(
              tap((row: any) => {
                if (row.success == false) {
                  Swal.fire({
                    icon: 'error',
                    title: row.message,
                  });
                } else {
                  this.messages$.next([...row.message]);
                }
                this.buttonLoading = false;
                this.checkValidation = false;
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.checkValidation = false;
                throw error;
              })
            ).subscribe();
          };

          let swalTitle, swalText = '';
          let remarksRequired = false;
          if (type === 'approve') {
            swalTitle = 'Approve Deposit';
            swalText = 'Do you want to approve this deposit?';
            if (this.depositStatus == DepositStatus.PendingReceipt) {
              remarksRequired = true;
              if (this.depositReceipts.length == 0) {
                swalText = 'There is no receipt uploaded for this deposit. Do you still want to approve the deposit?';
              }
            }
          }
          if (type === 'reject') {
            swalTitle = 'Reject Deposit';
            swalText = 'Do you want to reject this deposit?';
            remarksRequired = true;
            if (this.depositStatus == DepositStatus.PendingReceipt) {
              swalText = 'The <b>Request Receipt</b> action has been triggered. Do you want to proceed with rejecting the deposit?';
            }
          }
          if (type === 'void') {
            swalTitle = 'Void Approved Deposit';
            swalText = 'Do you want to reject this deposit?';
            remarksRequired = true;
            if (this.depositStatus == DepositStatus.PendingReceipt) {
              swalText = 'The <b>Request Receipt</b> action has been triggered. Do you want to proceed with rejecting the deposit?';
            }
          }
          if (type === 'revert') {
            swalTitle = 'Revert Deposit Status';
            swalText = "Do you want to revert this deposit status to <b>'Pending Callback'</b>?";
            remarksRequired = true;
          }
          if (type === 'mark-resolve') {
            swalTitle = 'Mark as Resolve';
            swalText = 'Would you like to mark as resolve?';
            remarksRequired = true;
          }

          let remarksHtml = `<p style="color: ${remarksRequired ? 'red' : ''};">Remark${remarksRequired ? '' : ' (Optional)'}</p></div><input id="remark-input" class="form-control">`;

          Swal.fire({
            title: swalTitle,
            html: '<div style="text-align: center;">' + remarksHtml + '<p style="margin-top: 20px;">' + swalText + '</p></div>',
            icon: 'info',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true,
            preConfirm: () => {
              const inputValue = (document.getElementById('remark-input') as HTMLInputElement)?.value ?? '';

              if (inputValue.trim() === '' && remarksRequired) {
                Swal.showValidationMessage('Remarks is required.');
                return false;
              }

              if (inputValue != '') {
                data.remarks = inputValue;
              }
              return true;
            }
          }).then((resp) => {
            if (resp.value) {
              if (type === 'approve') {
                this.subscription = this.hasBankReceipt(this.data.deposit.id)
                  .pipe(tap((bankReceiptId) => {
                    if (!bankReceiptId) {
                      showConfirmation(
                        'No bank receipt selected!',
                        'Are you sure you want to ' + type + ' this deposit without selecting a bank receipt?')
                        .then((result) => {
                          if (result.value) {
                            updateDeposit();
                          } else {
                            this.buttonLoading = false;
                            this.checkValidation = false;
                          }
                        });
                    } else {
                      updateDeposit();
                    }
                  }))
                  .subscribe();
              }

              if (type === 'reject' || type === 'void' || type === 'revert' || type === 'mark-resolve') {
                updateDeposit();
              }
            } else {
              this.buttonLoading = false;
              this.checkValidation = false;
            }
          });
        })).subscribe();
      this.refreshStatus = true;
    }
  }

  onUpdateRemarks() {
    const data = {
      id: this.data.deposit.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (key !== 'remarks' && key !== 'id') && delete data[key]);
    this.depositDataService.updateRemarks(data).pipe(
      tap((row: any) => {
        this.messages$.next([...row.message]);
      })
    ).subscribe();
  }

  async onAdd() {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });

      const data = {
        ...this.form.value,
        datetime: moment(this.form.value.datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] < 1) && delete data[key]);
 
      const fireDepositLimitBypassConfirmation = async (title: string, messageText: string): Promise<boolean> => {
        const result = await Swal.fire({
          title,
          html: `${messageText} <br> Do you want to proceed with the deposit creation?`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Bypass Limit',
          reverseButtons: true,
        });
        return result.isConfirmed;
      };

      const validateAndPrompt = async (): Promise<boolean> => {
        // Min-Max Validation
        let minMaxValidation = this.validateMinMaxPerTransactionAmount(data['amount']);
        // Daily Count Validation
        let dailyCountValidation = (this.depositLimit.total_deposit_count_today >= this.depositLimit.daily_max_count && this.depositLimit.daily_max_count != 0) ? true : false;
        // Daily Max Validation
        let dailyMaxValidation = this.validateDailyMaxAmount(data['amount']);

        if (minMaxValidation || dailyCountValidation || dailyMaxValidation) {
          let messageText = '<div class="text-container deposit-limit-error" style="display: flex; justify-content: center; align-items: center; text-align: left;"><ul style="list-style: disc inside; padding: 0; margin: 0; max-width: 300px; word-wrap: break-word;">';
          if (dailyCountValidation) {
            messageText += '<li class="d-flex align-items-center"><i class="fas fa-info-circle mr-2"></i>' + 'Daily count : ' + this.depositLimit.daily_max_count + '</li>';
          }
          if (minMaxValidation) {
            messageText += '<li class="d-flex align-items-center"><i class="fas fa-info-circle mr-2"></i>';
            if (this.validateMinMaxPerTransactionAmount(data['amount'], 'checkMin')) {
              messageText += 'Minimum Amount : ' + this.depositLimit.currency_code + ' ' + this.depositLimit.min;
            } else if (this.validateMinMaxPerTransactionAmount(data['amount'], 'checkMax')) {
              messageText += 'Maximum Amount : ' + this.depositLimit.currency_code + ' ' + this.depositLimit.max;
            }
            messageText += '</li>';
          }
          if (dailyMaxValidation) {
            messageText += '<li class="d-flex align-items-center"><i class="fas fa-info-circle mr-2"></i>' + 'Daily Maximum : ' + this.depositLimit.currency_code + ' ' + this.depositLimit.daily_max + '</li>';
          }
          messageText += "</ul></div>"
          if (this.canBypassBankingDepositLimit) {
            const res = await fireDepositLimitBypassConfirmation('Invalid Transaction Amount', messageText);
            if (!res) return false;
          } else {
            await Swal.fire({
                title: 'Invalid Transaction Amount',
                html: `${messageText}`,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
              });
              return false;
          }
        }
  
        return true;
      };
      
      const isValidationPassed = await validateAndPrompt();
      if (isValidationPassed) {
        this.handleDeposit(data);
      } else {
        this.form.setErrors(null);
        this.buttonLoading = false;
        this.checkValidation = false;
      }
      
      this.messageSubscription = this.depositDataService.messages$.subscribe(message => {
        if (message.length > 0) {
          this.messages$.next([...message]);
          this.buttonLoading = false;
        }
      });
    }
    this.refreshStatus = true;
  }

  handleDeposit(data: any) {
    this.subscription = forkJoin([
      this.depositEntityService.add(data).pipe(
        tap((res: any) => {
          this.form.setErrors(null);
          this.buttonLoading = false;
          this.checkValidation = false;
        }),
        catchError((error) => {
          this.form.setErrors(null);
          this.buttonLoading = false;
          this.checkValidation = false;
          throw error;
        })
      )
    ]).subscribe();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onUploadFile(event: any) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'receipts');
    this.uploadService.upload(formData).subscribe(res => {
      this.imagePreview = res;
      this.form.patchValue({
        receipt_path: this.imagePreview[0]
      });
      this.form.get('receipt_path').markAsDirty();
      this.form.markAllAsTouched();
      this.onFocusField();
    });
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first.nativeElement.focus()
      )).subscribe();
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return moment(date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm');
    }
    return '-';
  }

  onShowBankReceiptBrowser() {
    if (typeof (this.form.value.merchant_bank_details) === 'string') {
      this.accountName = this.data.deposit.account_name;
      this.accountNumber = this.data.deposit.account_number;
      this.bankName = this.data.deposit.merchant_bank;
    }
    else {
      this.accountName = this.form.value.merchant_bank_details[1];
      this.accountNumber = this.form.value.merchant_bank_details[2];
      this.bankName = this.form.value.merchant_bank_details[3];
    }
    this.dialog.open(BankReceiptBrowserDialogComponent, {
      width: '1150px',
      data: {
        merchantBankDetails: this.merchanBank.account_number ? this.merchanBank : {
          account_name: this.accountName,
          account_number: this.accountNumber,
          bank_name: this.bankName
        },
        depositDetails: this.data.deposit
      }
    });
  }

  searchChange(event?: Event) {
    this.count = 0;
    this.searchTerm.next(String(event));
    this.searchTerm.pipe(
      distinctUntilChanged(),
      debounceTime(500),
    ).subscribe(res => {
      this.searchTerm = new Subject<string>();
      this.count++;
      if (this.count + 1 === res.length) {
        this.username = res;
      }
    });
  }

  onSelectMerchantBank(bankID: number) {
    const bankId = bankID;
    this.isSelectedMerchantBank = bankId ? true : false;
    this.onUpdateSelectedReceipt(null);
    if (bankId) {
      const merchantBankData = this.merchantBankHttpService.getById(bankId);
      this.subscription = merchantBankData.pipe(
        tap((res) => {
          this.merchanBank.bank_name = res.bank.name;
          this.merchanBank.bank_branch = res.bank_branch;
          this.merchanBank.account_number = res.account_number;
          this.merchanBank.account_name = res.account_name;
          this.form.patchValue({
            account_name: res.account_name,
            account_number: res.account_number,
            merchant_bank_account: res.bank_code + " - " + res.account_name + " - " + res.account_number,
            merchant_bank_id: bankID
          });
        })).subscribe();
    } else {
      this.merchanBank.bank_name = null;
      this.merchanBank.bank_branch = null;
      this.merchanBank.account_number = null;
      this.merchanBank.account_name = null;
      this.form.patchValue({
        account_name: null,
        account_number: null,
        merchant_bank_account: null,
        merchant_bank_id: null,
      });
    }
  }

  onSelectedMemberChanged(value) {
    this.selectedMember = value;
  }

  /**
   * For this we will directly match payment_method (name) provided in data.deposit.
   * As long as payment_method contains the word "Bank Transfer" then we will consider
   * this deposit a bank transfer
   *
   */
  isBankTransfer() {
    const str = this.data.deposit?.payment_method;
    const searchStr = "Bank Transfer";
    
    const regex = new RegExp(searchStr.split(' ').join('.*'), 'i');
    
    return regex.test(str);
  }

  onSelectedMerchantBankChanged(value) {
    this.onGetLimit(value[0].id);
  }

  onGetLimit(merchantBankId: number) {
    this.depositLimit = null;

    const path = `?member_account_id=${this.selectedMember[0]['id']}&merchant_bank_id=${merchantBankId}`;

    this.depositDataService.getLimit(path)
      .subscribe(res => {
        this.depositLimit = res;
      });
  }

  private openDialogBy(componentRef: any, data?: { remarks?: any, deposit?: any }) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        remarks: data.remarks,
        deposit: data.deposit,
      }
    });
  }

  private buildDatePicker(index: number, formKey: string) {
    if (this.data.mode === 'create') {
      return this.datePicker.valueChange.pipe(
        map(res => this.datePipe.transform(res, this.transactionHttpService.dateTimeFormat)),
        tap(date => {
          this.form.patchValue({ [formKey]: date });
        }),
      );
    }
  }

  private hasBankReceipt(id: number) {
    return of(sessionStorage.getItem('bank_receipt_id'));
  }

  private bankReceiptInit(id: number) {
    this.subscription = this.bankReceiptDataService.getReceipt(id).pipe(
      tap(res => {
        this.bankReceiptDataService.selectedReceipt$.next(res);
        this.selectedReceipt$.next(res);
        if (res.length > 0) {
          sessionStorage.setItem('bank_receipt_id', res[0].id.toString());
          sessionStorage.setItem('bank_receipt_currency', res[0].currency);
        }
      })
    ).subscribe();
  }

  private formInit() {
    let merchantAccountName = null;
    let merchantAccountNumber = null;
    let merchantBank = null;
    let merchantBankName = null;
    let merchantBankBranch = null;
    let merchantBankAccount = null;
    let confirmedAmount = null;
    let cr_confirmedAmount = null;

    let memberId = null;
    // let processingFee = null;
    let totalProcessingFee = 0;
    let processingFee = 0;
    let memberProcessingFee = 0;
    let cr_totalProcessingFee = 0;
    let cr_processingFee = 0;
    let cr_memberProcessingFee = 0;
    let remarks = null;
    let approvedAt = null;
    let createdAt = null;
    let receiptPath = null;
    let amount = null;
    const datetime = null;
    const merchantBankId = null;
    let status = 1;
    let pg_reference_id = null;

    if (this.data.mode === 'edit') {

      this.depositStatus = this.depo.status === DepositStatus.Pending ? DepositStatus.InProgress : this.depo.status;

      merchantBank = this.data.deposit.merchant_bank_id,
        merchantBankName = this.data.deposit.merchant_bank,
        merchantAccountName = this.data.deposit.account_name;
      merchantAccountNumber = this.data.deposit.account_number;
      merchantBankBranch = this.data.deposit.bank_branch;
      merchantBankAccount = this.data.deposit.merchant_bank_account;
      confirmedAmount = this.data.deposit.confirmed_amount;
      cr_confirmedAmount = this.data.deposit.cr_confirmed_amount;

      // processingFee = this.data.deposit.processing_fee;
      processingFee = +this.data.deposit.processing_fee;
      memberProcessingFee = +this.data.deposit.member_processing_fee;
      totalProcessingFee = processingFee + memberProcessingFee;
      cr_processingFee = +this.data.deposit.cr_processing_fee;
      cr_memberProcessingFee = +this.data.deposit.cr_member_processing_fee;
      cr_totalProcessingFee = cr_processingFee + cr_memberProcessingFee;
      remarks = this.data.deposit.remarks;
      approvedAt = this.data.deposit.updated_at;
      createdAt = this.data.deposit.created_at;
      receiptPath = this.data.deposit.receipt_path;
      memberId = this.data.deposit.member_id;
      status = this.data.deposit.status;
      amount = this.data.deposit.amount;
      pg_reference_id = this.data.deposit.pg_reference_id;

      this.form = new FormGroup({
        merchant_bank_id: new FormControl(merchantBank),
        merchant_bank_details: new FormControl([merchantBank, merchantAccountName, merchantAccountNumber, merchantBankName]),
        account_name: new FormControl(merchantAccountName),
        account_number: new FormControl(merchantAccountNumber),
        bank_branch: new FormControl(merchantBankBranch),
        merchant_bank_account: new FormControl(merchantBankAccount),
        // processing_fee: new FormControl(processingFee, [Validators.required]),
        total_processing_fee: new FormControl({ value: totalProcessingFee, disabled: [1, 2, 6].includes(this.data.deposit.status) }, [Validators.required, Validators.min(0), Validators.max(this.data.deposit.amount)]),
        processing_fee: new FormControl({ value: processingFee, disabled: [1, 2, 6].includes(this.data.deposit.status) }, [Validators.required, Validators.min(0)]),
        member_processing_fee: new FormControl({ value: memberProcessingFee, disabled: [1, 2, 6].includes(this.data.deposit.status) }, [Validators.required, Validators.min(0)]),
        cr_total_processing_fee: new FormControl(cr_totalProcessingFee, [Validators.required, Validators.min(0), Validators.max(this.data.deposit.cr_amount)]),
        cr_processing_fee: new FormControl(cr_processingFee, [Validators.required, Validators.min(0)]),
        cr_member_processing_fee: new FormControl(cr_memberProcessingFee, [Validators.required, Validators.min(0)]),
        confirmed_amount: new FormControl(confirmedAmount),
        cr_confirmed_amount: new FormControl(cr_confirmedAmount),
        remarks: new FormControl(remarks, [Validators.min(3)]),
        status: new FormControl(status),
        pg_reference_id: new FormControl({ value: pg_reference_id, disabled: (this.data.deposit.payment_gateway_id > 0 || [1,2,6].includes(this.data.deposit.status)) }),

        // Create form controls
        start_date: new FormControl()
      });

      this.merchanBank.bank_name = merchantBankName;
      this.merchanBank.bank_branch = merchantBankBranch;
      this.merchanBank.account_number = merchantAccountNumber;
      this.merchanBank.account_name = merchantAccountName;

      if (this.merchanBank != null)
        this.isSelectedMerchantBank = true;

      this.form.get('merchant_bank_details').valueChanges.subscribe(res => {
        if (this.selectedReceipt$.value != null && this.selectedReceipt$.value.length > 0) {
          this.selectReceiptAgain = true;
        } else {
          this.selectReceiptAgain = false;
        }
        this.onSelectMerchantBank(res ? res[0] : null);
        sessionStorage.removeItem('bank_receipt_id');
      });

      // Validate that the total processing fee should not be greater than the total deposit amount
      this.form.controls.total_processing_fee.valueChanges.subscribe(value => {
        this.validateProcessingFee('Local', value, +this.form.controls.processing_fee.value, +this.form.controls.member_processing_fee.value);
      })

      // Check if company + player greater than total, display message
      this.form.controls.processing_fee.valueChanges.subscribe(value => {
        this.validateProcessingFee('Local', +this.form.controls.total_processing_fee.value, value, +this.form.controls.member_processing_fee.value);
      });

      // Check if company + player greater than total, display message
      this.form.controls.member_processing_fee.valueChanges.subscribe(value => {
        this.validateProcessingFee('Local', +this.form.controls.total_processing_fee.value, +this.form.controls.processing_fee.value, value);
      });

      // Validate that the total processing fee should not be greater than the total deposit amount
      this.form.controls.cr_total_processing_fee.valueChanges.subscribe(value => {
        this.validateProcessingFee('Crypto', value, +this.form.controls.cr_processing_fee.value, +this.form.controls.cr_member_processing_fee.value);
      });

      // Check if company + player greater than total, display message
      this.form.controls.cr_processing_fee.valueChanges.subscribe(value => {
        this.validateProcessingFee('Crypto', +this.form.controls.cr_total_processing_fee.value, value, +this.form.controls.cr_member_processing_fee.value);
      });

      // Check if company + player greater than total, display message
      this.form.controls.cr_member_processing_fee.valueChanges.subscribe(value => {
        this.validateProcessingFee('Crypto', +this.form.controls.cr_total_processing_fee.value, +this.form.controls.cr_processing_fee.value, value);
      });
    }
    if (this.data.mode === 'create') {
      this.form = new FormGroup({
        member_account_id: new FormControl(null, [Validators.required]),
        merchant_bank_id: new FormControl(merchantBankId, [Validators.required]),
        amount: new FormControl(null, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]+)?$')]),
        datetime: new FormControl(datetime, [Validators.required]),
        receipt_path: new FormControl(receiptPath, [Validators.required]),
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    if (this.data.timezone) {
      let returnDate = this.timeZoneDate.transformWithTimezone(date, timezone, format);
      let todayDate = DateTime.now().setZone(timezone).toFormat('(ZZ)');
      todayDate = todayDate.replace('(', '(GMT ');
      returnDate = returnDate + ' ' + todayDate;
      return returnDate;
    }
    else {
      return this.timeZoneDate.transformWithTimezone(date, timezone, format);
    }
  }

  openUploadReceipt() {
    const dialogRef = this.dialog.open(UploadReceiptComponent, {
      width: '800px',
      data: {
        remarks: this.data.deposit.remarks,
        deposit: this.data.deposit,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReceipts();
      if (result === true) {
        this.refreshStatus = true;
        this.dialogRef.close(true);
      }
    });
  }

  getReceipts() {
    this.depositDataService.getReceipt(this.data.deposit.id).pipe(
      map(res => {
        this.depositReceipts = res;
      })
    ).subscribe();
  }

  validateProcessingFee(type, total, company, player) {
    let totalProcessingFeeErrorMessage = '';
    let processingFeeErrorMessage = '';

    if (total < 0 || total > this.data.deposit.amount) {
      totalProcessingFeeErrorMessage = 'Total must be greater or equal to 0 and less than deposit amount';
      processingFeeErrorMessage = '';
    } else if (total !== (company + player)) {
      totalProcessingFeeErrorMessage = 'Total must be equal to Company + Player';
      processingFeeErrorMessage = 'Company + Player must be equal to Total';
    } else {
      totalProcessingFeeErrorMessage = '';
      processingFeeErrorMessage = '';
    }

    if (type == 'Crypto') {
      this.cr_totalProcessingFeeErrorMessage = totalProcessingFeeErrorMessage;
      this.cr_processingFeeErrorMessage = processingFeeErrorMessage;
    } else {
      this.totalProcessingFeeErrorMessage = totalProcessingFeeErrorMessage;
      this.processingFeeErrorMessage = processingFeeErrorMessage;
    }
  }

  validateMinMaxPerTransactionAmount(amount: number, type = null): boolean {
    const min = Number(this.depositLimit.min);
    const max = Number(this.depositLimit.max);

    if (type == 'checkMin') {
      return amount < min;
    } else if (type == 'checkMax') {
      return amount > max && max != 0;
    } else {
      return amount < min || (amount > max && max != 0)
    }
  }

  validateDailyMaxAmount(amount: number, type = null): boolean {
    const max = Number(this.depositLimit.daily_max);
    const total_deposit_today = Number(this.depositLimit.total_deposit_today);

    if (type == 'front' && (amount == 0 || amount == null)) {
      // Display as error when amount is equal on default
      return (total_deposit_today + amount >= max && max != 0);
    } else {
      return (total_deposit_today + amount > max && max != 0);
    }
  }
}
