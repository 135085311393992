
<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div  class="kt-portlet kt-portlet--mobile">
        <div  class="kt-portlet__body">
          <div class="kt-form kt-form--label-right">
              <div class="row align-items-center">
                  <div class="col-xl-12 order-2 order-xl-1 pr-0">
                      <form class="row align-items-center" [formGroup]="form">
                          <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <div class="input-group-prepend">
                                            <select formControlName="date_type" class="form-control">
                                                <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                            </select>
                                        </div>    
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                          </div>
                          <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
              <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
              </div>
              <div>
                <button *ngIf="canExportAffiliateCommission" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="button_loading">
                    <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>
                    Export
                </button>
                <button *ngIf="canGenerateAffiliateCommission" class="btn btn-brand btn-icon-sm mr-2" (click)="onOpenDialog('generate-comission')"><i class="fas fa-plus"></i>Generate Commission</button>
                <ng-container *ngIf="canReleaseAffiliateCommission">
                    <button class="btn btn-success btn-icon-sm mr-2" (click)="onRejectRelease('release', 'selected')" [disabled]="!(selectedCommission.length > 0)"><i class="fas fa-check"></i>Release Selected</button>
                    <button class="btn btn-success btn-icon-sm mr-2" (click)="onRejectRelease('release', 'all')" [disabled]="selectedCommission.length > 0"><i class="fas fa-check"></i>Release All</button>
                </ng-container>
                <ng-container *ngIf="canRejectAffiliateCommission">
                    <button class="btn btn-danger btn-icon-sm mr-2" (click)="onRejectRelease('reject', 'selected')" [disabled]="!(selectedCommission.length > 0)"><i class="fas fa-ban"></i>Reject Selected</button>
                    <button class="btn btn-danger btn-icon-sm mr-2" (click)="onRejectRelease('reject', 'all')" [disabled]="selectedCommission.length > 0"><i class="fas fa-ban"></i>Reject All</button>
                </ng-container>
              </div>
          </div>

            <!-- Summary -->
            <div class="col-xl-12 summary-wrapper" *ngIf="commissionReleases$">
                <div class="row">
                    <div class="col-12 pl-0 pr-0">
                        <label>Selected Rows Total Commission (USD): </label><span class="ml-1">{{ selectedTotalCommission | number : '1.2' }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pl-0 pr-0">
                        <label>Current Page Total Commission (USD): </label><span class="ml-1">{{ commissionReleases$.current_page_total_commission | number : '1.2' }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pl-0 pr-0">
                        <label>Total Commission (USD): </label><span class="ml-1">{{ commissionReleases$.total_commission | number : '1.2' }}</span>
                    </div>
                </div>
            </div>

            <div  class="kt-section">
                <div  class="kt-section__content">
                    <div  class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        <input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()">
                                    </th>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('generate_date')" [ngClass]="sortingConfig.generate_date" [class.sort-selected]="sortingSelection.sort_by === 'generate_date'">Commission Calculation Period</th>
                                    <th class="sort-enabled" (click)="onSortColumn('affiliate')" [ngClass]="sortingConfig.affiliate" [class.sort-selected]="sortingSelection.sort_by === 'affiliate'">Affiliate</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Aff. Currency</th>
                                    <th class="sort-enabled" (click)="onSortColumn('affiliate_group')" [ngClass]="sortingConfig.affiliate_group" [class.sort-selected]="sortingSelection.sort_by === 'affiliate_group'">Group</th>
                                    <th class="sort-enabled" (click)="onSortColumn('active_members')" [ngClass]="sortingConfig.active_members" [class.sort-selected]="sortingSelection.sort_by === 'active_members'">Active Members</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('commission_currency')" [ngClass]="sortingConfig.commission_currency" [class.sort-selected]="sortingSelection.sort_by === 'commission_currency'">Comm. Currency</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('calculated_commission')" [ngClass]="sortingConfig.calculated_commission" [class.sort-selected]="sortingSelection.sort_by === 'calculated_commission'">Calculated Comm.</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('last_carry_forward')" [ngClass]="sortingConfig.last_carry_forward" [class.sort-selected]="sortingSelection.sort_by === 'last_carry_forward'">Last Carry Forward</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('entitled_commission')" [ngClass]="sortingConfig.entitled_commission" [class.sort-selected]="sortingSelection.sort_by === 'entitled_commission'">Entitled Comm.</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('adjustments')" [ngClass]="sortingConfig.adjustments" [class.sort-selected]="sortingSelection.sort_by === 'adjustments'">Adjustment</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('nett_commission')" [ngClass]="sortingConfig.nett_commission" [class.sort-selected]="sortingSelection.sort_by === 'nett_commission'">Nett Comm.</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('released_commission')" [ngClass]="sortingConfig.released_commission" [class.sort-selected]="sortingSelection.sort_by === 'released_commission'">Released Comm.</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('carry_forward')" [ngClass]="sortingConfig.carry_forward" [class.sort-selected]="sortingSelection.sort_by === 'carry_forward'">Carry Forward</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th>Remarks</th>
                                    <th class="date-min-width sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="date-min-width sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <!-- <th class="text-center">Action</th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="(commissionReleases$)">
                                <tr *ngFor="let row of commissionReleases$.rows">
                                    <th [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id"></th>
                                    <td>{{ row.id }}</td>
                                    <td>
                                        {{ row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }} - {{ row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="canViewAffiliateDialog; else elseTemplate">
                                            <a class="text-primary font-weight-bold" (click)="onViewInfo('information', row)">
                                                {{ row.affiliate }}
                                            </a>
                                        </ng-container>
                                        <ng-template #elseTemplate>
                                            {{ row.affiliate }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td>{{ row.affiliate_group }}</td>
                                    <td>{{ row.active_members }}</td>
                                    <td class="text-center">{{ row.commission_currency }}</td>
                                    <td class="text-right">{{ row.calculated_commission | number : '1.2' }}</td>
                                    <td class="text-right">{{ row.last_carry_forward | number : '1.0' }}</td>
                                    <td class="text-right">{{ row.entitled_commission | number : '1.2' }}</td>
                                    <td class="text-right">{{ row.adjustments | number : '1.2' }}</td>
                                    <td class="text-right">{{ row.nett_commission | number : '1.2' }}</td>
                                    <td class="text-right">{{ row.released_commission | number : '1.2' }}</td>
                                    <td class="text-right">{{ row.carry_forward | number : '1.2' }}</td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status_id"> {{ row.status }} </span><br>
                                    </td>
                                    <td>{{ row.remarks }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System'}} <br>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }} <br>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <!-- <td class="text-center">
                                        <button matTooltip="Release" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm">
                                            <i class="fas fa-check"></i>
                                        </button>
                                    </td> -->
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="onViewPageBy(page)"></kt-swal-alert>
