import { Component, OnInit, ChangeDetectorRef, OnDestroy  } from '@angular/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { environment } from '@env/environment';
import Echo from 'laravel-echo';
import { DepositDataService } from '../services/deposit-data.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GroupHttpService } from '@core/services/group-http.service';

@Component({
  selector: 'deposit-wrapper',
  templateUrl: './deposit-wrapper.component.html',
  styleUrls: ['./deposit-wrapper.component.scss'],
})
export class DepositWrapperComponent implements OnInit, OnDestroy {

  totalBanking = 0;
  totalCrypto = 0;
  echo: Echo;

  merchantBankAccounts$: ReplaySubject<any[]> = new ReplaySubject(1);
  merchantBankAccountsBanking$: Observable<any[]>;
  merchantBankAccountsCrypto$: Observable<any[]>;

  groups: any;

  // permissions
  canViewBankingTab: boolean;
  canViewCryptoTab: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
    private depositDataService: DepositDataService,
    private merchantBankHttpService: MerchantBankHttpService,
    private groupHttpService: GroupHttpService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    const user_token = JSON.parse(localStorage.getItem('user_token'));
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost,
        auth: {
          headers: {
              'access-token': user_token.access_token,
              'token-selector': user_token.plaintext_token,
              'X-User-Model': 'bo'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {

    // Permissions
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewBankingTab = appPermissions.view_banking_deposits;
      this.canViewCryptoTab = appPermissions.view_crypto_deposits;

      this.cdr.detectChanges();
    });

    // Deposit count event listener
    const listener = (res) => {
      this.totalBanking = res.banking;
      this.totalCrypto = res.crypto;
      this.cdr.detectChanges();
    };
    this.echo.channel('deposit-count-channel').listen('.DepositCountEvent', listener);

    this.depositDataService.triggerDepositCount().subscribe();

    // Get member groups
    this.getMemberGroups();

    // Get merchant bank list
    this.getMerchantBankAccountList();
  }

  ngOnDestroy(): void {
    this.echo.disconnect();
  }

  getMerchantBankAccountList() {

    this.merchantBankHttpService.getMerchantBanksAccount().subscribe(res => {
        res.forEach(elm => {
            elm.name = elm.account_number 
                ? `${elm.bank_code} - ${elm.account_name} - ${elm.account_number}`
                : `${elm.bank_code} - ${elm.account_name}`;
        });

        // Emit the API response to the shared ReplaySubject
        this.merchantBankAccounts$.next(res);
    });

    // Transform data for banking accounts
    this.merchantBankAccountsBanking$ = this.merchantBankAccounts$.pipe(
        map(res => {
            const bankingAccounts = res.filter(v => v.is_crypto === 0);
            bankingAccounts.unshift({
                id: 'all',
                name: 'All'
            });
            return bankingAccounts;
        })
    );

    // Transform data for crypto accounts
    this.merchantBankAccountsCrypto$ = this.merchantBankAccounts$.pipe(
        map(res => {
            const cryptoAccounts = res.filter(v => v.is_crypto === 1);
            cryptoAccounts.unshift({
                id: 'all',
                name: 'All'
            });
            return cryptoAccounts;
        })
    );
  }

  getMemberGroups() {
    this.groupHttpService.getGroups().subscribe(res => {
      this.groups = res;
    });
  }
}