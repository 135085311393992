import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AllAffiliates } from '@core/models/all-affiliates.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AdjustGroupDialogComponent } from './dialogs/adjust-group/adjust-group.component';
import { AffiliateInformationComponent } from './dialogs/affiliate-information/affiliate-information.component';
import { SubAffiliateComponent } from './dialogs/sub-affiliate/sub-affiliate.component';
import { AffiliateMemberComponent } from './dialogs/affiliate-member/affiliate-member.component';
import { AllAffiliatesEditDialogComponent } from './dialogs/all-affiliates-edit/all-affiliates-edit.component';
import { AllAffiliatesPasswordDialogComponent } from './dialogs/all-affiliates-password/all-affiliates-password.component';
import { TestFirePostbackLogsComponent } from './dialogs/test-fire-postback-logs/test-fire-postback-logs.component';
import { AffiliateRemarkComponent } from './dialogs/affiliate-remark/affiliate-remark.component';
import { AllAffiliatesDataService } from './services/all-affiliates-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-all-affiliates',
  templateUrl: './all-affiliates.component.html',
  styleUrls: ['./all-affiliates.component.scss']
})
export class AllAffiliatesComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    groups: this.dropdownHttpService.affiliateGroups,
    statuses: this.dropdownHttpService.affiliateStatusesNew,
    partnerNetwork: this.dropdownHttpService.affiliatePartnerNetwork,
  };
  currentContact = '';
  selectedContactType = 'Mobile';
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges = this.transactionHttpService.ranges;
  allAffiliates$: Observable<AllAffiliates[]>;
  messages$ = this.allAffiliatesDataService.messages$;
  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  userPermissions$ = this.store.pipe(select(specialPermissions));
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '10.2';
  default_sort_by = 'id';
  default_sort_order = 'desc';
  sortingConfig = {
    'id': 'desc',
    'parent_username': 'desc',
    'username': 'desc',
    'name': 'desc',
    'currency_code': 'desc',
    'code': 'desc',
    'partner_network_name': 'desc',
    'affiliate_group': 'desc',
    'total_sub_affiliates': 'desc',
    'total_members': 'desc',
    'total_clicks': 'desc',
    'main_wallet': 'desc',
    'deposit_wallet': 'desc',
    'register_date': 'desc',
    'status': 'desc',
    'remarks': 'desc',
    'updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order
  };
  canViewRemarkHistory = true;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canViewAffiliateDialog: boolean;
  canViewSubAffiliates: boolean;
  canViewMemberRegistration: boolean;
  canCreateAffiliate: boolean;
  canEditAffiliate: boolean;
  canSuspendAffiliate: boolean;
  canChangePassword: boolean;
  canAdjustGroup: boolean;
  canShadowLogin: boolean;
  canViewTestFireHistory: boolean;

  constructor(
    public loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private transactionHttpService: TransactionHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private store: Store<AppState>,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.pagination = this.allAffiliatesDataService.pagination;
    this.onSubmit(true);
    this.setCurrencyDropdown();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewAffiliateDialog = appPermissions.all_affiliates_view_affiliate_dialog;
      this.canViewSubAffiliates = appPermissions.view_sub_affiliates;
      this.canViewMemberRegistration = appPermissions.view_member_registration;
      this.canCreateAffiliate = appPermissions.create_affiliate;
      this.canEditAffiliate = appPermissions.edit_affiliate;
      this.canSuspendAffiliate = appPermissions.suspend_affiliate;
      this.canChangePassword = appPermissions.change_password;
      this.canAdjustGroup = appPermissions.adjust_group;
      this.canShadowLogin = appPermissions.shadow_login_affiliate;
      this.canViewTestFireHistory = appPermissions.view_test_fire_history;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.dataLength = 0;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef;
        this.loadingBar.start();
        this.allAffiliates$ = this.allAffiliatesDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.allAffiliatesDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;

        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef;

        return this.allAffiliatesDataService.exportAllAffiliates(`?${this.params}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.messages$ = this.allAffiliatesDataService.messages$;
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    (<HTMLInputElement>document.getElementById('contactRefValue')).value = "";
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.selectedContactType = 'Mobile';
    this.pageSize = 30;
    this.form.patchValue({
      currency_id: 'all',
      username: null,
      name: null,
      code: null,
      partner_network: 'all',
      affiliate_group_id: 'all',
      contact_type: 'Mobile',
      mobile: null,
      email: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      date_type: 'registration_date',
      status: 'all',
      id: null,
      parent: null,
      remarks: null,
      dummy: 1
    })
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.allAffiliates$ = this.allAffiliatesDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.allAffiliatesDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onContactType(label: string) {
    this.selectedContactType = label;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onContact(event: Event) {
    this.currentContact = (event.target as HTMLInputElement).value;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onSuspend(id: number, username: string) {
    Swal.fire({
      title: `Are you sure you want to suspend ${username} ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const data = {
          id: [id],
          status: 2
        }
        this.allAffiliatesDataService.suspend(data).subscribe(() => {
          this.messages$ = this.allAffiliatesDataService.messages$;
        });
      }
    });
  }

  onOpenDialog(mode: string, row?: any) {
    if (row) {
      switch (mode) {
        case 'edit':
          var affiliate = this.allAffiliatesDataService.getById(row.id);
          this.subscription = affiliate.pipe(
            tap((res: any) => {
              if (res) {
                this.openDialogBy(AllAffiliatesEditDialogComponent, { affiliate: res, mode: mode });
              }
            })
          ).subscribe();
          break;
        case 'resetPassword':
          this.openDialogBy(AllAffiliatesPasswordDialogComponent, { affiliate: row });
          break;
        case 'adjustGroup':
          var affiliate = this.allAffiliatesDataService.getById(row.id);
          this.subscription = affiliate.pipe(
            tap((res: any) => {
              if (res) {
                this.openDialogBy(AdjustGroupDialogComponent, { affiliate: res });
              }
            })
          ).subscribe();
          break;
        case 'information':
          var affiliate = this.allAffiliatesDataService.getById(row.id);
          this.subscription = affiliate.pipe(
            tap((res: any) => {
              if (res) {
                this.openDialogBy(AffiliateInformationComponent, { affiliate: res, mode: mode });
              }
            })
          ).subscribe();
          break;
        case 'subAffiliates':
          this.openDialogBy(SubAffiliateComponent, { affiliate: row });
          break;
        case 'affiliateMembers':
          this.openDialogBy(AffiliateMemberComponent, { affiliate: row, mode: mode, dummy: this.form.value.dummy });
          break;
        case 'testFirePostbackLogs':
          this.openDialogBy(TestFirePostbackLogsComponent, { affiliate: row });
          break;
        case 'showRemark':
          this.openDialogBy(AffiliateRemarkComponent, { affiliate: row, mode: mode });
          break;
      }
    } else {
      this.openDialogBy(AllAffiliatesEditDialogComponent, { affiliate: null, mode: mode });
    }
  }

  onShadowLogin(row: any) {
    this.allAffiliatesDataService.shadowLogin(row.id).subscribe(url => {
      if (url !== null) {
        if (!(url).match(/^https?:\/\//i)) {
          url = 'https://' + url;
        }
        window.open(url, "_blank");
      }
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { affiliate?: any, mode?: any, dummy?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'information' || data.mode === 'showRemark' ? '1500px' : '1000px',
      height: data.mode === 'information' ? '80vh' : 'auto',
      data: {
        affiliate: data.affiliate,
        mode: data.mode,
        dummy: data.dummy
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onSubmit();
      }
    });
  }

  private contactChanges() {
    if (this.selectedContactType !== '' || this.selectedContactType !== null) {
      if (this.selectedContactType === 'Mobile') {
        this.form.patchValue({
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
          email: null,
        });
      }
      if (this.selectedContactType === 'Email') {
        this.form.patchValue({
          mobile: null,
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
        });
      }
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl('all'),
      username: new FormControl(null),
      name: new FormControl(null),
      code: new FormControl(null),
      partner_network: new FormControl('all'),
      affiliate_group_id: new FormControl('all'),
      contact_type: new FormControl('Mobile'),
      mobile: new FormControl(null),
      email: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      date_type: new FormControl('registration_date'),
      status: new FormControl('all'),
      id: new FormControl(null),
      parent: new FormControl(null),
      remarks: new FormControl(null),
      dummy: new FormControl(1)
    })
  }

  onChangeDummy($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }

  getUrl(site: string) {
    let url = new URL(site);
    return url.host;
  }

  urlIsValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
