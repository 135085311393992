import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status } from '@core/enums/status.enum';
import { GameProvider } from '@core/models/game-provider.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionCode } from '@core/models/promotion-code.model';
import { PromotionCurrency } from '@core/models/promotion-currency.model';
import { PromotionMember } from '@core/models/promotion-member.model';
import { Promotion } from '@core/models/promotion.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { PromotionSettingHttpService } from '@core/services/promotion-setting-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import * as moment from 'moment-timezone';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { GroupEntityService } from '../../../groups/services/group-entity.service';
import { MemberDataService } from '../../../members/services/member-data.service';
import { MemberEntityService } from '../../../members/services/member-entity.service';
import { PromotionCurrencyComponent } from '../../../promotion-currency/promotion-currency.component';
import { PromotionCurrencyDataService } from '../../../promotion-currency/services/promotion-currency-data.service';
import { PromotionCurrencyEntityService } from '../../../promotion-currency/services/promotion-currency-entity.service';
import { PromotionMemberListComponent } from '../../../promotion-member-list/promotion-member-list.component';
import { PromotionAffiliateComponent } from '../../../promotion-codes/dialogs/promotion-affiliate/promotion-affiliate.component';
import { PromotionCodeDataService } from '../../services/promotion-code-data.service';
import { PromotionCodeEntityService } from '../../services/promotion-code-entity.service';
// import { GameProviderBlacklistComponent } from '../game-provider-blacklist/game-provider-blacklist.component';
import { GameProviderNewBlacklistComponent } from '../game-provider-new-blacklist/game-provider-new-blacklist.component';
import { PromotionMessagesComponent } from '../promotion-messages/promotion-messages.component';
import { AffiliateGroupsDataService } from './../../../affiliate-groups/services/affiliate-groups-data.service';
import { PromotionMemberListDataService } from '../../../promotion-member-list/services/promotion-member-list-data.service';
import { Game } from '@core/models/game.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
import { GroupHttpService } from '@core/services/group-http.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './promotion-code-details.html',
  styleUrls: ['./promotion-code-details.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionCodeDetailsDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;
  status = Status;

  popupDropdownSettings = {};
  popupDropdownList = [];
  popupDropdownListArray = [];
  popupSelectedItems = [];

  affGroupVisibility = [];

  pagination: Pagination;
  currentPage = 1;
  pageSize = 30;
  params = '';
  promotionCurrency$: Observable<PromotionCurrency[]>;
  promotionMember$: Observable<PromotionMember[]>;
  promotionCode$: Observable<Promotion[]>;
  dropdown = {
    affiliateGroup: [],
    eligibleTypes: [],
    targetTypes: [],
    groups: this.dropdownHttpService.groups,
    members: this.dropdownHttpService.members, // TODO: Get members list by Groups
    telemarketers: [],
    gameProviders: this.dropdownHttpService.gameProviders,
    statuses: this.dropdownHttpService.statuses,
    smstemplates: this.messageTemplateDataService.getMessageTemplateList(2, 8), // Get sms templates with type = 2 (SMS) and section = 8 (Promotions)
    messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1, 8), // Get message templates with type = 1 (Message) and section = 8 (Promotions)
    kycTypes: this.dropdownHttpService.kyc_types,
    kycStatuses: this.dropdownHttpService.kyc_statuses
  };

  requiredBonusRate = true;
  requiredDepositWelcome = true;
  disabledInput: boolean = false;

  promotionCodeDropdownSettings = {};
  promotionCodeDropdownList = [];
  promotionCodeSelectedItems = [];

  promoTypesDropdown: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';

  categoriesDropdownList = [];
  categoriesSelectedItems = [];
  categoriesDropdownSettings = {};

  turnoverCategoriesDropdownList = [];
  turnoverCategoriesSelectedItems = [];

  winlossCategoriesDropdownList = [];
  winlossCategoriesSelectedItems = [];

  gameProvidersDropdownList = [];
  gameProvidersDropdownListTurnover = [];
  gameProvidersDropdownListWinover = [];
  gameProviderDropdownTurnoverDisabled: boolean = true;
  gameProviderDropdownWinoverDisabled: boolean = true;
  gameProvidersSelectedItems = [];
  gameProvidersSelectedTurnover = [];
  gameProvidersSelectedWinover = [];
  gameProvidersDropdownSettings = {};

  groupDropdownList = [];
  groupSelectedItems = [];
  affiliateGroupSelectedItems = [];
  groupDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    // lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: false
  };
  dateTimeStack = {
    valid_from: null,
    valid_to: null
  };
  importedMemberList: any;
  isCallingApi = true;
  promotionCurrency: any;
  selectedKycType = null;
  kycStatusDropdownSettings = {};
  kycStatusSelectedItem = [];

  @Input() isReadOnly: boolean = false;

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  messages$ = this.promotionCodeDataService.messages$;

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  pageNumber = 1;
  username = '';
  count = 0;
  memberDropdown = [];
  groupDropdown = []; // gil
  groupsToPatch = []; // gil

  frequencyTypes = [ // TODO: Will change once API collection is available
    { id: 1, name: 'Daily Max', value: 'Daily' },
    { id: 2, name: 'Weekly Max', value: 'Weekly' },
    { id: 3, name: 'Monthly Max', value: 'Monthly' },
  ];
  frequencyWeekdays = Object.keys(moment.weekdays()).map(el => {
    const id = (+el + 1);
    const name = String(moment.weekdays(id)).substring(0, 3);
    return { id, name };
  });
  isFrequencyTypeChanged = false;
  frequencyMonths = this.buildFrequencyMonths();
  frequencySelected = [];
  promotionSubTypes = [];
  frequencyControls = [];
  refreshStatus: boolean;
  hideReccuring = false;
  hideResetFrequency = true;
  buttonLoading = false;
  showRewardsValidity = true;
  // blacklistedGameProviders$ = this.gameProviderHttpService.blacklistedGameProviders$;
  // blacklistedGameProvidersData = null;
  newBlacklistedGameProvidersData = null;
  messageTemplatesSettings = {};
  messageTemplateList = [];
  messageTemplateSelectedItem = [];
  smsTemplateList = [];
  smsTemplateSelectedItem = [];
  showFirstAsterisk: boolean = true;
  showSecondAsterisk: boolean = true;

  allFreeSpinGameProvidersDropdownList : Array<GameProvider>;
  freeSpinGameProvidersDropdownList : Array<GameProvider>;
  freeSpinGameProviderDisabled: boolean = true;
  freeSpinGameProvidersSelectedList : Array<GameProvider>;
  freeSpinGameDropdownList : Array<Game>;
  freeSpinGameDisabled: boolean = true;
  freeSpinGameSelectedList : Array<Game>;
  freeSpingameProvidersDropdownSettings = {};
  freeSpinGameDropdownSettings = {};

  // permissions
  canCreatePromotionCode: boolean;
  canEditPromotionCode: boolean;
  canViewPromotionCurrency: boolean;
  canViewPromotionName: boolean;
  canViewGameProviderBlacklist: boolean;
  canDuplicatePromotionCode: boolean;

  telemarketerDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    maxHeight: 200,
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  normalAccountManagerDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    maxHeight: 200,
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  vipAccountManagerDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    maxHeight: 200,
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  telemarketerDropdownList = [];
  telemarketerSelectedItems = [];
  normalAccountManagerDropdownList = [];
  normalAccountManagerSelectedItems = [];
  vipAccountManagerDropdownList = [];
  vipAccountManagerSelectedItems = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionCode: PromotionCode, mode: string },
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private promotionCurrencyEntityService: PromotionCurrencyEntityService,
    private promotionCurrencyDataService: PromotionCurrencyDataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionCodeDetailsDialogComponent>,
    private memberDataService: MemberDataService,
    private memberService: MemberEntityService,
    private groupService: GroupEntityService, // gil
    private promotionCodeEntityService: PromotionCodeEntityService,
    private promotionCodeDataService: PromotionCodeDataService,
    private datePipe: DatePipe,
    private promotionSettingHttpService: PromotionSettingHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private messageTemplateDataService: MessageTemplateDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private promotionMemberListDataService: PromotionMemberListDataService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private dialogDataService: DialogDataService,
    private groupHttpService: GroupHttpService,
  ) { }

  async ngOnInit() {
    this.formInit();
    this.reload();
    this.setMembers();
    this.setPopups();
    this.pagination = this.promotionCurrencyDataService.pagination;
    this.form.value.frequency.map((item: any) => this.frequencySelected.push(+item));
    this.buildFrequencyMonths();
    await this.setPromoSettings();

    this.promotionCodeDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };

    
    this.kycStatusDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    //AIO-5411 Setting dropdown list for promotion code
    this.promotionCodeDataService.getWithQuery(`?status=1&paginate=false&dropdown=true`).subscribe(res => {
      this.promotionCodeDropdownList = res;
      this.promotionCodeDropdownList.map(function (elm) {
        elm['labelKey'] = elm.code + ' - ' + elm.name;
      });

      if (this.data.mode === 'edit') {
        //Removing promotion code's id from dropdown list during edit mode
        this.promotionCodeDropdownList = this.promotionCodeDropdownList.filter(item => item.id !== this.data.promotionCode.id);
        if (this.data.promotionCode.promo_linked_ids.length > 0) {
          this.data.promotionCode.promo_linked_ids.map(res => {
            //Setting linked promotions from API as selected in the dropdown list
            var item = this.promotionCodeDropdownList.find(v => v.id === res);
            if(item){
              this.promotionCodeSelectedItems.push(item);
            }
            //Removing linked promotions from dropdown list
            //this.promotionCodeDropdownList = this.promotionCodeDropdownList.filter(item => item.id !== res);
          });
        }
      }

      if (this.data.mode === 'duplicate') {
        if (this.data.promotionCode.promo_linked_ids.length > 0) {
          this.data.promotionCode.promo_linked_ids.map(res => {
            var item = this.promotionCodeDropdownList.find(v => v.id === res);
            if(item){
              this.promotionCodeSelectedItems.push(item);
            }
          });
        }
      }
    });

    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {

      // Case 1: Deposit > Welcome
      // Case 2: Free Spin > Welcome
      const recurring = ((this.data.promotionCode.promo_type === 2 && this.data.promotionCode.promo_sub_type === 2) || (this.data.promotionCode.promo_type === 4 && this.data.promotionCode.promo_sub_type === 1)) ? true : false;
      const reset = this.data.promotionCode.recurring === 1 ? false : true;
      this.hideReccuring = recurring;
      this.hideResetFrequency = reset;

      if (this.data.promotionCode.first_deposit != 0) {
        this.isReadOnly = true;
      }
      this.bonusRateValidator(this.data.promotionCode.promo_type, this.data.promotionCode.promo_sub_type);
      this.depositWelcomeValidator(this.data.promotionCode.promo_type, this.data.promotionCode.promo_sub_type);

      if (this.data.mode === 'edit') {
        this.freeSpinGameProviderDisabled = true;
        this.freeSpinGameDisabled = true;
        this.disabledInput = true;
      } else {
        let params = `promotion_id=${this.data.promotionCode.id}`;

          /**
           * Member list should not be duplicated when duplicate promotion
           */
          // Duplicate member list/affiliate list
          // if (+this.data.promotionCode.eligible_types === 2) {
          //   this.promotionMemberListDataService.getAffiliateWithQuery(`?${params}&paginate=0`).pipe(
          //     tap(res => {
          //       const tem_res = [];
          //       res.forEach(function sampleFunc(member) {
          //         var data = {
          //           id: member.id,
          //           username: member.username,
          //           group: member.group,
          //         };
          //         tem_res.push(data);
          //       });
          //       localStorage.setItem("createPromoCodeAffiliates", JSON.stringify(tem_res));
          //     })
          //   ).subscribe();
          // } else {
          //   this.promotionMemberListDataService.getWithQuery(`?${params}&paginate=0`).pipe(
          //     tap(res => {
          //       const tem_res = [];
          //       res.forEach(function sampleFunc(member) {
          //         var data = {
          //           id: member.id,
          //           username: member.username,
          //           group: member.group,
          //         };
          //         tem_res.push(data);
          //       });
          //       localStorage.setItem("createPromoCodeMembers", JSON.stringify(tem_res));
          //     })
          //   ).subscribe();
          // }

          // Duplicate promotion currencies
          this.promotionCurrencyEntityService.getWithQuery(`?${params}`).subscribe(res => {
            let currencies = JSON.parse(sessionStorage.getItem('currencies'));
            let promoCurrencies = res;
            let currencyArr = [];

            promoCurrencies.forEach((currency) => {
              let tempCurrency = {
                ...currency,
                currency_id: currencies.find(x => x.name === currency['currency']).id,
                current_players: 0,
                used_budget: 0.00,
                promo_type: this.data.promotionCode.promo_type
              };
              delete tempCurrency['updated_by'];
              currencyArr.push(tempCurrency);
            });

            localStorage.setItem("createPromoCodeCurrencies", JSON.stringify(currencyArr));
          });

          // Duplicate promotion affiliates
          if (+this.form.value.eligible_types === 1) {
            this.affiliateGroupsDataService.getPromotionVisibilityList(`?${params}&paginate=false`).subscribe(res => {
              let promoAffiliateGroup = res;
              let promoAffGroupAff = [];
  
              promoAffiliateGroup.forEach((affGroup) => {
                let tempAffGroup = {
                  affiliate_group_id: affGroup.affiliate_group_id,
                  affiliate_id: affGroup.affiliate_id,
                  visibility: affGroup.visibility,
                };
                delete tempAffGroup['updated_by'];
                promoAffGroupAff.push(tempAffGroup);
              });
  
              localStorage.setItem("createPromoAffiliateGroups", JSON.stringify(promoAffGroupAff));
            });
          }
        }
    }

    // if (this.data.promotionCode) {
    //   if (Object.keys(this.data.promotionCode.member_ids).length !== 0 && Object.keys(this.data.promotionCode.member_group_ids).length !== 0) {
    //     this.isMemberDisabled = true;
    //     this.isMemberGroupDisabled = false;
    //   } else {
    //     if (Object.keys(this.data.promotionCode.member_ids).length !== 0) {
    //       this.isMemberGroupDisabled = true;
    //     }

    //     if (Object.keys(this.data.promotionCode.member_group_ids).length !== 0) {
    //       this.isMemberDisabled = true;
    //     }
    //   }

    //   if (Object.keys(this.data.promotionCode.affiliate_ids).length !== 0 && Object.keys(this.data.promotionCode.affiliate_group_ids).length !== 0) {
    //     this.isAffiliateDisabled = true;
    //     this.isAffiliateGroupDisabled = false;
    //   } else {
    //     if (Object.keys(this.data.promotionCode.affiliate_ids).length !== 0) {
    //       this.isAffiliateGroupDisabled = true;
    //     }

    //     if (Object.keys(this.data.promotionCode.affiliate_group_ids).length !== 0) {
    //       this.isAffiliateDisabled = true;
    //     }
    //   }
    // }

    this.categoriesDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.gameProvidersDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'labelKey',
      // lazyLoading: true,
      noDataLabel: '',
      maxHeight: 150,
      showCheckbox: false
    };

    this.freeSpingameProvidersDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'labelKey',
      // lazyLoading: true,
      noDataLabel: '',
      maxHeight: 150,
      showCheckbox: false,
      singleSelection: true,
    };

    this.freeSpinGameDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'code',
      labelKey: 'labelKey',
      // lazyLoading: true,
      noDataLabel: '',
      maxHeight: 150,
      showCheckbox: false,
      singleSelection: true,
    }

    this.popupDropdownSettings = {
      autoPosition: false,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };

    this.dropdownHttpService.gameProviders.subscribe(
      async res => {
        this.gameProvidersDropdownList = res;
        this.gameProvidersDropdownListTurnover = res.filter(x => x.supported_target_type === 1 || x.supported_target_type === 2);
        if (this.gameProvidersDropdownListTurnover.length > 0) {
          this.gameProvidersDropdownListTurnover = this.gameProvidersDropdownListTurnover.filter(item => {
            // Check if at least one category name in item.categories exists in category array
            return item.categories.some(cat => this.turnoverCategoriesSelectedItems.some(catItem => catItem.code === cat.code));
          });
        }
        this.gameProvidersDropdownListTurnover.map(function (elm) {
          elm['labelKey'] = elm.code + ' - ' + elm.name;
        });
        this.gameProvidersDropdownListWinover = res.filter(x => x.supported_target_type === 1 || x.supported_target_type === 3);
        if (this.gameProvidersDropdownListWinover.length > 0) {
          this.gameProvidersDropdownListWinover = this.gameProvidersDropdownListWinover.filter(item => {
            // Check if at least one category name in item.categories exists in category array
            return item.categories.some(cat => this.winlossCategoriesSelectedItems.some(catItem => catItem.code === cat.code));
          });
        }
        this.gameProvidersDropdownListWinover.map(function (elm) {
          elm['labelKey'] = elm.code + ' - ' + elm.name;
        });
        if (this.data.promotionCode) {
          if ((this.data.promotionCode.game_provider_ids).length >= 1) {
            this.gameProvidersSelectedItems = this.data.promotionCode.game_provider_ids.map(id => {
              const gameproviderRow = this.gameProvidersDropdownList.find(i => +i.id === id);
              if (gameproviderRow) {
                return {
                  ...gameproviderRow
                }
              }
              
            }).filter(Boolean);

            // Turnover Transfer
            this.gameProvidersSelectedTurnover = (this.getTargetTypeProviders(1))?.map(id => {
              const gameproviderRow = this.gameProvidersDropdownListTurnover.find(i => +i.id === id);
              if (gameproviderRow) {
                return {
                  ...gameproviderRow
                };
              }
            }).filter(Boolean);

            // Winover Transfer
            this.gameProvidersSelectedWinover = (this.getTargetTypeProviders(2))?.map(id => {
              const gameproviderRow = this.gameProvidersDropdownListWinover.find(i => +i.id === id);
              if (gameproviderRow) {
                return {
                  ...gameproviderRow
                };
              }
            }).filter(Boolean);
          }

          // Free Spin
          if (this.data.promotionCode.promo_type == 4) {
            this.freeSpinGameProvidersDropdownList = await this.gameProviderHttpService.getFreeSpinGameProvider().toPromise();
            if (this.data.promotionCode.promo_sub_type == 2) {
              this.freeSpinGameProvidersDropdownList = [this.freeSpinGameProvidersDropdownList.find(x => x.code === 'PP')];
            }
            this.freeSpinGameProvidersDropdownList.map(function (elm) {
              elm['labelKey'] = elm.code + ' - ' + elm.name;
            });
            this.data.mode == 'edit' ? this.freeSpinGameProviderDisabled = true : this.freeSpinGameProviderDisabled = false;
            this.freeSpinGameProvidersSelectedList = this.freeSpinGameProvidersDropdownList.find(x => x.id == this.data.promotionCode.free_spin_game_provider_id)
              ? [this.freeSpinGameProvidersDropdownList.find(x => x.id == this.data.promotionCode.free_spin_game_provider_id)]
              : [];
            if (this.freeSpinGameProvidersSelectedList.length > 0) {
              this.freeSpinGameDropdownList = await this.gameProviderHttpService.getFreeSpinGameList(this.freeSpinGameProvidersSelectedList[0].code).toPromise();
              this.freeSpinGameDropdownList.map(function (elm) {
                elm['labelKey'] = elm.code + ' - ' + elm.name;
              });
              this.data.mode == 'edit' ? this.freeSpinGameDisabled = true : this.freeSpinGameDisabled = false;
              this.freeSpinGameSelectedList = this.freeSpinGameDropdownList.find(x => x.code == this.data.promotionCode.free_spin_game_code)
                ? [this.freeSpinGameDropdownList.find(x => x.code == this.data.promotionCode.free_spin_game_code)]
                : [];
            }
          }
        }
      }
    );

    this.setMessagesDropdown();
    this.formValueChanges();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreatePromotionCode = appPermissions.create_promotion_code;
      this.canEditPromotionCode = appPermissions.edit_promotion_code;
      this.canViewPromotionCurrency = appPermissions.view_promotion_currency;
      this.canViewPromotionName = appPermissions.view_promotion_name;
      this.canViewGameProviderBlacklist = appPermissions.view_game_provider_blacklist;
      this.canDuplicatePromotionCode = appPermissions.duplicate_promotion_code;
    });

    this.subscriptions.add(apSub);

    if (this.data.mode !== 'edit') {
      setTimeout(() => {
        this.isCallingApi = false;
      }, 1000);
    }
    
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'valid_from'),
      this.buildDatePicker(1, 'valid_to')
    ]).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onFrequencyType();
    this.onRefresh();
    this.onFocusField();
    localStorage.removeItem('createPromoAffiliateGroups');
    localStorage.removeItem('createPromoCodeCurrencies');
    localStorage.removeItem('createPromoCodeMembers');
    localStorage.removeItem('importedMemberListName');
    localStorage.removeItem('updatePromoCodeMembers');
    localStorage.removeItem('createPromoCodeAffiliates');
    localStorage.removeItem('updatePromoCodeAffiliates');
    localStorage.removeItem('createPromoCodeCurrencies');
  }

  onScrollToEnd() {
    if (this.pageNumber >= this.pagination.last_page) {
      return;
    }
    this.pageNumber++;
    this.setMembers();
  }

  onSearchMember(event?: Event) {
    if (this.username !== (event.target as HTMLSelectElement).value) {
      this.memberDropdown = [];
      this.pageNumber = 1;
      this.username = (event.target as HTMLSelectElement).value;
      this.setMembers();
    }
  }

  onSelectEligibleTypes(type: Event) {
    if (+(type.target as HTMLSelectElement).value === 2) {
      this.setAffiliateGroup();
    } else if (+(type.target as HTMLSelectElement).value === 4) {
      this.setTelemarketers();
      this.setNormalAccountManagers();
      this.setVipAccountManagers();
    } else {
      this.setMemberGroup();
    }
    this.form.patchValue({
      member_group_ids: null,
      member_ids: null,
      affiliate_group_ids: null,
      affiliate_ids: null,
      telemarketer_ids: [],
      normal_account_manager_ids: [],
      vip_account_manager_ids: []
    });
    this.importedMemberList = undefined;
    this.groupSelectedItems = [];
    this.affiliateGroupSelectedItems = [];
    this.telemarketerSelectedItems = [];
    this.normalAccountManagerSelectedItems = [];
    this.vipAccountManagerSelectedItems = [];
    localStorage.removeItem('createPromoAffiliateGroups');
    localStorage.removeItem('createPromoCodeMembers');
    localStorage.removeItem('importedMemberListName');
    localStorage.removeItem('updatePromoCodeMembers');
    localStorage.removeItem('createPromoCodeAffiliates');
    localStorage.removeItem('updatePromoCodeAffiliates');
    this.groupDropdownSettings = {
      ...this.groupDropdownSettings,
      disabled: false
    };
  }

  onCloseDialog(event?: Event) {
    this.removeLocalStorage();
    this.dialogRef.close();
    this.promotionCodeDataService.newBlacklistedGameProviders$.next(null);
  }

  onOpenDialog(type?: string, promotionCurrencyId?: number) {
    // if (promotionCurrencyId) {
    //   const promotionCurr = this.promotionCurrencyEntityService.getByKey(this.data.promotionCode.id);
    //   this.subscription = promotionCurr.pipe(
    //     tap( (res) => {
    //       this.openDialogBy(PromotionCurrencyComponent, { promotionCurrency: res, promotionType: this.form.get('promo_type').value });
    //   })
    //   )
    //   .subscribe();
    // } else {
    this.openDialogBy(PromotionCurrencyComponent, { promotionCurrency: null, promotionCode: this.data.promotionCode, promotionType: +this.form.get('promo_type').value, promoSubType: +this.form.value.promo_sub_type });
    // }
  }

  onPersonListDialog() {
    this.openPersonDialogBy(PromotionMemberListComponent, { promotionCode: this.data.promotionCode });
  }

  onPromotionAffiliateDialog() {
    const dialogRef = this.dialog.open(PromotionAffiliateComponent, {
      width: '1300px',
      data: {
        mode: this.data.mode,
        promotion: this.data.promotionCode,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getAffGroupVisibility();
    });
  }

  onPromotionNamesDialog() {
    this.dialog.open(PromotionMessagesComponent, {
      width: '1300px',
      data: {
        promotionId: this.data.promotionCode.id
      }
    });
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.promotionCurrency$ = this.promotionCurrencyEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.currentPage = page;
        this.pagination = this.promotionCurrencyDataService.pagination;

      })
    );
  }

  onNextPage() {
    if (this.currentPage >= this.pagination.last_page) {
      return;
    }
    this.currentPage++;
    this.onViewPageBy(this.currentPage);
  }

  onPerPage(size: Event) {
    this.currentPage = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.currentPage, this.pageSize, this.params);
  }

  onPromotionType(event?: Event) {
    const value = +(event.target as HTMLSelectElement).value;
    this.form.patchValue({
      promo_type: value,
    });
    
    this.promotionSubType(this.promoTypesDropdown, value);
    localStorage.removeItem('createPromoCodeCurrencies');

    this.bonusRateValidator(this.form.value.promo_type, this.form.value.promo_sub_type);
    // Case 1: Deposit
    // Case 2: Free Spin
    if (+(event.target as HTMLSelectElement).value === 2 || +(event.target as HTMLSelectElement).value === 4) {
      this.hideReccuring = true;
      this.hideResetFrequency = true;
      this.form.patchValue({
        promo_sub_type: (+(event.target as HTMLSelectElement).value === 4) ? 1 : 2,
        recurring: 0,
      });
      this.setResetPrequency('unset');
      this.depositWelcomeValidator(this.form.value.promo_type, this.form.value.promo_sub_type);
    } else if (+(event.target as HTMLSelectElement).value === 3) {
    } else {
      this.form.patchValue({
        promo_sub_type: (this.data.mode === 'edit' || this.data.mode === 'duplicate') ? this.data.promotionCode.promo_sub_type : null,
        recurring: (this.data.mode === 'edit' || this.data.mode === 'duplicate') ? this.data.promotionCode.recurring : 0,
      });
      this.setResetPrequency('set');
      this.hideReccuring = false;
    }

    // Free Spin
    if (value == 4) {
      this.gameProviderHttpService.getFreeSpinGameProvider().subscribe(res => {
        this.freeSpinGameProvidersDropdownList = res
        this.allFreeSpinGameProvidersDropdownList = res;
        this.freeSpinGameProvidersDropdownList.map(function (elm) {
          elm['labelKey'] = elm.code + ' - ' + elm.name;
        });
        this.freeSpinGameProviderDisabled = false;
      })
    }
  }

  onKycType(event?: Event) {
    const value = +(event.target as HTMLSelectElement).value;
    this.selectedKycType = value;

    if (this.selectedKycType == 1) { // Kyc Status
      this.form.get('kyc_status').setValidators(Validators.required);
      this.form.patchValue({ 
        requires_email: 0,
        requires_mobile: 0,
        requires_dob: 0,
        requires_fullname: 0 
      });
    }
    else {  // Manual
      this.form.get('kyc_status').clearValidators();
      this.form.patchValue({ 
        kyc_status: [],
      });
      this.kycStatusSelectedItem = [];
    }

    this.form.get('kyc_status').updateValueAndValidity();
    this.cdr.detectChanges();
  }

  onPromotionSubType(event?: Event) {
    this.freeSpinGameProvidersSelectedList = [];
    this.freeSpinGameSelectedList = [];
    localStorage.removeItem('createPromoCodeCurrencies');
    this.bonusRateValidator(this.form.value.promo_type, this.form.value.promo_sub_type);
    this.depositWelcomeValidator(this.form.value.promo_type, this.form.value.promo_sub_type);
    if ((+(event.target as HTMLSelectElement).value === 1 && this.form.value.promo_type !== 4 ) || (+(event.target as HTMLSelectElement).value === 2 && this.form.value.promo_type === 4)) {
      this.hideReccuring = false;
      this.setResetPrequency('set');
      if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
        this.form.patchValue({
          recurring: this.data.promotionCode.recurring
        });
        this.hideResetFrequency = this.data.promotionCode.recurring > 0 ? false : true;
      }
    } else {
      this.hideReccuring = true;
      this.hideResetFrequency = true;
      this.setResetPrequency('unset');
      this.form.patchValue({
        recurring: 0,
      });
    }

    if (this.form.value.promo_sub_type == 2) {
      this.freeSpinGameProvidersDropdownList = [this.freeSpinGameProvidersDropdownList.find(x => x.code === 'PP')];
    } else {
      this.freeSpinGameProvidersDropdownList = this.allFreeSpinGameProvidersDropdownList;
    }
  }

  onSetRecurring(event?: Event) {
    if (+(event.target as HTMLSelectElement).value === 1) {
      this.hideResetFrequency = false;
      this.setResetPrequency('set');
    } else {
      this.hideResetFrequency = true;
      this.setResetPrequency('unset');
    }
  }

  onFrequencyType(event?: Event) {
    this.frequencySelected = [];
    this.isFrequencyTypeChanged = true;
  }

  onFrequency(event: Event) {
    const control = (event.target as HTMLInputElement);
    const controlValue = +control.value;
    if (control.checked) {
      this.frequencySelected.push(controlValue);
    } else {
      this.frequencySelected.splice(this.frequencySelected.indexOf(controlValue), 1);
    }
    this.form.patchValue({
      frequency: this.frequencySelected
    });
  }

  onResetPrequency(event: Event) {
    this.form.patchValue({
      reset_dayMonthly: (this.data.mode === 'edit' || this.data.mode === 'duplicate') && this.data.promotionCode.reset_frequency === 3 ? this.data.promotionCode.reset_day : null,
      reset_dayWeekly: (this.data.mode === 'edit' || this.data.mode === 'duplicate') && this.data.promotionCode.reset_frequency === 2 ? this.data.promotionCode.reset_day : null,
    });
  }

  frequencyCheckboHandler(id: number, items: any[]) {
    if (!this.isFrequencyTypeChanged && items.length > 0) {
      for (let i = 0; i <= items.length; i++) {
        if (id === +items[i]) {
          return true;
        }
      }
    }
  }

  async onSave(promotionCode: PromotionCode, mode?: string) {
    if ((localStorage.getItem('createPromoCodeCurrencies') && JSON.parse(localStorage.getItem('createPromoCodeCurrencies')).length > 0 && (mode === 'create' || mode === 'duplicate')) ||
    (this.promotionCurrency && this.promotionCurrency.length > 0 && mode === 'edit')) {
      let searchActivePromotionCurrency = mode === 'edit' ? this.promotionCurrency.filter(x => x.status == 1) : JSON.parse(localStorage.getItem('createPromoCodeCurrencies')).filter(x => x.status == 1);
      if (searchActivePromotionCurrency.length > 0) {
        this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
        this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
        // this.onBlacklistedGameProviders();
        // const blacklistedGameProvidersData = this.blacklistedGameProvidersData;
        await this.onNewBlacklistedGameProviders();
        const newBlacklistedGameProvidersData = this.newBlacklistedGameProvidersData;
        const createPromoCodeCurrencies = localStorage.getItem('createPromoCodeCurrencies');
        const createPromoCodeMembers = localStorage.getItem('createPromoCodeMembers');
        const createPromoCodeAffiliates = localStorage.getItem('createPromoCodeAffiliates');
        const createPromoAffiliateGroups = localStorage.getItem('createPromoAffiliateGroups');
        
        this.buttonLoading = true;
        let data = {
          id: promotionCode ? promotionCode.id : null,
          ...this.form.value,
          valid_from: this.isValidDate(this.form.value.valid_from) ? moment(this.form.value.valid_from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          valid_to: this.isValidDate(this.form.value.valid_to) ? moment(this.form.value.valid_to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
          reset_day: this.form.value.reset_dayMonthly === null ? this.form.value.reset_dayWeekly : this.form.value.reset_dayMonthly,
          last_deposit: this.form.value.last_deposit,
          first_deposit: this.form.value.first_deposit === 0 || this.form.value.first_deposit === false || this.form.value.first_deposit === null ? 0 : 1,
          reset_frequency: this.form.value.reset_frequency === 0 ? null : this.form.value.reset_frequency,
          message_template_id: this.form.value.message_template_id === null ? (this.data.mode === 'edit' ? 0 : null) : this.form.value.message_template_id,
          message_template_sms_id: this.form.value.message_template_sms_id === null ? (this.data.mode === 'edit' ? 0 : null) : this.form.value.message_template_sms_id,
          kyc_basic: this.form.value.kyc_status.includes(1) ? true : false,
          kyc_advanced: this.form.value.kyc_status.includes(2) ? true : false,
          kyc_pro: this.form.value.kyc_status.includes(3) ? true : false,
          requires_email: this.selectedKycType === 2 ? this.form.value.requires_email : false,
          requires_mobile: this.selectedKycType === 2 ? this.form.value.requires_mobile : false,
          requires_dob: this.selectedKycType === 2 ? this.form.value.requires_dob : false,
          requires_fullname: this.selectedKycType === 2 ? this.form.value.requires_fullname : false,
          visible_by_affiliate: +this.form.value.eligible_types == 1 ? this.form.value.visible_by_affiliate : false,
          // ...blacklistedGameProvidersData,
          black_list_sub_categories: newBlacklistedGameProvidersData ? [...newBlacklistedGameProvidersData] : [],
          // Pre-fill
          target: [{
            type: this.form.value.target[0].type, // Turnover Transfer
            multiplier: this.form.value.target[0].multiplier,
            game_provider_ids: this.gameProvidersSelectedTurnover?.map(i => i.id)
          }, {
            type: this.form.value.target[1].type, // Winover Transfer
            multiplier: this.form.value.target[1].multiplier,
            game_provider_ids: this.gameProvidersSelectedWinover?.map(i => i.id)
          }
          ],

          dialog_popup_list: this.getPopupSelectedItems()
          // eligible_types: 1 // fixed for member for now  TEMPORARY DISABLE
        };

        delete data['dialog_popups'];
        //run more than 1 time as the foreach loop will end earlier even though there is still null type inside the array
        // 1.0 Turnover | Winover payload
        for (let i = 0; i < 4; i++) {
          (data.target as any[]).forEach((row: any, index: number) => {
            if ((row.game_provider_ids === null || row.game_provider_ids === undefined) || row.game_provider_ids.length <= 0 || row.type === null) {
              return (data.target as any[]).splice(index, 1);
            }
          });
        }
        Object.keys(data).forEach((key) => (data[key] == null || key === 'reset_dayMonthly' || key === 'reset_dayWeekly' || key === 'kyc_type' || key === 'kyc_status') && delete data[key]);
        switch (mode) {
          case 'edit':
            delete data['member_ids'];
            delete data['affiliate_ids'];

            this.subscription = this.promotionCodeEntityService.update(data).pipe(
              tap((res: any) => {
                this.messages$.next([...res.message]);
                this.buttonLoading = false;
                this.removeLocalStorage();
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe();
            break;
          case 'create':
          case 'duplicate':
            if (createPromoCodeCurrencies !== null) {
              data = {
                ...data,
                promotion_currency: JSON.parse(createPromoCodeCurrencies)
              };
            }
            if (createPromoCodeMembers !== null) {
              var member_ids = [];
              var memberArray = JSON.parse(createPromoCodeMembers);
              memberArray.forEach(function sampleFunc(member) {
                member_ids.push(member.id);
              });
              data = {
                ...data,
                member_ids: member_ids
              };
            }
            if (createPromoCodeAffiliates !== null) {
              var affiliate_ids = [];
              var affiliateArray = JSON.parse(createPromoCodeAffiliates);
              affiliateArray.forEach(function sampleFunc(member) {
                affiliate_ids.push(member.id);
              });
              data = {
                ...data,
                affiliate_ids: affiliate_ids
              };
            }
            if (createPromoAffiliateGroups !== null) {
              data = {
                ...data,
                promotion_affiliate_group: JSON.parse(createPromoAffiliateGroups).slice().reverse()
              };
            }

            this.subscription = forkJoin([
              this.promotionCodeDataService.add(data).pipe(
                tap((res: any) => {
                  this.form.setErrors(null);
                  if (this.importedMemberList !== undefined) {
                    const formData = new FormData();
                    formData.append('files', this.importedMemberList);
                    formData.append('promotion_id', res.id);
                    this.promotionCodeDataService.importMemberList(formData, 'add').pipe(
                      tap((_) => {
                        this.buttonLoading = false;
                        this.removeLocalStorage();
                      }),
                      catchError((error) => {
                        this.buttonLoading = false;
                        throw error;
                      })
                    ).subscribe();
                  } else {
                    this.buttonLoading = false;
                  }
                }),
                catchError((error) => {
                  this.buttonLoading = false;
                  this.form.setErrors(null);
                  throw error;
                })
              ),
              this.promotionCodeDataService.messages$
            ]).subscribe();
            break;
        }
        this.refreshStatus = true;
      }
      else {
        Swal.fire({
          title: 'System Message',
          text: 'Promotion currency configuration is required. Please setup before proceeding.',
          icon: 'error',
        }).then((result) => {
          if (result) {
            this.onOpenDialog();
          }
        });
      }
    }
    else {
      Swal.fire({
        title: 'System Message',
        text: 'Promotion currency configuration is required. Please setup before proceeding.',
        icon: 'error',
      }).then((result) => {
        if (result) {
          this.onOpenDialog();
        }
      });
    }
  }

  onRefresh() {
    this.gameProviderHttpService.blacklistedGameProviders$.next(null);
    this.gameProviderHttpService.selectedSubCategories$.next(null);
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first.nativeElement.focus()
      )).subscribe();
  }

  // onBlacklistDialog(selectedProviders: GameProvider[]) {
  //   const data = {
  //     providers: selectedProviders,
  //     blacklist_sub_categories: (this.data.mode === 'edit' || this.data.mode === 'duplicate') ? this.data.promotionCode.blacklist_sub_categories : null
  //   };

  //   if (this.gameProvidersSelectedItems.length >= 1) {
  //     this.openDialogBy(GameProviderBlacklistComponent, data);
  //   }
  // }

  onNewBlacklistDialog(selectedProviders: GameProvider[], turnoverCategories: Categories[], winlossCategories: Categories[]) {
    const combinedArray = [...turnoverCategories, ...winlossCategories];
    // Create a Set to store distinct names
    const distinctCategoriesSet = new Set();
    // Iterate through the combinedArray and add unique names to the Set
    combinedArray.forEach(item => distinctCategoriesSet.add(item.name));
    // Convert the Set back to an array to get the final result
    const distinctCategories = Array.from(distinctCategoriesSet) as string[];
    const data = {
      providers: selectedProviders,
      promotion_id: this.data.mode === 'edit' ? this.data.promotionCode.id : null,
      categories: distinctCategories
    };

    if (this.gameProvidersSelectedItems.length >= 1) {
      this.openDialogBy(GameProviderNewBlacklistComponent, data);
    }
  }

  // onBlacklistedGameProviders() {
  //   this.gameProviderHttpService.blacklistedGameProviders$.subscribe(res => {
  //     let data: { [k: number]: any[] } = {};
  //     if (res?.game_providers !== null && (res?.game_providers)?.length >= 1) {
  //       (res?.game_providers.filter((row: any) => row.blacklist_sub_categories !== null)).forEach((item: any) => {
  //         data = {
  //           ...data,
  //           [item.game_provider_id]: item.blacklist_sub_categories
  //         };
  //       });
  //     } else {
  //       if (Object.keys(data).length == 0 && this.data.mode === 'duplicate') {
  //         (this.data.promotionCode.blacklist_sub_categories.filter((row: any) => row.sub_category_name !== null)).forEach((item: any) => {
  //           data = {
  //             ...data,
  //             [item.game_provider_id]: item.sub_category_name
  //           };
  //         });
  //       }
  //     }

  //     if (Object.keys(data)) {
  //       const finalData = {
  //         blacklist_sub_categories: data
  //       }
  //       this.blacklistedGameProvidersData = finalData;
  //     }
  //   });
  // }

  async onNewBlacklistedGameProviders() {
    return new Promise<void>((resolve) => {
      this.promotionCodeDataService.newBlacklistedGameProviders$.subscribe(res => {
        this.newBlacklistedGameProvidersData = res;
        resolve();
      });
    });
  }

  // Validate Dropdown
  onGameProviderChanged(index: number, event: any[]) {

    // 1: Turnover
    if (index === 0) {
      this.gameProvidersSelectedTurnover = event;
    }

    // 2: Winover
    if (index === 1) {
      this.gameProvidersSelectedWinover = event;
    }
    // Blacklist selections
    if (Array.isArray(this.gameProvidersSelectedTurnover) && Array.isArray(this.gameProvidersSelectedWinover)) {
      this.gameProvidersSelectedItems = [...this.getUniqueListBy([...this.gameProvidersSelectedTurnover, ...this.gameProvidersSelectedWinover], 'id')];
    } else {
      if (this.form.value.target[0]?.game_provider_ids?.length >= 1) {
        this.gameProvidersSelectedItems = this.getUniqueListBy([...this.gameProvidersSelectedItems, ...this.gameProvidersSelectedTurnover], 'id');
      }
      if (this.form.value.target[1]?.game_provider_ids?.length >= 1) {
        this.gameProvidersSelectedItems = this.getUniqueListBy([...this.gameProvidersSelectedItems, ...this.gameProvidersSelectedWinover], 'id');
      }
      // Reset when both dropdown is null
      if (
        this.form.value.target[0].game_provider_ids.length <= 0 &&
        this.form.value.target[1].game_provider_ids.length <= 0
      ) {

        this.gameProvidersSelectedItems = [];

        if (this.gameProvidersSelectedTurnover?.length >= 1) {
          this.gameProvidersSelectedItems = [...this.gameProvidersSelectedItems, ...this.gameProvidersSelectedTurnover];
        }
        if (this.gameProvidersSelectedWinover?.length >= 1) {
          this.gameProvidersSelectedItems = [...this.gameProvidersSelectedItems, ...this.gameProvidersSelectedWinover];
        }
      }
    }

    // Narrow selections
    // if (index === 0 && this.gameProvidersSelectedTurnover.length >= 1) {
    //   this.gameProvidersDropdownListWinover = this.gameProvidersDropdownList.filter(this.narrowGameProviderSelection(this.gameProvidersSelectedItems));
    // }
    // if (index === 1 && this.gameProvidersSelectedWinover.length >= 1) {
    //   this.gameProvidersDropdownListTurnover = this.gameProvidersDropdownList.filter(this.narrowGameProviderSelection(this.gameProvidersSelectedItems));
    // }

    // Game providers selection
    // if (
    //   (this.gameProvidersSelectedTurnover?.length <= 0 || this.gameProvidersSelectedTurnover?.length === undefined) &&
    //   (this.gameProvidersSelectedWinover?.length <= 0 || this.gameProvidersSelectedWinover?.length === undefined)
    // ) {
    //   this.gameProvidersDropdownListTurnover = this.gameProvidersDropdownList;
    //   this.gameProvidersDropdownListWinover = this.gameProvidersDropdownList;
    // }

    // GameProviderIDs validation
    if (this.gameProvidersSelectedItems?.length >= 1 && (this.data.mode === 'edit' || this.data.mode === 'duplicate')) {
      (this.form.controls['game_provider_ids'] as FormControl).setErrors(null);
    }

  }

  formValueChanges(event?: any) {

    if (event !== true) {
      this.form.valueChanges.subscribe(res => {
        // Turnover | Winover sections
        const targetControls = (this.form.get('target') as FormArray).controls;
        targetControls.forEach((item, index: number) => {

          if (this.gameProvidersSelectedItems.length >= 1) {
            this.form.controls['game_provider_ids'].setErrors(null);
          }
          (this.form.controls['target'] as FormArray)[index]?.game_provider_ids.setErrors(null); // Explicit
        });

      });
    }

    // Narrow selections
    // if (this.data.mode === 'edit' && event === true) {
    //   if (this.gameProvidersSelectedTurnover?.length >= 1) {
    //     this.gameProvidersDropdownListWinover = this.gameProvidersDropdownList.filter(this.narrowGameProviderSelection(this.gameProvidersSelectedItems));
    //   }
    //   if (this.gameProvidersSelectedWinover?.length >= 1) {
    //     this.gameProvidersDropdownListTurnover = this.gameProvidersDropdownList.filter(this.narrowGameProviderSelection(this.gameProvidersSelectedItems));
    //   }

    // }

  }

  setMessagesDropdown() {
    // Message Templates Dropdown Settings
    this.messageTemplatesSettings = {
      maxHeight: 200,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'code',
      showCheckbox: false
    };
    // Dropdown list for SMS templates
    this.dropdown.smstemplates.subscribe(res => {
      this.smsTemplateList = res;
      if ((this.data.mode === 'edit' || this.data.mode === 'duplicate') && this.data.promotionCode.message_template_sms_id !== 0) {
        this.smsTemplateSelectedItem = [this.smsTemplateList.find(x => x.id === this.data.promotionCode.message_template_sms_id) ? this.smsTemplateList.find(x => x.id === this.data.promotionCode.message_template_sms_id) : []];
      }
    });
    // Dropdown list for Message templates
    this.dropdown.messagetemplates.subscribe(res => {
      this.messageTemplateList = res;
      if ((this.data.mode === 'edit' || this.data.mode === 'duplicate') && this.data.promotionCode.message_template_id !== 0) {
        this.messageTemplateSelectedItem = [this.messageTemplateList.find(x => x.id === this.data.promotionCode.message_template_id) ? this.messageTemplateList.find(x => x.id === this.data.promotionCode.message_template_id) : []];
      }
    });
  }

  onChangeTarget(index: number) {
    let target = this.form.get('target'), value = target.get(`${index}`).value, otherIndex = +!index, otherValue = target.get(`${otherIndex}`).value;
    if (value.type == '') {
      value.game_provider_ids = [];
      value.multiplier = null;
      index == 0 ? this.gameProvidersSelectedTurnover = [] : this.gameProvidersSelectedWinover = [];
      this.form.get(`target.${index}`).patchValue(value);
    }
    if ((value.type == '' && otherValue.type == '') || (value.type != '' && otherValue.type != '')) {
      target.get(`${index}`).get('type').setValidators(Validators.required);
      target.get(`${index}`).get('multiplier').setValidators(Validators.required);
      target.get(`${index}`).get('type').updateValueAndValidity();
      target.get(`${index}`).get('multiplier').updateValueAndValidity();

      target.get(`${otherIndex}`).get('type').setValidators(Validators.required);
      target.get(`${otherIndex}`).get('multiplier').setValidators(Validators.required);
      target.get(`${otherIndex}`).get('type').updateValueAndValidity();
      target.get(`${otherIndex}`).get('multiplier').updateValueAndValidity();

      this.showFirstAsterisk = true;
      this.showSecondAsterisk = true;

    } else if (value.type != '') {
      target.get(`${otherIndex}`).get('type').clearValidators();
      target.get(`${otherIndex}`).get('multiplier').clearValidators();
      target.get(`${otherIndex}`).get('type').updateValueAndValidity();
      target.get(`${otherIndex}`).get('multiplier').updateValueAndValidity();

      if(index == 0) {
        this.showFirstAsterisk = true;
        this.showSecondAsterisk = false;
      }
      else {
        this.showFirstAsterisk = false;
        this.showSecondAsterisk = true;
      }

    } else if (otherValue.type != '') {
      target.get(`${index}`).get('type').clearValidators();
      target.get(`${index}`).get('multiplier').clearValidators();
      target.get(`${index}`).get('type').updateValueAndValidity();
      target.get(`${index}`).get('multiplier').updateValueAndValidity();

      if(otherIndex == 0) {
        this.showFirstAsterisk = true;
        this.showSecondAsterisk = false;
      }
      else {
        this.showFirstAsterisk = false;
        this.showSecondAsterisk = true;
      }
      
    }
    // let value = this.form.get(`target.${index}`).value;
    // if (value.type == '') {
    //   value.game_provider_ids = [];
    //   value.multiplier = null;
    //   index == 0 ? this.gameProvidersSelectedTurnover = [] : this.gameProvidersSelectedWinover = [];
    //   this.form.get(`target.${index}`).patchValue(value);
    // }
  }

  private setAffiliateGroup() {
    if (this.dropdown.affiliateGroup.length === 0) {
      this.affiliateGroupsDataService.getWithQuery(`?status=1&paginate=false&dropdown=true`).subscribe(res => {
        this.dropdown.affiliateGroup = res;
        if (this.data.promotionCode) {
          Object.values(this.data.promotionCode.affiliate_group_ids).forEach((value) => {
            this.affiliateGroupSelectedItems.push(res.find(v => v.id === value));
          });
        }
      });

    }
  }

  private setMemberGroup() {
    if (this.groupDropdownList.length === 0) {
      this.groupService.getWithQuery(`?status=1&paginate=false&username=${this.username}`).subscribe(
        res => {
          this.groupDropdownList = res;
          if (this.data.promotionCode) {
            Object.values(this.data.promotionCode.member_group_ids).forEach((value) => {
              this.groupSelectedItems.push(res.find(v => v.id === value));
            });
          }
        }
      );
    }
  }

  private setTelemarketers() {
    if (this.telemarketerDropdownList.length === 0) {
      this.dropdownHttpService.telemarketer.subscribe(res => {
        this.telemarketerDropdownList = res;
          if (this.data.promotionCode) {
            this.data.promotionCode.telemarketer_ids.forEach((telemarketer) => {
              this.telemarketerSelectedItems.push(res.find(v => v.id === telemarketer.id));
            });
          }
      });
    }
  }

  private setNormalAccountManagers() {
    if (this.normalAccountManagerDropdownList.length === 0) {
      this.dropdownHttpService.normalAccountManager.subscribe(res => {
        this.normalAccountManagerDropdownList = res;
          if (this.data.promotionCode) {
            this.data.promotionCode.normal_account_manager_ids.forEach((normalAccountManager) => {
              this.normalAccountManagerSelectedItems.push(res.find(v => v.id === normalAccountManager.id));
            });
          }
      });
    }
  }

  private setVipAccountManagers() {
    if (this.vipAccountManagerDropdownList.length === 0) {
      this.dropdownHttpService.vipAccountManager.subscribe(res => {
        this.vipAccountManagerDropdownList = res;
          if (this.data.promotionCode) {
            this.data.promotionCode.vip_account_manager_ids.forEach((vipAccountManager) => {
              this.vipAccountManagerSelectedItems.push(res.find(v => v.id === vipAccountManager.id));
            });
          }
      });
    }
  }

  private bonusRateValidator(promoType: number, promoSubType: number) {
    this.requiredBonusRate = +promoType === 3 && (+promoSubType === 1 || +promoSubType === 3) ? false : true;
    if (this.requiredBonusRate) {
      this.form.get('bonus_rate').setValidators([Validators.required, Validators.min(0), Validators.pattern(/^\d+(\.\d{1,2})?$/)]);
    } else {
      this.form.get('bonus_rate').clearValidators();
      this.form.patchValue({ bonus_rate: null });
    }
    this.form.get('bonus_rate').updateValueAndValidity();
  }

  private depositWelcomeValidator(promoType: number, promoSubType: number) {
    this.requiredDepositWelcome = +promoType === 2 && +promoSubType === 2 ? false : true;
    if (this.requiredDepositWelcome) {
      this.form.get('daily_max').setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.form.patchValue({ daily_max: null });
      this.form.get('daily_max').clearValidators();
    }
    this.form.get('daily_max').updateValueAndValidity();
  }

  private getUniqueListBy(stack: any[], key: string) {
    return [...new Map(stack.map(item => [item[key], item])).values()];
  }

  private narrowGameProviderSelection(otherArray: any[]) {
    return function (current: any) {
      return otherArray.filter(function (other: any) {
        return +other.id === +current.id && +other.id === +current.id
      }).length == 0;
    }
  }

  // 1: Turnover  2: Winover
  private getTargetTypeProviders(typeId: 1 | 2) {
    if (typeId === 1) {
      var selectedGameProvider = (this.data.promotionCode.target.find(i => i.type === 1) as any)?.game_provider_ids;

      if (selectedGameProvider === undefined || selectedGameProvider === null) {
        selectedGameProvider = (this.data.promotionCode.target.find(i => i.type === 3) as any)?.game_provider_ids;
      }
    } else if (typeId === 2) {
      var selectedGameProvider = (this.data.promotionCode.target.find(i => i.type === 2) as any)?.game_provider_ids;

      if (selectedGameProvider === undefined || selectedGameProvider === null) {
        selectedGameProvider = (this.data.promotionCode.target.find(i => i.type === 4) as any)?.game_provider_ids;
      }
    }

    return selectedGameProvider;
  }

  private async setPromoSettings() {
    return new Promise<void>((resolve, reject) => {
      this.promotionSettingHttpService.getAllPromoSettings().subscribe(
        (res: any) => {
          this.dropdown.eligibleTypes = res.eligible_types;
          this.dropdown.targetTypes = res.target_types;
          this.promoTypesDropdown = res.promo_types;
          this.promotionSubType(
            res.promo_types,
            ((this.data.mode === 'edit' || this.data.mode === 'duplicate') ? this.data.promotionCode.promo_type : null)
          );
  
          this.categoriesDropdownList = res.promo_categories;
          this.turnoverCategoriesDropdownList = res.promo_categories;
          this.winlossCategoriesDropdownList = res.promo_categories;
          if (this.data.promotionCode) {
            const turnoverList = this.data.promotionCode.promotion_category.filter(
              (x) => x.target_type == 1
            );
            const winLossList = this.data.promotionCode.promotion_category.filter(
              (x) => x.target_type == 2
            );
  
            this.turnoverCategoriesSelectedItems = res.promo_categories.filter((cat) =>
              turnoverList.some((item) => item.category_id === cat.id)
            );

            if (this.turnoverCategoriesSelectedItems.length > 0) {
              this.gameProviderDropdownTurnoverDisabled = false;
            }
            this.winlossCategoriesSelectedItems = res.promo_categories.filter((cat) =>
              winLossList.some((item) => item.category_id === cat.id)
            );

            if (this.winlossCategoriesSelectedItems.length > 0) {
              this.gameProviderDropdownWinoverDisabled = false;
            }
          }
          resolve(); // Resolve the promise when everything is done.
        },
        (error) => {
          reject(error); // Reject the promise if there's an error.
        }
      );
    });
  }

  private setResetPrequency(type: string) {
    if (type === 'set' && (this.data.mode === 'edit' || this.data.mode === 'duplicate')) {
      this.form.patchValue({
        reset_frequency: this.data.promotionCode.reset_frequency,
        reset_dayMonthly: this.data.promotionCode.reset_frequency === 3 ? this.data.promotionCode.reset_day : null,
        reset_dayWeekly: this.data.promotionCode.reset_frequency === 2 ? this.data.promotionCode.reset_day : null
      });
    } else {
      this.form.patchValue({
        reset_dayMonthly: null,
        reset_dayWeekly: null,
        reset_frequency: null
      });
    }
  }

  private setMembers() {
    const members = this.memberService.getWithQuery(`?status=1&page=${this.pageNumber}&username=${this.username}`);
    members.pipe(
      tap((res) => {
        Object.keys(res).forEach(key => this.memberDropdown.push(res[key]));
        this.pagination = this.memberDataService.pagination;
      })
    )
      .subscribe();
  }

  private promotionSubType(list: any[], inputValue: number) {
    list.map((element: any) => {
      if (element.id === inputValue) {
        this.promotionSubTypes = element.sub_types;
      }
    });
  }

  private buildFrequencyMonths() {
    const result = [];
    for (let i = 1; i <= +moment().endOf('month').format('DD'); i++) {
      result.push({ id: i, name: i });
    }
    return result;
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private formInit() {
    let code = null;
    let name = null;
    let freeSpinGameProviderId = 0;
    let freeSpinGameCode = null;
    let turnOverCategory = null;
    let winLossCategory = null;
    let promoType = null;
    let promoSubType = null;
    let validFrom: any = null;
    let validTo: any = null;
    let validity = 30;
    let rewardsValidity = null;
    let frequencyType = 1;
    let frequency = [];
    let firstDeposit = 0;
    let memberGroupIds = null;
    let memberIds = null;
    let lastDeposit = 0;
    let autoApprove = 0;
    let status = 1;
    let gameProviderIds = null;
    let limitTransferIn = 0;
    let limitTransferOut = 0;
    let bonusRate = null;
    let autoUnlock = 0;
    let allowCancel = 0;
    let fixedAmount = 0;
    let maxPerPlayer = 0;
    let dailyMax = 1;
    let recurring = null;
    let resetFrequency = null;
    let resetDayWeekly = null;
    let resetDayMonthly = null;
    let promotionIDs = null;
    let smsTemplate = null;
    let messageTemplate = null;
    let depositCount = 0;
    let eligibleTypes = null;
    let affiliateGroupIds = null;
    let affiliateIds = null;
    let telemarketerIds = null;
    let normalAccountManagerIds = null;
    let vipAccountManagerIds = null;
    let kycType = null;
    let kycStatus = [];
    let requiresEmail = 0;
    let requiresMobile = 0;
    let requiresDob = 0;
    let requiresFullname = 0;
    let visibleByAffiliate = 0;
    let dialog_popups = [];
    let transferUnlock = 0;

    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      this.dateTimeStack = {
        valid_from: new Date(new Date(this.data.promotionCode.valid_from).getTime() + this.offset - this.clientOffset),
        valid_to: this.data.promotionCode.valid_to !== null ? new Date(new Date(this.data.promotionCode.valid_to).getTime() + this.offset - this.clientOffset) : null
      };

      if (this.data.mode === 'edit') {
        code = this.data.promotionCode.code;
        name = this.data.promotionCode.name;

        this.getPromotionCurrency();
        this.getAffGroupVisibility();
      }

      freeSpinGameProviderId = this.data.promotionCode.free_spin_game_provider_id;
      freeSpinGameCode = this.data.promotionCode.free_spin_game_code;
      turnOverCategory = this.data.promotionCode.promotion_category.filter(x => x.target_type == 1).map(x => x.category_id);
      winLossCategory = this.data.promotionCode.promotion_category.filter(x => x.target_type == 2).map(x => x.category_id);
      promoType = this.data.promotionCode.promo_type;
      promoSubType = this.data.promotionCode.promo_sub_type;
      validFrom = this.isValidDate(this.data.promotionCode.valid_from) ? moment(new Date(new Date(this.data.promotionCode.valid_from).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null;
      validTo = this.isValidDate(this.data.promotionCode.valid_to) ? moment(new Date(new Date(this.data.promotionCode.valid_to).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null;
      validity = this.data.promotionCode.validity;
      rewardsValidity = this.data.promotionCode.reward_validity;
      frequencyType = this.data.promotionCode.frequency_type;
      frequency = this.data.promotionCode.frequency;
      firstDeposit = this.data.promotionCode.first_deposit;
      memberGroupIds = this.data.promotionCode.member_group_ids;
      memberIds = (((this.data.promotionCode.member_ids).length >= 1) && this.data.mode !== 'duplicate') ? (this.data.promotionCode.member_ids).map((res: any) => res.id) : null;
      lastDeposit = this.data.promotionCode.last_deposit;
      autoApprove = this.data.promotionCode.auto_approve;
      visibleByAffiliate = this.data.promotionCode.visible_by_affiliate;
      status = this.data.promotionCode.status;
      gameProviderIds = (this.data.mode === 'edit' || this.data.mode === 'duplicate') ? this.data.promotionCode.game_provider_ids : [...this.gameProvidersSelectedTurnover, ...this.gameProvidersSelectedWinover]?.map(i => i.id);
      limitTransferIn = this.data.promotionCode.limit_transfer_in;
      limitTransferOut = this.data.promotionCode.limit_transfer_out;
      bonusRate = this.data.promotionCode.bonus_rate;
      autoUnlock = this.data.promotionCode.auto_unlock;
      allowCancel = this.data.promotionCode.allow_cancel;
      fixedAmount = this.data.promotionCode.fixed_amount;
      maxPerPlayer = this.data.promotionCode.max_per_player;
      dailyMax = this.data.promotionCode.daily_max;
      recurring = this.data.promotionCode.recurring;
      resetFrequency = this.data.promotionCode.reset_frequency;
      resetDayMonthly = this.data.promotionCode.reset_frequency === 3 ? this.data.promotionCode.reset_day : null;
      resetDayWeekly = this.data.promotionCode.reset_frequency === 2 ? this.data.promotionCode.reset_day : null;
      promotionIDs = this.data.promotionCode.promo_linked_ids;
      messageTemplate = this.data.promotionCode.message_template_id;
      smsTemplate = this.data.promotionCode.message_template_sms_id;
      depositCount = this.data.promotionCode.deposit_count;
      eligibleTypes = this.data.promotionCode.eligible_types;
      affiliateGroupIds = this.data.promotionCode.affiliate_group_ids;
      affiliateIds = (((this.data.promotionCode.affiliate_ids).length >= 1)  && this.data.mode !== 'duplicate') ? (this.data.promotionCode.affiliate_ids).map((res: any) => res.id) : null;
      telemarketerIds = (((this.data.promotionCode.telemarketer_ids).length >= 1) && this.data.mode !== 'duplicate') ? this.data.promotionCode.telemarketer_ids.map((res: any) => res.id) : [];
      normalAccountManagerIds = (((this.data.promotionCode.normal_account_manager_ids).length >= 1) && this.data.mode !== 'duplicate') ? this.data.promotionCode.normal_account_manager_ids.map((res: any) => res.id) : [];
      vipAccountManagerIds = (((this.data.promotionCode.vip_account_manager_ids).length >= 1) && this.data.mode !== 'duplicate') ? this.data.promotionCode.vip_account_manager_ids.map((res: any) => res.id) : [];
      transferUnlock = this.data.promotionCode.transfer_unlock;
      if (+this.data.promotionCode.eligible_types === 2) {
        this.setAffiliateGroup();
      } else if (+this.data.promotionCode.eligible_types === 4) {
        this.setTelemarketers();
        this.setNormalAccountManagers();
        this.setVipAccountManagers();
      } else {
        this.setMemberGroup();
      }
      kycType = this.data.promotionCode.kyc_type;
      
      if (this.data.promotionCode.kyc_basic == 1) {
        kycStatus.push(1);
      }

      if (this.data.promotionCode.kyc_advanced == 1) {
        kycStatus.push(2);
      }

      if (this.data.promotionCode.kyc_pro == 1) {
        kycStatus.push(3);
      }

      requiresEmail = this.data.promotionCode.requires_email;
      requiresMobile = this.data.promotionCode.requires_mobile;
      requiresDob = this.data.promotionCode.requires_dob;
      requiresFullname = this.data.promotionCode.requires_fullname;
    }

    let buildTarget = [];

    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      const buildSingleTargetSection = (data?: any, index?: number) => {
        return new FormGroup({
          type: new FormControl(data !== null ? data?.type : '', [Validators.required]),
          multiplier: new FormControl(data?.multiplier, [Validators.required]),
          game_provider_ids: new FormControl(index === 0 ? this.gameProvidersSelectedTurnover : this.gameProvidersSelectedWinover)
        });
      }

      // Single Entry
      if (this.data.promotionCode.target.length === 1) {
        // Turnover
        if (this.data.promotionCode.target[0].type === 1 || this.data.promotionCode.target[0].type === 3) {
          buildTarget = [...buildTarget, buildSingleTargetSection(this.data.promotionCode.target[0], 0)];
          buildTarget = [...buildTarget, buildSingleTargetSection(null, 1)];
        }

        // Winover
        if (this.data.promotionCode.target[0].type === 2 || this.data.promotionCode.target[0].type === 4) {
          buildTarget = [...buildTarget, buildSingleTargetSection(null, 0)];
          buildTarget = [...buildTarget, buildSingleTargetSection(this.data.promotionCode.target[0], 1)];
        }

        // Multiple Entry
      } else {
        this.data.promotionCode.target.forEach((item: any, index: number) => {
          buildTarget = [
            ...buildTarget,
            new FormGroup({
              type: new FormControl(this.data.promotionCode.target[index].type, [Validators.required]),
              multiplier: new FormControl(this.data.promotionCode.target[index].multiplier, [Validators.required]),
              game_provider_ids: new FormControl(index === 0 ? this.gameProvidersSelectedTurnover : this.gameProvidersSelectedWinover)
            })
          ];
        });
      }

    }

    if (this.data.mode === 'create') {
      buildTarget = [
        new FormGroup({
          type: new FormControl('', [Validators.required]),
          multiplier: new FormControl('', [Validators.required]),
          game_provider_ids: new FormControl(this.gameProvidersSelectedTurnover.length ? this.gameProvidersSelectedTurnover.map(i => i.id) : [])
        }),
        new FormGroup({
          type: new FormControl('', [Validators.required]),
          multiplier: new FormControl('', [Validators.required]),
          game_provider_ids: new FormControl(this.gameProvidersSelectedWinover.length >= 1 ? this.gameProvidersSelectedWinover.map(i => i.id) : [])
        }),
      ];
    }

    this.form = new FormGroup({
      code: new FormControl(code, [Validators.required, Validators.pattern('[-_a-zA-Z0-9]*')]),
      name: new FormControl(name, [Validators.required]),
      free_spin_game_provider_id: new FormControl(freeSpinGameProviderId),
      free_spin_game_code: new FormControl(freeSpinGameCode),
      promotion_category_turnover: new FormControl(turnOverCategory),
      promotion_category_winloss: new FormControl(winLossCategory),
      promo_type: new FormControl(promoType, [Validators.required]),
      promo_sub_type: new FormControl(promoSubType, [Validators.required]),
      promotion_ids: new FormControl(promotionIDs),
      valid_from: new FormControl(validFrom, [Validators.required]),
      valid_to: new FormControl(validTo),
      validity: new FormControl(validity),
      reward_validity: new FormControl(rewardsValidity),
      frequency: new FormControl(frequency),
      frequency_type: new FormControl(frequencyType),
      first_deposit: new FormControl(firstDeposit),
      member_group_ids: new FormControl(memberGroupIds),
      member_ids: new FormControl(memberIds),
      last_deposit: new FormControl(lastDeposit),
      auto_approve: new FormControl(autoApprove, [Validators.required]),
      visible_by_affiliate: new FormControl(visibleByAffiliate),
      recurring: new FormControl(recurring, [Validators.required]),
      reset_frequency: new FormControl(resetFrequency),
      reset_dayMonthly: new FormControl(resetDayMonthly),
      reset_dayWeekly: new FormControl(resetDayWeekly),
      max_per_player: new FormControl(maxPerPlayer),
      daily_max: new FormControl(dailyMax, [Validators.required, Validators.min(1)]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      limit_transfer_in: new FormControl(limitTransferIn),
      limit_transfer_out: new FormControl(limitTransferOut),
      bonus_rate: new FormControl(bonusRate, [Validators.required]),
      auto_unlock: new FormControl(autoUnlock, [Validators.required]),
      allow_cancel: new FormControl(allowCancel, [Validators.required]),
      fixed_amount: new FormControl(fixedAmount),
      game_provider_ids: new FormControl(gameProviderIds, [Validators.required]),
      target: new FormArray(buildTarget),
      message_template_id: new FormControl(messageTemplate),
      message_template_sms_id: new FormControl(smsTemplate),
      deposit_count: new FormControl(depositCount),
      eligible_types: new FormControl(eligibleTypes, [Validators.required]),
      affiliate_group_ids: new FormControl(affiliateGroupIds),
      affiliate_ids: new FormControl(affiliateIds),
      telemarketer_ids: new FormControl(telemarketerIds),
      normal_account_manager_ids: new FormControl(normalAccountManagerIds),
      vip_account_manager_ids: new FormControl(vipAccountManagerIds),
      kyc_type: new FormControl(kycType, [Validators.required]),
      kyc_status: new FormControl(kycStatus),
      requires_email: new FormControl(requiresEmail),
      requires_mobile: new FormControl(requiresMobile),
      requires_dob: new FormControl(requiresDob),
      requires_fullname: new FormControl(requiresFullname),
      dialog_popups: new FormControl(dialog_popups),
      transfer_unlock: new FormControl(transferUnlock, [Validators.required])
    });
    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      this.onChangeTarget(0);

      if (this.data.promotionCode.kyc_basic == 1) {
        this.kycStatusSelectedItem.push(this.dropdown.kycStatuses[0]);
      }
      if (this.data.promotionCode.kyc_advanced == 1) {
        this.kycStatusSelectedItem.push(this.dropdown.kycStatuses[1]);
      }
      if (this.data.promotionCode.kyc_pro == 1) {
        this.kycStatusSelectedItem.push(this.dropdown.kycStatuses[2]);
      }
      this.selectedKycType = this.data.promotionCode.kyc_type;

      if (this.selectedKycType == 1) { // Kyc Status
        this.form.get('kyc_status').setValidators(Validators.required);
        this.form.get('kyc_status').updateValueAndValidity();
      }

      this.cdr.detectChanges();
    }

    // Add subscription to handle value changes
    this.form.get('limit_transfer_out')?.valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        const transferUnlockControl = this.form.get('transfer_unlock');
        transferUnlockControl?.setValue(false); // Uncheck transfer_unlock
      }
    });

    this.form.get('auto_unlock')?.valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        const transferUnlockControl = this.form.get('transfer_unlock');
        transferUnlockControl?.setValue(false, { emitEvent: false }); // Prevent triggering valueChanges
      }
    });
    
    this.form.get('transfer_unlock')?.valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        const autoUnlockControl = this.form.get('auto_unlock');
        autoUnlockControl?.setValue(false, { emitEvent: false }); // Prevent triggering valueChanges
      }
    });
  }

  private openDialogBy(componentRef: any, data?: { promotionCurrency?: any, promotionCode?: any, providers?: any[], blacklist_sub_categories?: any[], promotionType?: number, promoSubType?: number, promotion_id?: number, categories?: string[]}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1300px',
      data: {
        promotionCurrency: data.promotionCurrency,
        promotionCode: data.promotionCode,
        providers: data.providers,
        blacklist_sub_categories: data.blacklist_sub_categories,
        promotionType: data.promotionType,
        promoSubType: data.promoSubType,
        promotionCodeMode: this.data.mode,
        promotion_id: data.promotion_id,
        categories: data.categories,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.onFocusField();
      if (result === true) {
        this.onViewPageBy(this.currentPage).subscribe();
      }
      
      if (this.data.mode === 'edit') {
        this.getPromotionCurrency();
      }
    });
  }

  private openPersonDialogBy(componentRef: any, data?: { promotionCode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1300px',
      data: {
        promotionCode: data.promotionCode,
        promotionCodeMode: this.data.mode,
        formData: this.form.value,
        currentImportedMemberList: this.importedMemberList
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.importedMemberList = result;
      this.onFocusField();
      // if (this.data.mode == 'edit') {
      //   this.groupDropdownSettings = {
      //     ...this.groupDropdownSettings,
      //     disabled: +this.form.value.eligible_types !== 2 ? (!JSON.parse(localStorage.getItem('updatePromoCodeMembers'))?.length && result === undefined ? false : true) : (!JSON.parse(localStorage.getItem('updatePromoCodeAffiliates'))?.length ? false : true)
      //   };
      // } else {
      //   this.groupDropdownSettings = {
      //     ...this.groupDropdownSettings,
      //     disabled: +this.form.value.eligible_types !== 2 ? (!JSON.parse(localStorage.getItem('createPromoCodeMembers'))?.length && result === undefined ? false : true) : (!JSON.parse(localStorage.getItem('createPromoCodeAffiliates'))?.length ? false : true)
      //   };
      // }

    });
  }

  onPreviousPage() {
    if (this.currentPage <= 1) {
      return;
    }
    this.currentPage--;
    this.onViewPageBy(this.currentPage);
  }

  private reload() {
    this.onViewPageBy(this.currentPage).subscribe();
  }

  //change deposit count input to read only if first deposit is checked
  disableDepositCount(checked) {
    let previousValue = (this.data.mode == 'create') ? 0 : this.data.promotionCode.deposit_count;
    if (!checked) {
      this.isReadOnly = false;
      this.form.controls['deposit_count'].setValue(previousValue);
    } else {
      this.isReadOnly = true;
      this.form.controls['deposit_count'].setValue(0);
    }
  }

  onFreeSpinGameProviderChange(event: Array<GameProvider>) {
    this.freeSpinGameDropdownList = [];
    this.freeSpinGameSelectedList = [];
    if (event.length > 0) {
      this.freeSpinGameDisabled = false;
      this.gameProviderHttpService.getFreeSpinGameList(event[0].code).subscribe(res => {
        this.freeSpinGameDropdownList = res;
        this.freeSpinGameDropdownList.map(function (elm) {
          elm['labelKey'] = elm.code + ' - ' + elm.name;
        });
      });
    } else {
      this.freeSpinGameDisabled = true;
    }
  }

  async onGameCategoryChanged(event: Array<Categories>, targetType: number) {
    if (targetType == 1) {
      this.gameProvidersDropdownListTurnover = [];
      this.gameProvidersSelectedTurnover = [];
      if (event.length > 0) {
        this.turnoverCategoriesSelectedItems = event;
        await this.setGame();
        this.gameProvidersDropdownListTurnover = this.gameProvidersDropdownListTurnover.filter(item => {
          // Check if at least one category name in item.categories exists in category array
          return item.categories.some(cat => event.some(catItem => catItem.code === cat.code));
        });
        this.gameProviderDropdownTurnoverDisabled = false;
      } else {
        this.gameProviderDropdownTurnoverDisabled = true;
      }
    } else {
      this.gameProvidersDropdownListWinover = [];
      this.gameProvidersSelectedWinover = [];
      if (event.length > 0) {
        this.winlossCategoriesSelectedItems = event;
        await this.setGame();
        this.gameProvidersDropdownListWinover = this.gameProvidersDropdownListWinover.filter(item => {
          //       // Check if at least one category name in item.categories exists in category array
          return item.categories.some(cat => event.some(catItem => catItem.code === cat.code));
        });
        this.gameProviderDropdownWinoverDisabled = false;
      } else {
        this.gameProviderDropdownWinoverDisabled = true;
      }
    }
  }

  removeLocalStorage(): void {
    localStorage.removeItem('gameProvidersDropdownListTurnover');
    localStorage.removeItem('gameProvidersDropdownListWinover');
  }

  private async setGame() {
    try {
      let res = [];
    
      // Check if values exist in local storage
      const storedTurnover = localStorage.getItem('gameProvidersDropdownListTurnover');
      const storedWinover = localStorage.getItem('gameProvidersDropdownListWinover');

      if (storedTurnover && storedWinover) {
        // If values exist in local storage, parse and set them
        this.gameProvidersDropdownListTurnover = JSON.parse(storedTurnover);
        this.gameProvidersDropdownListWinover = JSON.parse(storedWinover);
      } else {
        // If values don't exist in local storage, fetch data
        res = await this.dropdownHttpService.gameProviders.toPromise();

        // Create labelKey for Turnover
        // Filter game provider supported_target_type with 'All' and 'Turnover'
        this.gameProvidersDropdownListTurnover = res.filter(x => x.supported_target_type === 1 || x.supported_target_type === 2).map(function (elm) {
          const labelKey = elm.code + ' - ' + elm.name;
          return { ...elm, labelKey };
        });

        // Create labelKey for Winover
        // Filter game provider supported_target_type with 'All' and 'Winover'
        this.gameProvidersDropdownListWinover = res.filter(x => x.supported_target_type === 1 || x.supported_target_type === 3).map(function (elm) {
          const labelKey = elm.code + ' - ' + elm.name;
          return { ...elm, labelKey };
        });

        // Store the lists in local storage
        localStorage.setItem('gameProvidersDropdownListTurnover', JSON.stringify(this.gameProvidersDropdownListTurnover));
        localStorage.setItem('gameProvidersDropdownListWinover', JSON.stringify(this.gameProvidersDropdownListWinover));
      }
    } catch (error) {
      console.error('Error fetching game providers:', error);
    }
  }

  isMemberListDisabled() {
    return (+this.form.value.eligible_types === 4 && (this.form.value.telemarketer_ids.length == 0 && this.form.value.normal_account_manager_ids.length == 0 && this.form.value.vip_account_manager_ids.length == 0));
  }

  
  getPromotionCurrency() {
    this.promotionCurrencyEntityService.getWithQuery(`?page=1&perPage=30&promotion_id=${this.data.promotionCode.id}`).subscribe(res => {
      this.promotionCurrency = res;
      this.isCallingApi = false;
    });
  }

  isHiddenWarn() {
    if (this.data.mode === 'edit') {
      if (!this.isCallingApi) {
        if (this.promotionCurrency && this.promotionCurrency.length == 0) {
          return false;
        }
        else if (this.promotionCurrency && this.promotionCurrency.length > 0) {
          let searchActivePromotionCurrency = this.promotionCurrency.filter(x => x.status == 1);
          if (searchActivePromotionCurrency.length > 0) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    }
    else {
      if (!this.isCallingApi) {
        if (localStorage.getItem('createPromoCodeCurrencies') == null || JSON.parse(localStorage.getItem('createPromoCodeCurrencies')).length == 0) {
          return false;
        }
        else if (localStorage.getItem('createPromoCodeCurrencies') != null || JSON.parse(localStorage.getItem('createPromoCodeCurrencies')).length > 0) {
          let searchActivePromotionCurrency = JSON.parse(localStorage.getItem('createPromoCodeCurrencies')).filter(x => x.status == 1);
          if (searchActivePromotionCurrency.length > 0) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    }
  }

  setPopups() {
    let contents = [];
    this.dialogDataService.getWithQuery(`?paginate=false&status=1`).subscribe(res => {
      res.map(function (elm) {
        elm.contents.forEach(v => contents.push(v));

        let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
        localeTitle = localeTitle.sort(function(a, b) { 
          return a.locale_id - b.locale_id;
        });

        if (localeTitle.length > 0) {
          let title = (localeTitle[0]['title'].length > 9) ? localeTitle[0]['title'].slice(0, 9 - 1) + ' . . . ' : localeTitle[0]['title'];
          elm['labelKey'] = elm.code + ' (' + title + ')';
        }
        else {
          elm['labelKey'] = elm.code;
        }
      });

      this.popupDropdownList = this.popupDropdownList.concat(res);
      this.popupDropdownList = this.popupDropdownList.map((x) => {
        return {
          id: x.id,
          start_date: x.start_date,
          end_date: x.end_date,
          promotion_id: this.data.mode === 'edit' ? this.data.promotionCode.id : null,
          labelKey: x.labelKey,
          code: x.code
        }
      });
      
      this.popupDropdownListArray = this.popupDropdownList;
      if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
        let selected = this.data.promotionCode.dialog_popup_list.filter(x => x.popup_id !== null);
        if (selected.length > 0) {
          this.popupSelectedItems.push(this.popupDropdownList.filter(x => x.id == selected[0]['popup_id'])[0]);
        }
      }
    })
  }

  getPopupSelectedItems() {
    let selectedItems = [];
    if (this.popupSelectedItems.length > 0) {
      this.popupSelectedItems.forEach((item) => {
        selectedItems.push(item);
      });
    }

    return selectedItems;
  }

  onSelectedPopupItems($event: any) {
    if ($event.length == 0) {
      this.popupSelectedItems = [];
    }
    else {
      this.popupSelectedItems = [$event[0]];
    }
  }

  checkDialogValidityDate(selectedPopup: any) {
    if (selectedPopup != undefined && selectedPopup.length > 0) {
      var today = new Date();
      var start_date = new Date(selectedPopup[0]['start_date']);

      if (selectedPopup[0]['end_date'] != null) {
        var end_date = new Date(selectedPopup[0]['end_date']);
        if (today > end_date || today < start_date) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (today < start_date) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  getAffGroupVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    if (this.data.mode == 'edit') {
      this.affiliateGroupsDataService.getPromotionVisibilityList(`?page=${page}&perPage=${this.pageSize}&promotion_id${this.data.promotionCode.id}`).subscribe(res => {
        this.affGroupVisibility = res;
      });
    }
  }

  onChangeVisibileByAffiliate(event) {
    if (event.target.checked == false && this.affGroupVisibility.length > 0) {
      Swal.fire({
        title: 'System Alert',
        html: '<div>Are you sure?<br><p style="color: red">This action will remove the associated promotion from the affiliate campaign settings.</p></div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
        allowOutsideClick: false
      }).then((response) => {
        if (response.isConfirmed) {
          this.form.patchValue({
            visible_by_affiliate: false
          });
        }
        else {
          this.form.patchValue({
            visible_by_affiliate: true
          });
        }
      });
    }
  }
}

interface Categories {
  id: number;
  code: string;
  name: string
}
