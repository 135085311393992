import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { Member } from '@core/models/member.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EditorService } from '@core/services/editor.service';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { SendSMSHttpService } from '@core/services/send-sms-http.service';
import moment from 'moment';
import { forkJoin, of, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { catchError, delay, tap } from 'rxjs/operators';
import { MessageTemplateDataService } from '../../superuser/message-template/services/message-template-data.service';
import { AllSmsMessagesDataService } from '../all-sms-messages/service/all-sms-messages-data.service';
import { WebPushMessagesDataService } from '../web-push-messages/service/web-push-messages-data.service';
import { MemberDataService } from './../../general/members/services/member-data.service';
import { MemberEntityService } from './../../general/members/services/member-entity.service';
import { ProvidersDataService } from './../providers/service/providers-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { accessSections } from '@core/store/auth/auth.selectors';

declare var $: any;
@Component({
  selector: 'kt-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss']
})
export class SendComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  @ViewChild('myFileInput') myFileInput;
  form: FormGroup;
  formBulk: FormGroup;
  sendMessageForm: FormGroup;
  sendSMSForm: FormGroup;
  sendWebPushForm: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    smsProvider: this.providersDataService.getProviders(`?smstype=all`),
    campaignSMSProvider: this.providersDataService.getProviders(`?smstype=all`),
    countries: this.dropdownHttpService.countries,
    groups: this.dropdownHttpService.groups,
    locales$: this.messageTemplateDataService.locales$,
  };

  // permissions
  canSendMessage: boolean;
  canSendSMS: boolean;
  canSendCampaignSMS: boolean;
  canSendWebpush: boolean;

  sendMessageMemberDropdownSettings = {};
  sendMessageTemplateDropdownSettings = {};
  smsMemberDropdownSettings = {};
  smsMessageTemplateDropdownSettings = {};
  webPushMemberDropdownSettings = {};
  webPushTemplateDropdownSettings = {};
  messageTemplateDropdownList = [];
  smsMessageTemplateDropdownList = [];
  selectedMembers = [];
  selectedMessageTemplate = [];
  members$ = [];
  reloadMember$ = new Subject<any>();
  pageNumber = 1;
  pagination: Pagination;
  messages$ = this.sendSMSHttpService.messages$;
  username = '';

  buttonLoading = false;
  rewardsDropdown = [];
  loading = new Subject<any>();
  rewardsDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: '200',
    primaryKey: 'id',
    labelKey: 'name',
    showCheckbox: false,
  };
  fileName = null;
  file: File;
  webpushFile: File;
  webpushFileName = null;
  selectedMembersWebpush = [];
  bulkFile: File;
  bulkFileName = null;

  checkValidation = false;
  genericnotice = '';

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private messageSubscription = new Subscription();

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;
  // End: CKEditor Part

  constructor(
    private providersDataService: ProvidersDataService,
    private memberEntityService: MemberEntityService,
    private memberDataService: MemberDataService,
    private sendSMSHttpService: SendSMSHttpService,
    private dropdownHttpService: DropdownHttpService,
    private rewardsHttpService: RewardsHttpService,
    private cdr: ChangeDetectorRef,
    private allSmsMessagesDataService: AllSmsMessagesDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private editorService: EditorService,
    private store: Store<AppState>,
    private webPushMessagesDataService: WebPushMessagesDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.setMembers();
    this.setLocales();
    this.pagination = this.memberDataService.pagination;
    this.formInit();
    this.initDropdownSettings();
    this.initMessageTemplateDropdown();
    this.initSMSMessageTemplateDropdown();
    this.initGenericVariableContent();
    $(document).find('#campaign-dropdown-template').click();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canSendMessage = appPermissions.send_message;
      this.canSendSMS = appPermissions.send_sms;
      this.canSendCampaignSMS = appPermissions.send_campaign_sms;
      this.canSendWebpush = appPermissions.send_webpush;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.onWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    $('.dropdown-list').width($('.selected-list').width());
  }

  onSelectTab() {
    this.checkValidation = false;
    // var element = document.getElementById("campaign-dropdown-template");
    // element.getElementsByClassName("dropdown-list")
  }

  onMessageTemplateChanged(event: Event, form: String) {
    let content = (event as any)[0];

    // Remove html element
    var div = document.createElement('div');
    div.innerHTML = content !== undefined ? content.message : '';
    let message = (div.textContent || div.innerText);

    switch (form) {
      case 'messageForm': {
        this.sendMessageForm.patchValue({
          content: div.innerHTML
        });
        break;
      }
      case 'bulkSmsForm': {
        this.formBulk.patchValue({
          message_content: message
        });
        break;
      }
      case 'webPushForm': {
        this.sendWebPushForm.patchValue({
          content: div.innerHTML
        });
        break;
      }
    }
  }

  onSelectCurrency(event: Event) {
    this.loading.next(true);
    this.formBulk.patchValue({
      promotion_id: null
    });
    this.rewardsDropdown = [];
    this.setPromotion(`?status=1&paginate=false&promo_type=3&currency_id=${+(event.target as HTMLInputElement).value}`);
    this.setProviders(`?smstype=all&currency_id=${+(event.target as HTMLInputElement).value}`);
  }

  onScrollToEnd() {
    if (this.pageNumber >= this.pagination.last_page) {
      return;
    }
    this.pageNumber++;
    this.setMembers();
  }

  onSearchMember(event?: Event) {
    if (this.username !== (event.target as HTMLSelectElement).value) {
      this.members$ = [];
      this.pageNumber = 1;
      this.username = (event.target as HTMLSelectElement).value;
      this.setMembers();
    }
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onUploadFile(event: any, form?: string) {
    switch (form) {
      case 'sendMessageForm': {
        this.file = event.target.files[0];
        this.fileName = this.file.name;
        break;
      }
      case 'formBulk': {
        this.bulkFile = event.target.files[0];
        this.bulkFileName = this.bulkFile.name;
        break;
      }
      case 'webPushForm': {
        this.webpushFile = event.target.files[0];
        this.webpushFileName = this.webpushFile.name;
        break;
      }
    }
  }

  onRemoveFile(form: string) {
    this.myFileInput.nativeElement.value = null;
    switch (form) {
      case 'sendMessageForm': {
        this.file = undefined;
        this.fileName = null;
        break;
      }
      case 'formBulk': {
        this.bulkFile = undefined;
        this.bulkFileName = null;
        break;
      }
      case 'webPushForm': {
        this.webpushFile = undefined;
        this.webpushFileName = null;
        break;
      }
    }
  }

  onSend(type?: string) {
    this.checkValidation = true;
    if (type === 'bulk') {
      if (this.formBulk.valid && this.bulkFile !== undefined) {
        this.buttonLoading = true;
        const data = this.setFormData();
        delete data['message_template'];
        this.messages$ = this.sendSMSHttpService.messages$;
        this.subscription = forkJoin([
          this.sendSMSHttpService.sendBulkSms(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.bulkFileName = null;
              this.rewardsDropdown = [];
              this.formBulk.reset();
              this.myFileInput.nativeElement.value = null;
              // To enable "Save" button after get response
              this.onClearDate();
              this.formBulk.setErrors(null);
              this.selectedMessageTemplate = [];
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              // To enable "Save" button after get response
              this.formBulk.setErrors(null);
              throw error;
            })
          ),
          this.sendSMSHttpService.messages$
        ]).subscribe();
      }
    } else if (type === 'message') {
      if (this.sendMessageForm.valid) {
        this.buttonLoading = true;
        const data = new FormData();
        Object.keys(this.sendMessageForm.value).forEach((key) => {
          if (key !== 'message_template' && key !== 'member_account_id') {
            if (this.formBulk.value[key] !== null && this.formBulk.value[key] !== '') {
              data.append(key, this.sendMessageForm.value[key]);
            }
          }
        });
        data.append('type', '1');
        if (this.file) {
          data.append('attached_file', this.file);
          delete data['member_account_id'];
        } else {
          if (this.selectedMembers.length !== 0) {
            this.selectedMembers.forEach(member => { data.append('member_account_id[]', member.id) });
          }
        }

        this.messages$ = this.allSmsMessagesDataService.messages$;
        this.subscription = forkJoin([
          this.allSmsMessagesDataService.sendMessage(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.file = undefined;
              this.fileName = null;
              this.sendMessageForm.reset();
              // To enable "Save" button after get response
              this.sendMessageForm.setErrors(null);
              this.selectedMessageTemplate = [];
              this.selectedMembers = [];
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              // To enable "Save" button after get response
              this.sendMessageForm.setErrors(null);
              throw error;
            })
          ),
          this.allSmsMessagesDataService.messages$
        ]).subscribe();
      }
    } else if (type === 'webpush') {
      if (this.sendWebPushForm.valid) {
        this.buttonLoading = true;
        const data = new FormData();
        Object.keys(this.sendWebPushForm.value).forEach((key) => {
          if (key !== 'message_template' && key !== 'member_account_id') {
            data.append(key, this.sendWebPushForm.value[key]);
          }
        });
        data.append('type', '1');
        if (this.webpushFile) {
          data.append('attached_file', this.webpushFile);
          delete data['member_account_id'];
        } else {
          if (this.selectedMembersWebpush.length !== 0) {
            this.selectedMembersWebpush.forEach(member => { data.append('member_account_id[]', member.id) });
          }
        }
        this.messages$ = this.webPushMessagesDataService.messages$;
        this.subscription = forkJoin([
          this.webPushMessagesDataService.sendWebpush(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.webpushFile = undefined;
              this.webpushFileName = null;
              this.sendWebPushForm.reset();
              // To enable "Save" button after get response
              this.sendWebPushForm.setErrors(null);
              this.selectedMessageTemplate = [];
              this.selectedMembersWebpush = [];
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              // To enable "Save" button after get response
              this.sendWebPushForm.setErrors(null);
              throw error;
            })
          ),
          this.webPushMessagesDataService.messages$
        ]).subscribe();
      }
    } else {
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.formBulk.patchValue({
        execution_start_time: event.startDate !== null ? moment(event.startDate._d).utc().format('HH:mm:ss') : event.startDate,
        execution_end_time: event.endDate !== null ? moment(event.endDate._d).utc().format('HH:mm:ss') : event.endDate
      });
    }
  }

  onClearDate() {
    if (this.formBulk.value.execution_start_time !== null) {
      this.formBulk.patchValue({ defaultDate: null });
    }
  }

  onMemberChanged(event: Event, form?: string) {
    let arr = event as any;
    if (form === 'sendWebPushForm') {
      if (arr.length === 0) {
        this.selectedMembersWebpush = []
      }
      // else {
      //   this.selectedMembersWebpush.push((event as any)[0]);
      // }
      // this.updateMemberSelection(this.selectedMembersWebpush, 'sendWebPushForm');
    } else {
      if (arr.length === 0) {
        this.selectedMembers = []
      }
      // else {
      //   this.selectedMembers.push((event as any)[0]);
      // }
      // this.updateMemberSelection(this.selectedMembers);
    }
  }

  onRemoveMember(id: number, form?: string) {
    switch (form) {
      case 'sendMessageForm': {
        const result = this.selectedMembers.filter((i: Member) => i.id !== id);
        this.selectedMembers = result;
        this.updateMemberSelection(this.selectedMembers);
        break;
      }
      case 'sendWebPushForm': {
        const result = this.selectedMembersWebpush.filter((i: Member) => i.id !== id);
        this.selectedMembersWebpush = result;
        this.updateMemberSelection(this.selectedMembersWebpush, 'sendWebPushForm');
        break;
      }
    }
  }

  private initDropdownSettings() {
    this.sendMessageMemberDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
    };
    this.sendMessageTemplateDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      autoPosition: true,
    };
    this.smsMemberDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
    };
    this.smsMessageTemplateDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      autoPosition: true,
    };
    this.webPushMemberDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
    };
    this.webPushTemplateDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      autoPosition: true,
    };
  }

  private initMessageTemplateDropdown() {
    this.messageTemplateDataService.getWithQuery('?type[]=1&page=1&perPage=30').subscribe(res => {
      this.messageTemplateDropdownList = res;
    })
  }

  private initSMSMessageTemplateDropdown() {
    this.messageTemplateDataService.getWithQuery('?type[]=2&page=1&perPage=30').subscribe(res => {
      this.smsMessageTemplateDropdownList = res;
    })
  }

  private setPromotion(params: string) {
    this.rewardsHttpService.getRewardsList(params).subscribe(
      res => {
        this.rewardsDropdown = res;
        this.rewardsDropdown.map(function (elm) {
          elm['name'] = elm.code + ' - ' + elm.name;
        });
        this.loading.next(false);
      }
    );
  }

  private setProviders(params: string) {
      this.dropdown.campaignSMSProvider = this.providersDataService.getProviders(params);
      this.formBulk.controls['sms_provider'].setValue(null);
  }

  private setFormData() {
    const formData = new FormData();
    this.formBulk.patchValue({
      deduplicate: this.formBulk.value.deduplicate ? 1 : 0
    });
    Object.keys(this.formBulk.value).forEach((key) => {
      if (key !== 'defaultDate') {
        if (this.formBulk.value[key] !== null && this.formBulk.value[key] !== '') {
          formData.append(key, this.formBulk.value[key]);
        }
      }
    });
    formData.append('attached_file', this.bulkFile);

    return formData;
  }

  private setMembers() {
    const members = this.memberEntityService.getWithQuery(`?status=1&page=${this.pageNumber}&username=${this.username}`);
    members.pipe(
      tap((res) => {
        Object.keys(res).forEach(key => {
          this.members$.push(res[key]);
        });
        this.pagination = this.memberDataService.pagination;
        this.reloadMember$.next(...res);
      })
    ).subscribe();
  }

  private updateMemberSelection(selectedMembers: Member[], form?: string) {
    if (form === 'sendWebPushForm') {
      this.sendWebPushForm.patchValue({
        member_account_id: selectedMembers.map(i => i.id)
      });
    } else {
      this.sendMessageForm.patchValue({
        member_account_id: selectedMembers.map(i => i.id)
      });
    }
    this.cdr.detectChanges();
  }

  private formInit() {
    this.formBulk = new FormGroup({
      deduplicate: new FormControl(0, [Validators.required]),
      sms_provider: new FormControl(null, [Validators.required]),
      currency_id: new FormControl(null, [Validators.required]),
      promotion_id: new FormControl(null),
      subject: new FormControl(null, [Validators.required]),
      message_content: new FormControl(null, [Validators.required]),
      message_template: new FormControl(null),
      execution_start_time: new FormControl(null),
      execution_end_time: new FormControl(null),
      defaultDate: new FormControl({
        value: null,
      })
    });

    this.sendMessageForm = new FormGroup({
      member_account_id: new FormControl([]),
      title: new FormControl(null, [Validators.required]),
      content: new FormControl(null, [Validators.required]),
      message_template: new FormControl(null),
    });

    this.sendWebPushForm = new FormGroup({
      member_account_id: new FormControl([]),
      title: new FormControl(null, [Validators.required]),
      content: new FormControl(null, [Validators.required]),
      message_template: new FormControl(null),
    });
  }

  private initGenericVariableContent() {
    this.messageTemplateDataService.getGenericVariables().subscribe(res => {
      if (res) {
        this.genericnotice = `Message template variable only support ${res.generic.join(', ')}`;
      }
    })
  }

  private setLocales() {
    const localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.messageTemplateDataService.locales$.next(res);
    })).subscribe();

    this.subscriptions.add(localesSub);
  }
}
