import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap, take } from 'rxjs/operators';
import { Member } from '@core/models/member.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { LoginEvent } from '@core/models/login-event.model';
import { SKIP_ERROR_INTERCEPTOR_HEADER } from '@utils/constants';

@Injectable()
export class MemberDataService extends DefaultDataService<Member> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Member', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Member[]> {
    return this.http.get<ApiResponse>('/member').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(member: Member): Observable<Member> {
    return this.http.post<Member>(`/member`, member)
  }

  // Currently use in dropdown, this API faster and less info.
  getWithQueryDropdown(pageParam: string): Observable<Member[]> {
    return this.http.get<ApiResponse>(`/memberdropdown${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ' + res.data.rows[key].username.toUpperCase() + ' - ' + res.data.rows[key].name.toUpperCase();
        });
        return res.data.rows;
      })
    );
  }

  // Currently use in 1.1 All Members only, this API slower and more info
  getWithQuery(pageParam: string): Observable<Member[]> {
    return this.http.get<ApiResponse>(`/member${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ' + res.data.rows[key].username.toUpperCase() + ' - ' + res.data.rows[key].name.toUpperCase();
        });
        return res.data.rows;
      })
    );
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/member/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getById(id: number, pageParam?: string): Observable<Member> {
    const newPageParam = pageParam ? pageParam : '';
    return this.http.get<ApiResponse>(`/member/${id}${newPageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  getLogInEvents(pageParam: string): Observable<Member[]> {
    return this.http.get<ApiResponse>(`/member/loginlog${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getMemberLoginEvent(id: number): Observable<LoginEvent> {
    return this.http.get<ApiResponse>(`/member/loginlog?member_account_id=${id}&latest=1`).pipe(
      map(res => res.data.rows),
      take(1)
    );
  }

  resetPassword(data: any) {
    return this.http.post<ApiResponse>(`/gameprovider/resetpassword`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  shadowLogin(id: number): Observable<string> {
    return this.http.get<ApiResponse>(`/member/shadowLogin?member_account_id=${id}`).pipe(
      map(res => res.data.rows.login_url)
    );
  }

  forceLogout(id: number): Observable<number> {
    return this.http.post<ApiResponse>(`/member/forcelogout/${id}`, null).pipe(
      map(res => {
        this.messages$.next(res.message);
        return res.data.row.member_account_id
      })
    );
  }

  updateStatus(id: number, status: number) {
    return this.http.put<ApiResponse>(`/member/statusupdate?id=${id}&status=${status}`, {}).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  resetGameAccount(data: any) {
    return this.http.post<ApiResponse>(`/gameprovider/resetaccount`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  createGameAccount(data: any) {
    return this.http.post<ApiResponse>(`/gameprovider/creategameaccount`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  getBetInformation(memberId: number, param: string): Observable<any[]> {
    return this.http.get<ApiResponse>(`/member/getbetdetails/${memberId}${param}`).pipe(
      map(res => res.data.rows)
    );
  }

  getRemarksHistory(memberId: number) {
    return this.http.get<ApiResponse>(`/member/remarks?paginate=0&member_account_id=${memberId}`).pipe(
      map(res => res.data.rows)
    );
  }

  getMemberGameLog(data: any) {
    return this.http.get<ApiResponse>(`/gameprovider/getgamelog${data}`).pipe(
      map(res => res.data.game_sessions)
    );
  }

  getMemberGameLogDetails(data: any) {
    var result = this.http.get<ApiResponse>(`/gameprovider/getgamelogdetails${data}`).pipe(
      map(res => res.data.game_sessions)
    );
    return result;
  }

  validateTrafficSourceInput(trafficSource: number, data: any): Observable<any> {
    return this.http.post<ApiResponse>('/member/validateTrafficSourceInput', {
      traffic_source: trafficSource,
      ...data,
    });
  }

  validateEmail(data: any) {
    const headers = {
      [SKIP_ERROR_INTERCEPTOR_HEADER]: 'true'
    };
    return this.http.post<ApiResponse>('/member/validate/email', data, { headers });
  }

  checkMobileEmailDuplicate(data: any) {
    return this.http.post<ApiResponse>('/member/checkdup', data);
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
