<div *ngIf="form" [formGroup]="form" class="input-group date">
  <div class="input-group date">
    <input
      formControlName="defaultDate"
      type="text"
      class="form-control"
      [placeholder]="placeholder"
      [opens]="opens"
      [drops]="drops"
      (change)="onDateRange($event)"
      [timePicker]="true"
      [timePickerSeconds]="true"
      [alwaysShowCalendars]="true"
      [ranges]="hasRanges ? ranges : []"
      [showCustomRangeLabel]="hasRanges"
      [locale]="dateTimePickerLocale"
      [timePicker24Hour]="true"
      [showDropdowns]="true"
      (click)="updateDateRange()"
      [minDate]="minDate"
      [singleDatePicker]="singleDatePicker"
      ngxDaterangepickerMd
    />
    <span class="input-group-append">
      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
    </span>
  </div>
</div>
