<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Withdrawal Event History (ID: {{ data.withdraw.id }})</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <kt-withdrawal-log [withdraw]="data.withdraw"></kt-withdrawal-log>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
      </div>
    </form>
  </div>
  
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>