import { EventEmitterService } from '@core/services/event-emitter.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { map, exhaustMap, tap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { BotStatusDataService } from './service/bot-status-data.service';
import { BotStatusEntityService } from '@views/pages/apps/settings/bot-status/service/bot-status-entity.service';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { BotStatus } from '@core/models/bot-status.model';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import * as moment from 'moment-timezone';
import { TransactionHttpService } from '@core/services/transaction-http.service';

@Component({
  selector: 'kt-bot-status',
  templateUrl: './bot-status.component.html',
  styleUrls: ['./bot-status.component.scss']
})
export class BotStatusComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  botStatus$: Observable<BotStatus[]>;
  statuses = [
    "Disabled",
    "Enabled"
  ];

  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  messages$ = this.botStatusDataService.messages$;

  // permissions
  canEnterTAC: boolean;
  canUpdateBankBotStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;

  dateTimeFilterType = [
    {
      key: 'last_run_at',
      label: 'Last Run Date'
    },
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];

  constructor(
    private botStatusEntityService: BotStatusEntityService,
    private botStatusDataService: BotStatusDataService,
    private dropdownHttpService: DropdownHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private eventEmitterService: EventEmitterService,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
  ) { }

  ngOnInit() {
    this.reload();
    this.formInit();
    this.pagination = this.botStatusDataService.pagination;

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      // maxHeight: 'auto',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.merchantBankHttpService.getMerchantBankAccounts('&sync=1').subscribe(
      res => {
        res.map(elm => {
          elm.name = elm.bank_code + ' - ' + elm.account_name + ' - ' + elm.account_number;
        });
        this.bankaccountDropdownList = res;
        this.bankaccountDropdownList.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
      }
    );

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEnterTAC = appPermissions.enter_bank_bot_tac;
      this.canUpdateBankBotStatus = appPermissions.update_bank_bot_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.botStatus$ = this.botStatusEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.botStatusDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onCloseDialog() {
  }

  onClear() {
    this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.form.patchValue({
      merchant_bank_id: 'all',
      start_date: null,
      end_date: null,
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        // add 8 hour, bant bot +8 timezone
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).add(8, 'hours').tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).add(8, 'hours').tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        if(data['defaultDate']){
          delete data['defaultDate'];
        }
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.botStatus$ = this.botStatusEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.botStatusDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      merchant_bank_id: new FormControl('all'),
      date_type: new FormControl(this.dateTimeFilterType[0].key),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') ? fields[key] = formData[key] : key);
    return fields;
  }

  reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  updateSwitch(row: any, value: number) {
    if (row.switch !== value) {
      const data = {
        switch: value
      };
      this.subscription = this.botStatusDataService.updateSwitch(row.id, data).subscribe(() =>
        this.messages$ = this.botStatusDataService.messages$
      );
    }
  }

  updateTAC(bank_account_id: number) {
    Swal.fire({
      title: 'Enter TAC',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          bank_account_id: bank_account_id,
          tac: result.value
        }
        this.botStatusDataService.updateTAC(data).subscribe(() =>
          this.messages$ = this.botStatusDataService.messages$
        );
      }
    })
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

}
