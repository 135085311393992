import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import Swal from "sweetalert2";
import { AffiliateInformationComponent } from './../all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AllAffiliatesDataService } from './../all-affiliates/services/all-affiliates-data.service';
import { GenerateCommissionComponent } from './dialogs/generate-commission/generate-commission.component';
import { ReleaseCommissionsDialogComponent } from './dialogs/release-commissions/release-commissions.component';
import { CommissionReleasesDataService } from './services/commission-releases-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';


@Component({
  selector: 'kt-commission-releases',
  templateUrl: './commission-releases.component.html',
  styleUrls: ['./commission-releases.component.scss']
})
export class CommissionReleasesComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = "";
  searchStatus = [0];

  commissionReleases$: any;
  messages$ = this.commissionReleasesDataService.messages$;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  ranges = this.transactionHttpService.ranges;
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dropdown = {
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'Released', value: 1, checked: false
      },
      {
        name: 'Rejected', value: 2, checked: false
      }
    ],
  };

  currencyTimezone = CurrencyTimezone;
  selectedTotalCommission = 0;
  selectedCommission = [];
  availableCommission = [];
  checkboxForm: FormGroup;
  toggleAll: boolean = false;
  sortingConfig = {
    'id': 'desc',
    'generate_date': 'desc',
    'affiliate': 'desc',
    'currency': 'desc',
    'affiliate_group': 'desc',
    'active_members': 'desc',
    'commission_currency': 'desc',
    'calculated_commission': 'desc',
    'last_carry_forward': 'desc',
    'entitled_commission': 'desc',
    'adjustments': 'desc',
    'nett_commission': 'desc',
    'released_commission': 'desc',
    'carry_forward': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  button_loading = false;

  dateTimeFilterType = [
    {
      key: 'calculation_at',
      label: 'Calculation Date'
    },
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];
  // permissions
  canViewAffiliateDialog: boolean;
  canGenerateAffiliateCommission: boolean;
  canReleaseAffiliateCommission: boolean;
  canRejectAffiliateCommission: boolean;
  canExportAffiliateCommission: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private commissionReleasesDataService: CommissionReleasesDataService,
    private transactionHttpService: TransactionHttpService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewAffiliateDialog = appPermissions.commission_releases_view_affiliate_dialog;
      this.canGenerateAffiliateCommission = appPermissions.generate_affiliate_commission;
      this.canReleaseAffiliateCommission = appPermissions.release_affiliate_commission;
      this.canRejectAffiliateCommission = appPermissions.reject_affiliate_commission;
      this.canExportAffiliateCommission = appPermissions.export_affiliate_commission;
    });

    this.subscriptions.add(apSub);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.commissionReleases$ = undefined;
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef + '' + statusParams.newStatusParams;
        this.loadingBar.start();
        return this.commissionReleasesDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap((res: any) => {
            this.selectedTotalCommission = 0;
            this.selectedCommission = [];
            this.availableCommission = res.rows;
            this.checkboxFormInit();
            this.toggleAll = false;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.commissionReleases$ = res;
            this.dataLength = res.rows.length;
            this.page = 1;
            this.pagination = this.commissionReleasesDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewInfo(mode: string, row?: any) {
    const affiliate = this.allAffiliatesDataService.getById(row.affiliate_id);
    this.subscription = affiliate.pipe(
      tap((res: any) => {
        this.dialog.open(AffiliateInformationComponent, {
          width: '1500px',
          height: '80vh',
          data: {
            affiliate: res,
            mode: mode
          }
        });
      })
    ).subscribe();
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.commissionReleases$ = undefined;
    return this.commissionReleasesDataService.getWithQuery(`?page=${page}&${this.generateSortingParam()}&perPage=${pageSize}${params}`).pipe(
      tap((res: any) => {
        this.commissionReleases$ = res;
        this.selectedTotalCommission = 0;
        this.selectedCommission = [];
        this.availableCommission = res.rows;
        this.checkboxFormInit();
        this.toggleAll = false;
        this.pagination = this.commissionReleasesDataService.pagination;
        this.dataLength = res.rows.length;
        this.loading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.searchStatus = [0];
    this.dropdown.statuses.forEach((child) => {
      child.checked = child.name === 'Pending' ? true : false;
    });
    this.form.patchValue({
      username: null,
      currency_id: 'all',
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });

    this.sortingSelection = {
      'sort_by': 'id',
      'sort_order': 'desc',
    };
    this.onSubmit(true);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (this.toggleAll) {
      this.availableCommission.forEach(item => {
        if (item.status_id == 0) {
          patchValue = { ...patchValue, [item.id]: true };
        }
      });
    } else {
      this.availableCommission.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  onRejectRelease(action: string, mode: string, id?: number) {
    if (action === 'release') {
      const params = {
        mode: mode,
        selected_commisions: this.selectedCommission,
      }
      this.openDialogBy(ReleaseCommissionsDialogComponent, params, {
        width: '800px',
        class: 'generate-rebate-custom-dialog'
      });
    } else if (action === 'reject') {
      let commission = this.selectedCommission.length > 1 ? 'commisions' : 'commision';
      let message = '';
      let data = {};
      switch (mode) {
        case 'selected':
          message = `Are you sure you want to ${action} the selected ${commission} ?`;
          data = { affiliate_commission_id: this.selectedCommission };
          break;
        case 'all':
          message = `Are you sure you want to ${action} all the commission ?`;
          data = {};
          break;
      }

      Swal.fire({
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.commissionReleasesDataService.reject(data).subscribe();
        }
      });
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, id?: number) {
    this.openDialogBy(GenerateCommissionComponent, null, {
      width: '800px',
      class: 'generate-rebate-custom-dialog'
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: any, options?: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: options.width,
      panelClass: options.class ? options.class : '',
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.onViewPageBy(this.page);
    });
  }


  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availableCommission.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: [{ value: false, disabled: item.status_id != 0 }] }
    });
    this.checkboxForm = this.fb.group(prepareForm);

    this.checkboxForm.valueChanges.subscribe(val => {
      this.selectedCommission = [];
      this.selectedTotalCommission = 0;
      Object.keys(val).forEach(key => {
        if (val[key]) {
          this.selectedTotalCommission += +this.availableCommission.find(x => x.id == key).nett_commission;
          this.selectedCommission.push(key);
        }
      })
    })
  }

  private generateStatusParams() {
    return {
      newStatusParams: this.searchStatus.length > 0 ? '&' + Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&') : ''
    };
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;
        data['status'] = this.searchStatus;
        this.loadingBar.start();
        return this.commissionReleasesDataService.export(data).pipe(
          tap(res => {
            this.messages$ = this.commissionReleasesDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );

      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      currency_id: new FormControl("all"),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range,
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    });
  }
}

