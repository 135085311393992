import { MemberRemarksListComponent } from '../../../../../../../shared/member-remarks-list/member-remarks-list.component';
import { TransactionDataService } from './../../../transactions/services/transaction-data.service';
import { TransactionEntityService } from '@views/pages/apps/general/transactions/services/transaction-entity.service';
import { Dropdown } from '@core/models/dropdown.model';
import { memoize } from 'lodash';
import { BankHttpService } from '@core/services/bank-http.service';
import { MemberDataService } from './../../../members/services/member-data.service';
import { Pagination } from '@core/models/pagination.model';
import { MemberEntityService } from './../../../members/services/member-entity.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { WithdrawEntityService } from './../../services/withdraw-entity.service';
import { tap, catchError, delay, debounceTime, distinctUntilChanged, switchMap, map, finalize } from 'rxjs/operators';
import { Subscription, forkJoin, Observable, of, Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, ElementRef, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Withdraw } from '@core/models/withdraw.model';
import { WithdrawDataService } from '../../services/withdraw-data.service';
import { WithdrawMultiBankTransaction } from '@core/models/withdraw-multi.model';
import Swal from 'sweetalert2';
import { Transaction } from '@core/models/transaction.model';
import { CCIDLookupDialogComponent } from '../../../ccid-lookup/ccid-lookup.component';
import Echo from 'laravel-echo';
import { environment } from '@env/environment';
import { AppPermissionService } from '@core/services/app-permission.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MemberCryptoWalletHttpService } from '@core/services/member-crypto-wallet-http.service';
import { CryptoTokenHttpService } from '@core/services/crypto-token-http.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { DecimalPipe } from '@angular/common';
import { TransactionCallbackHttpService } from '@core/services/transaction-callback-http.service';
import { Stats } from '@core/enums/stats.enum';
import { WithdrawalMultiDialogComponent } from '../withdrawal-multi/withdrawal-multi.component';
import { WithdrawStatus } from '@core/enums/withdraw-status.enum';
import { BankTransactionStatus } from '@core/enums/bank-transaction-status.enum';
import { WithdrawalLogComponent } from '../withdrawal-log/withdrawal-log.component';
import { MatTabChangeEvent } from '@angular/material/tabs';

type WithdrawActionType = 'pending' | 'approve' | 'reject' | 'void' | 'mark-resolve' | 'payout' | 'update incomplete';

@Component({
  templateUrl: './withdrawal-edit.component.html',
  styleUrls: ['./withdrawal-edit.component.scss']
})
export class WithdrawalEditDialogComponent implements OnInit, OnDestroy {
  @ViewChild('withdrawalLog') withdrawalLogComponent!: WithdrawalLogComponent;

  // expose to template
  WithdrawStatusEnum = WithdrawStatus;

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  @ViewChild(WithdrawalMultiDialogComponent) withdrawMultiDialog: WithdrawalMultiDialogComponent;

  form: FormGroup;
  currentUserId = JSON.parse(localStorage.getItem('user_data')).id; // TODO: Refine and should not be in localStorage
  messages$ = this.withdrawDataService.messages$;
  // userPermissionsRisky = localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).process_risky_withdrawals : true;
  userPermissions$ = this.store.pipe(select(specialPermissions));
  pagination: Pagination;
  status = TransactionStatus;
  takeoverBy = {
    userId: null
  };
  withdrawStatus: number;
  statusCallback = Stats;
  withdraw = {
    ...this.data.withdraw
  };

  // approved new bank transaction index passed from withdrawal-multi
  newBankTransactionIndex: number;

  pageSize = 8;
  page = 1;
  maxSize = 5;
  purposeId = 2;
  members$ = [];
  dropdownSettings = {};
  memberBankDropdownSettings = {};
  memberBankDropdownList: any = [];
  selectedMemberBank = [];
  isSelectedMerchantBank = false;
  merchanBank = {
    name: '',
    accountNumber: null,
    accountName: ''
  };
  bankAccounts: any;
  dropdown = {
    members: this.dropdownHttpService.members,
    perPage: this.dropdownHttpService.perPage
  };
  transactionPagination: Pagination;
  transactions$: Observable<Transaction[]>;
  merchantBankAccounts: any;
  username = '';
  pageNumber = 1;
  params = '';
  lastPage = null;

  isValidRemarks = true;
  payNowParams = [{ param: 'virtual_payment_address', label: 'VPA' },
  { param: 'unique_entity_name', label: 'UEN' },
  { param: 'nric_fin', label: 'NRIC/FIN' },
  { param: 'mobile_number', label: 'Mobile Number' }];

  memberBankLoading = false;
  currencyCode = null;
  selectedToken = null;
  exchangeRate = null;
  selectedExchangeRate = 0;
  selectedMember = [];
  withdrawalLimit = null;

  transactionCallbackLogPage = 1;
  transactionCallbackLogsPagination: Pagination;
  transactionCallbackLogs = [];

  selectedTabIndex: number = 0;

  // permissions
  canEditWithdrawals: boolean;
  canProcessRiskyWithdrawals: boolean;
  canVoidWithdrawals: boolean;
  canViewWithdrawalAmount: boolean;
  canResolveUnusualCallback: boolean;
  canBypassBankingWithdrawalLimit: boolean;
  canViewWithdrawalEventHistory: boolean;

  private createBankTransactionPayload: WithdrawMultiBankTransaction;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;

  buttonLoading = false;
  isAdmin = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userPermissionsVoid = JSON.parse(localStorage.getItem('user_permissions')).void_deposit_withdrawal;
  checkValidation = false;

  processing_fee_valid : boolean = false;

  pgPayButton = false;

  private bufferSubject = new Subject<string>();
  private echo: Echo;
  session_id = this.generateUUID();
  session_extend_buffer_time = 3000;
  session_expired = false;
  session_init = false;

  showEventHistoryTab = false; // Only show this tab when 'edit' for wait update API run finish first, else the withdraw log might no get the latest data.

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { withdraw: Withdraw, mode: string, newHandler: string, withdrawalBankType: any },
    public dialogRef: MatDialogRef<WithdrawalEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private withdrawEntityService: WithdrawEntityService,
    private withdrawDataService: WithdrawDataService,
    private transactionHttpService: TransactionHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private memberEntityService: MemberEntityService,
    private memberDataService: MemberDataService,
    private bankHttpService: BankHttpService,
    private transactionEntityService: TransactionEntityService,
    private transactionDataService: TransactionDataService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private memberCryptoWalletHttpService: MemberCryptoWalletHttpService,
    private cryptoTokenHttpService: CryptoTokenHttpService,
    private decimalPipe: DecimalPipe,
    private transactionCallbackHttpService: TransactionCallbackHttpService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    this.formInit();

    // TODO: Revise!
    if (this.data.mode === 'edit') {
      this.onFocusField()
      if (this.data.newHandler) {
        this.refreshStatus = true;
      }
      if (
        this.withdraw.bo_user_id <= 0 ||
        this.withdraw.status === WithdrawStatus.Pending ||
        this.withdraw.status === WithdrawStatus.InProgress ||
        this.withdraw.status === WithdrawStatus.Risky ||
        this.withdraw.status === WithdrawStatus.IncompletePayout
      ) {
        this.transactionHttpService.updateUser('withdrawal', this.data.withdraw).subscribe((res: any) => {
          this.withdraw.confirmed_amount = res.confirmed_amount ? res.confirmed_amount : res.amount;
          this.withdraw.status = res.status;
          this.withdraw.status_name = res.status_name;
          if (this.withdraw.status === WithdrawStatus.Pending) {
            this.refreshStatus = true;
          }
          this.data.withdraw = { ...this.withdraw };
          this.showEventHistoryTab = true;
        });
      } else {
        this.showEventHistoryTab = true;
      }
      this.withdrawStatus = this.withdraw.status === WithdrawStatus.Pending ? WithdrawStatus.InProgress : this.withdraw.status;
      this.merchantBankAccounts = this.merchantBankHttpService.getMerchantBankAccounts(`&purpose=${this.purposeId}&currency=${this.data.withdraw.currency_id}`);

      this.onGetTransactionCallbackLogs();
    } else {
      this.setMembers();
      this.pagination = this.memberDataService.pagination;
      this.showEventHistoryTab = true;
    }

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.memberBankDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      maxHeight: 170,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };

    if (this.data.mode !== 'create') {
      this.onGetTransactions().subscribe();
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditWithdrawals = this.data.withdrawalBankType == 'crypto' ? appPermissions.edit_crypto_withdrawals : appPermissions.edit_withdrawals;
      this.canVoidWithdrawals = this.data.withdrawalBankType == 'crypto' ? appPermissions.void_crypto_withdrawals : true; // this void only used by Risky withdraw for non-crypto
      this.canProcessRiskyWithdrawals = this.data.withdrawalBankType == 'crypto' ? appPermissions.process_risky_crypto_withdrawals : appPermissions.process_risky_withdrawals;
      this.canViewWithdrawalAmount = this.data.withdrawalBankType == 'crypto' ? appPermissions.view_crypto_withdrawal_amount : appPermissions.view_banking_withdrawal_amount;
      this.canResolveUnusualCallback = this.data.withdrawalBankType == 'crypto' ? false : appPermissions.resolve_unusual_callback_withdrawal;
      this.canBypassBankingWithdrawalLimit = this.data.withdrawalBankType == 'crypto' ? false : appPermissions.bypass_banking_withdrawal_limit;
      this.canViewWithdrawalEventHistory = this.data.withdrawalBankType == 'crypto' ? false : appPermissions.view_withdrawal_event_history;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.echo.disconnect();
    this.onRefresh();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      this.withdrawalLogComponent.getTimeline(); // Call getTimeline in kt-withdrawal-log to get latest withdrawal-log
    }
  }

  onViewMoreRemarks(data: any) {
    this.memberDataService.getRemarksHistory(data.member_id).subscribe(res => {
      this.openDialogBy(MemberRemarksListComponent, { remarks: res });
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onGetTransactions(page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    const params = `&transaction_type_id[0]=1&transaction_type_id[1]=2&username=${this.data.withdraw.username}`;
    return this.transactions$ = this.transactionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.transactionPagination = this.transactionDataService.pagination;
      }),
      map(res => {
        // Replace comma with line break for pg_reference_id in each transaction
        res = res.map(transaction => {
          // Make a copy of the transaction object
          const newTransaction = { ...transaction };
          
          // Replace comma with line break for pg_reference_id in the copied transaction
          if (newTransaction.pg_reference_id) {
            newTransaction.pg_reference_id = newTransaction.pg_reference_id.replace(/,/g, '<br>');
          }
          return newTransaction; // Return the new transaction
        });
        return res;
      })
    );
  }

  onGetTransactionCallbackLogs(page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    const reference = this.data.withdraw.bank_transactions
        .filter(transaction => transaction.pg_reference_id)
        .map(transaction => transaction.pg_reference_id)
        .join(',');
    const params = `&reference=${reference}`;

    this.transactionCallbackHttpService.getCallbackLogs(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap((res: any) => {
        this.transactionCallbackLogsPagination = res.paginations;
        this.transactionCallbackLogs = res.rows;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onGetTransactions(this.page, this.pageSize);
  }

  bankTransactionsChanged(payload: WithdrawMultiBankTransaction) {
    if( payload.merchant_banks.length > 0 ) {
      if( this.data.withdrawalBankType == 'crypto' ) {
        this.eventEmitterService.onAddTransaction(this.withdraw, 'extend', true);
      } else {
        this.eventEmitterService.onAddTransaction(this.withdraw, 'extend', false);
      }
    } else {
      if( this.data.withdrawalBankType == 'crypto' ) {
        this.eventEmitterService.onAddTransaction(this.withdraw, 'drop', true);
      } else {
        this.eventEmitterService.onAddTransaction(this.withdraw, 'drop', false);
      }
    }
    this.createBankTransactionPayload = payload;
    this.processing_fee_valid = payload.merchant_banks.some((bank) => {
      const processingFee = +bank.processing_fee;
      const memberProcessingFee = +bank.member_processing_fee;
      const totalProcessingFee = +bank.total_processing_fee;
      return processingFee + memberProcessingFee !== totalProcessingFee;
    })
  }

  onSelectMerchantBank(bankID: number) {
    const bankId = bankID;
    const merchantBankData = this.merchantBankHttpService.getById(bankId);
    this.isSelectedMerchantBank = bankId ? true : false;
    if (bankId) {
      this.subscription = merchantBankData.pipe(
        tap((res) => {
          this.merchanBank.name = res.bank.name;
          this.merchanBank.accountNumber = res.account_number;
          this.merchanBank.accountName = res.account_name;
        })).subscribe();
    }
  }

  onPayNowBankName(data: any) {
    let accName = null;
    this.payNowParams.map(res => {
      if (data[res.param] !== '') {
        accName = res.label;
      }
    });
    return accName;
  }

  onPayNowBankNumber(data: any) {
    let accNumber = null;
    this.payNowParams.map(res => {
      if (data[res.param] !== '') {
        accNumber = data[res.param];
      }
    });
    return accNumber;
  }

  onLookup(accountNumber: string) {
    this.dialog.open(CCIDLookupDialogComponent, {
      width: '800px',
      data: {
        accountNumber: accountNumber
      }
    });
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first?.nativeElement.focus()
      )).subscribe();
  }

  /**
   * This withdraw update will refresh 'Bank Transaction' table inside withdrawal multi also.
   *
   * Make sure both 'withdraw' and 'bank_transactions' field exists inside payload
   * for withdrawal multi 'Bank Transaction' table to refresh
   *
   * @param payload 
   */
  handleUpdateWithdraw(payload) {
    this.data.withdraw = {
      ...this.data.withdraw,
      ...payload.withdraw,
      bank_transactions: [...payload.bank_transactions]
    };

    this.withdraw = {
      ...this.withdraw,
      ...payload.withdraw,
      bank_transactions: [...payload.bank_transactions]
    };

    this.withdrawStatus = payload.withdraw.status;
  }

  onTransactionAction(withdraw: Withdraw, type: WithdrawActionType, isPayout?: any) {
    this.buttonLoading = true;
    this.form.disable();

    if (type === 'payout') {
      const newBankTransaction = this.createBankTransactionPayload.merchant_banks[0];
      const isCrypto = this.data.withdrawalBankType == 'crypto';

      const data: any = {
        withdraw_id: this.createBankTransactionPayload.withdraw_id,
        amount: newBankTransaction.amount,
        member_account_id: this.withdraw.member_id,
        bank_account_id: this.withdraw.bank_account_id,
        merchant_bank_id: newBankTransaction.id,
        payee_ifsc_code: newBankTransaction.payee_ifsc_code,
        is_crypto: isCrypto ? 1 : 0,
        remarks: newBankTransaction.remarks
      };

      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

      // need to skip api error interceptor here (providing 'true" as second argument)
      this.withdrawDataService.createPayout(data, true).pipe(
        tap((res: any) => {
          this.updateWithdrawBankTransaction(res);
        }),
        catchError((error) => {
          const err = error.error;
          const message = '<ul>' + err.message.map((msg: string) => `<li>${msg}</li>`).join('') + '</ul>';
          const data = err.data;

          if (data) {
            this.updateWithdrawBankTransaction(data);
          }

          Swal.fire('System Message', message, 'error');

          throw message;
        }),
        finalize(() => {
          this.form.enable();
          this.buttonLoading = false;
        })
      ).subscribe();
    }

    if (type === 'reject' || type === 'approve' || type === 'void' || type === 'mark-resolve' || type === 'update incomplete') {
      let swalTitle, swalText = '';
      let remarksRequired = false;
      if (type === 'approve') {
        swalTitle = 'Approve Withdrawal';
        swalText = 'Do you want to approve this withdrawal?';
        remarksRequired = false;
      }
      if (type === 'update incomplete') {
        swalTitle = 'Update Withdrawal';
        swalText = 'Do you want to update this withdrawal?';
        remarksRequired = false;
      }
      if (type === 'reject' || type === 'void') {
        swalTitle = 'Reject Withdrawal';
        swalText = 'Do you want to reject this withdrawal?';
        remarksRequired = true;
      }
      if (type === 'mark-resolve') {
        swalTitle = 'Mark as Resolve';
        swalText = ' Would you like to mark as resolve?';
        remarksRequired = true;
      }

      let remarksHtml = `<p style="color: ${remarksRequired ? 'red' : ''};">Remark${remarksRequired ? '' : ' (Optional)'}</p></div><input id="remark-input" class="form-control">`;

      Swal.fire({
        title: swalTitle,
        html: '<div style="text-align: center;">' + remarksHtml + '<p style="margin-top: 20px;">' + swalText + '</p></div>',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
        preConfirm: () => {
          const inputValue = (document.getElementById('remark-input') as HTMLInputElement)?.value ?? '';

          if (inputValue.trim() === '' && remarksRequired) {
            Swal.showValidationMessage('Remarks is required.');
            return false;
          }

          return inputValue;
        }
      }).then((resp) => {
        if (resp.isConfirmed) {
          const inputValue = String(resp.value);

          if (type === 'mark-resolve') {
            const data = {
              id: withdraw.id,
              status: this.withdrawStatus,
              unusual_callback: 0,
              type: 'mark-resolve',
              remarks: inputValue
            };

            this.withdrawEntityService.update(data).pipe(
              tap((row: any) => {
                this.messages$.next([...row.message]);
                this.buttonLoading = false;
              }),
              catchError((error) => {
                this.form.enable();
                this.buttonLoading = false;
                throw error;
              })
            ).subscribe();
          }

          if (type === 'reject' || type === 'void') {
            this.subscription = this.withdrawEntityService.getByKey(withdraw.id).pipe(
              tap((res) => {
                const data = {
                  id: withdraw.id,
                  ...this.form.value,
                  status: WithdrawStatus.Rejected,
                  remarks: inputValue
                };
                this.transactionHttpService.checkCurrentUser(withdraw, type, res, data, this.dialogRef, 'withdrawal');
                this.transactionHttpService.messages$.subscribe(message => {
                  if (message.length > 0) {
                    this.messages$.next([...message]);
                    this.buttonLoading = false;
                  }
                });
      
                this.transactionHttpService.loadingState$.subscribe(isLoading => {
                  this.buttonLoading = isLoading;
                });
      
                for (const row in data) {
                  if (withdraw[row] !== res[row] && data[row] === withdraw[row]) {
                    this.withdraw[row] = res[row];
                    this.withdrawStatus = res[row];
                  } else {
                    this.withdraw[row] = data[row];
                    this.withdrawStatus = res[row];
                  }
                }
                this.form.enable();
                this.formInit();
              })).subscribe();
          }

          if (type === 'approve' || type === 'update incomplete') {
            this.createBankTransactionPayload.merchant_banks[0].remarks = inputValue;

            this.withdrawEntityService.update({
              id: withdraw.id,
              status: WithdrawStatus.Approved,
              remarks: inputValue,
              ...this.createBankTransactionPayload
            }).pipe(
              tap((res: any) => {
                this.buttonLoading = false;
                this.form.enable();

                const resData = res.data;

                Swal.fire({
                  title: 'Success',
                  text: res.message,
                  icon: 'info',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Yes',
                }).then(() => {
                  this.updateWithdrawBankTransaction(resData);
                });
              }),
              catchError((error) => {
                this.form.enable();
                this.buttonLoading = false;
                throw error;
              })
            ).subscribe();
          }
        } else {
          this.buttonLoading = false;
          this.form.enable();
        }
      });
    }

    if (type === 'pending') {
      this.withdrawStatus = WithdrawStatus.Pending;
      const data = {
        id: withdraw.id,
        status: this.withdrawStatus,
      }
      this.withdrawEntityService.update(data).pipe(
        tap((row: any) => {
          this.messages$.next([...row.message]);
        })
      ).subscribe();
    }
    this.refreshStatus = true;
  }

  updateWithdrawBankTransaction(data) {
    this.handleUpdateWithdraw(data);

    this.withdrawMultiDialog.onRemoveItem(this.newBankTransactionIndex);

    const isIncompletePayout = data.withdraw.status === this.WithdrawStatusEnum.IncompletePayout;

    // e.g. withdraw approved or rejected
    if (!isIncompletePayout) {
      this.onCloseDialog();
    }
  }

  onUpdateBankTransactions(event: any) {
    this.withdrawDataService.getById(this.data.withdraw.id).subscribe(res => {
      this.data.withdraw = res;
    })

  }

  handleApproveBankTransaction(payload) {
    this.newBankTransactionIndex = payload.index;

    this.onTransactionAction(this.data.withdraw, 'approve');
  }

  handlePayoutBankTransaction(payload) {
    this.newBankTransactionIndex = payload.index;

    this.onTransactionAction(this.data.withdraw, 'payout');
  }

  async onSave(withdraw: Withdraw) {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });
      delete this.form.value.bank_account_details;

      const data = {
        ...this.form.value
      };
      data['account_type'] = this.data.withdrawalBankType == 'crypto' ? 2 : 1;
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] < 1) && delete data[key]);
      if (this.data.withdrawalBankType == 'crypto') {
        this.handleWithdrawal(data);
      } else {

        const firewithdrawalLimitBypassConfirmation = async (title: string, messageText: string): Promise<boolean> => {
          const result = await Swal.fire({
            title,
            html: `${messageText} <br> Do you want to proceed with the withdrawal creation?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Bypass Limit',
            reverseButtons: true,
          });
          return result.isConfirmed;
        };
  
        const validateAndPrompt = async (): Promise<boolean> => {
          // Min-Max Validation
          let minMaxValidation = this.validateMinMaxPerTransactionAmount(data['amount']);
          // Daily Count Validation
          let dailyCountValidation = (this.withdrawalLimit.total_withdraw_count_today >= this.withdrawalLimit.daily_max_count && this.withdrawalLimit.daily_max_count != 0)  ? true : false;
          // Daily Max Validation
          let dailyMaxValidation = this.validateDailyMaxAmount(data['amount']);
  
          if (minMaxValidation || dailyCountValidation || dailyMaxValidation) {
            let messageText = '<div class="text-container withdrawal-limit-error" style="display: flex; justify-content: center; align-items: center; text-align: left;"><ul style="list-style: disc inside; padding: 0; margin: 0; max-width: 300px; word-wrap: break-word;">';
            if (dailyCountValidation) {
              messageText += '<li class="d-flex align-items-center"><i class="fas fa-info-circle mr-2"></i>' + 'Daily count : ' + this.withdrawalLimit.total_max_withdraw_count_today + '</li>';
            }
            if (minMaxValidation) {
              messageText += '<li class="d-flex align-items-center"><i class="fas fa-info-circle mr-2"></i>';
              if (this.validateMinMaxPerTransactionAmount(data['amount'], 'checkMin')) {
                messageText += 'Minimum Amount : ' + this.withdrawalLimit.currency_code + ' ' + this.withdrawalLimit.min;
              } else if (this.validateMinMaxPerTransactionAmount(data['amount'], 'checkMax')) {
                messageText += 'Maximum Amount : ' + this.withdrawalLimit.currency_code + ' ' + this.withdrawalLimit.max;
              }
              messageText += '</li>';
            }
            if (dailyMaxValidation) {
              messageText += '<li class="d-flex align-items-center"><i class="fas fa-info-circle mr-2"></i>' + 'Daily Maximum : ' + this.withdrawalLimit.currency_code + ' ' + this.withdrawalLimit.daily_max + '</li>';
            }
            messageText += "</ul></div>"
            if (this.canBypassBankingWithdrawalLimit) {
              const res = await firewithdrawalLimitBypassConfirmation('Invalid Transaction Amount', messageText);
              if (!res) return false;
            } else {
              await Swal.fire({
                  title: 'Invalid Transaction Amount',
                  html: `${messageText}`,
                  icon: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok',
                });
                return false;
            }
          }
    
          return true;
        };
        
        const isValidationPassed = await validateAndPrompt();
        if (isValidationPassed) {
          this.handleWithdrawal(data);
        } else {
          this.form.setErrors(null);
          this.buttonLoading = false;
          this.checkValidation = false;
        }
      }
      this.refreshStatus = true;
    }
  }

  handleWithdrawal(data: any) {
    this.subscription = forkJoin([
      this.withdrawDataService.add(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ),
      this.withdrawDataService.messages$
    ]).subscribe();
  }

  onGetLimit() {
    this.withdrawalLimit = null;

    const path = `?member_account_id=${this.selectedMember[0]['id']}`;

    this.withdrawDataService.getLimit(path)
      .subscribe(res => {
        this.withdrawalLimit = res;
      });
  }

  onUpdateRemarks() {
    const data = {
      id: this.data.withdraw.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (key !== 'remarks' && key !== 'id') && delete data[key]);
    this.withdrawDataService.updateRemarks(data).pipe(
      tap((row: any) => {
        this.messages$.next([...row.message]);
      })
    ).subscribe();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSearchMember(mode: string, event?: Event) {
    if (mode === 'edit') {
      this.merchantBankAccounts = this.merchantBankHttpService.getMerchantBankAccounts(`&purpose=${this.purposeId}&currency=${this.data.withdraw.currency_id}&keyword=${(event.target as HTMLSelectElement).value}`);
    } else {
      if (this.username !== (event.target as HTMLSelectElement).value) {
        this.members$ = [];
        this.pageNumber = 1;
        this.username = (event.target as HTMLSelectElement).value;
        this.setMembers();
      }
    }
  }

  onSelectMember(event: any) {
    this.selectedMember = event;
    this.onGetLimit();

    if (this.data.withdrawalBankType == 'crypto') {
      this.currencyCode = event[0]?.currency_code;
      this.memberBankLoading = true;
      this.resetCryptoFields();
      this.cryptoTokenHttpService.getTokenExchangeRate(`currency=${this.currencyCode}`).subscribe(res => {
        this.exchangeRate = res;
      })
      this.memberCryptoWalletHttpService.getWithQuery(`?member_account_id=${event[0]?.id}&status=1`).subscribe(banks => {
        banks.map(item => {
          item[`labelKey`] = item.token + ' - ' + item.network + ' - ' + item.wallet_address;
          item[`value`] = [item.token, item.network, item.wallet_address, item.wallet_nickname, item.id];
          return item;
        });
        this.memberBankLoading = false;
        this.memberBankDropdownList = banks;
      });
    } else {
      this.memberBankLoading = true;
      this.form.patchValue({
        bank_account_id: null,
        merchant_account_name: null,
        merchant_account_number: null
      });
      this.bankHttpService.getFilteredBanks(`?member_account_id=${event[0]?.id}&paginate=false&status=1`).subscribe(banks => {
        banks.map(item => {
          item[`labelKey`] = item.bank_code + ' - ' + item.account_name + ' - ' + item.account_number;
          item[`value`] = [item.account_name, item.account_number, item.id];
          return item;
        });
        this.memberBankLoading = false;
        this.memberBankDropdownList = banks;
      });
    }
  }

  onSelectBank(event: any) {
    // if (this.data.withdrawalBankType == 'crypto') {
    //   this.form.patchValue({
    //     token: event.token + ' - ' + event.network,
    //     wallet_address: event.wallet_address,
    //     wallet_nickname: event.wallet_nickname,
    //     bank_account_id: event.id
    //   });
    // } else {
    //   this.form.patchValue({
    //     merchant_account_name: event.account_name,
    //     merchant_account_number: event.account_number,
    //     bank_account_id: event.id
    //   });
    // }
  }

  onScrollToEnd() {
    if (this.pageNumber >= this.pagination.last_page) {
      return;
    }
    this.pageNumber++;
    this.setMembers();
  }

  private openDialogBy(componentRef: any, data?: { remarks: any }) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        remarks: data.remarks
      }
    });
  }

  private setMembers() {
    const members = this.memberEntityService.getWithQuery(`?status=1&page=${this.pageNumber}&username=${this.username}`);
    members.pipe(
      tap((res) => {
        Object.keys(res).forEach(key => this.members$.push(res[key]));
        this.pagination = this.memberDataService.pagination;
      })
    )
      .subscribe();
  }

  private formInit() {

    if (this.data.mode === 'edit') {
      this.form = new FormGroup({
        remarks: new FormControl(null, [Validators.minLength(1)]),
      });
      // this.checkRemarkInput();
    }

    if (this.data.mode === 'create') {
      this.form = new FormGroup({
        member_account_id: new FormControl(null, [Validators.required]),
        bank_account_id: new FormControl(null, [Validators.required]),
        bank_account_details: new FormControl(null),
        merchant_account_name: new FormControl(null),
        merchant_account_number: new FormControl(null),
        token: new FormControl(null),
        wallet_address: new FormControl(null),
        wallet_nickname: new FormControl(null),
        amount: new FormControl(null, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]+)?$')]),
        crypto_amount: new FormControl(null),
      });

      this.form.get('amount').valueChanges.subscribe(value => {
        if( this.data.withdrawalBankType == 'crypto' ) {
          const crypto_amount = value / this.selectedExchangeRate;
          this.form.get('crypto_amount')?.setValue(this.formatAmount('crypto', crypto_amount), { emitEvent: false });
        }
      });

      if( this.data.withdrawalBankType == 'crypto' ) {
        this.form.get('crypto_amount').valueChanges.subscribe(value => {
          const amount = value * this.selectedExchangeRate;
          this.form.get('amount')?.setValue(this.formatAmount('banking', amount), { emitEvent: false });
        });
      }

      // this.form.get('member_account_id').valueChanges.subscribe(memberAccountId => {
      //   if (this.data.withdrawalBankType == 'crypto') {
      //     this.memberBankLoading = true;
      //     this.form.patchValue({
      //       bank_account_id: null,
      //       merchant_account_name: null,
      //       merchant_account_number: null
      //     });
      //     this.memberCryptoWalletHttpService.getWithQuery(`?member_account_id=${memberAccountId}&status=1`).subscribe(banks => {
      //       banks.map(item => {
      //         item[`labelKey`] = item.token + ' - ' + item.network + ' - ' + item.wallet_address;
      //         item[`value`] = [item.token, item.network, item.wallet_address, item.wallet_nickname, item.id];
      //         return item;
      //       });
      //       this.memberBankLoading = false;
      //       this.memberBankDropdownList = banks;
      //     });

      //   } else {
      //     this.memberBankLoading = true;
      //     this.form.patchValue({
      //       bank_account_id: null,
      //       merchant_account_name: null,
      //       merchant_account_number: null
      //     });
      //     this.bankHttpService.getFilteredBanks(`?member_account_id=${memberAccountId}&paginate=false&status=1`).subscribe(banks => {
      //       banks.map(item => {
      //         item[`labelKey`] = item.bank_code + ' - ' + item.account_name + ' - ' + item.account_number;
      //         item[`value`] = [item.account_name, item.account_number, item.id];
      //         return item;
      //       });
      //       this.memberBankLoading = false;
      //       this.memberBankDropdownList = banks;
      //     });
      //   }
      // });

      this.form.get('bank_account_details').valueChanges.subscribe(
        bankAccountDetails => {
          if (bankAccountDetails !== null && bankAccountDetails !== '') {
            if (this.data.withdrawalBankType == 'crypto') {
              this.selectedToken = bankAccountDetails[0];
              this.onSelectExchangeRate();
              this.form.patchValue({
                token: bankAccountDetails[0] + ' - ' + bankAccountDetails[1],
                wallet_address: bankAccountDetails[2],
                wallet_nickname: bankAccountDetails[3],
                bank_account_id: bankAccountDetails[4]
              });
            } else {
              this.form.patchValue({
                merchant_account_name: bankAccountDetails[0],
                merchant_account_number: bankAccountDetails[1],
                bank_account_id: bankAccountDetails[2]
              });
            }
          }
        }
      );
    }
  }

  onSelectExchangeRate(): number {
    if (!this.exchangeRate) {
      return;
    }
    const exchangeRateEntry = this.exchangeRate.PriceList.find(entry => entry.Token === this.selectedToken);
    if (exchangeRateEntry) {
      this.selectedExchangeRate = exchangeRateEntry.Estimate_Withdrawal_Credit;
    }
  }

  // checkRemarkInput() {
  //   this.form.get('remarks').valueChanges.subscribe(res => {
  //     if (res) {
  //       this.isValidRemarks = true;
  //     }
  //   });
  // }

  private generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  handlePayButtonChange(pgPayButton: boolean) {
    this.pgPayButton = pgPayButton;
  }

  formatAmount(type: string, value: number): string {
    let decimal = 2;
    if( type == 'crypto' ) {
      decimal = 6;
    }
    return parseFloat(value.toFixed(decimal)).toString();
  }

  areAllBankTransactionsRejected = memoize(
    (bankTransactions) => {
      return bankTransactions.every(item => item.status == BankTransactionStatus.Rejected);
    }
  )

  resetCryptoFields() {
    this.form.patchValue({
      bank_account_id: null,
      merchant_account_name: null,
      merchant_account_number: null,
      token: null,
      wallet_address: null,
      wallet_nickname: null,
    });
    this.form.get('amount')?.setValue(null, { emitEvent: false });
    this.form.get('crypto_amount')?.setValue(null, { emitEvent: false });
    this.selectedMemberBank = [];
    this.selectedToken = null;
  }

  canShowAmount(amount: number, format: string) {
    let retAmount = '***';
    if( this.canViewWithdrawalAmount ) {
      retAmount = this.decimalPipe.transform(amount, format);
    }
    return retAmount;
  }

  validateMinMaxPerTransactionAmount(amount: number, type = null): boolean {
    const min = Number(this.withdrawalLimit.min);
    const max = Number(this.withdrawalLimit.max);
  
    if (type == 'checkMin') {
      return amount < min;
    } else if (type == 'checkMax') {
      return amount > max && max != 0;
    } else {
      return amount < min || (amount > max && max != 0)
    }
  }

  validateDailyMaxAmount(amount: number, type = null): boolean {
    const max = Number(this.withdrawalLimit.daily_max);
    const total_withdraw_today = Number(this.withdrawalLimit.total_withdraw_today);

    if (type == 'front' && (amount == 0 || amount == null)) {
      // Display as error when amount is equal on default
      return (total_withdraw_today + amount >= max && max != 0);
    } else {
      return (total_withdraw_today + amount > max && max != 0);
    }
  }
}
