import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { AffiliateInformationComponent } from './../all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AllAffiliatesDataService } from './../all-affiliates/services/all-affiliates-data.service';
import { AffiliateAdjustmentDialogComponent } from './dialogs/affiliate-adjustments-edit.component';
import { AffiliateAdjustmentDataService } from './services/affiliate-adjustment-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-affiliate-adjustments',
  templateUrl: './affiliate-adjustments.component.html',
  styleUrls: ['./affiliate-adjustments.component.scss']
})
export class AffiliateAdjustmentsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = "";
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    type: [
      {
        name: 'Main Wallet', value: 0
      },
      {
        name: 'Deposit Wallet', value: 1
      }
    ],
  };
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges = this.transactionHttpService.ranges;
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  affiliateAdjustments$ = [];
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canViewAffiliateDialog: boolean;
  canCreateAffiliateAdjustment: boolean;

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private affiliateAdjustmentDataService: AffiliateAdjustmentDataService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);
    this.setCurrencyDropdown();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewAffiliateDialog = appPermissions.affiliate_adjustments_view_affiliate_dialog;
      this.canCreateAffiliateAdjustment = appPermissions.create_affiliate_adjustment;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewInfo(mode: string, row?: any) {
    const affiliate = this.allAffiliatesDataService.getById(row.affiliate_id);
    this.subscription = affiliate.pipe(
      tap((res: any) => {
        this.dialog.open(AffiliateInformationComponent, {
          width: '1500px',
          height: '80vh',
          data: {
            affiliate: res,
            mode: mode
          }
        });
      })
    ).subscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.affiliateAdjustments$ = [];
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.affiliateAdjustmentDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap((res: any) => {
            this.affiliateAdjustments$ = res;
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.affiliateAdjustmentDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.affiliateAdjustments$ = [];
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.affiliateAdjustmentDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap((res: any) => {
        this.affiliateAdjustments$ = res;
        this.pagination = this.affiliateAdjustmentDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.form.patchValue({
      username: null,
      currency_id: "all",
      type_id: "all",
      start_datetime: null,
      end_datetime: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onOpenDialog() {
    this.openDialogBy(AffiliateAdjustmentDialogComponent);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
  private openDialogBy(componentRef: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '740px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page);
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      currency_id: new FormControl("all"),
      type_id: new FormControl("all"),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range,
    });
  }

}
