<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
              <form class="row align-items-center" [formGroup]="form">
              <!-- Filter first row -->
              <div class="col-12 row pr-0 mb-2">
                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <!-- VIP Level -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label class="mb-0">VIP Level</label>
                </div>
                <div class="col-md-2 kt-form__control custom-dropdown">
                  <kt-dropdown-wo-lazyload
                    [form]='form'
                    [dropdownList]='dropdown.groups'
                    [dropdownSettings]='vipLevelDropdownSettings'
                    [formName]="'member_group_ids'"
                    [selectionAttributes]="'id'"
                    [selectedItems]="vipLevelSelectedItems">
                  </kt-dropdown-wo-lazyload>
                </div>
                <!-- Reason -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Type:</label>
                </div>
                <div class="col-md-3 kt-form__control custom-dropdown">
                  <kt-dropdown-wo-lazyload
                    [form]='form'
                    [dropdownList]='dropdown.reason'
                    [dropdownSettings]='reasonDropdownSettings'
                    [formName]="'reasons'"
                    [selectionAttributes]="'id'"
                    [selectedItems]="reasonSelectedItems">
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
              <div class="col-12 row pr-0 mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <select formControlName="datetime_type" class="form-control" style="width: 30%;">
                      <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                    </select>
                    <input type="hidden" formControlName="start_datetime" >
                    <input type="hidden" formControlName="end_datetime" >

                    <div class="input-group date" style="width: 70%;">
                        <input type="text" class="form-control" placeholder="Search"
                        (change)="onDateRange($event)" formControlName="defaultDate"
                        [timePicker]="true" [timePickerSeconds]="true"
                        [alwaysShowCalendars]="true" [ranges]="ranges"
                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                        [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                        ngxDaterangepickerMd
                        [attr.disabled] = "interactiveInput.disableDate ? 'disabled': null"/>
                        <span class="input-group-append">
                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                        </span>
                    </div>
                  </div>
                </div>
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                      <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, vipVerificationStatus[item.name], i)" class="custom-control-input" [id]="item.name">
                      <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Account Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="account_type_condition" class="form-control" >
                    <option value="And">AND Condition</option>
                    <option value="Or">OR Condition</option>
                  </select>
                </div>
                <div class="col-md-3 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                    [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                    [formName]="'account_type'" [selectionAttributes]="'id'"
                    [selectedItems]='accountTypeSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
            
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div class="dropdown dropdown-inline">
          <button class="btn btn-import btn-icon-sm mr-2" *ngIf="canImportVip" (click)="onOpenDialog('import')" type="button"><i class="fas fa-file-import"></i>{{ 'Import' }}</button>
          <button class="btn btn-warning btn-icon-sm mr-2" *ngIf="canExportVip" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
          <button class="btn btn-brand btn-icon-sm" *ngIf="canCreateVip" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
        </div>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled text-center" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('level')" [ngClass]="sortingConfig.level" [class.sort-selected]="sortingSelection.sort_by === 'level'">VIP Level</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('currency_id')" [ngClass]="sortingConfig.currency_id" [class.sort-selected]="sortingSelection.sort_by === 'currency_id'">Currency</th>
                  <th class="text-center">Accumulated Deposits</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('reason')" [ngClass]="sortingConfig.reason" [class.sort-selected]="sortingSelection.sort_by === 'reason'">Type</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="sort-enabled date-column text-center" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created by</th>
                  <th class="sort-enabled date-column text-center" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated by</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="(members$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.id }}</td>
                  <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">{{ row.username }} </a></td>
                  <td class="level">{{ row.level }}</td>
                  <td class="text-center">{{ row.currency_code }}</td>
                  <td class="text-right">
                    Lifetime Deposit: {{ row.lifetime_deposits | number : '1.2-2' }} <br>
                    Annual Deposit: {{ row.annual_deposits | number : '1.2-2' }}
                  </td>
                  <td>{{ vipVerificationReason[row.reason] }}</td>
                  <td>
                    Remark: {{ row.remarks }}<br>
                    Adjustment: {{ row.adjustment_remark }}
                  </td>
                  <td class="text-center"><span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide"
                      [ngClass]="'kt-badge--' + row.status"> {{ vipVerificationStatus[row.status] }}</span></td>
                  <td>
                    {{ row.created_by ? row.created_by : 'System' }} <br />
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    {{ row.updated_by ? row.updated_by : 'System' }} <br />
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                      {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" matTooltip="Approve" (click)="onOpenDialog('approve', row)" *ngIf="row.status == 0 && canApproveVip"><i class="fas fa-check"></i></button>
                    <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" matTooltip="Reject" (click)="onOpenDialog('reject', row)" *ngIf="row.status == 0 && canRejectVip"><i class="fas fa-ban"></i></button>
                    <!-- <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" matTooltip="Edit" (click)="onOpenDialog('edit', row)" *ngIf="row.status != 0 && canEditVip" [disabled]="row.status == 2"><i class="fas fa-edit"></i></button> -->
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
              [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onSubmit()"></kt-swal-alert>
