import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, OnDestroy, HostListener } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { DialogDataService } from './services/dialog-data.service';
import { tap, map, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from "sweetalert2";
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { Dialog } from '@core/models/dialog.model';
import { Pagination } from '@core/models/pagination.model';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { UploadHttpService } from '@core/services/upload-http.service';
import { Lightbox } from 'ngx-lightbox';
import GLightbox from 'glightbox';
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { DialogPreviewComponent } from './dialogs/dialog-preview.component';
import { AppPermissionService } from '@core/services/app-permission.service';

type DisplaySuffixTypes = 'image' | 'content';

@Component({
    selector: 'dialogs',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
  })

export class DialogComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = { ...this.editorService.config };
  @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  formContent: FormGroup;
  messages$ = this.dialogDataService.messages$;
  data$ = this.dialogDataService.data$;
  imagePreview = [];
  checkFormContentMessages = '';
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;

  mode: any;
  dialog: any;
  initDialog: any;
  availableDialog = [];
  isEmptyContent = true;

  checkrender = false;

  dateTimeStack = {
    start_date: null,
    end_date: null,
  };

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  ranges = this.transactionHttpService.ranges;
  dialog$: Observable<Dialog[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  displayLocale: any;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;


  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];

  CTAVariables = [
    'external',
    'inbox',
    'deposit',
    'promotion',
    'referral'
  ];

  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  changedObjects: any;
  statusEvent: any;
  statusRow: any;

  ctaButtonTypes = [
    { id: 1, name: 'single' },
    { id: 2, name: 'dual' }
  ];
  
  sortingConfig = {
    'id': 'desc',
    'position': 'desc',
    'start_date': 'desc',
    'end_date': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    sessions: this.dropdownHttpService.marqueeSession,
    platforms: this.dropdownHttpService.dialogPlatform,
    locations: this.dropdownHttpService.dialogLocation,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
  };

  mediaTypes = [
    { id: 2, name: 'image'},
    { id: 1, name: 'video'}
  ];

  isImageUploading = new Array(2).fill(false);
  desktopLoading = false;
  mobileLoading = false;
  dialogImage = [];

  // permissions
  canCreateNewDialog: boolean;
  canViewDialogDetails: boolean;
  canEditDialog: boolean;
  canUpdateDialogStatus: boolean;
  canPreviewDialog: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();
  private datePickerSubscription = new Subscription();
  private uploadSub = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private dialogDataService: DialogDataService,
    private cdr: ChangeDetectorRef,
    public dialogs: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private domSanitizer:DomSanitizer,
    private uploadService: UploadHttpService,
    private lightbox: Lightbox,
    private editorService: EditorService,
    private appPermissionService: AppPermissionService,
  ) { }


  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.editorConfig.toolbar.items = [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'fontColor',
      '|',
      'insertTable',
      'bulletedList',
      'numberedList',
      'fontSize',
      'fontFamily'
    ]

    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.formInit();
      this.onSubmit();

      this.initDialog = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    this.dateTimeStack = {
      start_date: null,
      end_date: null,
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewDialog = appPermissions.create_new_dialog;
      this.canViewDialogDetails = appPermissions.view_dialog_details;
      this.canEditDialog = appPermissions.edit_dialog;
      this.canUpdateDialogStatus = appPermissions.update_dialog_status;
      this.canPreviewDialog = appPermissions.preview_dialog;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.uploadSub.unsubscribe();
  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          locale_id: new FormControl(element.id),
          content: new FormControl(null),
          title:  new FormControl(null),
          mobile_link:   new FormControl(null),
          desktop_link:   new FormControl(null),
          video_mobile_link:   new FormControl(null),
          video_desktop_link:   new FormControl(null),
          media_type:  new FormControl(null),
          cta_button_type: new FormControl(null),
          cta_button_text_1: new FormControl(null),
          cta_button_link_1: new FormControl(null),
          cta_button_text_2: new FormControl(null),
          cta_button_link_2: new FormControl(null),
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.form = new FormGroup({
      // Filter
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      status: new FormControl('all'),
      locale_id: new FormControl('all'),
      platform: new FormControl('all'),
      session: new FormControl('all'),
      location: new FormControl('all'),
      affiliates_visibility: new FormControl(0),
      code: new FormControl(null),
    });

    this.formContent = new FormGroup({
      // Create dialog
      platform: new FormControl(null, [Validators.required]),
      start_date: new FormControl(this.dateTimeStack.start_date, [Validators.required]),
      end_date: new FormControl(this.dateTimeStack.start_date),
      session: new FormControl(null, [Validators.required]),
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(null, [Validators.required]),
      contents: new FormGroup(buildContents()),
      location: new FormControl(null, [Validators.required]),
      affiliates_visibility: new FormControl(0, [Validators.required]),
      always_pop: new FormControl(0, [Validators.required]),
    });

    this.formContent.valueChanges.subscribe(data => {
      let isContent = false;

      Object.keys(data['contents']).forEach((sf) => {
        if ((data['contents'][sf]['content'] != null && data['contents'][sf]['content'] != '') ||
            (data['contents'][sf]['title'] != null && data['contents'][sf]['title'] != '') ||
            (data['contents'][sf]['media_type'] != null && data['contents'][sf]['media_type'] != '')) {
          isContent = true;
        }
      })
      this.isEmptyContent = isContent ? false : true;
    })
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;

    if (clearSearch) {
      this.onClear();
    }

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.dialog$ = this.dialogDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.displayLocale = this.displayLocale ? this.displayLocale : res.length && this.form.value.locale_id != 'all' ? this.dropdown.locales.filter(x => x.id == this.form.value.locale_id)[0] : this.dropdown.locales[0];
            this.page = 1;
            this.pagination = this.dialogDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.buttonLoading = false;
            this.dataLength = res.length;
            this.availableDialog = res;
            this.cdr.detectChanges();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.onClearDate();
    this.form.patchValue({
      start_date: null,
      end_date: null,
      defaultDate: null,
      status: 'all',
      locale_id: 'all',
      platform: 'all',
      session: 'all',
      location: 'all',
      affiliates_visibility: 0,
      code: null
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onClearDateContent() {
    if (this.formContent.value.start_date !== null) {
      this.form.patchValue({
        start_date: null,
        end_date: null
      });
    }
  }

  private generateSortingParam() {
      const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
      return sortingParams;
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearContent() {
    this.onClearDateContent();
    this.dateTimeStack = {
      start_date: new Date(),
      end_date: null,
    };

    this.formContent.patchValue({
      location: 1,
      platform: 1,
      start_date: this.datePipe.transform(new Date(), this.dateTimeFormat + '00'),
      end_date: null,
      session: 1,
      position: 99,
      status: 1,
      affiliates_visibility: 0,
      always_pop: 0
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            content: null,
            title: null,
            mobile_link:  null,
            desktop_link:  null,
            video_mobile_link:  null,
            video_desktop_link:  null,
            media_type: null,
            cta_button_text: null,
            cta_button_link:  null,
          }
        }
      });
    });

    this.initDialog = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {
    this.statusEvent = null;
    this.statusRow = null;
    this.isEmptyContent = true;
    this.mode = '';
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.buttonLoading = false;
  }

  expandRowCreate(mode: any, close?: boolean) {
    let dialogInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initDialog != dialogInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.dialog = null;
      this.changedObjects = false;
      this.initDialog = null;
      this.mode = mode;
      this.checkrender = false;

      setTimeout(() => {
        this.checkrender = true;
        this.cdr.detectChanges();
        this.datePickerSubscription = forkJoin([
          this.buildDatePicker(0, 'start_date'),
          this.buildDatePicker(1, 'end_date')
        ]).subscribe();
      }, 50);

      this.onClearContent();
    } else {
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      } else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.dialog == row ? true : false;
    let dialogInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initDialog && this.initDialog != dialogInput ? true : false;

    if (close != true && this.changedObjects == false) {

      this.dialog = row;
      this.changedObjects = false;
      this.initDialog = null;
      this.mode = mode;
      this.checkrender = false;

      setTimeout(() => {
        this.checkrender = true;
        this.cdr.detectChanges();
      }, 50);

      this.dateTimeStack = {
        start_date: new Date(new Date(this.dialog.start_date).getTime() + this.offset - this.clientOffset),
        end_date: this.dialog.end_date ? new Date(new Date(this.dialog.end_date).getTime() + this.offset - this.clientOffset) : null,
      };

      this.formContent.patchValue({
        platform: this.dialog.platform,
        start_date: moment(new Date(new Date(this.dialog.start_date).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss'),
        end_date: this.dialog.end_date ? moment(new Date(new Date(this.dialog.end_date).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null,
        session: this.dialog.session,
        position: this.dialog.position,
        status: this.dialog.status,
        location: this.dialog.location,
        affiliates_visibility: this.dialog.affiliates_visibility,
        always_pop: this.dialog.always_pop,
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.id]: {
              mobile_link:  null,
              desktop_link: null,
              video_mobile_link: null,
              video_desktop_link:  null,
              media_type:  null,
              cta_button_type: null,
              cta_button_text_1: null,
              cta_button_link_1: null,
              cta_button_text_2: null,
              cta_button_link_2: null,
              title:  null,
              content:  null
            }
          }
        });
      });
      
      setTimeout(() => {
        this.dialog.contents.forEach((element: any) => {
          this.formContent.patchValue({
            contents: {
              [element.locale_id]: {
                mobile_link:  element.media_type == 2 ? element.mobile_link : null,
                desktop_link: element.media_type == 2 ? element.desktop_link : null,
                video_mobile_link:  element.media_type == 1 ? element.mobile_link : null,
                video_desktop_link: element.media_type == 1 ? element.desktop_link : null,
                media_type:  element.media_type,
                cta_button_type: element.cta_button_type,
                cta_button_text_1: element.cta_button_text_1,
                cta_button_link_1: element.cta_button_link_1,
                cta_button_text_2: element.cta_button_text_2,
                cta_button_link_2: element.cta_button_link_2,
                title: element.raw_title,
                content: element.raw_content
              }
            }
          });
        });

        this.cdr.detectChanges();

        this.datePickerSubscription = forkJoin([
          this.buildDatePicker(0, 'start_date'),
          this.buildDatePicker(1, 'end_date')
        ]).subscribe();

        this.initDialog = JSON.stringify({ ...this.formContent.value });
      }, 500);

    } else {
      this.changedObjects = this.initDialog != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            if (this.dialog == row) {
              this.dialog = null;
            }
            else {
              this.dialog = null;
              this.checkReopen(mode, row);
            }
          }
        });
      } else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.dialog = null;
        this.onClearContent();
      }
    }

  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {

      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.onSubmit(clearSearch);
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.formContent.patchValue({ [formKey]: date });
      })
    );
  }

  displayLocaleContent(row: any, column: any) {
    let dialog = this.availableDialog.filter(x => x.id == row.id)[0]['contents'];
    dialog = dialog.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (dialog.length > 0) {
      if (column) {
        if(column == 'title' ) return dialog[0]['title'] ?  dialog[0]['title'] :  '-';
      }
      return dialog[0]['content']  ? dialog[0]['content'] :  '-';;
    } else {
      return '-';
    }
  }

  displayLocaleImageVideo(row: any, column: any) {
    let dialog = this.availableDialog.filter(x => x.id == row.id)[0]['contents'];
    dialog = dialog.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (dialog.length > 0) {
      if (column) {
        if (column == 'mobile' ) {
          let mobile_display = '';
          mobile_display = dialog[0]['mobile_link'];
          return dialog[0]['mobile_link'] ? mobile_display : '';
        }
        if (column == 'desktop' ) {
          let desktop_display = '';
          desktop_display = dialog[0]['desktop_link'];
          return dialog[0]['desktop_link'] ? desktop_display : '';
        }
      }
    } else {
      return '';
    }
  }

  displayLocaleImageVideoType(row: any, column: any) {
    let dialog = this.availableDialog.filter(x => x.id == row.id)[0]['contents'];
    dialog = dialog.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (dialog.length > 0) {
      if (column) {
        if (dialog[0]['media_type'] == 1) {
          if (column == 'desktop' && dialog[0]['desktop_link']) {
            if (dialog[0]['desktop_link'].includes("youtube")) {
              return 'youtube'
            }
            if (dialog[0]['desktop_link']) return 'video';
          }

          if (column == 'mobile' && dialog[0]['mobile_link']) {
            if (dialog[0]['mobile_link'].includes("youtube")) {
              return 'youtube'
            }
            if (dialog[0]['desktop_link']) return 'video';
          }
          return 'no video';
        }

        if (dialog[0]['media_type'] == 2) {
          if (column == 'desktop' && dialog[0]['desktop_link']) {
            if (dialog[0]['desktop_link']) return 'image';
          }
          if (column == 'mobile' && dialog[0]['mobile_link']) {
            if (dialog[0]['mobile_link']) return 'image';
          }
          return 'no image';
        }
      }
    } else {
      return '';
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  onChangeStatus($event, row: any, confirm: boolean) {
    this.statusEvent = $event;
    this.statusRow = row
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
      confirmation: confirm
    };

    this.subscription = this.dialogDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
        confirm ? this.data$.next(null) : null;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  onChangeStatusConfirm() {
    this.onChangeStatus(this.statusEvent, this.statusRow, true);
  }

  checkupdate() {
    return this.initDialog !== JSON.stringify({ ...this.formContent.value }) ? true : false;
  }

  onSave() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;
    var checkFormContent = true;

    checkFormContent = this.checkFormContent();

    if(checkFormContent == true){
      const data = {
        id: this.dialog ? this.dialog.id : null,
        ...this.formContent.value,
        start_date: moment(this.formContent.value.start_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(this.formContent.value.end_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      };

      Object.keys(data).forEach((key) => {
        if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
          delete data[key];
        }
        else if (key === 'contents') {
          Object.keys(data[key]).forEach((sf) => {
            if (data[key][sf] && data[key][sf]['media_type'] && data[key][sf]['media_type'] == 1 && data[key][sf]['video_desktop_link'] ) {
              data[key][sf]['desktop_link'] = data[key][sf]['video_desktop_link'];
            }

            if (data[key][sf] && data[key][sf]['media_type'] && data[key][sf]['media_type'] == 1 && data[key][sf]['video_mobile_link'] ) {
              data[key][sf]['mobile_link'] = data[key][sf]['video_mobile_link'];
            }
          })
        }
      });
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.formContent.setErrors({ 'invalid': true });
      switch (this.mode) {
        case 'edit':
          this.subscription = this.dialogDataService.updateDialog(this.dialog.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.dialogDataService.addDialog(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    } else {
      this.buttonLoading = false;

      Swal.fire({
        title: '<div class="text-center">Content Incomplate</div>',
        html: '<div class="text-center">'+this.checkFormContentMessages+'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Close'),
        // confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
      });
    }

  }

  checkFormContent() {
    this.checkFormContentMessages = '';

    const data = {
      ...this.formContent.value,
    };

    // here to check empty contents
    if (JSON.stringify(data['contents']).length < 3) {
      this.checkFormContentMessages = 'At least fill in a content or select a media type to upload a video/image';
      return false;
    }

    // here to check media_type and video/image
    Object.keys(data).forEach((key) => {
      if (key === 'contents') {
        Object.keys(data[key]).forEach((sf) => {
          if (data[key][sf]['media_type'] !== null ) {
            // platform: 1 = all , 2 = mobile, 3 = desktop
            // media_type: 1 = video , 2 =image
            if (data[key][sf]['media_type'] == 1) {
              if (data['platform'] == 1 && (data[key][sf]['video_desktop_link']  == null || data[key][sf]['video_mobile_link']  == null)) {
                this.checkFormContentMessages = 'Please fill in Video URL (Desktop) and Video URL (Mobile) ';
                return false;
              } else if(data['platform'] == 2 && data[key][sf]['video_mobile_link']  == null) {
                this.checkFormContentMessages = 'Please fill in Video URL (Mobile)';
                return false;
                }else if(data['platform'] == 3 && data[key][sf]['video_desktop_link']  == null) {
                this.checkFormContentMessages = 'Please fill in Video URL (Desktop)';
                return false;
              }
            } else if(data[key][sf]['media_type'] == 2){
              if(data['platform'] == 1 && (data[key][sf]['desktop_link']  == null || data[key][sf]['mobile_link']  == null)) {
                this.checkFormContentMessages = 'Please upload Image (Desktop) and Image (Mobile) ';
                return false;
              } else if(data['platform'] == 2 && data[key][sf]['mobile_link']  == null) {
                this.checkFormContentMessages = 'Please upload Image (Mobile)';
                return false;
              } else if(data['platform'] == 3 && data[key][sf]['desktop_link']  == null) {
                this.checkFormContentMessages = 'Please upload Image (Desktop)';
                return false;
              }
            }
          }
        })
      }
    });
    return this.checkFormContentMessages ? false : true;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }

      // User selection
      this.sortingSelection.sort_by = property;

      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      return this.dialog$ = this.dialogDataService.getWithQuery(`?${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
        tap(res => {
          this.pagination = this.dialogDataService.pagination;
          this.loading = false;
          this.dataLength = res.length;
          this.availableDialog = res;
          this.loadingBar.complete();
        })
      );
    }
    else {
      this.page = this.backupPage;
    }
  }

  addVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['content'] ? this.formContent.value.contents[id]['content'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          content: content + variable,
        }
      }
    });
  }

  addVariableCTA(variable: any, id: any, position: any) {
    let content = '';

    if (variable == 'external') {
      content = '/external?link=';
    } else if(variable == 'promotion') {
      content = '/promotion';
    } else if(variable == 'referral') {
      content = '/referral';
    } else if (variable == 'inbox') {
      content = '/member/message';
    } else if (variable == 'deposit') {
      content = '/member/deposit';
    }

    if (position == 1) {
      this.formContent.patchValue({
        contents: {
          [id]: {
            cta_button_link_1: content,
          }
        }
      });
    }
    else {
      this.formContent.patchValue({
        contents: {
          [id]: {
            cta_button_link_2: content,
          }
        }
      });
    }
  }

  onCTAButtonTypes(event: any, name: string, languageId: number) {
    if (event.target.checked) {
      if (name == 'single') {
        this.formContent.patchValue({
          contents: { [languageId]: { cta_button_type: 1 } }
        });

      } else {
        this.formContent.patchValue({
          contents: { [languageId]: { cta_button_type: 2 } }
        });
      }
    } else {
      if (name == 'dual') {
        this.formContent.patchValue({
          contents: { [languageId]: { cta_button_type: 2 } }
        });

      } else {
        this.formContent.patchValue({
          contents: { [languageId]: { cta_button_type: 1 } }
        });
      }
    }
  }

  addVariableTitle(variable: any, id: any) {
    let title = this.formContent.value.contents[id]['title'] ? this.formContent.value.contents[id]['title'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          title: title + variable,
        }
      }
    });
  }

  onUploadFile(event: any, image_type: any , localeId: number, num: number) {
    if (image_type === 'desktop_link') {
      this.desktopLoading = true;
    } else if (image_type === 'mobile_link') {
      this.mobileLoading = true;
    }

    this.isImageUploading[num] = true;

    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'promotions');

    this.uploadSub = this.uploadService.upload(formData).subscribe(res => {
      if (image_type === 'desktop_link') {
        this.desktopLoading = false;
      } else if (image_type === 'mobile_link') {
        this.mobileLoading = false;
      }
      this.imagePreview = res;
      this.formContent.patchValue({
        contents: { [localeId]: this.buildMediaPropertyBy( localeId, image_type) }
      });
      this.isImageUploading[num] = false;
      this.cdr.detectChanges();
    },
    err => {
      this.isImageUploading[num] = false;
      this.desktopLoading = false;
      this.mobileLoading = false;
    });
  }

  getMediaSource(languageId: number, image_type: any , propertySuffix: DisplaySuffixTypes = 'image') {
    let media: any;

    media = this.formContent.get(`contents.${languageId}.${image_type}`) ? this.formContent.get(`contents.${languageId}.${image_type}`).value : '';
    return media;
  }

  private buildMediaPropertyBy(languageId: number, propertySuffix?: DisplaySuffixTypes) {
    let output = {};
    output = { ...output, [propertySuffix]: this.imagePreview[0] };
    output = { ...output, settings_locale_id: languageId };
    return output;
  }

  onMediaTypes(event: any, name: string, localeId: number) {
    if (event.target.checked) {
      if (name == 'video') {
        this.formContent.patchValue({
          contents: { [localeId]:{media_type : 1 }  }
        });

      } else {
        this.formContent.patchValue({
          contents: { [localeId]:{media_type : 2 }  }
        });
      }
    } else {
      if (name == 'video') {
        this.formContent.patchValue({
          contents: { [localeId]:{media_type : 1 }  }
        });

      } else {
        this.formContent.patchValue({
          contents: { [localeId]:{media_type : 2 }  }
        });
      }
    }
  }

  removeImage(event: any, image_type: any , localeId: number) {

    if (image_type == 'desktop_link') {
      this.formContent.patchValue({
        contents: { [localeId]: {desktop_link : null }   }
      });
    }

    if (image_type == 'mobile_link') {
      this.formContent.patchValue({
        contents: { [localeId]: {mobile_link : null }   }
      });
    }

    this.cdr.detectChanges();
  }

  openImage(image: any) {
    this.dialogImage = [];
    this.dialogImage.push({
      src: image,
      thumb: image
    });

    this.lightbox.open(this.dialogImage, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
  }

  openVideo(video: any) {
    const myGallery = GLightbox({
      elements: [
        {
          'href': video,
          'type': 'video',
          'source': 'youtube', //vimeo, youtube or local
          'width': 900
        }
      ],
      autoplayVideos: true,
      closeButton: true
    });

    myGallery.open();
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              content: null,
              title: null,
              mobile_link: null,
              desktop_link: null,
              video_mobile_link: null,
              video_desktop_link: null,
              media_type: null,
              cta_button_type: null,
              cta_button_text_1: null,
              cta_button_link_1: null,
              cta_button_text_2: null,
              cta_button_link_2: null,
            }
          }
        });

        let contents = this.formContent.value.contents;
        this.isEmptyContent = true;

        Object.keys(contents).forEach((sf) => {
          if (contents[sf]['content'] != null || contents[sf]['title'] != null || contents[sf]['mobile_link'] != null ||
              contents[sf]['desktop_link'] != null || contents[sf]['video_mobile_link'] != null || contents[sf]['video_desktop_link'] != null ||
              contents[sf]['media_type'] != null) {
            this.isEmptyContent = false;
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

  checkContent(locale: any) {
    let mediaType = this.formContent.value.contents[locale.id].media_type;
    let ctaType = this.formContent.value.contents[locale.id].cta_button_type;
    let title = this.formContent.value.contents[locale.id].title;
    let content = this.formContent.value.contents[locale.id].content;

    if ((mediaType == null || mediaType == '') && (ctaType == null || ctaType == '') && (title == null || title == '') && (content == null || content == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  preview(row) {
    const dialog = row.contents.find(c => c.locale_name == this.displayLocale.code);
    const type = this.displayLocaleImageVideoType(row, 'desktop');

    this.dialogs.open(DialogPreviewComponent, {
      data: {
        dialog: dialog,
        type: type
      },
      autoFocus: false,
      width: '700px'
    })
  }

  displayPreviewIcon(row) {
    const dialog = row.contents.find(c => c.locale_name == this.displayLocale.code);
    return dialog ? true : false;
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let dialogInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initDialog && this.initDialog != dialogInput ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.dialog = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }

  onFilterAffVisibility($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      affiliates_visibility: isChecked
    });
  }

  onChangeAffVisibility($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.formContent.patchValue({
      affiliates_visibility: isChecked
    });
  }

  onChangeAlwaysPop($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.formContent.patchValue({
      always_pop: isChecked
    });
  }

  resetPopup() {
    this.data$.next(null);
    this.reload();
  }
}
