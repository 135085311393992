<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                      <div class="input-group date">
                                          <div class="input-group-prepend">
                                              <select formControlName="date_type" class="form-control">
                                                  <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                              </select>
                                          </div>
                                          <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                          <span class="input-group-append">
                                              <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                          </span>
                                      </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!--Action Button-->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateAffiliateGroup" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>

            <!--Listing-->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th class="text-center">Partner Network</th>
                                    <th class="text-center">Currency</th>
                                    <th class="text-center">Member Group</th>
                                    <th class="text-center">Default</th>
                                    <th class="text-center">Status</th>
                                    <th>Remarks</th>
                                    <th class="text-center">Created by</th>
                                    <th class="text-center">Updated by</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="affGroups$.length > 0">
                                <tr *ngFor="let row of affGroups$">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.name}}</td>
                                    <td class="text-center">{{ row.partner_network_name}}</td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td class="text-center">{{ row.member_group }}</td>
                                    <td class="text-center">
                                        <i [class]="row.default >= 1 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-info'"></i>
                                    </td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td>{{ row.remarks === '' ? '-' : row.remarks }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }} <br />
                                        <span placement="top" [ngbTooltip]="createddDateTime" container="body" class="text-ellipsis">{{ isValidDate(row.created_at) ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                                        <ng-template #createddDateTime>
                                          <ng-container *ngIf="isValidDate(row.created_at)">
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                          </ng-container>
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ isValidDate(row.updated_at) ? (row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                                        <ng-template #updatedDateTime>
                                          <ng-container *ngIf="isValidDate(row.updated_at)">
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                          </ng-container>
                                        </ng-template>
                                    </td>
                                    <td class="text-center actions-column">
                                        <button *ngIf="canViewSettings" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-cog"></i></button>
                                        <button *ngIf="canConfigureCommissionSettings" matTooltip="Commission Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('commission', row)"><i class="fa fa-cog"></i></button>
                                        <button *ngIf="canConfigureCurrencySettings" matTooltip="Currency Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('currency', row)"><i class="fas fa-dollar-sign"></i></button>
                                        <button *ngIf="canConfigureSettlementSettings" matTooltip="Settlement Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('settlement', row)"><i class="fas fa-sliders-h"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>