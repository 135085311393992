<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right mb-2">
        <div class="row align-items-center">
          <div class="col-xl-12 order-1 order-xl-1 pr-0">
            <div class="row mb-3">
              <div class="col-md-1 kt-form__label col-form-label">
                <label>Display Mode:</label>
              </div>
              <div class="col-md-11 kt-form__control dropdown dropdown-inline version-container">
                  <button class="version-select"
                    [ngClass]="selectedVersion == 2 ? 'version-selected' : ''" type="submit"
                    (click)="onSelectVersion(2)">Full Batch View</button>
                  <button class="version-select"
                    [ngClass]="selectedVersion ==1 ? 'version-selected' : ''" type="submit"
                    (click)="onSelectVersion(1)">Release Info View</button>
              </div>
            </div>
          </div>
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label" *ngIf="selectedVersion == 2">
                  <label>Batch ID:</label>
                </div>
                <div class="col-md-2 kt-form__control" *ngIf="selectedVersion == 2">
                  <input type="text" formControlName="rebate_batch_id" placeholder="{{ 'Search' | translate }}" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <kt-dropdown-wo-lazyload
                    [dropdownList] = "currencyDropdownList"
                    [dropdownSettings] = "currencyDropdownSettings"
                    [form] = "form"
                    [selectionAttributes] = "'id'"
                    [formName] = "'currency_id'"
                    [selectedItems]="currencySelectedItems"
                    (selectedItemsChanged)="currencySelectedItems = $event"
                  >
                  </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control" *ngIf="selectedVersion == 1">
                  <select formControlName="status" class="form-control">
                    <option value="all"> All </option>
                      <ng-container  *ngFor="let value of dropdown.statuses ; let i = index" >
                        <option [value]="i" *ngIf="value != 'Cancelled' ">
                          {{ value }}
                        </option>
                      </ng-container>
                  </select>
                </div>
                <div class="col-md-4 kt-form__control" *ngIf="selectedVersion == 2">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select formControlName="status_type" class="form-control">
                        <option value="job">Generation</option>
                        <option value="batch">Released</option>
                      </select>
                    </div>
                    <select formControlName="status" class="form-control">
                      <option value="all"> All </option>
                      <ng-container *ngIf="form.value.status_type == 'job'">
                        <ng-container  *ngFor="let value of dropdown.jobStatuses ; let i = index" >
                          <option [value]="i">
                            {{ value }}
                          </option>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="form.value.status_type == 'batch'">
                        <ng-container  *ngFor="let value of dropdown.statuses ; let i = index" >
                          <option [value]="i" *ngIf="value != 'Cancelled' ">
                            {{ value }}
                          </option>
                        </ng-container>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="col-md-1"  *ngIf="selectedVersion == 2">
                  
                </div>
                <div class="col-md-1 mt-2 kt-form__label col-form-label"  *ngIf="selectedVersion == 2">
                  <label>Remark:</label>
                </div>
                <div class="col-md-2 mt-2 kt-form__control" *ngIf="selectedVersion == 2">
                  <select formControlName="version" class="form-control">
                    <option value="all"> All </option>
                    <option value="1">Rebate 1.0</option>
                    <option value="2">Rebate 2.0</option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label" [ngClass]="selectedVersion == 2 ? 'mt-2' : ''">
                  <label>Date/Time:</label>
                </div>
                <div class="kt-form__control" [ngClass]="selectedVersion == 2 ? 'col-md-6 mt-2' : 'col-md-5'">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" formControlName="date_type">
                        <option value="calculation_at">Calculation Date</option>
                        <option value="created_at">Created Date</option>
                        <option value="updated_at">Updated Date</option>
                    </select>
                    </div>
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row mb-3">
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive" *ngIf="selectedVersion == 1">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('game_provider_code')" [ngClass]="sortingConfig.game_provider_code" [class.sort-selected]="sortingSelection.sort_by === 'game_provider_code'">Game Provider</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                  <th class="text-left sort-enabled" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Date</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('total_amount')" [ngClass]="sortingConfig.total_amount" [class.sort-selected]="sortingSelection.sort_by === 'total_amount'">Rebate<br>Amount</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                  <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                  <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                </tr>
              </thead>
              <tbody *ngIf="(rebateHistory$ | async) as rows">
                <tr *ngFor="let row of rows; let i = index">
                  <td  class="text-center">{{ row.id }}</td>
                  <td class="text-center">{{ row.game_provider_code }}</td>
                  <td class="text-center">{{ row.currency_code }}</td>
                  <td class="text-nowrap">
                    From: {{ row.start_datetime !== null ? (row.start_datetime | timezoneDate: 'YYYY-MM-DD') : '-' }}<br>
                    To: {{ row.end_datetime !== null ? (row.end_datetime | timezoneDate: 'YYYY-MM-DD') : '-' }}</td>
                  <td class="text-right">{{ row.total_amount | number : '1.2' }}</td>
                  <td class="text-center">
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge-rebate-history--' + row.status"> {{ row.status_name }}</span>
                  </td>
                  <td>{{ row.remarks }}</td>
                  <td>
                    {{ row.created_by ? row.created_by : 'System' }}<br>
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    {{ row.updated_by ? row.updated_by : 'System' }}<br>
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                      {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="table-responsive" *ngIf="selectedVersion == 2">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="toggle-column-area" (click)="toggleExpandableRows(); onExpandCollpseAll()">
                    <div id="accordion">
                      <span data-toggle="collapse" [attr.data-target]="'#collapse-0'" [attr.aria-expanded]="isRowsExpanded ? true : false"></span>
                    </div>
                  </th>                
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">Batch ID</th>
                  <th >Currency</th>
                  <th class="text-center sort-enabled" width="300" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Rebate Calculation Period</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Generation Status</th>
                  <th >Total Rebate Amount</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('version')" [ngClass]="sortingConfig.version" [class.sort-selected]="sortingSelection.sort_by === 'version'">Remark</th>
                  <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                  <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody *ngIf="(rebateHistory$ | async) as rows">
                <ng-container *ngFor="let row of rows; let i = index">
                  <!-- 1st layer data -->
                  <tr class="c-p" (click)="onExpandCollpse(row.id, i)">
                    <td>{{ rowIcon[i] }}</td>
                    <td class="text-center">{{ row.id }}</td>
                    <td class="text-center">
                      {{ onFormatDataCurrency(row.total_rebate_amount) }}
                    </td>
                    <td class="text-center" width="300">{{ row.start_datetime | timezoneDate:'YYYY-MM-DD' }}  ({{ row.start_datetime | timezoneDate:'ddd' }}) - {{ row.end_datetime | timezoneDate:'YYYY-MM-DD' }} ({{ row.end_datetime | timezoneDate:'ddd' }})</td>
                    <td class="text-center">
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge-rebate-job--' + row.status">{{ row.status_name }}</span>
                    </td>
                    <td  class="text-center">
                      <ng-container *ngFor="let amount of row.total_rebate_amount | keyvalue"><b class="pr-2">{{ amount.key ? onFormatData(amount.key) : '' }}:</b> {{ amount.value | number: '1.2-2' }} <br></ng-container>
                    </td>
                    <td class="text-right">{{ row.version  == 1 ? "Rebate 1.0" : "Rebate 2.0" }}</td>
                    <td>{{ row.created_by }}<br *ngIf="row.created_by">{{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</td>
                    <td>{{ row.updated_by }}<br *ngIf="row.updated_by">{{ row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</td>
                    <td class="text-center">
                      <a *ngIf="row.status == 1" class="btn btn-brand promotion-btn promocode-button" (click)="$event.stopPropagation();onGetDetails(row)"> {{ 'Details' | translate }}</a>
                    </td>  
                  </tr>
                  <!-- 2nd layer data  -->
                  <ng-container *ngIf="rowIcon[i] === '-'">
                    <tr class="detail" [id]="'detail-' + 1">
                      <td colspan="10">
                        <table class="table table-bordered mt-3">
                          <thead bgcolor="#eef1f6">
                            <th class="text-center">ID</th>
                            <th class="text-center">Game Provider</th>
                            <th class="text-center">Currency</th>
                            <th class="text-center">Rebate Amount</th>
                            <th class="text-center">Released Status</th>
                            <th class="text-center">Remarks</th>
                            <th class="text-center">Updated By</th>
                          </thead>
                          <tbody>
                            <ng-container *ngIf="row.rebate_batches.length > 0; else noData">
                              <tr *ngFor="let rebate of row.rebate_batches">
                                <td class="text-center">{{ rebate.rebate_batch_id }}</td>
                                <td class="text-center">{{ rebate.game_provider_code }}</td>
                                <td class="text-center">{{ rebate.currency_code }}</td>
                                <td class="text-right">{{ rebate.amount | number : '1.2' }}</td>
                                <td class="text-center">
                                  <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge-rebate-batch--' + rebate.status"> {{ rebate.status_name | translate }}</span>
                                </td>
                                <td>{{ rebate.remarks }}</td>
                                <td>{{ rebate.updated_by }}<br *ngIf="rebate.updated_by">{{ rebate.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</td>
                              </tr>          
                            </ng-container>
                            <ng-template #noData>
                              <tr>
                                <td colspan="7" class="text-center">{{ 'No data available' | translate }}</td>
                              </tr>            
                            </ng-template>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
