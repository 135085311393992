import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { SendMessageHistories } from '@core/models/send-message-histories.model';

@Injectable()
export class SendMessageHistoriesEntityService extends EntityCollectionServiceBase<SendMessageHistories>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SendMessageHistories', serviceElementsFactory)
  }
}
