import { AllSubAffiliateReportDataService } from '@views/pages/apps/reports/all-sub-affiliate-report/services/all-sub-affiliate-report-data.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment-timezone';
import { tap, map } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { TransactionHttpService } from '@core/services/transaction-http.service';

@Component({
  selector: 'kt-sub-affiliate',
  templateUrl: './sub-affiliate.component.html',
  styleUrls: ['./sub-affiliate.component.scss']
})
export class SubAffiliateComponent implements OnInit {
  pageSize = 30;
  page = 1;
  params = `parent_username=${this.data.affiliate.username}`;
  perPageDropdown = this.dropdownHttpService.perPage;
  pagination: Pagination;
  maxSize = 5;
  totalSubAffiliate = 0;
  form: FormGroup;
  button_loading = false;
  searchBtnLoading = false;
  subAffiliate$: any;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;

  dateTimeFilterType = [
    {
      key: 'register_at',
      label: 'Registration Date'
    }
  ];

  clearSearch = false;
  clearBtnLoading = false;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    private AllSubAffiliateReportDataService: AllSubAffiliateReportDataService,
    public dialogRef: MatDialogRef<SubAffiliateComponent>,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService
  ) { }

  ngOnInit() {
    this.formInit();
    this.getSubAffiliateData();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private getSubAffiliateData(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.button_loading = true;
    this.subAffiliate$ = [];
    this.searchBtnLoading = this.clearSearch ? false : true;
    this.loading = true;

    const data = this.form.value;

    if (this.form.value.start_date) {
      data.start_date = moment(this.form.value.start_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data.end_date = moment(this.form.value.end_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    if(this.form.value.start_date == null){
      delete data['date_type'];
    }
    delete data['defaultDate'];

    let formParams = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = formParams ? `&${formParams}` : '';

    this.AllSubAffiliateReportDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}${parameters}`).pipe(
      tap(res => {
        this.pagination = this.AllSubAffiliateReportDataService.pagination;
        this.totalSubAffiliate = this.pagination.total;
        this.subAffiliate$ = res;
        this.searchBtnLoading = false;
        this.clearSearch = false;
        this.clearBtnLoading = false;
        this.loading = false;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getSubAffiliateData(this.page, this.pageSize, this.params);
  }

  private formInit() {
    this.form = new FormGroup({
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClear() {
    this.form.patchValue({
      start_date: null,
      end_date: null,
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });
    this.clearSearch = true;
    this.clearBtnLoading = true;
    this.onSubmit();
  }

  onSubmit() {
    this.getSubAffiliateData();
  }
}
