<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <!-- Form -->
            <form class="row align-items-center" [formGroup]="form">
              <!-- First Row -->
              <div class="col-12 row mb-2 pr-0">
                <!-- Batch Id -->
                <div class="col-md-1 kt-form__label col-form-label" >
                  <label>Batch ID:</label>
                </div>
                <div class="col-md-2 kt-form__control" >
                  <input type="text" formControlName="rebate_batch_id" placeholder="{{ 'Search' | translate }}" class="form-control">
                </div>
                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <!-- Member Group -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Member Group:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="member_group_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="currency_id">
                    <option value="all">All</option>
                    <ng-container *ngFor="let value of dropdown.currencies">
                      <option [value]="value.id">{{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!-- Second Row -->
              <div class="col-12 row mb-2 pr-0">
                <!-- Game Provider -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Game Provider:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <kt-dropdown-wo-lazyload
                  [dropdownList] = "gameProviderDropdownList"
                  [dropdownSettings] = "gameProviderDropdownSettings"
                  [form] = "form"
                  [selectionAttributes] = "'id'"
                  [formName]="'game_provider_id'"
                  [selectedItems]="gameProviderSelectedItems"
                  (selectedItemsChanged)="gameProviderSelectedItems = $event"
              >
              </kt-dropdown-wo-lazyload>
                </div>
                <!-- Rebate Amount -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Release Amount:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <div class="input-group date">
                    <input class="form-control" formControlName="rebates" type="number" (wheel)="false" />
                  </div>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Account Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="account_type_condition" class="form-control" >
                    <option value="And">AND Condition</option>
                    <option value="Or">OR Condition</option>
                  </select>
                </div>
                <div class="col-md-3 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                    [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                    [formName]="'account_type'" [selectionAttributes]="'id'"
                    [selectedItems]='accountTypeSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
              <!-- Third Row -->
              <div class="col-12 row pr-0">
                <!-- Date Time -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" formControlName="date_type">
                        <ng-container *ngFor="let row of dropdown.dateType">
                            <option [value]="row.value">{{ row.name }}</option>
                        </ng-container>
                    </select>
                    </div>
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event, 'main')" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate('main')">X</span>
                     </span>
                  </div>
                </div>

                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>Status:</label>
                </div>
                <div class="col-md-5 kt-form__control" formArrayName="status">
                    <div class="d-flex flex-wrap status-filter-pt">
                      <ng-container *ngFor="let item of (dropdown.statuses | keyvalue)">
                          <ng-container *ngIf="item.key >= 0">
                            <div class="custom-control custom-checkbox mr-3">
                              <input type="checkbox" [value]="item.key" class="custom-control-input" [id]="item.key" [formControlName]="item.key">
                              <label class="custom-control-label text-nowrap" [for]="item.key">{{ item.value }}</label>
                          </div>
                          </ng-container>
                      </ng-container>
                    </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Action Button -->
      <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
        <div>
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div>
          <button *ngIf="canGenerateRebate" class="btn btn-brand btn-icon-sm mr-2" (click)="onOpenDialog('generate-rabates')"><i class="fas fa-plus"></i>Generate Rebates</button>
          <button *ngIf="canReleaseRebate" class="btn btn-success btn-icon-sm mr-2" (click)="rebateAction('release', 'selected')" [disabled]="!(selectedRebate.length > 0)"><i class="fas fa-check"></i>Release Selected</button>
          <button *ngIf="canReleaseRebate" class="btn btn-success btn-icon-sm mr-2" (click)="rebateAction('release', 'all')" [disabled]="selectedRebate.length > 0"><i class="fas fa-check"></i>Release All</button>
          <button *ngIf="canRejectRebate" class="btn btn-danger btn-icon-sm mr-2" (click)="rebateAction('reject', 'selected')" [disabled]="!(selectedRebate.length > 0)"><i class="fas fa-ban"></i>Reject Selected</button>
          <button *ngIf="canRejectRebate" class="btn btn-danger btn-icon-sm mr-2" (click)="rebateAction('reject', 'all')" [disabled]="selectedRebate.length > 0"><i class="fas fa-ban"></i>Reject All</button>
        </div>
      </div>

      <!-- Summary -->
      <div class="col-xl-12 summary-wrapper">
        <div class="row">
          <div class="col-md-3 pl-0 pr-0">
            <label>Selected Rows Total Rebates: </label><span class="ml-1">{{ selectedTotalRebates | number : '1.2' }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pl-0 pr-0">
            <label>Current Page Total Rebates: </label><span class="ml-1">{{ currentPageTotalRebates }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pl-0 pr-0">
            <label>Total Rebates: </label><span class="ml-1">{{ totalRebates }}</span>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <!-- Table -->
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr> 
                  <th><input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()"></th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('rebate_batch_id')" [ngClass]="sortingConfig.rebate_batch_id" [class.sort-selected]="sortingSelection.sort_by === 'rebate_batch_id'"> Batch ID</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('member_group_name')" [ngClass]="sortingConfig.member_group_name" [class.sort-selected]="sortingSelection.sort_by === 'member_group_name'">Member Group</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                  <th class="text-center date-column sort-enabled" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Rebate Calculation Period</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('game_provider_name')" [ngClass]="sortingConfig.game_provider_name" [class.sort-selected]="sortingSelection.sort_by === 'game_provider_name'">Game Provider</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('category_name')" [ngClass]="sortingConfig.category_name" [class.sort-selected]="sortingSelection.sort_by === 'category_name'">Game Category</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('winloss')" [ngClass]="sortingConfig.winloss" [class.sort-selected]="sortingSelection.sort_by === 'winloss'">W/L</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('valid_turnover')" [ngClass]="sortingConfig.valid_turnover" [class.sort-selected]="sortingSelection.sort_by === 'valid_turnover'">Valid Turnover</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('promo_turnover')" [ngClass]="sortingConfig.promo_turnover" [class.sort-selected]="sortingSelection.sort_by === 'promo_turnover'">Promo Turnover</th>
                  <th class="sort-enabled" (click)="onSortColumn('rate')" [ngClass]="sortingConfig.rate" [class.sort-selected]="sortingSelection.sort_by === 'rate'">Rate (%)</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('rebate_amount')" [ngClass]="sortingConfig.rebate_amount" [class.sort-selected]="sortingSelection.sort_by === 'rebate_amount'">Rebate Amount</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('release_amount')" [ngClass]="sortingConfig.release_amount" [class.sort-selected]="sortingSelection.sort_by === 'release_amount'">Release Amount</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                  <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>

                  <!-- <th class="text-center">Action</th> -->
                </tr>
              </thead>
              <tbody *ngIf="(rebateRelease$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <th [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id"></th>
                  <td class="text-center">{{ row.rebate_job_id }}</td>
                  <td class="text-center">{{ row.id }}</td>
                  <td>
                    <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">
                      {{ row.username }}
                    </a>
                  </td>
                  <td class="text-center">{{ row.member_group_name }}</td>
                  <td class="text-center">{{ row.currency }}</td>
                  <td style="min-width: 200px;">
                    {{ row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }} ({{ row.start_datetime | timezoneDate:'ddd' }}) - <br>{{ row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }} ({{ row.start_datetime | timezoneDate:'ddd' }})
                  </td>
                  <td class="text-center">{{ row.game_provider_name }}</td>
                  <td class="text-center">{{ row.category_name }}</td>
                  <td class="text-right">{{ row.winloss | number : '1.2' }}</td>
                  <td class="text-right">{{ row.valid_turnover | number : '1.2' }}</td>
                  <td class="text-right">
                    <!-- <a class="text-primary font-weight-bold" (click)="onOpenDialog('promo-turnover-details', null,row)"> -->
                      {{ row.promo_turnover | number : '1.2' }}
                    <!-- </a> -->
                  </td>
                  <td class="text-center">{{ row.rate | number : '1.0' }}</td>
                  <td class="text-right">{{ row.rebate_amount | number : '1.2' }}</td>
                  <td class="text-right">{{ row.release_amount | number : '1.2' }}</td>
                  <td class="text-center">
                    <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status_id"> {{ row.status }} </span><br>
                    <ng-container *ngIf="row.additional_info.related_rebates.length >= 1">
                      <ng-container *ngFor="let item of row.additional_info.related_rebates">
                        {{ item.id }}
                      </ng-container>
                  </ng-container>
                  </td>
                  <td>
                    {{ row.created_by ? row.created_by : 'System'}} <br>
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    {{ row.updated_by ? row.updated_by : 'System' }} <br>
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                      {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <!-- <td class="text-center">
                    <button [disabled]="row.status_id != 0" matTooltip="Release" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="rebateAction('release', 'single', row.id)"><i class="fas fa-check"></i></button>
                    <button [disabled]="row.status_id != 0" matTooltip="Reject" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="rebateAction('reject', 'single', row.id)"><i class="fas fa-ban"></i></button>
                  </td> -->
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>

      <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
    </div>
  </div>
</div>
