import { AuthHttpService } from '@core/services/auth-http.service';
import { AffiliateInformationComponent } from './../all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { MatDialog } from '@angular/material/dialog';
import { AllAffiliatesDataService } from './../all-affiliates/services/all-affiliates-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { AffiliateTransfersDataService } from './services/affiliate-transfers-data.service';
import { of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-affiliate-transfers',
  templateUrl: './affiliate-transfers.component.html',
  styleUrls: ['./affiliate-transfers.component.scss']
})
export class AffiliateTransfersComponent implements OnInit, OnDestroy {

  form: FormGroup;
  transfers$ = [];
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'Approved', value: 1, checked: true
      },
      {
        name: 'Rejected', value: 2, checked: false
      }
    ],
  }
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;
  searchStatus = [0, 1];
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status[0]=0&status[1]=1';
  originalParams = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  sortingConfig = {
    'id': 'desc',
    'affiliate_username': 'desc',
    'transfer_to': 'desc',
    'currency_id': 'desc',
    'amount': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];
  // permissions
  canViewAffiliateDialog: boolean;

  constructor(
    private affiliateTransfersDataService: AffiliateTransfersDataService,
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    public dialog: MatDialog,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit(true);
    this.originalParams = this.params;
    this.pagination = this.affiliateTransfersDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewAffiliateDialog = appPermissions.affiliate_transfer_view_affiliate_dialog;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewInfo(mode: string, row?: any) {
    const affiliate = this.allAffiliatesDataService.getById(row.affiliate_id);
    this.subscription = affiliate.pipe(
      tap((res: any) => {
        this.dialog.open(AffiliateInformationComponent, {
          width: '1500px',
          height: '80vh',
          data: {
            affiliate: res,
            mode: mode
          }
        });
      })
    ).subscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if(key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if(this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.transfers$ = [];
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.affiliateTransfersDataService.getWithQuery(`?${this.params}&page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.transfers$ = res;
            this.loading = false;
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.page = 1;
            this.pagination = this.affiliateTransfersDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.searchStatus = [0, 1];
    this.form.patchValue({
      id: null,
      username: null,
      affiliate_name: null,
      currency_id: 'all',
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      status: this.searchStatus,
      date_type: this.dateTimeFilterType[0].key
    });
    this.dropdown.statuses.forEach((child) => {
      if (child.name !== 'Pending' && child.name !== 'Approved') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.transfers$ = [];
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.affiliateTransfersDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.transfers$ = res;
        this.loading = false;
        this.pagination = this.affiliateTransfersDataService.pagination;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      affiliate_name: new FormControl(null),
      currency_id: new FormControl('all'),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      status: new FormControl(this.searchStatus),
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    })
  }

}
