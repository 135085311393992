import { tap, catchError } from 'rxjs/operators';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { VipSettingsDataService } from '../../services/vip-settings-data.service';
import { VipNameEvents } from '@core/models/vip-name.model';

@Component({
  selector: 'kt-vip-name-dialog',
  templateUrl: './vip-name-dialog.component.html',
  styleUrls: ['./vip-name-dialog.component.scss']
})
export class VIPNameDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales$: this.vipSettingsDataService.locales$,
  };
 
  messages$ = this.vipSettingsDataService.messages$;

  availableLocales = [];

  private subscription = new Subscription();

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { vip_names: VipNameEvents },
    public dialogRef: MatDialogRef<VIPNameDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private vipSettingsDataService: VipSettingsDataService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.setLocales();
  }

  ngOnDestroy() {
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;

    if (this.form.valid) {
      this.buttonLoading = true;
      this.form.setErrors({ 'invalid': true });

      this.dialogRef.close(this.form.value.vip_name_locales);
    }
  }

  private formInit() {
    this.form = new FormGroup({});
  }

  setLocales() {
    this.dropdown.locales$.subscribe(locales => {
      if (locales.length > 0){
        this.availableLocales = locales;
        this.generateLocale();

        Object.values(this.data.vip_names).forEach((res)=> {
          const detailsGroup = {
            settings_locale_id: res.settings_locale_id,
            name: res.name
          }
          this.form.patchValue({
            vip_name_locales : {[res.settings_locale_id]: detailsGroup} 
          });
        });
      }
    });
  }

  private generateLocale() {
    const buildLocales = () => {
      let fields = {};
      if (this.availableLocales.length > 0) {
        this.availableLocales.map((element: any) => {
          const subFields = new FormGroup({
            settings_locale_id: new FormControl(element.id),
            name: new FormControl(null),
          });
          fields = { ...fields, [element.id]: subFields };
        });
      }
        return fields;
    };
    this.form.addControl('vip_name_locales', new FormGroup(buildLocales()));

  }
}
    

  

  

