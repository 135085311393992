
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ReferralContentDataService } from './services/referral-content-data.service';
import { Dropdown } from '@core/models/dropdown.model';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { EventEmitterService } from '@core/services/event-emitter.service';
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-referral-content',
  templateUrl: './referral-content.component.html',
  styleUrls: ['./referral-content.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '80px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class ReferralContentComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if (this.checkupdate()) {
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }
  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config2;
  @ViewChildren('editors') editorComponent: QueryList<CKEditorComponent>;

  form: FormGroup;
  dropdown = {
    locales: [],
  };

  referralContent$: any;
  params = '';
  dropdownLocales: Dropdown[];
  loading = false;
  dataLength = 0;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  mode: any;
  displayLocale: any;
  buttonLoading = true;

  referralContent: any;
  updated_by: any;
  updated_at: any;
  created_by: any;
  created_at: any;
  availableReferralContent = [];

  formContent: FormGroup;
  initReferralContent: any;
  changedObjects: any;

  usableVariables = [
    ':brandprefix',
    ':brandname',
  ];

  private localesSub = new Subscription();
  messages$ = this.referralContentDataService.messages$;

  show = false;
  showMain = false;
  checkLoadingPage = false;

  // permissions
  canViewReferralContentDetails: boolean;
  canEditReferralContent: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    private referralContentDataService: ReferralContentDataService,
    private dropdownHttpService: DropdownHttpService,
    public sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private editorService: EditorService,
    private fb: FormBuilder,
    private eventEmitterService: EventEmitterService,
    private appPermissionService: AppPermissionService,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.displayLocale = this.dropdown.locales[0];
      this.formInit();
      this.onViewPageBy();
      this.initReferralContent = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewReferralContentDetails = appPermissions.view_referral_content_details;
      this.canEditReferralContent = appPermissions.edit_referral_content;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.localesSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onViewPageBy(params?: string) {
    this.loading = true;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.referralContentDataService.getWithQuery(`${params}`).subscribe(res => {
      this.referralContent$ = res;
      this.availableReferralContent = res;
      this.dataLength = res.length;
      this.loading = false;
      this.loadingBar.complete();
      this.cdr.detectChanges();
    });

  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          title: new FormControl(null),
          description: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formContent = new FormGroup({
      contents: new FormGroup(buildContents())
    });
  }

  onClearContent() {
    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            title: null,
            description: null
          }
        }
      });
    });
    this.initReferralContent = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {

    this.mode = '';
    this.showMain = false;
    this.onClearContent();
    this.onViewPageBy();

    this.initReferralContent = JSON.stringify({ ...this.formContent.value });
    this.buttonLoading = false;
  }

  onSanitize(data: string) {
    return this.sanitizer.bypassSecurityTrustHtml(data.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'))
  }

  onFormatString(data: string) {
    return data.split(",").join(", <br />");
  }

  checkupdate() {
    const referralContentcheck = this.initReferralContent !== JSON.stringify({ ...this.formContent.value }) ? true : false;
    return referralContentcheck ? true : false;
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {

    if (close != true) close = this.referralContent == row ? true : false;

    let referralContentInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initReferralContent && this.initReferralContent != referralContentInput ? true : false;
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.referralContent = row;
      this.changedObjects = false;
      this.initReferralContent = null;
      this.mode = mode;
      this.cdr.detectChanges();

      setTimeout(() => {
        this.created_at = this.referralContent[0].created_at;
        this.created_by = this.referralContent[0].created_by;
        this.updated_at = this.referralContent[0].updated_at;
        this.updated_by = this.referralContent[0].updated_by;
        this.referralContent.forEach((element: any) => {
          this.formContent.patchValue({
            contents: {
              [element.settings_locale_id]: {
                title: element.raw_title,
                description: element.raw_description
              }
            }
          });
        });

        this.cdr.detectChanges();

        this.initReferralContent = JSON.stringify({ ...this.formContent.value });
      }, 500);

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      this.changedObjects = this.initReferralContent != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.onClearContent();
            // Check if reopen needed
            if (this.referralContent == row) {
              this.referralContent = null;
            }
            else {
              this.referralContent = null;
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {
        this.mode = '';
        this.referralContent = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
    }
  }


  addTitleVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['title'] ? this.formContent.value.contents[id]['title'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          title: content + variable,
        }
      }
    });
  }

  addDescriptionVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['description'] ? this.formContent.value.contents[id]['description'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          description: content + variable,
        }
      }
    });
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  checkContent(locale: any) {
    let description = this.formContent.value.contents[locale.id].description ? this.formContent.value.contents[locale.id].description : null;

    if ((description == null || description == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  onSave() {

    this.buttonLoading = true;

    const data = {
      ...this.formContent.value,
    };

    // check must fill in contents and title , either one is fill-in
    let keepcheck = true;
    let fillInTitleNull = true;
    let fillInDescriptionNull = true;
    let locale = null;

    Object.keys(data['contents']).forEach((key) => {
      if (keepcheck) {
        if ((data['contents'][key]['title'] == null || data['contents'][key]['title'] == '') && (data['contents'][key]['description'] !== null && data['contents'][key]['description'] !== '')) {
          fillInTitleNull = false;
          keepcheck = false;
          locale = this.dropdown.locales.filter(x => x.id == key)[0]
        } else if ((data['contents'][key]['title'] !== null && data['contents'][key]['title'] !== '') && (data['contents'][key]['description'] == null || data['contents'][key]['description'] == '')) {
          fillInDescriptionNull = false;
          keepcheck = false;
          locale = this.dropdown.locales.filter(x => x.id == key)[0]
        }
      }
    });

    if (!fillInTitleNull || !fillInDescriptionNull) {
      let message = null;

      if (!fillInTitleNull) {
        message = "Please fill in title for locale " + locale['code'];
      } else if (!fillInDescriptionNull) {
        message = "Please fill in description for locale " + locale['code'];
      }

      Swal.fire({
        title: '<div class="text-center">' + message + '</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

      return false;
    }

    let checkcontent = false;

    Object.keys(data['contents']).forEach((key) => {
      if ((data['contents'][key]['title'] !== null && data['contents'][key]['title'] !== '') ||
        (data['contents'][key]['description'] !== null && data['contents'][key]['description'] !== '')
      ) {
        checkcontent = true;
      }
    });

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    if (checkcontent) {
      this.subscription = this.referralContentDataService.updateData(data).pipe(
        tap((res: any) => {
          this.messages$.next(res.message);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.formContent.setErrors(null);
          this.formContent.enable();
          throw error;
        })
      ).subscribe();
    } else {
      Swal.fire({
        title: '<div class="text-center">Fill in at least one locale content</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });
    }
  }

  displayLocaleContent(row: any, column?: any) {
    let referralContent = this.availableReferralContent.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (referralContent.length > 0) {
      if (column) {
        return referralContent[0]['description'] ? referralContent[0]['description'] : '-';
      } else {
        return referralContent[0]['title'] ? referralContent[0]['title'] : '-';
      }
    }
    else {
      return '-';
    }
  }

  showReadMoreButton(id) {
    var element = document.getElementById(id);
    if (element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }

  changeLocale(locale: any) {
    this.showMain = false;
    this.displayLocale = locale;
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              title: null,
              description: null
            }
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

}
