import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { SubheaderService } from '@core/_base/layout';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { Promotion } from '@core/models/promotion.model';
import { Observable, Subscription, interval, forkJoin, of } from 'rxjs';
import { PromotionCodeDataService } from './services/promotion-code-data.service';
import { PromotionCodeEntityService } from './services/promotion-code-entity.service';
import { tap, exhaustMap, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { Status } from '@core/enums/status.enum';
import { PromotionCodeDetailsDialogComponent } from './dialogs/promotion-code-details/promotion-code-details';
import * as moment from 'moment-timezone';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { Dropdown } from '@core/models/dropdown.model';
import Swal from 'sweetalert2';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { promotionDropdown } from '@core/models/promotion-dropdown.model';
import { PromotionCodeMemberEligibleDialogComponent } from './dialogs/promotion-code-member-eligible/promotion-code-member-eligible.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { PromotionSettingHttpService } from '@core/services/promotion-setting-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';

@Component({
  selector: 'kt-promotion',
  templateUrl: './promotion-code.component.html',
  styleUrls: ['./promotion-code.component.scss']
})
export class PromotionCodeComponent implements OnInit, OnDestroy, AfterViewInit {

  dropdown = {
    currencies: [],
    groups: this.dropdownHttpService.groups,
    promoTypes: this.dropdownHttpService.promoTypes,
    promoMemberGroups: [],
    targeTypes: this.dropdownHttpService.targetTypes,
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage,
    eligibilityTypes: [],
    bonusConditions: this.dropdownHttpService.bonusConditions,
    merchant: [],
    bonusSettings: [
      { id: 1, name: 'Wallet Bonus' },
      { id: 2, name: 'Deposit Bonus' },
    ],
    supported_target_type: this.dropdownHttpService.supportedTargetType,
    gameCategories: [],
    gameProviders: []
  };

  memberGroupDropdownList = [];
  memberGroupSelectedItems = [];
  memberGroupDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
  };

  gameCategoryDropdownList = [];
  gameCategorySelectedItems = [];
  gameCategoryDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
  }

  gameProviderDropdownList = [];
  gameProviderSelectedItems = [];
  gameProviderDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
  }

  currencyDropdownList = [];
  currencySelectedItems = [];
  currencyDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
    
  }

  messages$ = this.promotionCodeDataService.messages$;

  setPromoId: number | string;
  status = Status;
  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  promotionsDropdownSettings = {};
  promotionsSelectedItems = [];
  searchBonus = [];
  rowIcon = [];
  promotionCode = [];
  promoTypeahead = [];

  promotionCode$: Observable<Promotion[]>;

  // permissions
  canCreatePromotionCode: boolean;
  canViewPromotionCode: boolean;
  canDuplicatePromotionCode: boolean;
  canCheckMemberEligibility: boolean;

  userPermissions$ = this.store.pipe(select(specialPermissions));

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  getPromotionId: number;
  private refreshSubcription = new Subscription();


  dateTimeFilterType = [
    {
      key: 'start_at',
      label: 'Start Date'
    },
    {
      key: 'end_at',
      label: 'End Date'
    },
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ]

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dropdownHttpService: DropdownHttpService,
    private promotionCodeEntityService: PromotionCodeEntityService,
    private promotionCodeDataService: PromotionCodeDataService,
    private promotionSettingHttpService: PromotionSettingHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private store: Store<AppState>,
    private transactionHttpService: TransactionHttpService,
    private timeZoneDate: TimezoneDatePipe,
  ) { }

  ngOnInit() {
    this.ranges = this.transactionHttpService.ranges;    
    this.setCurrencyDropdown();
    this.formInit();
    this.promotionSettingHttpService.getAllPromoSettings().subscribe((res: any) => {
      this.dropdown.eligibilityTypes = res.eligible_types;
      this.memberGroupDropdownList = res.member_groups;
      this.gameCategoryDropdownList = res.promo_categories;
      this.promoTypeahead = res.promotions;
    });
    this.gameProviderHttpService.getGameProvidersByName().subscribe((res: any) => {
      this.gameProviderDropdownList = res;
    });
    this.dropdownHttpService.currencies.subscribe(res => {
      this.currencyDropdownList = res;
    });
    this.promotionsDropdownSettings = {
      singleSelection: true,
      text: 'All',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 150,
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.pagination = this.promotionCodeDataService.pagination;
    this.dropdown.bonusConditions.forEach(element => {
      element.checked = false;
    });
    this.searchBonus = [];

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreatePromotionCode = appPermissions.create_promotion_code;
      this.canViewPromotionCode = appPermissions.view_promotion_code;
      this.canDuplicatePromotionCode = appPermissions.duplicate_promotion_code;
      this.canCheckMemberEligibility = appPermissions.promotion_codes_check_member_eligibility;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onCloseDialog() {
    this.reload();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionCode$ = this.promotionCodeEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.promotionCodeDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onDuplicate(data: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to duplicate ${data.name}?`,
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((resp) => {
      if (resp.value) {
        this.loadingBar.start();
        this.subscription = forkJoin([
          this.promotionCodeDataService.duplicate(data),
          this.promotionCodeDataService.messages$,
        ]).subscribe();
      }
    });
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  onClear() {
    this.clearBtnLoading = true;
    this.currencySelectedItems = [];
    this.memberGroupSelectedItems = [];
    this.gameProviderSelectedItems = [];
    this.gameCategorySelectedItems = [];
    this.dropdown.bonusConditions.forEach(element => {
      element.checked = false;
    });
    this.searchBonus = [];

    this.form.get('promotion').enable();
    this.form.patchValue({
      id: null,
      promotion: null,
      // code: new FormControl(null),
      // promo_name: new FormControl(null),
      currency_id: this.currencySelectedItems,
      status: '1',
      member_group_id: this.memberGroupSelectedItems,
      category_id: this.gameCategorySelectedItems,
      game_provider_id: this.gameProviderSelectedItems,
      promo_type: 'all',
      target_type:'all',
      eligibility_type: 'all',
      bonus_condition: this.searchBonus,
      start_date: null,
      end_date: null,
      defaultDate: null, // Do not remove: For Clearing The Range
      date_type: this.dateTimeFilterType[0].key,
    });

    this.onSubmit(true);
    this.promotionsSelectedItems = [];
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    const formValue = this.form.value;
    formValue.currency_id = this.currencySelectedItems;
    formValue.member_group_id = this.memberGroupSelectedItems;
    formValue.category_id = this.gameCategorySelectedItems;
    formValue.game_provider_id = this.gameProviderSelectedItems;
    formValue.bonus_condition = this.searchBonus;

    if (formValue.promotion) {
      if (formValue.promotion.code != undefined && formValue.promotion.name != undefined) {
        formValue.code = formValue.promotion.code;
        formValue.promotion_name = formValue.promotion.name;
        delete formValue.promotion;
      }
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        
        if(data['defaultDate']){
          delete data['defaultDate'];
        }

        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => {
          const value = data[key];
          if (key != 'bonus_condition') {
            if (Array.isArray(value)) {
              return `${key}=${value.map(obj => obj.id).join(',')}`;
            } else {
              return `${key}=${value}`;
            }
          } else { // bonus_condition has no id
            return `${key}=${value.join(',')}`;
          }
        }).join('&');

        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.promotionCode$ = this.promotionCodeEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.promotionCodeDataService.pagination;
            this.promotionCode = res;
            
            res.map(() => {
              this.rowIcon.push('+');
            });
            this.loadingBar.complete();
            this.form.get('promotion').enable();
          })
        );
      }),
    ).subscribe();
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && formData[key] !== 'defaultDate') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      promotion: new FormControl({value: null, disabled: false}),
      // code: new FormControl(null),
      // promo_name: new FormControl(null),
      currency_id: new FormControl(this.currencySelectedItems),
      status: new FormControl('1'),
      member_group_id: new FormControl(this.memberGroupSelectedItems),
      category_id: new FormControl(this.gameCategorySelectedItems),
      game_provider_id: new FormControl(this.gameProviderSelectedItems),
      promo_type: new FormControl('all'),
      target_type: new FormControl('all'),
      eligibility_type: new FormControl('all'),
      bonus_condition: new FormControl(this.searchBonus),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      date_type: new FormControl(this.dateTimeFilterType[0].key),
    });
  }

  onOpenDialog(type: string, promotionCodeId?: number) {
    this.gameProviderHttpService.selectionMode = 'fresh';
    if (promotionCodeId) {
      const promotionCode = this.promotionCodeEntityService.getByKey(promotionCodeId);
      this.subscription = promotionCode.pipe(
        tap((res) => {
          switch (type) {
            case 'edit':
              this.openDialogBy(PromotionCodeDetailsDialogComponent, {
                promotionCode: res,
                mode: 'edit'
              });
              break;
            case 'duplicate':
              this.openDialogBy(PromotionCodeDetailsDialogComponent, {
                promotionCode: res,
                mode: 'duplicate'
              });
              break;
            case 'promo-eligible':
              this.openDialogBy(PromotionCodeMemberEligibleDialogComponent, {
                promotionCode: res,
                mode: 'promo-eligible',
              });
              break;
          }
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(PromotionCodeDetailsDialogComponent, { mode: 'create' });
    }
  }

  private openDialogBy(componentRef: any, data?: { promotionCode?: any, mode?: any }) {
    const isDetailDialog = data.mode == 'create' || data.mode === 'edit' || data.mode === 'duplicate';
    const dialogRef = this.dialog.open(componentRef, {
      width: isDetailDialog ? '90%' : data.mode == 'promo-eligible' ? '1500px' : '1200px',
      data: {
        promotionCode: data.promotionCode,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true && data.mode !== 'promo-eligible') {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateTargetTypeParams(params: string) {
    const searchTargetType = params.split(',');
    return {
      newTargetTypeParams: Object.keys(searchTargetType).map(key => `target_type[${key}]=${searchTargetType[key]}`).join('&'),
      oldTargetTypeParams: 'target_type=' + Object.keys(searchTargetType).map(key => searchTargetType[key]).join(','),
    };
  }

  onSearchBonus(event: any, value: string, ind: number) {
    const position = this.searchBonus.indexOf(value);
    if (event.target.checked) {
      this.searchBonus.push(value);
      this.dropdown.bonusConditions[ind].checked = true;
    } else {
      this.searchBonus.splice(position, 1);
      this.dropdown.bonusConditions[ind].checked = false;
    }

    this.form.patchValue({ bonus_condition: this.searchBonus });
  }

  filterPromo(term: string): Promotion[] {
    if (this.promoTypeahead) {
      return this.promoTypeahead.filter(
        promo => promo.code.toLowerCase().includes(term.toLowerCase()) || promo.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      return [];
    }
  }

  searchPromo = (search$: Observable<string>): Observable<Promotion[]> =>{
    return search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterPromo(term))
    )
  }

  formatPromo(promotion: Promotion): string {
    return `${promotion.code} - ${promotion.name}`;
  }

  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  timezoneDate(date: any, format: any) {
    return this.timeZoneDate.transform(date, format);
  }

}
