<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header" style="display: inline">
            <div>
                <h5 class="modal-title" style="display: inline">Total Sub-Affiliates By Affiliate - {{ totalSubAffiliate }} </h5>
            </div>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <div class="kt-form kt-form--label-right mb-2">
                    <div class="row align-items-center">
                        <div class="col-xl-12 order-2 order-xl-1 pr-0">
                            <form class="row align-items-center" [formGroup]="form">
                                <div class="col-12 row mb-2 pr-0">
                                    <!-- Date/Time-->
                                    <div class="col-md-2 kt-form__label col-form-label">
                                        <label>Date/Time:</label>
                                    </div>
                                    <div class="col-md-6 kt-form__control">
                                        <div class="input-group date">
                                            <div class="input-group-prepend">
                                                <select formControlName="date_type" class="form-control">
                                                    <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                                </select>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                            <span class="input-group-append">
                                                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                    <div class="dropdown dropdown-inline">
                        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                </div>
                <div class="kt-section mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                        <tr>
                                            <ng-container >
                                                <th> Username </th>
                                                <th> Name </th>
                                                <th> Aff. Group </th>
                                                <th class="date-column"> Registration Date </th>
                                                <th class="text-center"> Currency </th>
                                                <th class="text-center"> Clicks </th>
                                                <th class="text-center"> Members </th>
                                            </ng-container>
                                        </tr>
                                </thead>
                                <tbody *ngIf="subAffiliate$?.length > 0">
                                    <tr *ngFor="let row of subAffiliate$;">
                                        <ng-container>
                                            <td> {{ row.username }} </td>
                                            <td> {{ row.name }} </td>
                                            <td> {{ row.affiliate_group }} </td>
                                            <td> {{ row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm' }} </td>
                                            <td class="text-center"> {{ row.currency }} </td>
                                            <td class="text-center"> {{ row.clicks }} </td>
                                            <td class="text-center"> {{ row.member_count }} </td> 
                                        </ng-container>
                                    </tr>
                                    <kt-fallback-row [collection]="subAffiliate$"></kt-fallback-row>
                                </tbody>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="getSubAffiliateData(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>