import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BankTransactionStatus } from '@core/enums/bank-transaction-status.enum';
import { BankTransactionType } from '@core/enums/bank-transaction-type.enum';
import { Status } from '@core/enums/status.enum';
import { BankTransaction } from '@core/models/bank-transaction.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PdfDialogComponent } from '@shared/pdf-dialog/pdf-dialog.component';
import * as moment from 'moment-timezone';
import { Lightbox } from 'ngx-lightbox';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, map, tap } from 'rxjs/operators';
import { BankTransactionFormDialogComponent } from './dialogs/bank-transaction-form/bank-transaction-form.component';
import { BankTransactionDataService } from './service/bank-transaction-data.service';
import { BankTransactionEntityService } from './service/bank-transaction-entity.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-bank-transaction',
  templateUrl: './bank-transaction.component.html',
  styleUrls: ['./bank-transaction.component.scss']
})
export class BankTransactionComponent implements OnInit, OnDestroy {

  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  openMerchant = null;
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks: this.merchantBankHttpService.getMerchantBanksAccount(),
    bankTransactionTypes: this.dropdownHttpService.bankTransactionTypes,
    perPage: this.dropdownHttpService.perPage
  };
  bankTransactions$: Observable<BankTransaction[]>;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  transactionType = BankTransactionType;
  status = Status;
  ranges: any;
  transactionStatus = BankTransactionStatus;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '7.2';
  sortingConfig = {
    'id': 'desc',
    'payment_gateway': 'desc',
    'transaction': 'desc',
    'currency_code': 'desc',
    'amount': 'desc',
    'processing_fee': 'desc',
    'confirmed_amount': 'desc',
    'balance': 'desc',
    'status': 'desc',
    'trade_time': 'desc',
    'updated_at': 'desc',
    'created_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  summary: any;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  receiptList = [];

  object = Object;
  defaultStart = this.transactionHttpService.getLast24Hours().from;
  defaultEnd = this.transactionHttpService.getLast24Hours().to;
  button_loading = false;
  messages$ = this.bankTransactionDataService.messages$;

  // permissions
  canCreateBankTransaction: boolean;
  canUpdateBankTransactionStatus: boolean;
  canViewReceipts: boolean;
  canExportBankTransactions: boolean;

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ];

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();
  constructor(
    public dialog: MatDialog,
    private bankTransactionEntityService: BankTransactionEntityService,
    private bankTransactionDataService: BankTransactionDataService,
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private eventEmitterService: EventEmitterService,
    private lightbox: Lightbox,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.formInit();
    this.pagination = this.bankTransactionDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      // maxHeight: 'auto',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.merchantBankHttpService.getMerchantBanksAccount().subscribe(
      res => {
        res.map(elm => {
          elm.name = elm.bank_code + ' - ' + elm.account_name + (elm.account_number == null ? '' : ' - ' + elm.account_number);
        });
        this.bankaccountDropdownList = res;
        this.bankaccountDropdownList.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
      }
    );

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateBankTransaction = appPermissions.create_bank_transaction;
      this.canUpdateBankTransactionStatus = appPermissions.update_bank_transaction_status;
      this.canViewReceipts = appPermissions.view_receipts;
      this.canExportBankTransactions = appPermissions.export_bank_transactions;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, bankTransactionId?: number) {
    if (bankTransactionId) {
      const contact = this.bankTransactionEntityService.getByKey(bankTransactionId);
      this.subscription = contact.pipe(
        tap((res) => {
          switch (type) {
            case 'edit':
              this.openDialogBy(BankTransactionFormDialogComponent, { bankTransaction: res, mode: 'edit' });
              break;
          }
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(BankTransactionFormDialogComponent, { mode: 'create' });
    }
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onSearchMerchant(event?: Event) {
    this.openMerchant = true;
    this.dropdown.merchantBanks = this.merchantBankHttpService.getMerchantBankAccounts(`&keyword=${(event.target as HTMLSelectElement).value}`);
  }

  onSelectMerchantBank() {
    this.openMerchant = null;
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.bankTransactions$ = this.bankTransactionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.bankTransactionDataService.pagination;
        this.summary = this.bankTransactionDataService.summary;

        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.form.patchValue({
      merchant_bank_id: 'all',
      currency_id: 'all',
      transaction_type_id: 'all',
      keyword: null,
      start_date: this.defaultStart,
      end_date: this.defaultEnd,
      defaultDate: {
        startDate: this.defaultStart,
        endDate: this.defaultEnd
      },
      date_type: this.dateTimeFilterType[0].key
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.bankTransactions$ = this.bankTransactionEntityService.getWithQuery(`?perPage=${this.pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.bankTransactionDataService.pagination;
            this.summary = this.bankTransactionDataService.summary;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onOpenReceipt(row: any) {
    if (row['receipt_image'].indexOf('.pdf') >= 0) {
      this.dialog.open(PdfDialogComponent, {
        width: '800px',
        data: {
          pdfSrc: row['receipt_image']
        },
      });
    } else {
      this.receiptList = [];
      this.receiptList.push({
        src: row['receipt_image'],
        caption: ('deposit receipt').toLocaleUpperCase(),
        thumb: row['receipt_image']
      });
      this.lightbox.open(this.receiptList, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
    }
  }

  onChangeStatus(status: number, transactionId: any) {
    const data = {
      id: transactionId,
      status: status
    }
    this.bankTransactionDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.onViewPageBy(this.page).subscribe();
      })
    ).subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `?${this.params}` : '';
        this.loadingBar.start();
        return this.bankTransactionDataService.export(`${parameters}&${this.generateSortingParam()}&${parameters}`).pipe(
          tap(res => {
            this.messages$ = this.bankTransactionDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { bankTransaction?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        contact: data.bankTransaction,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      merchant_bank_id: new FormControl('all'),
      currency_id: new FormControl('all'),
      transaction_type_id: new FormControl('all'),
      start_date: new FormControl(this.defaultStart),
      end_date: new FormControl(this.defaultEnd),
      defaultDate: new FormControl({
        startDate: this.defaultStart,
        endDate: this.defaultEnd
      }), // Do not remove: For Clearing The Range
      keyword: new FormControl(null),
      date_type: new FormControl(this.dateTimeFilterType[0].key)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  getAmount(type: string, amount: string, processingFee: string) {
    if(type.toLowerCase() === 'deposit' || type.toLowerCase() === 'transfer in') {
      return Number(amount) + Number(processingFee);
    } else {
      return Number(amount);
    }
  }

  getConfirmedAmount(type: string, amount: string, processingFee: string) {
    if(type.toLowerCase() === 'withdraw' || type.toLowerCase() === 'transfer out') {
      return Number(amount) - Number(processingFee);
    } else {
      return Number(amount);
    }
  }

}
