<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">VIP Name</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <ng-container *ngIf="availableLocales.length > 0">
              <section class="col-12 p-4" formGroupName="vip_name_locales">
                <div class="row border-top border-left" *ngFor="let row of availableLocales; let last = last" [formGroupName]="row.id">
                  <div class="col-lg-4 col-md-4 card-header border-right p-3">
                    <span class="kt-font-bold">{{ row.code }}</span>
                    <input type="hidden" class="form-control" formControlName="settings_locale_id" [value]="row.id"/>
                  </div>
                  <div class="col-md-8 border-right p-3" [ngClass]="{'border-bottom': last}">
                    <input type="text" class="col-lg-12 form-control" formControlName="name"/>
                  </div>
                </div>
              </section>
            </ng-container>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSave()" #focusfield></kt-submit-button>
          </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>