<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Merchant Bank:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='bankaccountDropdownList' [dropdownSettings]='bankaccountDropdownSettings' [formName]="'merchant_bank_id'" [selectionAttributes]="'id'" [selectedItems]='bankaccountSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Transaction Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="transaction_type_id">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.bankTransactionTypes | async">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <div class="input-group-prepend">
                                            <select formControlName="date_type" class="form-control">
                                                <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Keyword:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Action Button -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button *ngIf="canExportBankTransactions" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="button_loading">
                        <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                    </button>
                    <button *ngIf="canCreateBankTransaction" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
                </div>
            </div>

            <!-- Summary -->
            <div class="col-xl-12 summary-wrapper" *ngIf="summary">
                <div class="row">
                    <div class="col-md-3 pl-0 pr-0">
                        <label>Total In: </label><span class="ml-1">{{ summary.total_in }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 pl-0 pr-0">
                        <label>Total Out: </label><span class="ml-1">{{ summary.total_out }}</span>
                    </div>
                </div>
            </div>

            <!-- Content -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('payment_gateway')" [ngClass]="sortingConfig.payment_gateway" [class.sort-selected]="sortingSelection.sort_by === 'payment_gateway'">Bank Account / Payment Gateway</th>
                                    <th class="sort-enabled" (click)="onSortColumn('transaction')" [ngClass]="sortingConfig.transaction" [class.sort-selected]="sortingSelection.sort_by === 'transaction'">Transaction </th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency </th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('processing_fee')" [ngClass]="sortingConfig.processing_fee" [class.sort-selected]="sortingSelection.sort_by === 'processing_fee'">Processing Fee (Company)</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('processing_fee')" [ngClass]="sortingConfig.confirmed_amount" [class.sort-selected]="sortingSelection.sort_by === 'confirmed_amount'">Confirmed Amount</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('balance')" [ngClass]="sortingConfig.balance" [class.sort-selected]="sortingSelection.sort_by === 'balance'">Balance</th>
                                    <th>References</th>
                                    <th>Remark</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <!-- <th class="date-column sort-enabled" (click)="onSortColumn('trade_time')" [ngClass]="sortingConfig.trade_time">Trade Time</th> -->
                                    <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th class="text-center">Receipt</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(bankTransactions$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.payment_gateway }}</td>
                                    <td>{{ row.transaction | titlecase}}</td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td class="text-right">{{ getAmount(row.transaction, row.amount, row.processing_fee) | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.processing_fee | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ getConfirmedAmount(row.transaction, row.amount, row.processing_fee) | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.balance | number : '1.2-2' }}</td>
                                    <td>{{ row.pg_reference_id || '-' }}</td>
                                    <td>{{ row.remarks }}</td>
                                    <!-- <td class="text-center pt-0">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                    </td> -->
                                    <td class="text-center">
                                        <div class="btn-group dropdown">
                                            <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + (row.status === 0 ? '2' : row.status === 2 ? '0' : row.status)"> {{ row.status_name }} </button>
                                            <ng-container *ngIf="canUpdateBankTransactionStatus">
                                              <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + (row.status === 0 ? '2' : row.status === 2 ? '0' : row.status)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <i class="fa fa-angle-down"></i>
                                              </button>
                                              <div class="dropdown-menu">
                                                  <ng-container *ngFor="let key of object.keys(transactionStatus)">
                                                      <button class="dropdown-item" type="button" *ngIf="+key >= 0" (click)="onChangeStatus(key, row.id)">{{ transactionStatus[key] }}</button>
                                                  </ng-container>
                                              </div>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        {{ row.created_by || 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by || 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canViewReceipts" matTooltip="View Receipt" [disabled]="row.receipt_image === '' || row.receipt_image === null || row.receipt_image === undefined" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenReceipt(row)"><i class="fas fa-receipt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
