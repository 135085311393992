import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from "@core/services/app-permission.service";
import { NewRebateReleaseDataService } from '../../services/new-rebate-release-data.service';
import { Pagination } from '@core/models/pagination.model';
import { tap, map, exhaustMap } from "rxjs/operators";
import * as moment from 'moment-timezone';

@Component({
  selector: 'kt-promotion-turnover-detail',
  templateUrl: './promotion-turnover-detail.component.html',
  styleUrls: ['./promotion-turnover-detail.component.scss'],
})
export class PromotionTurnoverDetailDialogComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  loading = false;
  dataLength = 0;
  buttonLoading = false;
  currencyTimezone = CurrencyTimezone;
  checkValidation = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  timezone = this.data.timezone ? this.data.timezone : JSON.parse(localStorage.getItem('user_data')).timezone;
  tz = moment().tz(this.timezone).format('Z');
  datetime = {
    start: moment(this.data.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    end: moment(this.data.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
  };
  unconvertedFrom = moment(this.data.start_datetime).format('YYYY-MM-DD HH:mm:ss'); // For display only
  unconvertedTo = moment(this.data.end_datetime).format('YYYY-MM-DD HH:mm:ss'); // For display only
  memberPromoTurnover$: any = [];
  totalTurnover=0;
  subtotalTurnover=0;
  totalWL=0;
  subtotalWL=0;

  sortingConfig = {
    'settlement_time': 'desc'

  };
  sortingSelection = {
    'sort_by': 'settlement_time',
    'sort_order': 'desc',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromotionTurnoverDetailDialogComponent>,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private newRebateHttpService: NewRebateReleaseDataService
  ) { }

  ngOnInit() {
    this.params = `game_provider_code=${this.data.game_provider_code}&game_category_code=${this.data.category_code}&game_sub_category=${this.data.game_provider_sub_categories_name}&start_datetime=${this.datetime.start}&end_datetime=${this.datetime.end}`
    this.onViewPageBy(this.page, this.pageSize, this.params).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params).subscribe();;
  }
  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    this.loading = true;
    params = this.params ? `&${this.params}` : '';

    return this.memberPromoTurnover$ = this.newRebateHttpService.showPromotionTurnoverDetails(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`, this.data.rebate_release_id)
    .pipe(
      tap(res => {
        if(res) {
          this.pagination = this.newRebateHttpService.pagination;
          this.totalTurnover = this.newRebateHttpService.totalTurnover;
          this.totalWL = this.newRebateHttpService.totalWL;
          this.subtotalTurnover = this.newRebateHttpService.subtotalTurnover;
          this.subtotalWL = this.newRebateHttpService.subtotalWL;
          this.dataLength = res.length;
        }
        this.loading = false;
      })
    );
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

}
