import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { WithdrawMultiBankTransaction } from '@core/models/withdraw-multi.model';
import { AffiliateWithdrawalEntityService } from './../services/affiliate-withdrawal-entity.service';
import { AffiliateWithdrawalDataService } from './../services/affiliate-withdrawal-data.service';
import { delay, tap, catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { AffiliateWithdrawals } from '@core/models/affiliate-withdrawals.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject, QueryList, ElementRef, ViewChildren, OnDestroy, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
import { WithdrawalMultiDialogComponent } from '../../withdrawals/dialogs/withdrawal-multi/withdrawal-multi.component';

@Component({
  selector: 'kt-affiliate-withdraw-edit',
  templateUrl: './affiliate-withdraw-edit.component.html',
  styleUrls: ['./affiliate-withdraw-edit.component.scss']
})
export class AffiliateWithdrawEditDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  @ViewChild(WithdrawalMultiDialogComponent) withdrawMultiDialog: WithdrawalMultiDialogComponent;

  form: FormGroup;
  refreshStatus: boolean;
  affiliateWithdraw = {
    ...this.data.affiliateWithdraw
  };
  affiliateWithdrawStatus: number;
  buttonLoading = false;
  currentUserId = JSON.parse(localStorage.getItem('user_data')).id;
  currentUsername = JSON.parse(localStorage.getItem('user_data')).username;
  messages$ = this.affiliateWithdrawalDataService.messages$;
  takeoverBy = {
    userId: null
  };
  purposeId = 2;
  merchantBankAccounts: any;
  isValidRemarks = true;

  // approved new bank transaction index passed from withdrawal-multi
  newBankTransactionIndex: number;

  private createBankTransactionPayload: WithdrawMultiBankTransaction;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canEditAffiliateWithdrawal: boolean

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliateWithdraw: AffiliateWithdrawals, mode: string, newHandler: string },
    private affiliateWithdrawalDataService: AffiliateWithdrawalDataService,
    private affiliateWithdrawalEntityService: AffiliateWithdrawalEntityService,
    public dialogRef: MatDialogRef<AffiliateWithdrawEditDialogComponent>,
    private merchantBankHttpService: MerchantBankHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    if (this.data.mode === 'edit') {
      this.onFocusField()
      if (this.data.newHandler) {
        this.refreshStatus = true;
      }
      if (this.affiliateWithdraw.bo_user_id <= 0 || this.affiliateWithdraw.status === 0 || this.affiliateWithdraw.status === 3 || this.affiliateWithdraw.status === 4) {
        this.updateUser(this.affiliateWithdraw).subscribe( (res: any) => {
          this.affiliateWithdraw.confirmed_amount = res.data.rows.confirmed_amount ? res.data.rows.confirmed_amount : res.data.rows.amount;
          if (this.affiliateWithdraw.bo_user_id !== this.currentUserId){
            this.refreshStatus = true;
          }
          if (this.affiliateWithdraw.status === 0){
            this.refreshStatus = true;
          }
        });
      }
      this.affiliateWithdrawStatus = this.affiliateWithdraw.status === 0 ? 3 : this.affiliateWithdraw.status;
      this.merchantBankAccounts = this.merchantBankHttpService.getMerchantBankAccounts(`&purpose=${this.purposeId}&currency=${this.data.affiliateWithdraw.currency_id}`);
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditAffiliateWithdrawal = appPermissions.edit_affiliate_withdrawal;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first.nativeElement.focus()
    )).subscribe();
  }

  bankTransactionsChanged(payload: WithdrawMultiBankTransaction) {
    this.createBankTransactionPayload = payload;
  }

  /**
   * This withdraw update will refresh 'Bank Transaction' table inside withdrawal multi also.
   *
   * Make sure both 'withdraw' and 'bank_transactions' field exists inside payload
   * for withdrawal multi 'Bank Transaction' table to refresh
   *
   * @param payload 
   */
  handleUpdateWithdraw(payload) {
    this.data.affiliateWithdraw = {
      ...this.data.affiliateWithdraw,
      ...payload.affiliate_withdraw,
      bank_transactions: [...payload.bank_transactions]
    };

    this.affiliateWithdraw = {
      ...this.affiliateWithdraw,
      ...payload.affiliate_withdraw,
      bank_transactions: [...payload.bank_transactions]
    };

    this.affiliateWithdrawStatus = payload.affiliate_withdraw.status;
  }

  handleApproveBankTransaction(payload) {
    this.newBankTransactionIndex = payload.index;

    this.onTransactionAction(this.data.affiliateWithdraw, 'approve');
  }

  handlePayoutBankTransaction(payload) {
    this.newBankTransactionIndex = payload.index;

    this.onTransactionAction(this.data.affiliateWithdraw, 'approve');
  }

  // Approve | Reject
  onTransactionAction(withdraw: AffiliateWithdrawals, type: string) {
    this.buttonLoading = true;
    if (type === 'reject') {
      if (this.form.get('remarks').value) {
        this.subscription = this.affiliateWithdrawalDataService.getById(withdraw.id).pipe(
          tap((res) => {
            const data = {
              id: withdraw.id,
              ...this.form.value,
              status: 2,
            };
            this.checkCurrentUser(withdraw, type, res, data, 'withdrawal');
            this.affiliateWithdrawalDataService.messages$.subscribe(message => {
              if (message.length > 0) {
                this.buttonLoading = false;
              }
            });

            for (const row in data) {
              if (withdraw[row] !== res[row] && data[row] === withdraw[row]) {
                this.affiliateWithdraw[row] = res[row];
                this.affiliateWithdrawStatus = res[row];
              } else {
                this.affiliateWithdraw[row] = data[row];
              }
            }
        })).subscribe();
      }else{
        Swal.fire({
          title: 'Input Error',
          text: 'Remarks required to reject',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(() => {
          this.isValidRemarks = false;
          this.buttonLoading = false;
          this.form.enable();
        });
      }
    }

    if (type === 'approve') {
      this.isValidRemarks = true;
      const data = {
        id: withdraw.id,
        status: 1,
        ...this.createBankTransactionPayload
      }
      this.updateWithdraw(data);
    }
  }

  checkRemarkInput() {
    this.form.get('remarks').valueChanges.subscribe(res => {
      if (res) {
        this.isValidRemarks = true;
      }
    });
  }

  onupdateCurrentStatus() {
    const data = {
      id: this.data.affiliateWithdraw.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (key !== 'remarks' && key !== 'id') && delete data[key]);
    this.affiliateWithdrawalDataService.updateToNewHolder(data).subscribe();
  }

  private updateUser(res: any) {
    const data = {
      id: res.id,
      status: res.status === 4 ? 4 : 3
    };
    return this.affiliateWithdrawalDataService.updateToNewHolder(data);
  }

  private checkCurrentUser(dbFirstData: any, type: string, dbLatestData: any, inputedData: any, transaction: string) {
    this.takeoverBy.userId = dbLatestData.bo_user_id;
    if (this.takeoverBy.userId !== this.currentUserId) {
      Swal.fire({
        title: `Someone is on the same ${transaction}!`,
        text: 'Do you to take over?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes and Reload',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.refreshStatus = true;
          const newUserData = {
            id: dbFirstData.id,
            status: dbLatestData.status === 1 || 4 ? 2 : 1
          };
          this.updateUser(newUserData).subscribe();
        }
        this.buttonLoading = false;
      });
    } else {
      this.updateTransaction(inputedData, type, transaction);
      return this.messages$;
    }
  }

  private updateTransaction(data: any, type: string, transaction: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${type} this ${transaction}?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        if (transaction === 'withdrawal') {
          this.updateWithdraw(data);
          this.affiliateWithdrawalEntityService.updateOneInCache(data);
        }
      }
      this.buttonLoading = false;
    });
  }

  onUpdateRemarks() {
    const data = {
      id: this.data.affiliateWithdraw.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (key !== 'remarks' && key !== 'id') && delete data[key]);
    this.affiliateWithdrawalDataService.updateRemarks(data).subscribe( () => {
      this.refreshStatus = true;
    });
  }

  private updateWithdraw(data: any){
    this.affiliateWithdrawalDataService.updateCurrentWithdraw(data).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        this.refreshStatus = true;

        this.handleUpdateWithdraw(res);

        this.withdrawMultiDialog.onRemoveItem(this.newBankTransactionIndex);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  private formInit() {
    if (this.data.mode === 'edit') {
      this.form = new FormGroup({
        remarks: new FormControl(this.data.affiliateWithdraw.remarks, [Validators.minLength(1)]),
      });
      this.checkRemarkInput();
    }
  }

}
