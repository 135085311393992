<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">

      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row pr-0 mb-2">
                <!-- ID -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>ID:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="id" placeholder="Search" class="form-control"/>
                </div>
                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" placeholder="Search" class="form-control"
                    (input)="toLowerCaseInput('username', $event)"/>
                </div>
                <!-- Member Group -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label class="mb-0">Member Group</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="member_group_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Member Group Type -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Member Group Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="member_group_type" class="form-control">
                    <option [value]="'all'">All</option>
                    <ng-container *ngFor="let item of dropdown.memberGroupTypes">
                      <option [value]="item.id">{{ item.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-12 row pr-0 mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <div class="input-group-prepend">
                      <select formControlName="date_type" class="form-control">
                          <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                      </select>
                  </div>
                    <input type="text" class="form-control" placeholder="Search"
                        (change)="onDateRange($event)" formControlName="defaultDate"
                        [timePicker]="true" [timePickerSeconds]="true"
                        [alwaysShowCalendars]="true" [ranges]="ranges"
                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                    ngxDaterangepickerMd/>
                    <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
                
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Account Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="account_type_condition" class="form-control" >
                    <option value="And">AND Condition</option>
                    <option value="Or">OR Condition</option>
                  </select>
                </div>
                <div class="col-md-3 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                    [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                    [formName]="'account_type'" [selectionAttributes]="'id'"
                    [selectedItems]='accountTypeSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
             
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Filter Action -->
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3 row">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div class="dropdown dropdown-inline">
          <button *ngIf="canExportVipMemberLogs" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="loading"><i [class]="exportBtnLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
        </div>
      </div>

      <!-- Table -->
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="sort-enabled" (click)="onSortColumn('member_group')" [ngClass]="sortingConfig.member_group" [class.sort-selected]="sortingSelection.sort_by === 'member_group'">Member Group</th>
                  <th>Remarks</th>
                  <th class="sort-enabled" (click)="onSortColumn('member_group_type')" [ngClass]="sortingConfig.member_group_type" [class.sort-selected]="sortingSelection.sort_by === 'member_group_type'">Member Group Type</th>
                  <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of pagedata">
                  <td>{{ row.id }}</td>
                  <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">{{ row.username }} </a></td>
                  <td>{{ row.member_group_name }}</td>
                  <td>
                    <ng-container *ngIf="row.prev_member_group_name != null; else emptyPrevMemberGroup">
                      {{ 'Adjustment: '}} {{ row.prev_member_group_name }} -> {{ row.member_group_name }}
                    </ng-container>
                    <ng-template #emptyPrevMemberGroup>
                      {{ 'Adjustment: '}} {{ row.member_group_name }}
                    </ng-template>
                  </td>
                  <td>{{ row.member_group_type }}</td>
                  <td>
                    {{ row.operator ? row.operator : 'System' }} <br />
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="loading" style="text-align: center;">Loading more...</div>
            <div *ngIf="!loading" style="text-align: center;">No more data available</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>