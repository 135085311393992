import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription, interval, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '@core/_base/layout';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ReferralSettingsEntityService } from './services/referral-settings-entity.service';
import { ReferralSettingsDataService } from './services/referral-settings-data.service';
import { ReferralSettingsEditDialogComponent } from './dialogs/referral-settings-edit.component';
import { ReferralSetting } from '@core/models/referral-setting.model';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
@Component({
  templateUrl: './referral-settings.component.html',
  styleUrls: ['./referral-settings.component.scss']
})
export class ReferralSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  currencyDropdown = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
  status = Status;
  referralSettings$: Observable<ReferralSetting[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  // permissions
  canCreateReferralSettings: boolean;
  canEditReferralSettings: boolean;

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  
  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    }
  ]

  ranges: any;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private referralSettingsEntityService: ReferralSettingsEntityService,
    private referralSettingsDataService: ReferralSettingsDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
  ) {}

  ngOnInit() {
    if(this.currencyDropdown.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.currencyDropdown = res;
      });
    }
    this.reload();
    this.formInit();
    this.pagination = this.referralSettingsDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateReferralSettings = appPermissions.create_referral_settings;
      this.canEditReferralSettings = appPermissions.edit_referral_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.referralSettings$ = this.referralSettingsEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.referralSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, row?: ReferralSetting) {
    if (type === 'edit') {
      this.openDialogBy(ReferralSettingsEditDialogComponent, { referralSetting: row, mode: 'edit' });
    } else {
      this.openDialogBy(ReferralSettingsEditDialogComponent, { mode: 'create' });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      currency_id: 'all',
      defaultDate: null,
      date_type: this.dateTimeFilterType[0].key
    });

    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {

        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.params = this.params ? `&${this.params}` : '';
        return this.referralSettings$ = this.referralSettingsEntityService.getWithQuery(`?perPage=${this.pageSize}${this.params}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.referralSettingsDataService.pagination;
            this.page = 1;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { referralSetting?: any, mode?: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        referralSetting: data.referralSetting,
        mode: data.mode,
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl('all'),
      date_type: new FormControl(this.dateTimeFilterType[0].key),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate' ) ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

}
