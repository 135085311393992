<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ data.mode === 'edit'? 'Edit' : 'Create'}} Promotion Content</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
          <div class="col-12 form-group">
            <label>Content Code <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="code" [ngClass]="{'is-invalid': checkValidation && form.controls.code.errors }">
          </div>
          <div class="col-12 form-group">
            <label>Promo Code <span class="text-danger"></span></label>
            <select formControlName="promotion_id" class="col-12 form-control">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="value.id" *ngFor="let value of dropdown.promotionIds | async">
                {{ value.code }} - {{ value.name }}
              </option>
            </select>
          </div>
          <div class="col-12 form-group">
            <label>Content Category <span class="text-danger">*</span></label>
            <!-- <select  class="form-control" formControlName="category_id">
              <option value="null" [disabled]="true">Please Select</option>
              <option *ngFor="let value of (dropdown.promoCategories | async) as promoCategories" [value]="value.id">{{ value.name | uppercase  }}</option>
            </select> -->
            <kt-dropdown-wo-lazyload
                [form] = 'form'
                [dropdownList] = 'categoriesDropdownList'
                [dropdownSettings] = 'categoriesDropdownSettings'
                [formName] = "'category_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'categoriesSelectedItems'
                [ngClass]="{'is-invalid': checkValidation && form.controls.category_id.errors }">
            </kt-dropdown-wo-lazyload>
          </div>
          <div class="col-12 form-group mb-4" formGroupName="content_type">
            <label>Content Type <span class="text-danger">*</span></label>
            <div class="d-flex flex-row" >
              <div *ngFor="let value of dropdown.displayTypes; let i = index">
                <label class="mr-4"><input type="checkbox" class="form-checkbox" [value]="value.id" [formControlName]="value.id" [ngClass]="{'is-invalid': checkValidation && form.controls.content_type.errors?.invalid }" (change)="isCheckboxValid()"> {{ value.label }} </label>
              </div>
            </div>
          </div>
          <div class="col-12 form-group mb-4">
            <label>Member Visibility <i class="fa fa-info-circle promotion-code-detail-tooltip" placement="top" matTooltipClass="tooltip-class" matTooltip="Determine the visibility of the promotion content.&#13;&#13;All Members : Promotion content will be viewable by all members.&#13;&#13;Eligible Members Only : Promotion content will be listed for members who meet the criteria specified in the 3.2 promotion code."  ></i></label>
            <div class="col-lg-9 pl-0">
              <div class="kt-radio-list">
                  <label class="kt-radio mr-3" *ngFor="let value of dropdown.memberVisibility">
                    <input type="radio" formControlName="member_visibility" [value]="value.value"> {{ value.name }} <span [ngClass]="{'radio-btn is-invalid': checkValidation && form.controls.member_visibility.errors }"></span>
                  </label>
              </div>
            </div>
            <!-- <div class="d-flex flex-row" >
              <label class="switch">
                <input type="checkbox" id="togBtn" formControlName="member_visibility">
                <div class="slider round">
                  <span class="on">ON</span>
                  <span class="off">OFF</span>
                </div>
              </label>
            </div> -->
          </div>
          <div class="col-12 form-group">
            <label>Status <span class="text-danger">*</span></label>
            <select class="form-control" *ngIf="dropdown.statuses" formControlName="status" [ngClass]="{'is-invalid': checkValidation && form.controls.status.errors }">
              <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
            </select>
          </div>
          <div class="col-12 form-group">
            <label>Position <span class="text-danger">*</span></label>
            <input type="number" (wheel)="false" class="form-control" formControlName="position" [ngClass]="{'is-invalid': checkValidation && form.controls.position.errors }" onkeydown="if (event.key === 'e' || event.key === 'E') event.preventDefault();">
          </div>
          <div class="col-12 form-group">
            <label><input type="checkbox" class="form-checkbox" formControlName="allow_apply" (change)="onAllowApply($event)"> Allow Apply</label>
          </div>
          <div class="row">
            <div class="col-6 form-group" [hidden]="!showApplyAction">
              <label>Apply Action</label>
              <select class="form-control" formControlName="apply_action" (change)="onApplyAction($event)">
                <option [value]=0 disabled hidden> Please Select </option>
                <option *ngFor="let item of (dropdown.applyActions | async) | keyvalue" [value]="+item.key">{{ item.value }}</option>
              </select>
            </div>
            <div class="col-6 form-group" *ngIf="showApplyAction" >
              <label>Max Application <span class="text-danger">*</span></label>
              <input type="number" (wheel)="false" class="form-control" formControlName="max_application">
            </div>
          </div>
          <div class="col-12 form-group" *ngIf="showSelectForm">
            <label>Select Form Fields</label>
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'formFields'
              [dropdownSettings] = 'formFieldsDropdownSettings'
              [formName] = "'fill_in_form'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'formFieldsSelectedItems'
              (selectedItemsChanged)="formFieldsSelectedItems = $event">
            </kt-dropdown-wo-lazyload>
          </div>
          <div class="col-12 p-0" *ngIf="showSelectLink">
            <label>Link <span class="text-danger">*</span><i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
          </div>
          <ng-template #linkToolTip>
            <div class="text-left">
              Internal Link: A hyperlink that points to the other pages on your website. <br>
              Examples:<br>
              /promotion?code=CODE0001<br>
              /referral<br>
              <br><br>
              External Link: A hyperlink that points to the target page on other websites.<br>
              Examples:<br>
              /external?link=https://example.com/mobileapp.apk<br>
              /external?link=https://google.com<br>
            </div>
          </ng-template>
          <div class="col-12 p-0 mb-5">
            <div class="input-group">

              <!-- Link Type -->
              <div class="col-3 p-0">
                <select class="form-control link-type-style" (change)="onChangeLinkFormat($event)" *ngIf="showSelectLink" formControlName="link_type">
                  <option [value]="null" [disabled]="true">Please Select</option>
                  <option *ngFor="let item of dropdown.linkTypes" [value]="item.id">{{ item.name }}</option>
                </select>
              </div>

              <!-- Internal/ External Link -->
              <div class="col-9 p-0" *ngIf="showLink">
                <input type="text" class="form-control link-style" formControlName="link">
                <span *ngIf="showUsableLink" class="header-hint">
                  <div *ngFor="let usableLink of usableLinks; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addUsableLink(usableLink)">{{ usableLink }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                  </div>
                </span>
              </div>
              <!-- Game Provider -->
              <div class="col-3 dropdown-style">
                <kt-dropdown-wo-lazyload *ngIf="showLaunchGameLink"
                  [form] = 'form'
                  [dropdownList] = 'providersDropdownList'
                  [dropdownSettings] = 'providersDropdownSettings'
                  [formName] = "'game_provider_code'"
                  [selectionAttributes] = "'code'"
                  [selectedItems] = 'providersSelectedItems'
                  (selectedItemsChanged)="providersSelectedItems = $event"
                  >
                </kt-dropdown-wo-lazyload>
              </div>

              <!-- Game Category -->
              <div class="col-2 dropdown-style">
                <kt-dropdown-wo-lazyload *ngIf="showLaunchGameLink"
                  [form] = 'form'
                  [dropdownList] = 'gameCategoriesDropdownList'
                  [dropdownSettings] = 'gameCategoriesDropdownSettings'
                  [formName] = "'game_category_code'"
                  [selectionAttributes] = "'category_code'"
                  [selectedItems] = 'gameCategoriesSelectedItems'
                  (selectedItemsChanged)="gameCategoriesSelectedItems = $event">
                </kt-dropdown-wo-lazyload>
                <i *ngIf="categoriesLoading" class="fas fa-circle-notch fa-spin p-0 mr-2 dropdown-loading"></i>
              </div>

              <!-- Games -->
              <div class="col-4 last-dropdown-style">
                <kt-dropdown-wo-lazyload *ngIf="showGames"
                  [form] = 'form'
                  [dropdownList] = 'gamesDropdownList'
                  [dropdownSettings] = 'gamesDropdownSettings'
                  [formName] = "'game_code'"
                  [selectionAttributes] = "'code'"
                  [selectedItems] = 'gamesSelectedItems'
                  (selectedItemsChanged)="gamesSelectedItems = $event">
                </kt-dropdown-wo-lazyload>
                <i *ngIf="gamesLoading" class="fas fa-circle-notch fa-spin p-0 mr-2 dropdown-loading"></i>
              </div>
            </div>
            <span *ngIf="showLaunchGameLink" class="form-text text-muted">Link: /launch{{ gameLaunchLink }}</span>
          </div>
          <mat-tab-group animationDuration="0ms" class="col-12 form-group" formGroupName="details">
            <ng-container *ngIf="languages.length > 0">
              <mat-tab *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                <ng-template mat-tab-label>
                  {{language.code}}
                  <mat-icon *ngIf="data.mode === 'edit' && data.promotionContent.details[language.id] !== undefined" class="btn-outline-hover-info btn-icon btn-delete" (click)="onDeleteLocale(data.promotionContent.details[language.id], $event)"><i class="fas fa-trash-alt"></i></mat-icon>
                </ng-template>
                <input type="hidden" formControlName="settings_locale_id" [value]="language.id" />
                <input type="hidden" formControlName="id" [value]="data.promotionContent.content.id" *ngIf="data.mode === 'edit'" />
                <div class="col-12 form-group p-0 m-0">
                  <label>Title <span class="text-danger">*</span></label>
                  <input type="text" formControlName="title" class="col-12 form-control" (keyup)="onChangeValidate($event, language.id)">
                </div>
                <div class="col-12 p-0 mb-2">
                  <span class="header-hint">Usable variables:
                    <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                      <p class="p-inline" (click)="addVariableTitle(usableVariable, language.id)">{{ usableVariable }}</p>
                      <div *ngIf="!last" class="p-inline">
                        <p class="p-inline p-display">, </p>
                      </div>
                    </div>
                  </span>
                </div>

                <div class="col-12 form-group p-0 m-0">
                  <label>Description <span class="text-danger">*</span></label>
                  <input type="text" formControlName="description" class="col-12 form-control">
                </div>
                <div class="col-12 p-0 mb-2">
                  <span class="header-hint">Usable variables:
                    <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                      <p class="p-inline" (click)="addVariableDescriptions(usableVariable, language.id)">{{ usableVariable }}</p>
                      <div *ngIf="!last" class="p-inline">
                        <p class="p-inline p-display">, </p>
                      </div>
                    </div>
                  </span>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-6 form-group pl-0">
                      <label>Start Date</label>
                      <div class="input-group date">
                        <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack[i]?.start" [ngModelOptions]="{standalone: true}">
                        <input type="text" formControlName="start" placeholder="Search" class="form-control">
                        <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                            <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                        </span>
                        <owl-date-time #dateStartRef></owl-date-time>
                      </div>
                    </div>
                    <div class="col-6 form-group pl-0 pr-0">
                      <label>End Date</label>
                      <div class="input-group date">
                        <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack[i]?.end" [ngModelOptions]="{standalone: true}">
                        <input type="text" formControlName="end" placeholder="Search" class="form-control">
                        <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                            <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                        </span>
                        <owl-date-time #dateEndRef></owl-date-time>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-6 form-group pl-0">
                      <label>Publish Date <span class="text-danger">*</span></label>
                      <div class="input-group date">
                        <input class="hidden" [owlDateTime]="datePublishRef" [ngModel]="dateTimeStack[i]?.publish_at" [ngModelOptions]="{standalone: true}">
                        <input type="text" formControlName="publish_at" placeholder="Search" class="form-control">
                        <span class="input-group-append" [owlDateTimeTrigger]="datePublishRef">
                            <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                        </span>
                        <owl-date-time #datePublishRef></owl-date-time>
                      </div>
                    </div>
                    <div class="col-6 form-group pl-0 pr-0">
                      <label>Unpublish Date <span class="text-danger">*</span></label>
                      <div class="input-group date">
                        <input class="hidden" [owlDateTime]="dateExpireRef" [ngModel]="dateTimeStack[i]?.expire_at" [ngModelOptions]="{standalone: true}">
                        <input type="text" formControlName="expire_at" placeholder="Search" class="form-control">
                        <span class="input-group-append" [owlDateTimeTrigger]="dateExpireRef">
                            <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                        </span>
                        <owl-date-time #dateExpireRef></owl-date-time>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 form-group row">
                  <label class="col-3 col-form-label"> Upload Image <span class="text-danger">*</span></label>
                  <div class="col-3">
                    <label class="btn btn-default btn-sm float-left">
                      <span>Choose file</span>
                      <input type="file" hidden accept="image/*" (change)="onUploadFile($event, language.id)" />
                    </label>
                  </div>
                </div>

                <div class="col-12 form-group" *ngIf="getMediaSource(language.id)">
                  <div class="row">
                    <label class="col-3 col-form-label">Preview</label>
                    <div class="col-9">
                        <div class="image-wrapper">
                          <img
                            class="img-fluid" alt="Image Promotion"
                            [src]="getMediaSource(language.id)" />
                        </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 form-group p-0 m-0">
                  <label>Content <span class="text-danger">*</span></label>
                  <ckeditor [config]="editorConfig" [editor]="editor" formControlName="content"></ckeditor>
                </div>
                <div class="col-12 p-0 mb-2">
                  <span class="header-hint">Usable variables:
                    <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                      <p class="p-inline" (click)="addVariableContent(usableVariable, language.id)">{{ usableVariable }}</p>
                      <div *ngIf="!last" class="p-inline">
                        <p class="p-inline p-display">, </p>
                      </div>
                    </div>
                  </span>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [buttonLoading]="buttonLoading" [isDisabled]="form.invalid || disabledButton" (confirmed)="onSave(data.promotionContent, data.mode)" #focusfield></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>