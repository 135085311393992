<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="id" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Game Provider:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="game_provider_id" class="form-control">
                                        <option value="all">All</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.gameProviders | async">
                                            {{ value.code +' - '+ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label no-break">
                                    <label class="mb-0">Target Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="target_type" class="form-control">
                                        <option value="all">All</option>
                                        <option [value]="row.id" *ngFor="let row of dropdown.targetTypes | async">
                                            {{ row.label | titlecase }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <div class="input-group-prepend">
                                            <select formControlName="date_type" class="form-control">
                                                <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label no-break">
                                    <label class="mb-0">Promo Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="promo_code" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all">All</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Remark:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                  <input type="text" formControlName="remarks" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Account Type:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                    <select formControlName="account_type_condition" class="form-control" >
                                      <option value="And">AND Condition</option>
                                      <option value="Or">OR Condition</option>
                                    </select>
                                  </div>
                                  <div class="col-md-3 kt-form__control">
                                    <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                      [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                      [formName]="'account_type'" [selectionAttributes]="'id'"
                                      [selectedItems]='accountTypeSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                  </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row mb-3 mt-3 d-flex justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button class="btn btn-warning btn-icon-sm" *ngIf="canExportMemberPromotion" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="text-center">Balance</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('game_provider_code')" [ngClass]="sortingConfig.game_provider_code" [class.sort-selected]="sortingSelection.sort_by === 'game_provider_code'">Game Provider</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('promo_code')" [ngClass]="sortingConfig.promo_code" [class.sort-selected]="sortingSelection.sort_by === 'promo_code'">Promo Code</th>
                                    <th class="text-center pr-4 sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'statusid'">Status</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Start Date Time</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('end_datetime')" [ngClass]="sortingConfig.end_datetime" [class.sort-selected]="sortingSelection.sort_by === 'end_datetime'">End Date Time</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('target_amount')" [ngClass]="sortingConfig.target_amount" [class.sort-selected]="sortingSelection.sort_by === 'target_amount'">Target Amount</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('accumulated_win_loss')" [ngClass]="sortingConfig.accumulated_win_loss" [class.sort-selected]="sortingSelection.sort_by === 'accumulated_win_loss'">Current W/L</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('rebate_amount')" [ngClass]="sortingConfig.rebate_amount" [class.sort-selected]="sortingSelection.sort_by === 'rebate_amount'">Rebate Amount</th>
                                    <th class="text-center pl-3 pr-3">Transfer Settings</th>
                                    <th>Remarks</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('unlocked_at')" [ngClass]="sortingConfig.unlocked_at" [class.sort-selected]="sortingSelection.sort_by === 'unlocked_at'">Unlocked Date Time</th>
                                    <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated by</th>
                                    <th class="text-center pr-4">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(memberPromotions$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                <td>{{ row.id }}</td>
                                <td>
                                    <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_id)">
                                      <strong> {{ row.username }}</strong><br>
                                    </a>
                                </td>
                                <td class="text-right">{{ row.currency_code + ' ' + row.balance }}</td>
                                <td class="text-center">{{ row.game_provider_code }}</td>
                                <td class="text-center">{{ row.promo_code }} <br>
                                    {{ row.promo_type_name }} <br>
                                    {{ row.promo_sub_type_name }}
                                </td>
                                <td class="text-center">
                                  <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ promotionTransferStatus[row.status] }}</span>
                                </td>
                                <td>{{ isValidDate(row.start_datetime) ? (row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}</td>
                                <td>{{ isValidDate(row.end_datetime) ? (row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}</td>
                                <td class="text-right">{{ row.code }} {{ row.amount | number : '1.2-2' }}<br/>
                                    <span class="tranfer-green-text">
                                        {{ row.bonus_amount < 0 ? '-' : '+'}}
                                        {{ row.code }} {{ row.bonus_amount | number : '1.2-2' }}
                                    </span>
                                </td>
                                <td class="text-right">
                                  {{ row.accumulated_target_amount }} / {{ row.target_amount | number : '1.2-2' }}
                                  <br/>({{ row.target_type | titlecase }})
                                </td>
                                <td class="text-right">{{ row.currency_code + ' ' + row.accumulated_win_loss  }}</td>
                                <td class="text-right">{{ row.rebate_amount | number : '1.2-2' }}</td>
                                <td class="text-center">
                                  <i class="fas fa-sign-in-alt m-1" [matTooltip]="'Limit Transfer In - ' + (row.limit_transfer_in > 0 ? 'On' : 'Off')" [ngClass]="row.limit_transfer_in > 0 ? 'tranfer-status-green' : 'tranfer-status-red'"></i>
                                  <i class="fas fa-sign-out-alt m-1" [matTooltip]="'Limit Transfer Out - ' + (row.limit_transfer_out > 0 ? 'On' : 'Off')" [ngClass]="row.limit_transfer_out > 0 ? 'tranfer-status-green' : 'tranfer-status-red'"></i>
                                  <i class="fas fa-lock m-1" [matTooltip]="'Auto Unlock - ' + (row.auto_unlock > 0 ? 'On' : 'Off')" [ngClass]="row.auto_unlock > 0 ? 'tranfer-status-green' : 'tranfer-status-red'"></i>
                                </td>
                                <td>{{ row.remarks }}</td>
                                <td>{{ isValidDate(row.unlocked_at) ? (row.unlocked_at | timezoneDate : "YYYY-MM-DD HH:mm") : "-" }}</td>
                                <td>
                                    {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #updatedDateTime>
                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                </td>
                                <td class="text-center">
                                  <button *ngIf="canCancelMemberPromotions && (row.status === 1 || row.status === 8 || row.status === 9)" matTooltip="Cancel" [disabled]="row.status !== 1" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('cancel', row)"><i class="fas fa-ban"></i></button>
                                  <button *ngIf="canUnlockMemberPromotions && (row.status === 1 || row.status === 8 || row.status === 9)" matTooltip="Unlock" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('unlock', row)"><i class="fas fa-unlock"></i></button>
                                  <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                  <!-- <button *ngIf="memberPromotionsUseNewPermissions ? canSyncPromotionTurnover : (userPermissions$ | async).sync_member_promotions" matTooltip="Sync" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSync(row.id)"><i class="fas fa-sync" [id]="'syncBtn_'+row.id"></i></button> -->
                                  <button *ngIf="canSyncPromotionTurnover" matTooltip="Sync" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSync(row.id)"><i class="fas fa-sync" [id]="'syncBtn_'+row.id"></i></button>

                                  <button *ngIf="canViewPromotionHistoryLog" matTooltip="History" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onShowHistory(row.id)"><i class="fas fa-history"></i></button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                      </div>
                    </div>
              </div>
          </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
