import { AuthHttpService } from '@core/services/auth-http.service';
import { AllMemberReportDataService } from './../../all-member-reports/services/all-member-report-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GameResultComponent } from './game-result/game-result.component';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TransactionReportDataService } from './services/transaction-report-data.service';
import { catchError, tap, map, exhaustMap } from 'rxjs/operators';
import { Observable, throwError, of, Subscription } from 'rxjs';
import { TicketInfo } from '@core/models/ticket-info.model';
import { TicketInfoDialogComponent } from './ticket-info/ticket-info.component';
import { TransactionStatus } from '@core/enums/transaction-status.enum';
import { Transfer } from '@core/models/transfer.model';
import { TransferDataService } from './../../../general/transfers/services/transfer-data.service';
import { TransferEntityService } from './../../../general/transfers/services/transfer-entity.service';
import * as moment from 'moment-timezone';
import { GameTypeReport } from '@core/models/gametype-report.model';
import { GameTypeReportDataService } from '../../gametype-report/services/gametype-report-data.service';
import { DatePipe } from '@angular/common';
import { ParlayInfoDialogComponent } from './parlay-info/parlay-info.component';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { DateTime } from 'luxon';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import * as momentTz from 'moment-timezone';
import { DomSanitizer } from '@angular/platform-browser';
import { MemberDataService } from '../../../general/members/services/member-data.service';
import { MemberGameLogDetailsDialogComponent } from '../../../general/members/dialogs/member-game-log-details/member-game-log-details.component';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { now } from 'moment';
import { AppPermissionService } from '@core/services/app-permission.service';

declare var $:any;
@Component({
  selector: 'kt-member-report-details',
  templateUrl: './member-report-details.component.html',
  styleUrls: ['./member-report-details.component.scss'],
})
export class MemberReportDetailsDialogComponent implements OnInit, OnDestroy {
  status = TransactionStatus;
  datetime = {
    start: moment(this.data.start_date_time).tz(this.data.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    end: moment(this.data.end_date_time).tz(this.data.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
  };
  tz = moment().tz(this.data.timezone).format('Z');
  unconvertedFrom = moment(this.data.start_date_time).format('YYYY-MM-DD HH:mm:ss');  // For display only
  unconvertedTo = moment(this.data.end_date_time).format('YYYY-MM-DD HH:mm:ss'); // For display only
  messages$ = this.memberReportDataService.messages$;
  transfer$: Observable<Transfer[]>;
  transferConfig = {
    loading: true,
    page: 1,
    pagination: null,
  };

  dynamicHeader = [];
  dynamicParameter = [];
  transactionReport$: Observable<any[]>;
  transactionReportConfig = {
    loading: true,
    page: 1,
    pagination: null,
  };

  betlogSummary$: Observable<GameTypeReport[]>;
  betlogSummaryConfig = {
    loading: true,
    page: 1,
    pagination: null,
    gametypeReport: {
      option: 'game',
      game_sub_category_name: 'all',
      start_date_time: this.datetime.start,
      end_date_time: this.datetime.end,
      game_provider_code: this.data.game_provider_code,
      username: this.data.username
    }
  };

  dynamicHeaderReward = [];
  dynamicParameterReward= [];
  rewardsReport$: Observable<any[]>;
  rewardsReportConfig = {
    loading: true,
    page: 1,
    pagination: null,
  };
  rewardsFilter = ['AG', 'BG', 'CQ9', 'DG', 'HABA', 'JK', 'MEGA', 'SEXY', 'XE'];

  dynamicHeaderTips = [];
  dynamicParameterTips = [];
  tipsReport$: Observable<any[]>;
  tipsReportConfig = {
    loading: true,
    page: 1,
    pagination: null,
  };
  tipsFilter = ['DG'];

  dynamicHeaderOutstandingBets = [];
  dynamicParameterOutstandingBets = [];
  outstandingBetsReport$: Observable<any[]>;
  outstandingBetsReportConfig = {
    loading: true,
    page: 1,
    pagination: null,
  };
  outstandingBetsFilter = ['CMD', 'IM', 'MAX', 'SBO', 'WBET', 'BS', '9W', 'VRNL', 'TF'];

  params = `&start_date_time=${this.datetime.start}&end_date_time=${this.datetime.end}&game_provider_code=${this.data.game_provider_code}&username=${this.data.username}`;
  pageSize = 15;
  maxSize = 5;
  ranges: any;
  button_loading = false;
  access$ = this.authHttpService.getUserAccess(5, 'Member Report');

  form: FormGroup;
  formDetailBet: FormGroup;
  gameLogConfig = {
    page: 1,
    tableContent: null,
    loading: false,
    buttonLoading: false,
    from: this.transactionHttpService.getToday().from,
    to: this.transactionHttpService.getToday().to,
    params: `date=${moment().utc().format('YYYY-MM-DD')}&start_time=${moment(this.transactionHttpService.getToday().from).utc().format('HH:mm:ss')}&end_time=${moment(this.transactionHttpService.getToday().to).utc().format('HH:mm:ss')}`,
    type: 'Normal',
  }

  dropdown = {
    memberGameLogType: this.dropdownHttpService.memberGameLogType,
    timezones: JSON.parse(localStorage.getItem('timezones')),
  }

  showPastTransactions = true;

  from_time:any;
  to_time:any;
  detailBetLogLoading: boolean = false;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };

  clearBtnLoading = false;

  // permissions
  canViewGameResult: boolean;
  canExportMemberBetLogsReport: boolean;

  private subscriptions = new Subscription();
  
  lastBetLogSyncAt = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      category_code: string;
      game_account: string;
      game_provider_id: number;
      game_provider_code: string;
      game_sub_category_name: string;
      option: string;
      member_account_id: number;
      username: string;
      start_date_time: string;
      end_date_time: string;
      agent_username?: string;
      type: string;
      from: 'member-report' | 'all-member-report' | 'game-provider-report' | 'game-type-report' | 'rebate-details';
      currency_code: string;
      timezone: string;
      game_type: number;
      showPastTransactions: boolean;
    },
    public dialogRef: MatDialogRef<MemberReportDetailsDialogComponent>,
    public dialog: MatDialog,
    private transactionReportDataService: TransactionReportDataService,
    private transferEntityService: TransferEntityService,
    private transferDataService: TransferDataService,
    private gameTypeReportDataService: GameTypeReportDataService,
    private datePipe: DatePipe,
    private memberReportDataService: AllMemberReportDataService,
    private loadingBar: LoadingBarService,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private memberDataService: MemberDataService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(typeof this.data.showPastTransactions !== 'undefined'){
      this.showPastTransactions = false; // hide the PastTransactions
    }

    if(this.data.option == 'game_sub_category' || this.data.option == 'game'){
      if(this.data.game_sub_category_name != undefined && this.data.game_sub_category_name != null){
        this.params += `&sub_category_name=${this.data.game_sub_category_name}`;
        this.betlogSummaryConfig.gametypeReport.game_sub_category_name = this.data.game_sub_category_name;
      }
    }
    this.formInit();
    this.onGetDetailedBetHistory();
    this.onGetTransactions();
    this.onGetBetlogSummary();
    if(this.rewardsFilter.includes(this.data.game_provider_code)){
      this.onGetRewards();
    }
    if(this.tipsFilter.includes(this.data.game_provider_code)){
      this.onGetTips();
    }
    if(this.outstandingBetsFilter.includes(this.data.game_provider_code)){
      this.onGetOutstandingBets();
    }

    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      const from = this.data.from;

      if (from === 'member-report') {
        this.canViewGameResult = appPermissions.view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.export_member_bet_logs_report;
      } else if (from === 'all-member-report') {
        this.canViewGameResult = appPermissions.all_member_report_view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.all_member_report_export_member_bet_logs_report;
      } else if (from === 'game-provider-report') {
        this.canViewGameResult = appPermissions.game_provider_report_view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.game_provider_report_export_member_bet_logs_report;
      } else if (from === 'game-type-report') {
        this.canViewGameResult = appPermissions.game_type_report_view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.game_type_report_export_member_bet_logs_report;
      }else if (from === 'rebate-details') {
        this.canViewGameResult = appPermissions.view_game_report_rebate_v2;
      }
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGetGameResult(gfURL: string){
    if (!this.canViewGameResult) {
      Swal.fire('Permission Error', 'You do not have permission to view game result', 'error');
      return;
    }

    this.onOpenDialog(gfURL);
  }

  onGetDetailedBetHistory(page = 1) {
    of(this.formDetailBet.value).pipe(
      map(this.filterFormFieldsDetailBet),
      tap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
          data['start_date_time'] = moment('1970-01-01 00:00:00').tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:00');
          data['end_date_time'] = moment(now()).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:59');
        }

        this.detailBetLogLoading = true;
        this.transactionReportConfig.loading = true;
        let params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        params += `&game_provider_code=${this.data.game_provider_code}&username=${this.data.username}`;
        if(this.data.game_sub_category_name != undefined && this.data.game_sub_category_name != null){
          params += `&sub_category_name=${this.data.game_sub_category_name}`;
        }
        if(this.data.category_code != undefined && this.data.category_code != null){
          params += `&category_code=${this.data.category_code}`;
        }
        this.transactionReport$ = this.transactionReportDataService
        .getGameResult(`?page=${page}&perPage=${this.pageSize}&${params}`)
        .pipe(
          tap((res) => {
            this.transactionReportConfig.pagination = this.transactionReportDataService.pagination;
            this.transactionReportConfig.loading = false;
            this.detailBetLogLoading = false;
            if (res?.length > 0) {
              this.onSetHeader(res[0], 'transactions');
            }
            this.clearBtnLoading = false;
            this.getLastBetLogSyncAt();
          }),
          catchError((error) => {
            // this.onSearch = true;
            this.detailBetLogLoading = false;
            this.betlogSummaryConfig.loading = false;
            this.clearBtnLoading = false;
            this.getLastBetLogSyncAt();
            return throwError(error);
          })
        );
      })
    ).subscribe();
  }

  onClearDetailBetHistory() {
    this.clearBtnLoading = true;
    this.formDetailBet.patchValue({
      ticket_id: null,
      timezone: this.timezone,
      start_date_time: this.unconvertedFrom,
      end_date_time: this.unconvertedTo,
      unconverted_start_date_time: this.unconvertedFrom,
      unconverted_end_date_time: this.unconvertedTo,
      defaultDate: {
        startDate: this.unconvertedFrom,
        endDate: this.unconvertedTo
      } // Do not remove: For Clearing The Range/ Do not remove: For Clearing The Range
    })
    this.onGetDetailedBetHistory();
  }

  onExport(){
    of(this.formDetailBet.value).pipe(
      map(this.filterFormFieldsDetailBet),
      tap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
          data['start_date_time'] = moment('1970-01-01 00:00:00').tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:00');
          data['end_date_time'] = moment(now()).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:59');
        }

        let params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        params += `&game_provider_code=${this.data.game_provider_code}&username=${this.data.username}`;
        if(this.data.game_sub_category_name != undefined && this.data.game_sub_category_name != null){
          params += `&sub_category_name=${this.data.game_sub_category_name}`;
        }
        if(this.data.category_code != undefined && this.data.category_code != null){
          params += `&category_code=${this.data.category_code}`;
        }

        params = params + (this.data.agent_username ? `&agent_username=${this.data.agent_username}` : '');
        this.button_loading = true;

        this.loadingBar.start();
        return this.memberReportDataService
          .exportBetHistory(`?${params}`)
          .pipe(
            tap((res) => {
              this.loadingBar.complete();
              this.button_loading = false;
            }),
            catchError((err) => {
              this.loadingBar.complete();
              this.button_loading = false;
              return throwError(err);
            })
          )
          .subscribe();

      })
    ).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialogTicketInfo(
    transactionId: string,
    gameProviderCode: string,
    ticketInfo: TicketInfo[]
  ) {
    this.dialog.open(TicketInfoDialogComponent, {
      width: '1400px',
      data: {
        transactionId,
        gameProviderCode,
        ticketInfo,
      },
    });
  }

  onGetTransactions(page = 1) {
    this.transferConfig.loading = true;
    const params = `&username=${this.data.username}&start_date=${this.datetime.start}&end_date=${this.datetime.end}&game_provider=${this.data.game_provider_id}&status=1`;
    this.transfer$ = this.transferEntityService
      .getWithQuery(
        `?page=${page}&perPage=${this.pageSize}${params}`
      )
      .pipe(
        tap((res) => {
          this.transferConfig.pagination = this.transferDataService.pagination;
          this.transferConfig.loading = false;
        }),
        catchError((error) => {
          this.betlogSummaryConfig.loading = false;
          return throwError(error);
        })
      );
  }

  onFormatLog(log) {
    if (typeof (log) === 'number') {
      return '<span>' + log + '</span><br>';
    } else if (this.isJson(log) || typeof (log) === 'object') {
      let result = '';
      let jsonObject = typeof (log) === 'object' ? log : JSON.parse(log);
      if (jsonObject != null && jsonObject != 'null') {
        Object.keys(jsonObject).forEach(key => {
          if (typeof (jsonObject[key]) === 'object') {
            result += '<strong>' + key + '~</strong><br>' + this.onFormatLog(JSON.stringify(jsonObject[key]));
          } else {
            result += '<span>' + key + ' : ' + jsonObject[key] + '</span><br>';
          }
        });
      } else {
        result += '<span>-</span><br>';
      }
      return result;
    } else if(moment(log, 'YYYY-MM-DD\THH:mm:ss\Z', true).isValid()){
      return '<div class="date-column">' + this.datePipe.transform(log, 'yyyy-MM-dd HH:mm:ss') + '</div><br>';
    }else {
      return '<span>' + log + '</span><br>';
    }
  }

  onFormatResult(log) {
    if (!Array.isArray(log)) {
      return log;
    }
    const res = [];
    for (let i = 0; i < log.length; i += 3) {
      const chunk = log.slice(i, i + 3).concat(";").join('');
      res.push(chunk);
    }
    return res.join('  ');
}

  onOpenDialog(resultUrl: any) {
    this.openDialogBy(GameResultComponent, { gfURL: resultUrl });
  }

  onOpenParlayInfo(username: string, ticketId: number, info: any) {
    const data = {
      username: username,
      ticket_id: ticketId,
      info: info
    }
    this.dialog.open(ParlayInfoDialogComponent, {data : data, width: '80vw'});
  }

  onSetHeader(data: any, type: string) {
    if (type === 'transactions'){
      this.dynamicHeader = [];
      this.dynamicParameter = [];
    }else if (type === 'rewards'){
      this.dynamicHeaderReward = [];
      this.dynamicParameterReward = [];
    }else if (type === 'tips'){
      this.dynamicHeaderTips = [];
      this.dynamicParameterTips= [];
    }else{
      this.dynamicHeaderOutstandingBets = [];
      this.dynamicParameterOutstandingBets = [];
    }
    if (data !== null) {
      Object.keys(data).map(key => {
        const header = key.replace(/_/g," ");
        if (type === 'transactions'){
          this.dynamicHeader.push(header);
          this.dynamicParameter.push(key);
        }else if (type === 'rewards'){
          this.dynamicHeaderReward.push(header);
          this.dynamicParameterReward.push(key);
        }else if (type === 'tips'){
          this.dynamicHeaderTips.push(header);
          this.dynamicParameterTips.push(key);
        }else{
          this.dynamicHeaderOutstandingBets.push(header);
          this.dynamicParameterOutstandingBets.push(key);
        }
      });
    }
  }

  onGetBetlogSummary(page = 1) {
    this.betlogSummaryConfig.loading = true;
    const params = Object.keys(this.betlogSummaryConfig.gametypeReport).map(key => key + '=' + this.betlogSummaryConfig.gametypeReport[key]).join('&');
    this.betlogSummary$ = this.gameTypeReportDataService
      .getWithQuery(`?paginate=true&page=${page}&perPage=${this.pageSize}&${params}`)
      .pipe(
        tap((res) => {
          this.betlogSummaryConfig.pagination = this.gameTypeReportDataService.pagination;
          this.betlogSummaryConfig.loading = false;
        }),
        catchError((error) => {
          // this.onSearch = true;
          this.betlogSummaryConfig.loading = false;
          return throwError(error);
        })
      );
  }

  onGetRewards(page = 1){
    this.rewardsReportConfig.loading = true;
    const params = this.params + (this.data.agent_username ? `&agent_username=${this.data.agent_username}` : '');
    this.rewardsReport$ = this.transactionReportDataService
      .getRewards(`?page=${page}&perPage=${this.pageSize}${params}`)
      .pipe(
        tap((res) => {
          this.rewardsReportConfig.pagination = this.transactionReportDataService.pagination;
          this.rewardsReportConfig.loading = false;
          if (res?.length > 0) {
            this.onSetHeader(res[0], 'rewards');
          }
        }),
        catchError((error) => {
          this.rewardsReportConfig.loading = false;
          return throwError(error);
        })
      );
  }

  onGetTips(page = 1){
    this.tipsReportConfig.loading = true;
    const params = this.params + (this.data.agent_username ? `&agent_username=${this.data.agent_username}` : '');
    this.tipsReport$ = this.transactionReportDataService
      .getTips(`?page=${page}&perPage=${this.pageSize}${params}`)
      .pipe(
        tap((res) => {
          this.tipsReportConfig.pagination = this.transactionReportDataService.pagination;
          this.tipsReportConfig.loading = false;
          if (res?.length > 0) {
            this.onSetHeader(res[0], 'tips');
          }
        }),
        catchError((error) => {
          this.tipsReportConfig.loading = false;
          return throwError(error);
        })
      );
  }

  onGetOutstandingBets(page = 1){
    this.outstandingBetsReportConfig.loading = true;
    const params = this.params + (this.data.agent_username ? `&agent_username=${this.data.agent_username}` : '');
    this.outstandingBetsReport$ = this.transactionReportDataService
      .getOutstandingBalance(`?page=${page}&perPage=${this.pageSize}${params}`)
      .pipe(
        tap((res) => {
          this.outstandingBetsReportConfig.pagination = this.transactionReportDataService.pagination;
          this.outstandingBetsReportConfig.loading = false;
          if (res?.length > 0) {
            this.onSetHeader(res[0], 'outstanding bets');
          }
        }),
        catchError((error) => {
          this.outstandingBetsReportConfig.loading = false;
          return throwError(error);
        })
      );
  }

  private openDialogBy(componentRef: any, data?: { gfURL: string}) {
    this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data: {
        gfURL: data.gfURL,
      }
    });
  }

  private isJson(data: string) {
    try {
      if (data.includes('{')) {
        JSON.parse(data);
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    let returnDate =  this.timeZoneDate.transformWithTimezone(date, timezone, format);
    let todayDate = DateTime.now().setZone(timezone).toFormat('(ZZ)');
    todayDate = todayDate.replace('(', '(GMT ');
    returnDate = returnDate + ' ' + todayDate;
    return returnDate;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.formDetailBet.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.from_time = event.startDate.format('YYYY-MM-DD HH:mm:ss');
      this.to_time = event.endDate.format('YYYY-MM-DD HH:mm:ss');
    }
    if(this.from_time < this.unconvertedFrom || this.to_time > this.unconvertedTo){
      Swal.fire({
        title: this.translateService.instant('Input Error'),
        text: this.translateService.instant(`Please note that this report is only able to search within the range from ${this.unconvertedFrom} to ${this.unconvertedTo}`),
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.translateService.instant('Close'),
      }).then(() => {
        this.formDetailBet.patchValue({
          start_date_time: this.unconvertedFrom,
          end_date_time: this.unconvertedTo,
          unconverted_start_date_time: this.unconvertedFrom,
          unconverted_end_date_time: this.unconvertedTo,
          defaultDate: {
            startDate: this.unconvertedFrom,
            endDate: this.unconvertedTo
          }
        })
      });
    } else {
      this.formDetailBet.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null,
        unconverted_start_date_time: event.startDate,
        unconverted_end_date_time: event.endDate,
        defaultDate: {
          startDate: this.unconvertedFrom,
          endDate: this.unconvertedTo
        }
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    if (this.formDetailBet.value.start_date_time !== null) {
      this.formDetailBet.patchValue({ defaultDate: null});
    }
  }

  private formInit(){
    this.form = new FormGroup({
      date: new FormControl(moment(this.gameLogConfig.to).format('YYYY-MM-DD'),[Validators.required]),
      start_time: new FormControl(moment(this.gameLogConfig.from).format('YYYY-MM-DD HH:mm:ss')),
      end_time: new FormControl(moment(this.gameLogConfig.to).format('YYYY-MM-DD HH:mm:ss')),
      page: new FormControl(this.gameLogConfig.page, [Validators.required]),
      type: new FormControl(this.gameLogConfig.type)
    });

    this.formDetailBet = new FormGroup({
      ticket_id: new FormControl(null),
      defaultDate: new FormControl({
        startDate: this.unconvertedFrom,
        endDate: this.unconvertedTo
      }),
      start_date_time: new FormControl(this.unconvertedFrom),
      end_date_time: new FormControl(this.unconvertedTo),
      unconverted_start_date_time: new FormControl(this.unconvertedFrom),
      unconverted_end_date_time: new FormControl(this.unconvertedTo),
      timezone: new FormControl(this.timezone),
    });
  }

  // submit game log form data
  onSubmit() {
    this.gameLogConfig.loading = true;
    this.gameLogConfig.buttonLoading = true;
    this.gameLogConfig.tableContent = `<div class="spinner-wrapper">
    <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
    </div>`;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.memberDataService.getMemberGameLog(`?${this.params}&username=${this.data.username}&member_account_id=${this.data.member_account_id}&game_provider_code=${this.data.game_provider_code}&currency_code=${this.data.currency_code}&game_account=${this.data.game_account}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.gameLogConfig.loading = false;
            this.gameLogConfig.buttonLoading = false;
            this.gameLogConfig.tableContent = this.sanitizer.bypassSecurityTrustHtml(res);
            this.getLastBetLogSyncAt();
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.gameLogConfig.loading = false;
            this.gameLogConfig.buttonLoading = false;
            this.getLastBetLogSyncAt();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = key === 'start_time' ? momentTz.tz(moment(formData[key]).format('YYYY-MM-DD HH:mm:00'), "Asia/Kuala_Lumpur").utc().format('YYYY-MM-DD HH:mm:00') : key === 'date' ? momentTz.tz(moment(formData[key]).format('YYYY-MM-DD HH:mm:ss'), "Asia/Kuala_Lumpur").utc().format('YYYY-MM-DD HH:mm:ss')  : key === 'end_time' ? momentTz.tz(moment(formData[key]).format('YYYY-MM-DD HH:mm:59'), "Asia/Kuala_Lumpur").utc().format('YYYY-MM-DD HH:mm:59') : formData[key];
      }
    });
    return fields;
  }
  
  onChangePage(mode:any) {
    if(mode === 'next'){
      this.gameLogConfig.page = Number(this.form.get('page').value) + 1;
    }else{
      this.gameLogConfig.page = Number(this.form.get('page').value) - 1;
    }
    this.form = new FormGroup({
      date: new FormControl(this.form.get('date').value, [Validators.required]),
      start_time: new FormControl(this.form.get('start_time').value),
      end_time: new FormControl(this.form.get('end_time').value),
      page: new FormControl(this.gameLogConfig.page, [Validators.required]),
      type: new FormControl(this.form.get('type').value)
    });
    this.onSubmit();
  }

  htmlRender(){
    if($('#link_view_game_sessions_popup').length){
      $('#contentTable').off('click');
      $( "#contentTable" ).on( "click", "tr",(events)=> {
        if($(events.currentTarget).attr('data-link') != undefined){
          this.onOpenGameLogDetailsDialog($(events.currentTarget).attr('data-link'), this.data, this.data.game_account);
        }
      });
    }else{
      $('#link_view_game_sessions').css("color", "#5867dd");
      $('#link_view_game_sessions').off('click');
      $( "#link_view_game_sessions" ).click((events)=> {
        if($(events.currentTarget).attr('data-link') != undefined){
          this.onOpenGameLogDetailsDialog(String($(events.currentTarget).attr('data-link')), this.data, this.data.game_account);
        }
      });
    }
  }  

  // open game log details dialog
  onOpenGameLogDetailsDialog(dataLink: String, member: any, game?: any) {
    this.openGameLogDetailsDialogBy(MemberGameLogDetailsDialogComponent, { dataLink: dataLink, member: member, existing_game_account: game})
  }

  private openGameLogDetailsDialogBy(componentRef: any, data?: { dataLink?: String, member?:any, existing_game_account?:any}) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        dataLink: data.dataLink,
        member: data.member,
        existing_game_account : data.existing_game_account
      }
    });
  }

  private filterFormFieldsDetailBet(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        if (key === 'unconverted_start_date_time' || key === 'unconverted_end_date_time') {
          fields[key] = moment(formData[key]).format('YYYY-MM-DD HH:mm:ss');
        } else {
          fields[key] = formData[key];
        }
      }
    });
    return fields;
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }

}
